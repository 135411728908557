


<div class="container p-0" *ngIf="deactiveUser">
    <div class="close-btn-section mb-2 cursor" (click)="close()">
    <img src="../../../assets/images/panel_images/close-1.svg" class="float-right">

    </div>
    <div class="close-img-section text-center pt-5 mb-3">
        <div class="d-flex justify-content-center">
    <img src="../../../assets/images/panel_images/close.svg" class="mb-3">

        </div>
    <div class="Deactive-user mb-2">
        <h5 class="mx-2">Suspend User</h5>
        <p>Are you sure you want to deactivate this user? </p>
    </div>
        <div class="user-details-img mb-4">
          <div class="d-flex">
            <div class="">
                <!-- <img src="../../../assets/images/panel_images/user-img.svg" class="rounded user-img"> -->
                <img *ngIf="profile != null" [src]="profile" class="mr-md-2 mt-md-1 user-img">
                <ngx-avatar *ngIf="profile == null" [class.name]="name != null" class="mr-2" size="40" initialsSize="1" name="{{ name }}"></ngx-avatar>
				<ngx-avatar *ngIf="name == null"  class="mr-3 name-a" initialsSize="1" size="40" name="N/A"></ngx-avatar>
            </div>
            <div class="details_user mt-3 pl-md-2">
                <h4 [class.memberID] ='name == null'>{{name ? name : 'N/A'}}</h4>
                <p class="mt-md-1" *ngIf="isMembershipId == true">{{membershipId ? membershipId : ''}}</p>
            </div>
          </div>
        </div>
    <div class="">
        <div class="Cancel-btn mt-5">
            <button class="btn btn-cancel" (click)="close()">Cancel</button>
            <button class="btn btn-Deactivate" (click)="suspend()">Suspend </button>
        </div>
    </div>
    </div>

</div>
<div class="main" *ngIf="statusSuccess">
  <div class="container">
    <div class="congrats text-center">
      <img src="../../assets/images/panel_images/thank.gif" class="success_Img" />
    </div>
     <div class="thank_you-section">
      <h4 class="mb-md-3">{{status}}</h4>
      </div>
      <div class="close-btn mt-5 mb-4">
        <button class="btn btn-close" (click)="close()">Close</button>
      </div>
    </div>
    </div>
