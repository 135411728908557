<div class="container-fluid bg-white">
    <div class="pt-3 ml-3">
        <div class="d-flex pt-3 pb-2">
            <div class="back-btn mr-2 cursor" routerLink="/Internal-Team">
                <img src="../../../assets/images/panel_images/back.svg" class="back-img" />
            </div>
            <div class="user-header-back">
                <h5>View User</h5>
            </div>
        </div>
    </div>
    <form [formGroup]="createUserManagment">
        <div class="user-header-details-img-section mt-4 ml-3 pb-5 bg-white">
            <div class="upload pt-4 pl-4">
                <img *ngIf="profile != ''" [src]="profile" width="100" height="100" alt="" class="upload-img" />
                <img *ngIf="profile == ''" src="../../assets/images/panel_images/proflie-o.svg" width="100" height="100" alt="" class="upload-img" />
                <div class="round">
                    <input type="file" accept="Image/*" (change)="onFileSelected($event)" />
                    <img src="../../assets/images/panel_images/camera.svg" width="100" height="100" alt="" class="camera-img" />
                </div>
            </div>
            <div class="form-section mt-3 ml-2">
                <div class="row">
                    <div class="col-md-5">
                        <div class="form-group">
                            <label for="exampleInputPassword1">First Name</label>
                            <input type="text" class="form-control" (keypress)="alphabetOnly($event)" formControlName="firstName" id="exampleInputPassword1" placeholder="Enter first name here" />
                            <div *ngIf="
                  userManagementControls.firstName.errors?.required && submitted
                " class="invalid-feedback d-block">
                                Please Enter The First Name
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="form-group">
                            <label for="exampleInputPassword1">Last Name</label>
                            <input type="text" class="form-control" (keypress)="alphabetOnly($event)" formControlName="lastName" id="exampleInputPassword1" placeholder="Enter last name here" />
                            <div *ngIf="
                  userManagementControls.lastName.errors?.required && submitted
                " class="invalid-feedback d-block">
                                Please Enter The Last Name
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Email address</label>
                            <input type="email" class="form-control" formControlName="email" readonly="readonly" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email address here" />
                            <div *ngIf="
                  userManagementControls.email.errors?.required && submitted
                " class="invalid-feedback d-block">
                                Please Enter The Email
                            </div>
                            <div *ngIf="
                  userManagementControls.email.errors?.pattern && submitted
                " class="invalid-feedback d-block">
                                Please Enter The Valid Email
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="form-group">
                            <label for="exampleInputPassword1">Mobile Number</label>
                            <input type="text" class="form-control" (keypress)="numberOnly($event)" maxlength="10" formControlName="phone" id="exampleInputPassword1" placeholder="Enter mobile number here" />
                            <div *ngIf="
                  userManagementControls.phone.errors?.required && submitted
                " class="invalid-feedback d-block">
                                Please Enter Mobile Number
                            </div>
                            <div *ngIf="
                  userManagementControls.phone.errors?.pattern && submitted
                " class="invalid-feedback d-block">
                                Please Enter The Valid Mobile Number
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Role</label>
                            <select class="form-control select" formControlName="role" id="exampleFormControlSelect1">
                <option value="" selected="selected">Select</option>
                <option value="admin">Admin</option>
                <option value="superAdmin">Super admin</option>
                <option value="manager">Manager</option>
              </select>
                            <div *ngIf="
                  userManagementControls.role.errors?.required && submitted
                " class="invalid-feedback d-block">
                                Please Select
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="checkbox-select-section mt-3 ml-2">
                <div class="check-box-option">
                    <label>Members List</label>
                    <section class="example-section" class="d-flex">
                        <div formArrayName="memberList" class="mr-3 d-flex user-m-section">
                            <input #checkboxes type="checkbox" value="View" [checked]="memberListChecked?.view" (change)="onChange($event)" />
                            <p class="mr-3">View</p>
                            <input #checkboxes type="checkbox" value="Edit" [checked]="memberListChecked?.edit" (change)="onChange($event)" />
                            <p class="mr-3">Edit</p>
                            <input #checkboxes type="checkbox" [checked]="memberListChecked?.analyse" (change)="onChange($event)" />
                            <p class="mr-3">View analytics</p>
                        </div>
                    </section>
                    <!-- <div *ngIf="
              userManagementControls.memberList.errors?.required && submitted
            " class="invalid-feedback d-block">
                        Please Select
                    </div> -->
                </div>
                <div class="check-box-option mt-3">
                    <label>Service Requests</label>
                    <section class="example-section" class="d-flex">
                        <div formArrayName="serviceRequest" class="mr-3 d-flex user-m-section">
                            <input #checkboxes type="checkbox" value="View" [checked]="ServiceChecked?.view" (change)="onChangeService($event)" />
                            <p class="mr-3">View</p>
                            <input #checkboxes type="checkbox" value="Edit" [checked]="ServiceChecked?.edit" (change)="onChangeService($event)" />
                            <p class="mr-3">Edit</p>
                            <input #checkboxes type="checkbox" [checked]="ServiceChecked?.analyse" (change)="onChangeService($event)" />
                            <p class="mr-3">View analytics</p>
                        </div>
                    </section>
                    <!-- <div *ngIf="
              userManagementControls.serviceRequest.errors?.required &&
              submitted
            " class="invalid-feedback d-block">
                        Please Select
                    </div> -->
                </div>
                <div class="check-box-option mt-3">
                    <label>Prayer Requests</label>
                    <section class="example-section" class="d-flex">
                        <div formArrayName="prayerRequest" class="mr-3 d-flex user-m-section">
                            <input #checkboxes type="checkbox" value="View" [checked]="prayerChecked?.view" (change)="onChangePrayer($event)" />
                            <p class="mr-3">View</p>
                            <input #checkboxes type="checkbox" value="Edit" [checked]="prayerChecked?.edit" (change)="onChangePrayer($event)" />
                            <p class="mr-3">Edit</p>
                            <input #checkboxes type="checkbox" [checked]="prayerChecked?.analyse" (change)="onChangePrayer($event)" />
                            <p class="mr-3">View analytics</p>
                        </div>
                    </section>
                    <!-- <div *ngIf="
              userManagementControls.prayerRequest.errors?.required && submitted
            " class="invalid-feedback d-block">
                        Please Select
                    </div> -->
                </div>
                <div class="check-box-option mt-3">
                    <label>Donations</label>
                    <section class="example-section" class="d-flex">
                        <div formArrayName="donation" class="mr-3 d-flex user-m-section">
                            <input #checkboxes type="checkbox" value="View" [checked]="donationChecked?.view" (change)="onChangeDonations($event)" />
                            <p class="mr-3">View</p>
                            <!-- <input #checkboxes type="checkbox" value="Edit" [checked]="donationChecked?.edit" (change)="onChangeDonations($event)">
               <p class="mr-3">Edit</p> -->
                            <input #checkboxes type="checkbox" [checked]="donationChecked?.analyse" (change)="onChangeDonations($event)" />
                            <p class="mr-3">View analytics</p>
                        </div>
                    </section>
                    <!-- <div *ngIf="
              userManagementControls.donation.errors?.required && submitted
            " class="invalid-feedback d-block">
                        Please Select
                    </div> -->
                </div>
                <div class="check-box-option mt-3">
                    <label>TV Programs</label>
                    <section class="example-section" class="d-flex">
                        <div formArrayName="tvProgram" class="mr-3 d-flex user-m-section">
                            <input #checkboxes type="checkbox" value="View" [checked]="tvProgramChecked?.view" (change)="onChangeTvPrograms($event)" />
                            <p class="mr-3">View</p>
                            <!-- <input #checkboxes type="checkbox" value="Edit" [checked]="tvProgramChecked?.edit" (change)="onChangeTvPrograms($event)">
               <p class="mr-3">Edit</p> -->
                            <input #checkboxes type="checkbox" [checked]="tvProgramChecked?.analyse" (change)="onChangeTvPrograms($event)" />
                            <p class="mr-3">View analytics</p>
                        </div>
                    </section>
                    <!-- <div
            *ngIf="
              userManagementControls.tvProgram.errors?.required && submitted
            "
            class="invalid-feedback d-block"
          >
            Please Select
          </div> -->
                </div>
                <div class="check-box-option mt-3">
                    <label>Support</label>
                    <section class="example-section" class="d-flex">
                        <div formArrayName="support" class="mr-3 d-flex user-m-section">
                            <input #checkboxes type="checkbox" value="View" [checked]="supportChecked?.view" (change)="onChangeSupport($event)" />
                            <p class="mr-3">View</p>
                            <input #checkboxes type="checkbox" value="Edit" [checked]="supportChecked?.edit" (change)="onChangeSupport($event)" />
                            <p class="mr-3">Edit</p>
                            <input #checkboxes type="checkbox" [checked]="supportChecked?.analyse" (change)="onChangeSupport($event)" />
                            <p class="mr-3">View analytics</p>
                        </div>
                    </section>
                    <!-- <div
            *ngIf="userManagementControls.support.errors?.required && submitted"
            class="invalid-feedback d-block"
          >
            Please Select
          </div> -->
                </div>
                <div class="check-box-option mt-3">
                    <label>CMS Module </label>
                    <section class="example-section" class="d-flex">
                        <div formArrayName="cmsModule" class="mr-3 d-flex user-m-section">
                            <input #checkboxes type="checkbox" value="View" [checked]="cmsModelChecked?.view" (change)="onChangeCms($event)" />
                            <p class="mr-3">View</p>
                            <input #checkboxes type="checkbox" value="Edit" [checked]="cmsModelChecked?.edit" (change)="onChangeCms($event)" />
                            <p class="mr-3">Edit</p>
                            <!-- <input #checkboxes type="checkbox" [checked]="cmsModelChecked?.analyse" (change)="onChangeCms($event)">
               <p class="mr-3">View analytics</p> -->
                        </div>
                    </section>
                    <div class="check-box-option mt-3">
                        <label>Spiritual Fitness </label>
                        <section class="example-section" class="d-flex">
                            <div formArrayName="spiritual_Fitness" class="mr-3 d-flex user-m-section">
                                <input #checkboxes type="checkbox" value="View" [checked]="spiritualFitnessChecked?.view" (change)="onChangeSpiritualFitness($event)" />
                                <p class="mr-3">View</p>
                            </div>
                        </section>
                        <!-- <div
              *ngIf="
                userManagementControls.spiritual_Fitness.errors?.required &&
                submitted
              "
              class="invalid-feedback d-block"
            >
              Please Select
            </div> -->
                    </div>
                </div>
            </div>
            <div class="success-msg justify-content-center d-flex align-items-center mt-3" *ngIf="createSuccesfully">
                <h5>
                    <img src="../../../assets/images/panel_images/success.svg" class="mr-2" />User Created Successfully
                </h5>
            </div>
            <div *ngIf="!spiritualFitnessView
            && !memberslistView
            && !memberslistEdit
            && !memberslistanalyse
            && !supportView
            && !supportEdit
            && !supportanalyse
            && !tvProgramView
            && !tvProgramanalyse
            && !donationView
            && !donationanalyse
            && !prayerView
            && !prayerEdit
            && !prayeranalyse
            && !serviceView
            && !serviceEdit
            && !serviceanalyse
            && !cmsView
            && !cmsEdit
            &&
            this.submitted" class="invalid-feedback d-block"> Please Select Atleast One</div>
        </div>
    </form>
</div>
<div class="button-section d-flex bg-white py-4 px-5">
    <div class="cancel-btn mr-auto">
        <!-- <button class="btn">Cancel</button> -->
    </div>
    <div class="create-team-btn">
        <button class="btn" (click)="createUser()">
      Save <img src="../../../assets/images/panel_images/create-1.svg" />
    </button>
    </div>
</div>