<div class="main">
  <div class="container-fluid bg-white">
    <div class="user-list-header pt-3 pb-1 ml-2">
      <div class="user-list-heading d-flex">
        <img src="../../assets/images/panel_images/it-img.svg" class="user-list mr-2" />

        <h6 class="mt-2">IT Team Support</h6>
      </div>
    </div>
  </div>

  <div class="container-fluid pt-5 mx-1">
    <div class="d-flex">
      <div class="card users-count mr-3 total-user-bg">
        <div class="card-body d-flex">
          <img src="../../assets/images/panel_images/sr-1.svg" id="users-total" class="mr-2" />
          <div class="card-details-users">
            <h4 class="mt-3">Support Requests</h4>
            <h2 class="mt-2">{{ total ? (total | records) : 0 }}</h2>
          </div>
        </div>
      </div>
      <div class="card users-count mr-3 total-existing-bg">
        <div class="card-body d-flex">
          <img src="../../assets/images/panel_images/sr-3.svg" id="users-total" class="mr-2" />
          <div class="card-details-users">
            <h4 class="mt-3">Open</h4>
            <h2 class="mt-2">{{ open ? (open | records) : 0 }}</h2>
          </div>
        </div>
      </div>
      <div class="card users-count mr-3 total-active-bg">
        <div class="card-body d-flex">
          <img src="../../assets/images/panel_images/sr-2.svg" id="users-total" class="mr-2" />
          <div class="card-details-users">
            <h4 class="mt-3">Closed</h4>
            <h2 class="mt-2">{{ closed ? (closed | records) : "0" }}</h2>
          </div>
        </div>
      </div>
      <div class="card users-count mr-0 total-non-bg">
        <div class="card-body d-flex">
          <img src="../../assets/images/panel_images/sr-4.svg" id="users-total" class="mr-2" />
          <div class="card-details-users">
            <h4 class="mt-3">Invalid Requests</h4>
            <h2 class="mt-2">{{ invalid ? (invalid | records) : "0" }}</h2>
          </div>
        </div>
      </div>
    </div>
    <app-showloader></app-showloader>
    <div class="table mt-5 mb-5">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="Requestno">
          <th mat-header-cell class="th-header-table" *matHeaderCellDef>
            <h2>Request no</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="">
              <h6 class="mt-2">{{ element.ticketId }}</h6>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef>
            <h2>user</h2>
          </th>
          <td mat-cell *matCellDef="let element" class="min-200">
            <div class="d-flex">
              <img *ngIf="element?.user[0]?.profile != '' && element.user[0] != null" [src]="element?.user[0]?.profile"
                class="user-image-table mr-2" />
              <ngx-avatar *ngIf="element?.user[0]?.profile == '' && 
                element?.user[0]?.firstName != '' " class="mr-2" initialsSize="1" size="40"
                name="{{ element?.user[0]?.firstName }} "></ngx-avatar>
              <ngx-avatar *ngIf="
                  element?.user[0]?.firstName == '' ||
                  element?.user[0]?.firstName == null ||
                  element.user[0] == null
                " class="mr-2 name-aa" initialsSize="1" size="40" name="N/A"></ngx-avatar>
              <div class="">
                <h6 class="mt-2">
                  {{ element?.user[0]?.firstName }}
                  {{
                  element?.user[0]?.lastName
                  ? element?.user[0]?.lastName
                  : "N/A"
                  }}
                </h6>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="Service">
          <th mat-header-cell *matHeaderCellDef>
            <h2>phone no.</h2>
          </th>
          <td mat-cell *matCellDef="let element" class="min-200">
            <h4>
              <span *ngIf="element?.mobile != null && element?.mobile != ''">+91-</span>{{ element?.mobile ?
              element.mobile : "-" }}
            </h4>
          </td>
        </ng-container>

        <ng-container matColumnDef="RECEIVERSNAME">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Issue Title & Description</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h6 class="mb-0">{{ element.title }}</h6>
            <P>{{ element?.description ? element.description : "-" }}</P>
          </td>
        </ng-container>
        <ng-container matColumnDef="Date">
          <th mat-header-cell *matHeaderCellDef>
            <h2 class="ml-5">Date & Time</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt | date : "MMMM d, y" }} <br />
            {{ element.createdAt | date : "shortTime" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Status</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h6 *ngIf="element.status == 'open'" class="eligable">
              {{ element.status | titlecase }}
            </h6>
            <h6 *ngIf="element.status == 'closed'" class="not-eligable">
              {{ element.status | titlecase }}
            </h6>
            <h6 *ngIf="element.status == 'invalid'" class="not-member">
              {{ element.status | titlecase }}
            </h6>
          </td>
        </ng-container>
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Action</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <button class="mb-4" mat-icon-button mat-button [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu">
              <mat-icon>
                <img src="../../assets/images/panel_images/action-dot.svg" id="users" class="action-dots" /></mat-icon>
              <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" place></div>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button class="View" mat-menu-item value="view" (click)="view()" routerLink="./"
                [queryParams]="{ _id: element._id }">
                <img src="../../assets/images/panel_images/view.svg" class="mr-3" />
                <span>View</span>
              </button>
              <button class="View" mat-menu-item value="element._id" *ngIf="
                  element.status != 'closed' && element.status != 'invalid'
                " (click)="markAsClose(element._id)">
                <img src="../../assets/images/panel_images/mark.svg" class="mr-3" />
                <span>Mark as Close</span>
              </button>
              <button class="Deactivate" mat-menu-item value="Edit" *ngIf="
                  element.status != 'closed' && element.status != 'invalid'
                " (click)="markAsInvalid(element._id)">
                <img src="../../assets/images/panel_images/decline.svg" class="mr-3" />
                <span>Invalid</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data available</td>
        </tr>
      </table>
      <mat-paginator (page)="pageChanged($event)" [length]="total" [pageIndex]="currentPage" [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator>
    </div>
  </div>
</div>