<div class="container-fluid bg-white ">
    <div class="pt-3 ml-1 ">
        <div class="d-flex pt-3 pb-2">
            <div class="back-btn mr-2 cursor" routerLink="/Donations">
                <img src="../../../assets/images/panel_images/back.svg" class="back-img">
            </div>
            <div class="user-header-back">
                <h5>Donations</h5>
                <p><span>Home / </span>Donations</p>
            </div>
        </div>
    </div>

</div>

<div class="container-fluid mt-3">
    <div class="row">
        <div class="col-md-12">
            <div class="user-header-details-img-section pt-5 bg-white ml-1">
                <div class="users-img mb-4">
                    <div class="user-details-img d-flex  mb-4">
                        <div class="d-flex mr-auto mt-0" *ngFor="let user of message">
                            <div class="mr-3 ml-4">
                                <img *ngIf="user?.users[0]?.profile != null || ''" src="{{user?.users[0]?.profile}}" class="rounded-1"> </div>
                            <ngx-avatar *ngIf="user?.users[0]?.profile == null" class="mr-2 mt-3" initialsSize="1" size="80" name="{{ user?.users[0]?.name}}"></ngx-avatar>
                            <ngx-avatar *ngIf="user?.users[0]?.name == null || user?.users[0]?.name == ''" class="mr-2 name-a" initialsSize="1" size="80" name="N/A"></ngx-avatar>


                            <div class="details_user mt-4">
                                <h4>{{user?.users[0]?.name ? user?.users[0]?.name : 'N/A'}}</h4>
                                <p *ngIf="user?.users[0]?.membershipId != null">{{user?.users[0]?.membershipId ? user?.users[0]?.membershipId : 'N/A'}}</p>
                            </div>
                        </div>

                    </div>

                </div>
                <hr class="hr-line-user">
                <div class="details-section mt-3 ml-5 " *ngFor="let user of message">
                    <div class="d-flex">
                        <div class="details-heading details-services">
                            <h4>Name:</h4>
                        </div>
                        <div class="details-heading-user">
                            <h4>{{user?.users[0]?.name ? user?.users[0]?.name : 'N/A'}}</h4>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="details-heading details-services">
                            <h4>Email:</h4>
                        </div>
                        <div class="details-heading-user">
                            <h4>{{user?.users[0]?.email ? user?.users[0]?.email : 'N/A'}}</h4>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="details-heading details-services">
                            <h4>Mobile:</h4>
                        </div>
                        <div class="details-heading-user">
                            <h4>{{user?.users[0]?.phone ? user?.users[0]?.phone : 'N/A'}}</h4>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="details-heading details-services">
                            <h4>Amount:</h4>
                        </div>
                        <div class="details-heading-user">
                            <h4>₹ {{user?.amount ? user?.amount : 'N/A'}}</h4>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="details-heading details-services">
                            <h4>Date:</h4>
                        </div>
                        <div class="details-heading-user">
                            <h4>{{user?.createdAt ? (user?.createdAt | date:'dd-MM-yyyy'): 'N/A'}}</h4>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="details-heading details-services">
                            <h4>Payment Mode:</h4>
                        </div>
                        <div class="details-heading-user">
                            <h4>{{user?.payment?.method ? user?.payment?.method : 'N/A'}}</h4>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="details-heading details-services">
                            <h4>Payment Status:</h4>
                        </div>
                        <div class="details-heading-user">
                            <h4>{{user?.payment?.status ? user?.payment?.status : 'N/A'}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="details-heading-user d-flex">
                        <img *ngIf="user.Value == 'Success'" src="../../assets/images/panel_images/s-1.svg" class="user-success mr-1">
						<h4 [class.success]="user.Value === 'Success'">{{user?.users[0]?.name ? user?.users[0]?.name : 'N/A'}}</h4>
                     </div> -->
        <!-- <div class="col-md-7">
            <div class="user-header-details-img-section pt-3 bg-white ml-1">
                <div class="users-img mb-4 d-flex pt-0">
                    <div class="invoice mr-auto">
                        <h4 class="ml-3 mt-3">Invoice</h4>
                    </div>
                    <div class="download-invoice-button mr-4">
                        <button class="btn " type="button"><img src='../../../assets/images/panel_images/d-in.svg' class="mr-2">Download</button>
                    </div>
                </div>
                <hr>
            </div>
        </div> -->
    </div>
</div>