<ng-container *ngIf="
    permissons.cms_Model.analyse ||
      permissons.cms_Model.edit ||
      permissons.cms_Model.view;
    else alternateTemplate
  " class="main">
  <div class="container-fluid bg-white">
    <div class="user-list-header d-flex pt-3 pb-1 ml-2">
      <div class="user-list-heading d-flex">
        <img src="../../assets/images/panel_images/cms-1.svg" class="user-list mr-2" />
        <h6 class="mt-2">CMS Module</h6>
      </div>
    </div>
  </div>
  <div class="container-fluid pt-4 ml-0">
    <div class="d-flex pt-2">
      <div class="card users-count mr-3 total-user-bg">
        <div class="card-body d-flex">
          <img src="../../assets/images/panel_images/total-users.svg" id="users-total" class="mr-2" />
          <div class="card-details-users">
            <h4 class="mt-3">Total Requests</h4>
            <h2 class="mt-2">
              {{ totalRequests ? (totalRequests | records) : 0 }}
            </h2>
          </div>
        </div>
      </div>
      <div class="card users-count total-existing-bg mr-3">
        <div class="card-body d-flex">
          <img src="../../assets/images/panel_images/total-users-1.svg" id="users-total" class="mr-2" />
          <div class="card-details-users">
            <h4 class="mt-3">Approved & Live</h4>
            <h2 class="mt-2">
              {{ approvedRequests ? (approvedRequests | records) : 0 }}
            </h2>
          </div>
        </div>
      </div>
      <div class="card users-count mr-3 total-active-bg">
        <div class="card-body d-flex">
          <img src="../../assets/images/panel_images/sr-2.svg" id="users-total" class="mr-2" />
          <div class="card-details-users">
            <h4 class="mt-3">Pending Approval</h4>
            <h2 class="mt-2">
              {{ pendingRequests ? (pendingRequests | records) : 0 }}
            </h2>
          </div>
        </div>
      </div>
      <div class="card users-count total-non-bg">
        <div class="card-body d-flex">
          <img src="../../assets/images/panel_images/total-users-3.svg" id="users-total" class="mr-2" />
          <div class="card-details-users">
            <h4 class="mt-3">Declined</h4>
            <h2 class="mt-2">
              {{ declinedRequests ? (declinedRequests | records) : 0 }}
            </h2>
          </div>
        </div>
      </div>
    </div>

    <!-- <app-showloader></app-showloader> -->
    <div class="tabs-section">
      <div class="mt-4 ml-0 mb-5 pb-4">
        <mat-tab-group (selectedTabChange)="reviewTabData($event)" [selectedIndex]="tabIndex">
          <mat-tab label="Manage CMS">
            <div *ngIf="permissons?.cms_Model.edit && permissons?.cms_Model.view">
              <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header class="disable_ripple">
                    <div class="d-flex">
                      <div class="push-img">
                        <img src="../../assets/images/panel_images/push.svg" class="mr-3" />
                        <!-- <img src="../../assets/images/panel_images/pray (1).svg" class="mr-3"> -->
                      </div>
                      <div class="push-header mt-1">
                        <h5>Push Notifications</h5>
                        <p *ngIf="
                            resNotifications != '' && resNotifications != null
                          ">
                          Last update made on
                          {{ updatedAt | date : "dd-MM-yyyy" }} by
                          {{ firstName }} {{ lastName }}
                        </p>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="push-nofications-section pt-2">
                    <div class="row event-row">
                      <div class="col-md-8 push-notofications-col">
                        <form [formGroup]="cmsPushNotifications">
                          <div class="d-flex ml-4 mt-2">
                            <div class="push-heading created-details-section">
                              <p>Device:</p>
                            </div>
                            <div class="checkbox-section radio-button-section">
                              <mat-radio-group name="radiobtn2" formControlName="deviceType">
                                <mat-radio-button *ngFor="let btn of Radiooptions"
                                  (change)="pushNotificationsEvent($event)" [disableRipple]="false"
                                  value="{{ btn.key }}" class="example-margin mr-3">{{ btn.value }}</mat-radio-button>
                              </mat-radio-group>
                              <div *ngIf="
                                  updateCmsPushNotificaitons.deviceType.errors
                                    ?.required && pushSubmitted
                                " class="invalid-feedback d-block">
                                Please select
                              </div>
                            </div>
                          </div>
                          <div class="d-flex ml-4 mt-3">
                            <div class="push-heading">
                              <p>Title:</p>
                            </div>
                            <div class="media-email-field mt-0 ml-4 pl-3" style="width: 100%">
                              <mat-form-field>
                                <mat-label>Enter Title</mat-label>
                                <input formControlName="title" appInputRestriction (keydown)="spaceNotAllowed($event)"
                                  matInput />
                              </mat-form-field>
                              <div *ngIf="
                                  updateCmsPushNotificaitons.title.errors
                                    ?.required && pushSubmitted
                                " class="invalid-feedback d-block">
                                Title is required
                              </div>
                              <div *ngIf="
                                  updateCmsPushNotificaitons.title.errors
                                    ?.pattern && pushSubmitted
                                " class="invalid-feedback d-block">
                                Title is required
                              </div>
                            </div>
                          </div>
                          <div class="d-flex ml-4 mt-3">
                            <div class="push-heading">
                              <p>Description:</p>
                            </div>
                            <div class="media-email-field mt-0 ml-4 pl-3" style="width: 100%">
                              <mat-form-field>
                                <mat-label>Write Something...</mat-label>
                                <input matInput appInputRestriction (keydown)="spaceNotAllowed($event)"
                                  formControlName="description" />
                              </mat-form-field>
                              <div *ngIf="
                                  updateCmsPushNotificaitons.description.errors
                                    ?.required && pushSubmitted
                                " class="invalid-feedback d-block">
                                Description is required
                              </div>
                              <!-- <div *ngIf="updateCmsPushNotificaitons.description.errors?.pattern && pushSubmitted" class="invalid-feedback d-block">Description is required</div> -->
                            </div>
                          </div>
                          <div class="d-flex ml-4 mt-3">
                            <div class="push-heading">
                              <p>Destination:</p>
                            </div>
                            <div class="media-email-field mt-0 ml-4 pl-3" style="width: 100%">
                              <mat-form-field>
                                <mat-label>Enter URL</mat-label>
                                <input matInput appInputRestriction (keydown)="spaceNotAllowed($event)"
                                  formControlName="url" />
                              </mat-form-field>
                              <div *ngIf="
                                  updateCmsPushNotificaitons.url?.errors
                                    ?.pattern && pushSubmitted
                                " class="invalid-feedback d-block">
                                Url is invalid
                              </div>
                            </div>
                          </div>
                          <div class="d-flex ml-4 mt-4">
                            <div class="source-heading" style="width: 280px">
                              <p>Upload Image:</p>
                            </div>
                            <div class="upload-img">
                              <input style="display: none" formControlName="file" type="file"
                                accept="image/png, image/jpeg" (change)="
                                  onFileSelectedPushNotifications($event)
                                " #fileInput90 />
                              <button class="file-btn btn" (click)="fileInput90.click()">
                                <img *ngIf="pushnotificationsUrl == null"
                                  src="../../assets/images/panel_images/upload-i.svg" class="i-img" />
                                <img *ngIf="pushnotificationsUrl != null" [src]="pushnotificationsUrl" width="100"
                                  height="100" alt="" class="upload-img-1 img-file" />
                              </button>
                              <p *ngIf="pushnotificationsUrl == null" class="text-centre">
                                Upload Image
                              </p>
                            </div>
                          </div>
                          <div *ngIf="
                              updateCmsPushNotificaitons.file.errors
                                ?.required && pushSubmitted
                            " class="push-invalid invalid-feedback d-block">
                            File is required
                          </div>
                          <div class="d-flex ml-4 mt-3">
                            <div class="push-heading">
                              <p>When to trigger:</p>
                            </div>
                            <div class="d-flex" style="width: 100%">
                              <div class="media-email-field mt-0 ml-md-4 pl-3" style="width: 30%">
                                <mat-form-field class="example-full-width">
                                  <input matInput [matDatepicker]="picker5" formControlName="date" [min]="todayDate"
                                    (dateChange)="
                                      selectDatePushNotications($event)
                                    " readonly="readonly" placeholder="Select Date" />
                                  <mat-datepicker-toggle matSuffix [for]="picker5">
                                    <mat-icon matDatepickerToggleIcon>
                                      <img src="../../assets/images/panel_images/cal-0.svg" class="calendra-img" />
                                    </mat-icon>
                                  </mat-datepicker-toggle>
                                  <mat-datepicker #picker5></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="
                                    updateCmsPushNotificaitons.date.errors
                                      ?.required && pushSubmitted
                                  " class="invalid-feedback d-block">
                                  Date is required
                                </div>
                              </div>
                              <div class="media-email-field mt-3 ml-md-3" style="width: 100%">
                                <div class="d-flex push_hour">
                                  <!-- <input [ngxTimepicker]="toggleTimepicker1" formControlName="time" [value]="currTime1"
                                    [format]="12" placeholder="Select Time" [disableClick]="true" readonly />
                                  <ngx-material-timepicker-toggle [for]="toggleTimepicker1">
                                    <img src="../../assets/images/panel_images/time.png" class="time-img"
                                      ngxMaterialTimepickerToggleIcon />
                                  </ngx-material-timepicker-toggle>
                                  <ngx-material-timepicker #toggleTimepicker1 (timeChanged)="selectpushTime($event)"
                                    (timeSet)="selectPushHour($event)"
                                    (opened)="updateTime(toggleTimepicker1)"></ngx-material-timepicker> -->
                                  <!-- <select (change)="pushHourEvent($event)">
                                       <option>select</option>
                                       <option  *ngFor="let hours of hours" [value]="hours">{{hours}}</option>
                                       
                                    </select>
                                    <select (change)="pushMInutesEvent($event)">
                                       <option>select</option>
                                       <option *ngFor="let minutes of minutes">{{minutes}}</option>
                                       
                                    </select>
                                    <select (change)="pushAmEvent($event)">
                                       <option>select</option>
                                       <option *ngFor="let am of am">{{am}}</option>
                                       
                                    </select> -->
                                  <!-- <input type="date" id="date" (change)="onchangeEventPush($event)"><br><br> -->
                                  <div>
                                    <select id="hour" [(ngModel)]="pushEventHour" formControlName="hour" (change)="pushHourEvent($event)">
                                      <option value='' selected disabled hidden>Hour</option>
                                      <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
                                    </select>
                                    <div *ngIf="
                                    updateCmsPushNotificaitons.hour.errors
                                      ?.required && pushSubmitted
                                  " class="invalid-feedback d-block">
                                      Hour is required
                                    </div>
                                  </div>
                                  <div >
                                    <select id="minute" [(ngModel)]="pushEventMin" formControlName="minute" (change)="pushMInutesEvent($event)">
                                      <option value='' selected disabled hidden>Minutes</option>
                                      <option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</option>
                                    </select>
                                    <div *ngIf="
                                    updateCmsPushNotificaitons.minute.errors
                                      ?.required && pushSubmitted
                                  " class="invalid-feedback d-block">
                                      Minute is required
                                    </div>
                                  </div>
                                  <div>
                                    <select id="period" [(ngModel)]="pushEventAM" formControlName="AM" (change)="pushAmEvent($event)">
                                      <option value='' selected disabled hidden>AM/PM</option>
                                      <option value="AM">AM</option>
                                      <option value="PM">PM</option>
                                    </select>
                                    <div *ngIf="
                                    updateCmsPushNotificaitons.AM.errors
                                      ?.required && pushSubmitted
                                  " class="invalid-feedback d-block">
                                      Time is required
                                    </div>
                                  </div>

                                </div>
                                <!-- <div *ngIf="
                                    updateCmsPushNotificaitons.time.errors
                                      ?.required && pushSubmitted
                                  " class="invalid-feedback d-block">
                                  Time is required
                                </div> -->
                                <div *ngIf="dailPromiseTimeErr" class="invalid-feedback d-block">
                                  {{ dailPromiseTimeErr }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="button-section-tv-programs ml-4 pl-2 mt-5 pb-4">
                            <button type="button" class="btn create-btn" [disabled]="pushbtndisabled" (click)="
                                !dailPromiseTimeErr && CreatePushNotifications()
                              ">
                              Create
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <!-- <div lass="push-nofications-section pt-2" *ngIf="!permissons?.cms_Model.edit
                                || !permissons?.cms_Model.view">
                                    You don't have permission to access this feature.
                                </div> -->
                  <app-showloader *ngIf="Pushloader"></app-showloader>
                </mat-expansion-panel>
              </mat-accordion>
              <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header class="disable_ripple">
                    <div class="d-flex">
                      <div class="push-img">
                        <img src="../../assets/images/panel_images/media.svg" class="mr-3" />
                      </div>
                      <div class="push-header mt-1">
                        <h5>Media</h5>
                        <p>
                          Last update made on
                          {{ updatedAtMedia | date : "dd-MM-yyyy" }} by
                          {{ mediaFirstName }} {{ mediaLastNameAdmin }}
                        </p>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="video-section ml-3 pl-2">
                    <div class="radio-button-section ml-4 pl-3">
                      <mat-tab-group #tabGroup [selectedIndex]="tabSelectedIndex"
                        (selectedTabChange)="tabChanged($event)">
                        <mat-tab [disableRipple]="true">
                          <ng-template mat-tab-label>
                            <mat-radio-group class="disable_ripple" [selectedIndex]="tabSelectedIndex">
                              <mat-radio-button class="disable_ripple" [checked]="video" value="mobile"
                                name="role">Video</mat-radio-button>
                            </mat-radio-group>
                          </ng-template>
                          <form [formGroup]="cmsVideoForm">
                            <div class="row">
                              <div class="col-md-8">
                                <div class="d-flex ml-4 mt-3">
                                  <div class="push-heading" style="width: 250px">
                                    <p>Title:</p>
                                  </div>
                                  <div class="media-email-field mt-0 ml-4 pl-3" style="width: 100%">
                                    <mat-form-field>
                                      <mat-label>Enter Title</mat-label>
                                      <input matInput appInputRestriction (keydown)="spaceNotAllowed($event)"
                                        formControlName="title" />
                                    </mat-form-field>
                                    <div *ngIf="
                                        updatecmsVideo.title.errors?.required &&
                                        submitted
                                      " class="invalid-feedback d-block">
                                      Title is required
                                    </div>
                                    <!-- <div *ngIf="updatecmsVideo.title.errors?.pattern && submitted" class="invalid-feedback d-block">Title is required</div> -->
                                  </div>
                                </div>
                                <div class="d-flex ml-4 mt-3">
                                  <div class="push-heading" style="width: 250px">
                                    <p>Description:</p>
                                  </div>
                                  <div class="media-email-field mt-0 ml-4 pl-3" style="width: 100%">
                                    <mat-form-field>
                                      <app-showloader *ngIf="mediaLoader"></app-showloader>

                                      <mat-label>Write something...</mat-label>
                                      <input matInput appInputRestriction (keydown)="spaceNotAllowed($event)"
                                        formControlName="description" />
                                    </mat-form-field>
                                    <div *ngIf="
                                        updatecmsVideo.description.errors
                                          ?.required && submitted
                                      " class="invalid-feedback d-block">
                                      Description is required
                                    </div>
                                    <!-- <div *ngIf="updatecmsVideo.description.errors?.pattern && submitted" class="invalid-feedback d-block"> Description is required.</div> -->
                                  </div>
                                </div>
                                <div class="d-flex ml-4 mt-3">
                                  <div class="source-heading" style="width: 309px">
                                    <p>Upload Thumbnail:</p>
                                  </div>
                                  <div class="upload-img">
                                    <input style="display: none" formControlName="thumbnail" type="file"
                                      accept="image/png, image/jpeg" (change)="onFileSelected($event)" #fileInput />
                                    <button class="file-btn btn" (click)="fileInput.click()">
                                      <img *ngIf="url == null" src="../../assets/images/panel_images/upload-i.svg"
                                        class="i-img" />
                                      <img *ngIf="url != null" [src]="url" width="100" height="100" alt=""
                                        class="upload-img-1 img-file" />
                                    </button>
                                    <p *ngIf="url == null" class="text-centre">
                                      Upload Image
                                    </p>
                                  </div>
                                </div>
                                <div class="d-flex ml-4 mt-3">
                                  <div class="source-heading" style="width: 250px">
                                    <p>Source:</p>
                                  </div>
                                  <div class="media-email-field mt-0 ml-4 pl-3" style="width: 100%">
                                    <mat-form-field>
                                      <mat-label>link</mat-label>
                                      <input matInput appInputRestriction (keydown)="spaceNotAllowed($event)"
                                        formControlName="youtubeVideo" />
                                    </mat-form-field>
                                    <div *ngIf="
                                        updatecmsVideo.youtubeVideo.errors
                                          ?.required && submitted
                                      " class="invalid-feedback d-block">
                                      YoutubeLink is required
                                    </div>
                                    <div *ngIf="
                                        updatecmsVideo.youtubeVideo.errors
                                          ?.pattern && submitted
                                      " class="invalid-feedback d-block">
                                      Please enter valid youtube link.
                                    </div>
                                  </div>
                                </div>
                                <div class="d-flex ml-4 mt-3">
                                  <div class="source-heading" style="width: 250px">
                                    <p>Created by:</p>
                                  </div>
                                  <div class="media-email-field mt-0 ml-4 pl-3" style="width: 100%">
                                    <mat-form-field>
                                      <mat-label>Created by</mat-label>
                                      <input matInput formControlName="speakerName" [(ngModel)]="name"
                                        [readonly]="readonly" />
                                    </mat-form-field>
                                    <div *ngIf="
                                        updatecmsVideo.speakerName.errors
                                          ?.required && submitted
                                      " class="invalid-feedback d-block">
                                      Created by is required
                                    </div>
                                  </div>
                                </div>
                                <div class="d-flex ml-4 mt-3">
                                  <div class="source-heading" style="width: 250px">
                                    <p>Category:</p>
                                  </div>
                                  <div class="Email-field mt-0 ml-4 pl-3" style="width: 100%">
                                    <mat-form-field>
                                      <mat-label>Category</mat-label>
                                      <mat-select formControlName="categoryId">
                                        <mat-option *ngFor="
                                            let category of cmsVideoCategory
                                          " [value]="category._id">{{ category.name }}</mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="
                                        updatecmsVideo.categoryId.errors
                                          ?.required && submitted
                                      " class="invalid-feedback d-block">
                                      Please Select Category
                                    </div>
                                  </div>
                                </div>
                                <div class="button-section-tv-programs ml-4 pl-2 mt-5 pb-4">
                                  <button type="button" class="btn create-btn" (click)="createVideo()">
                                    Create
                                  </button>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <!-- <div class="preview pb-3"><p>Preview</p></div><div class="img-section mt-0 pb-5 mb-5" ><img src="../../assets/images/panel_images/preview.svg" style='max-width:75%;'></div><div class="preview-section-view"><h3>PREVIEW</h3></div> -->
                              </div>
                            </div>
                          </form>
                        </mat-tab>
                        <mat-tab [disableRipple]="true">
                          <ng-template mat-tab-label>
                            <mat-radio-group [selectedIndex]="tabSelectedIndex">
                              <mat-radio-button [checked]="Daily" value="bulk" name="role">Daily
                                Promise</mat-radio-button>
                            </mat-radio-group>
                          </ng-template>
                          <div class="row event-row">
                            <div class="col-md-8">
                              <form [formGroup]="cmsPromiseForm">
                                <app-showloader *ngIf="promiseLoader"></app-showloader>

                                <div class="d-flex ml-4 mt-3">
                                  <div class="source-heading" style="width: 309px">
                                    <p>Upload Thumbnail:</p>
                                  </div>
                                  <div class="upload-img">
                                    <input style="display: none" type="file" formControlName="image"
                                      accept="image/png, image/jpeg" (change)="
                                        onFileSelectedPromiseCard($event)
                                      " #fileInput1 />
                                    <button class="file-btn btn" (click)="fileInput1.click()">
                                      <img *ngIf="promiseCardUrl == null"
                                        src="../../assets/images/panel_images/upload-i.svg" class="i-img" />
                                      <img *ngIf="promiseCardUrl != null" [src]="promiseCardUrl" width="100"
                                        height="100" alt="" class="upload-img-1 img-file" />
                                    </button>
                                    <p *ngIf="promiseCardUrl == null" class="text-centre">
                                      Upload Image
                                    </p>
                                  </div>
                                </div>

                                <div *ngIf="
                                    updatecmsPromiseCard.image.errors
                                      ?.required && promisesubmitted
                                  " class="invalid-feedback p-invalid d-block">
                                  Promise card Image is Required
                                </div>
                                <!-- <div class="d-flex ml-4 mt-3">
                                  <div class="source-heading" style="width: 250px">
                                    <p>Date</p>
                                  </div>
                                  <div class="media-email-field mt-0 ml-4 pl-3" style="width: 100%">
                                    <mat-form-field class="example-full-width">
                                      <input matInput [matDatepicker]="picker7" [min]="todayDate"
                                        (dateChange)="selectDate($event)" formControlName="date" readonly="readonly"
                                        placeholder="Select" />
                                      <mat-datepicker-toggle matSuffix [for]="picker7">
                                        <mat-icon matDatepickerToggleIcon>
                                          <img src="../../assets/images/panel_images/cal-0.svg" class="calendra-img" />
                                        </mat-icon>
                                      </mat-datepicker-toggle>
                                      <mat-datepicker #picker7 type="datetime"></mat-datepicker>
                                    </mat-form-field>
                                    <div *ngIf="
                                        updatecmsPromiseCard.date.errors
                                          ?.required && promisesubmitted
                                      " class="invalid-feedback d-block">
                                      Date is required
                                    </div>
                                  </div>
                                </div>
                                <div class="d-flex ml-4 mt-3">
                                  <div class="source-heading" style="width: 250px">
                                    <p>Time</p>
                                  </div>
                                  <div class="Email-field mt-2 ml-4 pl-3" style="width: 100%">
                                    <div class="toggle-example d-flex">
                                      <input [ngxTimepicker]="toggleTimepicker" formControlName="time"
                                        [value]="currTime" [format]="24" placeholder="Select" [disableClick]="true"
                                        readonly />
                                      <ngx-material-timepicker-toggle [for]="toggleTimepicker">
                                        <img src="../../assets/images/panel_images/time.png" class="time-img"
                                          ngxMaterialTimepickerToggleIcon />
                                      </ngx-material-timepicker-toggle>
                                      <ngx-material-timepicker #toggleTimepicker (timeChanged)="onChangeHour($event)"
                                        (timeSet)="selectHour($event)"
                                        (opened)="updateTime(toggleTimepicker)"></ngx-material-timepicker>
                                    </div>
                                    <div *ngIf="
                                        updatecmsPromiseCard.time.errors
                                          ?.required && promisesubmitted
                                      " class="invalid-feedback d-block">
                                      Time is required
                                    </div>
                                    <div *ngIf="dailPromiseTimeErr" class="invalid-feedback d-block">
                                      {{ dailPromiseTimeErr }}
                                    </div>
                                  </div>
                                </div> -->
                                <div class="d-flex ml-4 mt-3">
                                  <div class="source-heading" style="width: 250px">
                                    <p>Date :</p>
                                  </div>
                                  <div class="media-email-field mt-0 ml-md-4 pl-3" style="width: 30%">
                                    <mat-form-field class="example-full-width">
                                      <input matInput [matDatepicker]="picker7" formControlName="date" [min]="todayDate"
                                        (dateChange)="
                                          selectDatePushNotications($event)
                                        " readonly="readonly" placeholder="Select Date" />
                                      <mat-datepicker-toggle matSuffix [for]="picker7">
                                        <mat-icon matDatepickerToggleIcon>
                                          <img src="../../assets/images/panel_images/cal-0.svg" class="calendra-img" />
                                        </mat-icon>
                                      </mat-datepicker-toggle>
                                      <mat-datepicker #picker7></mat-datepicker>
                                    </mat-form-field>
                                    <div *ngIf="
                                    updatecmsPromiseCard.date.errors
                                          ?.required && promisesubmitted
                                      " class="invalid-feedback d-block">
                                      Date is required
                                    </div>
                                  </div>
                                  <div class="media-email-field mt-3 ml-md-3" style="width: 100%">
                                    <div class="d-flex push_hour">
                                      <!-- <input [ngxTimepicker]="toggleTimepicker1" formControlName="time" [value]="currTime1"
                                        [format]="12" placeholder="Select Time" [disableClick]="true" readonly />
                                      <ngx-material-timepicker-toggle [for]="toggleTimepicker1">
                                        <img src="../../assets/images/panel_images/time.png" class="time-img"
                                          ngxMaterialTimepickerToggleIcon />
                                      </ngx-material-timepicker-toggle>
                                      <ngx-material-timepicker #toggleTimepicker1 (timeChanged)="selectpushTime($event)"
                                        (timeSet)="selectPushHour($event)"
                                        (opened)="updateTime(toggleTimepicker1)"></ngx-material-timepicker> -->
                                      <!-- <select (change)="pushHourEvent($event)">
                                           <option>select</option>
                                           <option  *ngFor="let hours of hours" [value]="hours">{{hours}}</option>
                                           
                                        </select>
                                        <select (change)="pushMInutesEvent($event)">
                                           <option>select</option>
                                           <option *ngFor="let minutes of minutes">{{minutes}}</option>
                                           
                                        </select>
                                        <select (change)="pushAmEvent($event)">
                                           <option>select</option>
                                           <option *ngFor="let am of am">{{am}}</option>
                                           
                                        </select> -->
                                      <!-- <input type="date" id="date" (change)="onchangeEventPush($event)"><br><br> -->
                                      <div>
                                        <select id="hour" [(ngModel)]="pushEventHour" formControlName="hour" (change)="pushHourEvent($event)">
                                          <option value='' selected disabled hidden>Hour</option>
                                          <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
                                        </select>
                                        <div *ngIf="
                                        updatecmsPromiseCard.hour.errors
                                          ?.required && promisesubmitted
                                      " class="invalid-feedback d-block">
                                          Hour is required
                                        </div>
                                      </div>
                                      <div >
                                        <select id="minute" [(ngModel)]="pushEventMin" formControlName="minute" (change)="pushMInutesEvent($event)">
                                          <option value='' selected disabled hidden>Minutes</option>
                                          <option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</option>
                                        </select>
                                        <div *ngIf="
                                        updatecmsPromiseCard.minute.errors
                                          ?.required && promisesubmitted
                                      " class="invalid-feedback d-block">
                                          Minute is required
                                        </div>
                                      </div>
                                      <div>
                                        <select id="period" [(ngModel)]="pushEventAM" formControlName="AM" (change)="pushAmEvent($event)">
                                          <option value='' selected disabled hidden>AM/PM</option>
                                          <option value="AM">AM</option>
                                          <option value="PM">PM</option>
                                        </select>
                                        <div *ngIf="
                                        updatecmsPromiseCard.AM.errors
                                          ?.required && promisesubmitted
                                      " class="invalid-feedback d-block">
                                          Time is required
                                        </div>
                                      </div>
                                    </div>
                                    <!-- <div *ngIf="
                                        updateCmsPushNotificaitons.time.errors
                                          ?.required && pushSubmitted
                                      " class="invalid-feedback d-block">
                                      Time is required
                                    </div> -->
                                    <div *ngIf="dailPromiseTimeErr" class="invalid-feedback d-block">
                                      {{ dailPromiseTimeErr }}
                                    </div>
                                  </div>
                                </div>
                                <div class="d-flex ml-4 mt-3">
                                  <div class="source-heading" style="width: 250px">
                                    <p>Youtube Link</p>
                                  </div>
                                  <div class="Email-field mt-2 ml-4 pl-3" style="width: 100%">
                                    <div class="toggle-example d-flex">
                                      <input formControlName="youtubeLink" [value]="" placeholder="Video Link" />
                                    </div>
                                    <div *ngIf="
                                    updatecmsPromiseCard.youtubeLink.errors
                                          ?.pattern && promisesubmitted
                                      " class="invalid-feedback d-block">
                                      Please enter valid youtube link.
                                    </div>
                                    <!-- <mat-form-field>
                                      <mat-label>Enter Title</mat-label>
                                      <input matInput appInputRestriction (keydown)="spaceNotAllowed($event)"
                                        formControlName="title" />
                                    </mat-form-field> -->
                                  </div>
                                </div>
                              </form>
                              <div class="button-section-tv-programs ml-4 pl-2 mt-5 pb-4">
                                <button type="button" class="btn create-btn" (click)="
                                    !dailPromiseTimeErr && createPromiseCard()
                                  ">
                                  Create
                                </button>
                              </div>
                            </div>
                            <div class="col-md-4"></div>
                          </div>
                        </mat-tab>
                        <mat-tab [disableRipple]="true">
                          <ng-template mat-tab-label>
                            <mat-radio-group [selectedIndex]="tabSelectedIndex">
                              <mat-radio-button [checked]="Event" value="bulk" name="role">Event</mat-radio-button>
                            </mat-radio-group>
                          </ng-template>
                          <div class="row event-row">
                            <div class="col-md-8">
                              <form [formGroup]="cmsEventForm">
                                <div class="d-flex ml-4">
                                  <div class="source-heading" style="width: 309px">
                                    <app-showloader *ngIf="eventLoader"></app-showloader>
                                    <p>Upload Thumbnail:</p>
                                  </div>
                                  <div class="upload-img">
                                    <input style="display: none" type="file" formControlName="videoFile"
                                      accept="image/*" (change)="onFileSelectedEvent($event)" #fileInput2 />
                                    <button class="file-btn btn" (click)="fileInput2.click()">
                                      <img *ngIf="EventUrl == null" src="../../assets/images/panel_images/upload-i.svg"
                                        class="i-img" />
                                      <img *ngIf="format === 'image' && EventUrl" accept="image/*" [src]="EventUrl"
                                        width="100" height="100" alt="" class="upload-img-1 img-file" />
                                      <!-- <video [src]="url" *ngIf="format==='video' && url" style="width: 100%;
                                     height: auto;" controls></video> -->
                                    </button>
                                    <p *ngIf="EventUrl == null" class="text-centre">
                                      Upload Image
                                    </p>
                                  </div>
                                </div>
                                <div *ngIf="
                                    updatecmsEvent.videoFile.errors?.required &&
                                    eventSubmitted
                                  " class="invalid-feedback p-invalid d-block">
                                  Event Image is required
                                </div>
                                <div class="d-flex ml-4 mt-3">
                                  <div class="source-heading" style="width: 250px">
                                    <p>Event Start Date</p>
                                  </div>
                                  <!-- <div class="media-email-field mt-0 ml-4 pl-3" style="width: 100%">
                                    <mat-form-field class="example-full-width">
                                      <input matInput [matDatepicker]="picker6" [min]="todayDate" [max]="maxDate"
                                        readonly="readonly" (dateChange)="selectDateEvent($event)"
                                        formControlName="nextLaunchingVideo" placeholder="Select Start Date" />
                                      <mat-datepicker-toggle matSuffix [for]="picker6">
                                        <mat-icon matDatepickerToggleIcon>
                                          <img src="../../assets/images/panel_images/cal-0.svg" class="calendra-img" />
                                        </mat-icon>
                                      </mat-datepicker-toggle>
                                      <mat-datepicker #picker6></mat-datepicker>
                                    </mat-form-field>
                                    <div *ngIf="
                                        updatecmsEvent.nextLaunchingVideo.errors
                                          ?.required && eventSubmitted
                                      " class="invalid-feedback d-block">
                                      Date is required
                                    </div>
                                  </div> -->
                                
                                  <div class="media-email-field mt-0 ml-md-4 pl-3" style="width: 100%">
                                    <mat-form-field class="example-full-width">
                                      <input matInput [matDatepicker]="picker6" formControlName="startDate" [min]="todayDate"
                                        (dateChange)="
                                        selectStartDateEventNotications($event)
                                        " readonly="readonly" placeholder="Select Date" />
                                      <mat-datepicker-toggle matSuffix [for]="picker6">
                                        <mat-icon matDatepickerToggleIcon>
                                          <img src="../../assets/images/panel_images/cal-0.svg" class="calendra-img" />
                                        </mat-icon>
                                      </mat-datepicker-toggle>
                                      <mat-datepicker #picker6></mat-datepicker>
                                    </mat-form-field>
                                    <div *ngIf="
                                    updatecmsEvent.startDate.errors
                                          ?.required && eventSubmitted
                                      " class="invalid-feedback d-block">
                                      Start Date is required
                                    </div>
                                  </div>
                                </div>
                                <div class="d-flex ml-4 mt-3">
                                  <div class="source-heading" style="width: 250px">
                                    <p>Event Start Time</p>
                                  </div>
                                  <!-- <div class="Email-field mt-2 ml-4 pl-3" style="width: 100%">
                                    <div class="toggle-example d-flex">
                                      <input [ngxTimepicker]="toggleTimepicker2" formControlName="time"
                                        placeholder="Select Start Time" [min]="currentTime" [max]="maxTime"
                                        [format]="24" [disableClick]="true" readonly />
                                      <ngx-material-timepicker-toggle [for]="toggleTimepicker2">
                                        <img src="../../assets/images/panel_images/time.png" class="time-img"
                                          ngxMaterialTimepickerToggleIcon />
                                      </ngx-material-timepicker-toggle>
                                      <ngx-material-timepicker #toggleTimepicker2
                                        (timeChanged)="selectTimeEvent($event)"
                                        (timeSet)="selectTime($event)"></ngx-material-timepicker>
                                    </div>
                                    <div *ngIf="
                                        updatecmsEvent.time.errors?.required &&
                                        eventSubmitted
                                      " class="invalid-feedback d-block">
                                      Time is required
                                    </div>
                                    <div *ngIf="eventTimeErr" class="invalid-feedback d-block">
                                      {{ eventTimeErr }}
                                    </div>
                                  </div> -->
                                  
                                  <div class="media-email-field mt-3 ml-md-3" style="width: 100%">
                                    <div class="d-flex push_hour">
                                      <div class="ml-4">
                                        <select id="hour" [(ngModel)]="pushStartEventHour" formControlName="startHour" (change)="pushStartHourEvent($event)">
                                          <option value='' selected disabled hidden>Hour</option>
                                          <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
                                        </select>
                                        <div *ngIf="
                                        updatecmsEvent.startHour.errors
                                          ?.required && eventSubmitted
                                      " class="invalid-feedback d-block">
                                          Start Hour is required
                                        </div>
                                      </div>
                                      <div >
                                        <select id="minute" [(ngModel)]="pushStartEventMin" formControlName="startMinutes" (change)="pushStartMInutesEvent($event)">
                                          <option value='' selected disabled hidden>Minutes</option>
                                          <option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</option>
                                        </select>
                                        <div *ngIf="
                                        updatecmsEvent.startMinutes.errors
                                          ?.required && eventSubmitted
                                      " class="invalid-feedback d-block">
                                      Start Minutes is required
                                        </div>
                                      </div>
                                      <div>
                                        <select id="period" [(ngModel)]="pushStartEventAM" formControlName="startAM" (change)="pushStartAmEvent($event)">
                                          <option value='' selected disabled hidden>AM/PM</option>
                                          <option value="AM">AM</option>
                                          <option value="PM">PM</option>
                                        </select>
                                        <div *ngIf="
                                        updatecmsEvent.startAM.errors
                                          ?.required && eventSubmitted
                                      " class="invalid-feedback d-block ml-3">
                                           Start Time is required
                                        </div>
                                      </div>
                                    </div>
                                    <!-- <div *ngIf="
                                        updateCmsPushNotificaitons.time.errors
                                          ?.required && pushSubmitted
                                      " class="invalid-feedback d-block">
                                      Time is required
                                    </div> -->
                                    <div *ngIf="eventsTimeErr" class="invalid-feedback d-block">
                                      {{ eventsTimeErr }}
                                    </div>
                                  </div>
                                </div>
                                <!-- <div class="d-flex ml-4 mt-3">
                                  <div class="source-heading" style="width: 250px">
                                    <p>Event End Date</p>
                                  </div>
                                  <div class="media-email-field mt-0 ml-4 pl-3" style="width: 100%">
                                    <mat-form-field class="example-full-width">
                                      <input matInput [matDatepicker]="picker10" [min]="minDate" readonly="readonly"
                                        (dateChange)="
                                          selectEndDateEvent($event)
                                        " formControlName="nextLaunchingVideo" placeholder="Select End Date" />
                                      <mat-datepicker-toggle matSuffix [for]="picker10">
                                        <mat-icon matDatepickerToggleIcon>
                                          <img src="../../assets/images/panel_images/cal-0.svg" class="calendra-img" />
                                        </mat-icon>
                                      </mat-datepicker-toggle>
                                      <mat-datepicker #picker10></mat-datepicker>
                                    </mat-form-field>
                                    <div *ngIf="
                                        updatecmsEvent.nextLaunchingVideo.errors
                                          ?.required && eventSubmitted
                                      " class="invalid-feedback d-block">
                                      Date is required
                                    </div>
                                  </div>
                                </div>
                                <div class="d-flex ml-4 mt-3">
                                  <div class="source-heading" style="width: 250px">
                                    <p>Event End Time</p>
                                  </div>
                                  <div class="Email-field mt-2 ml-4 pl-3" style="width: 100%">
                                    <div class="toggle-example d-flex">
                                      <input [ngxTimepicker]="toggleTimepicker3" formControlName="time"
                                        placeholder="Select End Time" [min]="minTime" [format]="24"
                                        [disableClick]="true" readonly />
                                      <ngx-material-timepicker-toggle [for]="toggleTimepicker3">
                                        <img src="../../assets/images/panel_images/time.png" class="time-img"
                                          ngxMaterialTimepickerToggleIcon />
                                      </ngx-material-timepicker-toggle>
                                      <ngx-material-timepicker #toggleTimepicker3 (timeChanged)="
                                          selectEndTimeEvent($event)
                                        " (timeSet)="selectEndTime($event)"></ngx-material-timepicker>
                                    </div>
                                    <div *ngIf="
                                        updatecmsEvent.time.errors?.required &&
                                        eventSubmitted
                                      " class="invalid-feedback d-block">
                                      Time is required
                                    </div>
                                    <div *ngIf="eventSameError != null " class="invalid-feedback d-block">
                                      {{ eventSameError }}
                                    </div>
                                  </div>
                                </div> -->
                                
                                <div class="d-flex ml-4 mt-3">
                                  <div class="source-heading" style="width: 250px">
                                    <p>Event End Date</p>
                                  </div>
                                  <!-- <div class="media-email-field mt-0 ml-4 pl-3" style="width: 100%">
                                    <mat-form-field class="example-full-width">
                                      <input matInput [matDatepicker]="picker6" [min]="todayDate" [max]="maxDate"
                                        readonly="readonly" (dateChange)="selectDateEvent($event)"
                                        formControlName="nextLaunchingVideo" placeholder="Select Start Date" />
                                      <mat-datepicker-toggle matSuffix [for]="picker6">
                                        <mat-icon matDatepickerToggleIcon>
                                          <img src="../../assets/images/panel_images/cal-0.svg" class="calendra-img" />
                                        </mat-icon>
                                      </mat-datepicker-toggle>
                                      <mat-datepicker #picker6></mat-datepicker>
                                    </mat-form-field>
                                    <div *ngIf="
                                        updatecmsEvent.nextLaunchingVideo.errors
                                          ?.required && eventSubmitted
                                      " class="invalid-feedback d-block">
                                      Date is required
                                    </div>
                                  </div> -->
                                
                                  <div class="media-email-field mt-0 ml-md-4 pl-3" style="width: 100%">
                                    <mat-form-field class="example-full-width">
                                      <input matInput [matDatepicker]="picker10" formControlName="endDate" [min]="todayDate"
                                        (dateChange)="
                                          selectEndDateEventNotications($event)
                                        " readonly="readonly" placeholder="Select Date" />
                                      <mat-datepicker-toggle matSuffix [for]="picker10">
                                        <mat-icon matDatepickerToggleIcon>
                                          <img src="../../assets/images/panel_images/cal-0.svg" class="calendra-img" />
                                        </mat-icon>
                                      </mat-datepicker-toggle>
                                      <mat-datepicker #picker10></mat-datepicker>
                                    </mat-form-field>
                                    <div *ngIf="
                                    updatecmsEvent.endDate.errors
                                          ?.required && eventSubmitted
                                      " class="invalid-feedback d-block">
                                      End Date is required
                                    </div>
                                  </div>
                                </div>
                                <div class="d-flex ml-4 mt-3">
                                  <div class="source-heading" style="width: 250px">
                                    <p>Event End Time</p>
                                  </div>
                                  <div class="media-email-field mt-3 ml-md-3" style="width: 100%">
                                    <div class="d-flex push_hour">
                                      <div class="ml-4">
                                        <select id="hour" [(ngModel)]="pushEndEventHour" formControlName="endHour" (change)="pushEndHourEvent($event)">
                                          <option value='' selected disabled hidden>Hour</option>
                                          <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
                                        </select>
                                        <div *ngIf="
                                        updatecmsEvent.endHour.errors
                                          ?.required && eventSubmitted
                                      " class="invalid-feedback d-block">
                                         End Hour is required
                                        </div>
                                      </div>
                                      <div >
                                        <select id="minute" [(ngModel)]="pushEndEventMin" formControlName="endMinutes" (change)="pushEndMInutesEvent($event)">
                                          <option value='' selected disabled hidden>Minutes</option>
                                          <option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</option>
                                        </select>
                                        <div *ngIf="
                                        updatecmsEvent.endMinutes.errors
                                          ?.required && eventSubmitted
                                      " class="invalid-feedback d-block">
                                          End Minute is required
                                        </div>
                                      </div>
                                      <div>
                                        <select id="period" [(ngModel)]="pushEndEventAM" formControlName="endAM" (change)="pushEndAmEvent($event)">
                                          <option value='' selected disabled hidden>AM/PM</option>
                                          <option value="AM">AM</option>
                                          <option value="PM">PM</option>
                                        </select>
                                        <div *ngIf="
                                        updatecmsEvent.endAM.errors
                                          ?.required && eventSubmitted
                                      " class="invalid-feedback d-block">
                                         End Time is required
                                        </div>
                                      </div>
                                    </div>
                                    <!-- <div *ngIf="
                                        updateCmsPushNotificaitons.time.errors
                                          ?.required && pushSubmitted
                                      " class="invalid-feedback d-block">
                                      Time is required
                                    </div> -->
                                    <div *ngIf="eventsEndTimeErr" class="invalid-feedback d-block">
                                      {{ eventsEndTimeErr }}
                                    </div>
                                  </div>
                                </div>
                                <div class="d-flex ml-4 mt-3">
                                  <div class="source-heading" style="width: 250px">
                                    <p>Link :</p>
                                  </div>
                                  <div class="media-email-field mt-0 ml-4 pl-3" style="width: 100%">
                                    <mat-form-field>
                                      <mat-label>Link</mat-label>
                                      <input matInput formControlName="youtubeVideoLink" />
                                    </mat-form-field>
                                    <!-- <div
                                      *ngIf="
                                        updatecmsEvent.youtubeVideoLink.errors
                                          ?.required && eventSubmitted
                                      "
                                      class="invalid-feedback d-block"
                                    >
                                      Link is required
                                    </div> -->
                                    <div *ngIf="
                                        updatecmsEvent.youtubeVideoLink.errors
                                          ?.pattern && eventSubmitted
                                      " class="invalid-feedback d-block">
                                      Please enter valid youtube link.
                                    </div>
                                  </div>
                                </div>
                              </form>
                              <div class="button-section-tv-programs ml-4 pl-2 mt-5 pb-4">
                                <button type="button" class="btn create-btn"
                                  (click)="!eventsEndTimeErr && createEventCard()">
                                  Create
                                </button>
                              </div>
                            </div>
                            <div class="col-md-4"></div>
                          </div>
                        </mat-tab>
                      </mat-tab-group>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header class="disable_ripple">
                    <div class="d-flex">
                      <div class="push-img">
                        <img src="../../assets/images/panel_images/tv-1-p.svg" class="mr-3" />
                      </div>
                      <div class="push-header mt-1">
                        <h5>TV Programs</h5>
                        <p *ngIf="
                            updatedAtTvprogram != '' &&
                            updatedAtTvprogram != null
                          ">
                          Last update made on
                          {{ updatedAtTvprogram | date : "dd-MM-yyyy" }} by
                          {{ firstNameTvprogram }} {{ lastNameAdminTvprogram }}
                        </p>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="tv-programs-panel ml-5">
                    <div class="header-tv">
                      <app-showloader *ngIf="tvProgramLoader"></app-showloader>
                      <h4 class="pt-0">TV Programs Data Update Request</h4>
                      <h5 class="pt-3 ml-4 pl-3">What are the modifications</h5>
                    </div>
                    <form [formGroup]="cmsTvProgramForm">
                      <div class="Email-field mt-0 ml-4 pl-3">
                        <mat-form-field>
                          <mat-label>Modifications</mat-label>
                          <input matInput formControlName="modification" appInputRestriction />
                        </mat-form-field>
                        <div *ngIf="
                            updatecmsTvPrograms.modification.errors?.required &&
                            submitTvPrograms
                          " class="invalid-feedback d-block ml-0">
                          Modification value is required
                        </div>
                      </div>
                      <div class="pt-3 pl-4 ml-2" *ngIf="uploadFlie">
                        <input style="display: none" formControlName="file"
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          (change)="onFileSelectedTvProgram($event)" #fileInput type="file" />
                        <button class="btn-upload" (click)="fileInput.click()">
                          <img src="../../assets/images/panel_images/upload.svg" class="mr-2" />Upload CSV or Excel file
                        </button>
                        <div *ngIf="
                            updatecmsTvPrograms.file.errors?.required &&
                            submitTvPrograms
                          " class="invalid-feedback d-block ml-0">
                          File is required
                        </div>
                        <div class="mt-2 ml-2 text-gray">
                          <small>Allowed csv, xlsx, xls extension only.</small>
                        </div>
                      </div>

                      <div class="row mt-1 ml-md-4" *ngIf="attachmentFile">
                        <div class="col-md-5">
                          <div class="file-upload-view-section">
                            <div class="d-flex">
                              <div class="mr-auto">
                                <div class="image-section d-flex">
                                  <div class="img-section">
                                    <img src="../../assets/images/panel_images/attach-file.png" class="mr-2 flie-img" />
                                  </div>
                                  <div class="view_details">
                                    <h5>{{ fileName }}</h5>
                                  </div>
                                </div>
                              </div>
                              <div class="close-img cursor" (click)="close()">
                                <img src="../../assets/images/panel_images/close-pdf.png" width="30px" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6"></div>
                      </div>
                      <div *ngIf="errorTvProgram != null" class="invalid-feedback d-block ml-md-3">
                        {{ errorTvProgram }}
                      </div>
                    </form>
                    <div class="button-section-tv-programs ml-4 pl-2 mt-5 pb-4">
                      <button type="button" class="btn create-btn" (click)="cmsTvProgram()">
                        Create
                      </button>
                    </div>
                  </div>
                  <!-- <p>I'm visible because I am open</p> -->
                </mat-expansion-panel>
              </mat-accordion>
              <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header class="disable_ripple">
                    <div class="d-flex">
                      <div class="push-img">
                        <img src="../../assets/images/panel_images/services-1.svg" class="mr-3" />
                      </div>
                      <div class="push-header mt-1">
                        <h5>Services</h5>
                        <p *ngIf="
                            updatedAtServcie != '' && updatedAtServcie != null
                          ">
                          Last update made on
                          {{ updatedAtServcie | date : "dd-MM-yyyy" }} by
                          {{ firstNameService }} {{ lastNameAdminService }}
                        </p>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="tv-programs-panel ml-5">
                    <div class="header-tv">
                      <app-showloader *ngIf="servicesLoader"></app-showloader>
                      <h4 class="pt-0">Services Data Update Request</h4>
                      <h5 class="pt-3 ml-4 pl-3">What are the modifications</h5>
                    </div>
                    <form [formGroup]="cmsService">
                      <div class="Email-field mt-0 ml-4 pl-3">
                        <mat-form-field>
                          <mat-label>Modifications</mat-label>
                          <input matInput formControlName="modification" appInputRestriction />
                        </mat-form-field>
                        <div *ngIf="
                            updatecmsService.modification.errors?.required &&
                            ServiceSubmit
                          " class="invalid-feedback d-block ml-0">
                          Modification value is required
                        </div>
                      </div>
                      <div class="pt-3 pl-4 ml-2" *ngIf="serviceUploadFlie">
                        <input style="display: none" type="file" formControlName="file"
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          (change)="onFileSelectedService($event)" #fileServiceInput />
                        <button class="btn-upload" (click)="fileServiceInput.click()">
                          <img src="../../assets/images/panel_images/upload.svg" class="mr-2" />Upload CSV or Excel file
                        </button>
                        <div *ngIf="
                            updatecmsService.file.errors?.required &&
                            ServiceSubmit
                          " class="invalid-feedback d-block ml-0">
                          File is required
                        </div>
                        <div class="mt-2 ml-2 text-gray">
                          <small>Allowed csv, xlsx, xls extension only.</small>
                        </div>
                      </div>
                      <div class="row mt-1 ml-md-4" *ngIf="serviceAttachmentFile">
                        <div class="col-md-5">
                          <div class="file-upload-view-section">
                            <div class="d-flex">
                              <div class="mr-auto">
                                <div class="image-section d-flex">
                                  <div class="img-section">
                                    <img src="../../assets/images/panel_images/attach-file.png" class="mr-2 flie-img" />
                                  </div>
                                  <div class="view_details">
                                    <h5>{{ serviceFileName }}</h5>
                                  </div>
                                </div>
                              </div>
                              <div class="close-img cursor" (click)="serviceClose()">
                                <img src="../../assets/images/panel_images/close-pdf.png" width="30px" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6"></div>
                      </div>
                      <div *ngIf="errorTvProgram != null" class="invalid-feedback d-block ml-md-3">
                        {{ errorTvProgram }}
                      </div>
                    </form>
                    <div class="button-section-tv-programs ml-4 pl-2 mt-5 pb-4">
                      <button type="button" class="btn create-btn" (click)="cmsServiceBtn()">
                        Create
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header class="disable_ripple">
                    <div class="d-flex">
                      <div class="push-img">
                        <img src="../../assets/images/panel_images/donations-1.svg" class="mr-3" />
                      </div>
                      <div class="push-header mt-1">
                        <app-showloader *ngIf="donationsLoader"></app-showloader>
                        <h5>Donations</h5>
                        <p *ngIf="
                            updatedAtDonate != '' && updatedAtDonate != null
                          ">
                          Last update made on
                          {{ updatedAtDonate | date : "dd-MM-yyyy" }} by
                          {{ firstNameDonate }} {{ lastNameAdminDonate }}
                        </p>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="tv-programs-panel ml-5">
                    <div class="header-tv">
                      <h4 class="pt-0">Donations Data Update Request</h4>
                      <h5 class="pt-3 ml-4 pl-3">What are the modifications</h5>
                    </div>
                    <form [formGroup]="cmsDonationForm">
                      <div class="Email-field mt-0 ml-4 pl-3">
                        <mat-form-field>
                          <mat-label>Modifications</mat-label>
                          <input matInput formControlName="modification" appInputRestriction />
                        </mat-form-field>
                        <div *ngIf="
                            updatecmsDonations.modification.errors?.required &&
                            submittedDonations
                          " class="invalid-feedback d-block ml-0">
                          Modifications value is required
                        </div>
                      </div>
                      <div class="pt-3 pl-4 ml-2" *ngIf="donationsUploadFlie">
                        <input style="display: none" type="file" formControlName="file"
                          accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          (change)="onFileSelectedDonations($event)" #fileServiceInput />
                        <button class="btn-upload" (click)="fileServiceInput.click()">
                          <img src="../../assets/images/panel_images/upload.svg" class="mr-2" />Upload CSV or Excel file
                        </button>
                        <div *ngIf="
                            updatecmsDonations.file.errors?.required &&
                            submittedDonations
                          " class="invalid-feedback d-block ml-0">
                          File is required
                        </div>
                        <div class="mt-2 ml-2 text-gray">
                          <small>Allowed csv, xlsx, xls extension only.</small>
                        </div>
                      </div>
                      <div class="row mt-1 ml-md-4" *ngIf="donationsAttachmentFile">
                        <div class="col-md-5">
                          <div class="file-upload-view-section">
                            <div class="d-flex">
                              <div class="mr-auto">
                                <div class="image-section d-flex">
                                  <div class="img-section">
                                    <img src="../../assets/images/panel_images/attach-file.png" class="mr-2 flie-img" />
                                  </div>
                                  <div class="view_details">
                                    <h5>{{ donationsFileName }}</h5>
                                  </div>
                                </div>
                              </div>
                              <div class="close-img cursor" (click)="donationsClose()">
                                <img src="../../assets/images/panel_images/close-pdf.png" width="30px" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6"></div>
                      </div>
                      <div *ngIf="errorTvProgram != null" class="invalid-feedback d-block ml-md-3">
                        {{ errorTvProgram }}
                      </div>
                    </form>
                    <div class="button-section-tv-programs ml-4 pl-2 mt-5 pb-4">
                      <button type="button" class="btn create-btn" (click)="cmsDonationsBtn()">
                        Create
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header class="disable_ripple">
                    <div class="d-flex">
                      <div class="push-img">
                        <img src="../../assets/images/panel_images/temple-cms.svg" class="mr-3" />
                      </div>
                      <div class="push-header mt-1">
                        <h5>Temple</h5>
                        <p *ngIf="
                            updatedAtTemple != '' && updatedAtTemple != null
                          ">
                          Last update made on
                          {{ updatedAtTemple | date : "dd-MM-yyyy" }} by
                          {{ firstNameTemple }} {{ lastNameAdminTemple }}
                        </p>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="tv-programs-panel ml-5">
                    <div class="header-tv">
                      <app-showloader *ngIf="templeLoader"></app-showloader>
                      <h4 class="pt-0">Temple Data Update Request</h4>
                      <h5 class="pt-3 ml-4 pl-3">What are the modifications</h5>
                    </div>
                    <form [formGroup]="cmsTempleTest">
                      <div class="Email-field mt-0 ml-4 pl-3">
                        <mat-form-field>
                          <mat-label>Modifications</mat-label>
                          <input matInput formControlName="modification" appInputRestriction />
                        </mat-form-field>
                        <div *ngIf="
                            updatecmsTemple.modification.errors?.required &&
                            submitTemple
                          " class="invalid-feedback d-block ml-0">
                          Modifications value is required
                        </div>
                      </div>
                      <div class="pt-3 pl-4 ml-2" *ngIf="templeUploadFlie">
                        <input style="display: none" type="file" type="file" formControlName="file"
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          (change)="onFileSelectedTempleTest($event)" #fileInput />
                        <button class="btn-upload" (click)="fileInput.click()">
                          <img src="../../assets/images/panel_images/upload.svg" class="mr-2" />Upload CSV or Excel file
                        </button>
                        <div *ngIf="
                            updatecmsTemple.file.errors?.required &&
                            submitTemple
                          " class="invalid-feedback d-block ml-0">
                          File is required
                        </div>
                        <div class="mt-2 ml-2 text-gray">
                          <small>Allowed csv, xlsx, xls extension only.</small>
                        </div>
                      </div>
                      <div class="row mt-1 ml-md-4" *ngIf="templeAttachmentFile">
                        <div class="col-md-5">
                          <div class="file-upload-view-section">
                            <div class="d-flex">
                              <div class="mr-auto">
                                <div class="image-section d-flex">
                                  <div class="img-section">
                                    <img src="../../assets/images/panel_images/attach-file.png" class="mr-2 flie-img" />
                                  </div>
                                  <div class="view_details">
                                    <h5>{{ templeFileName }}</h5>
                                  </div>
                                </div>
                              </div>
                              <div class="close-img cursor" (click)="templeTestClose()">
                                <img src="../../assets/images/panel_images/close-pdf.png" width="30px" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6"></div>
                      </div>
                    </form>
                    <div class="button-section-tv-programs ml-4 pl-2 mt-5 pb-4">
                      <button type="button" class="btn create-btn" (click)="cmsTempleTestBtn()">
                        Create
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header class="disable_ripple">
                    <div class="d-flex">
                      <div class="push-img">
                        <img src="../../assets/images/panel_images/prayer-cms.svg" class="mr-3" />
                      </div>
                      <div class="push-header mt-1">
                        <h5>Prayer Request</h5>
                        <p *ngIf="
                            updatedAtPrayer != '' && updatedAtPrayer != null
                          ">
                          Last update made on
                          {{ updatedAtPrayer | date : "dd-MM-yyyy" }} by
                          {{ firstNamePrayer }} {{ lastNameAdminPrayer }}
                        </p>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="tv-programs-panel ml-5">
                    <div class="header-tv">
                      <app-showloader *ngIf="prayerReqLoader"></app-showloader>
                      <h4 class="pt-0">Prayer Request Data Update Request</h4>
                      <h5 class="pt-3 ml-4 pl-3">What are the modifications</h5>
                    </div>
                    <form [formGroup]="cmsPrayerRequest">
                      <div class="Email-field mt-0 ml-4 pl-3">
                        <mat-form-field>
                          <mat-label>Modifications</mat-label>
                          <input matInput formControlName="modification" appInputRestriction />
                        </mat-form-field>
                        <div *ngIf="
                            updatecmsPrayerRequest.modification.errors
                              ?.required && prayerRequestSubmit
                          " class="invalid-feedback d-block ml-0">
                          Modification value is required
                        </div>
                      </div>
                      <div class="pt-3 pl-4 ml-2" *ngIf="prayerRequestUploadFlie">
                        <input style="display: none" type="file" type="file" formControlName="file"
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          (change)="onFileSelectedPrayerRequest($event)" #fileInput />
                        <button class="btn-upload" (click)="fileInput.click()">
                          <img src="../../assets/images/panel_images/upload.svg" class="mr-2" />Upload CSV or Excel file
                        </button>
                        <div *ngIf="
                            updatecmsPrayerRequest.file.errors?.required &&
                            prayerRequestSubmit
                          " class="invalid-feedback d-block ml-0">
                          File is required
                        </div>
                        <div class="mt-2 ml-2 text-gray">
                          <small>Allowed csv, xlsx, xls extension only.</small>
                        </div>
                      </div>
                      <div class="row mt-1 ml-md-4" *ngIf="prayerRequestAttachmentFile">
                        <div class="col-md-5">
                          <div class="file-upload-view-section">
                            <div class="d-flex">
                              <div class="mr-auto">
                                <div class="image-section d-flex">
                                  <div class="img-section">
                                    <img src="../../assets/images/panel_images/attach-file.png" class="mr-2 flie-img" />
                                  </div>
                                  <div class="view_details">
                                    <h5>{{ prayerFileName }}</h5>
                                  </div>
                                </div>
                              </div>
                              <div class="close-img cursor" (click)="prayerRequestClose()">
                                <img src="../../assets/images/panel_images/close-pdf.png" width="30px" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6"></div>
                      </div>
                    </form>
                    <div class="button-section-tv-programs ml-4 pl-2 mt-5 pb-4">
                      <button type="button" class="btn create-btn" (click)="cmsprayerRequest()"
                        [disabled]="prayerRequest">
                        Create
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <!-- <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header class="disable_ripple">
                    <div class="d-flex">
                      <div class="push-img">
                        <img src="../../assets/images/panel_images/sf-11.svg" class="mr-3" />
                      </div>
                      <div class="push-header mt-1">
                        <h5>Spiritual Fitness</h5>
                        <p *ngIf="
                            updatedAtspiritual != '' &&
                            updatedAtspiritual != null
                          ">
                          Last update made on
                          {{ updatedAtspiritual | date : "dd-MM-yyyy" }} by
                          {{ firstNamespiritual }} {{ lastNameAdminspriitual }}
                        </p>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="tv-programs-panel ml-5">
                    <div class="header-tv">
                      <app-showloader *ngIf="spiritulFitnessLoader"></app-showloader>
                      <h4 class="pt-0">
                        Spiritual Fitness Data Update Request
                      </h4>
                      <h5 class="pt-3 ml-4 pl-3">What are the modifications</h5>
                    </div>
                    <form [formGroup]="cmsSpiritualFitnessForm">
                      <div class="Email-field mt-0 ml-4 pl-3">
                        <mat-form-field>
                          <mat-label>Modifications</mat-label>
                          <input matInput formControlName="modification" appInputRestriction />
                        </mat-form-field>
                        <div *ngIf="
                            updatecmsSpiritualFitness.modification.errors
                              ?.required && SpiritualFitnessSubmit
                          " class="invalid-feedback d-block ml-0">
                          Modification value is required
                        </div>
                      </div>
                      <div class="pt-3 pl-4 ml-2" *ngIf="spiritualUploadFlie">
                        <input style="display: none" type="file" formControlName="file"
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          (change)="onFileSelectedSpiritualFitness($event)" #fileServiceInput />
                        <button class="btn-upload" (click)="fileServiceInput.click()">
                          <img src="../../assets/images/panel_images/upload.svg" class="mr-2" />Upload CSV or Excel file
                        </button>
                        <div *ngIf="
                            updatecmsSpiritualFitness.file.errors?.required &&
                            SpiritualFitnessSubmit
                          " class="invalid-feedback d-block ml-0">
                          File is required
                        </div>
                        <div class="mt-2 ml-2 text-gray">
                          <small>Allowed csv, xlsx, xls extension only.</small>
                        </div>
                      </div>
                      <div class="row mt-1 ml-md-4" *ngIf="spiritualAttachmentFile">
                        <div class="col-md-5">
                          <div class="file-upload-view-section">
                            <div class="d-flex">
                              <div class="mr-auto">
                                <div class="image-section d-flex">
                                  <div class="img-section">
                                    <img src="../../assets/images/panel_images/attach-file.png" class="mr-2 flie-img" />
                                  </div>
                                  <div class="view_details">
                                    <h5>{{ spiritualFileName }}</h5>
                                  </div>
                                </div>
                              </div>
                              <div class="close-img cursor" (click)="spiritualFitnessClose()">
                                <img src="../../assets/images/panel_images/close-pdf.png" width="30px" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6"></div>
                      </div>
                      <div *ngIf="errorTvProgram != null" class="invalid-feedback d-block ml-md-3">
                        {{ errorTvProgram }}
                      </div>
                    </form>
                    <div class="button-section-tv-programs ml-4 pl-2 mt-5 pb-4">
                      <button type="button" class="btn create-btn" (click)="cmsSpiritualFitnessBtn()">
                        Create
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion> -->
            </div>
            <div *ngIf="!permissons?.cms_Model.edit" class="mt-5 text-center">
              <img src="../../assets//images/panel_images/notPermitted.png" width="120" alt="" />
              <p class="mt-5">
                You dont have permission to access this feature
              </p>
            </div>
          </mat-tab>
          <mat-tab label="History">
            <mat-accordion>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header class="disable_ripple">
                  <div class="d-flex">
                    <div class="push-img">
                      <img src="../../assets/images/panel_images/push.svg" class="mr-3" />
                    </div>
                    <div class="push-header mt-1">
                      <h5>Push Notifications</h5>
                      <p>
                        Last update made on
                        {{
                        updatedAt ? (updatedAt | date : "dd-MM-yyyy") : "N/A"
                        }}
                        by {{ firstName }} {{ lastName }}
                      </p>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <div class="table mt-5 mb-5 mx-md-5">
                  <table mat-table [dataSource]="dataSourcePushNotifications" class="mat-elevation-z8">
                    <ng-container matColumnDef="user">
                      <th mat-header-cell class="th-header-table" *matHeaderCellDef>
                        <h2>user</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <!-- <div class="d-flex">
                          <img *ngIf="element.createdBy.profile != ''" src="{{ element?.createdBy?.profile }}"
                            class="user-image-table mr-2" />
                          <ngx-avatar *ngIf="element.createdBy.profile == null || element.createdBy.profile == ''  "
                            class="mr-2" size="40" initialsSize="1"
                            name="{{ element.createdBy.firstName }}"></ngx-avatar>
                          <ngx-avatar *ngIf="element?.createdBy?.firstName == null" class="mr-3 name-a" initialsSize="1"
                            size="40" name="N/A"></ngx-avatar>
                        </div> -->
                        <div class="d-flex">
                          <span *ngIf=" element?.adminDetails?.profile
                          ? element?.adminDetails?.profile
                          : element?.createdBy?.profile
                          ? element?.createdBy?.profile
                          : element?.user && element?.user[0]?.profile; else noProfile">
                            <img src="{{
                                element?.adminDetails?.profile
                                  ? element?.adminDetails?.profile
                                  : element?.createdBy?.profile
                                  ? element?.createdBy?.profile
                                  : element?.user && element?.user[0]?.profile
                              }}" class="user-image-table mr-2" />
                          </span>
                          <ng-template #noProfile>
                            <ngx-avatar class="mr-2" initialsSize="1" size="40" name="{{ element?.adminDetails?.firstName
                              ? element?.adminDetails?.firstName
                              : element?.createdBy?.firstName
                              ? element?.createdBy?.firstName
                              : element?.user && element?.user[0]?.firstName}}"></ngx-avatar>
                          </ng-template>

                          <div class="">
                            <h6 class="mt-2">
                              {{
                              element?.adminDetails?.firstName
                              ? element?.adminDetails?.firstName
                              : element?.createdBy?.firstName
                              ? element?.createdBy?.firstName
                              : element?.user && element?.user[0]?.firstName
                              ? element?.user[0]?.firstName
                              : "N/A"
                              }}
                              {{
                              element?.adminDetails?.lastName
                              ? element?.adminDetails?.lastName
                              : element?.createdBy?.lastName
                              ? element?.createdBy?.lastName
                              : element?.user && element?.user[0]?.lastName
                              ? element?.user[0]?.lastName
                              : "N/A"
                              }}
                            </h6>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="title">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2>Title</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <h4>{{ element?.title ? element?.title : "N/A" }}</h4>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Requestdescription">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2>Request description</h2>
                      </th>
                      <td mat-cell *matCellDef="let element" class="description">
                        <h4 class="wrap" [matTooltip]="
                            element?.description?.length > 60
                              ? element?.description
                              : ''
                          ">
                          {{
                          element?.description ? element?.description : "N/A"
                          }}
                        </h4>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2 class="">status</h2>
                        <!-- <h2>status</h2> -->
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <!-- <h6 *ngIf="element?.status == 'active'" class="eligable">
                                                    {{ element?.status }}
                                                </h6> -->
                        <h6 *ngIf="
                            element?.status == 'sent' ||
                            element?.status == 'Sent'
                          " class="sent w-60">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'approved'" class="eligable1">
                          {{ element?.status | titlecase }}
                        </h6>


                        <h6 *ngIf="element?.status == 'rejected'" class="rejected w-55 ">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'deactivate'" class="rejected w-55 ">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'pending'" class="pending w-55">
                          {{ element?.status | titlecase }}
                        </h6>

                      </td>
                      <td mat-cell *matCellDef="let element">
                        <h6 *ngIf="element?.status == 'approved'" class="eligable">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.createdBy?.status == 'In Review'" class="not-member">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'rejected'" class="rejected">
                          {{ element?.status | titlecase }}
                        </h6>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Date">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2>Date</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <h6>
                          {{
                          element?.updatedAt
                          ? (element?.updatedAt | date : "dd-MM-YYYY")
                          : "-"
                          }}
                        </h6>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                  </table>
                  <mat-paginator (page)="pageChanged($event)" [length]="totalCmsNot" [pageIndex]="currentPage"
                    [pageSize]="pageSize"></mat-paginator>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header class="disable_ripple">
                  <div class="d-flex">
                    <div class="push-img">
                      <img src="../../assets/images/panel_images/media.svg" class="mr-3" />
                    </div>
                    <div class="push-header mt-1">
                      <h5>Media</h5>
                      <p>
                        Last update made on
                        {{
                        updatedAtMedia
                        ? (updatedAtMedia | date : "dd-MM-yyyy")
                        : "N/A"
                        }}
                        by {{ mediaFirstName }} {{ mediaLastNameAdmin }}
                      </p>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <div class="checkbox-section radio-button-section ml-50">
                  <mat-radio-group name="radiobtn1" [(ngModel)]="selectedMedia">
                    <mat-radio-button *ngFor="let btn of Radiomediaoptions" (change)="changeMediaType($event)"
                      [disableRipple]="false" value="{{ btn.key }}" class="example-margin mr-3">{{ btn.value
                      }}</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="table mt-5 mb-5 mx-md-5">
                  <table mat-table [dataSource]="dataSourceMedia" class="mat-elevation-z8">
                    <ng-container matColumnDef="user">
                      <th mat-header-cell class="th-header-table" *matHeaderCellDef>
                        <h2>user</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="d-flex">
                          <span *ngIf=" element?.adminDetails?.profile
                          ? element?.adminDetails?.profile
                          : element?.createdBy?.profile
                          ? element?.createdBy?.profile
                          : element?.user && element?.user[0]?.profile; else noProfile">
                            <img src="{{
                                element?.adminDetails?.profile
                                  ? element?.adminDetails?.profile
                                  : element?.createdBy?.profile
                                  ? element?.createdBy?.profile
                                  : element?.user && element?.user[0]?.profile
                              }}" class="user-image-table mr-2" />
                          </span>
                          <ng-template #noProfile>
                            <ngx-avatar class="mr-2" initialsSize="1" size="40" name="{{ element?.adminDetails?.firstName
                              ? element?.adminDetails?.firstName
                              : element?.createdBy?.firstName
                              ? element?.createdBy?.firstName
                              : element?.user && element?.user[0]?.firstName}}"></ngx-avatar>
                          </ng-template>

                          <div class="">
                            <h6 class="mt-2">
                              {{
                              element?.adminDetails?.firstName
                              ? element?.adminDetails?.firstName
                              : element?.createdBy?.firstName
                              ? element?.createdBy?.firstName
                              : element?.user && element?.user[0]?.firstName
                              ? element?.user[0]?.firstName
                              : "N/A"
                              }}
                              {{
                              element?.adminDetails?.lastName
                              ? element?.adminDetails?.lastName
                              : element?.createdBy?.lastName
                              ? element?.createdBy?.lastName
                              : element?.user && element?.user[0]?.lastName
                              ? element?.user[0]?.lastName
                              : "N/A"
                              }}
                            </h6>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Requestdescription" style="width: 100px !important;">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2>Title</h2>
                      </th>
                      <td mat-cell *matCellDef="let element" class="description">
                        <h4 class="wrap" [matTooltip]="
                            element?.title?.length > 60 ? element?.title : ''
                          ">
                          {{ element?.title ? element?.title : "N/A" }}
                        </h4>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2 class="mlt-3">status</h2>
                      </th>
                      <td mat-cell *matCellDef="let element" class="min-100">
                        <h6 *ngIf="element?.status == 'active'" class="eligable">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'sent'" class="sent">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'complete'" class="eligable">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'approved'" class="eligable">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="
                            element?.status == 'Created' ||
                            element?.status == 'created'
                          " class="eligable">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'live'" class="eligable">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'not-Live'" class="not-eligable">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'In Review'" class="not-member">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'not-publish'" class="not-member">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="
                            element?.status == 'Pending' ||
                            element?.status == 'pending'
                          " class="pending">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="
                            element?.status == 'Rejected' ||
                            element?.status == 'rejected'
                          " class="rejected">
                          {{ element?.status | titlecase }}
                        </h6>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="mediaStatus">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2>Modification Status</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <h6 *ngIf="element?.modificationStatus == 'approved'" class="eligable ">
                          {{
                          element?.modificationStatus
                          ? (element?.modificationStatus | titlecase)
                          : "N/A"
                          }}
                        </h6>
                        <h6 *ngIf="
                            element?.modificationStatus == 'Created' ||
                            element?.modificationStatus == 'created'
                          " class="eligable ">
                          {{
                          element?.modificationStatus
                          ? (element?.modificationStatus | titlecase)
                          : "N/A"
                          }}
                        </h6>
                        <h6 *ngIf="
                            element?.modificationStatus == 'Rejected' ||
                            element?.modificationStatus == 'rejected' ||
                            element?.modificationStatus == 'expired'
                          " class="rejected ">
                          {{
                          element?.modificationStatus
                          ? (element?.modificationStatus | titlecase)
                          : "N/A"
                          }}
                        </h6>
                        <h6 *ngIf="
                            element?.modificationStatus == 'Pending' ||
                            element?.modificationStatus == 'pending'
                          " class="pending">
                          {{
                          element?.modificationStatus
                          ? (element?.modificationStatus | titlecase)
                          : "N/A"
                          }}
                        </h6>
                        <h6 *ngIf="!element?.modificationStatus" class="w-50">
                          {{ "N/A" }}
                        </h6>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Date">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2>Created Date</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <h6>

                          {{element?.createdAt ? (element?.createdAt | date:"dd-MM-YYYY") : 'NA'}}
                        </h6>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="publishDate">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2>Publish Date</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <h6>
                          {{element?.date ? (element?.date | date:"dd-MM-YYYY") : element?.eventStart ?
                          (element?.eventStart | date:"dd-MM-YYYY") : 'NA' }}

                        </h6>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsMedia"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsMedia"></tr>
                  </table>
                  <mat-paginator (page)="pageChangedMedia($event)" [length]="totalMedia" [pageIndex]="currentPageMedia"
                    [pageSize]="pageSizeMedia"></mat-paginator>

                </div>
                <div class="table mt-5 mb-5 mx-md-5 text-center p-2" *ngIf="!totalMedia">
                  No data Found
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header class="disable_ripple">
                  <div class="d-flex">
                    <div class="push-img">
                      <img src="../../assets/images/panel_images/tv-1-p.svg" class="mr-3" />
                    </div>
                    <div class="push-header mt-1">
                      <h5>TV Programs</h5>
                      <p>
                        Last update made on
                        {{ updatedAtTvprogram | date : "dd-MM-yyyy" }} by
                        {{ firstNameTvprogram }} {{ lastNameAdminTvprogram }}
                      </p>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <div class="table mt-5 mb-5 mx-md-5">
                  <table mat-table [dataSource]="dataSourceTvProgram" class="mat-elevation-z8">
                    <ng-container matColumnDef="user">
                      <th mat-header-cell class="th-header-table" *matHeaderCellDef>
                        <h2>user</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="d-flex">
                          <img src="{{ element?.createdBy?.profile }}" class="user-image-table mr-2" />
                          <div class="">
                            <h6 class="mt-2">
                              {{
                              element?.createdBy?.firstName
                              ? element?.createdBy?.firstName
                              : "N/A"
                              }}
                              {{
                              element?.createdBy?.lastName
                              ? element?.createdBy?.lastName
                              : "N/A"
                              }}
                            </h6>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Requestdescription">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2>Request description</h2>
                      </th>
                      <td mat-cell *matCellDef="let element" class="description">
                        <h4 class="wrap" [matTooltip]="
                            element?.modification?.length > 60
                              ? element?.modification
                              : ''
                          ">
                          {{
                          element?.modification
                          ? element?.modification
                          : "N/A"
                          }}
                        </h4>
                        <!-- <p>{{element?.title ? element?.title : 'N/A'}}</p> -->
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2 class="mlt-3">status</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <h6 *ngIf="element?.status == 'approved'" class="eligable w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'sent'" class="sent w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'pending'" class="pending w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.createdBy?.status == 'In Review'" class="not-member w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'rejected'" class="rejected w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Date">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2>Date</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <h6>
                          {{
                          element?.updatedAt
                          ? (element?.updatedAt | date : "dd-MM-YYYY")
                          : "-"
                          }}
                        </h6>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsTvProgram"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsTvProgram"></tr>
                  </table>
                  <mat-paginator (page)="pageChangedProgram($event)" [length]="totalTvPrograms"
                    [pageIndex]="currentPageProgram" [pageSize]="pageSizeProgram"></mat-paginator>
                  <!-- <mat-paginator [pageSizeOptions]="[10,20, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
                </div>
                <!-- <p>I'm visible because I am open</p> -->
              </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header class="disable_ripple">
                  <div class="d-flex">
                    <div class="push-img">
                      <img src="../../assets/images/panel_images/services-1.svg" class="mr-3" />
                    </div>
                    <div class="push-header mt-1">
                      <h5>Services</h5>
                      <p>
                        Last update made on
                        {{ updatedAtServcie | date : "dd-MM-yyyy" }} by
                        {{ firstNameService }} {{ lastNameAdminService }}
                      </p>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <div class="table mt-5 mb-5 mx-md-5">
                  <table mat-table [dataSource]="dataSourceServcice" class="mat-elevation-z8">
                    <ng-container matColumnDef="user">
                      <th mat-header-cell class="th-header-table" *matHeaderCellDef>
                        <h2>user</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="d-flex">
                          <span *ngIf="element?.createdBy?.profile; else noProfile">
                            <img src="{{ element?.createdBy?.profile }}" class="user-image-table mr-2" />
                          </span>
                          <ng-template #noProfile>
                            <ngx-avatar class="mr-2 " initialsSize="1" size="40" name="{{ element?.adminDetails?.firstName
                              ? element?.adminDetails?.firstName
                              : element?.createdBy?.firstName
                              ? element?.createdBy?.firstName
                              : element?.user && element?.user[0]?.firstName}}"></ngx-avatar>
                          </ng-template>
                          <div class="">
                            <h6 class="mt-2">
                              {{
                              element?.createdBy?.firstName
                              ? element?.createdBy?.firstName
                              : "N/A"
                              }}
                              {{
                              element?.createdBy?.lastName
                              ? element?.createdBy?.lastName
                              : "N/A"
                              }}
                            </h6>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Requestdescription">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2>Request description</h2>
                      </th>
                      <td mat-cell *matCellDef="let element" class="description">
                        <h4 class="wrap" [matTooltip]="
                            element?.modification?.length > 60
                              ? element?.modification
                              : ''
                          ">
                          {{
                          element?.modification
                          ? element?.modification
                          : "N/A"
                          }}
                        </h4>
                        <!-- <p>{{element?.title ? element?.title : 'N/A'}}</p> -->
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2 class="mlt-3">status</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <h6 *ngIf="element?.status == 'approved'" class="eligable w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'pending'" class="pending w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'sent'" class="sent w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.createdBy?.status == 'In Review'" class="not-member w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'rejected'" class="rejected w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Date">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2>Date</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <h6>
                          {{
                          element?.updatedAt
                          ? (element?.updatedAt | date : "dd-MM-YYYY")
                          : "-"
                          }}
                        </h6>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsService"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsService"></tr>
                  </table>
                  <mat-paginator (page)="pageChangedService($event)" [length]="totalTvServices"
                    [pageIndex]="currentPageProgram" [pageSize]="pageSizeService"></mat-paginator>
                  <!-- <mat-paginator [pageSizeOptions]="[10,20, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header class="disable_ripple">
                  <div class="d-flex">
                    <div class="push-img">
                      <img src="../../assets/images/panel_images/donations-1.svg" class="mr-3" />
                    </div>
                    <div class="push-header mt-1">
                      <h5>Donations</h5>
                      <p>
                        Last update made on
                        {{ updatedAtDonate | date : "dd-MM-yyyy" }} by
                        {{ firstNameDonate }} {{ lastNameAdminDonate }}
                      </p>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <div class="table mt-5 mb-5 mx-md-5">
                  <table mat-table [dataSource]="dataSourceDonate" class="mat-elevation-z8">
                    <ng-container matColumnDef="user">
                      <th mat-header-cell class="th-header-table" *matHeaderCellDef>
                        <h2>user</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="d-flex">
                          <img src="{{ element?.createdBy?.profile }}" class="user-image-table mr-2" />
                          <div class="">
                            <h6 class="mt-2">
                              {{
                              element?.createdBy?.firstName
                              ? element?.createdBy?.firstName
                              : "N/A"
                              }}
                              {{
                              element?.createdBy?.lastName
                              ? element?.createdBy?.lastName
                              : "N/A"
                              }}
                            </h6>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Requestdescription">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2>Request description</h2>
                      </th>
                      <td mat-cell *matCellDef="let element" class="description">
                        <h4 class="wrap" [matTooltip]="
                            element?.modification?.length > 60
                              ? element?.modification
                              : ''
                          ">
                          {{
                          element?.modification
                          ? element?.modification
                          : "N/A"
                          }}
                        </h4>
                        <!-- <p>{{element?.title ? element?.title : 'N/A'}}</p> -->
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2 class="mlt-3">status</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <h6 *ngIf="element?.status == 'approved'" class="eligable w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.createdBy?.status == 'In Review'" class="not-member w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'rejected'" class="rejected w-50 px-2">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'pending'" class="pending w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'sent'" class="sent w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Date">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2>Date</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <h6>
                          {{
                          element?.updatedAt
                          ? (element?.updatedAt | date : "dd-MM-YYYY")
                          : "-"
                          }}
                        </h6>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsDonate"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsDonate"></tr>
                  </table>
                  <mat-paginator (page)="pageChangedDonations($event)" [length]="totalTvDonation"
                    [pageIndex]="currentPageDonation" [pageSize]="pageSizeDonation"></mat-paginator>
                  <!-- <mat-paginator [pageSizeOptions]="[10,20, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header class="disable_ripple">
                  <div class="d-flex">
                    <div class="push-img">
                      <img src="../../assets/images/panel_images/temple-cms.svg" class="mr-3" />
                    </div>
                    <div class="push-header mt-1">
                      <h5>Temple</h5>
                      <p>
                        Last update made on
                        {{ updatedAtTemple | date : "dd-MM-yyyy" }} by
                        {{ firstNameTemple }} {{ lastNameAdminTemple }}
                      </p>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <div class="table mt-5 mb-5 mx-md-5">
                  <table mat-table [dataSource]="dataSourceTemple" class="mat-elevation-z8">
                    <ng-container matColumnDef="user">
                      <th mat-header-cell class="th-header-table" *matHeaderCellDef>
                        <h2>user</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="d-flex">
                          <img src="{{ element?.createdBy?.profile }}" class="user-image-table mr-2" />
                          <div class="">
                            <h6 class="mt-2">
                              {{
                              element?.createdBy?.firstName
                              ? element?.createdBy?.firstName
                              : "N/A"
                              }}
                              {{
                              element?.createdBy?.lastName
                              ? element?.createdBy?.lastName
                              : "N/A"
                              }}
                            </h6>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Requestdescription">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2>Request description</h2>
                      </th>
                      <td mat-cell *matCellDef="let element" class="description">
                        <h4 class="wrap" [matTooltip]="
                            element?.modification?.length > 60
                              ? element?.modification
                              : ''
                          ">
                          {{
                          element?.modification
                          ? element?.modification
                          : "N/A"
                          }}
                        </h4>
                        <!-- <p>{{element?.title ? element?.title : 'N/A'}}</p> -->
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2 class="mlt-3">status</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <h6 *ngIf="element?.status == 'approved'" class="eligable w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'In Review'" class="not-member w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'rejected'" class="rejected w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'pending'" class="pending w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'sent'" class="sent w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Date">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2>Date</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <h6>
                          {{
                          element?.updatedAt
                          ? (element?.updatedAt | date : "dd-MM-YYYY")
                          : "-"
                          }}
                        </h6>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsTemple"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsTemple"></tr>
                  </table>
                  <mat-paginator (page)="pageChangedTemple($event)" [length]="totalTvTemple"
                    [pageIndex]="currentPageTemple" [pageSize]="pageSizeTemple"></mat-paginator>
                  <!-- <mat-paginator [pageSizeOptions]="[10,20, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header class="disable_ripple">
                  <div class="d-flex">
                    <div class="push-img">
                      <img src="../../assets/images/panel_images/prayer-cms.svg" class="mr-3" />
                    </div>
                    <div class="push-header mt-1">
                      <h5>Prayer Request</h5>
                      <p>
                        Last update made on
                        {{ updatedAtPrayer | date : "dd-MM-yyyy" }} by
                        {{ firstNamePrayer }} {{ lastNameAdminPrayer }}
                      </p>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <div class="table mt-5 mb-5 mx-md-5">
                  <table mat-table [dataSource]="dataSourcePrayerReq" class="mat-elevation-z8">
                    <ng-container matColumnDef="user">
                      <th mat-header-cell class="th-header-table" *matHeaderCellDef>
                        <h2>user</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="d-flex">
                          <img src="{{ element?.createdBy?.profile }}" class="user-image-table mr-2" />
                          <div class="">
                            <h6 class="mt-2">
                              {{
                              element?.createdBy?.firstName
                              ? element?.createdBy?.firstName
                              : "N/A"
                              }}
                              {{
                              element?.createdBy?.lastName
                              ? element?.createdBy?.lastName
                              : "N/A"
                              }}
                            </h6>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Requestdescription">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2>Request description</h2>
                      </th>
                      <td mat-cell *matCellDef="let element" class="description">
                        <h4 class="wrap" [matTooltip]="
                            element?.modification?.length > 60
                              ? element?.modification
                              : ''
                          ">
                          {{
                          element?.modification
                          ? element?.modification
                          : "N/A"
                          }}
                        </h4>
                        <!-- <p>{{element?.title ? element?.title : 'N/A'}}</p> -->
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2 class="mlt-3">status</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <h6 *ngIf="element?.status == 'approved'" class="eligable w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.createdBy?.status == 'In Review'" class="not-member w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'rejected'" class="rejected w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'pending'" class="pending w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'sent'" class="sent w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Date">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2>Date</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <h6>
                          {{
                          element?.updatedAt
                          ? (element?.updatedAt | date : "dd-MM-YYYY")
                          : "-"
                          }}
                        </h6>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsPrayerReq"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsPrayerReq"></tr>
                  </table>
                  <mat-paginator (page)="pageChangedPrayer($event)" [length]="totalTvPrayer"
                    [pageIndex]="currentPagePrayer" [pageSize]="pageSizePrayer"></mat-paginator>
                  <!-- <mat-paginator [pageSizeOptions]="[10,20, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <!-- <mat-accordion>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header class="disable_ripple">
                  <div class="d-flex">
                    <div class="push-img">
                      <img src="../../assets/images/panel_images/sf-11.svg" class="mr-3" />
                    </div>
                    <div class="push-header mt-1">
                      <h5>Spiritual Fitness</h5>
                      <p>
                        Last update made on
                        {{ updatedAtspiritual | date : "dd-MM-yyyy" }} by
                        {{ firstNamespiritual }} {{ lastNameAdminspriitual }}
                      </p>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <div class="table mt-5 mb-5 mx-md-5">
                  <table mat-table [dataSource]="dataSourceSpiritual" class="mat-elevation-z8">
                    <ng-container matColumnDef="user">
                      <th mat-header-cell class="th-header-table" *matHeaderCellDef>
                        <h2>user</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="d-flex">
                          <span *ngIf="element?.createdBy?.profile; else noProfile">
                            <img src="{{ element?.createdBy?.profile }}" class="user-image-table mr-2" />
                          </span>
                          <ng-template #noProfile>
                            <ngx-avatar class="mr-2 " initialsSize="1" size="40" name="{{ element?.adminDetails?.firstName
                              ? element?.adminDetails?.firstName
                              : element?.createdBy?.firstName
                              ? element?.createdBy?.firstName
                              : element?.user && element?.user[0]?.firstName}}"></ngx-avatar>
                          </ng-template>
                          <div class="">
                            <h6 class="mt-2">
                              {{
                              element?.createdBy?.firstName
                              ? element?.createdBy?.firstName
                              : "N/A"
                              }}
                              {{
                              element?.createdBy?.lastName
                              ? element?.createdBy?.lastName
                              : "N/A"
                              }}
                            </h6>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Requestdescription">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2>Request description</h2>
                      </th>
                      <td mat-cell *matCellDef="let element" class="description">
                        <h4 class="wrap" [matTooltip]="
                            element?.modification?.length > 60
                              ? element?.modification
                              : ''
                          ">
                          {{
                          element?.modification
                          ? element?.modification
                          : "N/A"
                          }}
                        </h4>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2 class="mlt-3">status</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <h6 *ngIf="element?.status == 'approved'" class="eligable w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'pending'" class="pending w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.createdBy?.status == 'In Review'" class="not-member w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'rejected'" class="rejected w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                        <h6 *ngIf="element?.status == 'sent'" class="sent w-50">
                          {{ element?.status | titlecase }}
                        </h6>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Date">
                      <th mat-header-cell *matHeaderCellDef>
                        <h2>Date</h2>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <h6>
                          {{
                          element?.updatedAt
                          ? (element?.updatedAt | date : "dd-MM-YYYY")
                          : "-"
                          }}
                        </h6>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsSpiritual"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsSpiritual"></tr>
                  </table>
                  <mat-paginator (page)="pageChangedSpiritual($event)" [length]="totalTvSpirtual"
                    [pageIndex]="currentPageSpirtual" [pageSize]="pageSizeSpirtual"></mat-paginator>
                </div>
              </mat-expansion-panel>
            </mat-accordion> -->
          </mat-tab>
          <mat-tab label="Review">
            <div *ngIf="permissons?.cms_Model.edit && permissons?.cms_Model.view">
              <mat-accordion>
                <mat-expansion-panel class="disabled-pointer">
                  <mat-expansion-panel-header class="disable_ripple review-header">
                    <div class="d-flex">
                      <div class="d-flex">
                        <div class="push-img">
                          <img src="../../assets/images/panel_images/push.svg" class="mr-3" />
                          <!-- <img src="../../assets/images/panel_images/pray (1).svg" class="mr-3"> -->
                        </div>
                        <div class="push-header mt-1">
                          <h5>Push Notifications</h5>
                          <p *ngIf="
                              resNotifications != '' && resNotifications != null
                            ">
                            Last update made on
                            {{ updatedAt | date : "dd-MM-yyyy" }} by
                            {{ firstName }} {{ lastName }}
                          </p>
                        </div>
                      </div>
                      <div class="ml-auto">
                        <div class="view-button">
                          <p>
                            {{ reviewCount ? reviewCount : 0 }} pending requests
                          </p>

                          <button class="btn ml-md-4 cursor" routerLink="./Review/PushNotifications">
                            <img src="../../assets/images/panel_images/eye.svg" class="mr-1" />View
                          </button>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="table mt-5 mb-5 mx-md-5" *ngIf="reviewCount">
                    <table mat-table [dataSource]="dataReviewPushNotifications" class="mat-elevation-z8">
                      <ng-container matColumnDef="user">
                        <th mat-header-cell class="th-header-table" *matHeaderCellDef>
                          <h2>user</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <div class="d-flex">
                            <span *ngIf="element?.createdBy?.profile; else noProfile">
                              <img src="{{ element?.createdBy?.profile }}" class="user-image-table mr-2" />
                            </span>
                            <ng-template #noProfile>
                              <ngx-avatar class="mr-2 " initialsSize="1" size="40" name="{{ element?.adminDetails?.firstName
                                ? element?.adminDetails?.firstName
                                : element?.createdBy?.firstName
                                ? element?.createdBy?.firstName
                                : element?.user && element?.user[0]?.firstName}}"></ngx-avatar>
                            </ng-template>
                            <div class="">
                              <h6 class="mt-2">
                                {{
                                element?.createdBy?.firstName
                                ? element?.createdBy?.firstName
                                : "N/A"
                                }}
                                {{
                                element?.createdBy?.lastName
                                ? element?.createdBy?.lastName
                                : "N/A"
                                }}
                              </h6>
                            </div>
                          </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2>Title</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <h4>{{ element?.title ? element?.title : "N/A" }}</h4>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Requestdescription">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2>Request description</h2>
                        </th>
                        <td mat-cell *matCellDef="let element" class="description">
                          <h4 class="wrap" [matTooltip]="
                              element?.description?.length > 60
                                ? element?.description
                                : ''
                            ">
                            {{
                            element?.description
                            ? element?.description
                            : "N/A"
                            }}
                          </h4>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2 class="mlt-3">status</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <!-- <h6 *ngIf="element?.status == 'active'" class="eligable">
                                                        {{ element?.status }}
                                                    </h6> -->
                          <h6 *ngIf="
                              element?.status == 'sent' ||
                              element?.status == 'Sent'
                            " class="sent">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'approved'" class="eligable1">
                            {{ element?.status | titlecase }}
                          </h6>

                          <!-- <h6 *ngIf="element?.status == 'In Review'" class="not-member">
                                                        {{ element?.status}}
                                                    </h6> -->
                          <h6 *ngIf="element?.status == 'rejected'" class="rejected">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'pending'" class="pending">
                            {{ element?.status | titlecase }}
                          </h6>
                          <!-- <h6 *ngIf="element?.status == 'decline'" class="not-eligable">
                                                        {{ element?.status }}
                                                    </h6> -->
                        </td>
                        <td mat-cell *matCellDef="let element">
                          <h6 *ngIf="element?.status == 'approved'" class="eligable">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.createdBy?.status == 'In Review'" class="not-member">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'rejected'" class="rejected">
                            {{ element?.status | titlecase }}
                          </h6>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Date">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2>Date</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <h6>
                            {{
                            element?.updatedAt
                            ? (element?.updatedAt | date : "dd-MM-YYYY")
                            : "-"
                            }}
                          </h6>
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </table>
                    <mat-paginator (page)="pageChangedReview($event)" [length]="reviewCount" [pageIndex]="currentPage"
                      [pageSize]="pageSize"></mat-paginator>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header class="disable_ripple review-header">
                    <div class="d-flex">
                      <div class="d-flex">
                        <div class="push-img">
                          <img src="../../assets/images/panel_images/media.svg" class="mr-3" />

                        </div>
                        <div class="push-header mt-1">
                          <h5>Media</h5>
                          <p>
                            Last update made on
                            {{
                            updatedAtMedia
                            ? (updatedAtMedia | date : "dd-MM-yyyy")
                            : "N/A"
                            }}
                            by {{ mediaFirstName }} {{ mediaLastNameAdmin }}
                          </p>
                        </div>
                      </div>
                      <div class="ml-auto">
                        <div class="view-button">
                          <p>
                            {{ reviewCountMedias ? reviewCountMedias : 0 }}
                            pending requests
                          </p>

                          <button class="btn ml-md-4 cursor" routerLink="./Review/Medias"
                            [queryParams]="{ type: selectedReviewMedia }">
                            <img src="../../assets/images/panel_images/eye.svg" class="mr-1" />View
                          </button>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="checkbox-section radio-button-section ml-50">
                    <mat-radio-group name="radiobtn" [(ngModel)]="selectedReviewMedia">
                      <mat-radio-button *ngFor="let btn of Radioreviewoptions" (change)="changedReviewMedia($event)"
                        [disableRipple]="false" value="{{ btn.key }}" class="example-margin mr-3">{{ btn.value
                        }}</mat-radio-button>
                    </mat-radio-group>
                    <!-- <div *ngIf="updateCmsPushNotificaitons.deviceType.errors?.required && pushSubmitted" class="invalid-feedback d-block">Please select</div> -->
                  </div>
                  <div class="table mt-5 mb-5 mx-md-5" *ngIf="reviewCountMedias">
                    <table mat-table [dataSource]="dataReviewMedias" class="mat-elevation-z8">
                      <ng-container matColumnDef="user">
                        <th mat-header-cell class="th-header-table" *matHeaderCellDef>
                          <h2>user</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <div class="d-flex">
                            <span *ngIf="element?.adminDetails?.profile
                            ? element?.adminDetails?.profile
                            : element?.createdBy?.profile
                            ? element?.createdBy?.profile
                            : element?.user && element?.user[0]?.profile; else noProfile">
                              <img src="{{
                                element?.adminDetails?.profile
                                  ? element?.adminDetails?.profile
                                  : element?.createdBy?.profile
                                  ? element?.createdBy?.profile
                                  : element?.user && element?.user[0]?.profile
                              }}" class="user-image-table mr-2" />
                            </span>
                            <ng-template #noProfile>
                              <ngx-avatar class="mr-2 " initialsSize="1" size="40" name="{{ element?.adminDetails?.firstName
                              ? element?.adminDetails?.firstName
                              : element?.createdBy?.firstName
                              ? element?.createdBy?.firstName
                              : element?.user && element?.user[0]?.firstName}}"></ngx-avatar>
                            </ng-template>
                            <div class="">
                              <h6 class="mt-2">
                                {{
                                element?.adminDetails?.firstName
                                ? element?.adminDetails?.firstName
                                : element?.createdBy?.firstName
                                ? element?.createdBy?.firstName
                                : element?.user &&
                                element?.user[0]?.firstName
                                ? element?.user[0]?.firstName
                                : "N/A"
                                }}
                                {{
                                element?.adminDetails?.lastName
                                ? element?.adminDetails?.lastName
                                : element?.createdBy?.lastName
                                ? element?.createdBy?.lastName
                                : element?.user &&
                                element?.user[0]?.lastName
                                ? element?.user[0]?.lastName
                                : "N/A"
                                }}
                              </h6>
                            </div>
                          </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Requestdescription">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2>Request description</h2>
                        </th>
                        <td mat-cell *matCellDef="let element" class="description">
                          <h4 class="wrap" [matTooltip]="
                              element?.title?.length > 60 ? element?.title : ''
                            ">
                            {{ element?.title ? element?.title : "N/A" }}
                          </h4>
                          <!-- <p class="wrap" [matTooltip]="element?.modification">{{element?.title ? element?.title : 'N/A'}}</p> -->
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2 class="mlt-3">status</h2>
                        </th>
                        <td mat-cell *matCellDef="let element" class="min-100">
                          <h6 *ngIf="element?.status == 'active'" class="eligable">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'sent'" class="sent">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'complete'" class="eligable">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'approved'" class="eligable">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'live'" class="eligable">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'not-Live'" class="not-eligable">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'In Review'" class="not-member">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'not-publish'" class="not-member">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'pending'" class="pending">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="
                              element?.status == 'Rejected' ||
                              element?.status == 'rejected'
                            " class="rejected">
                            {{ element?.status }}
                          </h6>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="mediaStatus">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2>Modification Status</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <h6 *ngIf="element?.modificationStatus == 'approved'" class="eligable w-50">
                            {{
                            element?.modificationStatus
                            ? (element?.modificationStatus | titlecase)
                            : "N/A"
                            }}
                          </h6>
                          <h6 *ngIf="element?.modificationStatus == 'pending'" class="pending w-50">

                            {{
                            element?.modificationStatus
                            ? (element?.modificationStatus | titlecase)
                            : "N/A"
                            }}
                          </h6>
                          <h6 *ngIf="element?.modificationStatus == 'rejected'" class="not-eligable w-50">
                            {{
                            element?.modificationStatus
                            ? (element?.modificationStatus | titlecase)
                            : "N/A"
                            }}
                          </h6>
                          <h6 *ngIf="!element?.modificationStatus" class="w-50">
                            {{ "N/A" }}
                          </h6>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Date">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2>Created Date</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <h6>
                            {{
                            element?.updatedAt
                            ? (element?.updatedAt | date : "dd-MM-YYYY")
                            : element?.createdAt
                            ? (element?.createdAt | date : "dd-MM-YYYY")
                            : "-"
                            }}
                          </h6>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="publishDate">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2>Publish Date</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <h6>
                            <!-- {{
                              element?.updatedAt
                                ? (element?.updatedAt | date : "dd-MM-YYYY")
                                : element?.createdAt
                                ? (element?.createdAt | date : "dd-MM-YYYY")
                                : "-"
                            }} -->
                            NA
                          </h6>
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumnsMedia"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsMedia"></tr>
                    </table>
                    <mat-paginator (page)="pageChangedReviewMedia($event)" [length]="reviewCountMedias"
                      [pageIndex]="currentPageMedia" [pageSize]="pageSizeMedia"></mat-paginator>
                  </div>
                  <div class="table mt-5 mb-5 mx-md-5 text-center p-2" *ngIf="!reviewCountMedias">
                    No pending request Found
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header class="disable_ripple review-header">
                    <div class="d-flex">
                      <div class="push-img">
                        <img src="../../assets/images/panel_images/tv-1-p.svg" class="mr-3" />
                      </div>
                      <div class="push-header mt-1">
                        <h5>TV Programs</h5>
                        <p *ngIf="
                            updatedAtTvprogram != '' &&
                            updatedAtTvprogram != null
                          ">
                          Last update made on
                          {{ updatedAtTvprogram | date : "dd-MM-yyyy" }} by
                          {{ firstNameTvprogram }} {{ lastNameAdminTvprogram }}
                        </p>
                      </div>
                      <div class="ml-auto">
                        <div class="view-button">
                          <p>
                            {{ TvProgramRecords ? TvProgramRecords : 0 }}
                            pending requests
                          </p>
                          <button class="btn ml-md-4 cursor" routerLink="./Review/TV-programs">

                            <img src="../../assets/images/panel_images/eye.svg" class="mr-1" />View
                          </button>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <!-- <p>I'm visible because I am open</p> -->
                  <div class="table mt-5 mb-5 mx-md-5" *ngIf="TvProgramRecords">
                    <table mat-table [dataSource]="dataReviewProgram" class="mat-elevation-z8">
                      <ng-container matColumnDef="user">
                        <th mat-header-cell class="th-header-table" *matHeaderCellDef>
                          <h2>user</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <div class="d-flex">
                            <span *ngIf="element?.createdBy?.profile; else noProfile">
                              <img src="{{ element?.createdBy?.profile }}" class="user-image-table mr-2" />
                            </span>
                            <ng-template #noProfile>
                              <ngx-avatar class="mr-2 mb-1 " initialsSize="1" size="40" name="{{ element?.adminDetails?.firstName
                                ? element?.adminDetails?.firstName
                                : element?.createdBy?.firstName
                                ? element?.createdBy?.firstName
                                : element?.user && element?.user[0]?.firstName}}"></ngx-avatar>
                            </ng-template>
                            <div class="">
                              <h6 class="mt-2">
                                {{
                                element?.createdBy?.firstName
                                ? element?.createdBy?.firstName
                                : "N/A"
                                }}
                                {{
                                element?.createdBy?.lastName
                                ? element?.createdBy?.lastName
                                : "N/A"
                                }}
                              </h6>
                            </div>
                          </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Requestdescription">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2>Request description</h2>
                        </th>
                        <td mat-cell *matCellDef="let element" class="description">
                          <h4 class="wrap" [matTooltip]="
                              element?.modification?.length > 60
                                ? element?.modification
                                : ''
                            ">
                            {{
                            element?.modification
                            ? element?.modification
                            : "N/A"
                            }}
                          </h4>
                          <!-- <p>{{element?.title ? element?.title : 'N/A'}}</p> -->
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2 class="mlt-3">status</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <h6 *ngIf="element?.status == 'approved'" class="eligable w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'sent'" class="sent w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'pending'" class="pending w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.createdBy?.status == 'In Review'" class="not-member w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'rejected'" class="rejected w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Date">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2>Date</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <h6>
                            {{
                            element?.updatedAt
                            ? (element?.updatedAt | date : "dd-MM-YYYY")
                            : "-"
                            }}
                          </h6>
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumnsTvProgram"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsTvProgram"></tr>
                    </table>
                    <mat-paginator (page)="pageChangedReviewProgram($event)" [length]="TvProgramRecords"
                      [pageIndex]="currentPageProgram" [pageSize]="pageSizeProgram"></mat-paginator>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header class="disable_ripple review-header">
                    <div class="d-flex">
                      <div class="push-img">
                        <img src="../../assets/images/panel_images/services-1.svg" class="mr-3" />
                      </div>
                      <div class="push-header mt-1">
                        <h5>Services</h5>
                        <p *ngIf="
                            updatedAtServcie != '' && updatedAtServcie != null
                          ">
                          Last update made on
                          {{ updatedAtServcie | date : "dd-MM-yyyy" }} by
                          {{ firstNameService }} {{ lastNameAdminService }}
                        </p>
                      </div>
                      <div class="ml-auto">
                        <div class="view-button">
                          <p>
                            {{ serviceRecords ? serviceRecords : 0 }} pending
                            requests
                          </p>
                          <button class="btn ml-md-4 cursor" routerLink="./Review/Services">
                            <img src="../../assets/images/panel_images/eye.svg" class="mr-1" />View
                          </button>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="table mt-5 mb-5 mx-md-5" *ngIf="serviceRecords">
                    <table mat-table [dataSource]="dataReviewServices" class="mat-elevation-z8">
                      <ng-container matColumnDef="user">
                        <th mat-header-cell class="th-header-table" *matHeaderCellDef>
                          <h2>user</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <div class="d-flex">
                            <span *ngIf="element?.createdBy?.profile; else noProfile">
                              <img src="{{ element?.createdBy?.profile }}" class="user-image-table mr-2" />
                            </span>
                            <ng-template #noProfile>
                              <ngx-avatar class="mr-2 " initialsSize="1" size="40" name="{{ element?.adminDetails?.firstName
                                ? element?.adminDetails?.firstName
                                : element?.createdBy?.firstName
                                ? element?.createdBy?.firstName
                                : element?.user && element?.user[0]?.firstName}}"></ngx-avatar>
                            </ng-template>
                            <div class="">
                              <h6 class="mt-2">
                                {{
                                element?.createdBy?.firstName
                                ? element?.createdBy?.firstName
                                : "N/A"
                                }}
                                {{
                                element?.createdBy?.lastName
                                ? element?.createdBy?.lastName
                                : "N/A"
                                }}
                              </h6>
                            </div>
                          </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Requestdescription">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2>Request description</h2>
                        </th>
                        <td mat-cell *matCellDef="let element" class="description">
                          <h4 class="wrap" [matTooltip]="
                              element?.modification?.length > 60
                                ? element?.modification
                                : ''
                            ">
                            {{
                            element?.modification
                            ? element?.modification
                            : "N/A"
                            }}
                          </h4>
                          <!-- <p>{{element?.title ? element?.title : 'N/A'}}</p> -->
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2 class="mlt-3">status</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <h6 *ngIf="element?.status == 'approved'" class="eligable w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'sent'" class="sent w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.createdBy?.status == 'In Review'" class="not-member w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'rejected'" class="rejected w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'pending'" class="pending w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Date">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2>Date</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <h6>
                            {{
                            element?.updatedAt
                            ? (element?.updatedAt | date : "dd-MM-YYYY")
                            : "-"
                            }}
                          </h6>
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumnsService"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsService"></tr>
                    </table>
                    <mat-paginator (page)="pageChangedReviewService($event)" [length]="serviceRecords"
                      [pageIndex]="currentPageService" [pageSize]="pageSizeService"></mat-paginator>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header class="disable_ripple review-header">
                    <div class="d-flex">
                      <div class="push-img">
                        <img src="../../assets/images/panel_images/donations-1.svg" class="mr-3" />
                      </div>
                      <div class="push-header mt-1">
                        <h5>Donations</h5>
                        <p *ngIf="
                            updatedAtDonate != '' && updatedAtDonate != null
                          ">
                          Last update made on
                          {{ updatedAtDonate | date : "dd-MM-yyyy" }} by
                          {{ firstNameDonate }} {{ lastNameAdminDonate }}
                        </p>
                      </div>
                      <div class="ml-auto">
                        <div class="view-button">
                          <p>
                            {{ DonationsRecords ? DonationsRecords : 0 }}
                            pending requests
                          </p>
                          <button class="btn ml-md-4 cursor" routerLink="./Review/Donations">
                            <img src="../../assets/images/panel_images/eye.svg" class="mr-1" />View
                          </button>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="table mt-5 mb-5 mx-md-5" *ngIf="DonationsRecords">
                    <table mat-table [dataSource]="dataReviewDonations" class="mat-elevation-z8">
                      <ng-container matColumnDef="user">
                        <th mat-header-cell class="th-header-table" *matHeaderCellDef>
                          <h2>user</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <div class="d-flex">
                            <span *ngIf="element?.createdBy?.profile; else noProfile">
                              <img src="{{ element?.createdBy?.profile }}" class="user-image-table mr-2" />
                            </span>
                            <ng-template #noProfile>
                              <ngx-avatar class="mr-2 " initialsSize="1" size="40" name="{{ element?.adminDetails?.firstName
                                ? element?.adminDetails?.firstName
                                : element?.createdBy?.firstName
                                ? element?.createdBy?.firstName
                                : element?.user && element?.user[0]?.firstName}}"></ngx-avatar>
                            </ng-template>
                            <div class="">
                              <h6 class="mt-2">
                                {{
                                element?.createdBy?.firstName
                                ? element?.createdBy?.firstName
                                : "N/A"
                                }}
                                {{
                                element?.createdBy?.lastName
                                ? element?.createdBy?.lastName
                                : "N/A"
                                }}
                              </h6>
                            </div>
                          </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Requestdescription">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2>Request description</h2>
                        </th>
                        <td mat-cell *matCellDef="let element" class="description">
                          <h4 class="wrap" [matTooltip]="
                              element?.modification?.length > 60
                                ? element?.modification
                                : ''
                            ">
                            {{
                            element?.modification
                            ? element?.modification
                            : "N/A"
                            }}
                          </h4>
                          <!-- <p>{{element?.title ? element?.title : 'N/A'}}</p> -->
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2 class="mlt-3">status</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <h6 *ngIf="element?.status == 'approved'" class="eligable w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.createdBy?.status == 'In Review'" class="not-member w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'rejected'" class="rejected w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'sent'" class="sent w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'pending'" class="pending w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Date">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2>Date</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <h6>
                            {{
                            element?.updatedAt
                            ? (element?.updatedAt | date : "dd-MM-YYYY")
                            : "-"
                            }}
                          </h6>
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumnsSpiritual"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsSpiritual"></tr>
                    </table>
                    <mat-paginator (page)="pageChangedReviewDonations($event)" [length]="DonationsRecords"
                      [pageIndex]="currentPageDonation" [pageSize]="pageSizeDonation"></mat-paginator>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header class="disable_ripple review-header">
                    <div class="d-flex">
                      <div class="push-img">
                        <img src="../../assets/images/panel_images/temple-cms.svg" class="mr-3" />
                      </div>
                      <div class="push-header mt-1">
                        <h5>Temple</h5>
                        <p *ngIf="
                            updatedAtTemple != '' && updatedAtTemple != null
                          ">
                          Last update made on
                          {{ updatedAtTemple | date : "dd-MM-yyyy" }} by
                          {{ firstNameTemple }} {{ lastNameAdminTemple }}
                        </p>
                      </div>
                      <div class="ml-auto">
                        <div class="view-button">
                          <p>
                            {{ reviewTempleCount ? reviewTempleCount : 0 }}
                            pending requests
                          </p>
                          <button class="btn ml-md-4 cursor" routerLink="./Review/Temples">
                            <img src="../../assets/images/panel_images/eye.svg" class="mr-1" />View
                          </button>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="table mt-5 mb-5 mx-md-5" *ngIf="reviewTempleCount">
                    <table mat-table [dataSource]="dataReviewTemple" class="mat-elevation-z8">
                      <ng-container matColumnDef="user">
                        <th mat-header-cell class="th-header-table" *matHeaderCellDef>
                          <h2>user</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <div class="d-flex">
                            <span *ngIf="element?.createdBy?.profile; else noProfile">
                              <img src="{{ element?.createdBy?.profile }}" class="user-image-table mr-2" />
                            </span>
                            <ng-template #noProfile>
                              <ngx-avatar class="mr-2 " initialsSize="1" size="40" name="{{ element?.adminDetails?.firstName
                                ? element?.adminDetails?.firstName
                                : element?.createdBy?.firstName
                                ? element?.createdBy?.firstName
                                : element?.user && element?.user[0]?.firstName}}"></ngx-avatar>
                            </ng-template>
                            <div class="">
                              <h6 class="mt-2">
                                {{
                                element?.createdBy?.firstName
                                ? element?.createdBy?.firstName
                                : "N/A"
                                }}
                                {{
                                element?.createdBy?.lastName
                                ? element?.createdBy?.lastName
                                : "N/A"
                                }}
                              </h6>
                            </div>
                          </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Requestdescription">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2>Request description</h2>
                        </th>
                        <td mat-cell *matCellDef="let element" class="description">
                          <h4 class="wrap" [matTooltip]="
                              element?.modification?.length > 60
                                ? element?.modification
                                : ''
                            ">
                            {{
                            element?.modification
                            ? element?.modification
                            : "N/A"
                            }}
                          </h4>
                          <!-- <p>{{element?.title ? element?.title : 'N/A'}}</p> -->
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2 class="mlt-3">status</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <h6 *ngIf="element?.status == 'approved'" class="eligable w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'In Review'" class="not-member w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'rejected'" class="rejected w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'pending'" class="pending w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'sent'" class="sent w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Date">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2>Date</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <h6>
                            {{
                            element?.updatedAt
                            ? (element?.updatedAt | date : "dd-MM-YYYY")
                            : "-"
                            }}
                          </h6>
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumnsTemple"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsTemple"></tr>
                    </table>
                    <mat-paginator (page)="pageChangedReviewTemple($event)" [length]="reviewTempleCount"
                      [pageIndex]="currentPageTemple" [pageSize]="pageSizeTemple"></mat-paginator>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header class="disable_ripple review-header">
                    <div class="d-flex">
                      <div class="push-img">
                        <img src="../../assets/images/panel_images/prayer-cms.svg" class="mr-3" />
                      </div>
                      <div class="push-header mt-1">
                        <h5>Prayer Request</h5>
                        <p *ngIf="
                            updatedAtPrayer != '' && updatedAtPrayer != null
                          ">
                          Last update made on
                          {{ updatedAtPrayer | date : "dd-MM-yyyy" }} by
                          {{ firstNamePrayer }} {{ lastNameAdminPrayer }}
                        </p>
                      </div>
                      <div class="ml-auto">
                        <div class="view-button">
                          <p>
                            {{ reviewPrayerCount ? reviewPrayerCount : 0 }}
                            pending requests
                          </p>
                          <button class="btn ml-md-4 cursor" routerLink="./Review/Prayerrequests">
                            <img src="../../assets/images/panel_images/eye.svg" class="mr-1" />View
                          </button>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="table mt-5 mb-5 mx-md-5" *ngIf="reviewPrayerCount">
                    <table mat-table [dataSource]="dataReviewPrayerRequest" class="mat-elevation-z8">
                      <ng-container matColumnDef="user">
                        <th mat-header-cell class="th-header-table" *matHeaderCellDef>
                          <h2>user</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <div class="d-flex">
                            <span *ngIf="element?.createdBy?.profile; else noProfile">
                              <img src="{{ element?.createdBy?.profile }}" class="user-image-table mr-2" />
                            </span>
                            <ng-template #noProfile>
                              <ngx-avatar class="mr-2 " initialsSize="1" size="40" name="{{ element?.adminDetails?.firstName
                                ? element?.adminDetails?.firstName
                                : element?.createdBy?.firstName
                                ? element?.createdBy?.firstName
                                : element?.user && element?.user[0]?.firstName}}"></ngx-avatar>
                            </ng-template>
                            <div class="">
                              <h6 class="mt-2">
                                {{
                                element?.createdBy?.firstName
                                ? element?.createdBy?.firstName
                                : "N/A"
                                }}
                                {{
                                element?.createdBy?.lastName
                                ? element?.createdBy?.lastName
                                : "N/A"
                                }}
                              </h6>
                            </div>
                          </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Requestdescription">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2>Request description</h2>
                        </th>
                        <td mat-cell *matCellDef="let element" class="description">
                          <h4 class="wrap" [matTooltip]="
                              element?.modification?.length > 60
                                ? element?.modification
                                : ''
                            ">
                            {{
                            element?.modification
                            ? element?.modification
                            : "N/A"
                            }}
                          </h4>
                          <!-- <p>{{element?.title ? element?.title : 'N/A'}}</p> -->
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2 class="mlt-3">status</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <h6 *ngIf="element?.status == 'approved'" class="eligable w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.createdBy?.status == 'In Review'" class="not-member w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'rejected'" class="rejected w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'pending'" class="pending w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'sent'" class="sent w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Date">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2>Date</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <h6>
                            {{
                            element?.updatedAt
                            ? (element?.updatedAt | date : "dd-MM-YYYY")
                            : "-"
                            }}
                          </h6>
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumnsPrayerReq"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsPrayerReq"></tr>
                    </table>
                    <mat-paginator (page)="pageChangedReviewPrayer($event)" [length]="reviewPrayerCount"
                      [pageIndex]="currentPagePrayer" [pageSize]="pageSizePrayer"></mat-paginator>
                    <!-- <mat-paginator [pageSizeOptions]="[10,20, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <!-- <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header class="disable_ripple review-header">
                    <div class="d-flex">
                      <div class="push-img">
                        <img src="../../assets/images/panel_images/sf-11.svg" class="mr-3" />
                      </div>
                      <div class="push-header mt-1">
                        <h5>Spiritual Fitness</h5>
                        <p *ngIf="
                            updatedAtspiritual != '' &&
                            updatedAtspiritual != null
                          ">
                          Last update made on
                          {{ updatedAtspiritual | date : "dd-MM-yyyy" }} by
                          {{ firstNamespiritual }} {{ lastNameAdminspriitual }}
                        </p>
                      </div>
                      <div class="ml-auto">
                        <div class="view-button">
                          <p>
                            {{
                            reviewSpiritualCount ? reviewSpiritualCount : 0
                            }}
                            pending requests
                          </p>
                          <button class="btn ml-md-4 cursor" routerLink="./Review/Spiritualfitness">
                            <img src="../../assets/images/panel_images/eye.svg" class="mr-1" />View
                          </button>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="table mt-5 mb-5 mx-md-5" *ngIf="reviewSpiritualCount">
                    <table mat-table [dataSource]="dataReviewSpritual" class="mat-elevation-z8">
                      <ng-container matColumnDef="user">
                        <th mat-header-cell class="th-header-table" *matHeaderCellDef>
                          <h2>user</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <div class="d-flex">
                            <span *ngIf="element?.createdBy?.profile; else noProfile">
                              <img src="{{ element?.createdBy?.profile }}" class="user-image-table mr-2" />
                            </span>
                            <ng-template #noProfile>
                              <ngx-avatar class="mr-2 " initialsSize="1" size="40" name="{{ element?.adminDetails?.firstName
                                ? element?.adminDetails?.firstName
                                : element?.createdBy?.firstName
                                ? element?.createdBy?.firstName
                                : element?.user && element?.user[0]?.firstName}}"></ngx-avatar>
                            </ng-template>
                            <div class="">
                              <h6 class="mt-2">
                                {{
                                element?.createdBy?.firstName
                                ? element?.createdBy?.firstName
                                : "N/A"
                                }}
                                {{
                                element?.createdBy?.lastName
                                ? element?.createdBy?.lastName
                                : "N/A"
                                }}
                              </h6>
                            </div>
                          </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Requestdescription">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2>Request description</h2>
                        </th>
                        <td mat-cell *matCellDef="let element" class="description">
                          <h4 class="wrap" [matTooltip]="
                              element?.modification?.length > 60
                                ? element?.modification
                                : ''
                            ">
                            {{
                            element?.modification
                            ? element?.modification
                            : "N/A"
                            }}
                          </h4>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2 class="mlt-3">status</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <h6 *ngIf="element?.status == 'approved'" class="eligable w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.createdBy?.status == 'In Review'" class="not-member w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'rejected'" class="rejected w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'sent'" class="sent w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                          <h6 *ngIf="element?.status == 'pending'" class="pending w-50">
                            {{ element?.status | titlecase }}
                          </h6>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Date">
                        <th mat-header-cell *matHeaderCellDef>
                          <h2>Date</h2>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <h6>
                            {{
                            element?.updatedAt
                            ? (element?.updatedAt | date : "dd-MM-YYYY")
                            : "-"
                            }}
                          </h6>
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumnsSpiritual"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsSpiritual"></tr>
                    </table>
                    <mat-paginator (page)="pageChangedReviewSpiritual($event)" [length]="reviewSpiritualCount"
                      [pageIndex]="currentPageSpirtual" [pageSize]="pageSizeSpirtual"></mat-paginator>
                  </div>
                </mat-expansion-panel>
              </mat-accordion> -->
            </div>

            <div *ngIf="!permissons?.cms_Model.edit" class="mt-5 text-center">
              <img src="../../assets//images/panel_images/notPermitted.png" width="120" alt="" />
              <p class="mt-5">
                You dont have permission to access this feature
              </p>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #alternateTemplate>
  <div class="mt-5 text-center">
    <img src="../../assets//images/panel_images/notPermitted.png" width="120" alt="" />
    <p class="mt-5">You dont have permission to access this feature</p>
  </div>
</ng-template>