<div class="container-fluid bg-white pl-4">
    <div class="user-list-header d-flex py-2">
        <div class="user-list-heading d-flex">
            <img src="../../assets/images/panel_images/dashboard1.svg" class="user-list mr-2" />
            <h6 class="mt-2">Dashboard</h6>
        </div>
    </div>
</div>

<div class="no-access"
    *ngIf=" permissionsList.members_List?.analyse == false && permissionsList.service_Requests?.analyse == false && permissionsList.prayer_Requests?.analyse == false && permissionsList.Donation?.analyse == false && permissionsList.tv_programs?.analyse == false && permissionsList.support?.analyse == false">

    <!-- <h5>Not have Permissions to see the analytics</h5> -->
    <div class="mt-5 text-center">
        <img src="../../assets//images/panel_images/notPermitted.png" width="120" alt="" />
        <p class="mt-5">You dont have permission to access this feature</p>
    </div>
</div>
<!-- member-list-Analystics -->
<div class="user-header-details-img-section mt-4 mx-4 bg-white" *ngIf="permissionsList.members_List?.analyse">
    <div class="container-fluid px-0 pt-4 mb-4">
        <div class="ml-3 Analytics-one">
            <div class="d-flex">
                <div class="mt-0 mr-auto">
                    <div class="user-list-heading d-flex">
                        <img src="../../assets/images/panel_images/user-list.svg" class="user-list mr-2" />
                        <h6 class="mt-2">Members List</h6>
                    </div>
                </div>
                <div class="ml-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Select Year</mat-label>
                        <mat-select [(ngModel)]="selectedYear" (selectionChange)="selectYear($event)">
                            <mat-option *ngFor="let year of yearList" [value]="year">
                                {{ year }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="ml-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Select Month</mat-label>
                        <mat-select [(ngModel)]="selectedMonth" (selectionChange)="selectMonth($event)">
                            <mat-option *ngFor="let month of monthList" [value]="month.value">
                                {{ month.key }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="calendra-field mr-3 ml-3">
                    <mat-form-field class="example-full-width" appearance="fill"> <span matPrefix>Date:&nbsp;</span>
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate class="ml-1" placeholder="Start date" readonly="readonly"
                                (dateChange)="onChangeDate($event)" [value]="startDateMember">
                            <input matEndDate placeholder="End date" readonly="readonly"
                                (dateChange)="onChangeToDate($event)" [value]="endDateMember">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker">
                            <mat-icon matDatepickerToggleIcon><img src="../../assets/images/panel_images/c-11.svg"
                                    class="calendra-img" /></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <div class="graph cursor mr-2" (click)="resetMemberFilter()"> <img
                        src="../../assets/images/panel_images/reset-1.png" class="reset-img ml-2"> </div>
            </div>
        </div>

        <div class="d-flex ml-3 mt-2">
            <div class="card users-count mr-3 total-user-bg">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/total-users.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">Total Users</h4>
                        <h2 class="mt-2">{{ totalRecords ? (totalRecords | records) : '0'}}</h2>
                    </div>
                </div>
            </div>
            <app-showloader></app-showloader>

            <div class="card users-count mr-3  total-existing-bg">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/total-users-1.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">Male</h4>
                        <h2 class="mt-2">{{totalMale ? (totalMale | records) : '0'}}</h2>
                    </div>
                </div>
            </div>
            <div class="card users-count total-non-bg mr-3">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/total-users-3.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">Female</h4>
                        <h2 class="mt-2">{{totalFemale ? (totalFemale | records) : '0'}}</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4 ml-1 mr-xl-1">
            <div class="col">
                <div class="charts-bg">
                    <div class="pt-3 ml-3 d-flex">
                        <div class="d-flex pt-3 pb-2 mt-1 mr-auto">
                            <div class="user-header-back">
                                <h5 class="ml-2">User Growth</h5>
                                <div class="present-weeks ml-2 mt-4">
                                    <div class="d-flex">
                                        <div class="d-flex mr-3">
                                            <span class="present-dot"></span>
                                            <h5>Male</h5>
                                        </div>
                                        <div class="d-flex">
                                            <span class="romania-dot"></span>
                                            <h5>Female</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="export-button mt-2 mr-3">
                            <button type="button" class="cursor" (click)="membersExportBtn()">
                                <img src="../../../assets/images/panel_images/export.svg" class="mr-2 ">Export </button>
                        </div>
                    </div>
                    <div class="chart ml-3 pt-3 pb-3 mr-3">
                        <h1 class="no-data" *ngIf="maleMembers ==  ''">No data available</h1>
                        <highcharts-chart [Highcharts]="highcharts" [options]="chartOptions" style="width: 100%; height: 300px;
              display: block;"></highcharts-chart>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-6">
        <div class="ml-2 charts-bg">
          <div class="pt-3 ml-3 d-flex">
            <div class="d-flex pt-3 pb-2 mt-1 mr-auto">
              <div class="user-header-back">
                <h5 class="ml-2">Attendance</h5>
                <div class="present-weeks ml-2 mt-4">
                  <div class="d-flex">
                    <div class="d-flex mr-3">
                      <span class="present-dot"></span>
                      <h5> >75%</h5>
                    </div>
                    <div class="d-flex">
                      <span class="romania-dot"></span>
                      <h5>
                        <75% </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="export-button mt-2 mr-3">
              <button type="button" class="">
                <img src="../../../assets/images/panel_images/export.svg"  class="mr-2">Export </button>
            </div>
          </div>
          <div class="chart ml-3 pt-3 pb-3 mr-3">
            <highcharts-chart [Highcharts]="highcharts" [options]="chartOptionsAnalytics" style="width: 100%; height: 300px; display: block;"></highcharts-chart>
          </div>
        </div>
      </div> -->
        </div>
    </div>
</div>
<!-- member-list-Analystics -->
<!-- services-request-Analystics -->
<div class="user-header-details-img-section mt-4 mx-4 bg-white" *ngIf="permissionsList.service_Requests?.analyse">
    <div class="container-fluid px-0 pt-4 mb-4">
        <div class="ml-3 Analytics-one">
            <div class="d-flex">
                <div class="mt-1 mr-auto">
                    <div class="user-list-heading d-flex">
                        <img src="../../assets/images/panel_images/sr.svg" class="user-list mr-2" />
                        <h6 class="mt-2">Service Requests</h6>
                    </div>
                </div>
                <div class="ml-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Select Year</mat-label>
                        <mat-select [(ngModel)]="selectedYearService" (selectionChange)="selectYearService($event)">
                            <mat-option *ngFor="let year of yearList" [value]="year">
                                {{ year }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="ml-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Select Month</mat-label>
                        <mat-select [(ngModel)]="selectedMonthService" (selectionChange)="selectMonthService($event)">
                            <mat-option *ngFor="let month of monthListService" [value]="month.value">
                                {{ month.key }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="calendra-field mr-3 ml-3">
                    <mat-form-field class="example-full-width" appearance="fill"> <span matPrefix>Date:&nbsp;</span>
                        <mat-date-range-input [rangePicker]="picker1">
                            <input matStartDate class="ml-1" placeholder="Start date" readonly="readonly"
                                (dateChange)="onChangeServiceDate($event)" [value]="startDateService">
                            <input matEndDate placeholder="End date" readonly="readonly"
                                (dateChange)="onChangeServiceToDate($event)" [value]="endDateService">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker1">
                            <mat-icon matDatepickerToggleIcon><img src="../../assets/images/panel_images/c-11.svg"
                                    class="calendra-img" /></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-date-range-picker #picker1></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <div class="graph cursor mr-2" (click)="resetServiceFilter()"> <img
                        src="../../assets/images/panel_images/reset-1.png" class="reset-img ml-2"> </div>
            </div>
        </div>
        <div class="d-flex ml-3 mt-2">
            <div class="card users-count mr-3 total-user-bg">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/sr-1.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">Total Service Requests</h4>
                        <h2 class="mt-2">{{ totalService ? (totalService| records) : 0}}</h2>
                    </div>
                </div>
            </div>
            <div class="card users-count mr-3 total-active-bg">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/sr-2.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">Active Requests</h4>
                        <h2 class="mt-2">{{activeRequest ?(activeRequest | records ) : 0}}</h2>
                    </div>
                </div>
            </div>
            <div class="card users-count mr-3 total-existing-bg">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/sr-3.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">Completed Requests</h4>
                        <h2 class="mt-2">{{completedRequest ? (completedRequest | records) : 0}}</h2>
                    </div>
                </div>
            </div>
            <div class="card users-count mr-3 total-non-bg">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/sr-4.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">Cancelled Requests</h4>
                        <h2 class="mt-2">{{cancelledRequest ? (cancelledRequest | records) : 0}}</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4 ml-1 mr-xl-1">
            <div class="col">
                <div class="charts-bg">
                    <div class="pt-3 ml-3 d-flex">
                        <div class="d-flex pt-3 pb-2 mt-1 mr-auto">
                            <div class="user-header-back">
                                <h5 class="ml-2">Service Requests</h5>
                                <div class="present-weeks ml-2 mt-4">
                                    <div class="d-flex">
                                        <div class="d-flex mr-3">
                                            <span class="present-dot"></span>
                                            <h5>Created</h5>
                                        </div>
                                        <div class="d-flex">
                                            <span class="romania-dot"></span>
                                            <h5>Completed</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="export-button mt-2 mr-3">
                            <button type="button" class="cursor" (click)="serviceExportBtn()">
                                <img src="../../../assets/images/panel_images/export.svg" class="mr-2">Export </button>
                        </div>
                    </div>
                    <div class="chart ml-3 pt-3 pb-3 mr-3">
                        <highcharts-chart [Highcharts]="highcharts" [options]="serviceRequestCharts"
                            style="width: 100%; height: 300px; display: block;"></highcharts-chart>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-12">
                <div class="charts-bg">
                    <div class="pt-3 ml-3 d-flex">
                        <div class="d-flex pt-3 pb-2 mt-1 mr-auto w-50">
                            <div class="user-header-back">
                                <h5 class="ml-2">Service Type</h5>
                                <div class="present-weeks ml-2 mt-4">
                                    <div class="d-flex">
                                        <div class="d-flex mr-3">
                                            <span class="present-dot"></span>
                                            <h5>Hospital & Medical</h5>
                                        </div>
                                        <div class="d-flex mr-3">
                                            <span class="romania-dot"></span>
                                            <h5> Parents Home </h5>
                                        </div>
                                        <div class="d-flex mr-3 ">
                                            <span class="romania-dot b-c-dot"></span>
                                            <h5>Birthday Cake</h5>
                                        </div>
                                        <div class="d-flex mr-3">
                                            <span class="romania-dot c-dot"></span>
                                            <h5>Cementery</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="export-button mt-2 mr-3">
                            <button type="button" class="">
                                <img src="../../../assets/images/panel_images/export.svg" class="mr-2">Export </button>
                        </div>
                    </div>
                    <div class="chart ml-3 pt-3 pb-3 mr-3">
                        <highcharts-chart [Highcharts]="highcharts" [options]="serviceChartOptionsAnalytics"
                            style="width: 100%; height: 300px; display: block;"></highcharts-chart>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>
<!-- services-request-Analystics -->
<!-- prayer-request-Analystics -->
<div class="user-header-details-img-section mt-4 mx-4 bg-white" *ngIf="permissionsList.prayer_Requests?.analyse">
    <div class="container-fluid px-0 pt-4 mb-4">
        <div class="ml-3 Analytics-one">
            <div class="d-flex">
                <div class="mt-1 mr-auto">
                    <div class="user-list-heading d-flex">
                        <img src="../../assets/images/panel_images/p-r.svg" class="user-list mr-2" />
                        <h6 class="mt-2">Prayer Request</h6>
                    </div>
                </div>
                <div class="ml-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Select Year</mat-label>
                        <mat-select [(ngModel)]="selectedYearPrayer" (selectionChange)="selectYearPrayer($event)">
                            <mat-option *ngFor="let year of yearList" [value]="year">
                                {{ year }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="ml-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Select Month</mat-label>
                        <mat-select [(ngModel)]="selectedMonthPrayer" (selectionChange)="selectMonthPrayer($event)">
                            <mat-option *ngFor="let month of monthListPrayer" [value]="month.value">
                                {{ month.key }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="calendra-field mr-3 ml-3">
                    <mat-form-field class="example-full-width" appearance="fill"> <span matPrefix>Date:&nbsp;</span>
                        <mat-date-range-input [rangePicker]="picker2">
                            <input matStartDate class="ml-1" placeholder="Start date" readonly="readonly"
                                (dateChange)="onChangePrayerDate($event)" [value]="startDatePrayer">
                            <input matEndDate placeholder="End date" readonly="readonly"
                                (dateChange)="onChangePrayerToDate($event)" [value]="endDatePrayer">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker2">
                            <mat-icon matDatepickerToggleIcon><img src="../../assets/images/panel_images/c-11.svg"
                                    class="calendra-img" /></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-date-range-picker #picker2></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <div class="graph cursor mr-2" (click)="resetPrayerFilter()"> <img
                        src="../../assets/images/panel_images/reset-1.png" class="reset-img ml-2"> </div>
            </div>
        </div>
        <div class="d-flex ml-3 mt-2">
            <div class="card users-count mr-3 total-user-bg">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/prayer-1.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">Total Prayer Requests</h4>
                        <h2 class="mt-2">{{ totalPrayerRequest ? (totalPrayerRequest | records) : 0}}</h2>
                    </div>
                </div>
            </div>
            <div class="card users-count mr-3 total-active-bg">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/prayer-2.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">Total Active Requests</h4>
                        <h2 class="mt-2">{{ activePrayerRequest ? (activePrayerRequest | records) : 0}}</h2>
                    </div>
                </div>
            </div>
            <div class="card users-count mr-3 total-existing-bg">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/prayer-3.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">Total Completed Requests</h4>
                        <h2 class="mt-2">{{completedPrayerRequest ? (completedPrayerRequest | records) : 0}}</h2>
                    </div>
                </div>
            </div>
            <div class="card users-count mr-3 total-non-bg">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/prayer-4.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">Total Cancelled Requests</h4>
                        <h2 class="mt-2">{{cancelledPrayerRequest ? (cancelledPrayerRequest | records) : 0}}</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4 ml-1 mr-xl-1">
            <div class="col">
                <div class="charts-bg">
                    <div class="pt-3 ml-3 d-flex">
                        <div class="d-flex pt-3 pb-2 mt-1 mr-auto">
                            <div class="user-header-back">
                                <h5 class="ml-2">Prayer Requests</h5>
                                <div class="present-weeks ml-2 mt-4">
                                    <div class="d-flex">
                                        <div class="d-flex mr-3">
                                            <span class="present-dot"></span>
                                            <h5>Open</h5>
                                        </div>
                                        <div class="d-flex">
                                            <span class="romania-dot"></span>
                                            <h5>Closed</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="export-button mt-2 mr-3">
                            <button type="button" class="cursor" (click)="prayerExportBtn()">
                                <img src="../../../assets/images/panel_images/export.svg" class="mr-2">Export </button>
                        </div>
                    </div>
                    <div class="chart ml-3 pt-3 pb-3 mr-3">
                        <highcharts-chart [Highcharts]="highcharts" [options]="prayerRequestchartOptions"
                            style="width: 100%; height: 300px; display: block;"></highcharts-chart>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-6">
        <div class="charts-bg">
          <div class="pt-3 ml-3 d-flex">
            <div class="d-flex pt-3 pb-2 mt-1 mr-auto w-50">
              <div class="user-header-back">
                <h5 class="ml-2">Cause</h5>
                <div class="present-weeks ml-2 mt-4">
                  <div class="d-flex">
                    <div class="d-flex mr-3">
                      <span class="present-dot"></span>
                      <h5>Job</h5>
                    </div>
                    <div class="d-flex mr-3">
                      <span class="romania-dot"></span>
                      <h5> Family </h5>
                    </div>
                    <div class="d-flex mr-3 ">
                      <span class="romania-dot b-c-dot"></span>
                      <h5>Exam</h5>
                    </div>
                    <div class="d-flex mr-3">
                      <span class="romania-dot c-dot"></span>
                      <h5>Financial Problems</h5>
                    </div>
                    <div class="d-flex mr-3">
                      <span class="romania-dot"></span>
                      <h5> Marriage</h5>
                    </div>
                    <div class="d-flex mr-3">
                      <span class="romania-dot m-p-dot"></span>
                      <h5>Others</h5>
                    </div>
                    <div class="d-flex mr-3"><span class="romania-dot a-s-dot"></span><h5>Healing</h5></div>
                    <div class="d-flex mr-3"><span class="romania-dot p-f-dot"></span><h5>Spiritual Life</h5></div><div class="d-flex mr-3"><span class="romania-dot p-f-dot"></span><h5>Children</h5></div><div class="d-flex mr-3"><span class="romania-dot p-f-dot"></span><h5>Emergency</h5></div><div class="d-flex mr-3"><span class="romania-dot p-f-dot"></span><h5>Deliverance</h5></div><div class="d-flex mr-3"><span class="romania-dot p-f-dot"></span><h5>Other</h5></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="export-button mt-2 mr-3">
              <button type="button" class="">
                <img src="../../../assets/images/panel_images/export.svg" class="mr-2">Export </button>
            </div>
          </div>
          <div class="chart ml-3 pt-3 pb-3 mr-3">
            <highcharts-chart [Highcharts]="highcharts" [options]="prayerRequestchartOptionsAnalytics" style="width: 100%; height: 300px; display: block;"></highcharts-chart>
          </div>
        </div>
      </div> -->
        </div>
    </div>
</div>
<!-- prayer-request-Analystics -->
<!-- Donations-Analystics -->
<div class="user-header-details-img-section mt-4 mx-4 bg-white" *ngIf="permissionsList.Donation?.analyse">
    <div class="container-fluid px-0 pt-4 mb-4">
        <div class="ml-3 Analytics-one">
            <div class="d-flex">
                <div class="mt-1 mr-auto">
                    <div class="user-list-heading d-flex">
                        <img src="../../assets/images/panel_images/donations.svg" class="user-list mr-2" />
                        <h6 class="mt-2">Donations</h6>
                    </div>
                </div>
                <div class="ml-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Select Year</mat-label>
                        <mat-select [(ngModel)]="selectedYearDonation" (selectionChange)="selectYearDonation($event)">
                            <mat-option *ngFor="let year of yearList" [value]="year">
                                {{ year }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="ml-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Select Month</mat-label>
                        <mat-select [(ngModel)]="selectedMonthDonation" (selectionChange)="selectMonthDonation($event)">
                            <mat-option *ngFor="let month of monthListDonation" [value]="month.value">
                                {{ month.key }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="calendra-field mr-3 ml-3">
                    <mat-form-field class="example-full-width" appearance="fill"> <span matPrefix>Date:&nbsp;</span>
                        <mat-date-range-input [rangePicker]="picker3">
                            <input matStartDate class="ml-1" placeholder="Start date" readonly="readonly"
                                (dateChange)="onChangeDonationsDate($event)" [value]="startDateDonation">
                            <input matEndDate placeholder="End date" readonly="readonly"
                                (dateChange)="onChangeDonationsToDate($event)" [value]="endDateDonation">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker3">
                            <mat-icon matDatepickerToggleIcon><img src="../../assets/images/panel_images/c-11.svg"
                                    class="calendra-img" /></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-date-range-picker #picker3></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <div class="graph cursor mr-2" (click)="resetDonationFilter()"> <img
                        src="../../assets/images/panel_images/reset-1.png" class="reset-img ml-2"> </div>
            </div>
        </div>
        <div class="d-flex ml-3 mt-2">
            <div class="card users-count mr-3 total-user-bg">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/d-1.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">Total Donation</h4>
                        <h2 class="mt-2">{{ totalDonationData ? (totalDonationData | records) : 0}}</h2>
                    </div>
                </div>
            </div>
            <div class="card users-count mr-3 total-active-bg">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/d-2.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">Donations</h4>
                        <h2 class="mt-2">{{ activeDonationData ? (activeDonationData | records) : 0}}</h2>
                    </div>
                </div>
            </div>
            <div class="card users-count mr-3 total-existing-bg">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/d-3.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">Total Donors</h4>
                        <h2 class="mt-2">{{completedDonationData ? (completedDonationData | records) : 0}}</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4 ml-1 mr-xl-1">
            <div class="col-md-6">
                <div class="charts-bg">
                    <div class="pt-3 ml-3 d-flex">
                        <div class="d-flex pt-3 pb-2 mt-1 mr-auto">
                            <div class="user-header-back">
                                <h5 class="ml-2">Donation Amount</h5>
                            </div>
                        </div>
                        <div class="export-button mt-2 mr-3">
                            <button type="button" class="cursor" (click)="donationAmountDataExportBtn()">
                                <img src="../../../assets/images/panel_images/export.svg" class="mr-2">Export </button>
                        </div>
                    </div>
                    <div class="chart ml-3 pt-3 pb-3 mr-3">
                        <h1 class="no-data" *ngIf="DonationDataMembers == 0">Loading...</h1>
                        <highcharts-chart [Highcharts]="highcharts" [options]="donationchartOptions"
                            style="width: 100%; height: 346px; display: block;"></highcharts-chart>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-6">
        <div class="charts-bg">
          <div class="pt-3 ml-3 d-flex">
            <div class="d-flex pt-3 pb-2 mt-1 mr-auto w-50">
              <div class="user-header-back">
                <h5 class="ml-2">Cause</h5>
                <div class="present-weeks ml-2 mt-4">
                  <div class="d-flex">
                    <div class="d-flex mr-3">
                      <span class="present-dot"></span>
                      <h5>Tithing</h5>
                    </div>
                    <div class="d-flex mr-3">
                      <span class="romania-dot"></span>
                      <h5> Offering </h5>
                    </div>
                    <div class="d-flex mr-3 ">
                      <span class="romania-dot b-c-dot"></span>
                      <h5>TV Program</h5>
                    </div>
                    <div class="d-flex mr-3">
                      <span class="romania-dot c-dot"></span>
                      <h5>New Church Construction</h5>
                    </div>
                    <div class="d-flex mr-3"><span class="romania-dot"></span><h5> Thanks Giving</h5></div><div class="d-flex mr-3"><span class="romania-dot m-p-dot"></span><h5>Ministry</h5></div><div class="d-flex mr-3"><span class="romania-dot a-s-dot"></span><h5>Special Offering</h5></div><div class="d-flex mr-3"><span class="romania-dot p-f-dot"></span><h5>Anonymous</h5></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="export-button mt-2 mr-3">
              <button type="button" class="">
                <img src="../../../assets/images/panel_images/export.svg" class="mr-2">Export </button>
            </div>
          </div>
          <div class="chart ml-3 pt-3 pb-3 mr-3">
            <highcharts-chart [Highcharts]="highcharts" [options]="DonationchartOptionsAnalytics" style="width: 100%; height: 310px; display: block;"></highcharts-chart>
          </div>
        </div>
      </div> -->
            <div class="col-md-6">
                <div class="charts-bg">
                    <div class="pt-3 ml-3 d-flex">
                        <div class="d-flex pt-3 pb-2 mt-1 mr-auto">
                            <div class="user-header-back">
                                <h5 class="ml-2">Users Donating</h5>
                            </div>
                        </div>
                        <div class="export-button mt-2 mr-3">
                            <button type="button" class="cursor" (click)="donationUserDataExportBtn()">
                                <img src="../../../assets/images/panel_images/export.svg" class="mr-2">Export </button>
                        </div>
                    </div>
                    <div class="chart ml-3 pt-3 pb-3 mr-3">
                        <h1 class="no-data" *ngIf="DonationDataMembers == 0">Loading...</h1>
                        <highcharts-chart [Highcharts]="highcharts" [options]="donationsecondchartOptions"
                            style="width: 100%; height: 346px; display: block;"></highcharts-chart>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row mt-4 ml-1 mr-xl-1">
      <div class="col-md-6">
        <div class="charts-bg">
          <div class="pt-3 ml-3 d-flex">
            <div class="d-flex pt-3 pb-2 mt-1 mr-auto">
              <div class="user-header-back">
                <h5 class="ml-2">Users Donating</h5>
              </div>
            </div>
            <div class="export-button mt-2 mr-3">
              <button type="button" class="">
                <img src="../../../assets/images/panel_images/export.svg" class="mr-2">Export </button>
            </div>
          </div>
          <div class="chart ml-3 pt-3 pb-3 mr-3">
            <highcharts-chart [Highcharts]="highcharts" [options]="donationsecondchartOptions" style="width: 100%; height: 346px; display: block;"></highcharts-chart>
          </div>
        </div>
      </div>
      <div class="col-md-6"></div>
    </div> -->
    </div>
</div>
<!-- Donations-Analystics -->
<!-- tv-program-Analystics -->
<div class="user-header-details-img-section mt-4 mx-4 bg-white" *ngIf="permissionsList.tv_programs?.analyse">
    <div class="container-fluid px-0 pt-4 mb-4">
        <div class="ml-3 Analytics-one">
            <div class="d-flex">
                <div class="mt-1 mr-auto">
                    <div class="user-list-heading d-flex">
                        <img src="../../assets/images/panel_images/tv-1.svg" class="user-list mr-2" />
                        <h6 class="mt-2">TV Programs</h6>
                    </div>
                </div>
                <div class="ml-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Select Year</mat-label>
                        <mat-select [(ngModel)]="selectedYearTvPrograms"
                            (selectionChange)="selectYearTvPrograms($event)">
                            <mat-option *ngFor="let year of yearList" [value]="year">
                                {{ year }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="ml-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Select Month</mat-label>
                        <mat-select [(ngModel)]="selectedMonthTvPrograms"
                            (selectionChange)="selectMonthTvPrograms($event)">
                            <mat-option *ngFor="let month of monthListTvPrograms" [value]="month.value">
                                {{ month.key }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="calendra-field mr-3 ml-3">
                    <mat-form-field class="example-full-width" appearance="fill"> <span matPrefix>Date:&nbsp;</span>
                        <mat-date-range-input [rangePicker]="picker4">
                            <input matStartDate class="ml-1" placeholder="Start date" readonly="readonly"
                                (dateChange)="onChangeTvProgramDate($event)" [value]="startDateTvPrograms">
                            <input matEndDate placeholder="End date" readonly="readonly"
                                (dateChange)="onChangeTvProgramToDate($event)" [value]="endDateTvPrograms">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker4">
                            <mat-icon matDatepickerToggleIcon><img src="../../assets/images/panel_images/c-11.svg"
                                    class="calendra-img" /></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-date-range-picker #picker4></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <div class="graph cursor mr-2" (click)="resetTvProgramsFilter()"> <img
                        src="../../assets/images/panel_images/reset-1.png" class="reset-img ml-2"> </div>
            </div>
        </div>
        <div class="d-flex ml-3 mt-2">
            <div class="card users-count mr-3 total-user-bg">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/d-1.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">Total Sponsorships</h4>
                        <h2 class="mt-2">₹ {{ totalTvPrograms ? (totalTvPrograms | records ) : 0 }}</h2>
                    </div>
                </div>
            </div>
            <div class="card users-count mr-3 total-active-bg">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/d-2.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">Sponsors</h4>
                        <h2 class="mt-2">{{ completedTvPrograms ? (completedTvPrograms | records):0 }}</h2>
                    </div>
                </div>
            </div>
            <div class="card users-count mr-3 total-existing-bg">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/d-3.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">TV Programs</h4>
                        <h2 class="mt-2">{{activeTvPrograms ? (activeTvPrograms | records) : 0 }}</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4 ml-1 mr-xl-1">
            <div class="col-md-6">
                <div class="charts-bg">
                    <div class="pt-3 ml-3 d-flex">
                        <div class="d-flex pt-3 pb-2 mt-1 mr-auto ">
                            <div class="user-header-back">
                                <h5 class="ml-2">Sponsor Amount</h5>
                            </div>
                        </div>
                        <div class="export-button mt-2 mr-3">
                            <button type="button" class="cursor" (click)="TvProgramDataAmountExportBtn()">
                                <img src="../../../assets/images/panel_images/export.svg" class="mr-2">Export </button>
                        </div>
                    </div>
                    <div class="chart ml-3 pt-3 pb-3 mr-3">
                        <highcharts-chart [Highcharts]="highcharts" [options]="tvProgramschartOptions"
                            style="width: 100%; height: 346px; display: block;"></highcharts-chart>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-6">
        <div class="charts-bg">
          <div class="pt-3 ml-3 d-flex">
            <div class="d-flex pt-3 pb-2 mt-1 mr-auto w-50">
              <div class="user-header-back">
                <h5 class="ml-2">Language</h5>
                <div class="present-weeks ml-2 mt-4">
                  <div class="d-flex">
                    <div class="d-flex mr-3">
                      <span class="present-dot"></span>
                      <h5>Punjabi</h5>
                    </div>
                    <div class="d-flex mr-3">
                      <span class="romania-dot"></span>
                      <h5> Telugu </h5>
                    </div>
                    <div class="d-flex mr-3 ">
                      <span class="romania-dot b-c-dot"></span>
                      <h5>English</h5>
                    </div>
                    <div class="d-flex mr-3">
                      <span class="romania-dot c-dot"></span>
                      <h5>Hindi</h5>
                    </div>
                    <div class="d-flex mr-3">
                      <span class="romania-dot"></span>
                      <h5> Gujarati</h5>
                    </div>
                    <div class="d-flex mr-3">
                      <span class="romania-dot m-p-dot"></span>
                      <h5>Malyalam</h5>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="export-button mt-2 mr-3">
              <button type="button" class="">
                <img src="../../../assets/images/panel_images/export.svg" class="mr-2">Export </button>
            </div>
          </div>
          <div class="chart ml-3 pt-3 pb-3 mr-3">
            <highcharts-chart [Highcharts]="highcharts" [options]="tvProgramschartOptionsAnalytics" style="width: 100%; height: 310px; display: block;"></highcharts-chart>
          </div>
        </div>
      </div> -->
            <div class="col-md-6">
                <div class="charts-bg">
                    <div class="pt-3 ml-3 d-flex">
                        <div class="d-flex pt-3 pb-2 mt-1 mr-auto">
                            <div class="user-header-back">
                                <h5 class="ml-2">Sponsors </h5>
                            </div>
                        </div>
                        <div class="export-button mt-2 mr-3">
                            <button type="button" class="cursor" (click)="TvProgramDataExportBtn()">
                                <img src="../../../assets/images/panel_images/export.svg" class="mr-2">Export </button>
                        </div>
                    </div>
                    <div class="chart ml-3 pt-3 pb-3 mr-3">
                        <highcharts-chart [Highcharts]="highcharts" [options]="tvProgramsSponsorchartOptions"
                            style="width: 100%; height: 346px; display: block;"></highcharts-chart>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row mt-4 ml-1 mr-xl-1">
      <div class="col-md-6">
        <div class="charts-bg">
          <div class="pt-3 ml-3 d-flex">
            <div class="d-flex pt-3 pb-2 mt-1 mr-auto">
              <div class="user-header-back">
                <h5 class="ml-2">Sponsors </h5>
              </div>
            </div>
            <div class="export-button mt-2 mr-3">
              <button type="button" class="">
                <img src="../../../assets/images/panel_images/export.svg" class="mr-2">Export </button>
            </div>
          </div>
          <div class="chart ml-3 pt-3 pb-3 mr-3">
            <highcharts-chart [Highcharts]="highcharts" [options]="tvProgramschartOptions" style="width: 100%; height: 346px; display: block;"></highcharts-chart>
          </div>
        </div>
      </div>
      <div class="col-md-6"></div>
    </div> -->
    </div>
</div>
<!-- tv-programs-Analystics -->
<!-- support-Analystics -->
<div class="user-header-details-img-section mt-4 mb-4 mx-4 bg-white" *ngIf="permissionsList.support?.analyse">
    <div class="container-fluid px-0 pt-4 mb-4">
        <div class="ml-3 Analytics-one">
            <div class="d-flex">
                <div class="mt-1 mr-auto">
                    <div class="user-list-heading d-flex">
                        <img src="../../assets/images/panel_images/support-9.svg" class="user-list mr-2" />
                        <h6 class="mt-2">Support</h6>
                    </div>
                </div>
                <div class="ml-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Select Year</mat-label>
                        <mat-select [(ngModel)]="selectedYearSupport" (selectionChange)="selectYearSupport($event)">
                            <mat-option *ngFor="let year of yearList" [value]="year">
                                {{ year }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="ml-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Select Month</mat-label>
                        <mat-select [(ngModel)]="selectedMonthSupport" (selectionChange)="selectMonthSupport($event)">
                            <mat-option *ngFor="let month of monthListTvPrograms" [value]="month.value">
                                {{ month.key }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="calendra-field mr-3 ml-3">
                    <mat-form-field class="example-full-width" appearance="fill"> <span matPrefix>Date:&nbsp;</span>
                        <mat-date-range-input [rangePicker]="picker5">
                            <input matStartDate class="ml-1" placeholder="Start date" readonly="readonly"
                                (dateChange)="onChangeSupportDate($event)" [value]="startDateTvSupport">
                            <input matEndDate placeholder="End date" readonly="readonly"
                                (dateChange)="onChangeSuppportToDate($event)" [value]="endDateTvSupport">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker5">
                            <mat-icon matDatepickerToggleIcon><img src="../../assets/images/panel_images/c-11.svg"
                                    class="calendra-img" /></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-date-range-picker #picker5></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <div class="graph cursor mr-2" (click)="resetSupportFilter()"> <img
                        src="../../assets/images/panel_images/reset-1.png" class="reset-img ml-2"> </div>
            </div>
        </div>
        <div class="d-flex ml-3 mt-2">
            <div class="card users-count mr-3 total-user-bg">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/sr-1.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">Support Requests</h4>
                        <h2 class="mt-2">{{ totalsupport ? (totalsupport | records) : 0}}</h2>
                    </div>
                </div>
            </div>
            <div class="card users-count mr-3 total-active-bg">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/sr-2.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">Open</h4>
                        <h2 class="mt-2">{{ activesupport ? (activesupport |records) : 0}}</h2>
                    </div>
                </div>
            </div>
            <div class="card users-count mr-3 total-existing-bg">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/sr-3.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">Closed</h4>
                        <h2 class="mt-2">{{completedsupport ? (completedsupport |records) : 0}}</h2>
                    </div>
                </div>
            </div>
            <div class="card users-count mr-3 total-non-bg">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/sr-4.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">Invalid Requests</h4>
                        <h2 class="mt-2">{{ cancelledsupport ? (cancelledsupport |records) : 0}}</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="charts-bg mt-4 mx-3 ">
            <div class="pt-3 ml-3 d-flex">
                <div class="d-flex pt-3 pb-2 mt-1 mr-auto">
                    <div class="user-header-back">
                        <h5 class="ml-2">Support Tickets</h5>
                        <div class="present-weeks ml-2 mt-4">
                            <div class="d-flex">
                                <div class="d-flex mr-3">
                                    <span class="support-dot romania-dot"></span>
                                    <h5>Open</h5>
                                </div>
                                <div class="d-flex">
                                    <span class="romania-dot support-closed-dot"></span>
                                    <h5>Closed</h5>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="export-button mt-2 mr-3">
                    <button type="button" class="cursor" (click)="supportDataExportBtn()">
                        <img src="../../../assets/images/panel_images/export.svg" class="mr-2">Export </button>
                </div>
            </div>
            <div class="chart ml-3 pt-3 pb-3 mr-3">
                <highcharts-chart [Highcharts]="highcharts" [options]="supportchartOptions"
                    style="width: 100%; height: 300px; display: block;"></highcharts-chart>
            </div>
        </div>
    </div>
</div>
<!-- member-list-Analystics -->