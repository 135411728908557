import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-sucessdialogcmsmodule',
  templateUrl: './sucessdialogcmsmodule.component.html',
  styleUrls: ['./sucessdialogcmsmodule.component.css'],
})
export class SucessdialogcmsmoduleComponent implements OnInit {
  role: any;
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.role = localStorage.getItem('ROLE');
  }
  close() {
    this.dialog.closeAll();
  }
}
