<app-showloader></app-showloader>

<div class="container-fluid bg-white">
  <div class="pt-3 ml-1">
    <div class="d-flex pt-3 pb-2">
      <div class="back-btn mr-2 cursor" routerLink="/Userslist">
        <img src="../../../assets/images/panel_images/back.svg" class="back-img" />
      </div>
      <div class="user-header-back">
        <h5>Members List</h5>
        <p><span>Home / </span>Members List</p>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid mt-4">
  <div class="user-header-details-img-section pt-5 bg-white mx-2" *ngFor="let user of userprofileDetails">
    <div class="user-details-details-img d-flex mb-4">
      <div class="d-flex mr-auto">
        <div class="mr-3 ml-4" [class.no-profile]="user?.profile == null || user?.profile == ''">
          <img *ngIf="user?.profile != null" src="{{ user?.profile }}" class="user-img-1" />
          <ngx-avatar *ngIf="user?.profile == null || user?.profile == ''" class="mr-2 name-a" initialsSize="1"
            size="80" name="{{ user?.name }}"></ngx-avatar>
          <ngx-avatar *ngIf="user?.name == null" class="mr-2 name-a" initialsSize="1" size="80" name="N/A"></ngx-avatar>
        </div>
        <div class="details_user mt-4">
          <h4 [class.no-name]="user?.name == null">
            {{ user.name ? user.name : "N/A" }}
          </h4>
          <p *ngIf="user.membershipId != null || user.membership == ''">
            {{ user.membershipId ? user.membershipId : "" }}
          </p>
        </div>
        <!-- <div class="edit-user mr-3">
            <button type="button" class="btn">Edit <img src="../../../assets/images/panel_images/edit-user.svg"></button>
          </div> -->
      </div>
    </div>
    <hr class="hr-line-user" />

    <div class="details-section mt-4 ml-5">
      <div class="d-flex">
        <div class="details-heading">
          <h4>Gender:</h4>
        </div>
        <div class="details-heading-user">
          <h4>{{ user?.gender ? user?.gender : "-" }}</h4>
        </div>
      </div>
      <div class="d-flex">
        <div class="details-heading">
          <h4>Email:</h4>
        </div>
        <div class="details-heading-user">
          <h4>{{ user?.email ? user?.email : "-" }}</h4>
        </div>
      </div>
      <div class="d-flex">
        <div class="details-heading">
          <h4>Mobile No:</h4>
        </div>
        <div class="details-heading-user">
          <h4>{{ user?.phone ? user.phone : "-" }}</h4>
        </div>
      </div>
      <div class="d-flex">
        <div class="details-heading">
          <h4>Date of birth :</h4>
        </div>
        <div class="details-heading-user">
          <h4>{{ user?.dob ? (user.dob | date : "dd-MM-yyyy") : "-" }}</h4>
        </div>
      </div>
      <div class="d-flex">
        <div class="details-heading">
          <h4>Attendance:</h4>
        </div>
        <div class="details-heading-user">
          <!-- <h4>{{ user.Value ? user.Value : "NA" }}</h4> -->
          <h4>
            {{ attendenceData?.present ? attendenceData.present : "NA" }} %
          </h4>
        </div>
      </div>
      <div class="d-flex">
        <div class="details-heading">
          <h4>Eligibility:</h4>
        </div>
        <div class="details-heading-user">
          <!-- <h4>{{ user.Value ? user.Value : "NA" }}</h4> -->
          <h4>
            {{ attendenceData?.present >= 75 ? "Eligible" : "Not Eligible" }}
          </h4>
        </div>
      </div>
    </div>
  </div>

  <div class="user-header-details-img-section mx-2 bg-white">
    <mat-tab-group>
      <mat-tab label="Attendance">
        <div class="ml-2">
          <div class="container-fluid bg-white">
            <div class="user-list-header d-flex pt-4 pb-3 ml-2">
              <!-- <div class="user-list-heading d-flex">
                <h6 class="mt-2">Filter</h6>
              </div> -->

              <!-- <div class="calendra-field mr-3 ml-3">
                <mat-form-field class="example-full-width" appearance="fill">
                  <input
                    matInput
                    (dateChange)="onChangeDate($event)"
                    [matDatepicker]="picker"
                    [max]="today"
                    readonly="readonly"
                  />
                  <span matPrefix>Date:&nbsp;</span>
                  <mat-datepicker-toggle matSuffix [for]="picker">
                    <mat-icon matDatepickerToggleIcon
                      ><img
                        src="../../assets/images/panel_images/c-11.svg"
                        class="calendra-img"
                    /></mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div> -->

              <div class="user-list-drop-down ml-auto d-flex mr-3">
                <mat-form-field appearance="fill">
                  <mat-label>Select Year</mat-label>
                  <mat-select (selectionChange)="selectYear($event)">
                    <mat-option *ngFor="let year of yearList" [value]="year">
                      {{ year }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="graph cursor mr-4" (click)="reset()">
                <img src="../../assets/images/panel_images/reset-1.png" class="reset-img ml-2" />
              </div>

              <!-- <div class="create-btn-section mx-2">
                <button type="button" class="create-btn">Apply</button>
              </div> -->
            </div>
          </div>
          <div class="attendance-section mr-3" *ngFor="let i of allAttendenceData">
            <div class="d-flex">
              <div class="mr-auto d-flex">
                <div class="year-attendance mr-5 ml-3">
                  <h3>{{ i.year }}</h3>
                </div>
                <div class="present-weeks ml-3">
                  <div class="d-flex">
                    <span class="present-dot"></span>
                    <h5>Present</h5>
                  </div>
                  <h6>{{ i.presentWeek }} Weeks</h6>
                </div>
                <div class="absent-weeks ml-3">
                  <div class="d-flex">
                    <span class="absent-dot"></span>
                    <h5>Absent</h5>
                  </div>
                  <h6>{{ i.absentWeek }} Weeks</h6>
                </div>
              </div>

              <div class="progress-bar-success">
                <apx-chart [series]="[i.present]" [chart]="chartOptionsExcellent.chart"
                  [plotOptions]="chartOptionsExcellent.plotOptions" [labels]="chartOptionsExcellent.labels"
                  [colors]="[i.present >= 75 ? '#00ff00' : '#FF0000']"></apx-chart>
                <!-- [colors]="['#0dbc67', '#0dbc67', '#0dbc67']" -->
                <!-- <div class="progress">
                  <span
                    class="title timer"
                    data-from="0"
                    data-to="50"
                    data-speed="1800"
                    style="color: green;"
                    >85</span
                  >
                  <div class="overlay"></div>
                  <div class="left"></div>
                  <div class="right"></div>
                </div> -->
              </div>
            </div>
          </div>
          <!-- <div class="attendance-section mt-4 mb-3 mr-3">
            <div class="d-flex">
              <div class="mr-auto d-flex">
                <div class="year-attendance mr-5 ml-3">
                  <h3>2021</h3>
                </div>
                <div class="present-weeks ml-3">
                  <div class="d-flex">
                    <span class="present-dot"></span>
                    <h5>Present</h5>
                  </div>
                  <h6>17 Weeks</h6>
                </div>
                <div class="absent-weeks ml-3">
                  <div class="d-flex">
                    <span class="absent-dot"></span>
                    <h5>Absent</h5>
                  </div>
                  <h6>04 Weeks</h6>
                </div>
              </div>
              <div class="progress-bar-success"></div>
            </div>
          </div>
          <div class="attendance-section mt-4 mb-3 mr-3">
            <div class="d-flex">
              <div class="mr-auto d-flex">
                <div class="year-attendance mr-5 ml-3">
                  <h3>2021</h3>
                </div>
                <div class="present-weeks ml-3">
                  <div class="d-flex">
                    <span class="present-dot"></span>
                    <h5>Present</h5>
                  </div>
                  <h6>17 Weeks</h6>
                </div>
                <div class="absent-weeks ml-3">
                  <div class="d-flex">
                    <span class="absent-dot"></span>
                    <h5>Absent</h5>
                  </div>
                  <h6>04 Weeks</h6>
                </div>
              </div>
              <div class="progress-bar-success"></div>
            </div>
          </div> -->
        </div>
        <!-- <div class="previous-re py-md-5 pl-md-4">
          <p>No Previous Records</p>
        </div> -->
      </mat-tab>
      <mat-tab label="Prayer Request">
        <div class="previous-re py-md-5 pl-md-4" *ngIf="userPreviousPrayerRequest == ''">
          <p>No Previous Records</p>
        </div>
        <div class="mt-3" *ngIf="userPreviousPrayerRequest != ''">
          <div class="prayer-request-section d-flex ml-4 pt-3 pb-2" *ngFor="let request of userPreviousPrayerRequest">
            <div class="mr-auto">
              <div class="d-flex">
                <p>
                  {{
                  request?.prayerId?.title ? request?.prayerId?.title : "-"
                  }}
                  ({{
                  request?.prayerForWhomId?.name
                  ? request?.prayerForWhomId?.name
                  : "-"
                  }}) <span class="p-dot ml-2 mr-2"></span>
                </p>
                <p>{{ request.createdAt | date : "dd-MM-yyyy" }}</p>
              </div>

              <div class="request">
                <p>{{ request.description }}</p>
              </div>
            </div>
            <div class="completed mr-4">
              <h3 *ngIf="request.status == 'completed'" class="completedtype">
                {{ request.status | titlecase }}
              </h3>
              <h3 *ngIf="request.status == 'in-progress'" class="not-eligable-option">
                New
              </h3>
              <h3 *ngIf="request.status == 'cancelled'" class="rejected">
                {{ request.status | titlecase }}
              </h3>
            </div>
          </div>
        </div>
        <mat-paginator [pageSize]="pageSize" [pageIndex]="pageIndex" [length]="totalRecords"
          (page)="onPageChanged($event,'prayerReq')" [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
      </mat-tab>
      <mat-tab label="Service Request">
        <div class="previous-re py-md-5 pl-md-4" *ngIf="userPreviousServiceRequest == ''">
          <p>No Previous Records</p>
        </div>
        <div *ngIf="userPreviousServiceRequest != ''">
          <div class="serivce-request d-flex mt-4 ml-3 mr-3" *ngFor="let service of userPreviousServiceRequest">
            <!-- *ngFor="let service of userPreviousServiceRequest?.slice(0, 10)" -->
            <div class="d-flex mr-auto ml-3">
              <div class="service-req-details-img mr-3">
                <img src="{{ this.url }}{{ service?.serviceId?.image }}" class="service-img" />
              </div>
              <div class="service-req-details mt-2">
                <h4>
                  {{
                  service?.serviceId?.title ? service?.serviceId?.title : "-"
                  }}
                </h4>
                <p>
                  {{
                  service.createdAt
                  ? (service.createdAt | date : "dd-MM-yyyy")
                  : "N/A"
                  }}
                </p>
              </div>
            </div>

            <div class="completed mr-3 mt-2">
              <h3 *ngIf="service.status == 'completed'" class="completedtype">
                {{ service.status | titlecase }}
              </h3>
              <h3 *ngIf="service.status == 'accepted'" class="completedtype">
                {{ service.status | titlecase }}
              </h3>
              <h3 *ngIf="service.status == 'in-progress'" class="not-eligable-option">
                New
              </h3>
              <h3 *ngIf="service.status == 'cancelled'" class="rejected">
                {{ service.status | titlecase }}
              </h3>
            </div>
          </div>
        </div>
        <mat-paginator [pageSize]="servicePageSize" [pageIndex]="servicePageIndex" [length]="totalServiceRecords"
          (page)="onPageChanged($event,'serviceReq')" [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
      </mat-tab>
      <mat-tab label="Donations">
        <div class="previous-re py-md-5 pl-md-4" *ngIf="userDonations == ''">
          <p>No Previous Records</p>
        </div>
        <div class="mt-3 ml-3 mr-3" *ngIf="userDonations != ''">
          <div class="d-flex pt-1 pb-1" *ngFor="let donate of userDonations; let i = index">
            <div class="d-flex mr-auto">
              <div class="bank-img mr-2">
                <div class="count">
                  <p>{{ i + 1 }}</p>
                </div>
              </div>
              <div class="bank-details mt-2">
                <h5>{{ donate.method }}</h5>
                <h4>
                  {{ donate?.createdAt | date }} at
                  {{ donate?.createdAt | date : "shortTime" }}
                </h4>
              </div>
            </div>
            <div class="amount mt-2">
              <p>₹ {{ donate.amount }}</p>
            </div>
          </div>
        </div>
        <mat-paginator [pageIndex]="donationPageIndex" [pageSize]="donationPageSize" [length]="totalDonationRecords"
          (page)="onPageChanged($event , 'donationReq')" [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
      </mat-tab>
      <!-- <mat-tab label="Committments">
        <div class="previous-re py-md-5 pl-md-4" *ngIf="userCommitments == ''">
          <p>No Previous Records</p>
        </div>
        <div class="mt-3 ml-3 mr-3" *ngIf="userCommitments != ''">
          <div class="d-flex pt-3 pb-3" *ngFor="let commitment of userCommitments; index as i">
            <div class="d-flex mr-auto">
              <div class="count">
                <p>{{ i + 1 }}</p>
              </div>

              <div class="details-committments">
                <div class="details-count d-flex">
                  <img src="../../../assets/images/panel_images/c1.svg" class="c1-img" />
                  <p>{{ commitment?.date | date }}</p>
                </div>
                <h6>{{ commitment?.categoryId?.name }}</h6>
                <h4>{{ commitment?.commitmentDescription }}</h4>
              </div>
            </div>
            <div class="commitiment-obeying mt-3">
              <p>
                <span>{{ commitment?.obeying }}%</span>&nbsp;&nbsp;Obeying
              </p>
              <h4>
                <span>{{ commitment?.disobedience }}%</span>&nbsp;&nbsp;Disobedience
              </h4>
            </div>
          </div>
          <hr class="mx-0 my-0" />
        </div>
        <mat-paginator [pageSize]="commitmentPageSize" [pageIndex]="commitmentPageIndex"
          [length]="totalCommitmentRecords" (page)="onPageChanged($event , 'commitmentReq')"
          [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
      </mat-tab> -->
    </mat-tab-group>
  </div>
</div>