import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: 'button[appOldPasswordToggle]',
  exportAs: 'appOldPasswordToggle',
})
export class OldPasswordToggleDirective {
  constructor() {}
  public get type(): string {
    return this.showOldPassword ? 'text' : 'password';
  }

  public get icon(): string {
    return this.showOldPassword ? 'visibility_off' : 'visibility';
  }

  private showOldPassword: boolean = false;

  @HostListener('click')
  public onClickold(): void {
    this.showOldPassword = !this.showOldPassword;
  }
}
