import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import * as moment from 'moment';
import { DashboardServicesService } from '../_services/dashboard-services.service';
import { SucessdialogcmsmoduleComponent } from './sucessdialogcmsmodule/sucessdialogcmsmodule.component';
declare const $: any;
export interface PeriodicElement {
  user: string;
  Requestdescription: any;
  Date: any;
  status: any;
  image: any;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    user: 'Kristin Watson',
    Requestdescription: 'Lorem Ipsum',
    Date: '23-04-2023',
    status: 'Apporved',
    image: '../../assets/images/panel_images/user-1.svg',
  },
  {
    user: 'Kristin Watson',
    Requestdescription: 'Lorem Ipsum',
    Date: '23-04-2023',
    status: 'In Review',
    image: '../../assets/images/panel_images/user-1.svg',
  },
  {
    user: 'Kristin Watson',
    Requestdescription: 'Lorem Ipsum',
    Date: '23-04-2023',
    status: 'Apporved',
    image: '../../assets/images/panel_images/user-1.svg',
  },
  {
    user: 'Kristin Watson',
    Requestdescription: 'Lorem Ipsum',
    Date: '23-04-2023',
    status: 'Apporved',
    image: '../../assets/images/panel_images/user-1.svg',
  },
  {
    user: 'Kristin Watson',
    Requestdescription: 'Lorem Ipsum',
    Date: '23-04-2023',
    status: 'Apporved',
    image: '../../assets/images/panel_images/user-1.svg',
  },
  {
    user: 'Kristin Watson',
    Requestdescription: 'Lorem Ipsum',
    Date: '23-04-2023',
    status: 'Rejected',
    image: '../../assets/images/panel_images/user-1.svg',
  },
  {
    user: 'Kristin Watson',
    Requestdescription: 'Lorem Ipsum',
    Date: '23-04-2023',
    status: 'In Review',
    image: '../../assets/images/panel_images/user-1.svg',
  },
  {
    user: 'Kristin Watson',
    Requestdescription: 'Lorem Ipsum',
    Date: '23-04-2023',
    status: 'Rejected',
    image: '../../assets/images/panel_images/user-1.svg',
  },
  {
    user: 'Kristin Watson',
    Requestdescription: 'Lorem Ipsum',
    Date: '23-04-2023',
    status: 'In Review',
    image: '../../assets/images/panel_images/user-1.svg',
  },
];
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import * as _moment from 'moment';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
// import { Cipher } from 'crypto';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-cms-module',
  templateUrl: './cms-module.component.html',
  styleUrls: ['./cms-module.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CmsModuleComponent implements OnInit {
  panelOpenState = false;
  reviewPanelOpenState = false;
  tabSelectedIndex = 0;
  Daily: boolean = false;
  Event: boolean = false;
  video: boolean = true;
  submitted: boolean = false;
  url: any;
  selectedFile: File;

  allowedFormat = ['csv', 'CSV', 'xls', 'xlsx', 'XLSX', 'XLS'];
  cmsVideoForm: FormGroup;
  cmsPromiseForm: FormGroup;
  cmsEventForm: FormGroup;
  cmsTvProgramForm: FormGroup;
  cmsService: FormGroup;
  cmsPrayerRequest: FormGroup;
  cmsTempleTest: FormGroup;
  cmsSpiritualFitnessForm: FormGroup;
  cmsDonationForm: FormGroup;
  cmsPushNotifications: FormGroup;
  fileName: any;
  cmsVideoCategory: any;
  promiseCardUrl: any;
  promisesubmitted: boolean = false;
  date: Date;
  format: string;
  eventSubmitted: boolean = false;
  dateSelect: any = '';
  Date: any;
  calendraDate: string;
  selectedTime: any;
  dataAndTime: string;
  scheuledDateAndTime: any;
  afterSelectTime: string;
  daliypromise: any = '';
  promiseTimeClear: any = '';
  selectedFiles: File;
  fileNames: any;
  dateSelectEvent: any = '';
  calendraDateEvent: string;
  selectedTimeEvent: any;
  scheuledDateAndTimeEvent: string;
  attachmentFile: boolean = false;
  uploadFlie: boolean = true;
  serviceUploadFlie: boolean = true;
  serviceAttachmentFile: boolean = false;
  errorTvProgram: any;
  serviceSelectedFile: File;
  serviceFileName: any;
  prayerRequestAttachmentFile: boolean = false;
  prayerRequestUploadFlie: boolean = true;
  templeUploadFlie: boolean = true;
  templeAttachmentFile: boolean = false;
  spiritualUploadFlie: boolean = true;
  spiritualAttachmentFile: boolean = false;
  donationsUploadFlie: boolean = true;
  donationsAttachmentFile: boolean = false;
  submittedDonations: boolean = false;
  prayerFileName: any;
  prayerSelectedFile: File;
  templeFileName: any;
  templeSelectedFile: File;
  spiritualSelectedFile: File;
  spiritualFileName: any;
  donationsSelectedFile: File;
  donationsFileName: any;
  hour: any;

  andriodEvent: any;
  iosEvent: any;
  selectedTimeAfterFormat: string;
  selectedTimeEventAfterFormat: string;
  fileNameEvent: any;
  selectedFileEvent: File;
  name: any = ' Dr. P. Satish Kumar';
  readonly: boolean = true;
  //today's date
  todayDate: Date = new Date();
  minDate: Date;
  maxDate: Date;
  minTime: any;
  maxTime: any;
  currentTIme: any;
  firstname: string;
  lastName: string;
  todayTime: string;
  currentTime: any;
  prayerRequestSubmit: boolean = false;
  SpiritualFitnessSubmit: boolean = false;
  ServiceSubmit: boolean = false;
  submitTemple: boolean = false;
  submitTvPrograms: boolean = false;
  pushSubmitted: boolean = false;
  time: any;
  selectedRadio: any;
  both: boolean = false;
  ios: boolean = false;
  andriod: any;
  dailPromiseTimeErr: string = null;
  eventsTimeErr: string = null;
  eventsEndTimeErr: string = null;
  pushNotifyTimeErr: string = null;
  eventTimeErr: string = null;
  currTime: any;
  currTime1: any;
  prayerRequest: boolean = false;

  tabIndex: number = 0;

  selectedDate: string = '';
  selectedHour: number;
  selectedMinute: number;
  selectedPeriod: string;
  startDate: any = '';
  startHour: any = '';
  startMinutes: string = '';
  startAM: any = '';
  endDate: any = '';
  endHour: any = '';
  endMinutes: any = '';
  endAM: any = '';
  hours: number[] = Array.from({ length: 12 }, (_, i) => i + 1);
  // minutes: number[] = [0, 15, 30, 45];
  utcDateTime: string = '';

  minutes: any = ([] = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
    '60',
  ]);
  // am: any = ['AM', 'PM'];
  Radiooptions = [
    { key: 'both', value: 'Both' },
    { key: 'android', value: 'Android' },
    { key: 'ios', value: 'iOS' },
  ];

  Radiomediaoptions = [
    { key: 'video', value: 'Video' },
    { key: 'daily promise', value: 'Daily Promise' },
    { key: 'event', value: 'Event' },
  ];

  Radioreviewoptions = [
    { key: 'video', value: 'Video' },
    { key: 'daily promise', value: 'Daily Promise' },
    { key: 'event', value: 'Event' },
  ];

  selectedMedia: string = 'video';
  selectedReviewMedia: string = 'video';
  pushnotificationsUrl: string;
  selectedFilePushnotifications: File;
  fileNamePushnotifications: any;
  dateSelectPush: any = '';
  calendraDatePush: any;
  calendraEndDatePush: any;
  selectedTimePush: any;
  scheuledDateAndTimePushNotifications: string;
  readyDateAndTime: string;
  updatedAt: any;
  approvedRequests: any;
  pendingRequests: any;
  declinedRequests: any;
  totalRequests: any;
  firstName: any;
  lastNameAdmin: any;
  tvProgramFileTypeParams: any;
  cmsHistoryTVProgram: any = 'tvProgram';
  cmsTvProgramData: any;
  ServicesFileTypeParams: any;
  cmsHistoryService: any = 'service';
  donateFileTypeParams: any;
  cmsHistoryDonate: any = 'donate';
  cmsHistoryPrayerReq: any = 'prayer';
  prayerFileTypeParams: any;
  TempleFileTypeParams: any;
  cmsHistoryTemple: any = 'temple';
  cmsHistorySpiritual: any = 'spiritual';
  spiritualFileTypeParams: any;
  updatedAtTvprogram: any;
  firstNameTvprogram: any;
  lastNameAdminTvprogram: any;
  firstNameService: any;
  lastNameAdminService: any;
  updatedAtServcie: any;
  updatedAtDonate: any;
  firstNameDonate: any;
  lastNameAdminDonate: any;
  updatedAtTemple: any;
  firstNameTemple: any;
  lastNameAdminTemple: any;
  updatedAtPrayer: any;
  firstNamePrayer: any;
  lastNameAdminPrayer: any;
  updatedAtspiritual: any;
  firstNamespiritual: any;
  lastNameAdminspriitual: any;
  resNotifications: any;
  resTvPrograms: any;
  loader: boolean = false;
  updatedAtMedia: any;
  mediaFirstName: any;
  mediaLastNameAdmin: any;
  Pushloader: boolean = false;
  mediaLoader: boolean = false;
  promiseLoader: boolean = false;
  eventLoader: boolean = false;
  tvProgramLoader: boolean = false;
  servicesLoader: boolean = false;
  donationsLoader: boolean = false;
  templeLoader: boolean = false;
  prayerReqLoader: boolean = false;
  spiritulFitnessLoader: boolean = false;
  pushbtndisabled: boolean = false;
  role: any;
  reviewCMSModule: any = [];

  readonly NoWhitespaceRegExp: RegExp = new RegExp('\\S');
  resMedia: any;
  readyDateAndTimePromise: string;
  EventUrl: any;
  calendraEndDateEvent: any;
  dateSelectEndEvent: any;
  currentEndTime: string;
  selectedEndTimeEvent: any;
  selectedEndTimeEventAfterFormat: string;
  scheuledEndDateAndTimePushNotifications: any;
  readyEndDateAndTime: string;
  review: any;
  reviewCount: any;
  reviewCountMedias: any;
  DonationsRecords: any;
  reviewSpiritualCount: any;
  reviewTempleCount: any;
  reviewPrayerCount: any;
  status: any = 'pending';
  tvProgramFileType: any;
  serviceFileTypeParams: any;
  serviceRecords: any;
  TvProgram: any;
  TvProgramRecords: any;
  eventSameError: string;

  pageSize: any = 10;
  currentPage: any = 1;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  pageLimit: any;
  pageNumber: any;
  totalCmsNot: number = 0;

  currentPageMedia: any = 0;
  pageSizeMedia: any = 10;
  totalMedia: number = 0;

  currentPageProgram: any = 0;
  pageSizeProgram: any = 10;
  totalTvPrograms: number = 0;

  currentPageService: any = 0;
  pageSizeService: any = 10;
  totalTvServices: number = 0;

  currentPageDonation: any = 0;
  pageSizeDonation: any = 10;
  totalTvDonation: number = 0;

  currentPageTemple: any = 0;
  pageSizeTemple: any = 10;
  totalTvTemple: number = 0;

  currentPagePrayer: any = 0;
  pageSizePrayer: any = 10;
  totalTvPrayer: number = 0;

  currentPageSpirtual: any = 0;
  pageSizeSpirtual: any = 10;
  totalTvSpirtual: number = 0;

  permissons: any;
  ELEMENT_REVIEW_DONATE: any;
  pushEventHour: any = '';
  pushEventMin: any = '';
  pushEventAM: any = '';
  pushStartEventHour: any = '';
  pushStartEventMin: any = '';
  pushStartEventAM: any = '';
  pushEndEventHour: any = '';
  pushEndEventMin: any = '';
  pushEndEventAM: any = '';
  changeDatePushValue: any;
  totalTime: string;
  pushNotificationTime: string;
  pushStartNotificationTime: string;
  pushEndNotificationTime: string;
  currentStateTime: string;

  constructor(
    private dialog: MatDialog,
    private services: DashboardServicesService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService
  ) {
    this.activatedRoute.queryParams.subscribe((res) => {
      this.tabIndex = res.tab ? Number(res.tab) : 0;
    });
    this.cmsPushNotifications = this.fb.group({
      deviceType: ['', [Validators.required]],
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      date: ['', [Validators.required]],
      hour: ['', [Validators.required]],
      minute: ['', [Validators.required]],
      AM: ['', [Validators.required]],
      file: [''],
      url: [
        '',
        [
          Validators.pattern(
            '^((https?|ftp):\\/\\/)?([a-z0-9-]+\\.)+[a-z]{2,}(\\/[^\\s]*)?$'
          ),
          // Validators.pattern('^(https?://)?(www.youtube.com|youtu.be)/.+$')
        ],
      ],
      time: [''],
    });

    this.cmsVideoForm = this.fb.group({
      title: ['', [Validators.required]],
      date: [''],
      description: ['', [Validators.required]],
      speakerName: ['', [Validators.required]],
      categoryId: ['', [Validators.required]],
      youtubeVideo: [
        '',
        [
          Validators.required,
          Validators.pattern('^(https?://)?(www.youtube.com|youtu.be)/.+$'),
        ],
      ],
      thumbnail: [''],
    });

    this.cmsPromiseForm = this.fb.group({
      image: ['', [Validators.required]],
      // date: ['', [Validators.required]],
      // time: ['', [Validators.required]],
      date: ['', [Validators.required]],
      hour: ['', [Validators.required]],
      minute: ['', [Validators.required]],
      AM: ['', [Validators.required]],
      youtubeLink: [
        '',
        Validators.pattern('^(https?://)?(www.youtube.com|youtu.be)/.+$'),
      ],
    });
    this.cmsEventForm = this.fb.group({
      videoFile: ['', [Validators.required]],
      status: 'live',
      startDate: ['', [Validators.required]],
      startHour: ['', [Validators.required]],
      startMinutes: ['', [Validators.required]],
      startAM: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      endHour: ['', [Validators.required]],
      endMinutes: ['', [Validators.required]],
      endAM: ['', [Validators.required]],
      youtubeVideoLink: [
        '',
        [Validators.pattern('^(https?://)?(www.youtube.com|youtu.be)/.+$')],
      ],
      // nextLaunchingVideo: ['', [Validators.required]],
    });
    this.cmsTvProgramForm = this.fb.group({
      file: ['', [Validators.required]],
      modification: ['', [Validators.required]],
    });
    this.cmsService = this.fb.group({
      file: ['', [Validators.required]],
      modification: ['', [Validators.required]],
    });
    this.cmsPrayerRequest = this.fb.group({
      file: ['', [Validators.required]],
      modification: ['', [Validators.required]],
    });
    this.cmsTempleTest = this.fb.group({
      file: ['', [Validators.required]],
      modification: ['', [Validators.required]],
    });
    this.cmsSpiritualFitnessForm = this.fb.group({
      file: ['', [Validators.required]],
      modification: ['', [Validators.required]],
    });
    this.cmsDonationForm = this.fb.group({
      file: ['', [Validators.required]],
      modification: ['', [Validators.required]],
    });
  }

  ELEMENT_REVIEW_PUSH_NOTIFICATION: Element[] = [];
  dataReviewPushNotifications = new MatTableDataSource(
    this.ELEMENT_REVIEW_PUSH_NOTIFICATION
  );

  ELEMENT_REVIEW_MEDIA: Element[] = [];
  dataReviewMedias = new MatTableDataSource(this.ELEMENT_REVIEW_MEDIA);

  ELEMENT_REVIEW_TV_PROGRAM: Element[] = [];
  dataReviewProgram = new MatTableDataSource(this.ELEMENT_REVIEW_TV_PROGRAM);

  ELEMENT_REVIEW_TV_SERVICES: Element[] = [];
  dataReviewServices = new MatTableDataSource(this.ELEMENT_REVIEW_TV_SERVICES);

  ELEMENT_REVIEW_TV_DONATIONS: Element[] = [];
  dataReviewDonations = new MatTableDataSource(
    this.ELEMENT_REVIEW_TV_DONATIONS
  );

  ELEMENT_REVIEW_TV_SPIRITUAL: Element[] = [];
  dataReviewSpritual = new MatTableDataSource(this.ELEMENT_REVIEW_TV_SPIRITUAL);

  ELEMENT_REVIEW_TEMPLE: Element[] = [];
  dataReviewTemple = new MatTableDataSource(this.ELEMENT_REVIEW_TEMPLE);

  ELEMENT_REVIEW_PRAYER: Element[] = [];
  dataReviewPrayerRequest = new MatTableDataSource(this.ELEMENT_REVIEW_PRAYER);
  @ViewChild(MatPaginator) paginator: any = MatPaginator;
  ELEMENT_DATA_PUSH_NOTIFICATION: Element[] = [];
  ELEMENT_DATA_TV_PROGRAM: Element[] = [];
  ELEMENT_DATA_SERVICE: Element[] = [];
  ELEMENT_DATA_DONATE: Element[] = [];
  ELEMENT_DATA_PRAYER_REQ: Element[] = [];
  ELEMENT_DATA_TEMPLE: Element[] = [];
  ELEMENT_DATA_SPIRITUAL: Element[] = [];
  ELEMENT_DATA_MEDIA: Element[] = [];
  displayedColumns: string[] = [
    'user',
    'title',
    'Requestdescription',
    'status',
    'Date',
  ];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  dataSourcePushNotifications = new MatTableDataSource(
    this.ELEMENT_DATA_PUSH_NOTIFICATION
  );
  displayedColumnsPushNotifications: string[] = [
    'user',
    'title',
    'Requestdescription',
    'status',
    'Date',
  ];
  dataSourceMedia = new MatTableDataSource(this.ELEMENT_DATA_MEDIA);
  displayedColumnsMedia: string[] = [
    'user',
    'Requestdescription',
    'status',
    'mediaStatus',
    'Date',
    'publishDate',
  ];
  dataSourceTvProgram = new MatTableDataSource(this.ELEMENT_DATA_TV_PROGRAM);
  displayedColumnsTvProgram: string[] = [
    'user',
    'Requestdescription',
    'status',
    'Date',
  ];
  dataSourceServcice = new MatTableDataSource(this.ELEMENT_DATA_SERVICE);
  displayedColumnsService: string[] = [
    'user',
    'Requestdescription',
    'status',
    'Date',
  ];
  dataSourceDonate = new MatTableDataSource(this.ELEMENT_DATA_DONATE);
  displayedColumnsDonate: string[] = [
    'user',
    'Requestdescription',
    'status',
    'Date',
  ];
  dataSourcePrayerReq = new MatTableDataSource(this.ELEMENT_DATA_PRAYER_REQ);
  displayedColumnsPrayerReq: string[] = [
    'user',
    'Requestdescription',
    'status',
    'Date',
  ];
  dataSourceTemple = new MatTableDataSource(this.ELEMENT_DATA_TEMPLE);
  displayedColumnsTemple: string[] = [
    'user',
    'Requestdescription',
    'status',
    'Date',
  ];
  dataSourceSpiritual = new MatTableDataSource(this.ELEMENT_DATA_SPIRITUAL);
  displayedColumnsSpiritual: string[] = [
    'user',
    'Requestdescription',
    'status',
    'Date',
  ];
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  ngOnInit(): void {
    this.permissons = JSON.parse(localStorage.getItem('permissons'));
    this.spinner.show();

    setTimeout(() => {
      this.spinner.hide();
    }, 1000);

    let now = new Date();
    now.setMinutes(now.getMinutes() + 1);
    this.minTime = this.datepipe.transform(now, 'HH:mm a');
    this.currTime = this.datepipe.transform(now, 'hh:mm a');
    this.currTime1 = this.datepipe.transform(now, 'hh:mm a');

    this.services.cmsModulePushNotificationsReview().subscribe((res) => {
      console.log(res , 'res of res');
      this.reviewCount = res.totalRecords;
      this.ELEMENT_REVIEW_PUSH_NOTIFICATION = res.data;
      this.dataReviewPushNotifications = new MatTableDataSource(
        this.ELEMENT_REVIEW_PUSH_NOTIFICATION
      );
    });
    this.tvProgramFileType = {
      fileType: this.cmsHistoryDonate,
      status: this.status,
      pageLimit: this.pageSizeDonation ? this.pageSizeDonation : '',
      pageNumber: 0,
    };

    let newParams = {
      status: this.status,
      pageLimit: this.pageSizeMedia ? this.pageSizeMedia : '',
      pageNumber: 0,
    };
    this.services.cmsHistoryMediaVideo(newParams).subscribe((response) => {
      this.ELEMENT_REVIEW_MEDIA = response.data;
      this.dataReviewMedias = new MatTableDataSource(this.ELEMENT_REVIEW_MEDIA);
      this.reviewCountMedias = response.totalRecords;
    });

    this.services
      .cmsHistoryTvProgramSection(this.tvProgramFileType)
      .subscribe((data: any) => {
        this.DonationsRecords = data.totalRecords;
        this.ELEMENT_REVIEW_TV_DONATIONS = data.data;
        this.dataReviewDonations = new MatTableDataSource(
          this.ELEMENT_REVIEW_TV_DONATIONS
        );
        console.log(this.DonationsRecords);
      });
    this.serviceFileTypeParams = {
      fileType: this.cmsHistoryService,
      status: this.status,
      pageLimit: this.pageSizeService ? this.pageSizeService : '',
      pageNumber: 0,
    };
    this.services
      .cmsHistoryTvProgramSection(this.serviceFileTypeParams)
      .subscribe((data: any) => {
        this.serviceRecords = data.totalRecords;
        this.ELEMENT_REVIEW_TV_SERVICES = data.data;
        this.dataReviewServices = new MatTableDataSource(
          this.ELEMENT_REVIEW_TV_SERVICES
        );
      });
    let templeParams = {
      fileType: this.cmsHistoryTemple,
      status: this.status,
      pageLimit: this.pageSizeProgram ? this.pageSizeProgram : '',
      pageNumber: 0,
    };
    this.services
      .cmsHistoryTvProgramSection(templeParams)
      .subscribe((data: any) => {
        this.reviewTempleCount = data.totalRecords;
        this.ELEMENT_REVIEW_TEMPLE = data.data;
        this.dataReviewTemple = new MatTableDataSource(
          this.ELEMENT_REVIEW_TEMPLE
        );
      });
    let pRParams = {
      fileType: this.cmsHistoryPrayerReq,
      status: this.status,
      pageLimit: this.pageSizePrayer ? this.pageSizePrayer : '',
      pageNumber: 0,
    };
    this.services
      .cmsHistoryTvProgramSection(pRParams)
      .subscribe((data: any) => {
        this.reviewPrayerCount = data.totalRecords;
        this.ELEMENT_REVIEW_PRAYER = data.data;
        this.dataReviewPrayerRequest = new MatTableDataSource(
          this.ELEMENT_REVIEW_PRAYER
        );
      });
    this.TvProgram = {
      fileType: this.cmsHistoryTVProgram,
      status: this.status,
      pageLimit: this.pageSizeProgram ? this.pageSizeProgram : '',
      pageNumber: 0,
    };

    this.spiritualFileTypeParams = {
      fileType: this.cmsHistorySpiritual,
      status: this.status,
      pageLimit: this.pageSizeSpirtual ? this.pageSizeSpirtual : '',
      pageNumber: 0,
    };
    this.services
      .cmsHistoryTvProgramSection(this.spiritualFileTypeParams)
      .subscribe((data) => {
        this.ELEMENT_REVIEW_TV_SPIRITUAL = data.data;
        this.dataReviewSpritual = new MatTableDataSource(
          this.ELEMENT_REVIEW_TV_SPIRITUAL
        );
        this.reviewSpiritualCount = data.totalRecords;
      });
    this.services
      .cmsHistoryTvProgramSection(this.TvProgram)
      .subscribe((data) => {
        this.TvProgramRecords = data.totalRecords;
        this.ELEMENT_REVIEW_TV_PROGRAM = data.data;
        this.dataReviewProgram = new MatTableDataSource(
          this.ELEMENT_REVIEW_TV_PROGRAM
        );
      });
    this.role = localStorage.getItem('ROLE');
    let params = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: 0,
    };
    this.services.cmsHistoryPushNotifications(params).subscribe((data) => {
      this.ELEMENT_DATA_PUSH_NOTIFICATION = data.data;
      this.dataSourcePushNotifications = new MatTableDataSource(
        this.ELEMENT_DATA_PUSH_NOTIFICATION
      );

      this.totalCmsNot = data.totalRecords;
    });
    let params1 = {
      pageLimit: this.pageSizeMedia ? this.pageSizeMedia : '',
      pageNumber: 0,
    };
    this.services.cmsHistoryMediaVideo(params1).subscribe((data) => {
      this.ELEMENT_DATA_MEDIA = data.data;
      this.dataSourceMedia = new MatTableDataSource(this.ELEMENT_DATA_MEDIA);
      this.totalMedia = data.totalRecords;
    });

    this.tvProgramFileTypeParams = {
      fileType: this.cmsHistoryTVProgram,
      pageLimit: this.pageSizeProgram ? this.pageSizeProgram : '',
      pageNumber: 0,
    };
    this.services
      .cmsHistoryTvProgramSection(this.tvProgramFileTypeParams)
      .subscribe((data) => {
        this.cmsTvProgramData = data.data;
        this.ELEMENT_DATA_TV_PROGRAM = data.data;
        this.dataSourceTvProgram = new MatTableDataSource(
          this.ELEMENT_DATA_TV_PROGRAM
        );
        this.totalTvPrograms = data.totalRecords;
      });
    this.ServicesFileTypeParams = {
      fileType: this.cmsHistoryService,
      pageLimit: this.pageSizeService ? this.pageSizeService : '',
      pageNumber: 0,
    };
    this.services
      .cmsHistoryTvProgramSection(this.ServicesFileTypeParams)
      .subscribe((data) => {
        this.ELEMENT_DATA_SERVICE = data.data;
        this.dataSourceServcice = new MatTableDataSource(
          this.ELEMENT_DATA_SERVICE
        );
        this.totalTvServices = data.totalRecords;
      });
    this.donateFileTypeParams = {
      fileType: this.cmsHistoryDonate,
      pageLimit: this.pageSizeDonation ? this.pageSizeDonation : '',
      pageNumber: 0,
    };
    this.services
      .cmsHistoryTvProgramSection(this.donateFileTypeParams)
      .subscribe((data) => {
        this.ELEMENT_DATA_DONATE = data.data;
        this.dataSourceDonate = new MatTableDataSource(
          this.ELEMENT_DATA_DONATE
        );
        this.totalTvDonation = data.totalRecords;
      });
    this.prayerFileTypeParams = {
      fileType: this.cmsHistoryPrayerReq,
      pageLimit: this.pageSizePrayer ? this.pageSizePrayer : '',
      pageNumber: 0,
    };
    this.services
      .cmsHistoryTvProgramSection(this.prayerFileTypeParams)
      .subscribe((data) => {
        this.ELEMENT_DATA_PRAYER_REQ = data.data;
        this.dataSourcePrayerReq = new MatTableDataSource(
          this.ELEMENT_DATA_PRAYER_REQ
        );
        this.totalTvPrayer = data.totalRecords;
      });
    this.TempleFileTypeParams = {
      fileType: this.cmsHistoryTemple,
      pageLimit: this.pageSizeDonation ? this.pageSizeDonation : '',
      pageNumber: 0,
    };
    this.services
      .cmsHistoryTvProgramSection(this.TempleFileTypeParams)
      .subscribe((data) => {
        this.ELEMENT_DATA_TEMPLE = data.data;
        this.dataSourceTemple = new MatTableDataSource(
          this.ELEMENT_DATA_TEMPLE
        );
        this.totalTvTemple = data.totalRecords;
      });
    this.spiritualFileTypeParams = {
      fileType: this.cmsHistorySpiritual,
      pageLimit: this.pageSizeSpirtual ? this.pageSizeSpirtual : '',
      pageNumber: 0,
    };
    this.services
      .cmsHistoryTvProgramSection(this.spiritualFileTypeParams)
      .subscribe((data) => {
        this.ELEMENT_DATA_SPIRITUAL = data.data;
        this.dataSourceSpiritual = new MatTableDataSource(
          this.ELEMENT_DATA_SPIRITUAL
        );
        this.totalTvSpirtual = data.totalRecords;
      });
    this.services.cmsStatusLastApproved().subscribe((res) => {
      this.approvedRequests = res.approvedRequests;
      // this.approvedRequests = '';
      this.pendingRequests = res.pendingRequests;
      this.declinedRequests = res.declinedRequests;
      this.totalRequests = res.totalRequests;
    });
    this.services.cmsNotificaitonsLastApproved().subscribe((res) => {
      this.resNotifications = res.data;
      this.updatedAt = res.data?.updatedAt;
      this.firstName = res.data?.createdBy?.firstName;
      this.lastName = res.data?.createdBy?.lastName;
    });
    this.services.cmsMediaLastApproved().subscribe((res) => {
      this.resMedia = res.data;

      this.updatedAtMedia = res.data?.updatedAt;
      this.mediaFirstName = res.data?.createdBy.firstName;
      this.mediaLastNameAdmin = res.data?.createdBy.lastName;
    });
    this.services.cmsTvProgramsLastApproved().subscribe((res) => {
      this.resTvPrograms = '';

      this.updatedAtTvprogram = res.message.updatedAt;
      this.firstNameTvprogram = res.message.createdBy.firstName;
      this.lastNameAdminTvprogram = res.message.createdBy.lastName;
    });
    this.services.cmsServiceLastApproved().subscribe((res) => {
      this.updatedAtServcie = res.message.updatedAt;
      this.firstNameService = res.message.createdBy.firstName;
      this.lastNameAdminService = res.message.createdBy.lastName;
    });
    this.services.cmsDonateLastApproved().subscribe((res) => {
      this.updatedAtDonate = res.message.updatedAt;
      this.firstNameDonate = res.message.createdBy.firstName;
      this.lastNameAdminDonate = res.message.createdBy.lastName;
    });
    this.services.cmsTempleLastApproved().subscribe((res) => {
      this.updatedAtTemple = res.message.updatedAt;
      this.firstNameTemple = res.message.createdBy.firstName;
      this.lastNameAdminTemple = res.message.createdBy.lastName;
    });
    this.services.cmsPrayerReqLastApproved().subscribe((res) => {
      this.updatedAtPrayer = res.message.updatedAt;
      this.firstNamePrayer = res.message.createdBy.firstName;
      this.lastNameAdminPrayer = res.message.createdBy.lastName;
    });
    this.services.cmsSpiritualFitnessLastApproved().subscribe((res) => {
      this.updatedAtspiritual = res.message.updatedAt;
      this.firstNamespiritual = res.message.createdBy.firstName;
      this.lastNameAdminspriitual = res.message.createdBy.lastName;
    });
    let currentDateTime = this.datepipe.transform(new Date(), 'HH:mm a');
    this.currentTime = currentDateTime;
    $(document).ready(() => {
      $('input[name="intervaltype"]').click(() => {
        $(this).tab('show');
      });
    });
    this.services.cmsVideoCategory().subscribe((res) => {
      this.cmsVideoCategory = res.message;
    });
  }

  public selectedTimePicker: any;
  open(event: any) {
    this.selectedTimeEvent = event;
    const localDate = this.calendraDateEvent;
    const localTime = this.selectedTimePicker;
    this.selectedTimeEventAfterFormat = moment(localTime, 'HH:mm:ss').format(
      'hh:mm:ss'
    );

    this.scheuledDateAndTimeEvent = `${localDate}T${this.selectedTimeEventAfterFormat}.000Z`;
  }
  pushNotificationsEvent(event: any) { }
  invalidInputHandler() {
    // some error handling
  }
  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    if (tabChangeEvent.index == 0) {
      this.video = true;
      this.Daily = false;
      this.Event = false;
    } else if (tabChangeEvent.index == 1) {
      this.video = false;
      this.Daily = true;
      this.Event = false;
    } else {
      this.Event = true;
      this.video = false;
      this.Daily = false;
    }
  }
  onFileSelected(event: any): void {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      this.url = reader.result as string;
      this.selectedFile = <File>event.target.files[0];
      this.fileName = event.target.files[0].name;
    };
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.cmsVideoForm.get('thumbnail').setValue(file);
    }
  }
  onFileSelectedPushNotifications(event: any): void {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.pushnotificationsUrl = reader.result as string;
      this.selectedFilePushnotifications = <File>event.target.files[0];
      this.fileNamePushnotifications = event.target.files[0].name;
    };
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.cmsPushNotifications.get('file').setValue(file);
    }
  }
  onFileSelectedPromiseCard(event: any): void {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      this.promiseCardUrl = reader.result as string;

      this.selectedFiles = <File>event.target.files[0];
      this.fileNames = event.target.files[0].name;
    };
  }
  onFileSelectedEvent(event: any): void {
    const file = event.target.files && event.target.files[0];
    this.selectedFileEvent = <File>event.target.files[0];
    this.fileNameEvent = event.target.files[0].name;
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      if (file.type.indexOf('image') > -1) {
        this.format = 'image';
      } else if (file.type.indexOf('video') > -1) {
        this.format = 'video';
      }
      reader.onload = (event) => {
        this.EventUrl = (<FileReader>event.target).result;
      };
    }
  }
  onFileSelectedTvProgram(event: any): void {
    let ext = event.target.files[0].name.split('.')[1];
    if (this.allowedFormat.includes(ext)) {
      this.selectedFile = <File>event.target.files[0];
      this.fileName = event.target.files[0].name;
      if (event.target.files[0].name != null) {
        this.uploadFlie = false;
        this.attachmentFile = true;
      }
    } else {
    }
  }
  onFileSelectedService(event: any): void {
    let ext = event.target.files[0].name.split('.')[1];
    if (this.allowedFormat.includes(ext)) {
      this.serviceSelectedFile = <File>event.target.files[0];
      this.serviceFileName = event.target.files[0].name;
      if (event.target.files[0].name != null) {
        this.serviceAttachmentFile = true;
        this.serviceUploadFlie = false;
      }
    } else {
    }
  }
  onFileSelectedPrayerRequest(event: any): void {
    let ext = event.target.files[0].name.split('.')[1];
    if (this.allowedFormat.includes(ext)) {
      this.prayerSelectedFile = <File>event.target.files[0];
      this.prayerFileName = event.target.files[0].name;
      if (event.target.files[0].name != null) {
        this.prayerRequestUploadFlie = false;
        this.prayerRequestAttachmentFile = true;
      }
    } else {
    }
  }
  onFileSelectedTempleTest(event: any): void {
    let ext = event.target.files[0].name.split('.')[1];
    if (this.allowedFormat.includes(ext)) {
      this.templeSelectedFile = <File>event.target.files[0];
      this.templeFileName = event.target.files[0].name;
      if (event.target.files[0].name != null) {
        this.templeUploadFlie = false;
        this.templeAttachmentFile = true;
      }
    } else {
    }
  }
  onFileSelectedSpiritualFitness(event: any): void {
    let ext = event.target.files[0].name.split('.')[1];
    if (this.allowedFormat.includes(ext)) {
      this.spiritualSelectedFile = <File>event.target.files[0];
      this.spiritualFileName = event.target.files[0].name;
      if (event.target.files[0].name != null) {
        this.spiritualUploadFlie = false;
        this.spiritualAttachmentFile = true;
      }
    } else {
    }
  }
  onFileSelectedDonations(event: any): void {
    let ext = event.target.files[0].name.split('.')[1];
    if (this.allowedFormat.includes(ext)) {
      this.donationsSelectedFile = <File>event.target.files[0];
      this.donationsFileName = event.target.files[0].name;
      if (event.target.files[0].name != null) {
        this.donationsUploadFlie = false;
        this.donationsAttachmentFile = true;
      }
    } else {
    }
  }

  updateTime(toggleTimepicker: any) {
    let now = new Date();
    now.setMinutes(now.getMinutes());

    let currentDateTime = this.datepipe.transform(now, 'hh:mm a');
    toggleTimepicker.defaultTime = currentDateTime;

    // this.cdr.detectChanges();
  }

  onChangeHour(event: any) {
    this.Date = this.dateSelect;
    let ds = this.dateSelect;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    let now = new Date();
    now.setMinutes(now.getMinutes() + 1);

    let currentDateTime = this.datepipe.transform(now, 'HH:mm a');
    this.currentTime = currentDateTime;
    this.selectedTime = event;

    const localDate = this.calendraDate;
    const localTime = this.selectedTime;
    this.selectedTimeAfterFormat = moment(localTime, 'HH:mm:ss').format(
      'HH:mm:ss'
    );
    this.scheuledDateAndTime = `${localDate}T${this.selectedTimeAfterFormat}.00`;
    console.log(this.scheuledDateAndTime);

    const localDateString = this.scheuledDateAndTime;
    const utcDateString = this.datepipe.transform(
      localDateString,
      'hh:mm:ss',
      'UTC'
    );

    console.log(utcDateString);

    this.readyDateAndTimePromise = `${localDate}T${utcDateString}.000Z`;
    console.log(this.readyDateAndTimePromise);

    // var utcStart = new moment('2019-06-24T09:00', 'YYYY-MM-DDTHH:mm').utc();
  }

  selectHour(event: any) {
    this.Date = this.dateSelect;
    let ds = this.dateSelect;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    let now = new Date();
    let testDate = moment(new Date(now.toString().substr(0, 16))).format(
      'YYYY-MM-DD'
    );
    now.setMinutes(now.getMinutes());

    let currentDateTime = this.datepipe.transform(now, 'HH:mm a');
    this.currentTime = currentDateTime;
    this.selectedTime = event;
    this.currTime = event;

    const localDate = this.calendraDate;
    const localTime = this.selectedTime;
    this.selectedTimeAfterFormat = moment(localTime, 'HH:mm:ss').format(
      'HH:mm:ss'
    );
    this.scheuledDateAndTime = `${localDate}T${this.selectedTimeAfterFormat}.00`;
    console.log(this.scheuledDateAndTime);

    const localDateString = this.scheuledDateAndTime;
    const utcDateString = this.datepipe.transform(
      localDateString,
      'hh:mm:ss',
      'UTC'
    );

    this.readyDateAndTimePromise = `${localDate}T${utcDateString}.000Z`;
    console.log(this.readyDateAndTimePromise);

    if (this.calendraDate == testDate) {
      this.currentTime = this.currentTime.split(' ')[0];
      if (this.currentTime[0] == '0') {
        this.currentTime = this.currentTime.slice(1, this.currentTime.length);
      }
      if (this.currentTime > this.selectedTime) {
        console.log('hi how');
        this.dailPromiseTimeErr = 'Time should not be the previous time.';
      } else {
        this.dailPromiseTimeErr = null;
      }
    } else {
      this.dailPromiseTimeErr = null;
    }
    this.dailPromiseTimeErr = null;
    // var utcStart = new moment('2019-06-24T09:00', 'YYYY-MM-DDTHH:mm').utc();
  }

  selectDate(event: any) {
    this.dateSelect = event.target.value;
    this.Date = this.dateSelect;
    let ds = this.dateSelect;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    let now = new Date();
    let testDate = moment(new Date(now.toString().substr(0, 16))).format(
      'YYYY-MM-DD'
    );
    now.setMinutes(now.getMinutes());
    let currentDateTime = this.datepipe.transform(now, 'HH:mm a');
    this.currentTime = currentDateTime;
    // this.currentStateTime = currentDateTime;
    console.log('time', this.currentTime);

    this.selectedTime = event;

    const localDate = this.calendraDate;
    const localTime = this.selectedTime;
    this.selectedTimeAfterFormat = moment(localTime, 'HH:mm:ss').format(
      'HH:mm:ss'
    );
    this.scheuledDateAndTime = `${localDate}T${this.selectedTimeAfterFormat}.00`;
    // this.selectedTimeEventAfterFormat = moment(localTime, 'HH:mm:ss').format(
    //   'HH:mm:ss'
    // );
    const localDateString = this.scheuledDateAndTime;
    const utcDateString = this.datepipe.transform(
      localDateString,
      'hh:mm:ss',
      'UTC'
    );
    this.readyDateAndTimePromise = `${localDate}T${utcDateString}.000Z`;
    console.log(this.readyDateAndTimePromise);

    if (this.calendraDate == testDate) {
      if (this.currentTime[0] == '0') {
        this.currentTime = this.currentTime.slice(1, this.currentTime.length);
      }
      if (this.currentTime > this.selectedTime) {
        console.log('hi how');
        this.dailPromiseTimeErr = 'Time should not be the previous time.';
      } else {
        this.dailPromiseTimeErr = null;
      }
    } else {
      this.dailPromiseTimeErr = null;
    }
  }

  pushHourEvent(event: any) {
    this.pushEventHour = event.target.value;

    this.hour = parseInt(this.pushEventHour);
    if (this.pushEventAM === 'PM' && this.hour !== 12) {
      this.hour += 12;
    } else if (this.pushEventAM === 'AM' && this.hour === 12) {
      this.hour = 0;
    } else if (this.pushEventAM === 'AM' && this.hour !== 12) {
      this.hour = parseInt(this.pushEventHour);
    }
    const selectedDateTime = new Date(this.calendraDatePush);
    // selectedDateTime.setHours(this.pushEventHour);
    console.log('====================================');
    console.log('hours', this.hour);
    console.log('====================================');
    selectedDateTime.setHours(this.hour);
    selectedDateTime.setMinutes(this.pushEventMin);
    const utcString = selectedDateTime.toISOString();
    console.log('pushHourEvent 123', utcString);
    this.pushNotificationTime = utcString;
    if (this.pushEventHour !== '' && this.pushEventMin !== '' && this.pushEventAM !== '') {
      const currentTime = new Date();
      if (selectedDateTime < currentTime) {
        this.dailPromiseTimeErr = 'Time should not be the previous time.';
        console.log('pushNotificationTime is earlier than current time');
      } else {
        this.dailPromiseTimeErr = '';
      }
    }
  }
  pushMInutesEvent(event: any) {
    this.pushEventMin = event.target.value;
    this.hour = parseInt(this.pushEventHour);
    // this.hour=parseInt(this.hour)
    if (this.pushEventAM === 'PM' && this.hour !== 12) {
      console.log('hi how');

      this.hour += 12;
    } else if (this.pushEventAM === 'AM' && this.hour === 12) {
      console.log('hi how 2');
      this.hour = 0;
    } else if (this.pushEventAM === 'AM' && this.hour !== 12) {
      console.log('hi how 3');
      this.hour = parseInt(this.pushEventHour);
    }
    const selectedDateTime = new Date(this.calendraDatePush);
    selectedDateTime.setHours(this.hour);
    // selectedDateTime.setHours(this.pushEventHour);
    selectedDateTime.setMinutes(this.pushEventMin);

    const utcString = selectedDateTime.toISOString();
    console.log('pushHourEvent', utcString);
    this.pushNotificationTime = utcString;
    if (this.pushEventHour !== '' && this.pushEventMin !== '' && this.pushEventAM !== '') {
      const currentTime = new Date();
      if (selectedDateTime < currentTime) {
        this.dailPromiseTimeErr = 'Time should not be the previous time.';
        console.log('pushNotificationTime is earlier than current time');
      } else {
        this.dailPromiseTimeErr = '';
      }
    }
    // this.totalTime = `${this.pushEventHour}${this.pushEventMin}${this.pushEventAM}`;
    // console.log('pushHourEvent', this.pushEventMin);
    // console.log('totalTime', this.totalTime);
  }
  pushAmEvent(event: any) {
    console.log('time', this.currentTime);
    const number = moment(this.currentTime, ['HH.mm']).format('hh:mm A');
    console.log(number);
    this.pushEventAM = event.target.value;
    console.log('pushEventAM', this.pushEventAM);
    this.hour = parseInt(this.hour)
    if (this.pushEventAM === 'PM' && this.hour !== 12) {
      console.log('hi how');
      this.hour += 12;
    } else if (this.pushEventAM === 'AM' && this.hour === 12) {
      console.log('hi how 2');
      this.hour = 0;
    } else if (this.pushEventAM === 'AM' && this.hour !== 12) {
      console.log('hi how 3');
      this.hour = this.pushEventHour;
    }
    const selectedDateTime = new Date(this.calendraDatePush);
    selectedDateTime.setHours(this.hour);
    selectedDateTime.setMinutes(this.pushEventMin);
    const utcString = selectedDateTime.toISOString();
    console.log('pushHourEvent', utcString);
    this.pushNotificationTime = utcString;
    if (this.pushEventHour !== '' && this.pushEventMin !== '' && this.pushEventAM !== '') {
      const currentTime = new Date();
      if (selectedDateTime < currentTime) {
        this.dailPromiseTimeErr = 'Time should not be the previous time.';
        console.log('pushNotificationTime is earlier than current time');
      } else {
        this.dailPromiseTimeErr = '';
      }
    }
  }
  selectDatePushNotications(event: any) {
    this.pushEventHour = '';
    this.hour = ''
    this.pushEventMin = '';
    this.pushEventAM = '';
    this.dailPromiseTimeErr = '';
    console.log('welcome');
    let now = new Date();
    let testDate = moment(new Date(now.toString().substr(0, 16))).format(
      'YYYY-MM-DD'
    );
    this.dateSelectPush = event.target.value;
    this.Date = this.dateSelectPush;
    let ds = this.dateSelectPush;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDatePush = date.format('YYYY-MM-DD');
    const localDate = this.calendraDatePush;
    const localTime = this.selectedTimePush;
    this.selectedTimeEventAfterFormat = moment(localTime, 'HH:mm:ss').format(
      'HH:mm:ss'
    );
    // var utcStart = new moment('2019-06-24T09:00', 'YYYY-MM-DDTHH:mm').utc();
    this.scheuledDateAndTimePushNotifications = `${localDate}T${this.selectedTimeEventAfterFormat}.00`;
    const localDateString = this.scheuledDateAndTimePushNotifications;
    const utcDateString = this.datepipe.transform(
      localDateString,
      'hh:mm:ss',
      'UTC'
    );
    this.readyDateAndTime = `${localDate}T${utcDateString}.000Z`;
    console.log(this.readyDateAndTime);
    // this.currentStateTime = currentDateTime;
    console.log('time', this.currentTime);

    if (this.calendraDatePush == testDate) {
      if (this.currentTime[0] == '0') {
        this.currentTime = this.currentTime.slice(1, this.currentTime.length);
      }
      if (this.currentTime > this.selectedTimePush) {
        console.log('hi how');
        this.pushNotifyTimeErr = 'Time should not be the previous time.';
      } else {
        this.pushNotifyTimeErr = null;
      }
    } else {
      this.pushNotifyTimeErr = null;
    }
  }
  selectStartDateEventNotications(event: any) {
    this.pushStartEventHour = '';
    this.eventsEndTimeErr = '';
    this.startHour = ''
    this.pushStartEventMin = '';
    this.pushStartEventAM = '';
    this.endHour = ''
    this.pushEndEventMin = '';
    this.pushEndEventHour = '';
    this.pushEndEventAM = '';
    this.eventsTimeErr = '';
    this.pushStartNotificationTime = '';
    console.log('welcome');
    let now = new Date();
    let testDate = moment(new Date(now.toString().substr(0, 16))).format(
      'YYYY-MM-DD'
    );
    this.dateSelectPush = event.target.value;
    this.Date = this.dateSelectPush;
    let ds = this.dateSelectPush;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDatePush = date.format('YYYY-MM-DD');
    const localDate = this.calendraDatePush;
    const localTime = this.selectedTimePush;
    this.selectedTimeEventAfterFormat = moment(localTime, 'HH:mm:ss').format(
      'HH:mm:ss'
    );
    // var utcStart = new moment('2019-06-24T09:00', 'YYYY-MM-DDTHH:mm').utc();
    this.scheuledDateAndTimePushNotifications = `${localDate}T${this.selectedTimeEventAfterFormat}.00`;
    const localDateString = this.scheuledDateAndTimePushNotifications;
    const utcDateString = this.datepipe.transform(
      localDateString,
      'hh:mm:ss',
      'UTC'
    );
    this.readyDateAndTime = `${localDate}T${utcDateString}.000Z`;
    console.log(this.readyDateAndTime);
    // this.currentStateTime = currentDateTime;
    console.log('time', this.currentTime);
    if (this.calendraDatePush == testDate) {
      if (this.currentTime[0] == '0') {
        this.currentTime = this.currentTime.slice(1, this.currentTime.length);
      }
      if (this.currentTime > this.selectedTimePush) {
        console.log('hi how');
        this.pushNotifyTimeErr = 'Time should not be the previous time.';
      } else {
        this.pushNotifyTimeErr = null;
      }
    } else {
      this.pushNotifyTimeErr = null;
    }
    if (!isNaN(new Date(this.pushStartNotificationTime).getTime())) {
      this.eventsEndTimeErr = '';
    }
  }
  pushStartHourEvent(event: any) {
    this.endHour = ''
    this.pushEndEventHour = '';
    this.pushEndEventMin = '';
    this.pushEndEventAM = '';
    this.eventsTimeErr = '';
    this.pushStartEventHour = event.target.value;
    this.startHour = parseInt(this.pushStartEventHour);
    if (this.pushStartEventAM === 'PM' && this.startHour !== 12) {
      this.startHour += 12;
    } else if (this.pushStartEventAM === 'AM' && this.startHour === 12) {
      this.startHour = 0;
    } else if (this.pushStartEventAM === 'AM' && this.startHour !== 12) {
      this.startHour = parseInt(this.pushStartEventHour);
    }
    const selectedDateTime = new Date(this.calendraDatePush);
    // selectedDateTime.setHours(this.pushEventHour);
    selectedDateTime.setHours(this.hour);
    selectedDateTime.setMinutes(this.pushEventMin);
    const utcString = selectedDateTime.toISOString();
    console.log('pushHourEvent 123', utcString);
    this.pushStartNotificationTime = utcString;
    if (this.pushStartEventHour !== '' && this.pushStartEventMin !== '' && this.pushStartEventAM !== '') {
      const currentTime = new Date();
      if (selectedDateTime < currentTime) {
        this.eventsTimeErr = 'Time should not be the previous time.';
        console.log('pushNotificationTime is earlier than current time');
      } else {
        this.eventsTimeErr = '';
      }
    }
    if (!isNaN(new Date(this.pushStartNotificationTime).getTime())) {
      this.eventsEndTimeErr = '';
    }
  }
  pushStartMInutesEvent(event: any) {
    this.endHour
    this.pushEndEventHour = '';
    this.pushEndEventMin = '';
    this.pushEndEventAM = '';
    this.eventsTimeErr = '';
    this.pushStartEventMin = event.target.value;
    if (this.pushStartEventHour !== '' && this.pushStartEventMin !== '') {
    this.startHour = parseInt(this.pushStartEventHour);
    // this.hour=parseInt(this.hour)
    if (this.pushStartEventAM === 'PM' && this.startHour !== 12) {
      console.log('hi how');
      this.startHour += 12;
    } else if (this.pushStartEventAM === 'AM' && this.startHour === 12) {
      console.log('hi how 2');
      this.hour = 0;
    } else if (this.pushStartEventAM === 'AM' && this.startHour !== 12) {
      console.log('hi how 3');
      this.startHour = parseInt(this.pushStartEventHour);
    }
    const selectedDateTime = new Date(this.calendraDatePush);
    selectedDateTime.setHours(this.startHour);
    // selectedDateTime.setHours(this.pushEventHour);
    selectedDateTime.setMinutes(this.pushStartEventMin);

    const utcString = selectedDateTime.toISOString();
    console.log('pushHourEvent', utcString);
    this.pushStartNotificationTime = utcString;
   
      const currentTime = new Date();
      if (selectedDateTime < currentTime) {
        this.eventsTimeErr = 'Time should not be the previous time.';
        console.log('pushNotificationTime is earlier than current time');
      } else {
        this.eventsTimeErr = '';
      }
    }else{
      this.eventsEndTimeErr = 'Select Hour First';
        setTimeout(() => {   this.eventsTimeErr = ''   }, 2000);
        event.target.value = '';
    }
    // this.totalTime = `${this.pushEventHour}${this.pushEventMin}${this.pushEventAM}`;
    // console.log('pushHourEvent', this.pushEventMin);
    // console.log('totalTime', this.totalTime);
    if (!isNaN(new Date(this.pushStartNotificationTime).getTime())) {
      this.eventsEndTimeErr = '';
    }
  }
  pushStartAmEvent(event: any) {
    this.endHour = ''
    this.pushEndEventHour = '';
    this.pushEndEventMin = '';
    this.pushEndEventAM = '';
    this.eventsTimeErr = '';
    if (this.pushStartEventHour) {

    }
    if (this.pushStartEventHour !== '' && this.pushStartEventMin !== '') {
    console.log('time', this.currentTime);
    const number = moment(this.currentTime, ['HH.mm']).format('hh:mm A');
    console.log(number);
    this.pushStartEventAM = event.target.value;
    console.log('pushEventAM', this.pushStartEventAM);
    this.startHour = parseInt(this.startHour)
    if (this.pushStartEventAM === 'PM' && this.startHour !== 12) {
      console.log('hi how');
      this.startHour += 12;
    } else if (this.pushStartEventAM === 'AM' && this.startHour === 12) {
      console.log('hi how 2');
      this.startHour = 0;
    } else if (this.pushStartEventAM === 'AM' && this.startHour !== 12) {
      console.log('hi how 3');
      this.startHour = this.pushStartEventHour;
    }
    const selectedDateTime = new Date(this.calendraDatePush);
    selectedDateTime.setHours(this.startHour);
    selectedDateTime.setMinutes(this.pushStartEventMin);
    const utcString = selectedDateTime.toISOString();
    console.log('pushHourEvent', utcString);
    this.pushStartNotificationTime = utcString;
    console.log(this.pushStartNotificationTime, 'huhuhuh');

   
      const currentTime = new Date();
      if (selectedDateTime < currentTime) {
        this.eventsTimeErr = 'Time should not be the previous time.';
        console.log('pushNotificationTime is earlier than current time');
        console.log(this.pushStartNotificationTime, 'huhuhuh');
      } else {
        this.eventsTimeErr = '';
        console.log(this.pushStartNotificationTime, 'huhuhuh');
      }
    }else{
      this.eventsTimeErr = 'Select Hour , Minutes First';
        setTimeout(() => {   this.eventsTimeErr = ''   }, 2000);
        event.target.value = '';
    }
    if (!isNaN(new Date(this.pushStartNotificationTime).getTime())) {
      this.eventsEndTimeErr = '';
    }
  }
  selectEndDateEventNotications(event: any) {
    const start = new Date(this.pushStartNotificationTime);
    if (!isNaN(start.getTime())) {
      this.pushEndEventHour = '';
      this.endHour = ''
      this.pushEndEventMin = '';
      this.pushEndEventAM = '';
      this.eventsEndTimeErr = '';
      this.pushEndNotificationTime = '';
      console.log('welcome');
      let now = new Date();
      let testDate = moment(new Date(now.toString().substr(0, 16))).format(
        'YYYY-MM-DD'
      );
      this.dateSelectPush = event.target.value;
      this.Date = this.dateSelectPush;
      let ds = this.dateSelectPush;
      let date = moment(new Date(ds.toString().substr(0, 16)));
      this.calendraEndDatePush = date.format('YYYY-MM-DD');
      const localDate = this.calendraEndDatePush;
      const localTime = this.selectedTimePush;
      this.selectedTimeEventAfterFormat = moment(localTime, 'HH:mm:ss').format(
        'HH:mm:ss'
      );
      // var utcStart = new moment('2019-06-24T09:00', 'YYYY-MM-DDTHH:mm').utc();
      this.scheuledDateAndTimePushNotifications = `${localDate}T${this.selectedTimeEventAfterFormat}.00`;
      const localDateString = this.scheuledDateAndTimePushNotifications;
      const utcDateString = this.datepipe.transform(
        localDateString,
        'hh:mm:ss',
        'UTC'
      );
      this.readyDateAndTime = `${localDate}T${utcDateString}.000Z`;
      console.log(this.readyDateAndTime);
      // this.currentStateTime = currentDateTime;
      console.log('time', this.currentTime);

      if (this.calendraEndDatePush == testDate) {
        if (this.currentTime[0] == '0') {
          this.currentTime = this.currentTime.slice(1, this.currentTime.length);
        }
        if (this.currentTime > this.selectedTimePush) {
          console.log('hi how');
          this.pushNotifyTimeErr = 'Time should not be the previous time.';
        } else {
          this.pushNotifyTimeErr = null;
        }
      } else {
        this.pushNotifyTimeErr = null;
      }
    } else {
      this.eventsEndTimeErr = 'Select start time first';
    }
  }
  pushEndHourEvent(event: any) {
    const start = new Date(this.pushStartNotificationTime);
    start.setMinutes(start.getMinutes() + 4);
    if (!isNaN(start.getTime())) {
      this.pushEndEventHour = event.target.value;
      this.endHour = parseInt(this.pushEndEventHour);
      if (this.pushEndEventAM === 'PM' && this.endHour !== 12) {
        this.startHour += 12;
      } else if (this.pushEndEventAM === 'AM' && this.endHour === 12) {
        this.startHour = 0;
      } else if (this.pushEndEventAM === 'AM' && this.endHour !== 12) {
        this.endHour = parseInt(this.pushEndEventHour);
      }
      const selectedDateTime = new Date(this.calendraEndDatePush);
      // selectedDateTime.setHours(this.pushEventHour);
      selectedDateTime.setHours(this.endHour);
      selectedDateTime.setMinutes(this.pushEndEventMin);
      const utcString = selectedDateTime.toISOString();
      console.log('pushHourEvent 123', utcString);
      this.pushEndNotificationTime = utcString;
      if (this.pushEndEventHour !== '' && this.pushEndEventMin !== '' && this.pushEndEventAM !== '') {
        const currentTime = new Date();
        const start = new Date(this.pushStartNotificationTime);
        if (selectedDateTime <= start) {
          this.eventsEndTimeErr = 'Time should not be the equal or less then start time. please select time with 4 minute difference';
          console.log('pushNotificationTime is earlier than current time');
        } else {
          this.eventsEndTimeErr = '';
        }
      }
    } else {
      this.eventsEndTimeErr = 'Select start time first';
    }
  }
  pushEndMInutesEvent(event: any) {
    const start = new Date(this.pushStartNotificationTime);
    start.setMinutes(start.getMinutes() + 4);
    if (!isNaN(start.getTime())) {
      if (this.pushEndEventHour !== '') {
        this.pushEndEventMin = event.target.value;
        this.endHour = parseInt(this.pushEndEventHour);
        // this.hour=parseInt(this.hour)
        if (this.pushEndEventAM === 'PM' && this.endHour !== 12) {
          console.log('hi how');
          this.endHour += 12;
        } else if (this.pushEndEventAM === 'AM' && this.endHour === 12) {
          console.log('hi how 2');
          this.endHour = 0;
        } else if (this.pushEndEventAM === 'AM' && this.endHour !== 12) {
          console.log('hi how 3');
          this.endHour = parseInt(this.pushStartEventHour);
        }
        const selectedDateTime = new Date(this.calendraEndDatePush);
        selectedDateTime.setHours(this.endHour);
        // selectedDateTime.setHours(this.pushEventHour);
        selectedDateTime.setMinutes(this.pushEndEventMin);

        const utcString = selectedDateTime.toISOString();
        console.log('pushHourEvent', utcString);
        this.pushEndNotificationTime = utcString;

        const currentTime = new Date();
        // const start = new Date(this.pushStartNotificationTime);
        if (selectedDateTime <= start) {
          this.eventsEndTimeErr = 'Time should not be the equal or less then start time. please select time with 4 minute difference';
          console.log('pushNotificationTime is earlier than current time');
        } else {
          this.eventsEndTimeErr = '';
        }
      }else{
        this.eventsEndTimeErr = 'Select Hour First';
        setTimeout(() => {   this.eventsEndTimeErr = ''   }, 2000);
        event.target.value = '';
      }
    }
    else {
      this.eventsEndTimeErr = 'Select start time first';
    }
    // this.totalTime = `${this.pushEventHour}${this.pushEventMin}${this.pushEventAM}`;
    // console.log('pushHourEvent', this.pushEventMin);
    // console.log('totalTime', this.totalTime);
  }
  pushEndAmEvent(event: any) {
    const start = new Date(this.pushStartNotificationTime);
    start.setMinutes(start.getMinutes() + 4);
    if (!isNaN(start.getTime())) {
      if (this.pushEndEventHour !== '' && this.pushEndEventMin !== '') {
      console.log('time', this.currentTime);
      const number = moment(this.currentTime, ['HH.mm']).format('hh:mm A');
      console.log(number);
      this.pushEndEventAM = event.target.value;
      console.log('pushEventAM', this.pushEndEventAM);
      this.endHour = parseInt(this.endHour)
      if (this.pushEndEventAM === 'PM' && this.endHour !== 12) {
        console.log('hi how');
        this.endHour += 12;
      } else if (this.pushEndEventAM === 'AM' && this.endHour === 12) {
        console.log('hi how 2');
        this.endHour = 0;
      } else if (this.pushEndEventAM === 'AM' && this.endHour !== 12) {
        console.log('hi how 3');
        this.endHour = this.pushEndEventHour;
      }
      const selectedDateTime = new Date(this.calendraEndDatePush);
      selectedDateTime.setHours(this.endHour);
      selectedDateTime.setMinutes(this.pushEndEventMin);
      const utcString = selectedDateTime.toISOString();
      console.log('pushHourEvent', utcString);
      this.pushEndNotificationTime = utcString;
      console.log(this.pushEndNotificationTime, 'huhuhuh');

      const currentTime = new Date();
      console.log(this.pushStartNotificationTime, 'check this');
        if (selectedDateTime <= start) {
          this.eventsEndTimeErr = 'Time should not be the equal or less then start time. please select time with 4 minute difference';
          console.log('pushNotificationTime is earlier than current time');
        } else {
          this.eventsEndTimeErr = '';
        }
      }else{
        this.eventsEndTimeErr = 'Select Hour , Minutes First';
        setTimeout(() => {   this.eventsEndTimeErr = ''   }, 2000);
         event.target.value = '';
         
      }
    } else {
      this.eventsEndTimeErr = 'Select start time first';
    }

  }
  selectpushTime(event: any) {
    let now = new Date();
    let testDate = moment(new Date(now.toString().substr(0, 16))).format(
      'YYYY-MM-DD'
    );
    now.setMinutes(now.getMinutes() + 1);
    let currentDateTime = this.datepipe.transform(now, 'HH:mm a');
    this.currentTime = currentDateTime;
    this.selectedTimePush = event;
    this.currTime1 = event;
    this.Date = this.dateSelectPush;
    let ds = this.dateSelectPush;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDatePush = date.format('YYYY-MM-DD');
    const localDate = this.calendraDatePush;
    const localTime = this.selectedTimePush;
    this.selectedTimeEventAfterFormat = moment(localTime, 'HH:mm:ss').format(
      'hh:mm:ss'
    );
    // var utcStart = new moment('2019-06-24T09:00', 'YYYY-MM-DDTHH:mm').utc();
    this.scheuledDateAndTimePushNotifications = `${localDate}T${this.selectedTimeEventAfterFormat}.00`;
    const localDateString = this.scheuledDateAndTimePushNotifications;
    const utcDateString = this.datepipe.transform(
      localDateString,
      'hh:mm:ss',
      'UTC'
    );
    this.readyDateAndTime = `${localDate}T${utcDateString}.000Z`;
    console.log(this.readyDateAndTime);
  }

  selectPushHour(event: any) {
    let now = new Date();
    let testDate = moment(new Date(now.toString().substr(0, 16))).format(
      'YYYY-MM-DD'
    );
    now.setMinutes(now.getMinutes());

    let currentDateTime = this.datepipe.transform(now, 'hh:mm a');
    this.currentTime = currentDateTime;
    this.selectedTimePush = event;
    this.currTime1 = event;
    this.Date = this.dateSelectPush;
    let ds = this.dateSelectPush;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDatePush = date.format('YYYY-MM-DD');
    const localDate = this.calendraDatePush;
    const localTime = this.selectedTimePush;
    this.selectedTimeEventAfterFormat = moment(localTime, 'HH:mm:ss').format(
      'hh:mm:ss'
    );
    // var utcStart = new moment('2019-06-24T09:00', 'YYYY-MM-DDTHH:mm').utc();
    this.scheuledDateAndTimePushNotifications = `${localDate}T${this.selectedTimeEventAfterFormat}.00`;
    const localDateString = this.scheuledDateAndTimePushNotifications;
    const utcDateString = this.datepipe.transform(
      localDateString,
      'hh:mm:ss',
      'UTC'
    );
    this.readyDateAndTime = `${localDate}T${utcDateString}.000Z`;
    console.log(this.readyDateAndTime);

    if (this.calendraDatePush == testDate) {
      if (this.currentTime[0] == '0') {
        this.currentTime = this.currentTime.slice(1, this.currentTime.length);
      }
      console.log('current time', this.currentTime);
      console.log('selectedTimePush', this.selectedTimePush);
      if (this.currentTime > this.selectedTimePush) {
        this.pushNotifyTimeErr = 'Time should not be the previous time.';
      } else {
        this.pushNotifyTimeErr = null;
      }
    } else {
      this.pushNotifyTimeErr = null;
    }
  }

  selectTime(event: any) {
    let now = new Date();
    let testDate = moment(new Date(now.toString().substr(0, 16))).format(
      'YYYY-MM-DD'
    );
    now.setMinutes(now.getMinutes());
    this.minTime = event;

    let currentDateTime = this.datepipe.transform(now, 'HH:mm a');
    this.currentTime = currentDateTime;
    this.selectedTimeEvent = event;
    this.Date = this.dateSelectEvent;
    let ds = this.dateSelectEvent;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDateEvent = date.format('YYYY-MM-DD');
    const localDate = this.calendraDateEvent;
    const localTime = this.selectedTimeEvent;
    this.selectedTimeEventAfterFormat = moment(localTime, 'HH:mm:ss').format(
      'HH:mm:ss'
    );

    this.scheuledDateAndTimePushNotifications = `${localDate}T${this.selectedTimeEventAfterFormat}.00`;
    const localDateString = this.scheuledDateAndTimePushNotifications;
    const utcDateString = this.datepipe.transform(
      localDateString,
      'HH:mm:ss',
      'UTC'
    );
    this.readyDateAndTime = `${localDate}T${utcDateString}.000Z`;
    console.log('current time', this.currentTime);
    console.log('select time', this.selectedTimeEvent);
    // const [time, modifier] = this.currentTime.split(' ');
    // let [hours, minutes] = time.split(':');
    // if (hours === '12') {
    //   hours = '00';
    // }
    // if (modifier === 'PM') {
    //   hours = parseInt(hours, 10) + 12;
    // }
    // console.log(hours,':',minutes);

    if (this.calendraDateEvent == testDate) {
      this.currentTime = this.currentTime.split(' ')[0];
      if (this.currentTime[0] == '0') {
        this.currentTime = this.currentTime.slice(1, this.currentTime.length);
      }
      if (this.currentTime > this.selectedTimeEvent) {
        console.log('hi how');
        this.eventTimeErr = 'Time should not be the previous time.';
      } else {
        this.eventTimeErr = null;
      }
    } else {
      this.eventTimeErr = null;
    }
  }
  selectDateEvent(event: any) {
    let now = new Date();
    let testDate = moment(new Date(now.toString().substr(0, 16))).format(
      'YYYY-MM-DD'
    );
    this.minDate = event.target.value;
    this.dateSelectEvent = event.target.value;
    this.Date = this.dateSelectEvent;
    let ds = this.dateSelectEvent;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDateEvent = date.format('YYYY-MM-DD');
    const localDate = this.calendraDateEvent;
    const localTime = this.selectedTimeEvent;
    this.selectedTimeEventAfterFormat = moment(localTime, 'HH:mm:ss').format(
      'HH:mm:ss'
    );
    this.scheuledDateAndTimePushNotifications = `${localDate}T${this.selectedTimeEventAfterFormat}.00`;
    const localDateString = this.scheuledDateAndTimePushNotifications;
    const utcDateString = this.datepipe.transform(
      localDateString,
      'hh:mm:ss',
      'UTC'
    );
    this.readyDateAndTime = `${localDate}T${utcDateString}.000Z`;

    if (this.calendraDateEvent == testDate) {
      if (this.currentTime[0] == '0') {
        this.currentTime = this.currentTime.slice(1, this.currentTime.length);
      }
      if (this.currentTime > this.selectedTimeEvent) {
        console.log('hi how');
        this.eventTimeErr = 'Time should not be the previous time.';
      } else {
        this.eventTimeErr = null;
      }
    } else {
      this.eventTimeErr = null;
    }
  }

  selectTimeEvent(event: any) {
    let now = new Date();
    let testDate = moment(new Date(now.toString().substr(0, 16))).format(
      'YYYY-MM-DD'
    );
    now.setMinutes(now.getMinutes() + 1);
    this.minTime = event;

    let currentDateTime = this.datepipe.transform(now, 'HH:mm a');
    this.currentTime = currentDateTime;
    this.selectedTimeEvent = event;
    this.Date = this.dateSelectEvent;
    let ds = this.dateSelectEvent;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDateEvent = date.format('YYYY-MM-DD');
    const localDate = this.calendraDateEvent;
    const localTime = this.selectedTimeEvent;
    this.selectedTimeEventAfterFormat = moment(localTime, 'HH:mm:ss').format(
      'HH:mm:ss'
    );

    this.scheuledDateAndTimePushNotifications = `${localDate}T${this.selectedTimeEventAfterFormat}.00`;
    const localDateString = this.scheuledDateAndTimePushNotifications;
    const utcDateString = this.datepipe.transform(
      localDateString,
      'HH:mm:ss',
      'UTC'
    );
    this.readyDateAndTime = `${localDate}T${utcDateString}.000Z`;
    if (this.readyDateAndTime == this.readyEndDateAndTime) {
      console.error('same time and data');
    }

    if (this.calendraDateEvent == testDate) {
      if (this.currentTime[0] == '0') {
        this.currentTime = this.currentTime.slice(1, this.currentTime.length);
      }
      if (this.currentTime > this.selectedTimeEvent) {
        console.log('hi how');
        this.eventTimeErr = 'Time should not be the previous time.';
      } else {
        this.eventTimeErr = null;
      }
    } else {
      this.eventTimeErr = null;
    }
    console.log('====================================');
    console.log(this.eventSameError);
    console.log('====================================');
  }

  selectEndTime(event: any) {
    let now = new Date();
    now.setMinutes(now.getMinutes() + 1);

    this.maxTime = event;
    let currentDateTime = this.datepipe.transform(now, 'HH:mm a');
    this.currentEndTime = currentDateTime;
    this.selectedEndTimeEvent = event;
    this.Date = this.dateSelectEndEvent;
    let ds = this.dateSelectEndEvent;
    let date = moment(new Date(ds?.toString().substr(0, 16)));
    this.calendraEndDateEvent = date.format('YYYY-MM-DD');
    const localDate = this.calendraEndDateEvent;
    const localTime = this.selectedEndTimeEvent;
    this.selectedEndTimeEventAfterFormat = moment(localTime, 'HH:mm:ss').format(
      'HH:mm:ss'
    );
    this.scheuledEndDateAndTimePushNotifications = `${localDate}T${this.selectedEndTimeEventAfterFormat}.00`;
    const localDateString = this.scheuledEndDateAndTimePushNotifications;
    const utcDateString = this.datepipe.transform(
      localDateString,
      'HH:mm:ss',
      'UTC'
    );
    this.readyEndDateAndTime = `${localDate}T${utcDateString}.000Z`;
    console.log(this.readyEndDateAndTime);
    if (this.readyDateAndTime == this.readyEndDateAndTime) {
      this.eventSameError =
        'Event start time should not be same as Event end time. Please update time accordingly.';
      console.error('same time and data');
    } else {
      this.eventSameError = null;
    }
    console.log('====================================');
    console.log(this.eventSameError);
    console.log('====================================');
  }

  selectEndDateEvent(event: any) {
    this.maxDate = event.target.value;
    this.dateSelectEndEvent = event.target.value;
    this.Date = this.dateSelectEndEvent;
    let ds = this.dateSelectEndEvent;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraEndDateEvent = date.format('YYYY-MM-DD');
    const localDate = this.calendraEndDateEvent;
    const localTime = this.selectedEndTimeEvent;
    this.selectedEndTimeEventAfterFormat = moment(localTime, 'HH:mm:ss').format(
      'HH:mm:ss'
    );
    this.scheuledEndDateAndTimePushNotifications = `${localDate}T${this.selectedEndTimeEventAfterFormat}.00`;
    const localDateString = this.scheuledEndDateAndTimePushNotifications;
    const utcDateString = this.datepipe.transform(
      localDateString,
      'HH:mm:ss',
      'UTC'
    );
    this.readyEndDateAndTime = `${localDate}T${utcDateString}.000Z`;
    console.log('data', this.readyEndDateAndTime);
    console.log(utcDateString);
    if (this.readyDateAndTime == this.readyEndDateAndTime) {
      console.error('same time and data');
    }
  }
  selectEndTimeEvent(event: any) {
    let now = new Date();
    now.setMinutes(now.getMinutes() + 1);

    this.maxTime = event;
    let currentDateTime = this.datepipe.transform(now, 'HH:mm a');
    this.currentEndTime = currentDateTime;
    this.selectedEndTimeEvent = event;
    this.Date = this.dateSelectEndEvent;
    let ds = this.dateSelectEndEvent;
    let date = moment(new Date(ds?.toString().substr(0, 16)));
    this.calendraEndDateEvent = date.format('YYYY-MM-DD');
    const localDate = this.calendraEndDateEvent;
    const localTime = this.selectedEndTimeEvent;
    this.selectedEndTimeEventAfterFormat = moment(localTime, 'HH:mm:ss').format(
      'HH:mm:ss'
    );
    this.scheuledEndDateAndTimePushNotifications = `${localDate}T${this.selectedEndTimeEventAfterFormat}.00`;
    const localDateString = this.scheuledEndDateAndTimePushNotifications;
    const utcDateString = this.datepipe.transform(
      localDateString,
      'HH:mm:ss',
      'UTC'
    );
    this.readyEndDateAndTime = `${localDate}T${utcDateString}.000Z`;
    console.log(this.readyEndDateAndTime);
    if (this.readyDateAndTime == this.readyEndDateAndTime) {
      this.eventSameError =
        'Event start time should not be same as Event end time. Please update time accordingly.';
      console.error('same time and data');
    } else {
      this.eventSameError = null;
    }
  }
  convert() {
    console.log('utc start');
    console.log('utc selectedDate', this.changeDatePushValue);
    console.log('utc selectedHour', this.selectedHour);
    console.log('utc selectedPeriod', this.selectedPeriod);
    let hour = this.selectedHour;
    if (this.selectedPeriod === 'PM' && this.selectedHour !== 12) {
      hour += 12;
    } else if (this.selectedPeriod === 'AM' && this.selectedHour === 12) {
      hour = 0;
    }

    const selectedDateTime = new Date(this.changeDatePushValue);
    selectedDateTime.setHours(hour);
    selectedDateTime.setMinutes(this.selectedMinute);

    console.log('utc utcString', selectedDateTime);
  }
  onchangeEventPush(e: any) {
    console.log('utc utcString', e.target.value);
    this.changeDatePushValue = e.target.value;
  }
  close() {
    this.uploadFlie = true;
    this.attachmentFile = false;
    this.cmsTvProgramForm['controls'].file.reset();
  }
  serviceClose() {
    this.serviceUploadFlie = true;
    this.serviceAttachmentFile = false;
    this.cmsService['controls'].file.reset();
  }
  prayerRequestClose() {
    this.prayerRequestUploadFlie = true;
    this.prayerRequestAttachmentFile = false;
    this.cmsPrayerRequest['controls'].file.reset();
  }
  templeTestClose() {
    this.templeUploadFlie = true;
    this.templeAttachmentFile = false;
    this.cmsTempleTest['controls'].file.reset();
  }
  spiritualFitnessClose() {
    this.spiritualUploadFlie = true;
    this.spiritualAttachmentFile = false;
    this.cmsSpiritualFitnessForm['controls'].file.reset();
  }
  donationsClose() {
    this.donationsUploadFlie = true;
    this.donationsAttachmentFile = false;
    this.cmsDonationForm['controls'].file.reset();
  }
  spaceNotAllowed(e: any) {
    // if (/^\s/.test(e.target.value)) {
    //   e.preventDefault();
    // }
    // console.log('evnet', e.target.selectionStart);
    // console.log('evnet1', e.code);
    // if (e.target.selectionStart === 0 && e.code === 'Space') {
    //   e.preventDefault();
    // }
  }
  CreatePushNotifications() {
    let currentDateTime = this.datepipe.transform(new Date(), 'hh:mm a');
    this.currentTime = currentDateTime;
    let now = new Date();
    let testDate = moment(new Date(now.toString().substr(0, 16))).format(
      'YYYY-MM-DD'
    );
    if (this.calendraDatePush == testDate) {
      if (this.currentTime[0] == '0') {
        this.currentTime = this.currentTime.slice(1, this.currentTime.length);
      }
      if (this.currentTime > this.selectedTimePush) {
        this.pushNotifyTimeErr = 'Time should not be the previous time.';
        return;
      } else {
        this.pushNotifyTimeErr = null;
      }
    } else {
      this.pushNotifyTimeErr = null;
    }

    if (this.cmsPushNotifications.invalid) {
      this.pushSubmitted = true;
      console.log(this.cmsPushNotifications);
    } else {
      console.log(this.cmsPushNotifications.value);

      this.pushSubmitted = false;
      this.pushbtndisabled = true;
      this.Pushloader = true;
      const formData = new FormData();
      formData.append('file', this.cmsPushNotifications.get('file').value);
      formData.append('title', this.cmsPushNotifications.value.title.trim());
      formData.append('url', this.cmsPushNotifications.value.url);
      formData.append(
        'description',
        this.cmsPushNotifications.value.description.trim()
      );
      // formData.append('date', this.readyDateAndTime);
      formData.append('date', this.pushNotificationTime);
      formData.append('deviceType', this.cmsPushNotifications.value.deviceType);

      this.services.cmsModulePushNotifications(formData).subscribe((result) => {
        this.pushnotificationsUrl = null;

        this.updatedAt = this.datepipe.transform(new Date(), 'dd-mm-yyyy');
        this.firstName = localStorage.getItem('firstName');
        this.lastName = localStorage.getItem('lastName');
        // setTimeout(() => {
        this.pushbtndisabled = false;
        this.Pushloader = false;
        this.dialog.open(SucessdialogcmsmoduleComponent, {
          disableClose: true,
        });
        this.cmsPushNotifications.reset();
        this.pushEventHour = '';
        this.pushEventMin = '';
        this.pushEventAM = '';
        this.dailPromiseTimeErr = '';

        // }, 1000);
      });
    }
  }
  createVideo() {
    if (this.cmsVideoForm.invalid) {
      this.submitted = true;
    } else {
      this.mediaLoader = true;
      this.submitted = false;
      const formData = new FormData();
      formData.append('thumbnail', this.cmsVideoForm.get('thumbnail').value);
      formData.append('title', this.cmsVideoForm.value.title.trim());
      formData.append(
        'description',
        this.cmsVideoForm.value.description.trim()
      );
      formData.append('youtubeVideo', this.cmsVideoForm.value.youtubeVideo);
      formData.append('speakerName', this.cmsVideoForm.value.speakerName);
      formData.append('categoryId', this.cmsVideoForm.value.categoryId);
      this.services.cmsModuleVideo(formData).subscribe((result) => {
        this.url = null;
        this.updatedAtMedia = this.datepipe.transform(new Date());
        this.mediaFirstName = localStorage.getItem('firstName');
        this.mediaLastNameAdmin = localStorage.getItem('lastName');
        // setTimeout(() => {
        this.mediaLoader = false;
        this.dialog.open(SucessdialogcmsmoduleComponent, {
          disableClose: true,
        });
        this.cmsVideoForm.get('title').reset();
        this.cmsVideoForm.get('description').reset();
        this.cmsVideoForm.get('youtubeVideo').reset();
        this.cmsVideoForm.get('thumbnail').reset();
        this.cmsVideoForm.get('title').reset();
        this.cmsVideoForm.get('categoryId').reset();
        // }, 1000);
      });
    }
  }
  createPromiseCard() {
    let currentDateTime = this.datepipe.transform(new Date(), 'HH:mm a');
    this.currentTime = currentDateTime;

    let now = new Date();
    let testDate = moment(new Date(now.toString().substr(0, 16))).format(
      'YYYY-MM-DD'
    );
    if (this.calendraDate == testDate) {
      if (this.currentTime[0] == '0') {
        this.currentTime = this.currentTime.slice(1, this.currentTime.length);
      }
      if (this.currentTime > this.selectedTime) {
        this.dailPromiseTimeErr = 'Time should not be the previous time.';
        return;
      } else {
        this.dailPromiseTimeErr = null;
      }
    } else {
      this.dailPromiseTimeErr = null;
    }

    if (this.cmsPromiseForm.invalid) {
      this.promisesubmitted = true;
    } else {
      this.promiseLoader = true;
      this.promisesubmitted = false;
      const formData = new FormData();
      formData.append('image', this.selectedFiles, this.fileNames);
      // formData.append('date', this.readyDateAndTimePromise);
      formData.append('date', this.pushNotificationTime);
      formData.append(
        'youtubeVideoLink',
        this.cmsPromiseForm.get('youtubeLink').value
      );
      this.services.cmsModulePromiseCard(formData).subscribe((result) => {
        this.promiseCardUrl = null;
        this.daliypromise = '';
        this.promiseTimeClear = '';
        this.updatedAtMedia = this.datepipe.transform(new Date());
        this.mediaFirstName = localStorage.getItem('firstName');
        this.mediaLastNameAdmin = localStorage.getItem('lastName');
        // setTimeout(() => {
        this.promiseLoader = true;
        this.dialog.open(SucessdialogcmsmoduleComponent, {
          disableClose: true,
        });
        // }, 1000);

        this.cmsPromiseForm.reset();
        this.pushEventHour = '';
        this.pushEventMin = '';
        this.pushEventAM = '';
        this.dailPromiseTimeErr = '';
      });
    }
  }
  createEventCard() {
    let now = new Date();
    let testDate = moment(new Date(now.toString().substr(0, 16))).format(
      'YYYY-MM-DD'
    );
    now.setMinutes(now.getMinutes());
    this.currentTime = this.datepipe.transform(now, 'HH:mm a');

    if (this.calendraDateEvent == testDate) {
      this.currentTime = this.currentTime.split(' ')[0];
      if (this.currentTime[0] == '0') {
        this.currentTime = this.currentTime.slice(1, this.currentTime.length);
      }
      if (this.currentTime > this.selectedTimeEvent) {
        console.log('hi how');
        this.eventTimeErr = 'Time should not be the previous time.';
      } else {
        this.eventTimeErr = null;
      }
    } else {
      this.eventTimeErr = null;
    }
    console.log('Form validity:', this.cmsEventForm.valid);
    console.log('Form errors:', this.cmsEventForm.errors);
    console.log('Control errors:', this.cmsEventForm.controls);
    this.cmsEventForm.controls.status.setValue('');
    console.log(this.cmsEventForm.value);
    if (this.cmsEventForm.invalid) {
      this.eventSubmitted = true;
    }
    //  else if (
    //   this.readyEndDateAndTime == '' ||
    //   this.readyEndDateAndTime == null
    // ) {
    //   this.eventSameError = 'End Date/Time Missing';
    // } 
    // else if (this.eventsEndTimeErr != '' && this.eventsEndTimeErr != null) {
    // } 
    else {
      this.eventSameError = null;
      this.eventLoader = true;
      this.eventSubmitted = false;
      const formData = new FormData();
      formData.append('thumbnail', this.selectedFileEvent, this.fileNameEvent);
      // formData.append('nextLaunchingVideo', this.readyDateAndTime);
      formData.append('eventStart', this.pushStartNotificationTime);
      formData.append('eventEnd', this.pushEndNotificationTime);
      formData.append(
        'youtubeVideoLink',
        this.cmsEventForm.value.youtubeVideoLink
      );
      formData.append('status', 'not-Live');
      this.services.cmsModuleEvents(formData).subscribe((result) => {
        this.EventUrl = null;
        this.updatedAtMedia = this.datepipe.transform(new Date());
        this.mediaFirstName = localStorage.getItem('firstName');
        this.mediaLastNameAdmin = localStorage.getItem('lastName');
        // setTimeout(() => {
        this.eventLoader = false;
        this.dialog.open(SucessdialogcmsmoduleComponent, {
          disableClose: true,
        });
        // }, 1000);

        this.cmsEventForm.reset();
        this.maxDate = null;
      });
    }
  }

  cmsTvProgram() {
    if (this.cmsTvProgramForm.invalid) {
      this.submitTvPrograms = true;
    } else {
      this.tvProgramLoader = true;

      this.submitTvPrograms = false;
      const formData = new FormData();
      formData.append('file', this.selectedFile, this.fileName);
      formData.append('modification', this.cmsTvProgramForm.value.modification);
      this.services.cmsModuleTvPrograms(formData).subscribe(
        (result) => {
          this.uploadFlie = true;
          this.attachmentFile = false;
          this.updatedAtTvprogram = this.datepipe.transform(new Date());
          this.firstNameTvprogram = localStorage.getItem('firstName');
          this.lastNameAdminTvprogram = localStorage.getItem('lastName');
          // setTimeout(() => {
          this.tvProgramLoader = false;
          this.dialog.open(SucessdialogcmsmoduleComponent, {
            disableClose: true,
          });
          // }, 1000);
          this.cmsTvProgramForm.reset();
        },
        (error) => {
          this.errorTvProgram = error.error.message;
          setTimeout(() => {
            this.errorTvProgram = '';
          }, 3000);
        }
      );
    }
  }
  cmsServiceBtn() {
    if (this.cmsService.invalid) {
      this.ServiceSubmit = true;
    } else {
      this.servicesLoader = true;

      this.ServiceSubmit = false;
      const formData = new FormData();
      formData.append('file', this.serviceSelectedFile, this.serviceFileName);
      formData.append('modification', this.cmsService.value.modification);

      this.services.cmsModuleService(formData).subscribe((result) => {
        this.serviceUploadFlie = true;
        this.serviceAttachmentFile = false;
        this.updatedAtServcie = this.datepipe.transform(new Date());
        this.firstNameService = localStorage.getItem('firstName');
        this.lastNameAdminService = localStorage.getItem('lastName');
        // setTimeout(() => {
        this.servicesLoader = false;
        this.dialog.open(SucessdialogcmsmoduleComponent, {
          disableClose: true,
        });
        this.cmsService.reset();
        // }, 1000);
      });
    }
  }
  cmsprayerRequest() {
    if (this.cmsPrayerRequest.invalid) {
      this.prayerRequestSubmit = true;
    } else {
      this.prayerReqLoader = true;
      this.prayerRequest = true;
      this.prayerRequestSubmit = false;
      const formData = new FormData();
      formData.append('file', this.prayerSelectedFile, this.prayerFileName);
      formData.append('modification', this.cmsPrayerRequest.value.modification);
      this.services.cmsModulePrayerRequest(formData).subscribe((result) => {
        this.prayerRequestUploadFlie = true;
        this.prayerRequestAttachmentFile = false;
        this.updatedAtPrayer = this.datepipe.transform(new Date());
        this.firstNamePrayer = localStorage.getItem('firstName');
        this.lastNameAdminPrayer = localStorage.getItem('lastName');
        // setTimeout(() => {
        this.prayerReqLoader = false;
        this.dialog.open(SucessdialogcmsmoduleComponent, {
          disableClose: true,
        });
        this.prayerRequest = false;
        this.cmsPrayerRequest.reset();
        // }, 1000);
      });
    }
  }
  cmsTempleTestBtn() {
    if (this.cmsTempleTest.invalid) {
      this.submitTemple = true;
    } else {
      this.templeLoader = true;
      this.submitTemple = false;
      const formData = new FormData();
      formData.append('file', this.templeSelectedFile, this.templeFileName);
      formData.append('modification', this.cmsTempleTest.value.modification);
      this.services.cmsModuleTemple(formData).subscribe((result) => {
        this.templeUploadFlie = true;
        this.templeAttachmentFile = false;
        this.updatedAtTemple = this.datepipe.transform(new Date());
        this.firstNameTemple = localStorage.getItem('firstName');
        this.lastNameAdminTemple = localStorage.getItem('lastName');
        // setTimeout(() => {
        this.templeLoader = false;
        this.dialog.open(SucessdialogcmsmoduleComponent, {
          disableClose: true,
        });
        // }, 1000);

        this.cmsTempleTest.reset();
      });
    }
  }
  cmsSpiritualFitnessBtn() {
    console.log(this.cmsSpiritualFitnessForm);
    if (this.cmsSpiritualFitnessForm.invalid) {
      this.SpiritualFitnessSubmit = true;
    } else {
      this.spiritulFitnessLoader = true;
      this.SpiritualFitnessSubmit = false;
      const formData = new FormData();
      formData.append(
        'file',
        this.spiritualSelectedFile,
        this.spiritualFileName
      );
      formData.append(
        'modification',
        this.cmsSpiritualFitnessForm.value.modification
      );
      this.services.cmsModuleSpiritualFitness(formData).subscribe((result) => {
        this.spiritualUploadFlie = true;
        this.spiritualAttachmentFile = false;
        this.updatedAtspiritual = this.datepipe.transform(new Date());
        this.firstNamespiritual = localStorage.getItem('firstName');
        this.lastNameAdminspriitual = localStorage.getItem('lastName');
        // setTimeout(() => {
        this.spiritulFitnessLoader = false;
        this.dialog.open(SucessdialogcmsmoduleComponent, {
          disableClose: true,
        });
        this.cmsSpiritualFitnessForm.reset();
        // }, 1000);
      });
    }
  }
  cmsDonationsBtn() {
    if (this.cmsDonationForm.invalid) {
      this.submittedDonations = true;
    } else {
      this.submittedDonations = false;
      this.donationsLoader = true;
      const formData = new FormData();
      formData.append(
        'file',
        this.donationsSelectedFile,
        this.donationsFileName
      );
      formData.append('modification', this.cmsDonationForm.value.modification);
      this.services.cmsModuleDonations(formData).subscribe((result) => {
        this.donationsUploadFlie = true;
        this.donationsAttachmentFile = false;
        this.updatedAtDonate = this.datepipe.transform(new Date());
        this.firstNameDonate = localStorage.getItem('firstName');
        this.lastNameAdminDonate = localStorage.getItem('lastName');
        // setTimeout(() => {
        this.donationsLoader = false;
        this.dialog.open(SucessdialogcmsmoduleComponent, {
          disableClose: true,
        });
        // }, 1000);

        this.cmsDonationForm.reset();
      });
    }
  }
  get updatecmsVideo(): { [key: string]: AbstractControl } {
    return this.cmsVideoForm.controls;
  }
  get updateCmsPushNotificaitons(): { [key: string]: AbstractControl } {
    return this.cmsPushNotifications.controls;
  }
  get updatecmsPromiseCard(): { [key: string]: AbstractControl } {
    return this.cmsPromiseForm.controls;
  }
  get updatecmsEvent(): { [key: string]: AbstractControl } {
    return this.cmsEventForm.controls;
  }
  get updatecmsDonations(): { [key: string]: AbstractControl } {
    return this.cmsDonationForm.controls;
  }
  get updatecmsTemple(): { [key: string]: AbstractControl } {
    return this.cmsTempleTest.controls;
  }
  get updatecmsPrayerRequest(): { [key: string]: AbstractControl } {
    return this.cmsPrayerRequest.controls;
  }
  get updatecmsSpiritualFitness(): { [key: string]: AbstractControl } {
    return this.cmsSpiritualFitnessForm.controls;
  }
  get updatecmsService(): { [key: string]: AbstractControl } {
    return this.cmsService.controls;
  }
  get updatecmsTvPrograms(): { [key: string]: AbstractControl } {
    return this.cmsTvProgramForm.controls;
  }
  // Approve() {
  //   this.dialog.open(SucessdialogcmsmoduleComponent, {
  //     disableClose: true,
  //   });
  // }

  reviewTabData(event: any) {
    this.pushSubmitted = false;
    this.eventSubmitted = false;
    this.promisesubmitted = false;
    this.submitted = false;
    this.pageSize = 10;
    this.currentPage = 0;

    this.currentPageMedia = 0;
    this.pageSizeMedia = 10;
    // totalMedia: number = 0;

    this.currentPageProgram = 0;
    this.pageSizeProgram = 10;
    // this.totalTvPrograms: number = 0;

    this.currentPageService = 0;
    this.pageSizeService = 10;
    // totalTvServices: number = 0;

    this.currentPageDonation = 0;
    this.pageSizeDonation = 10;
    // totalTvDonation: number = 0;

    this.currentPageTemple = 0;
    this.pageSizeTemple = 10;
    // totalTvTemple: number = 0;

    this.currentPagePrayer = 0;
    this.pageSizePrayer = 10;
    // totalTvPrayer: number = 0;

    this.currentPageSpirtual = 0;
    this.pageSizeSpirtual = 10;
    // totalTvSpirtual: number = 0;

    console.log('event', event.tab.textLabel);
    if (event && event.tab.textLabel == 'Review') {
      this.services
        .cmsNotificaitonsLastReview('Pending')
        .subscribe((res: any) => {
          if (res.data.length) {
            this.reviewCMSModule = res.data;
          } else {
            this.reviewCMSModule = [];
          }
        });
    }
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    let params = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    this.services.cmsHistoryPushNotifications(params).subscribe((response) => {
      this.ELEMENT_DATA_PUSH_NOTIFICATION = response.data;
      this.dataSourcePushNotifications = new MatTableDataSource(
        this.ELEMENT_DATA_PUSH_NOTIFICATION
      );
      this.totalCmsNot = response.totalRecords;
    });
  }

  pageChangedReview(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    let params = {
      status: this.status,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    this.services.cmsHistoryPushNotifications(params).subscribe((response) => {
      this.ELEMENT_REVIEW_PUSH_NOTIFICATION = response.data;
      this.dataReviewPushNotifications = new MatTableDataSource(
        this.ELEMENT_REVIEW_PUSH_NOTIFICATION
      );
      this.reviewCount = response.totalRecords;
    });
  }

  pageChangedMedia(event: PageEvent) {
    this.pageSizeMedia = event.pageSize;
    this.currentPageMedia = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    let params = {
      pageLimit: this.pageSizeMedia ? this.pageSizeMedia : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    if (this.selectedMedia == 'video') {
      this.services.cmsHistoryMediaVideo(params).subscribe((response) => {
        this.ELEMENT_DATA_MEDIA = response.data;
        this.dataSourceMedia = new MatTableDataSource(this.ELEMENT_DATA_MEDIA);
        this.totalMedia = response.totalRecords;
      });
    } else if (this.selectedMedia == 'event') {
      this.services.cmsHistoryMediaEvent(params).subscribe((response) => {
        this.ELEMENT_DATA_MEDIA = response.data;
        this.dataSourceMedia = new MatTableDataSource(this.ELEMENT_DATA_MEDIA);
        this.totalMedia = response.totalRecords;
      });
    } else {
      this.services.cmsHistoryMediaDpromise(params).subscribe((response) => {
        this.ELEMENT_DATA_MEDIA = response.data;
        this.dataSourceMedia = new MatTableDataSource(this.ELEMENT_DATA_MEDIA);
        this.totalMedia = response.totalRecords;
      });
    }
  }

  pageChangedReviewMedia(event: PageEvent) {
    this.pageSizeMedia = event.pageSize;
    this.currentPageMedia = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    let params = {
      status: this.status,
      pageLimit: this.pageSizeMedia ? this.pageSizeMedia : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    if (this.selectedReviewMedia == 'video') {
      this.services.cmsHistoryMediaVideo(params).subscribe((response) => {
        this.ELEMENT_REVIEW_MEDIA = response.data;
        this.dataReviewMedias = new MatTableDataSource(
          this.ELEMENT_REVIEW_MEDIA
        );
        this.reviewCountMedias = response.totalRecords;
      });
    } else if (this.selectedReviewMedia == 'event') {
      this.services.cmsHistoryMediaEvent(params).subscribe((response) => {
        this.ELEMENT_REVIEW_MEDIA = response.data;
        this.dataReviewMedias = new MatTableDataSource(
          this.ELEMENT_REVIEW_MEDIA
        );
        this.reviewCountMedias = response.totalRecords;
      });
    } else {
      this.services.cmsHistoryMediaDpromise(params).subscribe((response) => {
        this.ELEMENT_REVIEW_MEDIA = response.data;
        this.dataReviewMedias = new MatTableDataSource(
          this.ELEMENT_REVIEW_MEDIA
        );
        this.reviewCountMedias = response.totalRecords;
      });
    }
  }

  pageChangedProgram(event: PageEvent) {
    this.pageSizeProgram = event.pageSize;
    this.currentPageProgram = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    let params = {
      fileType: this.cmsHistoryTVProgram,
      pageLimit: this.pageSizeProgram ? this.pageSizeProgram : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    this.services.cmsHistoryTvProgramSection(params).subscribe((data) => {
      this.cmsTvProgramData = data.data;
      this.ELEMENT_DATA_TV_PROGRAM = data.data;
      this.dataSourceTvProgram = new MatTableDataSource(
        this.ELEMENT_DATA_TV_PROGRAM
      );
      this.totalTvPrograms = data.totalRecords;
    });
  }

  pageChangedReviewProgram(event: PageEvent) {
    this.pageSizeProgram = event.pageSize;
    this.currentPageProgram = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    let params = {
      status: this.status,
      fileType: this.cmsHistoryTVProgram,
      pageLimit: this.pageSizeProgram ? this.pageSizeProgram : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    this.services.cmsHistoryTvProgramSection(params).subscribe((data) => {
      // this.cmsTvProgramData = data.data;
      this.ELEMENT_REVIEW_TV_PROGRAM = data.data;
      this.dataReviewProgram = new MatTableDataSource(
        this.ELEMENT_REVIEW_TV_PROGRAM
      );
      this.TvProgramRecords = data.totalRecords;
    });
  }

  pageChangedService(event: PageEvent) {
    this.pageSizeService = event.pageSize;
    this.currentPageService = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    let params = {
      fileType: this.cmsHistoryService,
      pageLimit: this.pageSizeService ? this.pageSizeService : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    this.services.cmsHistoryTvProgramSection(params).subscribe((data) => {
      this.ELEMENT_DATA_SERVICE = data.data;
      this.dataSourceServcice = new MatTableDataSource(
        this.ELEMENT_DATA_SERVICE
      );
      this.totalTvServices = data.totalRecords;
    });
  }

  pageChangedReviewService(event: PageEvent) {
    this.pageSizeService = event.pageSize;
    this.currentPageService = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    let params = {
      status: this.status,
      fileType: this.cmsHistoryService,
      pageLimit: this.pageSizeService ? this.pageSizeService : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    this.services.cmsHistoryTvProgramSection(params).subscribe((data) => {
      this.ELEMENT_REVIEW_TV_SERVICES = data.data;
      this.dataReviewServices = new MatTableDataSource(
        this.ELEMENT_REVIEW_TV_SERVICES
      );
      this.serviceRecords = data.totalRecords;
    });
  }

  pageChangedDonations(event: PageEvent) {
    this.pageSizeDonation = event.pageSize;
    this.currentPageDonation = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    let params = {
      fileType: this.cmsHistoryDonate,
      pageLimit: this.pageSizeDonation ? this.pageSizeDonation : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    this.services.cmsHistoryTvProgramSection(params).subscribe((data) => {
      this.ELEMENT_DATA_DONATE = data.data;
      this.dataSourceDonate = new MatTableDataSource(this.ELEMENT_DATA_DONATE);
      this.totalTvDonation = data.totalRecords;
    });
  }

  pageChangedReviewDonations(event: PageEvent) {
    this.pageSizeDonation = event.pageSize;
    this.currentPageDonation = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    let params = {
      staus: this.status,
      fileType: this.cmsHistoryDonate,
      pageLimit: this.pageSizeDonation ? this.pageSizeDonation : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    this.services.cmsHistoryTvProgramSection(params).subscribe((data) => {
      this.ELEMENT_REVIEW_DONATE = data.data;
      this.dataReviewDonations = new MatTableDataSource(
        this.ELEMENT_REVIEW_DONATE
      );
      this.DonationsRecords = data.totalRecords;
    });
  }

  pageChangedTemple(event: PageEvent) {
    this.pageSizeTemple = event.pageSize;
    this.currentPageTemple = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    let params = {
      fileType: this.cmsHistoryTemple,
      pageLimit: this.pageSizeTemple ? this.pageSizeTemple : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };

    this.services.cmsHistoryTvProgramSection(params).subscribe((data) => {
      this.ELEMENT_DATA_TEMPLE = data.data;
      this.dataSourceTemple = new MatTableDataSource(this.ELEMENT_DATA_TEMPLE);
      this.totalTvTemple = data.totalRecords;
    });
  }

  pageChangedReviewTemple(event: PageEvent) {
    this.pageSizeTemple = event.pageSize;
    this.currentPageTemple = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    let params = {
      staus: this.status,
      fileType: this.cmsHistoryTemple,
      pageLimit: this.pageSizeTemple ? this.pageSizeTemple : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };

    this.services.cmsHistoryTvProgramSection(params).subscribe((data) => {
      this.ELEMENT_REVIEW_TEMPLE = data.data;
      this.dataReviewTemple = new MatTableDataSource(
        this.ELEMENT_REVIEW_TEMPLE
      );
      this.reviewTempleCount = data.totalRecords;
    });
  }

  pageChangedPrayer(event: PageEvent) {
    this.pageSizePrayer = event.pageSize;
    this.currentPagePrayer = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    let params = {
      fileType: this.cmsHistoryPrayerReq,
      pageLimit: this.pageSizePrayer ? this.pageSizePrayer : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };

    this.services.cmsHistoryTvProgramSection(params).subscribe((data) => {
      this.ELEMENT_DATA_PRAYER_REQ = data.data;
      this.dataSourcePrayerReq = new MatTableDataSource(
        this.ELEMENT_DATA_PRAYER_REQ
      );
      this.totalTvPrayer = data.totalRecords;
    });
  }

  pageChangedReviewPrayer(event: PageEvent) {
    this.pageSizePrayer = event.pageSize;
    this.currentPagePrayer = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    let params = {
      status: this.status,
      fileType: this.cmsHistoryPrayerReq,
      pageLimit: this.pageSizePrayer ? this.pageSizePrayer : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };

    this.services.cmsHistoryTvProgramSection(params).subscribe((data) => {
      this.ELEMENT_REVIEW_PRAYER = data.data;
      this.dataReviewPrayerRequest = new MatTableDataSource(
        this.ELEMENT_REVIEW_PRAYER
      );
      this.reviewPrayerCount = data.totalRecords;
    });
  }

  pageChangedSpiritual(event: PageEvent) {
    this.pageSizeSpirtual = event.pageSize;
    this.currentPageSpirtual = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    let params = {
      fileType: this.cmsHistorySpiritual,
      pageLimit: this.pageSizeSpirtual ? this.pageSizeSpirtual : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };

    this.services.cmsHistoryTvProgramSection(params).subscribe((data) => {
      this.ELEMENT_DATA_SPIRITUAL = data.data;
      this.dataSourceSpiritual = new MatTableDataSource(
        this.ELEMENT_DATA_SPIRITUAL
      );
      this.totalTvSpirtual = data.totalRecords;
    });
  }

  pageChangedReviewSpiritual(event: PageEvent) {
    this.pageSizeSpirtual = event.pageSize;
    this.currentPageSpirtual = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    let params = {
      status: this.status,
      fileType: this.cmsHistorySpiritual,
      pageLimit: this.pageSizeSpirtual ? this.pageSizeSpirtual : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };

    this.services.cmsHistoryTvProgramSection(params).subscribe((data) => {
      this.ELEMENT_REVIEW_TV_SPIRITUAL = data.data;
      this.dataReviewSpritual = new MatTableDataSource(
        this.ELEMENT_REVIEW_TV_SPIRITUAL
      );
      this.reviewSpiritualCount = data.totalRecords;
    });
  }

  changedReviewMedia(event: any) {
    this.ELEMENT_REVIEW_MEDIA = [];
    if (event.value == 'video') {
      let params = {
        status: this.status,
      };
      this.services.cmsHistoryMediaVideo(params).subscribe((response) => {
        this.ELEMENT_REVIEW_MEDIA = response.data;
        this.dataReviewMedias = new MatTableDataSource(
          this.ELEMENT_REVIEW_MEDIA
        );
        this.reviewCountMedias = response.totalRecords;
      });
    } else if (event.value == 'event') {
      let params = {
        status: this.status,
      };
      this.services.cmsHistoryMediaEvent(params).subscribe((response) => {
        this.ELEMENT_REVIEW_MEDIA = response.data;
        this.dataReviewMedias = new MatTableDataSource(
          this.ELEMENT_REVIEW_MEDIA
        );
        this.reviewCountMedias = response.totalRecords;
      });
    } else {
      let params = {
        status: this.status,
      };
      this.services.cmsHistoryMediaDpromise(params).subscribe((response) => {
        this.ELEMENT_REVIEW_MEDIA = response.data;
        console.log('------------>m', this.ELEMENT_REVIEW_MEDIA);

        this.dataReviewMedias = new MatTableDataSource(
          this.ELEMENT_REVIEW_MEDIA
        );
        this.reviewCountMedias = response.totalRecords
          ? response.totalRecords
          : response.data.length;
      });
    }
  }

  changeMediaType(event: any) {
    this.ELEMENT_DATA_MEDIA = [];
    this.pageSizeMedia = 10;
    this.currentPageMedia = 0;
    if (event.value == 'video') {
      let params = {
        pageLimit: this.pageSizeMedia ? this.pageSizeMedia : '',
        pageNumber: this.currentPageMedia ? this.currentPageMedia : 0,
      };
      this.services.cmsHistoryMediaVideo(params).subscribe((response) => {
        this.ELEMENT_DATA_MEDIA = response.data;
        console.log('viedio====', this.ELEMENT_DATA_MEDIA);
        this.dataSourceMedia = new MatTableDataSource(this.ELEMENT_DATA_MEDIA);
        this.totalMedia = response.totalRecords;
      });
    } else if (event.value == 'event') {
      let params = {
        pageLimit: this.pageSizeMedia ? this.pageSizeMedia : '',
        pageNumber: this.currentPageMedia ? this.currentPageMedia : 0,
      };
      this.services.cmsHistoryMediaEvent(params).subscribe((response) => {
        this.ELEMENT_DATA_MEDIA = response.data;
        console.log('event====', this.ELEMENT_DATA_MEDIA);
        this.dataSourceMedia = new MatTableDataSource(this.ELEMENT_DATA_MEDIA);
        this.totalMedia = response.totalRecords;
      });
    } else {
      let params = {
        pageLimit: this.pageSizeMedia ? this.pageSizeMedia : '',
        pageNumber: this.currentPageMedia ? this.currentPageMedia : 0,
      };
      this.services.cmsHistoryMediaDpromise(params).subscribe((response) => {
        this.ELEMENT_DATA_MEDIA = response.data;
        console.log('promise====', this.ELEMENT_DATA_MEDIA);
        this.dataSourceMedia = new MatTableDataSource(this.ELEMENT_DATA_MEDIA);
        this.totalMedia = response.totalRecords;
      });
    }
  }
}
