import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardServicesService } from 'src/app/_services/dashboard-services.service';

@Component({
  selector: 'app-deactivateuser',
  templateUrl: './deactivateuser.component.html',
  styleUrls: ['./deactivateuser.component.css'],
})
export class DeactivateuserComponent implements OnInit {
  queryParamsDetails: any;
  userDetailsParams: any;
  queryParamsStatus: any;
  name: any;
  profile: any;
  membershipId: any;
  isMembershipId: any;
  deactiveUser: boolean = true;
  statusSuccess: boolean = false;
  onLoadPage = new EventEmitter();
  status: any;

  constructor(
    private diloag: MatDialog,
    private router: Router,
    public service: DashboardServicesService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.queryParamsDetails = { ...params.keys, ...params };
      this.userDetailsParams = params.get('_id');
      console.log(this.userDetailsParams);
    });
    this.service
      .membersDetailsRequest(this.userDetailsParams)
      .subscribe((response) => {
        console.table(response);
        this.name = response.data.name;
        this.profile = response.data.profile;
        this.membershipId = response.data.membershipId;
        this.isMembershipId = response.data.isMembershipId;
      });
  }
  suspend() {
    let obj = {
      isActive: 'false',
    };
    this.service
      .membersStatusRequest(this.userDetailsParams, obj)
      .subscribe((response) => {
        console.log(response.message);
        this.status = response.message;
        this.deactiveUser = false;
        this.statusSuccess = true;
        this.onLoadPage.emit();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
  }
  params(params: any) {
    throw new Error('Method not implemented.');
  }
  close() {
    this.diloag.closeAll();
  }
}
