import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardServicesService } from '../_services/dashboard-services.service';

export interface PeriodicElement {
  user: string;
  Phoneno: any;
  image: any;
  Language: any;
  Channel: any;
  NoofPrograms: any;
  Payableamount: any;
  PaymentMode: any;
  Action: any;
  languageimage: any;
  paymentimg: any;
  tvimage: any;
  OriginImg: any;
  origin: any;
  PaymentID: any;
}

// const ELEMENT_DATA: PeriodicElement[] = [
//   {
//     user: 'Kristin Watson',
//     Phoneno: '+91-9876543210',
//     Language: 'Punjabi',
//     Channel: 'Aaradhana',
//     NoofPrograms: '2',
//     Payableamount: '₹15,000',
//     PaymentMode: 'UPI',
//     Action: '',
//     languageimage: '../../assets/images/panel_images/l-p.svg',
//     paymentimg: '../../assets/images/panel_images/upi.svg',
//     image: '../../assets/images/panel_images/user-1.svg',
//     tvimage: '../../assets/images/panel_images/ar-1.svg',
//     OriginImg: '../../assets/images/panel_images/moblie-1.svg',
//     origin: 'Mobile app',
//   },
//   {
//     user: 'Cody Fisher',
//     Phoneno: '+91-9876543210',
//     Language: 'Malyalam',
//     Channel: 'Maa TV',
//     NoofPrograms: '2',
//     Payableamount: '₹15,000',
//     PaymentMode: 'UPI',
//     Action: '',
//     languageimage: '../../assets/images/panel_images/l-m.svg',
//     paymentimg: '../../assets/images/panel_images/upi.svg',
//     image: '../../assets/images/panel_images/user-2.svg',
//     tvimage: '../../assets/images/panel_images/maa.svg',
//     OriginImg: '../../assets/images/panel_images/web-1.svg',
//     origin: 'Web',
//   },
//   {
//     user: 'Arlene McCoy',
//     Phoneno: '+91-9876543210',
//     Language: 'Tamil',
//     Channel: 'Subhavaartha',
//     NoofPrograms: '2',
//     Payableamount: '₹15,000',
//     PaymentMode: 'UPI',
//     Action: '',
//     languageimage: '../../assets/images/panel_images/l-ta.svg',
//     paymentimg: '../../assets/images/panel_images/upi.svg',
//     image: '../../assets/images/panel_images/user-3.svg',
//     tvimage: '../../assets/images/panel_images/sub.svg',
//     OriginImg: '../../assets/images/panel_images/web-1.svg',
//     origin: 'Web',
//   },
//   {
//     user: 'Floyd Miles',
//     Phoneno: '+91-9876543210',
//     Language: 'English',
//     Channel: 'Zee Telugu',
//     NoofPrograms: '2',
//     Payableamount: '₹15,000',
//     PaymentMode: 'UPI',
//     Action: '',
//     languageimage: '../../assets/images/panel_images/l-e.svg',
//     paymentimg: '../../assets/images/panel_images/upi.svg',
//     image: '../../assets/images/panel_images/user-4.svg',
//     tvimage: '../../assets/images/panel_images/zee.svg',
//     OriginImg: '../../assets/images/panel_images/web-1.svg',
//     origin: 'Web',
//   },

//   {
//     user: 'Darlene Robertson',
//     Phoneno: '+91-9876543210',
//     Language: 'Telugu',
//     Channel: 'Maa TV',
//     NoofPrograms: '2',
//     Payableamount: '₹15,000',
//     PaymentMode: 'UPI',
//     Action: '',
//     languageimage: '../../assets/images/panel_images/l-t.svg',
//     paymentimg: '../../assets/images/panel_images/upi.svg',
//     image: '../../assets/images/panel_images/user-5.svg',
//     tvimage: '../../assets/images/panel_images/maa.svg',
//     OriginImg: '../../assets/images/panel_images/moblie-1.svg',
//     origin: 'Mobile app',
//   },
//   {
//     user: 'Kristin Watson',
//     Phoneno: '+91-9876543210',
//     Language: 'Punjabi',
//     Channel: 'Aaradhana',
//     NoofPrograms: '2',
//     Payableamount: '₹15,000',
//     PaymentMode: 'UPI',
//     Action: '',
//     languageimage: '../../assets/images/panel_images/l-p.svg',
//     paymentimg: '../../assets/images/panel_images/upi.svg',
//     image: '../../assets/images/panel_images/user-1.svg',
//     tvimage: '../../assets/images/panel_images/ar-1.svg',
//     OriginImg: '../../assets/images/panel_images/web-1.svg',
//     origin: 'Web',
//   },
//   {
//     user: 'Kristin Watson',
//     Phoneno: '+91-9876543210',
//     Language: 'Tamil',
//     Channel: 'Subhavaartha',
//     NoofPrograms: '2',
//     Payableamount: '₹15,000',
//     PaymentMode: 'UPI',
//     Action: '',
//     languageimage: '../../assets/images/panel_images/l-ta.svg',
//     paymentimg: '../../assets/images/panel_images/upi.svg',
//     image: '../../assets/images/panel_images/user-3.svg',
//     tvimage: '../../assets/images/panel_images/sub.svg',
//     OriginImg: '../../assets/images/panel_images/moblie-1.svg',
//     origin: 'Mobile app',
//   },
//   {
//     user: 'Kristin Watson',
//     Phoneno: '+91-9876543210',
//     Language: 'Malyalam',
//     Channel: 'Maa TV',
//     NoofPrograms: '2',
//     Payableamount: '₹15,000',
//     PaymentMode: 'UPI',
//     Action: '',
//     languageimage: '../../assets/images/panel_images/l-m.svg',
//     paymentimg: '../../assets/images/panel_images/upi.svg',
//     image: '../../assets/images/panel_images/user-2.svg',
//     tvimage: '../../assets/images/panel_images/maa.svg',
//     OriginImg: '../../assets/images/panel_images/moblie-1.svg',
//     origin: 'Mobile app',
//   },
//   {
//     user: 'Kristin Watson',
//     Phoneno: '+91-9876543210',
//     Language: 'Punjabi',
//     Channel: 'Aaradhana',
//     NoofPrograms: '2',
//     Payableamount: '₹15,000',
//     PaymentMode: 'UPI',
//     Action: '',
//     languageimage: '../../assets/images/panel_images/l-p.svg',
//     paymentimg: '../../assets/images/panel_images/upi.svg',
//     image: '../../assets/images/panel_images/user-1.svg',
//     tvimage: '../../assets/images/panel_images/ar-1.svg',
//     OriginImg: '../../assets/images/panel_images/web-1.svg',
//     origin: 'Web',
//   },
// ];

@Component({
  selector: 'app-tv-programs',
  templateUrl: './tv-programs.component.html',
  styleUrls: ['./tv-programs.component.css'],
})
export class TvProgramsComponent implements OnInit {
  dropDownSelection: any;
  Date: any;
  calendraDate: any;
  today = new Date();
  statusOption = new FormControl('');
  serviceControl = new FormControl('');

  status:any[] = [
    { key: 'wallet', value: 'Wallet' },
    { key: 'netbanking', value: 'Net Banking' },
    { key: 'upi', value: 'UPI' },
    { key: 'card', value: 'Card' },
    { key: 'emi', value: 'EMI' },
  ];
  languages: any = [];
  params: {};
  search: any;
  fromDate: any;
  toDate: any;
  paymentUpi: any;
  searchparams: any;
  language: any;
  totalProgram: any;
  totalSponsor: any;
  totalSponsorShip: any;
  totalRows: any;
  pageSize: any = 10;
  currentPage: any;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  pageLimit: any;
  pageNumber: any;
  dateClear: any;
  dateClear1: any;
  totalRecords: any;
  donationCauseClear: string;
  statusClear: string;
  paymentClear: string;
  languageClear: string;
  searchClear: string;
  permissons: string;
  permissionsArray: any;
  queryparmsTvprogram: any;
  exportTvprogram: any;
  constructor(
    private router: Router,
    private service: DashboardServicesService,
    private spinner: NgxSpinnerService
  ) {}
  @ViewChild(MatPaginator) paginator: any = MatPaginator;
  ELEMENT_DATA: Element[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  ngOnInit(): void {
    this.permissons = localStorage.getItem('permissons');
    this.permissionsArray = JSON.parse(this.permissons);
    console.log('permissiions' , this.permissionsArray);
    this.getLanguage();

    this.params = {
      search: this.search ? this.search : '',
      language: this.language ? this.language : '',
      fromDate: this.fromDate ? this.fromDate : '',
      toDate: this.toDate ? this.toDate : '',
      paymentUpi: this.paymentUpi ? this.paymentUpi : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.tvProgramRequest(this.params).subscribe((response) => {
      this.ELEMENT_DATA = response.data;
      this.totalProgram = response.totalRecords[0]?.tvProgram;
      this.totalSponsor = response.totalRecords[0].totalSponsor;
      this.totalRecords = response.totalDocument;
      this.totalSponsorShip = response.totalRecords[0].totalSponsorship;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
    this.spinner.show();

    setTimeout(() => {
      this.spinner.hide();
    }, 1500);
  }
  reset() {
    this.languageClear = '';
    this.statusClear = '';
    this.searchClear = '';
    this.paymentClear = '';
    this.dateClear = null;
    this.dateClear1 = null;
    this.search = '';
    this.fromDate = '';
    this.language = '';
    this.toDate = '';
    this.paymentUpi = '';
    this.params = {
      search: this.search ? this.search : '',
      language: this.language ? this.language : '',
      fromDate: this.fromDate ? this.fromDate : '',
      toDate: this.toDate ? this.toDate : '',
      paymentUpi: this.paymentUpi ? this.paymentUpi : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.tvProgramRequest(this.params).subscribe((response) => {
      this.ELEMENT_DATA = response.data;
      this.totalProgram = response.totalRecords[0].tvProgram;
      this.totalSponsor = response.totalRecords[0].totalSponsor;
      this.totalSponsorShip = response.totalRecords[0].totalSponsorship;
      this.totalRecords = response.totalDocument;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    this.params = {
      search: this.search ? this.search : '',
      language: this.language ? this.language : '',
      fromDate: this.fromDate ? this.fromDate : '',
      toDate: this.toDate ? this.toDate : '',
      paymentUpi: this.paymentUpi ? this.paymentUpi : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    this.service.tvProgramRequest(this.params).subscribe((response) => {
      this.ELEMENT_DATA = response.data;
      this.totalProgram = response.totalRecords[0].tvProgram;
      this.totalSponsor = response.totalRecords[0].totalSponsor;
      this.totalSponsorShip = response.totalRecords[0].totalSponsorship;
      this.totalRecords = response.totalDocument;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }

  displayedColumns: string[] = [
    'PaymentID',
    'user',
    'Language',
    'Channel',
    'NoofPrograms',
    'Payableamount',
    'Dateoftrans',
    'PaymentMode',
    'Action',
  ];
  ngAfterViewInit() {
    // this.dataSource.sort = this.sort;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  view() {
    // this.router.navigate(['Tv-programs/Download-invoice']);
  }

  Analytics() {
    this.router.navigate(['Tv-programs/Analytics']);
  }
  onChangeDate(e: any) {
    this.dropDownSelection = e.target.value;
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.fromDate = date.format('YYYY-MM-DD');
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.params = {
        search: this.search ? this.search : '',
        language: this.language ? this.language : '',
        fromDate: this.fromDate ? this.fromDate : '',
        toDate: this.toDate ? this.toDate : '',
        paymentUpi: this.paymentUpi ? this.paymentUpi : '',
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage ? this.currentPage : '',
      };
      this.service.tvProgramRequest(this.params).subscribe((response) => {
        this.ELEMENT_DATA = response.data;
        this.totalProgram = response.totalRecords[0].tvProgram;
        this.totalSponsor = response.totalRecords[0].totalSponsor;
        this.totalSponsorShip = response.totalRecords[0].totalSponsorship;
        this.totalRecords = response.totalDocument;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      });
    }
  }
  onChangeToDate(e: any) {
    this.dropDownSelection = e.target.value;
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.params = {
        search: this.search ? this.search : '',
        language: this.language ? this.language : '',
        fromDate: this.fromDate ? this.fromDate : '',
        toDate: this.toDate ? this.toDate : '',
        paymentUpi: this.paymentUpi ? this.paymentUpi : '',
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage ? this.currentPage : '',
      };
      this.service.tvProgramRequest(this.params).subscribe((response) => {
        this.ELEMENT_DATA = response.data;
        // this.totalProgram = response.totalRecords[0].tvProgram;
        // this.totalSponsor = response.totalRecords[0].totalSponsor;
        // this.totalSponsorShip = response.totalRecords[0].totalSponsorship;
        this.totalProgram = response.totalRecords.length ? response.totalRecords[0].tvProgram : 0;
        this.totalSponsor = response.totalRecords.length ? response.totalRecords[0].totalSponsor : 0;
        this.totalSponsorShip = response.totalRecords.length ? response.totalRecords[0].totalSponsorship : 0;
        this.totalRecords = response.totalDocument;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      });
    }
  }
  serviceDropDown(event: any) {
    if (event.value != '') {
      this.paymentUpi = JSON.stringify(event.value);
      // this.paymentUpi = event.value
    } else {
      this.paymentUpi = '';
    }
    this.params = {
      search: this.search ? this.search : '',
      language: this.language ? this.language : '',
      fromDate: this.fromDate ? this.fromDate : '',
      toDate: this.toDate ? this.toDate : '',
      paymentUpi: this.paymentUpi ? this.paymentUpi : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.tvProgramRequest(this.params).subscribe((response) => {
      this.ELEMENT_DATA = response.data;
      // this.totalProgram = response.totalRecords[0].tvProgram;
      // this.totalSponsor = response.totalRecords[0].totalSponsor;
      // this.totalSponsorShip = response.totalRecords[0].totalSponsorship;
      this.totalProgram = response.totalRecords.length ? response.totalRecords[0].tvProgram : 0;
      this.totalSponsor = response.totalRecords.length ? response.totalRecords[0].totalSponsor : 0;
      this.totalSponsorShip = response.totalRecords.length ? response.totalRecords[0].totalSponsorship : 0;
      this.totalRecords = response.totalDocument;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  searchDropDown(event: any) {
    this.search = event.target.value;
    this.params = {
      search: this.search ? this.search : '',
      language: this.language ? this.language : '',
      fromDate: this.fromDate ? this.fromDate : '',
      toDate: this.toDate ? this.toDate : '',
      paymentUpi: this.paymentUpi ? this.paymentUpi : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.tvProgramRequest(this.params).subscribe((response) => {
      this.ELEMENT_DATA = response.data;
      // this.totalProgram = response.totalRecords[0].tvProgram;
      // this.totalSponsor = response.totalRecords[0].totalSponsor;
      // this.totalSponsorShip = response.totalRecords[0].totalSponsorship;
      this.totalProgram = response.totalRecords.length ? response.totalRecords[0].tvProgram : 0;
      this.totalSponsor = response.totalRecords.length ? response.totalRecords[0].totalSponsor : 0;
      this.totalSponsorShip = response.totalRecords.length ? response.totalRecords[0].totalSponsorship : 0;
      this.totalRecords = response.totalDocument;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  languageChange(event: any) {
    if (event.value != '') {
      this.language = JSON.stringify(event.value);
      // this.language = event.value;
    } else {
      this.language = '';
    }
    this.params = {
      search: this.search ? this.search : '',
      language: this.language ? this.language : '',
      fromDate: this.fromDate ? this.fromDate : '',
      toDate: this.toDate ? this.toDate : '',
      paymentUpi: this.paymentUpi ? this.paymentUpi : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.tvProgramRequest(this.params).subscribe((response) => {
      this.ELEMENT_DATA = response.data.length ? response.data : [];
      // this.ELEMENT_DATA = response.data;
      // this.totalProgram = response.totalRecords[0].tvProgram;
      // this.totalSponsor = response.totalRecords[0].totalSponsor;
      // this.totalSponsorShip = response.totalRecords[0].totalSponsorship;
      this.totalProgram = response.totalRecords.length ? response.totalRecords[0].tvProgram : 0;
      this.totalSponsor = response.totalRecords.length ? response.totalRecords[0].totalSponsor : 0;
      this.totalSponsorShip = response.totalRecords.length ? response.totalRecords[0].totalSponsorship : 0;
      this.totalRecords = response.totalDocument;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }

  getLanguage(){
    this.service.tvProgramLanguage().subscribe((response) => {
         this.languages = response.data.map((lang:any) => lang.title);
    });
  }
  download(url: any, downloadName: any) {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = downloadName;
    a.click();
    document.body.removeChild(a);
  }
  tvprogramDetailExport(){
    this.queryparmsTvprogram = {
      search: this.search ? this.search : '',
      language: this.language ? this.language : '',
      fromDate: this.fromDate ? this.fromDate : '',
      toDate: this.toDate ? this.toDate : '',
      paymentUpi: this.paymentUpi ? this.paymentUpi : '',
    }
    this.service
    .exportsDetailTvprogramData(this.queryparmsTvprogram)
    .subscribe((res:any) => {
      console.log(res);
      this.exportTvprogram = res.sheetUrl;
      this.download(this.exportTvprogram, 'sheetUrl');
    });
  }
}
