import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { DashboardServicesService } from 'src/app/_services/dashboard-services.service';
import { ViewRequestComponent } from 'src/app/prayer-requests/view-request/view-request.component';
import { MatDialog } from '@angular/material/dialog';
import { ViewissuedialogComponent } from 'src/app/support/viewissuedialog/viewissuedialog.component';
@Component({
  selector: 'app-sidenav-header',
  templateUrl: './sidenav-header.component.html',
  styleUrls: ['./sidenav-header.component.css'],
})
export class SidenavHeaderComponent implements OnInit {
  events: string[] = [];
  opened: boolean | undefined;
  @Output() toogleSideNavBar: EventEmitter<any> = new EventEmitter();
  onLoadPage = new EventEmitter();

  adminImg: any;
  name: any;
  shownotification: boolean = false;
  firstName: any;
  LastName: any;

  notificationsData: any;
  queryParams: any;
  pageLimit: any;
  pageNumber: any;
  notificationsCount: Number;
  notificationsMarkAsRead: any;
  notificationsMarkAsUnRead: any;
  notificationsDataAfter: any;
  role: any;

  constructor(
    private router: Router,
    private services: DashboardServicesService,
    private location : Location,
    private dialog: MatDialog,
  ) {
   this.notificationCount()
  }

  ngOnInit(): void {
   
    this.services.adminDetails().subscribe((results) => {
      this.adminImg = results.data.profile;
      this.firstName = results.data.firstName;
      this.LastName = results.data.lastName;
      this.role = results.data.role;
      localStorage.setItem('ROLE', this.role);
      localStorage.setItem('firstName', this.firstName);
      localStorage.setItem('lastName', this.LastName);
      if (this.firstName == 'null' || this.LastName == 'null') {
        this.name = '';
      } else {
        this.name = `${this.firstName} ${this.LastName}`;
      }
    });
    this.queryParams = {
      pageLimit: this.pageLimit ? this.pageLimit : '1000',
      pageNumber: this.pageNumber ? this.pageNumber : '1',
    };
    this.services.notifications(this.queryParams).subscribe((res) => {
      this.notificationsDataAfter = res.data;
      this.notificationsMarkAsRead = this.notificationsDataAfter.filter(
        (item: any) => {
          return item.isMarkAsRead == false;
        }

        );
      // this.notificationsData.push(this.notificationsMarkAsRead);

      this.pageLimit = res.totalRecords;
    });
  }
  nav_position: any = 'start';
  toggleSidenav() {
    this.toogleSideNavBar.emit();
  }

  onTogglePosition(position: string) {
    this.nav_position = position === 'start' ? 'end' : 'start';
  }
  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/admin/login']);
  }
  closeNotifications() {
    this.shownotification = false;
  }
  markAsRead() {
    this.services.notificationsMarkAsRead().subscribe((result) => {
      this.notificationCount()
      // this.notificationsCount = 0;
      // this.notificationsMarkAsRead = '';
      this.notificaitonsOpen()
    });
  }
  notificationCount(){
    this.services.notificationsCount().subscribe((res) => {
      this.notificationsCount = res.data;
    });
  }
  notificaitonsOpen() {
    this.shownotification = true;
    this.queryParams = {
      pageLimit: this.pageLimit ? this.pageLimit : '1000',
      pageNumber: this.pageNumber ? this.pageNumber : '1',
    };
    this.services.notifications(this.queryParams).subscribe((res) => {
      this.notificationsDataAfter = res.data;
      console.log('-------------->' , res);
      this.notificationsMarkAsRead = this.notificationsDataAfter.filter(
        (item: any) => {
          console.log(item);
          return item
          // .isMarkAsRead == false;
        }
      );
      this.pageLimit = res.totalRecords;
    });
  }

  navigate(notification:any){
    // this.toogleSideNavBar.emit();
    this.shownotification = false;
    this.services.singleNotificationsMarkAsRead(notification._id).subscribe((res)=>{
      this.notificationCount()
      if(notification.notificationType == 'service'){
        // this.location.href = `/Servicerequests/viewRequest?_id=${notification.requestId}`
        this.router.navigate(['/Servicerequests/viewRequest'],{
          queryParams:{_id:notification.requestId},
        });
      }else if(notification.notificationType == 'prayer'){
        this.router.navigate(['/Prayer-requests/viewRequest'],{
          queryParams:{_id:notification.requestId}
        });
      }else if(notification.notificationType == 'RaiseAnIssue'){
        this.router.navigate(['/Support'],{
          queryParams:{_id:notification.requestId}
        });
        this.dialog.open(ViewissuedialogComponent, {
          disableClose: true,
        });
      }
    })
  }

}
