import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { MarkAsClosedcommonDialogComponent } from '../mark-as-closedcommon-dialog/mark-as-closedcommon-dialog.component';
import { MarkAsInvalidcommonDialogComponent } from '../mark-as-invalidcommon-dialog/mark-as-invalidcommon-dialog.component';
import { DashboardServicesService } from '../_services/dashboard-services.service';
import { InvaliddialogComponent } from './invaliddialog/invaliddialog.component';

declare const audioplayer: any;
export interface PeriodicElement {
  Prayerrequestno: any;
  user: string;
  phoneno: any;
  Cause: any;
  language: any;
  Date:any
  Status: string;
  Action: any;
  image: any;
  tvimage: any;
}

@Component({
  selector: 'app-prayer-requests',
  templateUrl: './prayer-requests.component.html',
  styleUrls: ['./prayer-requests.component.css'],
})
export class PrayerRequestsComponent implements OnInit {
  searchText: any;
  dropDownSelection: any;
  Date: any;
  calendraDate: any;
  today = new Date();
  markedClose: boolean = false;
  statusOption = new FormControl('');
  serviceControl = new FormControl('');
  PrayerCauseControl = new FormControl('');
  languageControl = new FormControl('');
  status: any = [
    { key: 'New', value: 'in-progress' },
    { key: 'Completed', value: 'completed' },
    { key: 'Cancelled', value: 'cancelled' },
  ];
  PrayerCause: string[] = [
    'Job',
    'Family',
    'Birthday Cake',
    'Exams',
    'Financial Problems',
    'Marriage',
    'Business',
    'Healing',
    'Spiritual Life',
    'Children',
    'Emergency',
    'Deliverance',
    'Other Reasons',
  ];
  language: any = [
    { key: 'Telugu', value: 'Telugu' },
    { key: 'English', value: 'English' },
    { key: 'Hindi', value: 'Hindi' },
    { key: 'Tamil', value: 'Tamil' },
    { key: 'Kannada', value: 'Kannada' },
    { key: 'Marathi', value: 'Marathi' },
    { key: 'Bengali', value: 'Bengali' },
    { key: 'Oriya', value: 'Oriya' },
    { key: 'Urdu', value: 'Urdu' },
    { key: 'Gujarati', value: 'Gujarati' },
    { key: 'Assamese', value: 'Assamese' },
    { key: 'Punjabi', value: 'Punjabi' },
    { key: 'Malayalam', value: 'Malayalam' },
    { key: 'Bhojpuri', value: 'Bhojpuri' },
    { key: 'Sinhalese', value: 'Sinhalese' },
    { key: 'Nepali', value: 'Nepali' },
    { key: 'Konkani', value: 'Konkani' },
  ];
  totalrecords: any;
  activeRequest: any;
  cancelledRequest: any;
  completedRequest: any;
  ELEMENT_DATA: Element[] = [];
  onLoadPage = new EventEmitter();
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  prayerRequest: any;
  languageparams: any;
  queryParams: any;
  prayerCauseDropDownValue: any;
  queryParamsDetails: any;
  userDetailsParams: string;
  queryParamsStatus: any;
  statusCompleted: any = 'completed';
  statusDeclined: any = 'cancelled';
  statusparams: any;
  toDate: any;
  searchparams: any;
  serviceparams: any;
  prayer: any;
  pageSize: any = 100;
  currentPage: any;
  // pageSizeOptions: number[] = [10, 20, 40, 100];
  pageSizeOptions: number[] = [100, 200, 400, 1000];
  pageLimit: any;
  pageNumber: any;
  statusClear: any;
  dateClear: any;
  dateClear1: any;
  prayerCauseClear: string;
  languageClear: string;
  searchClear: string;
  prayerreq: any;
  permissons: string;
  permissionsArray: any;
  exportDetailPrayerReq: any;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private services: DashboardServicesService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {}
  @ViewChild(MatPaginator) paginator: any = MatPaginator;

  ngOnInit(): void {
    this.permissons = localStorage.getItem('permissons');
    this.permissionsArray = JSON.parse(this.permissons);
    this.prayerrequestdropdown();
    this.getAllPrayerRequest();
    this.spinner.show();

    setTimeout(() => {
      this.spinner.hide();
    }, 1500);
  }
  prayerrequestdropdown() {
    this.services.prayerRequestList().subscribe((res) => {
      this.prayerreq = res.data;
    });
  }
  reset() {
    this.prayerCauseClear = '';
    this.statusClear = '';
    this.searchClear = '';
    this.languageClear = '';
    this.serviceparams = '';
    this.searchparams = '';
    this.calendraDate = '';
    this.statusparams = '';
    this.toDate = '';
    this.dateClear = null;
    this.dateClear1 = null;
    this.queryParams = {
      service: this.serviceparams ? this.serviceparams : '',
      status: this.statusparams ? this.statusparams : '',
      search: this.searchparams ? this.searchparams : '',
      fromDate: this.calendraDate ? this.calendraDate : '',
      toDate: this.toDate ? this.toDate : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.services.prayerRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.data;
      this.totalrecords = response.totalRecords;
      this.activeRequest = response.active;
      this.cancelledRequest = response.cancelled;
      this.completedRequest = response.completed;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }

  getAllPrayerRequest() {
    this.queryParams = {
      status: this.statusparams ? this.statusparams : '',
      search: this.searchparams ? this.searchparams : '',
      fromDate: this.calendraDate ? this.calendraDate : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
      toDate: this.toDate ? this.toDate : '',
      language: this.languageparams ? this.languageparams : '',
      prayer: this.prayerCauseDropDownValue
        ? this.prayerCauseDropDownValue
        : '',
    };
    this.services.prayerRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.data;
      // this.prayerRequest = response.member_types;
      this.totalrecords = response.totalRecords;
      this.activeRequest = response.active;
      this.cancelledRequest = response.cancelled;
      this.completedRequest = response.completed;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      // this.dataSource.paginator = this.paginator;
    });
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    this.queryParams = {
      status: this.statusparams ? this.statusparams : '',
      search: this.searchparams ? this.searchparams : '',
      fromDate: this.calendraDate ? this.calendraDate : '',
      toDate: this.toDate ? this.toDate : '',
      language: this.languageparams ? this.languageparams : '',
      prayer: this.prayerCauseDropDownValue
        ? this.prayerCauseDropDownValue
        : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    this.services.prayerRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.data;
      this.totalrecords = response.totalRecords;
      this.activeRequest = response.active;
      this.cancelledRequest = response.cancelled;
      this.completedRequest = response.completed;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }

  serviceDropDown(event: any) {
    if (event.value != '') {
      this.languageparams = JSON.stringify(event.value);
    } else {
      this.languageparams = '';
    }
    this.queryParams = {
      status: this.statusparams ? this.statusparams : '',
      search: this.searchparams ? this.searchparams : '',
      fromDate: this.calendraDate ? this.calendraDate : '',
      toDate: this.toDate ? this.toDate : '',
      language: this.languageparams ? this.languageparams : '',
      prayer: this.prayerCauseDropDownValue
        ? this.prayerCauseDropDownValue
        : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.services.prayerRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.data;
      this.totalrecords = response.totalRecords;
      this.activeRequest = response.active;
      this.cancelledRequest = response.cancelled;
      this.completedRequest = response.completed;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  searchDropDown(event: any) {
    this.searchparams = event.target.value;
    this.queryParams = {
      status: this.statusparams ? this.statusparams : '',
      search: this.searchparams ? this.searchparams : '',
      fromDate: this.calendraDate ? this.calendraDate : '',
      toDate: this.toDate ? this.toDate : '',
      language: this.languageparams ? this.languageparams : '',
      prayer: this.prayerCauseDropDownValue
        ? this.prayerCauseDropDownValue
        : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.services.prayerRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.data;
      this.totalrecords = response.totalRecords;
      this.activeRequest = response.active;
      this.cancelledRequest = response.cancelled;
      this.completedRequest = response.completed;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }

  prayerCauseDropDown(event: any) {
    if (event.value != '') {
      this.prayerCauseDropDownValue = JSON.stringify(event.value);
    } else {
      this.prayerCauseDropDownValue = '';
    }
    this.queryParams = {
      status: this.statusparams ? this.statusparams : '',
      search: this.searchparams ? this.searchparams : '',
      fromDate: this.calendraDate ? this.calendraDate : '',
      toDate: this.toDate ? this.toDate : '',
      language: this.languageparams ? this.languageparams : '',
      prayer: this.prayerCauseDropDownValue
        ? this.prayerCauseDropDownValue
        : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.services.prayerRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.data;
      this.totalrecords = response.totalRecords;
      this.activeRequest = response.active;
      this.cancelledRequest = response.cancelled;
      this.completedRequest = response.completed;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  displayedColumns: string[] = [
    'Prayerrequestno',
    'user',
    'phoneno',
    'Cause',
    'language',
    'Date',
    'Status',
    'Origin',
    'Action',
  ];
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;

    // this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  statusDropDown(event: any) {
    if (event.value != '') {
      this.statusparams = JSON.stringify(event.value);
    } else {
      this.statusparams = '';
    }
    this.queryParams = {
      status: this.statusparams ? this.statusparams : '',
      search: this.searchparams ? this.searchparams : '',
      fromDate: this.calendraDate ? this.calendraDate : '',
      toDate: this.toDate ? this.toDate : '',
      language: this.languageparams ? this.languageparams : '',
      prayer: this.prayerCauseDropDownValue
        ? this.prayerCauseDropDownValue
        : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.services.prayerRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.data;
      this.totalrecords = response.totalRecords;
      this.activeRequest = response.active;
      this.cancelledRequest = response.cancelled;
      this.completedRequest = response.completed;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  onChangeDate(e: any) {
    this.dropDownSelection = e.target.value;
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
  }

  view() {
    this.router.navigate(['Prayer-requests/viewRequest']);
  }
  Analytics() {
    this.router.navigate(['Prayer-requests/Analytics']);
  }
  mark(id: any) {
    this.markedClose = true;

    this.queryParamsStatus = {
      status: this.statusCompleted ? this.statusCompleted : '',
    };
    this.services
      .prayerRequestStatus(id, this.queryParamsStatus)
      .subscribe((response) => {
        this.dialog.open(MarkAsClosedcommonDialogComponent, {
          disableClose: true,
        });
        this.getAllPrayerRequest();
        this.onLoadPage.emit();
      });
    setTimeout(() => {
      this.markedClose = false;
    }, 3000);
  }
  decline() {
    const dialogRef = this.dialog.open(InvaliddialogComponent, {
      disableClose: true,
    });
    const sub = dialogRef.componentInstance.onLoadPage.subscribe(() => {
      this.getAllPrayerRequest();
    });
  }
  download(url: any, downloadName: any) {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = downloadName;
    a.click();
    document.body.removeChild(a);
  }
  prayerReqDetailExport(){
    this.queryParams = {
      status: this.statusparams ? this.statusparams : '',
      search: this.searchparams ? this.searchparams : '',
      fromDate: this.calendraDate ? this.calendraDate : '',
      toDate: this.toDate ? this.toDate : '',
      language: this.languageparams ? this.languageparams : '',
      prayer: this.prayerCauseDropDownValue
        ? this.prayerCauseDropDownValue
        : '',
    };
    this.services
    .exportDetailPrayerRequest(this.queryParams)
    .subscribe((res:any) => {
      console.log(res);
      this.exportDetailPrayerReq = res.sheetUrl;
      this.download(this.exportDetailPrayerReq, 'sheetUrl');
    });
  }
}
