import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../_services/loader.service';

@Component({
  selector: 'app-showloader',
  templateUrl: './showloader.component.html',
  styleUrls: ['./showloader.component.css'],
})
export class ShowloaderComponent implements OnInit {
  loaderShow: boolean;
  constructor(public loader: LoaderService) {
    this.loader.loading.subscribe((res) => {
      this.loaderShow = res;
    });
  }

  ngOnInit(): void {}
}
