<div class="container-fluid bg-white">
  <div class="pt-3 ml-1 d-flex">
    <div class="d-flex pt-3 pb-2 mr-auto">
      <div class="back-btn mr-2 cursor" routerLink="/Userslist">
        <img
          src="../../../assets/images/panel_images/a-arrow.svg"
          class="back-img"
        />
      </div>
      <div class="user-header-back">
        <h5>Analytics</h5>
        <p><span>Home / Members List / </span>Analytics</p>
      </div>
    </div>
    <!-- <div class="calendra-field mr-3 mt-2 ml-3">
      <mat-form-field class="example-full-width" appearance="fill">
        <input
          matInput
          (dateChange)="onChangeDate($event)"
          [matDatepicker]="picker"
          [max]="today"
          readonly="readonly"
        />
        <span class="date" matPrefix>Date Range:&nbsp;</span>
        <mat-datepicker-toggle matSuffix [for]="picker">
          <mat-icon matDatepickerToggleIcon
            ><img
              src="../../assets/images/panel_images/c-11.svg"
              class="calendra-img"
          /></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div> -->
  </div>
</div>

<!-- member-list-Analystics -->

<div class="user-header-details-img-section mt-4 mx-4 bg-white">
  <div class="container-fluid px-3 pt-4 mb-4">
    <div class="ml-3 Analytics-one">
      <div class=" py-3 px-3 d-flex">
        <div class="mt-0 mr-auto">
          <div class="user-list-heading d-flex">
            <img
              src="../../assets/images/panel_images/user-list.svg"
              class="user-list mr-2"
            />
            <h6 class="mt-2">Filters</h6>
          </div>
        </div>
        <div class="ml-3">
          <mat-form-field appearance="fill">
            <mat-label>Select Year</mat-label>
            <mat-select
              [(ngModel)]="selectedYear"
              (selectionChange)="selectYear($event)"
            >
              <mat-option *ngFor="let year of yearList" [value]="year">
                {{ year }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="ml-3">
          <mat-form-field appearance="fill">
            <mat-label>Select Month</mat-label>
            <mat-select
              [(ngModel)]="selectedMonth"
              (selectionChange)="selectMonth($event)"
            >
              <mat-option *ngFor="let month of monthList" [value]="month.value">
                {{ month.key }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="calendra-field mr-3 ml-3">
          <mat-form-field class="example-full-width" appearance="fill"> <span matPrefix>Date:&nbsp;</span>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate class="ml-1" placeholder="Start date" readonly="readonly"
                    (dateChange)="onChangeDate($event)" [value]="startDateMember">
                <input matEndDate placeholder="End date" readonly="readonly"
                    (dateChange)="onChangeToDate($event)" [value]="endDateMember">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon><img src="../../assets/images/panel_images/c-11.svg"
                        class="calendra-img" /></mat-icon>
            </mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        </div>
        <div class="graph cursor mr-2" (click)="resetMemberFilter()">
          <img
            src="../../assets/images/panel_images/reset-1.png"
            class="reset-img ml-2"
          />
        </div>
      </div>
    </div>
    <div class="row mt-4 ml-1 mr-xl-1">
      <div class="col">
        <div class="charts-bg">
          <div class="pt-3 ml-3 d-flex">
            <div class="d-flex pt-3 pb-2 mt-1 mr-auto">
              <div class="user-header-back">
                <h5 class="ml-2">User Growth</h5>
                <div class="present-weeks ml-2 mt-4">
                  <div class="d-flex">
                    <div class="d-flex mr-3">
                      <span class="present-dot"></span>
                      <h5>Male</h5>
                    </div>
                    <div class="d-flex">
                      <span class="romania-dot"></span>
                      <h5>Female</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="export-button mt-2 mr-3">
              <button type="button" class="cursor" (click)="membersExportBtn()">
                <img
                  src="../../../assets/images/panel_images/export.svg"
                  class="mr-2"
                />Export
              </button>
            </div>
          </div>
          <div class="chart ml-3 pt-3 pb-3 mr-3">
            <!-- <h1 class="no-data" *ngIf="maleMembers == ''">No data available</h1> -->
            <highcharts-chart
              [Highcharts]="highcharts"
              [options]="chartOptions"
              style="width: 100%; height: 300px; display: block"
            ></highcharts-chart>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-6">
      <div class="ml-2 charts-bg">
        <div class="pt-3 ml-3 d-flex">
          <div class="d-flex pt-3 pb-2 mt-1 mr-auto">
            <div class="user-header-back">
              <h5 class="ml-2">Attendance</h5>
              <div class="present-weeks ml-2 mt-4">
                <div class="d-flex">
                  <div class="d-flex mr-3">
                    <span class="present-dot"></span>
                    <h5> >75%</h5>
                  </div>
                  <div class="d-flex">
                    <span class="romania-dot"></span>
                    <h5>
                      <75% </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="export-button mt-2 mr-3">
            <button type="button" class="">
              <img src="../../../assets/images/panel_images/export.svg"  class="mr-2">Export </button>
          </div>
        </div>
        <div class="chart ml-3 pt-3 pb-3 mr-3">
          <highcharts-chart [Highcharts]="highcharts" [options]="chartOptionsAnalytics" style="width: 100%; height: 300px; display: block;"></highcharts-chart>
        </div>
      </div>
    </div> -->
    </div>
  </div>
</div>
<!-- member-list-Analystics -->

<!-- OLD----------------- -->
<!-- <div class="container-fluid pt-4 mb-4">
  <div class="ml-1 pt-2 Analytics-one">
    <div class="pt-3 ml-3 d-flex">
      <div class="d-flex pt-3 pb-2 mt-1 mr-auto">
        <div class="user-header-back">
          <h5 class="ml-2">Users</h5>
          <div class="present-weeks ml-2 mt-4">
            <div class="d-flex">
              <div class="d-flex mr-3">
                <span class="present-dot"></span>
                <h5>Male</h5>
              </div>
              <div class="d-flex">
                <span class="romania-dot"></span>
                <h5>Female</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="calendra-field mr-3 mt-2 ml-3">
        <mat-form-field class="example-full-width" appearance="fill">
          <span matPrefix>Date:&nbsp;</span>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              matInput
              class="ml-1"
              placeholder="Start date"
              readonly="readonly"
              (dateChange)="onChangeDate($event)"
            />
            <input
              matEndDate
              matInput
              placeholder="End date"
              readonly="readonly"
              (dateChange)="onChangeToDate($event)"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon
              ><img
                src="../../assets/images/panel_images/c-11.svg"
                class="calendra-img"
            /></mat-icon>
          </mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="export-button mt-2 mr-3">
        <button type="button" class="btn">
          <img
            src="../../../assets/images/panel_images/export.svg"
            class="mr-2"
          />Export
        </button>
      </div>
    </div>
    <div class="chart ml-3 pt-3 pb-3 mr-3">
      <highcharts-chart
        [Highcharts]="highcharts"
        [options]="chartOptions"
        style="width: 100%; height: 300px; display: block"
      >
      </highcharts-chart>
    </div>
  </div> -->
<!-- <div class="ml-1 mt-4  Analytics-one">
      <div class="pt-3 ml-3 d-flex">
       <div class="d-flex pt-3 pb-2 mt-1 mr-auto">
        <div class="user-header-back">
            <h5 class="ml-2">Statistics</h5>
             <div class="present-weeks ml-2 mt-4">
            <div class="d-flex">
                <div class="d-flex mr-3">
                      <span class="present-dot"></span>
             <h5> >75%</h5>
                </div>
                <div class="d-flex">
                <span class="romania-dot"></span>
             <h5> <75% </h5>     
                </div>
               
            </div>
    
           </div>
        </div>
    </div>
      <div class="calendra-field mr-3 mt-2 ml-3">
      <mat-form-field class="example-full-width" appearance="fill">
        <input
          matInput
          (dateChange)="StatisticsDate($event)"
          [matDatepicker]="picker2"
          [max]="today"
          readonly="readonly"
        />
        <span class="date" matPrefix>Date Range:&nbsp;</span>
        <mat-datepicker-toggle matSuffix [for]="picker2">
          <mat-icon matDatepickerToggleIcon
            ><img
              src="../../assets/images/panel_images/c-11.svg"
              class="calendra-img"
          /></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
        <div class="export-button mt-2 mr-3">
        <button type="button" class="btn"><img src="../../../assets/images/panel_images/export.svg" class="mr-2">Export</button>
      </div>
    </div>
    <div class="chart ml-3 pt-3 pb-3 mr-3">
  <highcharts-chart
                [Highcharts] = "highcharts" 
                [options] = "chartOptionsAnalytics" 
                style = "width: 100%; height: 300px; display: block;">
             </highcharts-chart>
    </div>
    
    </div> -->
<!-- </div> -->
