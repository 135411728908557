import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-itsupoortsuccessdialog',
  templateUrl: './itsupoortsuccessdialog.component.html',
  styleUrls: ['./itsupoortsuccessdialog.component.css'],
})
export class ItsupoortsuccessdialogComponent implements OnInit {
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}
  close() {
    this.dialog.closeAll();
  }
}
