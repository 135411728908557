import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MarkAsInvalidcommonDialogComponent } from 'src/app/mark-as-invalidcommon-dialog/mark-as-invalidcommon-dialog.component';
import { DashboardServicesService } from 'src/app/_services/dashboard-services.service';

@Component({
  selector: 'app-decline-dialog',
  templateUrl: './decline-dialog.component.html',
  styleUrls: ['./decline-dialog.component.css'],
})
export class DeclineDialogComponent implements OnInit {
  queryParamsStatus: any;
  queryParamsDetails: any;
  userDetailsParams: string;
  statusDecline: any = 'cancelled';
  onLoadPage = new EventEmitter();
  queryParams: any;
  serviceparams: any;
  constructor(
    private diloag: MatDialog,
    private activatedRoute: ActivatedRoute,
    private service: DashboardServicesService,
    private dialogRef: MatDialogRef<DeclineDialogComponent>
  ) {}

  ngOnInit(): void {}
  close() {
    this.diloag.closeAll();
  }
  decline() {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.queryParamsDetails = { ...params.keys, ...params };
      if (params.get('_id') != null) {
        this.userDetailsParams = params.get('_id');
      }
    });
    this.queryParamsStatus = {
      status: this.statusDecline ? this.statusDecline : '',
    };
    this.service
      .serviceRequestStatus(this.userDetailsParams, this.queryParamsStatus)
      .subscribe((response) => {
        this.dialogRef.close();
        this.diloag.open(MarkAsInvalidcommonDialogComponent, {
          data: {
            disableClose: true,
          },
        });
        this.onLoadPage.emit();
      });
  }
  getAllserviceRequest() {
    this.queryParams = {
      service: this.serviceparams ? this.serviceparams : '',
    };
    this.service.serviceRequest(this.queryParams).subscribe((response) => {});
  }
}
