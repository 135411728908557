<div class="container-fluid bg-white">
  <div class="pt-3 ml-1">
    <div class="d-flex pt-3 pb-2">
      <div class="back-btn mr-2 cursor" routerLink="/Tv-programs">
        <img
          src="../../../assets/images/panel_images/back.svg"
          class="back-img"
        />
      </div>
      <div class="user-header-back">
        <h5>TV Programs</h5>
        <p><span>Home / </span>Invoice</p>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-md-12">
      <div class="user-header-details-img-section pt-5 bg-white ml-1">
        <div class="users-img mb-4">
          <div
            class="user-details-img d-flex mb-4"
            *ngFor="let user of issueDialog"
          >
            <div class="d-flex mr-auto mt-0">
              <div class="mr-3 ml-4">
                <img
                  *ngIf="user?.users[0]?.profile != null || ''"
                  src="{{ user?.users[0]?.profile }}"
                  class="tv-p-img"
                />
                <ngx-avatar
                  *ngIf="user?.users[0]?.profile == null"
                  class="mr-2 mt-3"
                  initialsSize="1"
                  size="80"
                  name="{{ user?.users[0]?.name }}"
                ></ngx-avatar>
                <ngx-avatar
                  *ngIf="user?.users[0]?.name == null"
                  class="mr-2 name-a"
                  initialsSize="1"
                  size="80"
                  name="N/A"
                ></ngx-avatar>
              </div>
              <div class="details_user mt-3">
                <h4 [class.no-name]="user?.users[0]?.membershipId == null">
                  {{ user?.users[0]?.name ? user?.users[0]?.name : "N/A" }}
                </h4>
                <p *ngIf="user?.users[0]?.membershipId != null">
                  {{
                    user?.users[0]?.membershipId
                      ? user.users[0]?.membershipId
                      : "-"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr class="hr-line-user" />
        <div class="details-section mt-3 ml-5">
          <div class="d-flex" *ngFor="let user of issueDialog">
            <div class="details-heading details-services">
              <h4 class="mr-0">Name</h4>
            </div>
            <div class="details-heading-user">
              <h4 class="mr-3">
                {{ user.users[0]?.name ? user.users[0]?.name : "N/A" }}
              </h4>
            </div>
          </div>
          <div class="d-flex" *ngFor="let user of issueDialog">
            <div class="details-heading details-services">
              <h4 class="mr-0">Email</h4>
            </div>
            <div class="details-heading-user">
              <h4 class="mr-3">
                {{ user.users[0]?.email ? user.users[0]?.email : "N/A" }}
              </h4>
            </div>
          </div>
          <div class="d-flex" *ngFor="let user of issueDialog">
            <div class="details-heading details-services">
              <h4 class="mr-0">Mobile:</h4>
            </div>
            <div class="details-heading-user">
              <h4 class="mr-3">
                {{ user.users[0]?.phone ? user.users[0]?.phone : "N/A" }}
              </h4>
            </div>
          </div>
          <div class="d-flex" *ngFor="let user of issueDialog">
            <div class="details-heading details-services">
              <h4 class="mr-0">Channel:</h4>
            </div>
            <div class="details-heading-user">
              <h4 class="mr-3">
                {{
                  user?.channels[0]?.channelName
                    ? user?.channels[0]?.channelName
                    : "N/A"
                }}
              </h4>
            </div>
          </div>
          <div class="d-flex" *ngFor="let user of issueDialog">
            <div class="details-heading details-services">
              <h4 class="mr-0">No. of Programs:</h4>
            </div>
            <div class="details-heading-user">
              <h4 class="mr-3">
                {{ user?.noOfProgram ? user?.noOfProgram : "N/A" }}
              </h4>
            </div>
          </div>
          <div class="d-flex" *ngFor="let user of issueDialog">
            <div class="details-heading details-services">
              <h4 class="mr-0">Amount:</h4>
            </div>
            <div class="details-heading-user">
              <h4 class="mr-3">₹ {{ user?.amount ? user?.amount : "N/A" }}</h4>
            </div>
          </div>

          <div class="d-flex" *ngFor="let user of issueDialog">
            <div class="details-heading details-services">
              <h4 class="mr-0">Payment Mode:</h4>
            </div>
            <div class="details-heading-user">
              <h4 class="mr-3">
                {{ user.payment[0]?.method ? user.payment[0]?.method : "-" }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-md-7">
      <div class="user-header-details-img-section pt-3 bg-white ml-1">
        <div class="users-img mb-4 d-flex pt-0">
          <div class="invoice mr-auto">
            <h4 class="ml-3 mt-3">Invoice</h4>
          </div>
          <div class="download-invoice-button mr-4">
            <button class="btn" type="button">
              <img
                src="../../../assets/images/panel_images/d-in.svg"
                class="mr-2"
              />Download
            </button>
          </div>
        </div>
        <hr />
      </div>
    </div> -->
  </div>
</div>
