import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DeclineDialogComponent } from './decline-dialog/decline-dialog.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FormControl, FormGroup } from '@angular/forms';
import { DashboardServicesService } from '../_services/dashboard-services.service';
import { MarkAsClosedcommonDialogComponent } from '../mark-as-closedcommon-dialog/mark-as-closedcommon-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoaderService } from 'src/app/_services/loader.service';
export interface PeriodicElement {
  Requestno: string;
  user: string;
  Service: any;
  FAMILY: any;
  Date:any
  Status: string;
  Action: any;
  image: any;
}

@Component({
  selector: 'app-service-requests',
  templateUrl: './service-requests.component.html',
  styleUrls: ['./service-requests.component.css'],
})
export class ServiceRequestsComponent implements OnInit, AfterViewInit {
  searchText: any;
  dropDownSelection: any;
  Date: any;
  calendraDate: any;
  today = new Date();
  markedClose: boolean = false;
  statusOption = new FormControl('');
  serviceControl = new FormControl('');

  // status: string[] = ['in-progress', 'completed', 'accepted', 'cancelled'];
  status: any = [
    { key: 'New', value: 'in-progress' },
    { key: 'Completed', value: 'completed' },
    { key: 'Cancelled', value: 'cancelled' },
    { key: 'Accepted', value: 'accepted' },
  ];
  servicesDropDown: any[] = [];
  // @ViewChild(MatSort) sort: MatSort;
  ELEMENT_DATA: Element[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  totalrecords: any;
  activeRequest: any;
  cancelledRequest: any;
  completedRequest: any;
  queryParams: any;
  serviceparams: any = [];
  searchparams: any;
  toDate: any;
  statusCompleted: any = 'completed';
  queryParamsDetails: any;
  userDetailsParams: any = '';
  queryParamsStatus: any;
  onLoadPage = new EventEmitter();
  statusparams: any;
  pageSize: any = 10;
  currentPage: any;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  pageLimit: any;
  pageNumber: any;
  statusMessage: any;
  serviceClear: any;
  statusClear: any;
  dateClear: any;
  dateClear1: any;
  searchClear: string;
  statusparamsArray: any = [];
  loaderShow: boolean;
  acceptedRequest: any;
  permissons: string;
  permissionsArray: any;
  exportDetailServiceReq: any;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    public service: DashboardServicesService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public loader: LoaderService
  ) {
    this.loader.loading.subscribe((res) => {
      this.loaderShow = res;
    });
  }
  @ViewChild(MatPaginator) paginator: any = MatPaginator;

  ngOnInit(): void {
    this.permissons = localStorage.getItem('permissons');

    this.permissionsArray = JSON.parse(this.permissons);
    // console.log('====================================');
    // console.log(this.permissionsArray);
    // console.log('====================================');
    this.getAllServiceRequest();
    this.spinner.show();

    this.getServices();

    setTimeout(() => {
      this.spinner.hide();
    }, 1500);
  }
  reset() {
    this.serviceClear = '';
    this.statusClear = '';
    this.dateClear = null;
    this.dateClear1 = null;
    this.searchClear = '';
    this.serviceparams = '';
    this.statusparams = '';
    this.calendraDate = '';
    this.toDate = '';
    this.searchparams = '';

    this.queryParams = {
      service: this.serviceparams ? this.serviceparams : '',
      status: this.statusparams ? this.statusparams : '',
      search: this.searchparams ? this.searchparams : '',
      fromDate: this.calendraDate ? this.calendraDate : '',
      toDate: this.toDate ? this.toDate : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.serviceRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.services;
      this.totalrecords = response.totalRecords;
      this.acceptedRequest = response.accepted;
      this.activeRequest = response.active;
      this.cancelledRequest = response.cancelled;
      this.completedRequest = response.completed;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }

  getAllServiceRequest() {
    this.queryParams = {
      service: this.serviceparams ? this.serviceparams : '',
      status: this.statusparams ? this.statusparams : '',
      search: this.searchparams ? this.searchparams : '',
      fromDate: this.calendraDate ? this.calendraDate : '',
      toDate: this.toDate ? this.toDate : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.serviceRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.services;
      this.totalrecords = response.totalRecords;
      this.acceptedRequest = response.accepted;
      this.activeRequest = response.active;
      this.cancelledRequest = response.cancelled;
      this.completedRequest = response.completed;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
    });
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    this.queryParams = {
      service: this.serviceparams ? this.serviceparams : '',
      status: this.statusparams ? this.statusparams : '',
      search: this.searchparams ? this.searchparams : '',
      fromDate: this.calendraDate ? this.calendraDate : '',
      toDate: this.toDate ? this.toDate : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    this.service.serviceRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.services;
      this.totalrecords = response.totalRecords;
      this.acceptedRequest = response.accepted;
      this.activeRequest = response.active;
      this.cancelledRequest = response.cancelled;
      this.completedRequest = response.completed;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  serviceDropDown(event: any) {
    if (event.value != '') {
      this.serviceparams = JSON.stringify(event.value);
    } else {
      this.serviceparams = '';
    }
    this.queryParams = {
      service: this.serviceparams ? this.serviceparams : '',
      status: this.statusparams ? this.statusparams : '',

      search: this.searchparams ? this.searchparams : '',
      fromDate: this.calendraDate ? this.calendraDate : '',
      toDate: this.toDate ? this.toDate : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.serviceRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.services;
      this.totalrecords = response.totalRecords;
      this.acceptedRequest = response.accepted;
      this.activeRequest = response.active;
      this.cancelledRequest = response.cancelled;
      this.completedRequest = response.completed;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  statusDropDown(event: any) {
    if (event.value != '') {
      this.statusparams = JSON.stringify(event.value);
    } else {
      this.statusparams = '';
    }
    this.queryParams = {
      service: this.serviceparams ? this.serviceparams : '',
      status: this.statusparams ? this.statusparams : '',
      search: this.searchparams ? this.searchparams : '',
      fromDate: this.calendraDate ? this.calendraDate : '',
      toDate: this.toDate ? this.toDate : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.serviceRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.services;
      this.totalrecords = response.totalRecords;
      this.acceptedRequest = response.accepted;
      this.activeRequest = response.active;
      this.cancelledRequest = response.cancelled;
      this.completedRequest = response.completed;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }

  searchDropDown(event: any) {
    this.searchparams = event.target.value;
    this.queryParams = {
      service: this.serviceparams ? this.serviceparams : '',
      status: this.statusparams ? this.statusparams : '',
      search: this.searchparams ? this.searchparams : '',
      fromDate: this.calendraDate ? this.calendraDate : '',
      toDate: this.toDate ? this.toDate : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.serviceRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.services;
      this.totalrecords = response.totalRecords;
      this.acceptedRequest = response.accepted;
      this.activeRequest = response.active;
      this.cancelledRequest = response.cancelled;
      this.completedRequest = response.completed;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  onChangeDate(e: any) {
    this.dropDownSelection = e.target.value;
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.queryParams = {
        service: this.serviceparams ? this.serviceparams : '',
        status: this.statusparams ? this.statusparams : '',
        search: this.searchparams ? this.searchparams : '',
        fromDate: this.calendraDate ? this.calendraDate : '',
        toDate: this.toDate ? this.toDate : '',
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage ? this.currentPage : '',
      };
      this.service.serviceRequest(this.queryParams).subscribe((response) => {
        this.ELEMENT_DATA = response.services;
        this.totalrecords = response.totalRecords;
        this.acceptedRequest = response.accepted;
        this.activeRequest = response.active;
        this.cancelledRequest = response.cancelled;
        this.completedRequest = response.completed;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      });
    }
  }
  onChangeToDate(e: any) {
    this.dropDownSelection = e.target.value;
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.queryParams = {
        service: this.serviceparams ? this.serviceparams : '',
        status: this.statusparams ? this.statusparams : '',
        search: this.searchparams ? this.searchparams : '',
        fromDate: this.calendraDate ? this.calendraDate : '',
        toDate: this.toDate ? this.toDate : '',
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage ? this.currentPage : '',
      };
      this.service.serviceRequest(this.queryParams).subscribe((response) => {
        this.ELEMENT_DATA = response.services;
        this.totalrecords = response.totalRecords;
        this.acceptedRequest = response.accepted;
        this.activeRequest = response.active;
        this.cancelledRequest = response.cancelled;
        this.completedRequest = response.completed;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      });
    }
  }
  displayedColumns: string[] = [
    'Requestno',
    'user',
    'Service',
    'Date',
    'Status',
    'Action',
  ];
  // dataSource = new MatTableDataSource(ELEMENT_DATA);
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  view() {
    this.router.navigate(['Servicerequests/viewRequest']);
  }
  Analytics() {
    this.router.navigate(['Servicerequests/Analytics']);
  }
  deActivateUser() {
    const dialogRef = this.dialog.open(DeclineDialogComponent, {
      disableClose: true,
    });
    const sub = dialogRef.componentInstance.onLoadPage.subscribe(() => {
      // this.getAllServiceRequest();
      this.reset()
    });
  }
  markEvent(event: any) {}
  mark(id: any) {
    this.markedClose = true;
    this.queryParamsStatus = {
      status: this.statusCompleted ? this.statusCompleted : '',
    };
    this.service
      .serviceRequestStatus(id, this.queryParamsStatus)
      .subscribe((response) => {
        this.statusMessage = response.message;
        this.dialog.open(MarkAsClosedcommonDialogComponent, {
          data: {
            message: this.statusMessage,
            disableClose: true,
          },
        });
        // this.getAllServiceRequest();
        this.reset()
        this.onLoadPage.emit();
      });
    setTimeout(() => {
      this.markedClose = false;
    }, 3000);
  }

  getServices(){
    this.service.serviceRequestFilter().subscribe((response) => {
      this.servicesDropDown = response.data.map((ser:any) => ser.title);
      // debugger
    });
  }
  download(url: any, downloadName: any) {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = downloadName;
    a.click();
    document.body.removeChild(a);
  }
  serviceReqDetailExport(){
    this.queryParams = {
      service: this.serviceparams ? this.serviceparams : '',
      status: this.statusparams ? this.statusparams : '',
      search: this.searchparams ? this.searchparams : '',
      fromDate: this.calendraDate ? this.calendraDate : '',
      toDate: this.toDate ? this.toDate : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service
    .exportDetailServiceRequest(this.queryParams)
    .subscribe((res:any) => {
      console.log(res);
      this.exportDetailServiceReq = res.sheetUrl;
      this.download(this.exportDetailServiceReq, 'sheetUrl');
    });
  }
}
