import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { DeactivateuserComponent } from './deactivateuser/deactivateuser.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';
import { DashboardServicesService } from '../_services/dashboard-services.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;
export interface PeriodicElement {
  user: string;
  Membershipid: any;
  Phoneno: any;
  Attendance: any;
  Date: any;
  Status: string;
  Lastvisited: any;
  Action: any;
  image: any;
}

// const ELEMENT_DATA: PeriodicElement[] = [
//   {
//     user: 'Kristin Watson',
//     Membershipid: '56CALVARY1234',
//     Phoneno: '+91-9876543210',
//     Attendance: '78%',
//     Status: 'Eligible',
//     Lastvisited: '13-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-1.svg',
//   },
//   {
//     user: 'Cody Fisher',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Attendance: '44%',
//     Status: 'Not Eligible',
//     Lastvisited: '18-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-2.svg',
//   },
//   {
//     user: 'Arlene McCoy',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Attendance: '90%',
//     Status: 'Eligible',
//     Lastvisited: '8-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-3.svg',
//   },
//   {
//     user: 'Floyd Miles',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Attendance: '78%',
//     Status: 'Not Eligible',
//     Lastvisited: '13-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-4.svg',
//   },
//   {
//     user: 'Darlene Robertson',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Attendance: '78%',
//     Status: 'Not Member',
//     Lastvisited: '13-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-5.svg',
//   },
//   {
//     user: 'Kristin Watson',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Attendance: '78%',
//     Status: 'Eligible',
//     Lastvisited: '13-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-6.svg',
//   },
//   {
//     user: 'Kristin Watson',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Attendance: '78%',
//     Status: 'Eligible',
//     Lastvisited: '13-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-7.svg',
//   },
//   {
//     user: 'Kristin Watson',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Attendance: '78%',
//     Status: 'Not Member',
//     Lastvisited: '13-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-8.svg',
//   },
//   {
//     user: 'Kristin Watson',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Attendance: '78%',
//     Status: 'Eligible',
//     Lastvisited: '13-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-9.svg',
//   },
// ];
@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.css'],
})
export class UserlistComponent implements OnInit {
  searchText: any;
  dropDownSelection: any;
  Date: any;
  calendraDate: any;
  today = new Date();
  Eligable = new FormControl('');
  Membership = new FormControl('');
  AttendanceControl = new FormControl('');
  MemberShipList: any = ['Member', 'Not Member'];

  EligableList: any = [
    // { key: 'Eligible', value: 'Eligible' },
    // { key: 'Not Eligible', value: 'Not Eligible' },
    { key: 'Member', value: 'Member' },
    { key: 'Not Member', value: 'Not Member' },
    { key: 'Temporary Member', value: 'Temp Member' },
  ];
  attendance: any[] = [
    '1-10%',
    '11-20%',
    '21-30%',
    '31-40%',
    '41-50%',
    '51-60%',
    '61-70%',
    '71-80%',
    '81-90%',
    '91-100%',
  ];
  totalrecords: any;
  male: any;
  female: any;
  loading: any = true;

  prayerRequest: any;
  percentageparams: any;
  queryParams: any;
  isLoading = false;
  totalRows: any;
  pageSize: any;
  currentPage: any;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  search: any;
  attendanceClear: string;
  eligbilityClear: string;
  searchClear: string;
  status: any;
  userStatus: any;
  MemberShipData: any;
  memberShipData: any;
  permissons: string;
  permissionsArray: any;
  exportMembers: any;
  queryparmsMembers: any;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private services: DashboardServicesService,
    private spinner: NgxSpinnerService
  ) {}
  ELEMENT_DATA: Element[] = [];
  prayerRequestUrl = environment.API_URL;
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: any = MatPaginator;

  ngOnInit(): void {
    this.permissons = localStorage.getItem('permissons');
    this.permissionsArray = JSON.parse(this.permissons);
    console.log(this.permissionsArray);
    this.getAllPrayerRequest();
    this.spinner.show();

    setTimeout(() => {
      this.spinner.hide();
    }, 1500);
  }
  displayedColumns: string[] = [
    'user',
    'Membershipid',
    'Phoneno',
    'Date',
    'Status',
    'Action',
  ];

  getAllPrayerRequest() {
    this.queryParams = {
      search: this.search ? this.search : '',
      status: this.userStatus ? this.userStatus : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.services.membersRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.data;
      this.loading = false;
      this.male = response.totalMale;
      this.female = response.totalFemale;
      this.totalrecords = response.totalRecords;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
    });
  }
  MemberShipDropDown(e: any) {
    console.log(e.value);
    this.MemberShipData = this.ELEMENT_DATA;
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);

    this.memberShipData = this.MemberShipData.filter((item: any) => {
      return item.isMembershipId === true;
    });
    console.log(this.memberShipData);
  }

  reset() {
    this.attendanceClear = '';
    this.eligbilityClear = '';
    this.searchClear = '';
    this.search = '';
    this.userStatus = '';
    this.queryParams = {
      search: this.search ? this.search : '',
      status: this.userStatus ? this.userStatus : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.services.membersRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.data;
      this.loading = false;
      this.male = response.totalMale;
      this.female = response.totalFemale;
      this.totalrecords = response.totalRecords;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      // this.dataSource.paginator = this.paginator;
    });
  }
  statusDropDown(event: any) {
    if (event.value != '') {
      this.userStatus = event.value;
    } else {
      this.userStatus = '';
    }

    this.queryParams = {
      search: this.search ? this.search : '',
      status: this.userStatus ? this.userStatus : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.services.membersRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.data;
      this.loading = false;
      this.male = response.totalMale;
      this.female = response.totalFemale;
      this.totalrecords = response.totalRecords;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
    });
  }
  searchDropDown(event: any) {
      this.search = event.target.value;
      this.queryParams = {
        search: this.search ? this.search : '',
        status: this.userStatus ? this.userStatus : '',
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage ? this.currentPage : '',
        
      };
      this.services.membersRequest(this.queryParams).subscribe((response) => {
        this.ELEMENT_DATA = response.data;
        console.log('====================================');
        console.log(response);
        console.log('====================================');
        this.male = response.totalMale;
        this.female = response.totalFemale;
        this.totalrecords = response.totalRecords;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        // this.dataSource.paginator = this.paginator;
       
      });
   
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;

    this.queryParams = {
      search: this.search ? this.search : '',
      status: this.userStatus ? this.userStatus : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    this.services.membersRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.data;
      this.male = response.totalMale;
      this.female = response.totalFemale;
      this.totalrecords = response.totalRecords;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  onChangeDate(e: any) {
    this.dropDownSelection = e.target.value;
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
  }
  view() {}
  Analytics() {
    this.router.navigate(['Userslist/Analytics']);
  }
  deActivateUser() {
    this.dialog.open(DeactivateuserComponent, {
      disableClose: true,
    });
  }
  download(url: any, downloadName: any) {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = downloadName;
    a.click();
    document.body.removeChild(a);
  }
  memberExport(){
    this.queryparmsMembers = {
      userStatus : this.userStatus ? this.userStatus : '',
      search : this.search ? this.search :''
    }
    this.services
    .dashboardExportsDetailMembers(this.queryparmsMembers)
    .subscribe((res) => {
      console.log(res);
      this.exportMembers = res.sheetUrl;
      this.download(this.exportMembers, 'sheetUrl');
    });
  }
}
