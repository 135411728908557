import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { DashboardServicesService } from 'src/app/_services/dashboard-services.service';
import { months } from '../../app.constant';

@Component({
  selector: 'app-donate-analystics',
  templateUrl: './donate-analystics.component.html',
  styleUrls: ['./donate-analystics.component.css'],
})
export class DonateAnalysticsComponent implements OnInit {
  today = new Date();
  dropDownSelection: any;
  calendraDate: any;
  Date: any;
  highcharts = Highcharts;
  chartOptions: any;
  chartOptionsAnalytics: any;
  exportDoantionData: any;
  DonationDataMembers: any;
  DonationDataMembersCharts: any;
  DonationDataOpenArray: any = [];
  DonationDataTotalUsers: any = [];
  DonationDataMembersMonths: any = [];
  donationsecondchartOptions: any;
  DonationDataQueryParms: any;
  DonationDataYear: any;
  toDate: any;


  // new------------
  monthArray = months;
  yearList: any = [
    '2023',
    '2022',
    '2021',
    '2020',
    '2019',
    '2018',
    '2017',
    '2016',
    '2015',
    '2014',
    '2013',
    '2012',
    '2011',
    '2010',
    '2009',
    '2008',
    '2007',
    '2006',
    '2005',
    '2004',
    '2003',
    '2002',
    '2001',
    '2000',
  ];
  // selectedYear: any = '2023';
  monthList = [
    { key: 'JAN', value: 0 },
    { key: 'FEB', value: 1 },
    { key: 'MAR', value: 2 },
    { key: 'APR', value: 3 },
    { key: 'MAY', value: 4 },
    { key: 'JUN', value: 5 },
    { key: 'JUL', value: 6 },
    { key: 'AUG', value: 7 },
    { key: 'SEP', value: 8 },
    { key: 'OCT', value: 9 },
    { key: 'NOV', value: 10 },
    { key: 'DEC', value: 11 },
  ];
  monthListDonation = this.monthList;
  selectedYearDonation: any = '2023';
  selectedMonthDonation: any;
  startDateDonation: Date = new Date(2023, 0, 1);
  endDateDonation: Date = new Date();
  donationchartOptions: any;
  completedDonationData: any;
  totalDonationData: any;
  activeDonationData: any;
  exportDonorsData: any;
  constructor(private services: DashboardServicesService) {

  }

  ngOnInit(): void {
    if (this.selectedYearDonation == new Date().getFullYear()) {
      let month = new Date().getMonth() + 1;
      console.log(month);

      this.monthListDonation = this.monthListDonation.slice(0, month);
      console.log('from if block', this.monthListDonation);
    } else {
      this.monthListDonation = [
        { key: 'JAN', value: 0 },
        { key: 'FEB', value: 1 },
        { key: 'MAR', value: 2 },
        { key: 'APR', value: 3 },
        { key: 'MAY', value: 4 },
        { key: 'JUN', value: 5 },
        { key: 'JUL', value: 6 },
        { key: 'AUG', value: 7 },
        { key: 'SEP', value: 8 },
        { key: 'OCT', value: 9 },
        { key: 'NOV', value: 10 },
        { key: 'DEC', value: 11 },
      ];
    }
    this.getDonationData();
    this.chartOptionsAnalytics = {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: { enabled: false },
      //  subtitle: {
      //     text: "Source: WorldClimate.com"
      //  },
      xAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineWidth: 0,
        lineColor: 'transparent',
        title: {
          text: '',
        },
        labels: {
          style: {
            color: '#8492A6',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
        categories: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        ],
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          text: '',
        },
        labels: {
          format: '{value}',
          style: {
            color: '#425466',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
      },
      //  tooltip: {
      //     valueSuffix:" °C"
      //  },
      plotOptions: {
        series: {
          lineWidth: 0.5,
          borderRadius: 5,
          pointWidth: 7,
          marker: {
            enabled: false,
          },
        },
      },

      colors: ['#0074D6', '#FD6A65', '#FFB65C', '#75809C'],
      series: [
        {
          data: [200, 300, 450, 750, 450, 350, 250, 760, 870, 970, 650, 540],
        },
        {
          data: [600, 500, 550, 650, 350, 250, 150, 650, 870, 650, 450, 870],
        },
        {
          data: [400, 560, 450, 550, 550, 650, 760, 760, 870, 890, 960, 650],
        },
        // {
        //   data: [700, 25, 30, 48, 55, 60, 65, 75, 89, 98, 190, 200],
        // },
        // {
        //   data: [900, 15, 20, 35, 45, 50, 55, 65, 70, 90, 100, 110],
        // },
      ],
    };
  }
  download(url: any, downloadName: any) {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = downloadName;
    a.click();
    document.body.removeChild(a);
  }
  donationDataExportBtn() {
    this.DonationDataQueryParms.type = 'amount'
    this.services.dashboardExportsDonationData(this.DonationDataQueryParms).subscribe((res) => {
      console.log(res);
      this.exportDoantionData = res.sheetUrl;
      this.download(this.exportDoantionData, 'sheetUrl');
    });
  }
  onChangeDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.DonationDataQueryParms = {
        startDate: this.calendraDate ? this.calendraDate : '',
        endDate: this.toDate ? this.toDate : '',
        year: this.DonationDataYear ? this.DonationDataYear : '2022',
      };
      this.services
        .dashboardDonation(this.DonationDataQueryParms)
        .subscribe((result) => {
          console.log(result);
          this.DonationDataMembers = result.message;
          this.DonationDataMembersCharts = this.DonationDataMembers.forEach(
            (item: any) => {
              this.DonationDataOpenArray.push(item.amount);
              this.DonationDataTotalUsers.push(item.totalUser);
              this.DonationDataMembersMonths.push(item.month);
            }
          );

          this.donationsecondchartOptions = {
            chart: {
              type: 'spline',
            },
            title: {
              text: '',
            },
            legend: {
              enabled: false,
            },
            credits: {
              enabled: false,
            },
            xAxis: {
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              lineWidth: 0,
              lineColor: 'transparent',
              title: {
                text: '',
              },
              labels: {
                style: {
                  color: '#5A657F',
                  fontSize: 12,
                  fontWeight: 600,
                  fontFamily: 'Inter',
                },
              },
              categories: this.DonationDataMembersMonths,
            },
            yAxis: {
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              title: {
                text: '',
              },
              labels: {
                format: '{value}',
                style: {
                  color: '#425466',
                  fontSize: 12,
                  fontWeight: 600,
                  fontFamily: 'Inter',
                },
              },
            },
            plotOptions: {
              series: {
                marker: {
                  enabled: false,
                },
              },
            },
            colors: ['#0DBC67'],
            series: [
              {
                name:'User :', // Specify the name for the graph line here
                data: this.DonationDataTotalUsers,
              },
            ],
          };
          
          
        });
    }
  }
  onChangeToDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.DonationDataQueryParms = {
        startDate: this.calendraDate ? this.calendraDate : '',
        endDate: this.toDate ? this.toDate : '',
        year: this.DonationDataYear ? this.DonationDataYear : '2022',
      };
      this.services
        .dashboardDonation(this.DonationDataQueryParms)
        .subscribe((result) => {
          console.log(result);
          this.DonationDataMembers = result.message;
          this.DonationDataMembersCharts = this.DonationDataMembers.forEach(
            (item: any) => {
              this.DonationDataOpenArray.push(item.amount);
              this.DonationDataTotalUsers.push(item.totalUser);
              this.DonationDataMembersMonths.push(item.month);
            }
          );

          this.donationsecondchartOptions = {
            chart: {
              type: 'spline',
            },
            title: {
              text: '',
            },
            legend: { enabled: false },

            credits: {
              enabled: false,
            },
            //  subtitle: {
            //     text: "Source: WorldClimate.com"
            //  },
            xAxis: {
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              lineWidth: 0,
              lineColor: 'transparent',
              title: {
                text: '',
              },
              labels: {
                style: {
                  color: '#5A657F',
                  fontSize: 12,
                  fontWeight: 600,
                  fontFamily: 'Inter',
                },
              },
              categories: this.DonationDataMembersMonths,
            },
            yAxis: {
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              title: {
                text: '',
              },
              labels: {
                format: '{value}',
                style: {
                  color: '#425466',
                  fontSize: 12,
                  fontWeight: 600,
                  fontFamily: 'Inter',
                },
              },
            },
            //  tooltip: {
            //     valueSuffix:" °C"
            //  },
            plotOptions: {
              series: {
                marker: {
                  enabled: false,
                },
              },
            },

            colors: ['#0DBC67'],
            series: [
              {
                name:'User :',
                data: this.DonationDataTotalUsers,
              },
            ],
          };
        });
    }
  }

  StatisticsDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
  }
  AnalyticsDateChange(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
  }

  // new=======

  getDonationData() {
    this.DonationDataOpenArray = [];
    this.DonationDataTotalUsers = [];
    this.DonationDataMembersMonths = [];
    this.DonationDataMembers = [];
    this.DonationDataMembersCharts = [];

    this.DonationDataQueryParms = {
      startDate:
        this.startDateDonation.getFullYear() +
        '-' +
        (this.startDateDonation.getMonth() + 1) +
        '-' +
        this.startDateDonation.getDate(),
      endDate:
        this.endDateDonation.getFullYear() +
        '-' +
        (this.endDateDonation.getMonth() + 1) +
        '-' +
        this.endDateDonation.getDate(),
    };

    if (this.selectedMonthDonation != undefined) {
      this.DonationDataQueryParms['groupBy'] = 'day';
    }

    if (

      this.startDateDonation.getFullYear() == this.endDateDonation.getFullYear() &&
      this.startDateDonation.getMonth() == this.endDateDonation.getMonth() &&
      this.startDateDonation.getDate() != this.endDateDonation.getDate()

    ) {

      this.DonationDataQueryParms['groupBy'] = 'day';
    }
    if (
      this.startDateDonation.getFullYear() == this.endDateDonation.getFullYear() &&
      this.startDateDonation.getMonth() == this.endDateDonation.getMonth() &&
      this.startDateDonation.getDate() == this.endDateDonation.getDate()

    ) {
      this.DonationDataQueryParms['groupBy'] = 'hour';
    }


    this.services
      .dashboardDonation(this.DonationDataQueryParms)
      .subscribe((result) => {
        this.DonationDataMembers = result.data;
        this.completedDonationData = result.totalRecords[0]?.totalDonors;
        this.totalDonationData = result.totalRecords[0]?.totalDonations;
        this.activeDonationData = result.totalRecords[0]?.donations;
        this.DonationDataMembersCharts = this.DonationDataMembers.forEach(
          (item: any) => {
            this.DonationDataOpenArray.push(item.amount);
            this.DonationDataTotalUsers.push(item.totalUser);
            const day = item.date?.split('-')
            const dateText = item.day ? item.day + '-' : '';
            // console.log('item' , item);

            // const dateText = item.day ? item.day + '-' : '';
            if (item.date != undefined && item.hour == undefined) {
              this.DonationDataMembersMonths.push(day[2] + '-' + item.month)
            } else if (item.date != undefined && item.hour != undefined) {
              this.DonationDataMembersMonths.push(item.hour + 1 + ':00')
            } else {
              this.DonationDataMembersMonths.push(dateText + item.month + '-' + item.year);
            }
            // this.DonationDataMembersMonths.push(
            //   dateText + item.month + '-' + item.year
            // );
          }
        );

        this.donationchartOptions = {
          chart: {
            type: 'spline',
          },
          title: {
            text: '',
          },
          legend: { enabled: false },

          credits: {
            enabled: false,
          },
          //  subtitle: {
          //     text: "Source: WorldClimate.com"
          //  },
          xAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0,
            lineColor: 'transparent',
            title: {
              text: '',
            },
            labels: {
              style: {
                color: '#5A657F',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
            categories: this.DonationDataMembersMonths,
          },
          yAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            title: {
              text: '',
            },
            labels: {
              format: '{value}',
              style: {
                color: '#425466',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
          },
          //  tooltip: {
          //     valueSuffix:" °C"
          //  },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
              },
            },
          },

          colors: ['#0DBC67'],
          series: [
            {
              name:'Amount ',
              data: this.DonationDataOpenArray,
            },
          ],
        };
        this.donationsecondchartOptions = {
          chart: {
            type: 'spline',
          },
          title: {
            text: '',
          },
          legend: { enabled: false },

          credits: {
            enabled: false,
          },
          //  subtitle: {
          //     text: "Source: WorldClimate.com"
          //  },
          xAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0,
            lineColor: 'transparent',
            title: {
              text: '',
            },
            labels: {
              style: {
                color: '#5A657F',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
            categories: this.DonationDataMembersMonths,
          },
          yAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            title: {
              text: '',
            },
            labels: {
              format: '{value}',
              style: {
                color: '#425466',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
          },
          //  tooltip: {
          //     valueSuffix:" °C"
          //  },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
              },
            },
          },

          colors: ['#0DBC67'],
          series: [
            {
              name:'User ',
              data: this.DonationDataTotalUsers,
            },
          ],
        };
      });
  }
  resetDonationFilter() {
    this.selectedYearDonation = '2023';
    this.selectedMonthDonation = undefined;
    this.startDateDonation = new Date(2023, 0, 1);
    this.endDateDonation = new Date();
    this.getDonationData();
  }

  selectYearDonation(event: any) {
    if (this.selectedYearDonation == new Date().getFullYear()) {
      let month = new Date().getMonth() + 1;
      this.monthListDonation = this.monthListDonation.slice(0, month);
      console.log('ddfdf', this.monthList);

    } else {
      this.monthListDonation = [
        { key: 'JAN', value: 0 },
        { key: 'FEB', value: 1 },
        { key: 'MAR', value: 2 },
        { key: 'APR', value: 3 },
        { key: 'MAY', value: 4 },
        { key: 'JUN', value: 5 },
        { key: 'JUL', value: 6 },
        { key: 'AUG', value: 7 },
        { key: 'SEP', value: 8 },
        { key: 'OCT', value: 9 },
        { key: 'NOV', value: 10 },
        { key: 'DEC', value: 11 },
      ];
    }
    // this.startDateDonation = new Date(Number(this.selectedYearDonation), 0, 1);
    // this.endDateDonation = new Date(Number(this.selectedYearDonation), 11, 31);
    //prev
    //  this.startDateMember = this.datePipe.transform(this.startDateMember,'dd/MM/yyyy')
    this.DonationDataQueryParms = {
      startDate:
        this.startDateDonation.getFullYear() +
        '-' +
        (this.startDateDonation.getMonth() + 1) +
        '-' +
        this.startDateDonation.getDate(),
      endDate:
        this.endDateDonation.getFullYear() +
        '-' +
        (this.endDateDonation.getMonth() + 1) +
        '-' +
        this.endDateDonation.getDate(),
    };
    if (this.selectedMonthDonation == undefined && this.selectedYearDonation != new Date().getFullYear()) {
      this.startDateDonation = new Date(Number(this.selectedYearDonation), 0, 1);
      this.endDateDonation = new Date(Number(this.selectedYearDonation), 11, 31);
    } else if (this.selectedMonthDonation == undefined && this.selectedYearDonation == new Date().getFullYear()) {
      this.startDateDonation = new Date(Number(this.selectedYearDonation), 0, 1);
      this.endDateDonation = new Date();
    } else {
      this.startDateDonation = new Date(Number(this.selectedYearDonation), this.selectedMonthDonation, 1);
      this.endDateDonation = new Date(Number(this.selectedYearDonation), this.selectedMonthDonation, this.monthArray[this.selectedMonthDonation]);
    }

    this.getDonationData();
  }

  selectMonthDonation(month: any) {
    this.startDateDonation = new Date(
      Number(this.selectedYearDonation),
      this.selectedMonthDonation,
      1
    );
    this.endDateDonation = new Date(
      Number(this.selectedYearDonation),
      this.selectedMonthDonation,
      this.monthArray[this.selectedMonthDonation]
    );

    this.getDonationData();
  }
  onChangeDonationsDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    this.startDateDonation = new Date(e.target.value);
    this.endDateDonation = new Date(e.target.value);
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getDonationData();
    }
  }
  onChangeDonationsToDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    this.endDateDonation = new Date(e.target.value);
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getDonationData();
    }
  }
  DonorsDataExportBtn() {
    this.DonationDataQueryParms.type = 'user'
    this.services.dashboardExportsDonationData(this.DonationDataQueryParms).subscribe((res) => {
      console.log(res);
      this.exportDonorsData = res.sheetUrl;
      this.download(this.exportDonorsData, 'sheetUrl');
    });
  }
}
