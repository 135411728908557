import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardServicesService } from 'src/app/_services/dashboard-services.service';
import { SuccesupdateDialogComponent } from '../succesupdate-dialog/succesupdate-dialog.component';
const emailPhonePattern =
  '^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})|(^[0-9]{10})+$';
const phonePattern =
  '^[1-9][0-9]{9}$';

@Component({
  selector: 'app-viewuser',
  templateUrl: './viewuser.component.html',
  styleUrls: ['./viewuser.component.css'],
})
export class ViewuserComponent implements OnInit {
  createSuccesfully: boolean = false;
  queryParamsDetails: any;
  userDetailsIdParams: string;
  profile: any;
  managementOption: any = [
    { value: 'view', key: 'View', resp: false },
    { value: 'edit', key: 'Edit', resp: false },
    { value: 'view analytics', key: 'View analytics', resp: false },
  ];
  createUserManagment: FormGroup;
  subRubrosSelec: any[];
  memberslistPermission: any = [];
  itSupportForm: any;
  submitted: boolean = false;
  url: any = '../../assets/images/panel_images/proflie-o.svg';
  selectedFile: File;
  updateProfileForm: any;
  @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>;
  firstName: any;
  lastName: any;
  phone: any;
  email: any;
  role: any;
  memberList: any;
  memberListChecked: any;
  ServiceChecked: any;
  prayerChecked: any;
  donationChecked: any;
  tvProgramChecked: any;
  supportChecked: any;
  cmsModelChecked: any;
  spiritualFitnessChecked:any;
  memberslistView: any;
  memberslistEdit: any;
  memberslistanalyse: any;
  apiData: any;
  serviceView: any;
  serviceEdit: any;
  serviceanalyse: any;
  members_List: any = {};
  donationView: any;
  donationEdit: any;
  donationanalyse: any;
  tvProgramEdit: any;
  tvProgramanalyse: any;
  tvProgramView: any;
  prayerView: any;
  prayerEdit: any;
  prayeranalyse: any;
  supportView: any;
  supportEdit: any;
  supportanalyse: any;
  cmsView: any;
  cmsEdit: any;
  spiritualFitnessView:any;
  cmsanalyse: any;
  constructor(
    private service: DashboardServicesService,
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {
    this.createUserManagment = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['',[Validators.required]],
      role: ['', [Validators.required]],
      phone: ['' ,[Validators.required,Validators.pattern(phonePattern)] ],
      email: ['', [Validators.required, Validators.pattern(emailPhonePattern)]],
      prayerRequest: this.fb.array([]),
      memberList: this.fb.array([]),
      serviceRequest: this.fb.array([]),
      support: this.fb.array([]),
      donation: this.fb.array([]),
      cmsModule: this.fb.array([]),
      spiritual_Fitness: this.fb.array([]),
      tvProgram: this.fb.array([]),
      file: [''],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.queryParamsDetails = { ...params.keys, ...params };
      this.userDetailsIdParams = params.get('_id');
      console.log(this.userDetailsIdParams);
    });
    this.service
      .userMangmentAdminDetails(this.userDetailsIdParams)
      .subscribe((res) => {
        console.log(res);
        this.profile = res.message.profile;
        this.firstName = res.message.firstName;
        this.lastName = res.message.lastName;
        this.phone = res.message.phone;
        this.email = res.message.email;
        this.role = res.message.role;
        this.memberListChecked = res.message.permissions.members_List;
        this.ServiceChecked = res.message.permissions.service_Requests;
        this.prayerChecked = res.message.permissions.prayer_Requests;
        this.donationChecked = res.message.permissions.Donation;
        this.tvProgramChecked = res.message.permissions.tv_programs;
        this.supportChecked = res.message.permissions.support;
        this.cmsModelChecked = res.message.permissions.cms_Model;
        this.spiritualFitnessChecked = res.message.permissions.spiritual_Fitness;
        this.memberslistView = res.message.permissions.members_List.view;
        this.memberslistEdit = res.message.permissions.members_List.edit;
        this.memberslistanalyse = res.message.permissions.members_List.analyse;
        this.serviceView = res.message.permissions.service_Requests.view;
        this.serviceEdit = res.message.permissions.service_Requests.edit;
        this.serviceanalyse = res.message.permissions.service_Requests.analyse;
        this.prayerView = res.message.permissions.prayer_Requests.view;
        this.prayerEdit = res.message.permissions.prayer_Requests.edit;
        this.prayeranalyse = res.message.permissions.prayer_Requests.analyse;
        this.donationView = res.message.permissions.Donation.view;
        this.donationEdit = res.message.permissions.Donation.edit;
        this.donationanalyse = res.message.permissions.Donation.analyse;
        this.tvProgramView = res.message.permissions.tv_programs.view;
        this.tvProgramEdit = res.message.permissions.tv_programs.edit;
        this.tvProgramanalyse = res.message.permissions.tv_programs.analyse;
        this.supportView = res.message.permissions.support.view;
        this.supportEdit = res.message.permissions.support.edit;
        this.supportanalyse = res.message.permissions.support.analyse;
        this.cmsView = res.message.permissions.cms_Model.view;
        this.cmsEdit = res.message.permissions.cms_Model.edit;
        this.spiritualFitnessView = res.message.permissions.spiritual_Fitness.view;
        this.cmsanalyse = res.message.permissions.cms_Model.analyse;
        this.createUserManagment.get('firstName').setValue(this.firstName);
        this.createUserManagment.get('lastName').setValue(this.lastName);
        this.createUserManagment.get('phone').setValue(this.phone);
        this.createUserManagment.get('email').setValue(this.email);
        this.createUserManagment.get('role').setValue(this.role);
        // this.createUserManagment.get('memberList').setValue(this.memberListChecked.view);
        // this.createUserManagment
        //   .get('memberList')
        //   .setValue(this.memberListChecked.edit);
        // this.createUserManagment
        //   .get('memberList')
        //   .setValue(this.memberListChecked.analyse);
      });
  }
  createUser() {
    if (!this.spiritualFitnessView
      && !this.memberslistView
      && !this.memberslistEdit
      && !this.memberslistanalyse
      && !this.supportView
      && !this.supportEdit
      && !this.supportanalyse
      && !this.tvProgramView
      && !this.tvProgramanalyse
      && !this.donationView
      && !this.donationanalyse
      && !this.prayerView
      && !this.prayerEdit
      && !this.prayeranalyse
      && !this.serviceView
      && !this.serviceEdit
      && !this.serviceanalyse
      && !this.cmsView
      && !this.cmsEdit) {
      this.submitted = true;
      console.log(this.createUserManagment);
    } else if(this.createUserManagment.invalid) {
      this.submitted = true;
    } else {
      this.submitted = false;
      let obj: any = {
        members_List: {
          view: this.memberslistView ? true : false,
          edit: this.memberslistEdit ? true : false,
          analyse: this.memberslistanalyse ? true : false,
        },
        service_Requests: {
          view: this.serviceView ? true : false,
          edit: this.serviceEdit ? true : false,
          analyse: this.serviceanalyse ? true : false,
        },
        prayer_Requests: {
          view: this.prayerView ? true : false,
          edit: this.prayerEdit ? true : false,
          analyse: this.prayeranalyse ? true : false,
        },
        Donation: {
          view: this.donationView ? true : false,
          edit: this.donationEdit ? true : false,
          analyse: this.donationanalyse ? true : false,
        },
        tv_programs: {
          view: this.tvProgramView ? true : false,
          edit: this.tvProgramEdit ? true : false,
          analyse: this.tvProgramanalyse ? true : false,
        },
        support: {
          view: this.supportView ? true : false,
          edit: this.supportEdit ? true : false,
          analyse: this.supportanalyse ? true : false,
        },
        cms_Model: {
          view: this.cmsView ? true : false,
          edit: this.cmsEdit ? true : false,
          analyse: this.cmsanalyse ? true : false,
        },
        spiritual_Fitness: {
          view: this.spiritualFitnessView ? true : false,

        },
      };
      const formData = new FormData();
      formData.append('file', this.createUserManagment.get('file').value);
      // formData.append('firstName', this.createUserManagment.value.firstName);
      formData.append('firstName', this.createUserManagment.value.firstName);
      formData.append('lastName', this.createUserManagment.value.lastName);
      // formData.append('email', this.createUserManagment.value.email);
      formData.append('phone', this.createUserManagment.value.phone);
      formData.append('role', this.createUserManagment.value.role);
      formData.append('permissions', JSON.stringify(obj));

      this.service
        .userMangmentAdminUpdateAddMember(formData, this.userDetailsIdParams)
        .subscribe((res) => {
          console.log(res);
          this.dialog.open(SuccesupdateDialogComponent, {
            disableClose: true,
          });
          this.router.navigate(['Internal-Team']);

          this.createUserManagment.reset();
          this.checkboxes.forEach((element) => {
            element.nativeElement.checked = false;
          });
        });
    }
  }
  create() {
    this.createSuccesfully = true;
    setTimeout(() => {
      this.createSuccesfully = false;
    }, 4000);
  }

  onFileSelected(event: any): void {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      // this.msg = '';
      this.profile = reader.result as string;
      this.selectedFile = <File>event.target.files[0];
    };
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.createUserManagment.get('file').setValue(file);
      console.log(this.createUserManagment.get('file').value);
    }
    //  this.updateProfileForm.get('file').setValue(file);
  }
  onChange(event: any): void {
    if (event.target.value == 'View') {
      this.memberslistView = event.currentTarget.checked;
    } else if (event.target.value == 'Edit') {
      this.memberslistEdit = event.currentTarget.checked;
    } else {
      this.memberslistanalyse = event.currentTarget.checked;
    }
  }
  onChangeService(event: any): void {
    if (event.target.value == 'View') {
      this.serviceView = event.currentTarget.checked;
    } else if (event.target.value == 'Edit') {
      this.serviceEdit = event.currentTarget.checked;
    } else {
      this.serviceanalyse = event.currentTarget.checked;
    }
  }
  onChangeDonations(event: any): void {
    if (event.target.value == 'View') {
      this.donationView = event.currentTarget.checked;
    } else if (event.target.value == 'Edit') {
      this.donationEdit = event.currentTarget.checked;
    } else {
      this.donationanalyse = event.currentTarget.checked;
    }
  }
  onChangeTvPrograms(event: any): void {
    if (event.target.value == 'View') {
      this.tvProgramView = event.currentTarget.checked;
    } else if (event.target.value == 'Edit') {
      this.tvProgramEdit = event.currentTarget.checked;
    } else {
      this.tvProgramanalyse = event.currentTarget.checked;
    }
  }
  onChangePrayer(event: any): void {
    if (event.target.value == 'View') {
      this.prayerView = event.currentTarget.checked;
    } else if (event.target.value == 'Edit') {
      this.prayerEdit = event.currentTarget.checked;
    } else {
      this.prayeranalyse = event.currentTarget.checked;
    }
  }
  onChangeSupport(event: any): void {
    if (event.target.value == 'View') {
      this.supportView = event.currentTarget.checked;
    } else if (event.target.value == 'Edit') {
      this.supportEdit = event.currentTarget.checked;
    } else {
      this.supportanalyse = event.currentTarget.checked;
    }
  }
  onChangeCms(event: any): void {
    if (event.target.value == 'View') {
      this.cmsView = event.currentTarget.checked;
    } else if (event.target.value == 'Edit') {
      this.cmsEdit = event.currentTarget.checked;
    } else {
      this.cmsanalyse = event.currentTarget.checked;
    }
  }
  onChangeSpiritualFitness(event: any): void {
    if (event.target.value == 'View') {
      this.spiritualFitnessView = event.currentTarget.checked;
    }
    console.log(event.currentTarget.checked);

  }
  get userManagementControls(): { [key: string]: AbstractControl } {
    return this.createUserManagment.controls;
  }
  // validations for numberOnly
  numberOnly(event: any): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
  // validations for numberOnly
  alphabetOnly(event: any): boolean {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}
