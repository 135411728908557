import { flatten } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DashboardServicesService } from 'src/app/_services/dashboard-services.service';
import { RejectedApprovedDialogComponent } from '../rejected-approved-dialog/rejected-approved-dialog.component';
import { SuccessApprovedDialogComponent } from '../success-approved-dialog/success-approved-dialog.component';

@Component({
  selector: 'app-reviewpushnotifications',
  templateUrl: './reviewpushnotifications.component.html',
  styleUrls: ['./reviewpushnotifications.component.css'],
})
export class ReviewpushnotificationsComponent implements OnInit {
  review: any;
  reviewPush: FormGroup;
  status: any = 'approved';
  rejectstatus: any = 'rejected';
  statusParams: {};
  Loader: boolean = false;
  declineParams: { status: any };
  role:any = 'superAdmin';

  today:Date = new Date();
  constructor(
    private services: DashboardServicesService,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {
    this.reviewPush = this.fb.group({
      createdby: [''],
      Device: [''],
      Title: [''],
      Description: [''],
      trigger: [''],
      CreatedDate: [''],
    });
  }

  ngOnInit(): void {
    this.role = localStorage.getItem('ROLE');
    this.services.cmsModulePushNotificationsReview().subscribe((res) => {
      console.log(res);
      this.review = res.data;
    });
  }
  approve(id: any, i: any) {
    this.statusParams = {
      status: this.status,
    };
    this.Loader = true;
    this.services.superAdminAPProval(id, this.statusParams).subscribe((res) => {
      setTimeout(() => {
        this.Loader = false;
        this.review.splice(i, 1);
        this.dialog.open(SuccessApprovedDialogComponent, {
          disableClose: true,
        });
      }, 1000);
    });

    // console.log('id', id, 'status', this.statusParams);
  }
  decline(id: any, i: any) {
    this.declineParams = {
      status: this.rejectstatus,
    };
    this.Loader = true;

    this.services
      .superAdminAPProval(id, this.declineParams)
      .subscribe((res) => {
        this.review.splice(i, 1);
        setTimeout(() => {
          this.Loader = false;
          this.dialog.open(RejectedApprovedDialogComponent, {
            disableClose: true,
          });
        }, 1000);
      });

    //   console.log('id', id, 'status', this.declineParams);
  }

  getTime(time:any){
    return new Date(time);
  }
}
