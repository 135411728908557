import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: 'button[appNewPasswordToggle]',
  exportAs: 'appNewPasswordToggle',
})
export class NewPasswordToggleDirective {
  constructor() {}

  public get type(): string {
    return this.showNewPassword ? 'text' : 'password';
  }

  public get icon(): string {
    return this.showNewPassword ? 'visibility_off' : 'visibility';
  }

  private showNewPassword: boolean = false;

  @HostListener('click')
  public onClicknew(): void {
    this.showNewPassword = !this.showNewPassword;
  }
}
