import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as Highcharts from 'highcharts';
import { DashboardServicesService } from 'src/app/_services/dashboard-services.service';
import { months } from '../../app.constant';
@Component({
  selector: 'app-prayer-analytics',
  templateUrl: './prayer-analytics.component.html',
  styleUrls: ['./prayer-analytics.component.css'],
})
export class PrayerAnalyticsComponent implements OnInit {
  today = new Date();
  dropDownSelection: any;
  calendraDate: any;
  Date: any;
  highcharts = Highcharts;
  chartOptions: any;
  chartOptionsAnalytics: any;
  prayerReqQueryParms: any;
  prayerReqYear: any;
  prayerMembers: any;
  PrayerRequestMembersCharts: any;
  prayerReqClosedArray: any = [];
  prayerReqMonths: any = [];
  prayerReqOpenArray: any = [];
  prayerRequestchartOptions: any;
  exportPrayerReq: any;
  toDate: string;
  DonationDataYear: any;


  yearList:any = [
    '2023',
    '2022',
    '2021',
    '2020',
    '2019',
    '2018',
    '2017',
    '2016',
    '2015',
    '2014',
    '2013',
    '2012',
    '2011',
    '2010',
    '2009',
    '2008',
    '2007',
    '2006',
    '2005',
    '2004',
    '2003',
    '2002',
    '2001',
    '2000'
]
// selectedYear:any = '2023';
monthArray = months;
monthList = [
    { key: "JAN", value : 0},
    { key: "FEB", value : 1},
    { key: "MAR", value : 2},
    { key: "APR", value : 3},
    { key: "MAY", value : 4},
    { key: "JUN", value : 5},
    { key: "JUL", value : 6},
    { key: "AUG", value : 7},
    { key: "SEP", value : 8},
    { key: "OCT", value : 9},
    { key: "NOV", value : 10},
    { key: "DEC", value : 11}
]
  monthListPrayer = this.monthList;
  selectedYearPrayer:any = '2023';
  selectedMonthPrayer:any;
  startDatePrayer:Date =new Date(2023,0,1);
  endDatePrayer:Date = new Date();
  totalPrayerRequest: any;
  activePrayerRequest: any;
  completedPrayerRequest: any;
  cancelledPrayerRequest: any;
  prayerRequestchartOptionsAnalytics: {
    chart: { type: string; }; title: { text: string; }; credits: { enabled: boolean; }; legend: { enabled: boolean; };
    //  subtitle: {
    //     text: "Source: WorldClimate.com"
    //  },
    xAxis: { gridLineWidth: number; minorGridLineWidth: number; lineWidth: number; lineColor: string; title: { text: string; }; labels: { style: { color: string; fontSize: number; fontWeight: number; fontFamily: string; }; }; categories: number[]; }; yAxis: { gridLineWidth: number; minorGridLineWidth: number; title: { text: string; }; labels: { format: string; style: { color: string; fontSize: number; fontWeight: number; fontFamily: string; }; }; };
    //  tooltip: {
    //     valueSuffix:" °C"
    //  },
    plotOptions: { series: { lineWidth: number; borderRadius: number; pointWidth: number; marker: { enabled: boolean; }; }; }; colors: string[]; series: { data: number[]; }[];
  };

  
  constructor(private services: DashboardServicesService) {
    
   this.prayerRequestchartOptionsAnalytics = {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: { enabled: false },
      //  subtitle: {
      //     text: "Source: WorldClimate.com"
      //  },
      xAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineWidth: 0,
        lineColor: 'transparent',
        title: {
          text: '',
        },
        labels: {
          style: {
            color: '#8492A6',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
        categories: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        ],
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          text: '',
        },
        labels: {
          format: '{value}',
          style: {
            color: '#425466',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
      },
      //  tooltip: {
      //     valueSuffix:" °C"
      //  },
      plotOptions: {
        series: {
          lineWidth: 1,
          borderRadius: 5,
          pointWidth: 7,
          marker: {
            enabled: false,
          },
        },
      },

      colors: ['#FD6A65', '#0DBC67', '#5A657F', '#FD6A65'],
      series: [
        {
          data: [200, 300, 450, 750, 450, 350, 250, 760, 870, 970, 650, 540],
        },
        {
          data: [600, 500, 550, 650, 350, 250, 150, 650, 870, 650, 450, 870],
        },
        {
          data: [400, 560, 450, 550, 550, 650, 760, 760, 870, 890, 960, 650],
        },
        // {
        //   data: [700, 25, 30, 48, 55, 60, 65, 75, 89, 98, 190, 200],
        // },
        // {
        //   data: [900, 15, 20, 35, 45, 50, 55, 65, 70, 90, 100, 110],
        // },
      ],
    };
  }

  ngOnInit(): void {
    if(this.selectedYearPrayer == new Date().getFullYear()){
      let month = new Date().getMonth() + 1;
      
      this.monthListPrayer = this.monthListPrayer.slice(0,month);
  }else{
      this.monthListPrayer = [
       { key: "JAN", value : 0},
       { key: "FEB", value : 1},
       { key: "MAR", value : 2},
       { key: "APR", value : 3},
       { key: "MAY", value : 4},
       { key: "JUN", value : 5},
       { key: "JUL", value : 6},
       { key: "AUG", value : 7},
       { key: "SEP", value : 8},
       { key: "OCT", value : 9},
       { key: "NOV", value : 10},
       { key: "DEC", value : 11}
      ]
    }
   this.getPrayerData();
    this.chartOptionsAnalytics = {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: { enabled: false },
      //  subtitle: {
      //     text: "Source: WorldClimate.com"
      //  },
      xAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineWidth: 0,
        lineColor: 'transparent',
        title: {
          text: '',
        },
        labels: {
          style: {
            color: '#8492A6',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
        categories: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        ],
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          text: '',
        },
        labels: {
          format: '{value}',
          style: {
            color: '#425466',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
      },
      //  tooltip: {
      //     valueSuffix:" °C"
      //  },
      plotOptions: {
        series: {
          lineWidth: 0.5,
          borderRadius: 5,
          pointWidth: 7,
          marker: {
            enabled: false,
          },
        },
      },

      colors: ['#0074D6', '#FD6A65', '#FFB65C', '#75809C'],
      series: [
        {
          data: [200, 300, 450, 750, 450, 350, 250, 760, 870, 970, 650, 540],
        },
        {
          data: [600, 500, 550, 650, 350, 250, 150, 650, 870, 650, 450, 870],
        },
        {
          data: [400, 560, 450, 550, 550, 650, 760, 760, 870, 890, 960, 650],
        },
        // {
        //   data: [700, 25, 30, 48, 55, 60, 65, 75, 89, 98, 190, 200],
        // },
        // {
        //   data: [900, 15, 20, 35, 45, 50, 55, 65, 70, 90, 100, 110],
        // },
      ],
    };
  }
  onChangeDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.prayerReqQueryParms = {
        startDate: this.calendraDate ? this.calendraDate : '',
        endDate: this.toDate ? this.toDate : '',
        year: this.DonationDataYear ? this.DonationDataYear : '2023',
      };
      this.services
        .dashboardPrayer(this.prayerReqQueryParms)
        .subscribe((result) => {
          console.log(result);
          this.prayerMembers = result.message;
          this.PrayerRequestMembersCharts = this.prayerMembers.forEach(
            (item: any) => {
              this.prayerReqOpenArray.push(item.open);
              this.prayerReqClosedArray.push(item.closed);
              this.prayerReqMonths.push(item.month);
              console.log('====================================');
              console.log('from item' , this.prayerReqMonths);
              console.log('====================================');
            }
          );
          this.prayerRequestchartOptions = {
            chart: {
              type: 'spline',
            },
            title: {
              text: '',
            },
            legend: { enabled: false },

            credits: {
              enabled: false,
            },
            //  subtitle: {
            //     text: "Source: WorldClimate.com"
            //  },
            xAxis: {
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              lineWidth: 0,
              lineColor: 'transparent',
              title: {
                text: '',
              },
              labels: {
                style: {
                  color: '#5A657F',
                  fontSize: 12,
                  fontWeight: 600,
                  fontFamily: 'Inter',
                },
              },
              categories: this.prayerReqMonths,
            },
            yAxis: {
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              title: {
                text: '',
              },
              labels: {
                format: '{value}',
                style: {
                  color: '#425466',
                  fontSize: 12,
                  fontWeight: 600,
                  fontFamily: 'Inter',
                },
              },
            },
            //  tooltip: {
            //     valueSuffix:" °C"
            //  },
            plotOptions: {
              series: {
                marker: {
                  enabled: false,
                },
              },
            },

            colors: ['#0DBC67', '#FF4242'],
            series: [
              {
                name: 'Open',
                data: this.prayerReqOpenArray,
              },
              {
                name: 'Closed',
                data: this.prayerReqClosedArray,
              },
            ],
          };
        });
    }
  }
  onChangeToDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.prayerReqQueryParms = {
        startDate: this.calendraDate ? this.calendraDate : '',
        endDate: this.toDate ? this.toDate : '',
        year: this.DonationDataYear ? this.DonationDataYear : '2023',
      };
      this.services
        .dashboardPrayer(this.prayerReqQueryParms)
        .subscribe((result) => {
          console.log(result);
          this.prayerMembers = result.message;
          this.PrayerRequestMembersCharts = this.prayerMembers.forEach(
            (item: any) => {
              this.prayerReqOpenArray.push(item.open);
              this.prayerReqClosedArray.push(item.closed);
              this.prayerReqMonths.push(item.month);
            }
          );
          this.prayerRequestchartOptions = {
            chart: {
              type: 'spline',
            },
            title: {
              text: '',
            },
            legend: { enabled: false },

            credits: {
              enabled: false,
            },
            //  subtitle: {
            //     text: "Source: WorldClimate.com"
            //  },
            xAxis: {
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              lineWidth: 0,
              lineColor: 'transparent',
              title: {
                text: '',
              },
              labels: {
                style: {
                  color: '#5A657F',
                  fontSize: 12,
                  fontWeight: 600,
                  fontFamily: 'Inter',
                },
              },
              categories: this.prayerReqMonths,
            },
            yAxis: {
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              title: {
                text: '',
              },
              labels: {
                format: '{value}',
                style: {
                  color: '#425466',
                  fontSize: 12,
                  fontWeight: 600,
                  fontFamily: 'Inter',
                },
              },
            },
            //  tooltip: {
            //     valueSuffix:" °C"
            //  },
            plotOptions: {
              series: {
                marker: {
                  enabled: false,
                },
              },
            },

            colors: ['#0DBC67', '#FF4242'],
            series: [
              {
                name: 'Open',
                data: this.prayerReqOpenArray,
              },
              {
                name: 'Closed',
                data: this.prayerReqClosedArray,
              },
            ],
          };
        });
    }
  }
  prayerExportBtn() {
    this.services.dashboardExportsPrayer(this.prayerReqQueryParms).subscribe((res) => {
      console.log(res);
      this.exportPrayerReq = res.sheetUrl;
      this.download(this.exportPrayerReq, 'sheetUrl');
    });
  }

  download(url: any, downloadName: any) {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = downloadName;
    a.click();
    document.body.removeChild(a);
  }

  StatisticsDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
  }
  AnalyticsDateChange(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
  }

  resetPrayerFilter(){
    this.selectedYearPrayer = '2023';
    this.selectedMonthPrayer = undefined;
    this.startDatePrayer =new Date(2023,0,1);
    this.endDatePrayer = new Date();
    this.getPrayerData();
  }

  selectYearPrayer(event:any){
    if(this.selectedYearPrayer == new Date().getFullYear()){
      let month = new Date().getMonth() + 1;
      this.monthListPrayer = this.monthListPrayer.slice(0,month);
  }else{
      this.monthListPrayer = [
       { key: "JAN", value : 0},
       { key: "FEB", value : 1},
       { key: "MAR", value : 2},
       { key: "APR", value : 3},
       { key: "MAY", value : 4},
       { key: "JUN", value : 5},
       { key: "JUL", value : 6},
       { key: "AUG", value : 7},
       { key: "SEP", value : 8},
       { key: "OCT", value : 9},
       { key: "NOV", value : 10},
       { key: "DEC", value : 11}
      ]
  }
  this.prayerReqQueryParms = {
    startDate:
      this.startDatePrayer.getFullYear() +
      '-' +
      (this.startDatePrayer.getMonth() + 1) +
      '-' +
      this.startDatePrayer.getDate(),
    endDate:
      this.endDatePrayer.getFullYear() +
      '-' +
      (this.endDatePrayer.getMonth() + 1) +
      '-' +
      this.endDatePrayer.getDate(),
  };
  if (this.selectedMonthPrayer == undefined && this.selectedYearPrayer != new Date().getFullYear()) {
    this.startDatePrayer = new Date(Number(this.selectedYearPrayer), 0, 1);
    this.endDatePrayer = new Date(Number(this.selectedYearPrayer), 11, 31);
  }else if(this.selectMonthPrayer == undefined && this.selectedYearPrayer == new Date().getFullYear()){
    this.startDatePrayer = new Date(Number(this.selectedYearPrayer), 0, 1);
    this.endDatePrayer = new Date();
  }else{
    this.startDatePrayer = new Date(Number(this.selectedYearPrayer), this.selectedMonthPrayer, 1);
    this.endDatePrayer = new Date(Number(this.selectedYearPrayer), this.selectedMonthPrayer, this.monthArray[this.selectedMonthPrayer]);
  }
  // this.startDateMember = new Date(Number(this.selectedYear), 0, 1);
  // this.endDateMember = new Date(Number(this.selectedYear), 11, 31);
  //comment below
  //  this.startDateMember = this.datePipe.transform(this.startDateMember,'dd/MM/yyyy')

  this.getPrayerData();
      //  this.startDatePrayer = new Date(Number(this.selectedYearPrayer),0,1);
      //  this.endDatePrayer = new Date(Number(this.selectedYearPrayer),11,31);
      //prev
      //  this.startDateMember = this.datePipe.transform(this.startDateMember,'dd/MM/yyyy')
  }

  selectMonthPrayer(month:any){
    this.startDatePrayer = new Date(Number(this.selectedYearPrayer),this.selectedMonthPrayer,1);
    this.endDatePrayer = new Date(Number(this.selectedYearPrayer),this.selectedMonthPrayer,
    this.monthArray[this.selectedMonthPrayer]);

    this.getPrayerData();
  }
  getPrayerData(){
    this.prayerMembers = [];
    this.PrayerRequestMembersCharts = [];
    this.prayerReqClosedArray = [];
    this.prayerReqOpenArray = [];
    this.prayerReqMonths = [];

    this.prayerReqQueryParms = {
      startDate: this.startDatePrayer.getFullYear()+'-'+(this.startDatePrayer.getMonth()+1)
      +'-'+this.startDatePrayer.getDate(),
      endDate: this.endDatePrayer.getFullYear()+'-'+(this.endDatePrayer.getMonth()+1)
      +'-'+this.endDatePrayer.getDate(),
    };
    
    if (this.selectedMonthPrayer != undefined) {
      this.prayerReqQueryParms['groupBy'] = 'day';
    }

    if (
      this.startDatePrayer.getFullYear() == this.endDatePrayer.getFullYear() &&
      this.startDatePrayer.getMonth() == this.endDatePrayer.getMonth() &&
      this.startDatePrayer.getDate() != this.endDatePrayer.getDate()

    ) {
      this.prayerReqQueryParms['groupBy'] = 'day';
    }
    if (
      this.startDatePrayer.getFullYear() == this.endDatePrayer.getFullYear() &&
      this.startDatePrayer.getMonth() == this.endDatePrayer.getMonth() &&
      this.startDatePrayer.getDate() == this.endDatePrayer.getDate()

    ) {
      this.prayerReqQueryParms['groupBy'] = 'hour';
    }

    this.services
      .dashboardPrayer(this.prayerReqQueryParms)
      .subscribe((result) => {
        this.prayerMembers = result.data;
        this.totalPrayerRequest = result.totalRecords;
        this.activePrayerRequest = result.active;
        this.completedPrayerRequest = result.cancelled;
        this.cancelledPrayerRequest = result.completed;
        this.PrayerRequestMembersCharts = this.prayerMembers.forEach(
          (item: any) => {
            this.prayerReqOpenArray.push(item.created);
            this.prayerReqClosedArray.push(item.completed);
            const day = item.date?.split('-')
            const dateText = item.day ? item.day + '-' : '';
            // const dateText = item.day ? item.day + '-' : '';
            if (item.date != undefined && item.hour == undefined) {
              this.prayerReqMonths.push(day[2] + '-' + item.month)
            }else if(item.date != undefined && item.hour != undefined){
              this.prayerReqMonths.push(item.hour + 1 + ':00')
            }else{
              this.prayerReqMonths.push(dateText + item.month + '-' + item.year);
            }
            // this.prayerReqMonths.push(dateText + item.month + '-' + item.year);
          }

          
          );
        this.prayerRequestchartOptions = {
          chart: {
            type: 'spline',
          },
          title: {
            text: '',
          },
          legend: { enabled: false },

          credits: {
            enabled: false,
          },
          //  subtitle: {
          //     text: "Source: WorldClimate.com"
          //  },
          xAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0,
            lineColor: 'transparent',
            title: {
              text: '',
            },
            labels: {
              style: {
                color: '#5A657F',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
            categories: this.prayerReqMonths,
          },
          yAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            title: {
              text: '',
            },
            labels: {
              format: '{value}',
              style: {
                color: '#425466',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
          },
          //  tooltip: {
          //     valueSuffix:" °C"
          //  },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
              },
            },
          },

          colors: ['#0DBC67', '#FF4242'],
          series: [
            {
              name: 'Open',
              data: this.prayerReqOpenArray,
            },
            {
              name: 'Closed',
              data: this.prayerReqClosedArray,
            },
          ],
        };
      });
  }

  onChangePrayerToDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    this.endDatePrayer = new Date(e.target.value);
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getPrayerData();
    }
  }

  onChangePrayerDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
    this.startDatePrayer = new Date(e.target.value);
    this.endDatePrayer = new Date(e.target.value);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getPrayerData();
    }
  }
}
