import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FileHandle } from 'src/app/_modal/fileHandle';
import { Issue } from 'src/app/_modal/issues';
import { DashboardServicesService } from 'src/app/_services/dashboard-services.service';

@Component({
  selector: 'app-viewissueitsupport',
  templateUrl: './viewissueitsupport.component.html',
  styleUrls: ['./viewissueitsupport.component.css'],
})
export class ViewissueitsupportComponent implements OnInit {
  errorIssue: boolean = true;
  replyIssue: boolean = false;
  url: any;
  selectedFile: File;
  fileName: any;
  queryParamsDetails: any;
  userDetailsParams: any;
  replyIssueForm: FormGroup;
  issue: Issue = {
    issueImages: [],
  };
  issueDialog: any;
  submitted: boolean = false;
  success: boolean = false;
  markAsInvalidStatus: boolean = false;
  statusInvalid: any = 'invalid';
  onLoadPage = new EventEmitter();
  queryParamsStatus: any;

  @ViewChild(MatPaginator) paginator: any = MatPaginator;
  ELEMENT_DATA: Element[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  queryParams: {};
  constructor(
    private diloag: MatDialog,
    private sanitizer: DomSanitizer,
    private router: Router,
    private services: DashboardServicesService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.replyIssueForm = this.fb.group({
      text: ['', [Validators.required]],
      file: [''],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.queryParamsDetails = { ...params.keys, ...params };
      this.userDetailsParams = params.get('_id');
    });
    this.services
      .itSupportRequestDetails(this.userDetailsParams)
      .subscribe((response) => {
        console.table(response);
        this.issueDialog = response.message;
      });
  }

  close() {
    this.diloag.closeAll();
    this.router.navigate(['/It-Support']).then(() => {
      this.reloadComponent();
    });
  }
  reloadComponent() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  reply() {
    this.replyIssue = true;
    this.errorIssue = false;
  }
  replyAfterIssues() {
    if (this.replyIssueForm.invalid) {
      this.submitted = true;
    } else {
      this.submitted = false;
      const formData = new FormData();
      // formData.append('file', this.selectedFile, this.fileName);
      formData.append('text', this.replyIssueForm.value.text);
      if (this.url != null) {
        formData.append('file', this.selectedFile, this.fileName);
      }else{
        formData.append('file',null);
      }
      this.services
        .itSupportRequestReply(this.userDetailsParams, formData)
        .subscribe((response) => {
          console.table(response);
          this.issueDialog = response.message;
          this.success = true;
          this.replyIssue = false;
          this.errorIssue = false;
        });
    }
  }
  markAsInvalid() {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.queryParamsDetails = { ...params.keys, ...params };
      if (params.get('_id') != null) {
        this.userDetailsParams = params.get('_id');
      }
    });
    this.queryParamsStatus = {
      status: this.statusInvalid ? this.statusInvalid : '',
    };
    this.services
      .itSupportChangeStatus(this.userDetailsParams, this.queryParamsStatus)
      .subscribe((response) => {
        this.markAsInvalidStatus = true;
        this.success = false;
        this.replyIssue = false;
        this.errorIssue = false;
        this.onLoadPage.emit();
        this.queryParams = {};
        this.services.itSupportData(this.queryParams).subscribe((response) => {
          this.ELEMENT_DATA = response.message;
        });
      });
  }
  get updatecmsEvent(): { [key: string]: AbstractControl } {
    return this.replyIssueForm.controls;
  }
  onFileSelected(event: any): void {
    // if (event.target.files) {
    //   const file = event.target.files[0];
    //   this.selectedFile = <File>event.target.files[0];
    //   this.fileName = event.target.files[0].name;

    //   const fileHandle: FileHandle = {
    //     file: file,
    //     url: this.sanitizer.bypassSecurityTrustUrl(
    //       window.URL.createObjectURL(file)
    //     ),
    //   };
    //   this.issue.issueImages.push(fileHandle);
    // }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result as string;
      console.log(this.url);
      this.selectedFile = <File>event.target.files[0];
      this.fileName = event.target.files[0].name;
    };
  }
  removeImage(i: number) {
    this.issue.issueImages.splice(i, 1);
  }
  resetFileInput() {
    const Elem:any = document.getElementById("fileInput");
    Elem.value = null
    Elem.type = "text";
    Elem.type = "file";
  }
}
