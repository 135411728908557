import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { interval, Subscription } from 'rxjs';
import { DashboardServicesService } from '../_services/dashboard-services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

export interface Element {
  Membershipid: string;
  user: string;
  // userId: any;
  Phoneno: any;
  weeklyAvgScore: any;
  monthlyAvgScore: any;
  yearlyAvgScore: any;
  Action: any;
  image: any;
  
}
import {
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexChart
} from "ng-apexcharts";
import { FormControl } from '@angular/forms';
export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
};
@Component({
  selector: 'app-newspiritualfitness',
  templateUrl: './newspiritualfitness.component.html',
  styleUrls: ['./newspiritualfitness.component.css']
})
export class NewspiritualfitnessComponent implements OnInit {
  progressValue: number = 3;
  percentagevalue: number = 50;
  bibleEveryday: any = '90%';
  value = 0;
  loading = true;
  spiritulafitnessAll: any;
  atLeastRecord: any;
  seventyPercentObeying: any;
  hundredPercentObeying: any;
  totalCommitment: any;
  totalObeying: number = 0;
  loaderSh:boolean= true;
  chartArrayExcellent: number;
  chartArrayveryGood: number;
  chartArrayveryBad: number;
  chartArrayAverage: number;
  chartArrayBelowAverage: number;
  permissons: string;
  permissionsArray: any;
  activeUsers: any
  irregularUsers: any
  notStarted: any
  totalrecords: any;
  currentPage: any;
  pageSize: any = 10;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  searchparams: any;
  searchClear: string;
  loadingData: true;
  ProfissionalList: any[] = [
    {
      name: 'All',
      count100: 0,
      count65: 0,
      count50a: 0,
      count50b: 0,
    },
    {
      name: 'Today',
      count100: 0,
      count65: 0,
      count50a: 0,
      count50b: 0,
    },
    {
      name: 'This Week',
      count100: 0,
      count65: 0,
      count50a: 0,
      count50b: 5,
    },
    {
      name: 'This Month',
      count100: 0,
      count65: 0,
      count50a: 0,
      count50b: 0,
    },
    {
      name: 'This Year',
      count100: 0,
      count65: 0,
      count50a: 0,
      count50b: 0,
    },
  ];
  selectedType: string = 'All';
  displayedColumns: string[] = [
    'Membershipid',
    'user',
    'Phoneno',
    'weekly',
    'monthly',
    'yearly',
    'Action',
  ];

  ELEMENT_DATA: Element[] = [
  ];
  public chartOptionsExcellent: Partial<ChartOptions>;
  public chartOptionsGood: Partial<ChartOptions>;
  public chartOptionsAvg: Partial<ChartOptions>;
  public chartOptionsBelowAvg: Partial<ChartOptions>;
  public chartOptionsPoor: Partial<ChartOptions>;
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  countResult: any;
  total: any;
  statisticsData: any;
  queryParams: any
  percent: any;
  percent100: number;
  percent50b: number;
  percent50a: number;
  percent65: number;
  selectedValue: string = '';
  weekAvg = new FormControl('');
  monthAvg = new FormControl('');
  yearAvg = new FormControl('');
  weekAvgClear: any;
  monthAvgClear: any;
  yearAvgClear: any;

  weekAvgList: any = [
    { key: '0-10', value: '0-10' },
    { key: '10-20', value: '10-20' },
    { key: '20-30', value: '20-30' },
    { key: '30-40', value: '30-40' },
    { key: '40-50', value: '40-50' },
    { key: '50-60', value: '50-60' },
    { key: '60-70', value: '60-70' },
    { key: '70-80', value: '70-80' },
    { key: '80-90', value: '80-90' },
    { key: '90-100', value: '90-100' },
  ];
  monthAvgList: any = [
    { key: '0-10', value: '0-10' },
    { key: '10-20', value: '10-20' },
    { key: '20-30', value: '20-30' },
    { key: '30-40', value: '30-40' },
    { key: '40-50', value: '40-50' },
    { key: '50-60', value: '50-60' },
    { key: '60-70', value: '60-70' },
    { key: '70-80', value: '70-80' },
    { key: '80-90', value: '80-90' },
    { key: '90-100', value: '90-100' },
  ];
  yearAvgList: any = [
    { key: '0-10', value: '0-10' },
    { key: '10-20', value: '10-20' },
    { key: '20-30', value: '20-30' },
    { key: '30-40', value: '30-40' },
    { key: '40-50', value: '40-50' },
    { key: '50-60', value: '50-60' },
    { key: '60-70', value: '60-70' },
    { key: '70-80', value: '70-80' },
    { key: '80-90', value: '80-90' },
    { key: '90-100', value: '90-100' },
  ];
  weekStatus: any;
  monthStatus: any;
  yearStatus: any;

  constructor(
    // private renderer: Renderer2,
    private host: ElementRef,
    private dialog: MatDialog,
    private services: DashboardServicesService,
    private spinner: NgxSpinnerService,
  ) { }
  @ViewChild(MatPaginator) paginator: any = MatPaginator;

  ngOnInit(): void {
    
    this.permissons = localStorage.getItem('permissons');
    this.permissionsArray = JSON.parse(this.permissons);
    console.log('permissiions', this.permissionsArray);
    // this.services.cmsSpiritualFitnessLastProfessionList().subscribe((res) => {
    //   if (res.data) {
    //     this.ProfissionalList = res.data;
    //     this.ProfissionalList.unshift({
    //       _id: 0,
    //       title: 'All'
    //     })
    //     console.log(this.ProfissionalList);
    //   } else {
    //     this.ProfissionalList = [];
    //   }
    // });
    this.getCounts();

    // this.getUserDetail();
    // this.ELEMENT_DATA = [
    //   {
    //     Membershipid: 'MOB-2023',
    //     user: 'Anuj Singh',
    //     Phoneno: 1234567890,
    //     weeklyAvgScore: '50/1000 20%',
    //     monthlyAvgScore: '50/1000 20%',
    //     yearlyAvgScore: '50/1000 20%',
    //     Action: '../../assets/images/panel_images/user-3.svg',
    //     image: '../../assets/images/panel_images/user-3.svg',
    //   }
    // ]
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  }
  truncateText(text: string, maxLength: number): string {
    if (!text) return 'N/A'; // Default value if text is empty or undefined
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  weekDropDown(event: any) {
    if (event.value != '') {
      this.weekStatus = event.value;
    } else {
      this.weekStatus = '';
    }
    this.queryParams = {
      search: this.searchparams ? this.searchparams : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      weekStatus: this.weekStatus ? this.weekStatus : '',
      monthStatus: this.monthStatus ? this.monthStatus : '',
      yearStatus: this.yearStatus ? this.yearStatus : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.services.spUserDetail(this.queryParams).subscribe((res: any) => {
      if (res.success) {
        this.ELEMENT_DATA = res.data;
        this.totalrecords = res.total;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      }
    })
  }
  monthDropDown(event: any) {
    if (event.value != '') {
      this.monthStatus = event.value;
    } else {
      this.monthStatus = '';
    }
    this.queryParams = {
      search: this.searchparams ? this.searchparams : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      weekStatus: this.weekStatus ? this.weekStatus : '',
      monthStatus: this.monthStatus ? this.monthStatus : '',
      yearStatus: this.yearStatus ? this.yearStatus : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.services.spUserDetail(this.queryParams).subscribe((res: any) => {
      if (res.success) {
        this.ELEMENT_DATA = res.data;
        this.totalrecords = res.total;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      }
    })
  }
  yearDropDown(event: any) {
    if (event.value != '') {
      this.yearStatus = event.value;
    } else {
      this.yearStatus = '';
    }
    this.queryParams = {
      search: this.searchparams ? this.searchparams : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      weekStatus: this.weekStatus ? this.weekStatus : '',
      monthStatus: this.monthStatus ? this.monthStatus : '',
      yearStatus: this.yearStatus ? this.yearStatus : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.services.spUserDetail(this.queryParams).subscribe((res: any) => {
      if (res.success) {
        this.ELEMENT_DATA = res.data;
        this.totalrecords = res.total;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      }
    })
  }
  pageChanged(event: PageEvent) {
    this.loaderSh = true;
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    this.queryParams = {

      search: this.searchparams ? this.searchparams : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    this.services.spUserDetail(this.queryParams).subscribe((res: any) => {
      if (res.success) {
        this.ELEMENT_DATA = res.data;
        this.totalrecords = res.total;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.loaderSh = false;
      }
    })
  }
  reset() {
    this.searchparams = '';
    this.weekAvgClear = '';
    this.monthAvgClear = '';
    this.yearAvgClear = '';
    this.searchClear = '';
    this.queryParams = {
      search: this.searchparams ? this.searchparams : '',
    };
    this.services.spUserDetail(this.queryParams).subscribe((res: any) => {
      if (res.success) {
        this.ELEMENT_DATA = res.data;
        this.totalrecords = res.total;

        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      }
    })
  }
  searchDropDown(event: any) {
    this.searchparams = event.target.value;
    this.queryParams = {
      search: this.searchparams ? this.searchparams : '',
    };
    this.services.spUserDetail(this.queryParams).subscribe((res: any) => {
      if (res.success) {
        this.ELEMENT_DATA = res.data;
        this.totalrecords = res.total;
        console.log('2323232323', this.ELEMENT_DATA);
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      }
    })
    // this.services.prayerRequest(this.queryParams).subscribe((response) => {
    //   this.ELEMENT_DATA = response.data;
    // this.totalrecords = response.totalRecords;
    // this.activeRequest = response.active;
    // this.cancelledRequest = response.cancelled;
    // this.completedRequest = response.completed;
    // this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    // });
  }
  chartData(event: any) {
    this.loaderSh = true
    this.selectedType = event?.tab?.textLabel;
    let params = {
      spiritualType: this.selectedType
    }
    this.spinner.show();
    this.statisticsData = {};
    this.ProfissionalList.forEach(item => {
      item.count100 = 0;
      item.count65 = 0;
      item.count50a = 0;
      item.count50b = 0;
    });
    this.percent100 = 0;
    this.percent50b = 0;
    this.percent50a = 0;
    this.percent65 = 0;
    this.services.spStatisticsDetail(params).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.loaderSh = false
        this.statisticsData = res.data;
        this.ProfissionalList.forEach(item => {
          item.count100 = this.statisticsData['100'] || 0;
          item.count65 = this.statisticsData['75-100'] || 0; 
          item.count50a = this.statisticsData['51-100'] || 0; 
          item.count50b = this.statisticsData['0-50'] || 0; 
        });
        this.percent100 = (this.statisticsData['100'] / this.statisticsData['totalUser']) * 100
        this.percent50b = (this.statisticsData['0-50'] / this.statisticsData['totalUser']) * 100;
        this.percent50a = (this.statisticsData['51-100'] / this.statisticsData['totalUser']) * 100;
        this.percent65 = (this.statisticsData['75-100'] / this.statisticsData['totalUser']) * 100;
      }
      //   })
      // }
      // this.atLeastRecord = res.atLeastRecord;
      // this.seventyPercentObeying = res.seventyPercentObeying;
      // this.hundredPercentObeying = res.hundredPercentObeying;
      // this.totalCommitment = res.totalCommitment;
      // this.spiritulafitnessAll = res.commitmentRecord;
      // this.totalObeying = res.totalUser;

      // this.chartOptionsExcellent.series = [res?.performance?.excellent];
      // this.chartOptionsGood.series = [res?.performance?.good];
      // this.chartOptionsAvg.series = [res?.performance?.average];
      // this.chartOptionsBelowAvg.series = [res?.performance?.belowAverage];
      // this.chartOptionsPoor.series = [res?.performance?.poor];
      // this.chartArrayveryBad = res?.performance?.poor;
      // this.chartArrayBelowAverage = res?.performance?.belowAverage;
      // this.chartArrayAverage = res?.performance?.average;
      // this.chartArrayExcellent = res?.performance?.excellent;
      // this.chartArrayveryGood = res?.performance?.good;
    });

  }
  getCounts() {
    // this.services.totalCount().subscribe({
    //   next: (res: any) => {
    //     if (res.success) {
    //       this.countResult = res.data
    //       this.loaderSh = false;
    //     }
    //   },
    // })
      this.getSpiritualStatistics()
  }
  getUserDetail() {
    this.queryParams = {
      search: this.searchparams ? this.searchparams : '',
      weekStatus: this.weekStatus ? this.weekStatus : '',
      monthStatus: this.monthStatus ? this.monthStatus : '',
      yearStatus: this.yearStatus ? this.yearStatus : '',
      pageLimit: 100,
      // pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    this.services.spUserDetail(this.queryParams).subscribe((res: any) => {
      if (res.success) {
        this.ELEMENT_DATA = res.data;
        console.log('2323232323', this.ELEMENT_DATA);
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.totalrecords = res.total;
        this.dataSource.paginator = this.paginator;
      }
    })
  }
  getSpiritualStatistics() {
    this.queryParams = { data: 'All' }
    this.services.spStatisticsDetail({ spiritualType: 'All' }).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.statisticsData = res.data;
          this.ProfissionalList.forEach(item => {
            item.count100 = this.statisticsData['100'] || 0;
            item.count65 = this.statisticsData['75-100'] || 0;
            item.count50a = this.statisticsData['51-100'] || 0;
            item.count50b = this.statisticsData['0-50'] || 0;
            item.totalUser = this.statisticsData['totalUser'] || 0;
          });
          this.percent100 = (this.statisticsData['100'] / this.statisticsData['totalUser']) * 100
          this.percent50b = (this.statisticsData['0-50'] / this.statisticsData['totalUser']) * 100;
          this.percent50a = (this.statisticsData['51-100'] / this.statisticsData['totalUser']) * 100;
          this.percent65 = (this.statisticsData['75-100'] / this.statisticsData['totalUser']) * 100;
        }
      },
      complete:()=>{
        this.getUserDetail()
      this.loaderSh = false;
      }
    })
  }
  applySelection() {
    console.log('Selected Range: ' + this.selectedValue);
  }
  view() { }
}