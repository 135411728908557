import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { DashboardServicesService } from 'src/app/_services/dashboard-services.service';
import { months } from '../../app.constant';

@Component({
  selector: 'app-tvprograms-analystics',
  templateUrl: './tvprograms-analystics.component.html',
  styleUrls: ['./tvprograms-analystics.component.css'],
})
export class TvprogramsAnalysticsComponent implements OnInit {
  today = new Date();
  dropDownSelection: any;
  calendraDate: any;
  Date: any;
  highcharts = Highcharts;
  chartOptions: any;
  chartOptionsAnalytics: any;
  tvProgramsSponsorchartOptions: any;
  TvProgramsMembersCharts: any;
  TvProgramssponsor: any;
  TvProgramssponsorOpenArray: any = [];
  TvProgramssponsorMembersMonths: any = [];
  TvProgramsMembers: any;
  TvProgramsQueryParms: any;
  TvProgramsYear: any;
  exportSponsorsData: any;
  toDate: string;


  //new---
  monthList = [
    { key: 'JAN', value: 0 },
    { key: 'FEB', value: 1 },
    { key: 'MAR', value: 2 },
    { key: 'APR', value: 3 },
    { key: 'MAY', value: 4 },
    { key: 'JUN', value: 5 },
    { key: 'JUL', value: 6 },
    { key: 'AUG', value: 7 },
    { key: 'SEP', value: 8 },
    { key: 'OCT', value: 9 },
    { key: 'NOV', value: 10 },
    { key: 'DEC', value: 11 },
  ];
  monthListDonation = this.monthList;
  monthArray = months;
  // startDateMember: Date = new Date(2023, 0, 1);
  // endDateMember: Date = new Date();
  TvProgramsOpenArray: any = [];
  TvProgramsMembersMonths: any = [];
  yearList: any = [
    '2023',
    '2022',
    '2021',
    '2020',
    '2019',
    '2018',
    '2017',
    '2016',
    '2015',
    '2014',
    '2013',
    '2012',
    '2011',
    '2010',
    '2009',
    '2008',
    '2007',
    '2006',
    '2005',
    '2004',
    '2003',
    '2002',
    '2001',
    '2000',
  ];
  // selectedYear: any = '2023';
  // monthList = [
  //   { key: 'JAN', value: 0 },
  //   { key: 'FEB', value: 1 },
  //   { key: 'MAR', value: 2 },
  //   { key: 'APR', value: 3 },
  //   { key: 'MAY', value: 4 },
  //   { key: 'JUN', value: 5 },
  //   { key: 'JUL', value: 6 },
  //   { key: 'AUG', value: 7 },
  //   { key: 'SEP', value: 8 },
  //   { key: 'OCT', value: 9 },
  //   { key: 'NOV', value: 10 },
  //   { key: 'DEC', value: 11 },
  // ];
  monthListTvPrograms = this.monthList;
  selectedYearTvPrograms: any = '2023';
  selectedMonthTvPrograms: any;
  startDateTvPrograms: Date = new Date(2023, 0, 1);
  endDateTvPrograms: Date = new Date();
  exportTvProgramData: any;
  totalTvPrograms: any;
  completedTvPrograms: any;
  activeTvPrograms: any;
  tvProgramschartOptions: any
  TvProgramsSponderCharts: any;
  // tvProgramschartOptions: {
  //   chart: { type: string; }; title: { text: string; }; legend: { enabled: boolean; }; credits: { enabled: boolean; };
  //  subtitle: {
  //     text: "Source: WorldClimate.com"
  //  },
  // xAxis: { gridLineWidth: number; minorGridLineWidth: number; lineWidth: number; lineColor: string; title: { text: string; }; labels: { style: { color: string; fontSize: number; fontWeight: number; fontFamily: string; }; }; categories: any; }; yAxis: { gridLineWidth: number; minorGridLineWidth: number; title: { text: string; }; labels: { format: string; style: { color: string; fontSize: number; fontWeight: number; fontFamily: string; }; }; };
  //  tooltip: {
  //     valueSuffix:" °C"
  //  },
  //   plotOptions: { series: { marker: { enabled: boolean; }; }; }; colors: string[]; series: { data: any; }[];
  // };

  constructor(private services: DashboardServicesService) {

  }

  ngOnInit(): void {
    if (this.selectedYearTvPrograms == new Date().getFullYear()) {
      let month = new Date().getMonth() + 1;
      this.monthListTvPrograms = this.monthListTvPrograms.slice(0, month);
    } else {
      this.monthListDonation = [
        { key: 'JAN', value: 0 },
        { key: 'FEB', value: 1 },
        { key: 'MAR', value: 2 },
        { key: 'APR', value: 3 },
        { key: 'MAY', value: 4 },
        { key: 'JUN', value: 5 },
        { key: 'JUL', value: 6 },
        { key: 'AUG', value: 7 },
        { key: 'SEP', value: 8 },
        { key: 'OCT', value: 9 },
        { key: 'NOV', value: 10 },
        { key: 'DEC', value: 11 },
      ];
    }
    this.getTvProgramsData();
    this.chartOptionsAnalytics = {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: { enabled: false },
      //  subtitle: {
      //     text: "Source: WorldClimate.com"
      //  },
      xAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineWidth: 0,
        lineColor: 'transparent',
        title: {
          text: '',
        },
        labels: {
          style: {
            color: '#8492A6',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
        categories: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        ],
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          text: '',
        },
        labels: {
          format: '{value}',
          style: {
            color: '#425466',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
      },
      //  tooltip: {
      //     valueSuffix:" °C"
      //  },
      plotOptions: {
        series: {
          lineWidth: 0.5,
          borderRadius: 5,
          pointWidth: 7,
          marker: {
            enabled: false,
          },
        },
      },

      colors: ['#0074D6', '#FD6A65', '#FFB65C', '#75809C'],
      series: [
        {
          data: [200, 300, 450, 750, 450, 350, 250, 760, 870, 970, 650, 540],
        },
        {
          data: [600, 500, 550, 650, 350, 250, 150, 650, 870, 650, 450, 870],
        },
        {
          data: [400, 560, 450, 550, 550, 650, 760, 760, 870, 890, 960, 650],
        },
        // {
        //   data: [700, 25, 30, 48, 55, 60, 65, 75, 89, 98, 190, 200],
        // },
        // {
        //   data: [900, 15, 20, 35, 45, 50, 55, 65, 70, 90, 100, 110],
        // },
      ],
    };
  }
  SponsorsDataExportBtn() {
    this.TvProgramsQueryParms.type = 'user'
    this.services.dashboardExportTvProgramData(this.TvProgramsQueryParms).subscribe((res) => {
      console.log(res);
      this.exportSponsorsData = res.sheetUrl;
      this.download(this.exportSponsorsData, 'sheetUrl');
    });
  }
  download(url: any, downloadName: any) {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = downloadName;
    a.click();
    document.body.removeChild(a);
  }
  onChangeDate(e: any) {
    this.dropDownSelection = e.target.value;
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getTvProgramsData()
    }
  }
  onChangeToDate(e: any) {
    this.dropDownSelection = e.target.value;
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.TvProgramsQueryParms = {
        startDate: this.calendraDate ? this.calendraDate : '',
        endDate: this.toDate ? this.toDate : '',
        year: this.TvProgramsYear ? this.TvProgramsYear : '2023',
      };
      this.services
        .dashboardtvProgram(this.TvProgramsQueryParms)
        .subscribe((result) => {
          this.TvProgramsMembers = result.message;
          this.TvProgramssponsor = result.sponsor;
          this.TvProgramsMembersCharts = this.TvProgramssponsor.forEach(
            (item: any) => {
              this.TvProgramssponsorOpenArray.push(item.totalUser);
              this.TvProgramssponsorMembersMonths.push(item.month);
            }
          );
          this.tvProgramsSponsorchartOptions = {
            chart: {
              type: 'spline',
            },
            title: {
              text: '',
            },
            legend: { enabled: false },

            credits: {
              enabled: false,
            },
            //  subtitle: {
            //     text: "Source: WorldClimate.com"
            //  },
            xAxis: {
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              lineWidth: 0,
              lineColor: 'transparent',
              title: {
                text: '',
              },
              labels: {
                style: {
                  color: '#5A657F',
                  fontSize: 12,
                  fontWeight: 600,
                  fontFamily: 'Inter',
                },
              },
              categories: this.TvProgramssponsorMembersMonths,
            },
            yAxis: {
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              title: {
                text: '',
              },
              labels: {
                format: '{value}',
                style: {
                  color: '#425466',
                  fontSize: 12,
                  fontWeight: 600,
                  fontFamily: 'Inter',
                },
              },
            },
            //  tooltip: {
            //     valueSuffix:" °C"
            //  },
            plotOptions: {
              series: {
                marker: {
                  enabled: false,
                },
              },
            },

            colors: ['#0DBC67'],
            series: [
              {
                data: this.TvProgramssponsorOpenArray,
              },
            ],
          };
        });
    }
  }
  StatisticsDate(e: any) {
    this.dropDownSelection = e.target.value;
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
  }
  AnalyticsDateChange(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
  }


  //new---


  onChangeTvProgramDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    this.startDateTvPrograms = new Date(e.target.value);
    this.endDateTvPrograms = new Date(e.target.value);
    // console.log('bhoooooo' , ds);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getTvProgramsData();
    }
  }
  onChangeTvProgramToDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    this.endDateTvPrograms = new Date(e.target.value);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getTvProgramsData();
    }
  }

  getTvProgramsData() {
    this.TvProgramsOpenArray = [];
    this.TvProgramsMembersMonths = [];
    this.TvProgramsMembers = [];
    this.TvProgramssponsor = [];
    this.TvProgramsMembersCharts = [];
    this.TvProgramssponsorOpenArray = [];
    this.TvProgramssponsorMembersMonths = [];
    this.TvProgramsQueryParms = {
      startDate:
        this.startDateTvPrograms.getFullYear() +
        '-' +
        (this.startDateTvPrograms.getMonth() + 1) +
        '-' +
        this.startDateTvPrograms.getDate(),
      endDate:
        this.endDateTvPrograms.getFullYear() +
        '-' +
        (this.endDateTvPrograms.getMonth() + 1) +
        '-' +
        this.endDateTvPrograms.getDate(),
    };
    

    if (this.selectedMonthTvPrograms != undefined) {
      this.TvProgramsQueryParms['groupBy'] = 'day';
    }

    if (
      this.startDateTvPrograms.getFullYear() == this.endDateTvPrograms.getFullYear() &&
      this.startDateTvPrograms.getMonth() == this.endDateTvPrograms.getMonth() &&
      this.startDateTvPrograms.getDate() != this.endDateTvPrograms.getDate()

    ) {
      this.TvProgramsQueryParms['groupBy'] = 'day';
    }
    if (
      this.startDateTvPrograms.getFullYear() == this.endDateTvPrograms.getFullYear() &&
      this.startDateTvPrograms.getMonth() == this.endDateTvPrograms.getMonth() &&
      this.startDateTvPrograms.getDate() == this.endDateTvPrograms.getDate()

    ) {
      this.TvProgramsQueryParms['groupBy'] = 'hour';
    }
    this.services
      .dashboardtvProgram(this.TvProgramsQueryParms)
      .subscribe((result) => {
        console.log(result);
        this.TvProgramsMembers = result.data ? result.data : [];
        this.TvProgramssponsor = result.sponsor ? result.sponsor : [];
        this.totalTvPrograms = result.totalSponsorship[0]?.totalAmount;

        this.activeTvPrograms = result.totalSponsorship[0]?.tvProgram;
        this.completedTvPrograms = result.totalSponsorship[0]?.totalSponsor;
        this.activeTvPrograms = result.totalProgram;
        this.completedTvPrograms = result.totalSponsor;
        this.TvProgramsMembersCharts = this.TvProgramsMembers.forEach(
          (item: any) => {
            this.TvProgramsOpenArray.push(item.amount);
            const day = item.date?.split('-')
            const dateText = item.day ? item.day + '-' : '';
            // const dateText = item.day ? item.day + '-' : '';
            // this.TvProgramsMembersMonths.push(
            //   dateText + item.month + '-' + item.year
            // );
            if (item.date != undefined && item.hour == undefined) {
              this.TvProgramsMembersMonths.push(day[2] + '-' + item.month)
            }else if(item.date != undefined && item.hour != undefined){
              this.TvProgramsMembersMonths.push(item.hour + 1 + ':00')
            }else{
              this.TvProgramsMembersMonths.push(dateText + item.month + '-' + item.year);
            }
          }
        );
        this.TvProgramsMembersCharts = this.TvProgramsMembers.forEach(
          (item: any) => {
            this.TvProgramssponsorOpenArray.push(item.totalUser);
            const day = item.date?.split('-')
            const dateText = item.day ? item.day + '-' : '';
            // const dateText = item.day ? item.day + '-' : '';
            if (item.date != undefined && item.hour == undefined) {
              this.TvProgramssponsorMembersMonths.push(day[2] + '-' + item.month)
            }else if(item.date != undefined && item.hour != undefined){
              this.TvProgramssponsorMembersMonths.push(item.hour + 1 + ':00')
            }else{
              this.TvProgramssponsorMembersMonths.push(dateText + item.month + '-' + item.year);
            }
            // this.TvProgramssponsorMembersMonths.push(
            //   dateText + item.month + '-' + item.year
            // );
          }
        );
        this.tvProgramschartOptions = {
          chart: {
            type: 'spline',
          },
          title: {
            text: '',
          },
          legend: { enabled: false },

          credits: {
            enabled: false,
          },
          //  subtitle: {
          //     text: "Source: WorldClimate.com"
          //  },
          xAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0,
            lineColor: 'transparent',
            title: {
              text: '',
            },
            labels: {
              style: {
                color: '#5A657F',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
            categories: this.TvProgramsMembersMonths,
          },
          yAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            title: {
              text: '',
            },
            labels: {
              format: '{value}',
              style: {
                color: '#425466',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
          },
          //  tooltip: {
          //     valueSuffix:" °C"
          //  },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
              },
            },
          },

          colors: ['#0DBC67'],
          series: [
            {
              name:'amount ',
              data: this.TvProgramsOpenArray,
            },
          ],
        };
        this.tvProgramsSponsorchartOptions = {
          chart: {
            type: 'spline',
          },
          title: {
            text: '',
          },
          legend: { enabled: false },

          credits: {
            enabled: false,
          },
          //  subtitle: {
          //     text: "Source: WorldClimate.com"
          //  },
          xAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0,
            lineColor: 'transparent',
            title: {
              text: '',
            },
            labels: {
              style: {
                color: '#5A657F',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
            categories: this.TvProgramssponsorMembersMonths,
          },
          yAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            title: {
              text: '',
            },
            labels: {
              format: '{value}',
              style: {
                color: '#425466',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
          },
          //  tooltip: {
          //     valueSuffix:" °C"
          //  },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
              },
            },
          },

          colors: ['#0DBC67'],
          series: [
            {
              name:'user ',
              data: this.TvProgramssponsorOpenArray,
            },
          ],
        };
      });
  }
  TvProgramDataExportBtn() {
    this.TvProgramsQueryParms.type = 'amount'
    this.services.dashboardExportTvProgramData(this.TvProgramsQueryParms).subscribe((res) => {
      console.log(res);
      this.exportTvProgramData = res.sheetUrl;
      this.download(this.exportTvProgramData, 'sheetUrl');
    });
  }

 

  resetTvProgramsFilter() {
    this.selectedYearTvPrograms = '2023';
    this.selectedMonthTvPrograms = undefined;
    this.startDateTvPrograms = new Date(2023, 0, 1);
    this.endDateTvPrograms = new Date();
    this.getTvProgramsData();
  }

  selectYearTvPrograms(event: any) {
    if (this.selectedYearTvPrograms == new Date().getFullYear()) {
      let month = new Date().getMonth() + 1;
      this.monthListTvPrograms = this.monthList.slice(0, month);
    } else {
      this.monthListTvPrograms = [
        { key: 'JAN', value: 0 },
        { key: 'FEB', value: 1 },
        { key: 'MAR', value: 2 },
        { key: 'APR', value: 3 },
        { key: 'MAY', value: 4 },
        { key: 'JUN', value: 5 },
        { key: 'JUL', value: 6 },
        { key: 'AUG', value: 7 },
        { key: 'SEP', value: 8 },
        { key: 'OCT', value: 9 },
        { key: 'NOV', value: 10 },
        { key: 'DEC', value: 11 },
      ];
    }
    // this.startDateTvPrograms = new Date(
    //   Number(this.selectedYearTvPrograms),
    //   0,
    //   1
    // );
    // this.endDateTvPrograms = new Date(
    //   Number(this.selectedYearTvPrograms),
    //   11,
    //   31
    // );
    this.TvProgramsQueryParms = {
      startDate:
        this.startDateTvPrograms.getFullYear() +
        '-' +
        (this.startDateTvPrograms.getMonth() + 1) +
        '-' +
        this.startDateTvPrograms.getDate(),
      endDate:
        this.endDateTvPrograms.getFullYear() +
        '-' +
        (this.endDateTvPrograms.getMonth() + 1) +
        '-' +
        this.endDateTvPrograms.getDate(),
    };
    
    if (this.selectedMonthTvPrograms == undefined && this.selectedYearTvPrograms != new Date().getFullYear()) {
      this.startDateTvPrograms = new Date(Number(this.selectedYearTvPrograms), 0, 1);
      this.endDateTvPrograms = new Date(Number(this.selectedYearTvPrograms), 11, 31);
    }else if(this.selectedMonthTvPrograms == undefined && this.selectedYearTvPrograms == new Date().getFullYear()){
      this.startDateTvPrograms = new Date(Number(this.selectedYearTvPrograms), 0, 1);
      this.endDateTvPrograms = new Date();
    }else{
      this.startDateTvPrograms = new Date(Number(this.selectedYearTvPrograms), this.selectedMonthTvPrograms, 1);
      this.endDateTvPrograms = new Date(Number(this.selectedYearTvPrograms), this.selectedMonthTvPrograms, this.monthArray[this.selectedMonthTvPrograms]);
    }
    //  this.startDateMember = this.datePipe.transform(this.startDateMember,'dd/MM/yyyy')

    this.getTvProgramsData();
  }

  selectMonthTvPrograms(month: any) {
    this.startDateTvPrograms = new Date(
      Number(this.selectedYearTvPrograms),
      this.selectedMonthTvPrograms,
      1
    );
    this.endDateTvPrograms = new Date(
      Number(this.selectedYearTvPrograms),
      this.selectedMonthTvPrograms,
      this.monthArray[this.selectedMonthTvPrograms]
    );
    
    this.getTvProgramsData();
  }
}
