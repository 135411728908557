<!-- <div class="spinner" >
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  type="ball-spin-clockwise-fade"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
		</div> -->

<div class="container-fluid bg-white ">
    <div class="pt-3 ml-1 ">
        <div class="d-flex pt-3 pb-2">
            <div class="back-btn mr-2 cursor" routerLink="/Servicerequests">
                <img src="../../../assets/images/panel_images/back.svg" class="back-img">
            </div>
            <div class="user-header-back">
                <h5>Service Request</h5>
                <p><span>Home / </span>Service Request </p>
            </div>
        </div>
    </div>

</div>
<div class='spinner-loader' *ngIf="loaderShow">
    <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>

</div>

<div class="container-fluid mt-4">
    <div class="user-header-details-img-section pt-5 bg-white ml-1">
        <div class="user-details-details-img grid-container mb-4">
            <div class="w-30 " *ngFor="let user of details">
                <div class="mr-3 ml-5">

                    <!-- <img src="../../../assets/images/panel_images/birthday-cake-img.png" class="rounded cake-img-1"> -->
                    <img src="{{ this.url }}/{{ user.service[0].image ? user.service[0].image : ''}}" class="cake-img-1">
                </div>
                <div class="details_user service-user ml-5 mt-3">

                    <h4>{{user.service[0].title ? user.service[0].title : ''}}</h4>
                </div>
            </div>
            <div class="details-section mt-0 ml-5 " *ngFor="let user of details;let i = index">



                <div class="d-flex" *ngIf="user.service[0].title === 'Birthday Cake'">
                    <div class="details-heading details-services">
                        <h4>Date:</h4>
                    </div>
                    <div class="details-heading-user">
                        <h4>{{user.dob | date: "MMMM d, y"}}</h4>
                    </div>
                </div>
                <div class="d-flex" *ngIf="user.service[0].title != 'Birthday Cake'">
                    <div class="details-heading details-services">
                        <h4>Service Requested Date:</h4>
                    </div>
                    <div class="details-heading-user">
                        <h4>{{ user?.createdAt ? (user?.createdAt | date: "MMMM d, y") : 'N/A'}}</h4>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="details-heading details-services">
                        <h4>Address:</h4>
                    </div>
                    <div class="details-heading-user">
                        <h4>{{user.address}}</h4>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="details-heading details-services">
                        <h4>Pincode:</h4>
                    </div>
                    <div class="details-heading-user">
                        <h4>{{user.pinCode ? user.pinCode : '-'}}</h4>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="details-heading details-services">
                        <h4>Landmark:</h4>
                    </div>
                    <div class="details-heading-user">
                        <h4>{{user.landMark ? user.landMark : '-'}}</h4>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="details-heading details-services">
                        <h4>State:</h4>
                    </div>
                    <div class="details-heading-user">
                        <h4>{{user.state ? user.state : 'N/A'}}</h4>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="details-heading details-services">
                        <h4>City:</h4>
                    </div>
                    <div class="details-heading-user">
                        <h4>{{user.city ? user.city : 'N/A'}}</h4>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="details-heading details-services">
                        <h4>Mobile Number:</h4>
                    </div>
                    <div class="details-heading-user">
                        <h4>{{user.phone ? user.phone : 'N/A'}}</h4>
                    </div>
                </div>
                <div class="d-flex mt-5 mb-3" *ngIf="user.status == 'in-progress'">
                    <div class="details-heading details-services">
                    </div>
                    <div class="details-heading-use">
                        <button *ngIf="permissons?.service_Requests?.edit" class="request-btn-service btn" (click)="schedule(i)">Accept</button>
                    </div>
                </div>
                <div class="d-flex mt-5 mb-3" *ngIf="user.status == 'accepted'">
                    <div class="details-heading details-services">
                    </div>
                    <div class="details-heading-use">
                        <button *ngIf="permissons?.service_Requests?.edit" class="request-btn-service btn">Accepted</button>
                    </div>
                </div>
            </div>

        </div>


        <hr class="hr-line-user">

        <div class="ml-4">
            <div class="user-details-details-img d-flex mb-4" *ngFor="let user of details">
                <div class="d-flex mr-auto">
                    <div class="mr-3 ml-4" [class.noimage]="user?.user[0]?.profile == null">
                        <!-- <img src="../../../assets/images/panel_images/user-img.svg" class="rounded"> -->
                        <img *ngIf="user?.user[0]?.profile != null" src="{{user?.user[0]?.profile}}" class="service-user-img">
                        <ngx-avatar *ngIf="user?.user[0]?.profile == null" class="mr-3 pt-md-5 name-a" initialsSize="1" size="60" name="{{user.user[0]?.name}}"></ngx-avatar>
                        <ngx-avatar *ngIf="user?.user[0]?.name == null" class="mr-3 pt-md-5 name-a" initialsSize="1" size="70" name="N/A"></ngx-avatar>

                    </div>
                    <div class="details_user mt-4">
                        <h4 [class.noname]="user?.user[0]?.name == null">{{user?.user[0]?.name ? user?.user[0]?.name : 'N/A'}}</h4>
                        <p *ngIf="user?.user[0]?.membershipId != null ">{{user?.user[0]?.membershipId }}</p>
                    </div>
                </div>
            </div>
            <hr class="hr-line-user">


            <div class="details-section mt-4 ml-5" *ngFor="let user of details">
                <div class="d-flex">
                    <div class="details-heading ">
                        <h4>Gender:</h4>
                    </div>
                    <div class="details-heading-user">
                        <h4>{{user?.gender ? user?.gender : 'none'}}</h4>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="details-heading ">
                        <h4>Email:</h4>
                    </div>
                    <div class="details-heading-user">
                        <h4>{{user.user[0].email ? user.user[0].email : 'NA'}}</h4>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="details-heading ">
                        <h4>Mobile No:</h4>
                    </div>
                    <div class="details-heading-user">
                        <h4>{{user.user[0].phone}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-4 mb-5">
    <div class="bg-white user-header-details-img-section  ml-1 service-history">
        <h6 class="pt-3 mb-3 ml-lg-5">Service History</h6>
        <hr class="hr-line-user">

        <div class="serivce-request d-flex mt-4 ml-3 mr-3" *ngFor="let user of serviceHistory.slice(0,10)">
            <div class="d-flex mr-auto ml-3">
                <div class="service-req-details-img mr-3">
                    <img *ngIf="user.serviceId.image.includes('https://')" src="{{ user.serviceId.image ? user.serviceId.image : ''}}" class="img-1">
                    <img *ngIf="!user.serviceId.image.includes('https://')" src="{{ this.url }}/{{ user.serviceId.image ? user.serviceId.image : ''}}" class="img-1">

                </div>

                <div class="service-req-details mt-2">
                    <h4>{{user?.serviceId?.title ? user?.serviceId?.title : 'N/A'}}</h4>


                    <p>{{user?.createdAt ? (user?.createdAt | date: "MMMM d, y") : '-'}}</p>
                </div>
            </div>
            <div class="completed mr-3 mt-2">
                <h3 *ngIf="user.status == 'completed'" class="eligable">
                    {{ user.status |titlecase }}
                </h3>
                <h3 *ngIf="user.status == 'accepted'" class="memberStatus">
                    {{ user.status |titlecase }}
                </h3>
                <h3 *ngIf="user.status == 'in-progress'" class="not-eligable">
                    New
                </h3>
                <h3 *ngIf="user.status == 'cancelled'" class="not-member">
                    {{ user.status |titlecase }}
                </h3>

            </div>
        </div>
        <div class="service-req-details mt-2 ml-5 pb-4">

            <h4 *ngIf="this.serviceHistory?.length == 0">No previous records </h4>


        </div>

        <!-- <hr class="hr-line-user">
<div class="serivce-request d-flex mt-4 ml-3 mr-3">
        <div class="d-flex mr-auto ml-3">
            <div class="service-req-details-img mr-3">
              <img src="../../../assets/images/panel_images/ce-2.png" class="rounded">
            </div>
            <div class="service-req-details mt-2">
                <h4>Ambulance Service</h4>
                <p>Mother  •  12-04-2022</p>
            </div>
        </div>
        <div class="completed mr-3 mt-2">
             <h3 class="rejected">
            Closed </h3>
        </div>
    </div>
        <hr class="hr-line-user">
<div class="serivce-request d-flex mt-4 ml-3 mr-3">
        <div class="d-flex mr-auto ml-3">
            <div class="service-req-details-img mr-3">
              <img src="../../../assets/images/panel_images/ce-3.png" class="rounded">
            </div>
            <div class="service-req-details mt-2">
                <h4>Parents Home</h4>
                <p>Mother  •  12-04-2022</p>
            </div>
        </div>
        <div class="completed mr-3 mt-2">
             <h3 class="completedtype">
            Open</h3>
        </div>
    </div>
        <hr class="hr-line-user">
<div class="serivce-request d-flex mt-4 ml-3 mr-3">
        <div class="d-flex mr-auto ml-3">
            <div class="service-req-details-img mr-3">
              <img src="../../../assets/images/panel_images/ce-4.png" class="rounded">
            </div>
            <div class="service-req-details mt-2">
                <h4>Weddings</h4>
                <p>Son  •  12-04-2022</p>
            </div>
        </div>
        <div class="completed mr-3 mt-2">
             <h3 class="completedtype">
            Open</h3>
        </div>
    </div> -->
    </div>
</div>