<div class="container-fluid bg-white ">
    <div class="pt-3 ml-1">
        <div class="d-flex pt-3 pb-2">
            <div class="back-btn mr-2 cursor" routerLink="/CMS-Module" [queryParams]="{tab:2}">
                <img src="../../../assets/images/panel_images/back.svg" class="back-img">
            </div>
            <div class="user-header-back">
                <h5>Medias Request</h5>
                <p><span>CMS Module /  Review / </span>Media Request</p>
            </div>
        </div>
    </div>

</div>

<div class="nofound" *ngIf="mediaData == '' || mediaData ==  null">
    <h5>
        No request found
    </h5>
</div>
<div class="user-header-details-img-section mediaData-push bg-white mx-md-4 mt-md-5" *ngIf="mediaData != '' && mediaData !=  null">
    <div class="push-nofications-section pt-2 tabs-section">
        <div class="d-flex push-notofications-col ml-md-3 pl-md-5 pr-md-4 pb-md-3">
            <app-showloader *ngIf="Loader"></app-showloader>

            <div class="mr-auto push pl-md-3 ">
                <h4>Media Request ( {{ selectedType | titlecase}} )</h4>
            </div>
        </div>

        <div class="row event-row" *ngFor="let card of mediaData; let i = index">
            <div class="col-md-8 push-notofications-col">

                <div class="d-flex ml-4">
                    <div class="push-heading created-details-section">
                        <p>Created by:</p>
                    </div>
                    <div class="details-push-nofications">
                        <p *ngIf="card?.createdBy?.firstName">{{card?.createdBy?.firstName}} {{card?.createdBy?.lastName}}</p>
                        <p *ngIf="card?.user">{{card?.user[0]?.firstName}} {{card?.user[0]?.lastName}}</p>
                        <p *ngIf="card?.adminDetails">{{card?.adminDetails?.firstName}} {{card?.adminDetails?.lastName}}</p>
                    </div>
                </div>
                <div class="d-flex ml-4 my-3">
                    <div class="push-heading created-details-section ">
                        <p>Created Date:</p>
                    </div>
                    <div class="details-push-nofications">
                        <p>{{card.createdAt | date: 'MMMM d, y, h:mm a' }}</p>
                    </div>
                </div>
                <div *ngIf="selectedType == 'video'">
                    <div class="d-flex ml-4 mt-3">
                        <div class="push-heading">
                            <p>Title:</p>
                        </div>
                        <div class="media-email-field mt-0 ml-4 pl-3" style='width:100%;'>
                            <mat-form-field>
                                <!-- <mat-label>Enter Title</mat-label> -->
                                <input matInput [value]="card.title" readonly/>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="d-flex ml-4 mt-3">
                        <div class="push-heading">
                            <p>Description:</p>
                        </div>
                        <div class="media-email-field mt-0 ml-4 pl-3" style='width:100%;'>
                            <mat-form-field>
                                <!-- <mat-label>Write something...</mat-label> -->
                                <input matInput [value]="card.description" readonly/>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="d-flex ml-4 mt-3">
                        <div class="push-heading">
                            <p>Link:</p>
                        </div>
                        <div class="media-email-field mt-0 ml-4 pl-3" style='width:100%;'>
                            <mat-form-field>
                                <input matInput [value]="card.youtubeVideo" readonly/>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="d-flex ml-4 mt-4">
                        <div class="source-heading" style="width: 280px;">
                            <p>Image:</p>
                        </div>
                        <div class="upload-img" *ngIf="card?.image">
                            <img [src]="card?.image" class="i-img">
                        </div>
                        <div class="upload-img" *ngIf="card?.thumbnail">
                            <img [src]="card?.thumbnail" class="i-img">
                        </div>
                        <div class="upload-img" *ngIf="!card?.image && !card?.thumbnail">
                            N/A
                        </div>
                    </div>
                    <div class="d-flex ml-4 mt-3">
                        <div class="push-heading">
                            <p>Category:</p>
                        </div>
                        <div class="media-email-field mt-0 ml-4 pl-3" style='width:100%;'>
                            <mat-form-field>
                                <!-- <mat-label>Write something...</mat-label> -->
                                <input matInput [value]="card.category[0].name" readonly />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectedType == 'daily promise'">
                    <div class="d-flex ml-4 mt-3">
                        <div class="push-heading">
                            <p>Date & Time:</p>
                        </div>
                        <div class="media-email-field mt-0 ml-4 pl-3" style='width:100%;'>
                            <mat-form-field>
                                <!-- <mat-label>Write something...</mat-label> -->
                                <input matInput value="{{
                                    card.date | date: 'MMMM d, y, h:mm a'
                                }}" readonly/>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="d-flex ml-4 mt-4">
                        <div class="source-heading" style="width: 280px;">
                            <p>Image:</p>
                        </div>
                        <div class="upload-img ">
                            <img [src]="card?.image" class="i-img">
                        </div>
                    </div>
                </div>

                <div *ngIf="selectedType == 'event'">
                    <div class="d-flex ml-4 mt-3">
                        <div class="push-heading">
                            <p>Event Start Date:</p>
                        </div>
                        <div class="media-email-field mt-0 ml-4 pl-3" style='width:100%;'>
                            <mat-form-field>
                                <!-- <mat-label>Write something...</mat-label> -->
                                <input matInput value="{{
                                    card.eventStart | date: 'MMMM d, y, h:mm a'
                                }}" readonly/>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="d-flex ml-4 mt-3">
                        <div class="push-heading">
                            <p>Event End Date:</p>
                        </div>
                        <div class="media-email-field mt-0 ml-4 pl-3" style='width:100%;'>
                            <mat-form-field>
                                <!-- <mat-label>Write something...</mat-label> -->
                                <input matInput value="{{
                                    card.eventEnd | date: 'MMMM d, y, h:mm a'
                                }}" readonly/>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="d-flex ml-4 mt-3">
                        <div class="push-heading">
                            <p>Link:</p>
                        </div>
                        <div class="media-email-field mt-0 ml-4 pl-3" style='width:100%;'>
                            <mat-form-field>
                                <input matInput [value]="card.youtubeVideoLink" readonly/>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="d-flex ml-4 mt-4">
                        <div class="source-heading" style="width: 280px;">
                            <p>Image:</p>
                        </div>
                        <div class="upload-img" *ngIf="card?.youtubeVideo">
                            <img [src]="card?.youtubeVideo" class="i-img">
                        </div>
                        <div class="upload-img" *ngIf="!card?.youtubeVideo">
                            N/A
                        </div>
                    </div>
                </div>
                <div class="button-section-tv-programs ml-4 pl-2 mt-5 pb-4" *ngIf="(role == 'superAdmin' || role == 'admin') && today <= getTime(card.date)
                && selectedType == 'daily promise'">
                    <button type="button " class="btn draft-btn mr-4 " (click)="decline(card._id,i) ">Reject</button>
                    <button type="button " class="btn create-btn " (click)="approve(card._id,i) ">Approve</button>
                </div>
                <div class="button-section-tv-programs ml-4 pl-2 mt-5 pb-4" *ngIf="(role == 'superAdmin' || role == 'admin') && selectedType != 'daily promise'">
                    <button type="button " class="btn draft-btn mr-4 " (click)="decline(card._id,i) ">Reject</button>
                    <button type="button " class="btn create-btn " (click)="approve(card._id,i) ">Approve</button>
                </div>
            </div>
            <div class="preview-btn button-section-tv-programs " *ngIf="today> getTime(card.date) && selectedType == 'daily promise'">
                <button type="button" class="btn">Request Expired</button>
            </div>
        </div>
    </div>
</div>