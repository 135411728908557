import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DashboardServicesService } from 'src/app/_services/dashboard-services.service';
import { RejectedApprovedDialogComponent } from '../rejected-approved-dialog/rejected-approved-dialog.component';
import { SuccessApprovedDialogComponent } from '../success-approved-dialog/success-approved-dialog.component';

@Component({
  selector: 'app-reviewprayerrequests',
  templateUrl: './reviewprayerrequests.component.html',
  styleUrls: ['./reviewprayerrequests.component.css']
})
export class ReviewprayerrequestsComponent implements OnInit {
  tvProgramFileTypeParams: any;
  cmsHistoryPrayerReq: any = 'prayer';
  status: any = 'pending';
  cmsPrayerData: any;
  statusParams: any;
  statusApprove: any = 'approved';
  Loader: boolean;
  declineParams: { status: any };
  rejectstatus: any = 'rejected';
  role:any = 'superAdmin';

  constructor(
    private services: DashboardServicesService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {

    this.role = localStorage.getItem('ROLE');
    this.tvProgramFileTypeParams = {
      fileType: this.cmsHistoryPrayerReq,
      status: this.status,
    };
    this.services
      .cmsHistoryTvProgramSection(this.tvProgramFileTypeParams)
      .subscribe((data) => {
        this.cmsPrayerData = data.data;
      });
  }
  create(id: any, i: any) {
    this.statusParams = {
      status: this.statusApprove,
    };
    this.Loader = true;
    this.services.superAdminReview(id, this.statusParams).subscribe((res) => {
      setTimeout(() => {
        this.Loader = false;
        this.cmsPrayerData.splice(i, 1);
        this.dialog.open(SuccessApprovedDialogComponent, {
          disableClose: true,
        });
      }, 1000);
    });
  }
  reject(id: any, i: any) {
    this.declineParams = {
      status: this.rejectstatus,
    };
    this.Loader = true;

    this.services.superAdminReview(id, this.declineParams).subscribe((res) => {
      this.cmsPrayerData.splice(i, 1);
      setTimeout(() => {
        this.Loader = false;
        this.dialog.open(RejectedApprovedDialogComponent, {
          disableClose: true,
        });
      }, 1000);
    });
  }

  downloadFile(key:any){
     window.open('https://calvary-backend.s3.amazonaws.com/'+key);
  }
}


