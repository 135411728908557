import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardServicesService } from '../_services/dashboard-services.service';

@Component({
  selector: 'app-sidenavbar',
  templateUrl: './sidenavbar.component.html',
  styleUrls: ['./sidenavbar.component.css'],
})
export class SidenavbarComponent implements OnInit {
  @Input() sidebarOpen: boolean = false;
  @Input() notificationsOpen: boolean = false;

  sidemenuMainItems = [
    {
      icon: '../../assets/images/panel_images/dashboard.svg',
      title: 'Dashboard',
      link: '/Dashboard',
      active: false,
      isPermit: false,
    },
    {
      icon: '../../assets/images/panel_images/user.svg',
      title: 'Members List',
      link: '/Userslist',
      active: false,
      isPermit: false,
    },
    {
      icon: '../../assets/images/panel_images/service.svg',
      title: 'Service Requests',
      link: '/Servicerequests',
      active: false,
      isPermit: false,
    },
    {
      icon: '../../assets/images/panel_images/tv-program.svg',
      title: 'TV Programs',
      link: '/Tv-programs',
      active: false,
      isPermit: false,
    },

    {
      icon: '../../assets/images/panel_images/prayer.svg',
      title: 'Prayer Requests',
      link: '/Prayer-requests',
      active: false,
      isPermit: false,
    },
    {
      icon: '../../assets/images/panel_images/donate.svg',
      title: 'Donations',
      link: '/Donations',
      active: false,
      isPermit: false,
    },

    {
      icon: '../../assets/images/panel_images/cms.svg',
      title: 'CMS Module',
      link: '/CMS-Module',
      active: false,
      isPermit: false,
    },

    {
      icon: '../../assets/images/panel_images/sf.svg',
      title: 'Spiritual Fitness',
      link: '/Spiritual-Fitness',
      active: false,
      isPermit: true,
    },
  ];
  sidemenuHelpItems: any = [
    {
      icon: '../../assets/images/panel_images/settings.svg',
      title: 'Settings',
      link: '/Setting',
      active: false,
      isPermit: true,
    },
    {
      icon: '../../assets/images/panel_images/img-it.svg',
      title: 'IT Support',
      link: '/It-Support',
      active: false,
      isPermit: false,
    },
    {
      icon: '../../assets/images/panel_images/user-m.svg',
      title: 'Internal-Team',
      link: '/Internal-Team',
      active: false,
      isPermit: false,
    },
    {
      icon: '../../assets/images/panel_images/support.svg',
      title: 'Support',
      link: '/Support',
      active: false,
      isPermit: false,
    },
  ];

  storelogo = '../../assets/images/Calvary_Temple/Group 1000004517@3x 2.png';
  role: any;
  permissons: any;

  constructor(
    private services: DashboardServicesService,
    private router: Router
  ) {
    this.role = localStorage.getItem('ROLE');
  }

  ngOnInit(): void {
    this.services.adminDetails().subscribe((results) => {
      this.permissons = results.data.permissions;
      localStorage.setItem('permissons', JSON.stringify(this.permissons));

      this.sidemenuMainItems.forEach((item: any) => {
        // if (
        //   item.link == '/Dashboard' &&
        //   this.permissons.members_List?.analyse == false &&
        //   this.permissons.service_Requests?.analyse == false &&
        //   this.permissons.prayer_Requests?.analyse == false &&
        //   this.permissons.Donation?.analyse == false &&
        //   this.permissons.tv_programs?.analyse == false &&
        //   this.permissons.support?.analyse == false
        // ) {
        //   item.isPermit = false;
        //   this.router.navigate(['/Spiritual-Fitness']);
        // }
        // if (item.link == '/Userslist' && this.permissons.members_List.view) {
        //   item.isPermit = true;
        // }
        // if (item.link == '/Dashboard' && this.permissons.members_List.analyse) {
        //   item.isPermit = true;
        // }
        // if (
        //   item.link == '/Dashboard' &&
        //   this.permissons.service_Requests?.analyse
        // ) {
        //   item.isPermit = true;
        // }
        // if (
        //   item.link == '/Dashboard' &&
        //   this.permissons.prayer_Requests.analyse
        // ) {
        //   item.isPermit = true;
        // }
        // if (item.link == '/Dashboard' && this.permissons.Donation.analyse) {
        //   item.isPermit = true;
        // }
        // if (item.link == '/Dashboard' && this.permissons.tv_programs.analyse) {
        //   item.isPermit = true;
        // }
        // if (item.link == '/Dashboard' && this.permissons.support?.analyse) {
        //   item.isPermit = true;
        // }

        // if (
        //   item.link == '/Servicerequests' &&
        //   this.permissons.service_Requests.view
        // ) {
        //   item.isPermit = true;
        // }
        // if (item.link == '/Tv-programs' && this.permissons.tv_programs.view) {
        //   item.isPermit = true;
        // }
        // if (
        //   item.link == '/Prayer-requests' &&
        //   this.permissons.prayer_Requests.view
        // ) {
        //   item.isPermit = true;
        // }
        // if (item.link == '/Donations' && this.permissons.Donation.view) {
        //   item.isPermit = true;
        // }
        // if (item.link == '/CMS-Module' && this.permissons.cms_Model.view) {
        //   item.isPermit = true;
        // }

        //New

        if (item.link == '/Dashboard') {
          item.isPermit = true;
        }
        // if (item.link == '/Spiritual-Fitness' && this.permissons.spiritual_Fitness.view) {
        //   item.isPermit = true;
        // }
        if (item.link == '/Spiritual-Fitness' && this.permissons.spiritual_Fitness.view) {
          item.isPermit = true;
        }
        if (item.link == '/Userslist' && this.permissons.members_List.view) {
          item.isPermit = true;
        }
        if (item.link == '/Dashboard' && this.permissons.members_List.analyse) {
          item.isPermit = true;
        }
        if (
          item.link == '/Dashboard' &&
          this.permissons.service_Requests?.analyse
        ) {
          item.isPermit = true;
        }
        if (
          item.link == '/Dashboard' &&
          this.permissons.prayer_Requests.analyse
        ) {
          item.isPermit = true;
        }
        if (item.link == '/Dashboard' && this.permissons.Donation.analyse) {
          item.isPermit = true;
        }
        if (item.link == '/Dashboard' && this.permissons.tv_programs.analyse) {
          item.isPermit = true;
        }
        if (item.link == '/Dashboard' && this.permissons.support?.analyse) {
          item.isPermit = true;
        }

        if (
          item.link == '/Servicerequests' &&
          this.permissons.service_Requests.view
        ) {
          item.isPermit = true;
        }
        if (item.link == '/Tv-programs' && this.permissons.tv_programs.view) {
          item.isPermit = true;
        }
        if (
          item.link == '/Prayer-requests' &&
          this.permissons.prayer_Requests.view
        ) {
          item.isPermit = true;
        }
        if (item.link == '/Donations' && this.permissons.Donation.view) {
          item.isPermit = true;
        }
        if (item.link == '/CMS-Module' && this.permissons.cms_Model.view) {
          item.isPermit = true;
        }

      });
      this.sidemenuHelpItems.forEach((item: any) => {
        if (item.link == '/Support' && this.permissons.support.view) {
          item.isPermit = true;
        }
      });
      console.log(this.sidemenuHelpItems);
      if (this.role == 'superAdmin') {
      }

      console.log('permissons', this.permissons);
      this.role = results.data.role;
      if (this.role == 'superAdmin' || this.role == 'admin') {
        this.sidemenuHelpItems = [
          {
            icon: '../../assets/images/panel_images/settings.svg',
            title: 'Settings',
            link: '/Setting',
            active: false,
            isPermit: true,
          },
          {
            icon: '../../assets/images/panel_images/img-it.svg',
            title: 'IT Support',
            link: '/It-Support',
            active: false,
            isPermit: true,
          },
          {
            icon: '../../assets/images/panel_images/user-m.svg',
            title: 'Internal-Team',
            link: '/Internal-Team',
            active: false,
            isPermit: true,
          },
          {
            icon: '../../assets/images/panel_images/support.svg',
            title: 'Support',
            link: '/Support',
            active: false,
            isPermit: true,
          },
        ];
      // } else if (this.permissons.support.view) {
      } else{
        this.sidemenuHelpItems = [
          {
            icon: '../../assets/images/panel_images/settings.svg',
            title: 'Settings',
            link: '/Setting',
            active: false,
            isPermit: true,
          },
          {
            icon: '../../assets/images/panel_images/support.svg',
            title: 'Support',
            link: '/Support',
            active: false,
            isPermit: true,
          },
        ];
      }
    });
  }
}
