import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { interval, Subscription } from 'rxjs';
import { DashboardServicesService } from '../_services/dashboard-services.service';
import { NgxSpinnerService } from 'ngx-spinner';

declare const circularProgressBar: any;

import {
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexChart
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
};
@Component({
  selector: 'app-spiritualfitness',
  templateUrl: './spiritualfitness.component.html',
  styleUrls: ['./spiritualfitness.component.css'],
})
export class SpiritualfitnessComponent implements OnInit {
  progressValue: number = 3;
  percentagevalue: number = 50;
  bibleEveryday: any = '90%';
  value = 0;
  loading = true;
  spiritulafitnessAll: any;
  atLeastRecord: any;
  seventyPercentObeying: any;
  hundredPercentObeying: any;
  totalCommitment: any;
  totalObeying:number = 0;

  chartArrayExcellent: number;
  chartArrayveryGood: number;
  chartArrayveryBad: number;
  chartArrayAverage: number;
  chartArrayBelowAverage: number;
  permissons: string;
  permissionsArray: any;

  ProfissionalList: any;
  selectedType:string = 'All';
  public chartOptionsExcellent: Partial<ChartOptions>;
  public chartOptionsGood: Partial<ChartOptions>;
  public chartOptionsAvg: Partial<ChartOptions>;
  public chartOptionsBelowAvg: Partial<ChartOptions>;
  public chartOptionsPoor: Partial<ChartOptions>;




  constructor(
    private renderer: Renderer2,
    private host: ElementRef,
    private services: DashboardServicesService,
    private spinner: NgxSpinnerService
  ) {

    this.chartOptionsExcellent = {
      series: [0],
      chart: {
        height: 200,
        width: 200,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "50%",
          },
          dataLabels:{
            value:{
              fontWeight:'bold',
              offsetY:-11
            }
         }
        }
      },
      labels: [""]
    };
    this.chartOptionsGood = {
      series: [0],
      chart: {
        height: 200,
        width: 200,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "50%"
          },
          dataLabels:{
            value:{
              fontWeight:'bold',
              offsetY:-11
            }
         }
        }
      },
      labels: [""]
    };
    this.chartOptionsAvg = {
      series: [0],
      chart: {
        height: 200,
        width: 200,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "50%"
          },
          dataLabels:{
            value:{
              fontWeight:'bold',
              offsetY:-11
            }
         }
        }
      },
      labels: [""]
    };
    this.chartOptionsBelowAvg = {
      series: [0],
      chart: {
        height: 200,
        width: 200,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "50%"
          },
          dataLabels:{
            value:{
              fontWeight:'bold',
              offsetY:-11
            }
         }
        }
      },
      labels: [""]
    };
    this.chartOptionsPoor = {
      series: [0],
      chart: {
        height: 200,
        width: 200,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "50%"
          },
          dataLabels:{
             value:{
               fontWeight:'bold',
               offsetY:-11
             }
          }
        }
      },
      labels: [""]
    };
  }
  mode: ProgressSpinnerMode = 'determinate';
  ngOnInit(): void {
    this.permissons = localStorage.getItem('permissons');
    this.permissionsArray = JSON.parse(this.permissons);
    console.log('permissiions' , this.permissionsArray);
    this.services.cmsSpiritualFitnessLastProfessionList().subscribe((res) => {
      if (res.data) {
        this.ProfissionalList = res.data;
        this.ProfissionalList.unshift({
           _id: 0,
           title: 'All'
        })
        console.log(this.ProfissionalList);
      } else {
        this.ProfissionalList = [];
      }
    });
    circularProgressBar();

    let params = {
      spiritualType: this.selectedType
    }
    this.services.spiritualFitnessAll(params).subscribe((res: any) => {
      console.log('ressssssssssss', res);
      // this.atLeastRecord = res.atLeastRecord;
      // this.seventyPercentObeying = res.seventyPercentObeying;
      // this.hundredPercentObeying = res.hundredPercentObeying;
      // this.totalCommitment = res.totalCommitment;
        // this.spiritulafitnessAll = res.commitmentRecord;
        // this.totalObeying = res.totalUser;

        // this.chartOptionsExcellent.series = [res?.performance?.excellent];
        // this.chartOptionsGood.series = [res?.performance?.good];
        // this.chartOptionsAvg.series = [res?.performance?.average];
        // this.chartOptionsBelowAvg.series = [res?.performance?.belowAverage];
        // this.chartOptionsPoor.series = [res?.performance?.poor];
    });
  }

  chartData(event: any) {
    this.selectedType = event?.tab?.textLabel;
    let params = {
      spiritualType: this.selectedType
    }
    this.spinner.show();
    this.services.spiritualFitnessAll(params).subscribe((res: any) => {
      this.spinner.hide();
      // this.atLeastRecord = res.atLeastRecord;
      // this.seventyPercentObeying = res.seventyPercentObeying;
      // this.hundredPercentObeying = res.hundredPercentObeying;
      // this.totalCommitment = res.totalCommitment;
      this.spiritulafitnessAll = res.commitmentRecord;
      this.totalObeying = res.totalUser;

        this.chartOptionsExcellent.series = [res?.performance?.excellent];
        this.chartOptionsGood.series = [res?.performance?.good];
        this.chartOptionsAvg.series = [res?.performance?.average];
        this.chartOptionsBelowAvg.series = [res?.performance?.belowAverage];
        this.chartOptionsPoor.series = [res?.performance?.poor];
        // this.chartArrayveryBad = res?.performance?.poor;
        // this.chartArrayBelowAverage = res?.performance?.belowAverage;
        // this.chartArrayAverage = res?.performance?.average;
        // this.chartArrayExcellent = res?.performance?.excellent;
        // this.chartArrayveryGood = res?.performance?.good;
    });
  }
}
