<ng-container
  *ngIf="
    permissionsArray?.support?.analyse ||
      permissionsArray?.support?.edit ||
      permissionsArray?.support?.view;
    else alternateTemplate
  "
  class="main"
>
  <div class="container-fluid bg-white">
    <div class="user-list-header d-flex pt-3 pb-1 ml-1">
      <ng-container *ngIf="permissionsArray?.support?.view;" >
        <div class="user-list-heading d-flex">
          <img
            src="../../assets/images/panel_images/support-icon.svg"
            class="user-list mr-2"
          />
          <h6 class="mt-2">Support</h6>
        </div>
        <div class="search-bar ml-3 mr-2">
          <mat-form-field class="example-full-width" appearance="fill">
            <input
              matInput
              (keyup)="applyFilter($event)"
              (input)="searchDropDown($event)"
              placeholder="Search by users,Request No..."
              #input
              [(ngModel)]="searchClear"
            />
            <mat-icon matSuffix>
              <img
                src="../../assets/images/panel_images/search-icon.svg"
                class="calendra-img"
              />
            </mat-icon>
          </mat-form-field>
        </div>
        <div class="service-list-drop-down mr-2">
          <mat-form-field appearance="fill">
            <div class="arrow-up"></div>
            <span matPrefix>Origin:&nbsp;</span>
            <mat-icon matSuffix 
          ><img
            src="../../assets/images/panel_images/arrow-1.svg"
            class="calendra-img"
        /></mat-icon>
            <mat-select
              [formControl]="serviceControl"
              multiple
              disableOptionCentering
              placeholder="Select..."
              (selectionChange)="originDropDown($event)"
              panelClass="myPanelClass"
              #closeSerivce
              [(ngModel)]="originClear"
            >
              <mat-select-trigger>
                {{serviceControl.value?.[0] || ''}}
                <span
                  *ngIf="(serviceControl.value?.length || 0) > 1"
                  class="example-additional-selection"
                >
                  (+{{ (serviceControl.value?.length || 0) - 1 }}
                  {{ serviceControl.value?.length === 2 ? "other" : "others" }})
                </span>
              </mat-select-trigger>
              <mat-option
                class="services-view"
                *ngFor="let viewService of Origin"
                [value]="viewService.key"
                >{{ viewService.value }}</mat-option
              >
              <div class="apply-btn-option pt-4 pb-3">
                <button
                  type="button"
                  class="btn btn-apply"
                  (click)="closeSerivce.close()"
                >
                  Apply
                </button>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="calendra-field mr-2">
          <mat-form-field class="example-full-width" appearance="fill">
            <span matPrefix>Date:&nbsp;</span>
            <mat-date-range-input [rangePicker]="picker">
              <input
                matStartDate
                matInput
                placeholder="Start date  "
                readonly="readonly"
                [(ngModel)]="dateClear"
                (dateChange)="onChangeDate($event)"
              />
              <input
                matEndDate
                matInput
                placeholder="End date"
                readonly="readonly"
                [(ngModel)]="dateClear1"
                (dateChange)="onChangeToDate($event)"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker">
              <mat-icon matDatepickerToggleIcon>
                <img
                  src="../../assets/images/panel_images/c-11.svg"
                  class="calendra-img"
                />
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
        <div class="user-list-drop-down mr-2">
          <mat-form-field appearance="fill">
            <div class="arrow-up"></div>
            <span matPrefix>Status:&nbsp;</span>
            <mat-icon matSuffix 
          ><img
            src="../../assets/images/panel_images/arrow-1.svg"
            class="calendra-img"
        /></mat-icon>
            <mat-select
              [formControl]="statusOption"
              multiple
              placeholder="Select..."
              (selectionChange)="statusDropDown($event)"
              disableOptionCentering
              panelClass="myPanelClass"
              (keyup)="applyFilter($event)"
              #closeSelect
              [(ngModel)]="statusClear"
            >
              <mat-select-trigger>
                {{statusOption.value?.[0] || ''}}
                <span
                  *ngIf="(statusOption.value?.length || 0) > 1"
                  class="example-additional-selection"
                >
                  (+{{ (statusOption.value?.length || 0) - 1 }}
                  {{ statusOption.value?.length === 2 ? "other" : "others" }})
                </span>
              </mat-select-trigger>
              <mat-option
                class="services-view"
                *ngFor="let viewStatus of status"
                [value]="viewStatus"
                >{{ viewStatus }}</mat-option
              >
              <div class="apply-btn-option pt-4 pb-3">
                <button
                  type="button"
                  class="btn btn-apply"
                  (click)="closeSelect.close()"
                >
                  Apply
                </button>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="graph cursor mr-2" (click)="reset()">
          <img
            src="../../assets/images/panel_images/reset-1.png"
            class="reset-img ml-2"
          />
        </div>
      </ng-container>
      
      <!-- <div class="create-btn-section mx-2"><button type="button" class="btn create-btn">Exports</button></div> -->
      
      <div class="graph cursor mr-2 ml-auto" *ngIf="permissionsArray.support.analyse">
        <img
          src="../../assets/images/panel_images/graph.svg"
          class="graph-img ml-2"
          (click)="Analytics()"
        />
      </div>
    </div>
  </div>
  <ng-container *ngIf="permissionsArray?.support?.view; else listTemplate" class="container-fluid pt-4 ml-1">
    <div class="d-flex p-4">
      <div class="card users-count mr-3 total-user-bg">
        <div class="card-body d-flex">
          <img
            src="../../assets/images/panel_images/sr-1.svg"
            id="users-total"
            class="mr-2"
          />
          <div class="card-details-users">
            <h4 class="mt-3">Support Requests</h4>
            <h2 class="mt-2">{{ total ? (total | records) : "0" }}</h2>
          </div>
        </div>
      </div>
      <div class="card users-count mr-3 total-active-bg">
        <div class="card-body d-flex">
          <img
            src="../../assets/images/panel_images/sr-2.svg"
            id="users-total"
            class="mr-2"
          />
          <div class="card-details-users">
            <h4 class="mt-3">Open</h4>
            <h2 class="mt-2">{{ open ? (open | records) : "0" }}</h2>
          </div>
        </div>
      </div>
      <div class="card users-count mr-3 total-existing-bg">
        <div class="card-body d-flex">
          <img
            src="../../assets/images/panel_images/sr-3.svg"
            id="users-total"
            class="mr-2"
          />
          <div class="card-details-users">
            <h4 class="mt-3">Closed</h4>
            <h2 class="mt-2">{{ closed ? (closed | records) : "0" }}</h2>
          </div>
        </div>
      </div>
      <div class="card users-count mr-3 total-non-bg">
        <div class="card-body d-flex">
          <img
            src="../../assets/images/panel_images/sr-4.svg"
            id="users-total"
            class="mr-2"
          />
          <div class="card-details-users">
            <h4 class="mt-3">Invalid Requests</h4>
            <h2 class="mt-2">{{ invalid ? (invalid | records) : "0" }}</h2>
          </div>
        </div>
      </div>
    </div>
    <!-- <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  type="ball-spin-clockwise-fade"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner> -->
    <app-showloader></app-showloader>

    <div class="table mt-5 mb-5">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Position Column -->
        <ng-container matColumnDef="Requestno">
          <th mat-header-cell class="th-header-table" *matHeaderCellDef>
            <h2>Request no</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="">
              <h6 class="mt-2">{{ element.requestNo }}</h6>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef>
            <h2>user</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              <img
                *ngIf="element.user[0]?.profile != null"
                
                src="{{ element?.user[0]?.profile }}"
                class="user-image-table mr-2"
              />
              <ngx-avatar
                *ngIf="
                  element.user[0]?.profile == null ||
                  element.user[0]?.profile == ''
                "
                class="mr-2"
                initialsSize="1"
                size="40"
                name="{{ element.user[0]?.name }}"
              ></ngx-avatar>
              <ngx-avatar
                *ngIf="element.user[0]?.name == null"
                class="mr-3 n-p"
                initialsSize="1"
                size="40"
                name="N/A"
              ></ngx-avatar>
              <div class="">
                <h6 class="mt-2">
                  {{ element.user[0]?.name ? element.user[0]?.name : (element.name ? element.name : 'NA') }}
                </h6>
                <!-- <p>56CALVARY1234</p> -->
              </div>
            </div>
          </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="Service">
          <th mat-header-cell *matHeaderCellDef>
            <h2>phone no.</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h4>
              <span *ngIf="element?.phone != null && element?.phone != ''"
                >+91-</span
              >{{ element?.phone ? element?.phone : "-" }}
            </h4>
          </td>
        </ng-container>
        <!-- Weight Column -->
        <ng-container matColumnDef="RECEIVERSNAME">
          <th mat-header-cell *matHeaderCellDef class="issue">
            <h2>Issue Title & Description</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h6 class="mb-0">
              {{ element.reason[0]?.name ? element.reason[0]?.name : "-" }}
            </h6>
            <P>{{
              element.description ? (element.description | slice : 0 : 50) : "-"
            }}</P>
          </td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Date</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h6 class="mb-0">{{ element.createdAt | date : "MMMM d, y" }} <br/>
              {{ element.createdAt | date : "shortTime" }}</h6>
          </td>
        </ng-container>
        <!-- Symbol Column -->
        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Status</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h6 *ngIf="element.status == 'open'" class="not-eligable">
              {{ element.status | titlecase }}
            </h6>
            <h6 *ngIf="element.status == 'closed'" class="rejected">
              {{ element.status | titlecase }}
            </h6>
            <h6 *ngIf="element.status == 'invalid'" class="not-member">
              {{ element.status | titlecase }}
            </h6>
          </td>
        </ng-container>
        <ng-container matColumnDef="Origin">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Origin</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <div
              class="d-flex"
              *ngIf="element.typeOfBug == 'IOS' || element.typeOfBug == 'iOS'"
            >
              <img
                src="../../assets/images/panel_images/moblie-1.svg"
                class="user-image-table-origin mr-2"
              />
              <div class="">
                <h6 class="mt-2">{{ element.typeOfBug }}</h6>
              </div>
            </div>
            <div
              class="d-flex"
              *ngIf="
                element.typeOfBug == 'Android' || element.typeOfBug == 'android'
              "
            >
              <img
                src="../../assets/images/panel_images/moblie-1.svg"
                class="user-image-table-origin mr-2"
              />
              <div class="">
                <h6 class="mt-2">{{ element.typeOfBug | titlecase }}</h6>
              </div>
            </div>
            <div
              class="d-flex"
              *ngIf="
                element.typeOfBug == 'general' || element.typeOfBug == 'general'
              "
            >
              <img
                src="../../assets/images/panel_images/web-1.svg"
                class="user-image-table-origin mr-2"
              />
              <div class="">
                <h6 class="mt-2">{{ element.typeOfBug | titlecase }}</h6>
              </div>
            </div>
            <div
              class="d-flex"
              *ngIf="
                element.typeOfBug == 'Website' || element.typeOfBug == 'website'
              "
            >
              <img
                src="../../assets/images/panel_images/web-1.svg"
                class="user-image-table-origin mr-2"
              />
              <div class="">
                <h6 class="mt-2">{{ element.typeOfBug | titlecase }}</h6>
              </div>
            </div>
            <!-- <div class="d-flex" >
               <img *ngIf="element.typeOfBug == 'general' || element.typeOfBug == 'General'" src="../../assets/images/panel_images/web-1.svg" class="user-image-table-origin mr-2" />
               <img  *ngIf="element.typeOfBug == 'website' || element.typeOfBug == 'Website'" src="../../assets/images/panel_images/web-1.svg" class="user-image-table-origin mr-2" />
               <img *ngIf="element.typeOfBug == 'Android' || element.typeOfBug == 'android'" src="../../assets/images/panel_images/moblie-1.svg" class="user-image-table-origin mr-2" />
               <img  *ngIf="element.typeOfBug == 'iOS' || element.typeOfBug == 'IOS' " src="../../assets/images/panel_images/moblie-1.svg" class="user-image-table-origin mr-2" />
               <div class="">
                 <h6 class="mt-2">{{ element.typeOfBug | titlecase  }}</h6>
               </div>
             </div> -->
          </td>
        </ng-container>
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef>
            <h2 class="text-center pr-md-3">Action</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              mat-button
              [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu"
            >
              <mat-icon>
                <img
                  src="../../assets/images/panel_images/action-dot.svg"
                  id="users-total"
                  class="action-dots"
                />
              </mat-icon>
              <div
                #menuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="menu"
                place
              ></div>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button
                class="View"
                mat-menu-item
                value="Edit"
                routerLink="./"
                [queryParams]="{ _id: element._id }"
                (click)="view()"
              >
                <img
                  src="../../assets/images/panel_images/view.svg"
                  class="mr-3"
                />
                <span>View</span>
              </button>
              <button
                class="View"
                mat-menu-item
                value="Edit"
                *ngIf="
                  element.status != 'closed' &&
                  element.status != 'invalid' &&
                  this.permissionsArray.support.edit
                "
                (click)="markAsClose(element._id)"
              >
                <img
                  src="../../assets/images/panel_images/mark.svg"
                  class="mr-3"
                />
                <span>Mark as Close</span>
              </button>
              <button
                class="View"
                mat-menu-item
                value="Edit"
                *ngIf="
                  element.status != 'closed' &&
                  element.status != 'invalid' &&
                  this.permissionsArray.support.edit
                "
                (click)="markAsInvalid(element._id)"
              >
                <img
                  src="../../assets/images/panel_images/invalid.svg"
                  class="mr-3"
                />
                <span>Mark as Invalid</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data available</td>
        </tr>
      </table>
      <mat-paginator
        (page)="pageChanged($event)"
        [length]="total"
        [pageIndex]="currentPage"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        aria-label="Select page of users"
      ></mat-paginator>
    </div>
  </ng-container>
</ng-container>
<ng-template #listTemplate>
  <div class="mt-5 text-center">
    <img
      src="../../assets//images/panel_images/notPermitted.png"
      width="120"
      alt=""
    />
    <p class="mt-5">You dont have permission to access this feature</p>
  </div>
</ng-template>
<ng-template #alternateTemplate>
  <div class="mt-5 text-center">
    <img
      src="../../assets//images/panel_images/notPermitted.png"
      width="120"
      alt=""
    />
    <p class="mt-5">You dont have permission to access this feature</p>
  </div>
</ng-template>
