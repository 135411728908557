import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MarkAsInvalidcommonDialogComponent } from '../mark-as-invalidcommon-dialog/mark-as-invalidcommon-dialog.component';
import { DashboardServicesService } from '../_services/dashboard-services.service';
import { SuspenddialogComponent } from './suspenddialog/suspenddialog.component';
export interface PeriodicElement {
  user: string;
  Membershipid: any;
  Phoneno: any;
  admin: any;
  Status: string;
  Lastvisited: any;
  adminimg: any;
  Action: any;
  image: any;
}

// const ELEMENT_DATA: PeriodicElement[] = [
//   {
//     user: 'Kristin Watson',
//     Membershipid: '56CALVARY1234',
//     Phoneno: '+91-9876543210',
//     Status: 'Suspended',
//     Lastvisited: '13-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-1.svg',
//     admin: 'Admin',
//     adminimg: '../../assets/images/panel_images/admin-i.svg',
//   },
//   {
//     user: 'Cody Fisher',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Status: 'Active',
//     Lastvisited: '18-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-2.svg',
//     admin: 'Admin',
//     adminimg: '../../assets/images/panel_images/admin-i.svg',
//   },
//   {
//     user: 'Arlene McCoy',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Status: 'Suspended',
//     Lastvisited: '8-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-3.svg',
//     admin: 'Admin',
//     adminimg: '../../assets/images/panel_images/admin-i.svg',
//   },
//   {
//     user: 'Floyd Miles',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Status: 'Active',
//     Lastvisited: '13-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-4.svg',
//     admin: 'Admin',
//     adminimg: '../../assets/images/panel_images/admin-i.svg',
//   },
//   {
//     user: 'Darlene Robertson',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Status: 'Active',
//     Lastvisited: '13-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-5.svg',
//     admin: 'Admin',
//     adminimg: '../../assets/images/panel_images/admin-i.svg',
//   },
//   {
//     user: 'Kristin Watson',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Status: 'Suspended',
//     Lastvisited: '13-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-6.svg',
//     admin: 'Admin',
//     adminimg: '../../assets/images/panel_images/admin-i.svg',
//   },
//   {
//     user: 'Kristin Watson',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Status: 'Suspended',
//     Lastvisited: '13-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-7.svg',
//     admin: 'Admin',
//     adminimg: '../../assets/images/panel_images/admin-i.svg',
//   },
//   {
//     user: 'Kristin Watson',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Status: 'Active',
//     Lastvisited: '13-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-8.svg',
//     admin: 'Admin',
//     adminimg: '../../assets/images/panel_images/admin-i.svg',
//   },
//   {
//     user: 'Kristin Watson',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Status: 'Suspended',
//     Lastvisited: '13-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-9.svg',
//     admin: 'Admin',
//     adminimg: '../../assets/images/panel_images/admin-i.svg',
//   },
// ];
@Component({
  selector: 'app-usermanagment',
  templateUrl: './usermanagment.component.html',
  styleUrls: ['./usermanagment.component.css'],
})
export class UsermanagmentComponent implements OnInit {
  statusOption = new FormControl('');
  serviceControl = new FormControl('');

  status = [
    { key: 'active', value: 'Active' },
    { key: 'suspended', value: 'Suspended' },
  ];
  services: any[] = [
    { key: 'superAdmin', value: 'Super Admin' },
    { key: 'admin', value: 'Admin' },
    { key: 'manager', value: 'Manager' },
  ];
  @ViewChild(MatPaginator) paginator: any = MatPaginator;
  ELEMENT_DATA: Element[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  supportQuery: any;
  role: any;
  search: any;
  statusparms: any;
  searchparams: any;
  queryParamsStatus: any;
  statusCompleted: any = 'suspended';
  onLoadPage = new EventEmitter();
  pageSize: any;
  currentPage: any;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  totalrecords: any;
  roleClear: string;
  statusClear:string;
  searchClear:string;
  rolePermission: string;
  exportInternalTeam: any;

  constructor(
    private router: Router,
    private service: DashboardServicesService,
    private dialog: MatDialog
  ) {
    this.rolePermission = localStorage.getItem('ROLE');
  }

  ngOnInit(): void {
    this.supportQuery = {
      status: this.statusparms ? this.statusparms : '',
      search: this.search ? this.search : '',
      role: this.role ? this.role : '',
      // pageLimit:
      // pageNumber:
    };
    this.service.userMangmentAdmin(this.supportQuery).subscribe((result) => {
      console.log(result);
      this.ELEMENT_DATA = result.message;
      this.totalrecords = result.totalData;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
    });
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    this.supportQuery = {
      status: this.statusparms ? this.statusparms : '',
      search: this.search ? this.search : '',
      role: this.role ? this.role : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    this.service.userMangmentAdmin(this.supportQuery).subscribe((result) => {
      console.log(result);
      this.ELEMENT_DATA = result.message;
      this.totalrecords = result.totalData;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  displayedColumns: string[] = [
    'user',
    'Phoneno',
    'role',
    'Status',
    'Lastvisited',
    'Action',
  ];
  selection = new SelectionModel<PeriodicElement>(true, []);
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  suspendUser(id: any) {
    this.queryParamsStatus = {
      status: this.statusCompleted ? this.statusCompleted : '',
    };
    this.service
      .userMangmentAdminUpdateStatus(id, this.queryParamsStatus)
      .subscribe((res) => {
        console.log(res);
        this.dialog.open(SuspenddialogComponent, {
          data: {
            disableClose: true,
          },
        });
        this.service
          .userMangmentAdmin(this.supportQuery)
          .subscribe((result) => {
            console.log(result);
            this.ELEMENT_DATA = result.message;
            this.totalrecords = result.totalData;
            this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
            // this.dataSource.paginator = this.paginator;
          });
        this.onLoadPage.emit();
      });
  }
  searchDropDown(event: any) {
    if (event.value != '') {
      this.search = event.target.value;
    } else {
      this.search = '';
    }
    this.supportQuery = {
      status: this.statusparms ? this.statusparms : '',
      search: this.search ? this.search : '',
      role: this.role ? this.role : '',
      // pageLimit:
      // pageNumber:
    };
    this.service.userMangmentAdmin(this.supportQuery).subscribe((result) => {
      console.log(result);
      this.ELEMENT_DATA = result.message;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.totalrecords = result.totalData;
      // this.dataSource.paginator = this.paginator;
    });
  }
  roleEvent(e: any) {
    if (e.value != '') {
      // this.role = JSON.stringify(e.value);
      this.role = e.value;
    } else {
      this.role = '';
    }
    this.supportQuery = {
      status: this.statusparms ? this.statusparms : '',
      search: this.search ? this.search : '',
      role: this.role ? this.role : '',
      // pageLimit:
      // pageNumber:
    };
    this.service.userMangmentAdmin(this.supportQuery).subscribe((result) => {
      console.log(result);
      this.ELEMENT_DATA = result.message;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      this.totalrecords = result.totalData;
      // this.dataSource.paginator = this.paginator;
    });
  }
  reset(){
    this.roleClear = ''
    this.statusClear = ''
    this.searchClear = ''
    this.statusparms = ''
    this.search = ''
    this.role = ''
    this.supportQuery = {
      status: this.statusparms ? this.statusparms : '',
      search: this.search ? this.search : '',
      role: this.role ? this.role : '',
      // pageLimit:
      // pageNumber:
    };
    this.service.userMangmentAdmin(this.supportQuery).subscribe((result) => {
      console.log(result);
      this.ELEMENT_DATA = result.message;
      this.totalrecords = result.totalData;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      // this.dataSource.paginator = this.paginator;
    });
  }
  statusDropDown(e: any) {
    if (e.value != '') {
      this.statusparms = e.value;
      // this.statusparms = JSON.stringify(e.value);
    } else {
      this.statusparms = '';
    }
    this.supportQuery = {
      status: this.statusparms ? this.statusparms : '',
      search: this.search ? this.search : '',
      role: this.role ? this.role : '',
      // pageLimit:
      // pageNumber:
    };
    this.service.userMangmentAdmin(this.supportQuery).subscribe((result) => {
      console.log(result);
      this.ELEMENT_DATA = result.message;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.totalrecords = result.totalData;
      this.dataSource.paginator = this.paginator;
    });
  }
  create() {
    this.router.navigate(['Internal-Team/view-user']);
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    // this.isAllSelected()
    //   ? this.selection.clear()
    //   : this.dataSource.data.forEach((row) => this.selection.select(row));
  }
  download(url: any, downloadName: any) {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = downloadName;
    a.click();
    document.body.removeChild(a);
  }
  internalTemDetailExport(){
    this.supportQuery = {
      status: this.statusparms ? this.statusparms : '',
          search: this.search ? this.search : '',
          role: this.role ? this.role : '',
        };
    this.service
    .userDetailMangmentAdmin(this.supportQuery)
    .subscribe((res:any) => {
      console.log(res);
      this.exportInternalTeam = res.sheetUrl;
      this.download(this.exportInternalTeam, 'sheetUrl');
    });
  }
}
