import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { DashboardServicesService } from '../_services/dashboard-services.service';
import { DatePipe } from '@angular/common';
import { months } from '../app.constant';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  isClicked = false;
  dropDownSelection: any;
  Date: any;
  calendraDate: any;
  today: Date = new Date();
  selectDuration = [{ value: 'Month' }, { value: 'Year' }];
  highcharts = Highcharts;
  chartOptions: any;
  chartOptionsAnalytics: any;
  serviceRequestCharts: any;
  serviceChartOptionsAnalytics: any;
  prayerRequestchartOptions: any;
  prayerRequestchartOptionsAnalytics: any;
  donationchartOptions: any;
  DonationchartOptionsAnalytics: any;
  donationsecondchartOptions: any;
  tvProgramschartOptionsAnalytics: any;
  tvProgramschartOptions: any;
  supportchartOptions: any;
  maleMembers: any;
  afterMaleMembersDetails: any;
  afterMaleMembersArray: any = [];
  afterFemaleMembersArray: any = [];
  TotalMonths: any = [];
  serviceCreatedArray: any = [];
  serviceCompletedArray: any = [];
  serviceMonths: any = [];
  totalRecords: any;
  totalMale: any;
  totalFemale: any;
  totalService: any;
  activeRequest: any;
  completedRequest: any;
  cancelledRequest: any;
  serviceMembers: any;
  serviceMembersCharts: any;
  loading: any = true;
  url =
    'https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg';
  exportMembers: any;
  queryparmsMembers: any;
  memberYear: any;
  ServiceYear: any;
  serviceQueryParms: any;
  exportService: any;
  prayerMembers: any;
  totalPrayerRequest: any;
  activePrayerRequest: any;
  completedPrayerRequest: any;
  cancelledPrayerRequest: any;
  PrayerRequestMembersCharts: any;
  prayerReqOpenArray: any = [];
  prayerReqClosedArray: any = [];
  prayerReqMonths: any = [];
  prayerReqQueryParms: any;
  prayerReqYear: any;
  exportPrayerReq: any;
  DonationDataMembers: any;
  totalDonationData: any;
  activeDonationData: any;
  completedDonationData: any;
  cancelledDonationData: any;
  DonationDataMembersCharts: any;
  DonationDataOpenArray: any = [];
  DonationDataMembersMonths: any = [];
  DonationDataYear: any;
  DonationDataQueryParms: any;
  exportDoantionData: any;
  exportSupport: any;
  supportQueryParms: any;
  supportYear: any;
  supportMembersMonths: any = [];
  supportdArray: any = [];
  supportOpenArray: any = [];
  supportMembersCharts: any;
  completedsupport: any;
  activesupport: any;
  totalsupport: any;
  supportMembers: any;
  cancelledsupport: any;
  DonationDataTotalUsers: any = [];
  exportDonorsData: any;
  exportTvProgramData: any;
  exportSponsorsData: any;
  TvProgramsQueryParms: any;
  TvProgramsYear: any;
  TvProgramsMembersCharts: any;
  completedTvPrograms: any;
  activeTvPrograms: any;
  totalTvPrograms: any;
  TvProgramsMembers: any;
  TvProgramsOpenArray: any = [];
  TvProgramsTotalUsers: any = [];
  TvProgramsMembersMonths: any = [];
  TvProgramssponsor: any = [];
  TvProgramssponsorOpenArray: any = [];
  TvProgramssponsorMembersMonths: any = [];
  tvProgramsSponsorchartOptions: any;
  toDate: any;
  afterFliterData: Iterable<unknown>;
  dropDownSelectionService: any;
  calendraDateService: string;
  toDateService: string;
  permissions: any;
  permissionsList: any = {};
  yearList: any = [
    '2024',
    '2023',
    '2022',
    '2021',
    '2020',
    '2019',
    '2018',
    '2017',
    '2016',
    '2015',
    '2014',
    '2013',
    '2012',
    '2011',
    '2010',
    '2009',
    '2008',
    '2007',
    '2006',
    '2005',
    '2004',
    '2003',
    '2002',
    '2001',
    '2000',
  ];
  selectedYear: any = '2024';
  monthList = [
    { key: 'JAN', value: 0 },
    { key: 'FEB', value: 1 },
    { key: 'MAR', value: 2 },
    { key: 'APR', value: 3 },
    { key: 'MAY', value: 4 },
    { key: 'JUN', value: 5 },
    { key: 'JUL', value: 6 },
    { key: 'AUG', value: 7 },
    { key: 'SEP', value: 8 },
    { key: 'OCT', value: 9 },
    { key: 'NOV', value: 10 },
    { key: 'DEC', value: 11 },
  ];

  selectedMonth: any;
  startDateMember: Date = new Date(2024, 0, 1);
  endDateMember: Date = new Date();

  selectedYearService: any = '2024';
  selectedMonthService: any;
  startDateService: Date = new Date(2024, 0, 1);
  endDateService: Date = new Date();
  monthListService = this.monthList;

  monthListPrayer = this.monthList;
  selectedYearPrayer: any = '2024';
  selectedMonthPrayer: any;
  startDatePrayer: Date = new Date(2024, 0, 1);
  endDatePrayer: Date = new Date();

  monthListDonation = this.monthList;
  selectedYearDonation: any = '2024';
  selectedMonthDonation: any;
  startDateDonation: Date = new Date(2024, 0, 1);
  endDateDonation: Date = new Date();

  monthListTvPrograms = this.monthList;
  selectedYearTvPrograms: any = '2024';
  selectedMonthTvPrograms: any;
  startDateTvPrograms: Date = new Date(2024, 0, 1);
  endDateTvPrograms: Date = new Date();

  monthListTvSupport = this.monthList;
  selectedYearSupport: any = '2024';
  selectedMonthSupport: any;
  startDateTvSupport: Date = new Date(2024, 0, 1);
  endDateTvSupport: Date = new Date();

  monthArray = months;
  daysMember: any=[];
  constructor(
    private services: DashboardServicesService,
    private datePipe: DatePipe
  ) {}
  getMonthName(monthNumber: any, year: any) {
    if (monthNumber == null) {
      return `${this.calendraDate} to ${this.toDate}`;
    } else {
      const date = new Date();
      date.setMonth(monthNumber - 1);
      let month = date.toLocaleString('en-US', {
        month: 'short',
      });
      return month + ' ' + year;
    }
    // if (monthNumber == null) {

    //   monthNumber = '10-1-2013';
    //   year = '31-12-2024';
    // }
  }

  ngOnInit(): void {
    if (this.selectedYear == new Date().getFullYear()) {
      let month = new Date().getMonth() + 1;
      this.monthList = this.monthList.slice(0, month);
    } else {
      this.monthList = [
        { key: 'JAN', value: 0 },
        { key: 'FEB', value: 1 },
        { key: 'MAR', value: 2 },
        { key: 'APR', value: 3 },
        { key: 'MAY', value: 4 },
        { key: 'JUN', value: 5 },
        { key: 'JUL', value: 6 },
        { key: 'AUG', value: 7 },
        { key: 'SEP', value: 8 },
        { key: 'OCT', value: 9 },
        { key: 'NOV', value: 10 },
        { key: 'DEC', value: 11 },
      ];
    }
    this.getMembersData();

    this.today = JSON.parse(JSON.stringify(this.today)).substring(0, 10);
    this.services.adminDetails().subscribe((results) => {
      this.permissionsList = results.data.permissions;
    });
    // this. = JSON.stringify(this.permissions);
    // console.log(this.permissionsList);

    this.chartOptionsAnalytics = {
      chart: {
        type: 'spline',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: { enabled: false },
      //  subtitle: {
      //     text: "Source: WorldClimate.com"
      //  },
      xAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineWidth: 0,
        lineColor: 'transparent',
        title: {
          text: '',
        },
        labels: {
          style: {
            color: '#5A657F',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
        categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          text: '',
        },
        labels: {
          format: '{value}',
          style: {
            color: '#425466',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
      },
      //  tooltip: {
      //     valueSuffix:" °C"
      //  },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
      },

      colors: ['#0DBC67', '#FF4242'],
      series: [
        {
          name: '>75%',
          data: [10, 15, 20, 35, 45, 50, 55, 65, 70, 90, 100, 110],
        },
        {
          name: '<75%',
          data: [15, 25, 30, 48, 55, 60, 65, 75, 89, 98, 190, 200],
        },
      ],
    };

    if (this.selectedYearService == new Date().getFullYear()) {
      let month = new Date().getMonth() + 1;
      this.monthListService = this.monthListService.slice(0, month);
    } else {
      this.monthListService = [
        { key: 'JAN', value: 0 },
        { key: 'FEB', value: 1 },
        { key: 'MAR', value: 2 },
        { key: 'APR', value: 3 },
        { key: 'MAY', value: 4 },
        { key: 'JUN', value: 5 },
        { key: 'JUL', value: 6 },
        { key: 'AUG', value: 7 },
        { key: 'SEP', value: 8 },
        { key: 'OCT', value: 9 },
        { key: 'NOV', value: 10 },
        { key: 'DEC', value: 11 },
      ];
    }
    this.getServiceData();
    this.serviceChartOptionsAnalytics = {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: { enabled: false },
      //  subtitle: {
      //     text: "Source: WorldClimate.com"
      //  },
      xAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineWidth: 0,
        lineColor: 'transparent',
        title: {
          text: '',
        },
        labels: {
          style: {
            color: '#8492A6',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
        categories: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        ],
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          text: '',
        },
        labels: {
          format: '{value}',
          style: {
            color: '#425466',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
      },
      //  tooltip: {
      //     valueSuffix:" °C"
      //  },
      plotOptions: {
        series: {
          lineWidth: 0.5,
          borderRadius: 5,
          pointWidth: 7,
          marker: {
            enabled: false,
          },
        },
      },

      colors: ['#0074D6', '#FD6A65', '#FFB65C', '#75809C'],
      series: [
        {
          data: [200, 300, 450, 750, 450, 350, 250, 760, 870, 970, 650, 540],
        },
        {
          data: [600, 500, 550, 650, 350, 250, 150, 650, 870, 650, 450, 870],
        },
        {
          data: [400, 560, 450, 550, 550, 650, 760, 760, 870, 890, 960, 650],
        },
        // {
        //   data: [700, 25, 30, 48, 55, 60, 65, 75, 89, 98, 190, 200],
        // },
        // {
        //   data: [900, 15, 20, 35, 45, 50, 55, 65, 70, 90, 100, 110],
        // },
      ],
    };
    if (this.selectedYearPrayer == new Date().getFullYear()) {
      let month = new Date().getMonth() + 1;
      this.monthListPrayer = this.monthListPrayer.slice(0, month);
    } else {
      this.monthListPrayer = [
        { key: 'JAN', value: 0 },
        { key: 'FEB', value: 1 },
        { key: 'MAR', value: 2 },
        { key: 'APR', value: 3 },
        { key: 'MAY', value: 4 },
        { key: 'JUN', value: 5 },
        { key: 'JUL', value: 6 },
        { key: 'AUG', value: 7 },
        { key: 'SEP', value: 8 },
        { key: 'OCT', value: 9 },
        { key: 'NOV', value: 10 },
        { key: 'DEC', value: 11 },
      ];
    }
    this.getPrayerData();
    this.prayerRequestchartOptionsAnalytics = {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: { enabled: false },
      //  subtitle: {
      //     text: "Source: WorldClimate.com"
      //  },
      xAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineWidth: 0,
        lineColor: 'transparent',
        title: {
          text: '',
        },
        labels: {
          style: {
            color: '#8492A6',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
        categories: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        ],
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          text: '',
        },
        labels: {
          format: '{value}',
          style: {
            color: '#425466',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
      },
      //  tooltip: {
      //     valueSuffix:" °C"
      //  },
      plotOptions: {
        series: {
          lineWidth: 1,
          borderRadius: 5,
          pointWidth: 7,
          marker: {
            enabled: false,
          },
        },
      },

      colors: ['#FD6A65', '#0DBC67', '#5A657F', '#FD6A65'],
      series: [
        {
          data: [200, 300, 450, 750, 450, 350, 250, 760, 870, 970, 650, 540],
        },
        {
          data: [600, 500, 550, 650, 350, 250, 150, 650, 870, 650, 450, 870],
        },
        {
          data: [400, 560, 450, 550, 550, 650, 760, 760, 870, 890, 960, 650],
        },
        // {
        //   data: [700, 25, 30, 48, 55, 60, 65, 75, 89, 98, 190, 200],
        // },
        // {
        //   data: [900, 15, 20, 35, 45, 50, 55, 65, 70, 90, 100, 110],
        // },
      ],
    };

    if (this.selectedYearDonation == new Date().getFullYear()) {
      let month = new Date().getMonth() + 1;
      this.monthListDonation = this.monthListDonation.slice(0, month);
    } else {
      this.monthListDonation = [
        { key: 'JAN', value: 0 },
        { key: 'FEB', value: 1 },
        { key: 'MAR', value: 2 },
        { key: 'APR', value: 3 },
        { key: 'MAY', value: 4 },
        { key: 'JUN', value: 5 },
        { key: 'JUL', value: 6 },
        { key: 'AUG', value: 7 },
        { key: 'SEP', value: 8 },
        { key: 'OCT', value: 9 },
        { key: 'NOV', value: 10 },
        { key: 'DEC', value: 11 },
      ];
    }
    this.getDonationData();

    this.DonationchartOptionsAnalytics = {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: { enabled: false },
      //  subtitle: {
      //     text: "Source: WorldClimate.com"
      //  },
      xAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineWidth: 0,
        lineColor: 'transparent',
        title: {
          text: '',
        },
        labels: {
          style: {
            color: '#8492A6',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
        categories: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        ],
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          text: '',
        },
        labels: {
          format: '{value}',
          style: {
            color: '#425466',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
      },
      //  tooltip: {
      //     valueSuffix:" °C"
      //  },
      plotOptions: {
        series: {
          lineWidth: 1,
          borderRadius: 5,
          pointWidth: 7,
          marker: {
            enabled: false,
          },
        },
      },

      colors: ['#0074D6', '#FD6A65', '#FFB65C', '#75809C'],
      series: [
        {
          data: [200, 300, 450, 750, 450, 350, 250, 760, 870, 970, 650, 540],
        },
        {
          data: [600, 500, 550, 650, 350, 250, 150, 650, 870, 650, 450, 870],
        },
        {
          data: [400, 560, 450, 550, 550, 650, 760, 760, 870, 890, 960, 650],
        },
        // {
        //   data: [700, 25, 30, 48, 55, 60, 65, 75, 89, 98, 190, 200],
        // },
        // {
        //   data: [900, 15, 20, 35, 45, 50, 55, 65, 70, 90, 100, 110],
        // },
      ],
    };
    if (this.selectedYearTvPrograms == new Date().getFullYear()) {
      let month = new Date().getMonth() + 1;
      this.monthListTvPrograms = this.monthListTvPrograms.slice(0, month);
    } else {
      this.monthListDonation = [
        { key: 'JAN', value: 0 },
        { key: 'FEB', value: 1 },
        { key: 'MAR', value: 2 },
        { key: 'APR', value: 3 },
        { key: 'MAY', value: 4 },
        { key: 'JUN', value: 5 },
        { key: 'JUL', value: 6 },
        { key: 'AUG', value: 7 },
        { key: 'SEP', value: 8 },
        { key: 'OCT', value: 9 },
        { key: 'NOV', value: 10 },
        { key: 'DEC', value: 11 },
      ];
    }
    this.getTvProgramsData();
    this.tvProgramschartOptionsAnalytics = {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: { enabled: false },
      //  subtitle: {
      //     text: "Source: WorldClimate.com"
      //  },
      xAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineWidth: 0,
        lineColor: 'transparent',
        title: {
          text: '',
        },
        labels: {
          style: {
            color: '#8492A6',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
        categories: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        ],
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          text: '',
        },
        labels: {
          format: '{value}',
          style: {
            color: '#425466',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
      },
      //  tooltip: {
      //     valueSuffix:" °C"
      //  },
      plotOptions: {
        series: {
          lineWidth: 1,
          borderRadius: 5,
          pointWidth: 7,
          marker: {
            enabled: false,
          },
        },
      },

      colors: ['#0074D6', '#FD6A65', '#FFB65C', '#75809C'],
      series: [
        {
          data: this.TvProgramssponsorOpenArray,
        },
        // {
        //   data: [700, 25, 30, 48, 55, 60, 65, 75, 89, 98, 190, 200],
        // },
        // {
        //   data: [900, 15, 20, 35, 45, 50, 55, 65, 70, 90, 100, 110],
        // },
      ],
    };
    if (this.selectedYearSupport == new Date().getFullYear()) {
      let month = new Date().getMonth() + 1;
      this.monthListTvSupport = this.monthListTvSupport.slice(0, month);
    } else {
      this.monthListTvSupport = [
        { key: 'JAN', value: 0 },
        { key: 'FEB', value: 1 },
        { key: 'MAR', value: 2 },
        { key: 'APR', value: 3 },
        { key: 'MAY', value: 4 },
        { key: 'JUN', value: 5 },
        { key: 'JUL', value: 6 },
        { key: 'AUG', value: 7 },
        { key: 'SEP', value: 8 },
        { key: 'OCT', value: 9 },
        { key: 'NOV', value: 10 },
        { key: 'DEC', value: 11 },
      ];
    }
    this.getSupportData();
  }
  download(url: any, downloadName: any) {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = downloadName;
    a.click();
    document.body.removeChild(a);
  }
  membersExportBtn() {
    // this.queryparmsMembers = {
    //   year: this.memberYear ? this.memberYear : '2023',
    // };
    this.services
      .dashboardExportsMembers(this.queryparmsMembers)
      .subscribe((res) => {
        console.log(res);
        this.exportMembers = res.sheetUrl;
        this.download(this.exportMembers, 'sheetUrl');
      });
  }
  serviceExportBtn() {
    this.services.dashboardExportsService(this.serviceQueryParms).subscribe((res) => {
      console.log(res);
      this.exportService = res.sheetUrl;
      this.download(this.exportService, 'sheetUrl');
    });
  }
  prayerExportBtn() {
    this.services.dashboardExportsPrayer(this.prayerReqQueryParms).subscribe((res) => {
      console.log(res);
      this.exportPrayerReq = res.sheetUrl;
      this.download(this.exportPrayerReq, 'sheetUrl');
    });
  }
  donationAmountDataExportBtn() {
    this.DonationDataQueryParms.type = 'amount';
    this.services.dashboardExportsDonationData(this.DonationDataQueryParms).subscribe((res) => {
      console.log(res);
      this.exportDoantionData = res.sheetUrl;
      this.download(this.exportDoantionData, 'sheetUrl');
    });
  }
  donationUserDataExportBtn() {
    this.DonationDataQueryParms.type = 'user'
    this.services.dashboardExportsDonationData(this.DonationDataQueryParms).subscribe((res) => {
      console.log(res);
      this.exportDoantionData = res.sheetUrl;
      this.download(this.exportDoantionData, 'sheetUrl');
    });
  }
  DonorsDataExportBtn() {
    this.DonationDataQueryParms.type = 'amount'
    this.services.dashboardExportsDonationData(this.DonationDataQueryParms).subscribe((res) => {
      console.log(res);
      this.exportDonorsData = res.sheetUrl;
      this.download(this.exportDonorsData, 'sheetUrl');
    });
  }
  TvProgramDataExportBtn(){
    this.TvProgramsQueryParms.type = 'user'
    this.services.dashboardExportTvProgramData(this.TvProgramsQueryParms).subscribe((res) => {
      console.log(res);
      this.exportTvProgramData = res.sheetUrl;
      this.download(this.exportTvProgramData, 'sheetUrl');
    });
  }
  TvProgramDataAmountExportBtn() {
    this.TvProgramsQueryParms.type = 'amount'
    this.services.dashboardExportTvProgramData(this.TvProgramsQueryParms).subscribe((res) => {
      this.exportTvProgramData = res.sheetUrl;
      this.download(this.exportTvProgramData, 'sheetUrl');
    });
  }
  SponsorsDataExportBtn() {
    this.services.dashboardExportSponsorsData().subscribe((res) => {

      this.exportSponsorsData = res.sheetUrl;
      this.download(this.exportSponsorsData, 'sheetUrl');
    });
  }
  supportDataExportBtn() {
    this.services.dashboardExportsSupport(this.supportQueryParms).subscribe((res) => {
      
      this.exportSupport = res.sheetUrl;
      this.download(this.exportSupport, 'sheetUrl');
    });
  }
  resetMemberFilter() {
    if (!this.isClicked) {
      this.isClicked = true;
      this.selectedYear = '2024';
      this.selectedMonth = undefined;
      this.startDateMember = new Date(2024, 0, 1);
      this.endDateMember = new Date();
      this.getMembersData();
      setTimeout(() => {
        this.isClicked = false
      }, 1000);
    }
  }
  selectYear(event: any) {
    if (this.selectedYear == new Date().getFullYear()) {
      let month = new Date().getMonth() + 1;
      this.monthList = this.monthList.slice(0, month);
    } else {
      this.monthList = [
        { key: 'JAN', value: 0 },
        { key: 'FEB', value: 1 },
        { key: 'MAR', value: 2 },
        { key: 'APR', value: 3 },
        { key: 'MAY', value: 4 },
        { key: 'JUN', value: 5 },
        { key: 'JUL', value: 6 },
        { key: 'AUG', value: 7 },
        { key: 'SEP', value: 8 },
        { key: 'OCT', value: 9 },
        { key: 'NOV', value: 10 },
        { key: 'DEC', value: 11 },
      ];
    }
    this.queryparmsMembers = {
      startDate:
        this.startDateMember.getFullYear() +
        '-' +
        (this.startDateMember.getMonth() + 1) +
        '-' +
        this.startDateMember.getDate(),
      endDate:
        this.endDateMember.getFullYear() +
        '-' +
        (this.endDateMember.getMonth() + 1) +
        '-' +
        this.endDateMember.getDate(),
    };
    if (this.selectedMonth == undefined && this.selectedYear != new Date().getFullYear()) {
      this.startDateMember = new Date(Number(this.selectedYear), 0, 1);
      this.endDateMember = new Date(Number(this.selectedYear), 11, 31);
    }else if(this.selectedMonth == undefined && this.selectedYear == new Date().getFullYear()){
      this.startDateMember = new Date(Number(this.selectedYear), 0, 1);
      this.endDateMember = new Date();
    }else{
      this.startDateMember = new Date(Number(this.selectedYear), this.selectedMonth, 1);
      this.endDateMember = new Date(Number(this.selectedYear), this.selectedMonth, this.monthArray[this.selectedMonth]);
    }
    // this.endDateMember = new Date();
    //  this.startDateMember = this.datePipe.transform(this.startDateMember,'dd/MM/yyyy')
    this.getMembersData();
  }

  selectMonth(month: any) {
    this.startDateMember = new Date(
      Number(this.selectedYear),
      this.selectedMonth,
      1
    );
    this.endDateMember = new Date(
      Number(this.selectedYear),
      this.selectedMonth,
      this.monthArray[this.selectedMonth]
    );

    this.getMembersData();
  }

  resetServiceFilter() {
    if (!this.isClicked) {
    this.selectedYearService = '2024';
    this.selectedMonthService = undefined;
    this.startDateService = new Date(2024, 0, 1);
    this.endDateService = new Date();
    this.getServiceData();
    setTimeout(() => {
      this.isClicked = false
    }, 1000);
    }
  }

  selectYearService(event: any) {
    if (this.selectedYearService == new Date().getFullYear()) {
      let month = new Date().getMonth() + 1;
      this.monthListService = this.monthListService.slice(0, month);
    } else {
      this.monthListService = [
        { key: 'JAN', value: 0 },
        { key: 'FEB', value: 1 },
        { key: 'MAR', value: 2 },
        { key: 'APR', value: 3 },
        { key: 'MAY', value: 4 },
        { key: 'JUN', value: 5 },
        { key: 'JUL', value: 6 },
        { key: 'AUG', value: 7 },
        { key: 'SEP', value: 8 },
        { key: 'OCT', value: 9 },
        { key: 'NOV', value: 10 },
        { key: 'DEC', value: 11 },
      ];
    }
    this.serviceQueryParms = {
      startDate:
        this.startDateService.getFullYear() +
        '-' +
        (this.startDateService.getMonth() + 1) +
        '-' +
        this.startDateService.getDate(),
      endDate:
        this.endDateService.getFullYear() +
        '-' +
        (this.endDateService.getMonth() + 1) +
        '-' +
        this.endDateService.getDate(),
    };
    if (this.selectedMonthService == undefined && this.selectedYearService != new Date().getFullYear()) {
      this.startDateService = new Date(Number(this.selectedYearService), 0, 1);
      this.endDateService = new Date(Number(this.selectedYearService), 11, 31);
    }else if(this.selectedMonthService == undefined && this.selectedYearService == new Date().getFullYear()){
      this.startDateService = new Date(Number(this.selectedYearService), 0, 1);
      this.endDateService = new Date();
    }else{
      this.startDateService = new Date(Number(this.selectedYearService), this.selectedMonthService, 1);
      this.endDateService = new Date(Number(this.selectedYearService), this.selectedMonthService, this.monthArray[this.selectedMonthService]);
    }

    this.getServiceData();
  }

  selectMonthService(month: any) {
    this.startDateService = new Date(
      Number(this.selectedYearService),
      this.selectedMonthService,
      1
    );
    this.endDateService = new Date(
      Number(this.selectedYearService),
      this.selectedMonthService,
      this.monthArray[this.selectedMonthService]
    );

    this.getServiceData();
  }

  resetPrayerFilter() {
    if (!this.isClicked) {
    this.selectedYearPrayer = '2024';
    this.selectedMonthPrayer = undefined;
    this.startDatePrayer = new Date(2024, 0, 1);
    this.endDatePrayer = new Date();
    this.getPrayerData();
    setTimeout(() => {
      this.isClicked = false
    }, 1000);
    }
  }

  selectYearPrayer(event: any) {
    if (this.selectedYearPrayer == new Date().getFullYear()) {
      let month = new Date().getMonth() + 1;
      this.monthListPrayer = this.monthListPrayer.slice(0, month);
    } else {
      this.monthListPrayer = [
        { key: 'JAN', value: 0 },
        { key: 'FEB', value: 1 },
        { key: 'MAR', value: 2 },
        { key: 'APR', value: 3 },
        { key: 'MAY', value: 4 },
        { key: 'JUN', value: 5 },
        { key: 'JUL', value: 6 },
        { key: 'AUG', value: 7 },
        { key: 'SEP', value: 8 },
        { key: 'OCT', value: 9 },
        { key: 'NOV', value: 10 },
        { key: 'DEC', value: 11 },
      ];
    }
    this.prayerReqQueryParms = {
      startDate:
        this.startDatePrayer.getFullYear() +
        '-' +
        (this.startDatePrayer.getMonth() + 1) +
        '-' +
        this.startDatePrayer.getDate(),
      endDate:
        this.endDatePrayer.getFullYear() +
        '-' +
        (this.endDatePrayer.getMonth() + 1) +
        '-' +
        this.endDatePrayer.getDate(),
    };
    
    if (this.selectedMonthPrayer == undefined && this.selectedYearPrayer != new Date().getFullYear()) {
      this.startDatePrayer = new Date(Number(this.selectedYearPrayer), 0, 1);
      this.endDatePrayer = new Date(Number(this.selectedYearPrayer), 11, 31);
      console.log('================================' , '1st')
    }else if(this.selectedMonthPrayer == undefined && this.selectedYearPrayer == new Date().getFullYear()){
      this.startDatePrayer = new Date(Number(this.selectedYearPrayer), 0, 1);
      this.endDatePrayer = new Date();
      console.log('================================' , '2nd')
      console.log('====================================');
      console.log(this.startDatePrayer , this.endDatePrayer);
      console.log('====================================');
    }else{
      console.log('================================' , '3rd')
      this.startDatePrayer = new Date(Number(this.selectedYearPrayer), this.selectedMonthPrayer, 1);
      this.endDatePrayer = new Date(Number(this.selectedYearPrayer), this.selectedMonthPrayer, this.monthArray[this.selectedMonthPrayer]);
    }
   
    this.getPrayerData();
  }

  selectMonthPrayer(month: any) {
    this.startDatePrayer = new Date(
      Number(this.selectedYearPrayer),
      this.selectedMonthPrayer,
      1
    );
    this.endDatePrayer = new Date(
      Number(this.selectedYearPrayer),
      this.selectedMonthPrayer,
      this.monthArray[this.selectedMonthPrayer]
    );

    this.getPrayerData();
  }

  resetDonationFilter() {
    this.selectedYearDonation = '2024';
    this.selectedMonthDonation = undefined;
    this.startDateDonation = new Date(2024, 0, 1);
    this.endDateDonation = new Date();
    this.getDonationData();
  }

  selectYearDonation(event: any) {
    if (this.selectedYearDonation == new Date().getFullYear()) {
      let month = new Date().getMonth() + 1;
      this.monthListDonation = this.monthListDonation.slice(0, month);
    } else {
      this.monthListDonation = [
        { key: 'JAN', value: 0 },
        { key: 'FEB', value: 1 },
        { key: 'MAR', value: 2 },
        { key: 'APR', value: 3 },
        { key: 'MAY', value: 4 },
        { key: 'JUN', value: 5 },
        { key: 'JUL', value: 6 },
        { key: 'AUG', value: 7 },
        { key: 'SEP', value: 8 },
        { key: 'OCT', value: 9 },
        { key: 'NOV', value: 10 },
        { key: 'DEC', value: 11 },
      ];
    }
    // this.startDateDonation = new Date(Number(this.selectedYearDonation), 0, 1);
    // this.endDateDonation = new Date(Number(this.selectedYearDonation), 11, 31);
    if (this.selectedMonthDonation == undefined && this.selectedYearDonation != new Date().getFullYear()) {
      this.startDateDonation = new Date(Number(this.selectedYearDonation), 0, 1);
      this.endDateDonation = new Date(Number(this.selectedYearDonation), 11, 31);
    }else if(this.selectedMonthDonation == undefined && this.selectedYearDonation == new Date().getFullYear()){
      this.startDateDonation = new Date(Number(this.selectedYearDonation), 0, 1);
      this.endDateDonation = new Date();
    }else{
      this.startDateDonation = new Date(Number(this.selectedYearDonation), this.selectedMonthDonation, 1);
      this.endDateDonation = new Date(Number(this.selectedYearDonation), this.selectedMonthDonation, this.monthArray[this.selectedMonthDonation]);
    }
    //  this.startDateMember = this.datePipe.transform(this.startDateMember,'dd/MM/yyyy')

    this.getDonationData();
  }

  selectMonthDonation(month: any) {
    this.startDateDonation = new Date(
      Number(this.selectedYearDonation),
      this.selectedMonthDonation,
      1
    );
    this.endDateDonation = new Date(
      Number(this.selectedYearDonation),
      this.selectedMonthDonation,
      this.monthArray[this.selectedMonthDonation]
    );

    this.getDonationData();
  }

  resetTvProgramsFilter() {
    this.selectedYearTvPrograms = '2024';
    this.selectedMonthTvPrograms = undefined;
    this.startDateTvPrograms = new Date(2024, 0, 1);
    this.endDateTvPrograms = new Date();
    this.getTvProgramsData();
  }

  selectYearTvPrograms(event: any) {
    if (this.selectedYearTvPrograms == new Date().getFullYear()) {
      let month = new Date().getMonth() + 1;
      this.monthListTvPrograms = this.monthListTvPrograms.slice(0, month);
    } else {
      this.monthListTvPrograms = [
        { key: 'JAN', value: 0 },
        { key: 'FEB', value: 1 },
        { key: 'MAR', value: 2 },
        { key: 'APR', value: 3 },
        { key: 'MAY', value: 4 },
        { key: 'JUN', value: 5 },
        { key: 'JUL', value: 6 },
        { key: 'AUG', value: 7 },
        { key: 'SEP', value: 8 },
        { key: 'OCT', value: 9 },
        { key: 'NOV', value: 10 },
        { key: 'DEC', value: 11 },
      ];
    }
    this.TvProgramsQueryParms = {
      startDate:
        this.startDateTvPrograms.getFullYear() +
        '-' +
        (this.startDateTvPrograms.getMonth() + 1) +
        '-' +
        this.startDateTvPrograms.getDate(),
      endDate:
        this.endDateTvPrograms.getFullYear() +
        '-' +
        (this.endDateTvPrograms.getMonth() + 1) +
        '-' +
        this.endDateTvPrograms.getDate(),
    };
    
    if (this.selectedMonthTvPrograms == undefined && this.selectedYearTvPrograms != new Date().getFullYear()) {
      this.startDateTvPrograms = new Date(Number(this.selectedYearTvPrograms), 0, 1);
      this.endDateTvPrograms = new Date(Number(this.selectedYearTvPrograms), 11, 31);
    }else if(this.selectedMonthTvPrograms == undefined && this.selectedYearTvPrograms == new Date().getFullYear()){
      this.startDateTvPrograms = new Date(Number(this.selectedYearTvPrograms), 0, 1);
      this.endDateTvPrograms = new Date();
    }else{
      this.startDateTvPrograms = new Date(Number(this.selectedYearTvPrograms), this.selectedMonthTvPrograms, 1);
      this.endDateTvPrograms = new Date(Number(this.selectedYearTvPrograms), this.selectedMonthTvPrograms, this.monthArray[this.selectedMonthTvPrograms]);
    }
    //  this.startDateMember = this.datePipe.transform(this.startDateMember,'dd/MM/yyyy')

    this.getTvProgramsData();
  }

  selectMonthTvPrograms(month: any) {
    this.startDateMember = new Date(
      Number(this.selectedYearTvPrograms),
      this.selectedMonthTvPrograms,
      1
    );
    this.endDateMember = new Date(
      Number(this.selectedYearTvPrograms),
      this.selectedMonthTvPrograms,
      this.monthArray[this.selectedMonthTvPrograms]
    );

    this.getTvProgramsData();
  }

  resetSupportFilter() {
    if (!this.isClicked) {
    this.selectedYearSupport = '2024';
    this.selectedMonthSupport = undefined;
    this.startDateTvSupport = new Date(2024, 0, 1);
    this.endDateTvSupport = new Date();
    this.getSupportData();
    setTimeout(() => {
      this.isClicked = false
    }, 1000);
    }
  }

  selectYearSupport(event: any) {
    if (this.selectedYearSupport == new Date().getFullYear()) {
      let month = new Date().getMonth() + 1;
      this.monthListTvSupport = this.monthListTvSupport.slice(0, month);
    } else {
      this.monthListTvSupport = [
        { key: 'JAN', value: 0 },
        { key: 'FEB', value: 1 },
        { key: 'MAR', value: 2 },
        { key: 'APR', value: 3 },
        { key: 'MAY', value: 4 },
        { key: 'JUN', value: 5 },
        { key: 'JUL', value: 6 },
        { key: 'AUG', value: 7 },
        { key: 'SEP', value: 8 },
        { key: 'OCT', value: 9 },
        { key: 'NOV', value: 10 },
        { key: 'DEC', value: 11 },
      ];
    }
    if (this.selectedMonthSupport == undefined && this.selectedYearSupport != new Date().getFullYear()) {
      this.startDateTvSupport = new Date(Number(this.selectedYearSupport), 0, 1);
      this.endDateTvSupport = new Date(Number(this.selectedYearSupport), 11, 31);
    }else if(this.selectedMonthSupport == undefined && this.selectedYearSupport == new Date().getFullYear()){
      this.startDateTvSupport = new Date(Number(this.selectedYearSupport), 0, 1);
      this.endDateTvSupport = new Date();
    }else{
      this.startDateTvSupport = new Date(Number(this.selectedYearSupport), this.selectedMonthSupport, 1);
      this.endDateTvSupport = new Date(Number(this.selectedYearSupport), this.selectedMonthSupport, this.monthArray[this.selectedMonthSupport]);
    }
    // this.startDateTvSupport = new Date(Number(this.selectedYearSupport), 0, 1);
    // this.endDateTvSupport = new Date();

    this.getSupportData();
  }

  selectMonthSupport(month: any) {
    this.startDateTvSupport = new Date(
      Number(this.selectedYearSupport),
      this.selectedMonthSupport,
      1
    );
    this.endDateTvSupport = new Date(
      Number(this.selectedYearSupport),
      this.selectedMonthSupport,
      this.monthArray[this.selectedMonthSupport]
    );

    this.getSupportData();
  }

  onChangeDate(e: any) {
    this.selectedMonth = '';
    // this.selectedYear = '';

    this.dropDownSelection = e.target.value;
    this.afterMaleMembersArray = [];
    this.afterFemaleMembersArray = [];
    this.TotalMonths = [];
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    this.startDateMember = new Date(e.target.value);
    this.endDateMember = new Date(e.target.value);
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getMembersData();
    }
  }

  getMembersData() {
    this.TotalMonths = [];
    this.afterFemaleMembersArray = [];
    this.afterMaleMembersArray = [];
    this.maleMembers = [];
    this.afterMaleMembersDetails = [];
    this.queryparmsMembers = {
      startDate:
        this.startDateMember.getFullYear() +
        '-' +
        (this.startDateMember.getMonth() + 1) +
        '-' +
        this.startDateMember.getDate(),
      endDate:
        this.endDateMember.getFullYear() +
        '-' +
        (this.endDateMember.getMonth() + 1) +
        '-' +
        this.endDateMember.getDate(),
    };
    if (this.selectedMonth != undefined) {
      this.queryparmsMembers['groupBy'] = 'day';
    }
    if (
      this.startDateMember.getFullYear() == this.endDateMember.getFullYear() &&
      this.startDateMember.getMonth() == this.endDateMember.getMonth() &&
      this.startDateMember.getDate() != this.endDateMember.getDate()

    ) {
      this.queryparmsMembers['groupBy'] = 'day';
    }
    if (
      this.startDateMember.getFullYear() == this.endDateMember.getFullYear() &&
      this.startDateMember.getMonth() == this.endDateMember.getMonth() &&
      this.startDateMember.getDate() == this.endDateMember.getDate()
    ) {
      this.queryparmsMembers['groupBy'] = 'hour';
    }
    // if(this.startDateMember.getFullYear() == this.endDateMember.getFullYear() &&
    // this.startDateMember.getMonth() == this.endDateMember.getMonth() &&
    // this.startDateMember.getDate() == this.endDateMember.getDate()){
    //   this.queryparmsMembers['groupBy'] = 'hour';
    // }
    this.services
      .dashboardMembers(this.queryparmsMembers)
      .subscribe((result) => {
        console.log(result);
        this.maleMembers = result.data;
        this.totalRecords = result.totalRecords;
        this.totalMale = result.totalMale;
        this.totalFemale = result.totalFemale;
        this.afterMaleMembersDetails = this.maleMembers.forEach((item: any) => {
          this.afterMaleMembersArray.push(item.male);
          this.afterFemaleMembersArray.push(item.female);
          const day = item.date?.split('-')
          const dateText = item.day ? item.day + '-' : '';
          if (item.date != undefined && item.hour == undefined) {
            this.TotalMonths.push(day[2] + '-' + item.month)
          }else if(item.date != undefined && item.hour != undefined){
            this.TotalMonths.push(item.hour + 1 + ':00')
          }else{
            this.TotalMonths.push(dateText + item.month + '-' + item.year);
          }
        });
        this.chartOptions = {
          chart: {
            type: 'spline',
          },
          title: {
            text: '',
          },
          credits: {
            enabled: false,
          },
          legend: { enabled: false },
          //  subtitle: {
          //     text: "Source: WorldClimate.com"
          //  },
          xAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0,
            lineColor: 'transparent',
            title: {
              text: '',
            //   style: {
            //     color: '#425466',
            //     fontSize: 16,
            //     fontWeight: 600,
            //     fontFamily: 'Inter', // Set the font size of the title
            // }
            },
            labels: {
              style: {
                color: '#5A657F',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
            categories: this.TotalMonths,
          },
          yAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            title: {
              text: '',
            //   style: {
            //     color: '#425466',
            //     fontSize: 16,
            //     fontWeight: 600,
            //     fontFamily: 'Inter',
            // }
            },
            labels: {
              style: {
                color: '#425466',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
          },
          //  tooltip: {
          //     valueSuffix:" °C"
          //  },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
              },
            },
          },

          colors: ['#0DBC67', '#FF4242'],
          series: [
            {
              name: 'Male',
              data: this.afterMaleMembersArray,
            },
            {
              name: 'Female',
              data: this.afterFemaleMembersArray,
            },
          ],
        };
      });
  }
  onChangeToDate(e: any) {
    this.dropDownSelection = e.target.value;
    this.afterMaleMembersArray = [];
    this.afterFemaleMembersArray = [];
    this.TotalMonths = [];
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    this.endDateMember = new Date(e.target.value);
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getMembersData();
    }
  }
  onChangeServiceDate(e: any) {
    this.dropDownSelectionService = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelectionService;
    let ds = this.dropDownSelectionService;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDateService = date.format('YYYY-MM-DD');
    this.startDateService = new Date(e.target.value);
    this.endDateService = new Date(e.target.value);
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getServiceData();
    }
  }

  getServiceData() {
    this.serviceMembers = [];
    // this.totalService = 0;
    this.serviceMembersCharts = [];
    this.serviceCreatedArray = [];
    this.serviceCompletedArray = [];
    this.serviceMonths = [];

    this.serviceQueryParms = {
      startDate:
        this.startDateService.getFullYear() +
        '-' +
        (this.startDateService.getMonth() + 1) +
        '-' +
        this.startDateService.getDate(),
      endDate:
        this.endDateService.getFullYear() +
        '-' +
        (this.endDateService.getMonth() + 1) +
        '-' +
        this.endDateService.getDate(),
    };
    if (this.selectedMonthService != undefined) {
      this.serviceQueryParms['groupBy'] = 'day';
    }
    if (
      this.startDateService.getFullYear() == this.endDateService.getFullYear() &&
      this.startDateService.getMonth() == this.endDateService.getMonth() &&
      this.startDateService.getDate() != this.endDateService.getDate()

    ) {
      this.serviceQueryParms['groupBy'] = 'day';
    }
    if (
      this.startDateService.getFullYear() == this.endDateService.getFullYear() &&
      this.startDateService.getMonth() == this.endDateService.getMonth() &&
      this.startDateService.getDate() == this.endDateService.getDate()

    ) {
      this.serviceQueryParms['groupBy'] = 'hour';
    }
   

    this.services
      .dashboardService(this.serviceQueryParms)
      .subscribe((result) => {
        console.log(result);
        this.serviceMembers = result.data;
        this.totalService = result.totalService;
        this.activeRequest = result.activeRequest;
        this.completedRequest = result.completedRequest;
        this.cancelledRequest = result.cancelledRequest;
        this.serviceMembersCharts = this.serviceMembers.forEach((item: any) => {
          this.serviceCreatedArray.push(item.created);
          this.serviceCompletedArray.push(item.completed);
          const day = item.date?.split('-')
          const dateText = item.day ? item.day + '-' : '';
          // const dateText = item.day ? item.day + '-' : '';
          if (item.date != undefined && item.hour == undefined) {
            this.serviceMonths.push(day[2] + '-' + item.month)
          }else if(item.date != undefined && item.hour != undefined){
            this.serviceMonths.push(item.hour + 1 + ':00')
          }else{
            this.serviceMonths.push(dateText + item.month + '-' + item.year);
          }
          // this.serviceMonths.push(dateText + item.month + '-' + item.year);
        });
        this.serviceRequestCharts = {
          chart: {
            type: 'spline',
          },
          title: {
            text: '',
          },
          credits: {
            enabled: false,
          },
          legend: { enabled: false },
          //  subtitle: {
          //     text: "Source: WorldClimate.com"
          //  },
          xAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0,
            lineColor: 'transparent',
            title: {
              text: '',
            },
            labels: {
              style: {
                color: '#5A657F',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
            categories: this.serviceMonths,
          },
          yAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            title: {
              text: '',
            },
            labels: {
              format: '{value}',
              style: {
                color: '#425466',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
          },
          //  tooltip: {
          //     valueSuffix:" °C"
          //  },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
              },
            },
          },

          colors: ['#0DBC67', '#FF4242'],
          series: [
            {
              name: 'Created',
              data: this.serviceCreatedArray,
            },
            {
              name: 'Completed',
              data: this.serviceCompletedArray,
            },
          ],
        };
      });
  }
  onChangeServiceToDate(e: any) {
    this.dropDownSelectionService = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelectionService;
    let ds = this.dropDownSelectionService;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDateService = date.format('YYYY-MM-DD');
    this.endDateService = new Date(e.target.value);
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getServiceData();
    }
  }
  onChangePrayerDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
    this.startDatePrayer = new Date(e.target.value);
    this.endDatePrayer = new Date(e.target.value);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getPrayerData();
    }
  }

  getPrayerData() {
    this.prayerMembers = [];
    this.PrayerRequestMembersCharts = [];
    this.prayerReqClosedArray = [];
    this.prayerReqOpenArray = [];
    this.prayerReqMonths = [];

    this.prayerReqQueryParms = {
      startDate:
        this.startDatePrayer.getFullYear() +
        '-' +
        (this.startDatePrayer.getMonth() + 1) +
        '-' +
        this.startDatePrayer.getDate(),
      endDate:
        this.endDatePrayer.getFullYear() +
        '-' +
        (this.endDatePrayer.getMonth() + 1) +
        '-' +
        this.endDatePrayer.getDate(),
    };

    if (this.selectedMonthPrayer != undefined) {
      this.prayerReqQueryParms['groupBy'] = 'day';
    }

    if (
      this.startDatePrayer.getFullYear() == this.endDatePrayer.getFullYear() &&
      this.startDatePrayer.getMonth() == this.endDatePrayer.getMonth() &&
      this.startDatePrayer.getDate() != this.endDatePrayer.getDate()

    ) {
      this.prayerReqQueryParms['groupBy'] = 'day';
    }
    if (
      this.startDatePrayer.getFullYear() == this.endDatePrayer.getFullYear() &&
      this.startDatePrayer.getMonth() == this.endDatePrayer.getMonth() &&
      this.startDatePrayer.getDate() == this.endDatePrayer.getDate()

    ) {
      this.prayerReqQueryParms['groupBy'] = 'hour';
    }

    this.services
      .dashboardPrayer(this.prayerReqQueryParms)
      .subscribe((result) => {
        // console.log(result);
        this.prayerMembers = result.data;
        this.totalPrayerRequest = result.totalRecords;
        this.activePrayerRequest = result.active;
        this.completedPrayerRequest = result.completed;
        this.cancelledPrayerRequest = result.cancelled;
        this.PrayerRequestMembersCharts = this.prayerMembers.forEach(
          (item: any) => {
            this.prayerReqOpenArray.push(item.created);
            this.prayerReqClosedArray.push(item.completed);
            const day = item.date?.split('-')
            const dateText = item.day ? item.day + '-' : '';
            // const dateText = item.day ? item.day + '-' : '';
            if (item.date != undefined && item.hour == undefined) {
              this.prayerReqMonths.push(day[2] + '-' + item.month)
            }else if(item.date != undefined && item.hour != undefined){
              this.prayerReqMonths.push(item.hour + 1 + ':00')
            }else{
              this.prayerReqMonths.push(dateText + item.month + '-' + item.year);
            }
          }
        );
        // debugger;
        this.prayerRequestchartOptions = {
          chart: {
            type: 'spline',
          },
          title: {
            text: '',
          },
          legend: { enabled: false },

          credits: {
            enabled: false,
          },
          //  subtitle: {
          //     text: "Source: WorldClimate.com"
          //  },
          xAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0,
            lineColor: 'transparent',
            title: {
              text: '',
            },
            labels: {
              style: {
                color: '#5A657F',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
            categories: this.prayerReqMonths,
          },
          yAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            title: {
              text: '',
            },
            labels: {
              format: '{value}',
              style: {
                color: '#425466',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
          },
          //  tooltip: {
          //     valueSuffix:" °C"
          //  },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
              },
            },
          },

          colors: ['#0DBC67', '#FF4242'],
          series: [
            {
              name: 'Open',
              data: this.prayerReqOpenArray,
            },
            {
              name: 'Closed',
              data: this.prayerReqClosedArray,
            },
          ],
        };
      });
  }

  onChangePrayerToDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    this.endDatePrayer = new Date(e.target.value);
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getPrayerData();
    }
  }

  onChangeDonationsDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    this.startDateDonation = new Date(e.target.value);
    this.endDateDonation = new Date(e.target.value);
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getDonationData();
    }
  }

  getDonationData() {
    this.DonationDataOpenArray = [];
    this.DonationDataTotalUsers = [];
    this.DonationDataMembersMonths = [];
    this.DonationDataMembers = [];
    this.DonationDataMembersCharts = [];

    this.DonationDataQueryParms = {
      startDate:
        this.startDateDonation.getFullYear() +
        '-' +
        (this.startDateDonation.getMonth() + 1) +
        '-' +
        this.startDateDonation.getDate(),
      endDate:
        this.endDateDonation.getFullYear() +
        '-' +
        (this.endDateDonation.getMonth() + 1) +
        '-' +
        this.endDateDonation.getDate(),
    };

    if (this.selectedMonthDonation != undefined) {
      this.DonationDataQueryParms['groupBy'] = 'day';
    }
    
    if (
      
      this.startDateDonation.getFullYear() == this.endDateDonation.getFullYear() &&
      this.startDateDonation.getMonth() == this.endDateDonation.getMonth() &&
      this.startDateDonation.getDate() != this.endDateDonation.getDate()
      
      ) {
        
      this.DonationDataQueryParms['groupBy'] = 'day';
    }
    if (
      this.startDateDonation.getFullYear() == this.endDateDonation.getFullYear() &&
      this.startDateDonation.getMonth() == this.endDateDonation.getMonth() &&
      this.startDateDonation.getDate() == this.endDateDonation.getDate()

    ) {
      this.DonationDataQueryParms['groupBy'] = 'hour';
    }

    this.services
      .dashboardDonation(this.DonationDataQueryParms)
      .subscribe((result) => {
        console.log(result);
        this.DonationDataMembers = result.data;
        this.completedDonationData = result.totalRecords[0]?.totalDonors;
        this.totalDonationData = result.totalRecords[0]?.totalDonations;
        this.activeDonationData = result.totalRecords[0]?.donations;
        this.DonationDataMembersCharts = this.DonationDataMembers.forEach(
          (item: any) => {
            this.DonationDataOpenArray.push(item.amount);
            this.DonationDataTotalUsers.push(item.totalUser);
            const day = item.date?.split('-')
            const dateText = item.day ? item.day + '-' : '';
            // const dateText = item.day ? item.day + '-' : '';
            if (item.date != undefined && item.hour == undefined) {
              this.DonationDataMembersMonths.push(day[2] + '-' + item.month)
            }else if(item.date != undefined && item.hour != undefined){
              this.DonationDataMembersMonths.push(item.hour + 1 + ':00')
            }else{
              this.DonationDataMembersMonths.push(dateText + item.month + '-' + item.year);
            }
          }
        );

        this.donationchartOptions = {
          chart: {
            type: 'spline',
          },
          title: {
            text: '',
          },
          legend: { enabled: false },

          credits: {
            enabled: false,
          },
          //  subtitle: {
          //     text: "Source: WorldClimate.com"
          //  },
          xAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0,
            lineColor: 'transparent',
            title: {
              text: '',
            },
            labels: {
              style: {
                color: '#5A657F',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
            categories: this.DonationDataMembersMonths,
          },
          yAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            title: {
              text: '',
            },
            labels: {
              format: '{value}',
              style: {
                color: '#425466',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
          },
          //  tooltip: {
          //     valueSuffix:" °C"
          //  },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
              },
            },
          },

          colors: ['#0DBC67'],
          series: [
            {
              name:'amount',
              data: this.DonationDataOpenArray,
            },
          ],
        };
        this.donationsecondchartOptions = {
          chart: {
            type: 'spline',
          },
          title: {
            text: '',
          },
          legend: { enabled: false },

          credits: {
            enabled: false,
          },
          //  subtitle: {
          //     text: "Source: WorldClimate.com"
          //  },
          xAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0,
            lineColor: 'transparent',
            title: {
              text: '',
            },
            labels: {
              style: {
                color: '#5A657F',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
            categories: this.DonationDataMembersMonths,
          },
          yAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            title: {
              text: '',
            },
            labels: {
              format: '{value}',
              style: {
                color: '#425466',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
          },
          //  tooltip: {
          //     valueSuffix:" °C"
          //  },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
              },
            },
          },

          colors: ['#0DBC67'],
          series: [
            {
              name:'user',
              data: this.DonationDataTotalUsers,
            },
          ],
        };
      });
  }
  onChangeDonationsToDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    this.endDateDonation = new Date(e.target.value);
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getDonationData();
    }
  }
  onChangeTvProgramDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    this.startDateTvPrograms = new Date(e.target.value);
    this.endDateTvPrograms = new Date(e.target.value);
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getTvProgramsData();
    }
  }

  getTvProgramsData() {
    this.TvProgramsOpenArray = [];
    this.TvProgramsMembersMonths = [];
    this.TvProgramsMembers = [];
    this.TvProgramssponsor = [];
    this.TvProgramsMembersCharts = [];
    this.TvProgramssponsorOpenArray = [];
    this.TvProgramssponsorMembersMonths = [];
    this.TvProgramsQueryParms = {
      startDate:
        this.startDateTvPrograms.getFullYear() +
        '-' +
        (this.startDateTvPrograms.getMonth() + 1) +
        '-' +
        this.startDateTvPrograms.getDate(),
      endDate:
        this.endDateTvPrograms.getFullYear() +
        '-' +
        (this.endDateTvPrograms.getMonth() + 1) +
        '-' +
        this.endDateTvPrograms.getDate(),
    };
    

    if (this.selectedMonthTvPrograms != undefined) {
      this.TvProgramsQueryParms['groupBy'] = 'day';
    }

    if (
      this.startDateTvPrograms.getFullYear() == this.endDateTvPrograms.getFullYear() &&
      this.startDateTvPrograms.getMonth() == this.endDateTvPrograms.getMonth() &&
      this.startDateTvPrograms.getDate() != this.endDateTvPrograms.getDate()

    ) {
      this.TvProgramsQueryParms['groupBy'] = 'day';
    }
    if (
      this.startDateTvPrograms.getFullYear() == this.endDateTvPrograms.getFullYear() &&
      this.startDateTvPrograms.getMonth() == this.endDateTvPrograms.getMonth() &&
      this.startDateTvPrograms.getDate() == this.endDateTvPrograms.getDate()

    ) {
      this.TvProgramsQueryParms['groupBy'] = 'hour';
    }
    this.services
      .dashboardtvProgram(this.TvProgramsQueryParms)
      .subscribe((result) => {
        console.log(result);
        this.TvProgramsMembers = result.data ? result.data : [];
        this.TvProgramssponsor = result.sponsor ? result.sponsor : [];
        this.totalTvPrograms = result.totalSponsorship[0]?.totalSponsorship;
        this.activeTvPrograms = result.totalSponsorship[0]?.tvProgram;
        this.completedTvPrograms = result.totalSponsorship[0]?.totalSponsor;
        // this.activeTvPrograms = result.totalProgram;
        // this.completedTvPrograms = result.totalSponsor;
        this.TvProgramsMembersCharts = this.TvProgramsMembers.forEach(
          (item: any) => {
            this.TvProgramsOpenArray.push(item.amount);
            const day = item.date?.split('-')
            const dateText = item.day ? item.day + '-' : '';
            // const dateText = item.day ? item.day + '-' : '';
            // this.TvProgramsMembersMonths.push(
            //   dateText + item.month + '-' + item.year
            // );
            if (item.date != undefined && item.hour == undefined) {
              this.TvProgramsMembersMonths.push(day[2] + '-' + item.month)
            }else if(item.date != undefined && item.hour != undefined){
              this.TvProgramsMembersMonths.push(item.hour + 1 + ':00')
            }else{
              this.TvProgramsMembersMonths.push(dateText + item.month + '-' + item.year);
            }
          }
        );
        this.TvProgramsMembersCharts = this.TvProgramsMembers.forEach(
          (item: any) => {
            this.TvProgramssponsorOpenArray.push(item.totalUser);
            const day = item.date?.split('-')
            const dateText = item.day ? item.day + '-' : '';
            // const dateText = item.day ? item.day + '-' : '';
            if (item.date != undefined && item.hour == undefined) {
              this.TvProgramssponsorMembersMonths.push(day[2] + '-' + item.month)
            }else if(item.date != undefined && item.hour != undefined){
              this.TvProgramssponsorMembersMonths.push(item.hour + 1 + ':00')
            }else{
              this.TvProgramssponsorMembersMonths.push(dateText + item.month + '-' + item.year);
            }
            // this.TvProgramssponsorMembersMonths.push(
            //   dateText + item.month + '-' + item.year
            // );
          }
        );
        this.tvProgramschartOptions = {
          chart: {
            type: 'spline',
          },
          title: {
            text: '',
          },
          legend: { enabled: false },

          credits: {
            enabled: false,
          },
          //  subtitle: {
          //     text: "Source: WorldClimate.com"
          //  },
          xAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0,
            lineColor: 'transparent',
            title: {
              text: '',
            },
            labels: {
              style: {
                color: '#5A657F',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
            categories: this.TvProgramsMembersMonths,
          },
          yAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            title: {
              text: '',
            },
            labels: {
              format: '{value}',
              style: {
                color: '#425466',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
          },
          //  tooltip: {
          //     valueSuffix:" °C"
          //  },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
              },
            },
          },

          colors: ['#0DBC67'],
          series: [
            {
              name:'amount ',
              data: this.TvProgramsOpenArray,
            },
          ],
        };
        this.tvProgramsSponsorchartOptions = {
          chart: {
            type: 'spline',
          },
          title: {
            text: '',
          },
          legend: { enabled: false },

          credits: {
            enabled: false,
          },
          //  subtitle: {
          //     text: "Source: WorldClimate.com"
          //  },
          xAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0,
            lineColor: 'transparent',
            title: {
              text: '',
            },
            labels: {
              style: {
                color: '#5A657F',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
            categories: this.TvProgramssponsorMembersMonths,
          },
          yAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            title: {
              text: '',
            },
            labels: {
              format: '{value}',
              style: {
                color: '#425466',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
          },
          //  tooltip: {
          //     valueSuffix:" °C"
          //  },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
              },
            },
          },

          colors: ['#0DBC67'],
          series: [
            {
              name:'user ',
              data: this.TvProgramssponsorOpenArray,
            },
          ],
        };
      });
  }

  onChangeTvProgramToDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    this.endDateTvPrograms = new Date(e.target.value);
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getTvProgramsData();
    }
  }
  onChangeSupportDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    this.startDateTvSupport = new Date(e.target.value);
    this.endDateTvSupport = new Date(e.target.value);
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getSupportData();
    }
  }

  getSupportData() {
    this.supportOpenArray = [];
    this.supportdArray = [];
    this.supportMembersMonths = [];
    this.supportMembers = [];
    this.supportMembersCharts = [];

    this.supportQueryParms = {
      startDate:
        this.startDateTvSupport.getFullYear() +
        '-' +
        (this.startDateTvSupport.getMonth() + 1) +
        '-' +
        this.startDateTvSupport.getDate(),
      endDate:
        this.endDateTvSupport.getFullYear() +
        '-' +
        (this.endDateTvSupport.getMonth() + 1) +
        '-' +
        this.endDateTvSupport.getDate(),
    };
    if (this.selectedMonthSupport != undefined) {
      this.supportQueryParms['groupBy'] = 'day';
    }

    if (
      this.startDateTvSupport.getFullYear() == this.endDateTvSupport.getFullYear() &&
      this.startDateTvSupport.getMonth() == this.endDateTvSupport.getMonth() &&
      this.startDateTvSupport.getDate() != this.endDateTvSupport.getDate()

    ) {
      this.supportQueryParms['groupBy'] = 'day';
    }
    if (
      this.startDateTvSupport.getFullYear() == this.endDateTvSupport.getFullYear() &&
      this.startDateTvSupport.getMonth() == this.endDateTvSupport.getMonth() &&
      this.startDateTvSupport.getDate() == this.endDateTvSupport.getDate()

    ) {
      this.supportQueryParms['groupBy'] = 'hour';
    }
    // if (this.selectedMonthSupport != undefined) {
    //   this.supportQueryParms['groupBy'] = 'day';
    // }

    // if (
    //   this.startDateTvSupport.getFullYear() ==
    //     this.endDateTvSupport.getFullYear() &&
    //   this.startDateTvSupport.getMonth() == this.endDateTvSupport.getMonth()
    // ) {
    //   this.supportQueryParms['groupBy'] = 'day';
    // }

    this.services
      .dashboardSupport(this.supportQueryParms)
      .subscribe((result) => {
        console.log(result);
        this.supportMembers = result.data;
        this.totalsupport = result.totalRecords;
        this.activesupport = result.active;
        this.completedsupport = result.completed;
        this.cancelledsupport = result.cancelled;
        this.supportMembersCharts = this.supportMembers.forEach((item: any) => {
          this.supportOpenArray.push(item.open);
          this.supportdArray.push(item.closed);
          const day = item.date?.split('-')
          const dateText = item.day ? item.day + '-' : '';
          // const dateText = item.day ? item.day + '-' : '';

          if (item.date != undefined && item.hour == undefined) {
            this.supportMembersMonths.push(day[2] + '-' + item.month)
          }else if(item.date != undefined && item.hour != undefined){
            this.supportMembersMonths.push(item.hour + 1 + ':00')
          }else{
            this.supportMembersMonths.push(dateText + item.month + '-' + item.year);
          }
          // this.supportMembersMonths.push(
          //   dateText + item.month + '-' + item.year
          // );
        });
        this.supportchartOptions = {
          chart: {
            type: 'spline',
          },
          title: {
            text: '',
          },
          legend: { enabled: false },

          credits: {
            enabled: false,
          },
          //  subtitle: {
          //     text: "Source: WorldClimate.com"
          //  },
          xAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0,
            lineColor: 'transparent',
            title: {
              text: '',
            },
            labels: {
              style: {
                color: '#5A657F',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
            categories: this.supportMembersMonths,
          },
          yAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            title: {
              text: '',
            },
            labels: {
              format: '{value}',
              style: {
                color: '#425466',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
          },
          //  tooltip: {
          //     valueSuffix:" °C"
          //  },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
              },
            },
          },

          colors: ['#ff8d00', '#0DBC67'],
          series: [
            {
              name: 'Open',
              data: this.supportOpenArray,
            },
            {
              name: 'Closed',
              data: this.supportdArray,
            },
          ],
        };
      });
  }

  onChangeSuppportToDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
    this.endDateTvSupport = new Date(e.target.value);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getSupportData();
    }
  }

  valueDropdown(event: any) {
    console.log('event', event);
    console.log(this.calendraDate);
    console.log(this.toDate);
    if (event.value == 'Month') {
      if (this.calendraDate && this.toDate) {
        const startMonth = new Date(this.calendraDate).getMonth() + 1;
        const endMonth = new Date(this.calendraDate).getMonth() + 1;
        if (startMonth == endMonth) {
          this.resetChartVariable();

          this.queryparmsMembers = {
            year: new Date(this.calendraDate).getFullYear().toString(),
            month: startMonth,
          };

          this.services
            .dashboardMembers(this.queryparmsMembers)
            .subscribe((result) => {
              console.log('resssssssssss change Month ', result);
              this.maleMembers = result.message;
              this.totalRecords = result.totalRecords;
              this.totalMale = result.totalMale;
              this.totalFemale = result.totalFemale;
              this.afterMaleMembersDetails = this.maleMembers.forEach(
                (item: any) => {
                  this.afterMaleMembersArray.push(item.male);
                  this.afterFemaleMembersArray.push(item.female);
                  this.TotalMonths.push(item.month + '-' + item.year);
                }
              );
              console.log('months', this.TotalMonths);

              this.chartValueSetBaseOnFilter();
            });
        } else if (this.calendraDate || this.toDate) {
          this.resetChartVariable();
          const startMonth = this.calendraDate
            ? new Date(this.calendraDate).getMonth() + 1
            : new Date(this.toDate).getMonth() + 1;
          this.queryparmsMembers = {
            year: this.calendraDate
              ? new Date(this.calendraDate).getFullYear().toString()
              : new Date(this.toDate).getFullYear().toString(),
            month: startMonth,
          };

          this.services
            .dashboardMembers(this.queryparmsMembers)
            .subscribe((result) => {
              console.log('resssssssssss change Month ', result);

              this.maleMembers = result.message;
              this.totalRecords = result.totalRecords;
              this.totalMale = result.totalMale;
              this.totalFemale = result.totalFemale;
              this.afterMaleMembersDetails = this.maleMembers.forEach(
                (item: any) => {
                  this.afterMaleMembersArray.push(item.male);
                  this.afterFemaleMembersArray.push(item.female);
                  this.TotalMonths.push(item.month + '-' + item.year);
                }
              );
              console.log('months', this.TotalMonths);

              this.chartValueSetBaseOnFilter();
            });
        } else {
        }
        console.log('if if if if', startMonth);
        console.log('if if if f', endMonth);
      } else {
        const startMonth = new Date().getMonth() + 1;
        console.log('else else ', startMonth);

        this.resetChartVariable();

        this.queryparmsMembers = {
          year: new Date().getFullYear().toString(),
          month: startMonth,
        };

        this.services
          .dashboardMembers(this.queryparmsMembers)
          .subscribe((result) => {
            console.log('resssssssssss change Month ', result);

            this.maleMembers = result.message;
            this.totalRecords = result.totalRecords;
            this.totalMale = result.totalMale;
            this.totalFemale = result.totalFemale;
            this.afterMaleMembersDetails = this.maleMembers.forEach(
              (item: any) => {
                this.afterMaleMembersArray.push(item.male);
                this.afterFemaleMembersArray.push(item.female);
                this.TotalMonths.push(item.month + '-' + item.year);
              }
            );
            console.log('months', this.TotalMonths);

            this.chartValueSetBaseOnFilter();
          });
      }
    }
  }

  chartValueSetBaseOnFilter() {
    this.chartOptions = {
      chart: {
        type: 'spline',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: { enabled: false },
      xAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineWidth: 0,
        lineColor: 'transparent',
        title: {
          text: '',
        },
        labels: {
          style: {
            color: '#5A657F',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
        categories: this.TotalMonths,
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          text: '',
        },
        labels: {
          format: '{value}',
          style: {
            color: '#425466',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
      },

      colors: ['#0DBC67', '#FF4242'],
      series: [
        {
          name: 'Male',
          data: this.afterMaleMembersArray,
        },
        {
          name: 'Female',
          data: this.afterFemaleMembersArray,
        },
      ],
    };
  }
  resetChartVariable() {
    this.maleMembers = null;
    this.totalRecords = null;
    this.totalMale = null;
    this.totalFemale = null;
    this.afterMaleMembersArray = [];
    this.afterFemaleMembersArray = [];
    this.TotalMonths = [];
    this.chartOptions = {};
    this.queryparmsMembers = {};
  }
}
