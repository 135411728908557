import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardServicesService } from 'src/app/_services/dashboard-services.service';
import { MarkAsClosedcommonDialogComponent } from 'src/app/mark-as-closedcommon-dialog/mark-as-closedcommon-dialog.component';
import { MarkAsCompletecommonDialogComponent } from 'src/app/mark-as-completecommon-dialog/mark-as-completecommon-dialog.component';
declare const audioFile: any;

@Component({
  selector: 'app-view-request',
  templateUrl: './view-request.component.html',
  styleUrls: ['./view-request.component.css'],
})
export class ViewRequestComponent implements OnInit {
  userDetails = [
    { key: 'Mobile No.:', Value: '9876543210' },
    { key: 'Email:', Value: 'kristinwatson23@gmail.com' },
    { key: 'Prayer for whom:', Value: 'Myself' },
    { key: 'Place:', Value: 'Hyderabad' },
    { key: 'Language:', Value: 'English' },
  ];
  // prayerRequest = [
  //   {
  //     code: '#2345',
  //     name: 'Kristin Watson',
  //     id: '56CALVARY1234',
  //     phone: '+91-987654321',
  //     reason: 'Exams',
  //     language: 'English',
  //     status: 'Closed',
  //     proimage: '../../assets/images/panel_images/user-1.svg',
  //     langimage: '../../assets/images/panel_images/l-e.svg',
  //   },
  //   {
  //     code: '#3643',
  //     name: 'Kristin Watson',
  //     id: '56CALVARY1234',
  //     phone: '+91-987654321',
  //     reason: 'Marriage',
  //     language: 'Hindi',
  //     status: 'Declined',
  //     proimage: '../../assets/images/panel_images/user-1.svg',
  //     langimage: '../../assets/images/panel_images/l-e.svg',
  //   },
  //   {
  //     code: '#6534',
  //     name: 'Kristin Watson',
  //     id: '56CALVARY1234',
  //     phone: '+91-987654321',
  //     reason: 'Telugu',
  //     language: 'Telugu',
  //     status: 'Closed',
  //     proimage: '../../assets/images/panel_images/user-1.svg',
  //     langimage: '../../assets/images/panel_images/l-e.svg',
  //   },
  // ];
  open: boolean = true;
  closed: boolean = false;
  queryParamsDetails: any;
  userDetailsParams: any;
  details: any;
  serviceHistory: any;
  statusCompleted: any = 'completed';
  queryParamsStatus: any;
  onLoadPage = new EventEmitter();

  permissons:any;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    public service: DashboardServicesService,
    private activatedRoute: ActivatedRoute
  ) {}
  ngOnInit(): void {
    this.permissons = JSON.parse(localStorage.getItem('permissons'))
    audioFile();
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.queryParamsDetails = { ...params.keys, ...params };
      this.userDetailsParams = params.get('_id');
    });

    this.service
      .prayerRequestView(this.userDetailsParams)
      .subscribe((response: any) => {
        this.details = response.data;
        this.serviceHistory = response.prayerHistory;
      });
  }
  mark() {}
  openBtn() {
    this.queryParamsStatus = {
      status: this.statusCompleted ? this.statusCompleted : '',
    };
    this.service
      .prayerRequestStatus(this.userDetailsParams, this.queryParamsStatus)
      .subscribe((response) => {
        this.dialog.open(MarkAsClosedcommonDialogComponent, {
          disableClose: true,
        });
        this.onLoadPage.emit();
        this.closed = true;
        this.open = false;
      });
  }

  showImage(value: string) {
    window.open(value, '_blank');
  }
}
