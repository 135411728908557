<div class="container-fluid bg-white ">
    <div class="pt-3 ml-1">
        <div class="d-flex pt-3 pb-2">
            <div class="back-btn mr-2 cursor" routerLink="/CMS-Module" [queryParams]="{tab:2}">
                <img src="../../../assets/images/panel_images/back.svg" class="back-img">
            </div>
            <div class="user-header-back">
                <h5>Prayer Request Data Update Request</h5>
                <p><span>CMS Module / Review /  </span>Prayer Request Data Update Request</p>
            </div>
        </div>
    </div>

</div>

<div class="nofound" *ngIf="cmsPrayerData == '' || cmsPrayerData ==  null">
    <h5>
        No request found
    </h5>
</div>
<div class="user-header-details-img-section review-push bg-white mx-md-4 mt-md-5" *ngIf="cmsPrayerData != '' && cmsPrayerData !=  null">
    <div class="push-nofications-section pt-2 tabs-section">
        <div class="d-flex ml-md-3 pr-md-4 pb-md-3">
            <div class="mr-auto push pl-md-3 ">
                <h4>Prayer Request Data Update Request</h4>
            </div>
        </div>
        <div class="tv-programs-panel ml-md-3 pl-md-3" *ngFor="let tv of cmsPrayerData;let i = index">
            <div class="row event-row">
                <div class="col-md-8 ">

                    <div class="d-flex">
                        <div class="push-heading created-details-section pb-md-3">
                            <p>Created by:</p>
                        </div>
                        <div class="details-push-nofications">
                            <p>{{tv?.createdBy?.firstName}} {{tv?.createdBy?.lastName}}</p>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="push-heading created-details-section pb-md-3">
                            <p>Created Date:</p>
                        </div>
                        <div class="details-push-nofications">
                            <p>{{tv.createdAt | date: 'MMMM d, y, h:mm:ss a' }}</p>
                        </div>
                    </div>
                    <div class="header-tv">

                        <h5 class="pt-3">What are the modifications</h5>
                    </div>
                    <div class="Email-field mt-0">
                        <mat-form-field>
                            <input matInput [value]="tv.modification" readonly/>
                        </mat-form-field>
                    </div>
                    <div class="pt-3 pb-md-3">
                        <button class="btn btn-upload" (click)="downloadFile(tv.key)">
                      <img src="../../assets/images/panel_images/upload.svg" class="mr-2">Download file</button>
                    </div>
                    <div class="button-section-tv-programs pt-md-3" *ngIf="role == 'superAdmin' || role == 'admin'">
                        <button type="button" class="btn draft-btn mr-4" (click)="reject(tv._id,i)">Reject</button>
                        <button type="button" class="btn create-btn" (click)="create(tv._id,i)">Approve</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>