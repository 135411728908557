import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  config: any;
  constructor(private http: HttpClient) {}
  getConfig() {
    return this.http.get<any>('assets/config/config.json');
  }
}
