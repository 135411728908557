import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material-module';
import { LoginComponent } from './Login-page/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgOtpInputModule } from 'ng-otp-input';
import { SidenavbarComponent } from './sidenavbar/sidenavbar.component';
import { SidenavHeaderComponent } from './sidenavbar/sidenav-header/sidenav-header.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserlistComponent } from './userlist/userlist.component';
import { ServiceRequestsComponent } from './service-requests/service-requests.component';
import { PrayerRequestsComponent } from './prayer-requests/prayer-requests.component';
import { DonationsComponent } from './donations/donations.component';
import { CmsModuleComponent } from './cms-module/cms-module.component';
import { SettingsComponent } from './settings/settings.component';
import { SupportComponent } from './support/support.component';
import { LegalComponent } from './legal/legal.component';
import { DeactivateuserComponent } from './userlist/deactivateuser/deactivateuser.component';
import { ViewuserlistComponent } from './userlist/viewuserlist/viewuserlist.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { AnalyticsComponent } from './userlist/analytics/analytics.component';
import { DeclineDialogComponent } from './service-requests/decline-dialog/decline-dialog.component';
import { ServiceAnalyticsComponent } from './service-requests/service-analytics/service-analytics.component';
import { ViewservicerequestComponent } from './service-requests/viewservicerequest/viewservicerequest.component';
import { ScheduleDialogComponent } from './service-requests/schedule-dialog/schedule-dialog.component';
import { ViewRequestComponent } from './prayer-requests/view-request/view-request.component';
import { PrayerAnalyticsComponent } from './prayer-requests/prayer-analytics/prayer-analytics.component';
import { DowloadinvoiceComponent } from './donations/dowloadinvoice/dowloadinvoice.component';
import { DonateAnalysticsComponent } from './donations/donate-analystics/donate-analystics.component';
import { TvProgramsComponent } from './tv-programs/tv-programs.component';
import { TvprogramsAnalysticsComponent } from './tv-programs/tvprograms-analystics/tvprograms-analystics.component';
import { TvdowloadinvoiceComponent } from './tv-programs/tvdowloadinvoice/tvdowloadinvoice.component';
import { PasswordToggleDirective } from './password-toggle.directive';
import { OldPasswordToggleDirective } from './directives/old-password-toggle.directive';
import { NewPasswordToggleDirective } from './directives/new-password-toggle.directive';
import { ViewissuedialogComponent } from './support/viewissuedialog/viewissuedialog.component';
import { SupportanalyticsComponent } from './support/supportanalytics/supportanalytics.component';
import { UsermanagmentComponent } from './usermanagment/usermanagment.component';
import { CreateuserComponent } from './usermanagment/createuser/createuser.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { SpiritualfitnessComponent } from './spiritualfitness/spiritualfitness.component';
import { SucessdialogcmsmoduleComponent } from './cms-module/sucessdialogcmsmodule/sucessdialogcmsmodule.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { ProfilesuccessDialogComponent } from './settings/profilesuccess-dialog/profilesuccess-dialog.component';
import { PasswordchangedialogComponent } from './settings/passwordchangedialog/passwordchangedialog.component';
import { ReviewpushnotificationsComponent } from './cms-module/reviewpushnotifications/reviewpushnotifications.component';
import { ReviewtvprogramComponent } from './cms-module/reviewtvprogram/reviewtvprogram.component';
import { ReviewservicesComponent } from './cms-module/reviewservices/reviewservices.component';
import { ReviewdonationsComponent } from './cms-module/reviewdonations/reviewdonations.component';
import { ReviewspiritualfitnessComponent } from './cms-module/reviewspiritualfitness/reviewspiritualfitness.component';
import { AvatarModule } from 'ngx-avatar';
import { ItsupoortsuccessdialogComponent } from './settings/itsupoortsuccessdialog/itsupoortsuccessdialog.component';
import { MarkAsInvalidcommonDialogComponent } from './mark-as-invalidcommon-dialog/mark-as-invalidcommon-dialog.component';
import { MarkAsClosedcommonDialogComponent } from './mark-as-closedcommon-dialog/mark-as-closedcommon-dialog.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DatepipePipe } from './_helpers/datepipe.pipe';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ErrorInterceptor } from './_helpers/error.interceptor';
// import { LoaderintercerptorService } from 'src/app/_services/loader.service';
import { DatePipe } from '@angular/common';
import { RecordsPipe } from './_helpers/records.pipe';
import { ViewuserComponent } from './usermanagment/viewuser/viewuser.component';
import { SuccesupdateDialogComponent } from './usermanagment/succesupdate-dialog/succesupdate-dialog.component';
import { SuccescreateDialogComponent } from './usermanagment/succescreate-dialog/succescreate-dialog.component';
import { LoaderintercerptorService } from './_services/loaderintercerptor.service';
import { ConfigService } from './_services/config.service';
import { ShowloaderComponent } from './showloader/showloader.component';
import { ItsupportComponent } from './itsupport/itsupport.component';
import { ViewissueitsupportComponent } from './itsupport/viewissueitsupport/viewissueitsupport.component';
import { InvaliddialogComponent } from './prayer-requests/invaliddialog/invaliddialog.component';
import { SafePipePipe } from './_helpers/safe-pipe.pipe';
import { SuspenddialogComponent } from './usermanagment/suspenddialog/suspenddialog.component';
import { InputRestrictionDirective } from './_modal/spacepipe';
import { SuccessApprovedDialogComponent } from './cms-module/success-approved-dialog/success-approved-dialog.component';
import { RejectedApprovedDialogComponent } from './cms-module/rejected-approved-dialog/rejected-approved-dialog.component';
import { ReviewmediasComponent } from './cms-module/reviewmedias/reviewmedias.component';
import { ReviewtemplesComponent } from './cms-module/reviewtemples/reviewtemples.component';
import { ReviewprayerrequestsComponent } from './cms-module/reviewprayerrequests/reviewprayerrequests.component';

import { NgApexchartsModule } from "ng-apexcharts";
import { CheckboxDialogComponent } from './usermanagment/checkbox-dialog/checkbox-dialog.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MarkAsCompletecommonDialogComponent } from './mark-as-completecommon-dialog/mark-as-completecommon-dialog.component';
import { MarkAsSuccesscommonDialogComponent } from './mark-as-successcommon-dialog/mark-as-successcommon-dialog.component';
import { NewspiritualfitnessComponent } from './newspiritualfitness/newspiritualfitness.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SidenavbarComponent,
    SidenavHeaderComponent,
    DashboardComponent,
    UserlistComponent,
    ServiceRequestsComponent,
    PrayerRequestsComponent,
    DonationsComponent,
    CmsModuleComponent,
    SettingsComponent,
    SupportComponent,
    LegalComponent,
    DeactivateuserComponent,
    ViewuserlistComponent,
    AnalyticsComponent,
    DeclineDialogComponent,
    ServiceAnalyticsComponent,
    ViewservicerequestComponent,
    ScheduleDialogComponent,
    ViewRequestComponent,
    PrayerAnalyticsComponent,
    DowloadinvoiceComponent,
    DonateAnalysticsComponent,
    TvProgramsComponent,
    TvprogramsAnalysticsComponent,
    TvdowloadinvoiceComponent,
    PasswordToggleDirective,
    OldPasswordToggleDirective,
    NewPasswordToggleDirective,
    ViewissuedialogComponent,
    SupportanalyticsComponent,
    UsermanagmentComponent,
    CreateuserComponent,
    SpiritualfitnessComponent,
    SucessdialogcmsmoduleComponent,
    PagenotfoundComponent,
    ProfilesuccessDialogComponent,
    PasswordchangedialogComponent,
    ReviewpushnotificationsComponent,
    ReviewtvprogramComponent,
    ReviewservicesComponent,
    ReviewdonationsComponent,
    ReviewspiritualfitnessComponent,
    ItsupoortsuccessdialogComponent,
    MarkAsInvalidcommonDialogComponent,
    MarkAsClosedcommonDialogComponent,
    DatepipePipe,
    RecordsPipe,
    ViewuserComponent,
    SuccesupdateDialogComponent,
    SuccescreateDialogComponent,
    ShowloaderComponent,
    ItsupportComponent,
    ViewissueitsupportComponent,
    InvaliddialogComponent,
    SafePipePipe,
    SuspenddialogComponent,
    InputRestrictionDirective,
    SuccessApprovedDialogComponent,
    RejectedApprovedDialogComponent,
    ReviewmediasComponent,
    ReviewtemplesComponent,
    ReviewprayerrequestsComponent,
    CheckboxDialogComponent,
    MarkAsCompletecommonDialogComponent,
    MarkAsSuccesscommonDialogComponent,
    NewspiritualfitnessComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    NgOtpInputModule,
    HttpClientModule,
    NgxMaterialTimepickerModule,
    NgxOtpInputModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    HighchartsChartModule,
    AvatarModule,
    NgApexchartsModule
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderintercerptorService,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
