import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DashboardServicesService } from '../_services/dashboard-services.service';
import { LoaderService } from '../_services/loader.service';

export interface PeriodicElement {
  user: string;
  Phoneno: any;
  Attendance: any;
  Status: string;
  Lastvisited: any;
  PaymentMode: any;
  Action: any;
  image: any;
  paymentimg: any;
}
@Component({
  selector: 'app-donations',
  templateUrl: './donations.component.html',
  styleUrls: ['./donations.component.css'],
})
export class DonationsComponent implements OnInit {
  dropDownSelection: any;
  Date: any;
  calendraDate: any;
  today = new Date();
  statusOption = new FormControl('');
  serviceControl = new FormControl('');

  status:any[] = [
      { key: 'wallet', value: 'Wallet' },
      { key: 'netbanking', value: 'Net Banking' },
      { key: 'upi', value: 'UPI' },
      { key: 'card', value: 'Card' },
      { key: 'emi', value: 'EMI' },
    ];
  services: any[] = [];
  totalDonar: any;
  totalDocument:number = 0;
  totalAmount: any;
  searchparams: any;
  params: any;
  fromDate: any;
  toDate: any;
  paymentUpi: any;
  donationCause: any;
  numberOfDonation: any;
  pageSize: any = 10;
  currentPage: any = 1;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  pageLimit: any;
  pageNumber: any;
  dateClear: any;
  dateClear1: any;
  donationCauseClear: string;
  statusClear: string;
  paymentClear: string;
  searchClear: string;

  permission:any;
  queryparmsDonation: any;
  exportDonation: any;
  constructor(
    private router: Router,
    private service: DashboardServicesService,
    private loaderService: LoaderService
  ) {}
  @ViewChild(MatPaginator) paginator: any = MatPaginator;
  ELEMENT_DATA: any[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  ngOnInit(): void {
    this.permission = JSON.parse(localStorage.getItem('permissons'))
    this.getAllData();
    this.getServices();
  }

  getAllData(){
    this.loaderService.loading.next(true);
    this.params = {
      search: this.searchparams ? this.searchparams : '',
      donationCause: this.donationCause ? this.donationCause : '',
      fromDate: this.fromDate ? this.fromDate : '',
      toDate: this.toDate ? this.toDate : '',
      paymentUpi: this.paymentUpi ? this.paymentUpi : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.donateRequest(this.params).subscribe((response) => {
      console.log('response' , response);
      this.ELEMENT_DATA = response.message;
      this.totalDonar = response.totalRecords[0].totalDonors;
      this.totalDocument = response.totalDocument;
      this.totalAmount = response.totalRecords[0].totalDonations;
      this.numberOfDonation = response.totalRecords[0].donations;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.loaderService.loading.next(false); 
    });
  }
  reset() {
    this.donationCauseClear = '';
    this.statusClear = '';
    this.searchClear = '';
    this.paymentClear = '';
    this.searchparams = '';
    this.donationCause = '';
    this.fromDate = '';
    this.toDate = '';
    this.paymentUpi = '';
    this.dateClear = null;
    this.dateClear1 = null;
    this.params = {
      search: this.searchparams ? this.searchparams : '',
      donationCause: this.donationCause ? this.donationCause : '',
      fromDate: this.fromDate ? this.fromDate : '',
      toDate: this.toDate ? this.toDate : '',
      paymentUpi: this.paymentUpi ? this.paymentUpi : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.donateRequest(this.params).subscribe((response) => {
      console.table(response);
      this.ELEMENT_DATA = response.message;
      this.totalDonar = response.totalRecords[0].totalDonors;
      this.totalDocument = response.totalDocument;
      this.totalAmount = response.totalRecords[0].totalDonations;
      this.numberOfDonation = response.totalRecords[0].donations;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    this.params = {
      search: this.searchparams ? this.searchparams : '',
      donationCause: this.donationCause ? this.donationCause : '',
      fromDate: this.fromDate ? this.fromDate : '',
      toDate: this.toDate ? this.toDate : '',
      paymentUpi: this.paymentUpi ? this.paymentUpi : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    this.service.donateRequest(this.params).subscribe((response) => {
      console.table(response);
      this.ELEMENT_DATA = response.message;
      this.totalDonar = response.totalRecords[0].totalDonors;
      this.totalDocument = response.totalDocument;
      this.totalAmount = response.totalRecords[0].totalDonations;
      this.numberOfDonation = response.totalRecords[0].donations;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  displayedColumns: string[] = [
    'PaymentID',
    'user',
    'Phoneno',
    'receivedin',
    'Amount',
    'Dateoftrans',
    'PaymentMode',
    'Action',
  ];
  // dataSource = new MatTableDataSource(ELEMENT_DATA);
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  searchDropDown(event: any) {
    this.searchparams = event.target.value;
    this.params = {
      search: this.searchparams ? this.searchparams : '',
      donationCause: this.donationCause ? this.donationCause : '',
      fromDate: this.fromDate ? this.fromDate : '',
      toDate: this.toDate ? this.toDate : '',
      paymentUpi: this.paymentUpi ? this.paymentUpi : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.donateRequest(this.params).subscribe((response) => {
      console.table(response);
      this.ELEMENT_DATA = response.message;
      this.totalDonar = response.totalRecords[0].totalDonors;
      this.totalDocument = response.totalDocument;
      this.totalAmount = response.totalRecords[0].totalDonations;
      this.numberOfDonation = response.totalRecords[0].donations;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  onChangeDate(e: any) {
    this.dropDownSelection = e.target.value;
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.fromDate = date.format('YYYY-MM-DD');
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.params = {
        search: this.searchparams ? this.searchparams : '',
        donationCause: this.donationCause ? this.donationCause : '',
        fromDate: this.fromDate ? this.fromDate : '',
        toDate: this.toDate ? this.toDate : '',
        paymentUpi: this.paymentUpi ? this.paymentUpi : '',
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage ? this.currentPage : '',
      };
      this.service.donateRequest(this.params).subscribe((response) => {
        
        this.ELEMENT_DATA = response.data;
        this.totalDonar = response.totalRecords[0].totalDonors;
        this.totalDocument = response.totalDocument;
        this.totalAmount = response.totalRecords[0].totalDonations;
        this.numberOfDonation = response.totalRecords[0].donations;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      });
    }
  }
  onChangeToDate(e: any) {
    this.dropDownSelection = e.target.value;
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.params = {
        search: this.searchparams ? this.searchparams : '',
        donationCause: this.donationCause ? this.donationCause : '',
        fromDate: this.fromDate ? this.fromDate : '',
        toDate: this.toDate ? this.toDate : '',
        paymentUpi: this.paymentUpi ? this.paymentUpi : '',
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage ? this.currentPage : '',
      };
      this.service.donateRequest(this.params).subscribe((response) => {
        console.table(response);
        this.ELEMENT_DATA = response.message;
        this.totalDonar = response.totalRecords[0].totalDonors;
        this.totalDocument = response.totalDocument;
        this.totalAmount = response.totalRecords[0].totalDonations;
        this.numberOfDonation = response.totalRecords[0].donations;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      });
    }
  }
  serviceDropDown(event: any) {
    if (event.value != '') {
      this.paymentUpi = JSON.stringify(event.value);
      // this.paymentUpi = event.value
    } else {
      this.paymentUpi = '';
    }
    this.params = {
      search: this.searchparams ? this.searchparams : '',
      donationCause: this.donationCause ? this.donationCause : '',
      fromDate: this.fromDate ? this.fromDate : '',
      toDate: this.toDate ? this.toDate : '',
      paymentUpi: this.paymentUpi ? this.paymentUpi : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.donateRequest(this.params).subscribe((response) => {
      this.ELEMENT_DATA = response.message.length ? response.message : [];
      this.totalDonar = response.totalRecords[0].totalDonors;
      this.totalDocument = response.totalDocument;
      this.totalAmount = response.totalRecords[0].totalDonations;
      this.numberOfDonation = response.totalRecords[0].donations;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  donationCauseDropDown(event: any) {
    if (event.value != '') {
      this.donationCause = JSON.stringify(event.value);
      // this.donationCause = event.vaue
    } else {
      this.donationCause = '';
    }
    this.params = {
      search: this.searchparams ? this.searchparams : '',
      donationCause: this.donationCause ? this.donationCause : '',
      fromDate: this.fromDate ? this.fromDate : '',
      toDate: this.toDate ? this.toDate : '',
      paymentUpi: this.paymentUpi ? this.paymentUpi : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.donateRequest(this.params).subscribe((response) => {
      this.ELEMENT_DATA = response.message.length ? response.message : [];
      this.totalDonar = response.totalRecords[0].totalDonors;
      this.totalAmount = response.totalRecords[0].totalDonations;
      this.numberOfDonation = response.totalRecords[0].donations;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  view(id: any) {
    this.router.navigate(['Donations/']);
  }

  Analytics() {
    this.router.navigate(['Donations/Analytics']);
  }
  deActivateUser() {}


  getServices(){
    this.service.donateRequestServices().subscribe((response) => {
      this.services = response.data.map((ser:any) => ser.title);
    });
  }

  donationDataExportBtn() {
    this.params = {
      search: this.searchparams ? this.searchparams : '',
      donationCause: this.donationCause ? this.donationCause : '',
      fromDate: this.fromDate ? this.fromDate : '',
      toDate: this.toDate ? this.toDate : '',
      paymentUpi: this.paymentUpi ? this.paymentUpi : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.exportsDonationData(this.params).subscribe((res) => {
      console.log(res);
      let exportDoantionData = res.sheetUrl;
      this.download(exportDoantionData, 'sheetUrl');
    });
  }

  download(url: any, downloadName: any) {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = downloadName;
    a.click();
    document.body.removeChild(a);
  }
  donationDetailExport(){
    this.queryparmsDonation = {
      search: this.searchparams ? this.searchparams : '',
      donationCause: this.donationCause ? this.donationCause : '',
      fromDate: this.fromDate ? this.fromDate : '',
      toDate: this.toDate ? this.toDate : '',
      paymentUpi: this.paymentUpi ? this.paymentUpi : '',
    }
    this.service
    .exportsDonationData(this.queryparmsDonation)
    .subscribe((res:any) => {
      console.log(res);
      this.exportDonation = res.sheetUrl;
      this.download(this.exportDonation, 'sheetUrl');
    });
  }
}
