<ng-container *ngIf="role==='superAdmin' || role==='admin'; else elseblock">
  <div class="container-fluid bg-white">
    <div class="pt-3 ml-3">
      <div class="d-flex pt-3 pb-2">
        <div class="back-btn mr-2 cursor" routerLink="/Internal-Team">
          <img src="../../../assets/images/panel_images/back.svg" class="back-img" />
        </div>
        <div class="user-header-back">
          <h5>Create User</h5>
        </div>
      </div>
    </div>
    <form [formGroup]="createUserManagment">
      <div class="user-header-details-img-section mt-4 ml-3 pb-5 bg-white">
        <div class="upload pt-4 pl-4">
          <img [src]="url" width="100" height="100" alt="" class="upload-img" />
          <!-- <img *ngIf="ussrl != null" [src]="adminImg" width=100 height=100 alt="" class="upload-img"> -->
          <div class="round">
            <input type="file" accept="Image/*" (change)="onFileSelected($event)" />
            <img src="../../assets/images/panel_images/camera.svg" width="100" height="100" alt="" class="camera-img" />
          </div>
        </div>
        <div class="form-section mt-3 ml-2">
          <div class="row">
            <div class="col-md-5">
              <div class="form-group">
                <label for="exampleInputPassword1">First Name</label>
                <input type="text" class="form-control" (keypress)="alphabetOnly($event)" formControlName="firstName"
                  id="exampleInputPassword1" placeholder="Enter first name here" />
                <div *ngIf="
                    userManagementControls.firstName.errors?.required && submitted
                  " class="invalid-feedback d-block">
                  Please Enter The First Name
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group">
                <label for="exampleInputPassword1">Last Name</label>
                <input type="text" class="form-control" (keypress)="alphabetOnly($event)" formControlName="lastName"
                  id="exampleInputPassword1" placeholder="Enter last name here" />
                <div *ngIf="
                    userManagementControls.lastName.errors?.required && submitted
                  " class="invalid-feedback d-block">
                  Please Enter The Last Name
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5">
              <div class="form-group">
                <label for="exampleInputEmail1">Email address</label>
                <input type="email" class="form-control" (keyup)="sameEmailEvent($event)" formControlName="email"
                  id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email address here" />
                <div *ngIf="
                    userManagementControls.email.errors?.required && submitted
                  " class="invalid-feedback d-block">
                  Please Enter The Email
                </div>
                <div *ngIf="
                    userManagementControls.email.errors?.pattern && submitted
                  " class="invalid-feedback d-block">
                  Please Enter The Valid Email
                </div>
                <div *ngIf="this.errorMessege != null || ''" class="invalid-feedback d-block">
                  {{ this.errorMessege }}
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group">
                <label for="exampleInputPassword1">Mobile Number</label>
                <input type="text" class="form-control" (keypress)="numberOnly($event)" maxlength="10"
                  formControlName="phone" id="exampleInputPassword1" placeholder="Enter mobile number here" />
                <div *ngIf="
                    userManagementControls.phone.errors?.required && submitted
                  " class="invalid-feedback d-block">
                  Please Enter Mobile Number
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5">
              <div *ngIf="role==='superAdmin'" class="form-group">
                <label for="exampleInputEmail1">Role</label>
                <select class="form-control select" formControlName="role" id="exampleFormControlSelect1">
                  <option value="" selected="selected">Select</option>
                  <option value="superAdmin">Super admin</option>
                  <option value="admin">Admin</option>
                  <option value="manager">Manager</option>
                </select>
                <div *ngIf="
                    userManagementControls.role.errors?.required && submitted
                  " class="invalid-feedback d-block">
                  Please Select
                </div>
              </div>
              <div *ngIf="role==='admin'" class="form-group">
                <label for="exampleInputEmail1">Role</label>
                <select class="form-control select" formControlName="role" id="exampleFormControlSelect1">
                  <option value="" selected="selected">Select</option>
                  <option value="manager">Manager</option>
                </select>
                <div *ngIf="
                    userManagementControls.role.errors?.required && submitted
                  " class="invalid-feedback d-block">
                  Please Select
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="checkbox-select-section mt-3 ml-2">
          <div class="check-box-option">
            <label>Members List</label>
            <section class="example-section" class="d-flex">
              <div *ngFor="let key of managementOption; let i = index" class="mr-3 d-flex user-m-section">
                <input [checked]="i === 0 ? memberslistEdit : false" #checkboxes type="checkbox"
                  formControlName="memberList" value="{{ key.key }}" (change)="onChange($event)" />
                <p>{{ key.key }}</p>
              </div>
            </section>
            <!-- <div *ngIf="userManagementControls.memberList.errors?.required && submitted" class="invalid-feedback d-block"> Please Select Atleast one</div> -->
          </div>
  
          <div class="check-box-option mt-3">
            <label>Service Requests</label>
            <section class="example-section" class="d-flex">
              <div *ngFor="let key of managementOption; let i = index" class="mr-3 d-flex user-m-section">
                <input [checked]="i === 0 ? serviceEdit : false" #checkboxes type="checkbox"
                  formControlName="serviceRequest" value="{{ key.key }}" (change)="onChangeService($event)" />
                <p>{{ key.key }}</p>
              </div>
            </section>
            <!-- <div *ngIf="userManagementControls.serviceRequest.errors?.required && submitted" class="invalid-feedback d-block"> Please Select Atleast one</div> -->
          </div>
  
          <div class="check-box-option mt-3">
            <label>Prayer Requests</label>
            <section class="example-section" class="d-flex">
              <div *ngFor="let key of managementOption; let i = index" class="mr-3 d-flex user-m-section">
                <input [checked]="i === 0 ? prayerEdit : false" #checkboxes type="checkbox" value="{{ key.key }}"
                  formControlName="prayerRequest" (change)="onChangePrayer($event)" />
                <p>{{ key.key }}</p>
              </div>
            </section>
            <!-- <div *ngIf="userManagementControls.prayerRequest.errors?.required && submitted" class="invalid-feedback d-block"> Please Select Atleast one</div> -->
          </div>
          <div class="check-box-option mt-3">
            <label>Donations</label>
            <section class="example-section" class="d-flex">
              <div *ngFor="let key of tvProgramsAndDontionsCheckboxValues" class="mr-3 d-flex user-m-section">
                <input #checkboxes formControlName="donation" type="checkbox" value="{{ key.key }}"
                  (change)="onChangeDonations($event)" />
                <p>{{ key.key }}</p>
              </div>
            </section>
            <!-- <div *ngIf="userManagementControls.donation.errors?.required && submitted" class="invalid-feedback d-block"> Please Select Atleast one</div> -->
          </div>
          <div class="check-box-option mt-3">
            <label>TV Programs</label>
            <section class="example-section" class="d-flex">
              <div *ngFor="let key of tvProgramsAndDontionsCheckboxValues" class="mr-3 d-flex user-m-section">
                <input #checkboxes type="checkbox" formControlName="tvProgram" value="{{ key.key }}"
                  (change)="onChangeTvPrograms($event)" />
                <p>{{ key.key }}</p>
              </div>
            </section>
            <!-- <div *ngIf="userManagementControls.tvProgram.errors?.required && submitted" class="invalid-feedback d-block"> Please Select Atleast one</div> -->
          </div>
          <div class="check-box-option mt-3">
            <label>Support</label>
            <section class="example-section" class="d-flex">
              <div *ngFor="let key of managementOption; let i = index" class="mr-3 d-flex user-m-section">
                <input [checked]="i === 0 ? supportEdit : false" #checkboxes type="checkbox" formControlName="support"
                  value="{{ key.key }}" (change)="onChangeSupport($event)" />
                <p>{{ key.key }}</p>
              </div>
            </section>
            <!-- <div *ngIf="userManagementControls.support.errors?.required && submitted" class="invalid-feedback d-block"> Please Select Atleast one</div> -->
          </div>
          <div class="check-box-option mt-3">
            <label>CMS Module </label>
            <section class="example-section" class="d-flex">
              <div *ngFor="let key of cmsCheckboxValues; let i = index" class="mr-3 d-flex user-m-section">
                <input [checked]="i === 0 ? cmsEdit : false" #checkboxes formControlName="cmsModule" type="checkbox"
                  value="{{ key.key }}" (change)="onChangeCms($event)" />
                <p>{{ key.key }}</p>
              </div>
            </section>
            <!-- <div *ngIf="userManagementControls.cmsModule.errors?.required && submitted" class="invalid-feedback d-block"> Please Select Atleast one</div> -->
          </div>
          <div class="check-box-option mt-3">
            <label>Spiritual Fitness</label>
            <section class="example-section" class="d-flex">
              <div *ngFor="let key of spiritualFitnessCheckboxValues" class="mr-3 d-flex user-m-section">
                <input #checkboxes formControlName="spiritualFitnessModule" type="checkbox" value="{{ key.key }}"
                  (change)="onChangeSpritualFitness($event)" />
                <p>{{ key.key }}</p>
              </div>
            </section>
            <!-- <div *ngIf="userManagementControls.spiritualFitnessModule.errors?.required && submitted" class="invalid-feedback d-block"> Please Select Atleast one</div> -->
          </div>
        </div>
        <div class="success-msg justify-content-center d-flex align-items-center mt-3" *ngIf="createSuccesfully">
          <h5>
            <img src="../../../assets/images/panel_images/success.svg" class="mr-2" />User Created Successfully
          </h5>
        </div>
        <!-- <div -->
        <!-- *ngIf="
            !userManagementControls.spiritualFitnessModule.value &&
            !userManagementControls.cmsModule.value &&
            !userManagementControls.support.value &&
            !userManagementControls.tvProgram.value &&
            !userManagementControls.donation.value &&
            !userManagementControls.prayerRequest.value &&
            !userManagementControls.serviceRequest.value &&
            !userManagementControls.memberList.value &&
            this.submitted
          " -->
        <!-- class="invalid-feedback d-block"
        >
          Please Select Atleast One
        </div> -->
      </div>
    </form>
  </div>
  
  <div class="button-section d-flex bg-white py-4 px-5">
    <div class="cancel-btn mr-auto" routerLink="/Internal-Team">
      <button class="btn">Cancel</button>
    </div>
    <div class="create-team-btn">
      <button class="btn" (click)="createUser()">
        Create <img src="../../../assets/images/panel_images/create-1.svg" />
      </button>
      <!-- <button class="btn" (click)="deActivateUser()">Create <img src='../../../assets/images/panel_images/create-1.svg'>
       </button> -->
    </div>
  </div>
</ng-container>
<ng-template #elseblock>
    <!-- <h5>Not have Permissions to see the analytics</h5> -->
    <div class="mt-5 text-center">
        <img src="../../assets//images/panel_images/notPermitted.png" width="120" alt="" />
        <p class="mt-5">You dont have permission to access this feature</p>
    </div>
</ng-template>



<app-showloader *ngIf="createUserLoader"></app-showloader>