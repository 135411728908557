<div class="main">
  <div class="container-fluid">
    <div class="congrats text-center">
      <img src="../../assets/images/panel_images/thank.gif" class="success_Img" />
    </div>
     <div class="thank_you-section">
      <h4 class="mb-md-3">Thankyou!</h4>
      <p>
        Your response has been submitted. Someone from Church team will reach out to you.
      </p>
      </div>
      <div class="close-btn mt-5 mb-4">
        <button class="btn btn-close" (click)="close()">Close</button>
      </div>
    </div>
    </div>
