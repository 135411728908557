import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as Highcharts from 'highcharts';
import { DashboardServicesService } from 'src/app/_services/dashboard-services.service';
import { FormControl } from '@angular/forms';
import { months } from '../../app.constant';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css'],
})
export class AnalyticsComponent implements OnInit {
  today = new Date();
  dropDownSelection: any;
  calendraDate: any;
  Date: any;
  highcharts = Highcharts;
  chartOptions: any;
  chartOptionsAnalytics: any;
  afterMaleMembersArray: any = [];
  afterFemaleMembersArray: any = [];
  TotalMonths: any = [];
  maleMembers: any;
  afterMaleMembersDetails: any;
  // queryparmsMembers: { year: any };
  queryparmsMembers: any;
  memberYear: any;
  Eligable = new FormControl('');
  toDate: string;
  queryparms: {};
  startDate: any;

  //new
  selectedYear: any = '2023';
  monthList = [
    { key: 'JAN', value: 0 },
    { key: 'FEB', value: 1 },
    { key: 'MAR', value: 2 },
    { key: 'APR', value: 3 },
    { key: 'MAY', value: 4 },
    { key: 'JUN', value: 5 },
    { key: 'JUL', value: 6 },
    { key: 'AUG', value: 7 },
    { key: 'SEP', value: 8 },
    { key: 'OCT', value: 9 },
    { key: 'NOV', value: 10 },
    { key: 'DEC', value: 11 },
  ];
  yearList: any = [
    '2023',
    '2022',
    '2021',
    '2020',
    '2019',
    '2018',
    '2017',
    '2016',
    '2015',
    '2014',
    '2013',
    '2012',
    '2011',
    '2010',
    '2009',
    '2008',
    '2007',
    '2006',
    '2005',
    '2004',
    '2003',
    '2002',
    '2001',
    '2000',
  ];
  monthArray = months;
  selectedMonth: any;
  startDateMember: Date = new Date(2023, 0, 1);
  endDateMember: Date = new Date();
  exportMembers: any;
  totalRecords: any;
  totalMale: any;
  totalFemale: any;
  constructor(private services: DashboardServicesService) {
  }

  ngOnInit(): void {
    if (this.selectedYear == new Date().getFullYear()) {
      let month = new Date().getMonth() + 1;
      this.monthList = this.monthList.slice(0, month);
      console.log('from if block' , this.monthList);
    } else {
      this.monthList = [
        { key: 'JAN', value: 0 },
        { key: 'FEB', value: 1 },
        { key: 'MAR', value: 2 },
        { key: 'APR', value: 3 },
        { key: 'MAY', value: 4 },
        { key: 'JUN', value: 5 },
        { key: 'JUL', value: 6 },
        { key: 'AUG', value: 7 },
        { key: 'SEP', value: 8 },
        { key: 'OCT', value: 9 },
        { key: 'NOV', value: 10 },
        { key: 'DEC', value: 11 },
      ];
    }
    this.getMembersData();
    this.chartOptionsAnalytics = {
      chart: {
        type: 'spline',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: { enabled: false },
      //  subtitle: {
      //     text: "Source: WorldClimate.com"
      //  },
      xAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineWidth: 0,
        lineColor: 'transparent',
        title: {
          text: '',
        },
        labels: {
          style: {
            color: '#8492A6',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          text: '',
        },
        labels: {
          format: '{value}',
          style: {
            color: '#425466',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
      },
      //  tooltip: {
      //     valueSuffix:" °C"
      //  },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
      },

      colors: ['#0DBC67', '#FF4242'],
      series: [
        {
          name: '>75%',
          data: [10, 15, 20, 35, 45, 50, 55, 65, 70, 90, 100, 110],
        },
        {
          name: '<75%',
          data: [15, 25, 30, 48, 55, 60, 65, 75, 89, 98, 190, 200],
        },
      ],
    };
  }
  onChangeDate(e: any) {
    // this.selectedMonth = '';
    // this.selectedYear = '';

    this.dropDownSelection = e.target.value;
    this.afterMaleMembersArray = [];
    this.afterFemaleMembersArray = [];
    this.TotalMonths = [];
    console.log('dd' , this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    this.startDateMember = new Date(e.target.value);
    this.endDateMember = new Date(e.target.value);
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getMembersData();
    }
  }
  onChangeToDate(e: any) {
    this.dropDownSelection = e.target.value;
    this.afterMaleMembersArray = [];
    this.afterFemaleMembersArray = [];
    this.TotalMonths = [];
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    this.endDateMember = new Date(e.target.value);
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getMembersData();
    }
  }
  StatisticsDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
  }
  AnalyticsDateChange(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
  }

  //new

  getMembersData() {
    this.TotalMonths = [];
    this.afterFemaleMembersArray = [];
    this.afterMaleMembersArray = [];
    this.maleMembers = [];
    this.afterMaleMembersDetails = [];
    this.queryparmsMembers = {
      startDate:
        this.startDateMember.getFullYear() +
        '-' +
        (this.startDateMember.getMonth() + 1) +
        '-' +
        this.startDateMember.getDate(),
      endDate:
        this.endDateMember.getFullYear() +
        '-' +
        (this.endDateMember.getMonth() + 1) +
        '-' +
        this.endDateMember.getDate(),
    };

    if (this.selectedMonth != undefined) {
      this.queryparmsMembers['groupBy'] = 'day';
    }
    
    if (
      this.startDateMember.getFullYear() == this.endDateMember.getFullYear() &&
      this.startDateMember.getMonth() == this.endDateMember.getMonth() &&
      this.startDateMember.getDate() != this.endDateMember.getDate()
      
      ) {
        this.queryparmsMembers['groupBy'] = 'day';
      }
      if (
        this.startDateMember.getFullYear() == this.endDateMember.getFullYear() &&
        this.startDateMember.getMonth() == this.endDateMember.getMonth() &&
        this.startDateMember.getDate() == this.endDateMember.getDate()
        
        ) {
      this.queryparmsMembers['groupBy'] = 'hour';
    }
    console.log('ss' , this.queryparmsMembers);
    

    // if(this.startDateMember.getFullYear() == this.endDateMember.getFullYear() &&
    // this.startDateMember.getMonth() == this.endDateMember.getMonth() &&
    // this.startDateMember.getDate() == this.endDateMember.getDate()){
    //   this.queryparmsMembers['groupBy'] = 'hour';
    // }
    this.services
    .dashboardMembers(this.queryparmsMembers)
    .subscribe((result) => {
      console.log(result);
      this.maleMembers = result.data;
      this.totalRecords = result.totalRecords;
      this.totalMale = result.totalMale;
      this.totalFemale = result.totalFemale;
      this.afterMaleMembersDetails = this.maleMembers.forEach((item: any) => {
        this.afterMaleMembersArray.push(item.male);        
        this.afterFemaleMembersArray.push(item.female);
        const day = item.date?.split('-')
        const dateText = item.day ? item.day + '-' : '';
        if (item.date != undefined && item.hour == undefined) {
          this.TotalMonths.push(day[2] + '-' + item.month)
        }else if(item.date != undefined && item.hour != undefined){
          this.TotalMonths.push(item.hour + 1 + ':00')
        }else{
          this.TotalMonths.push(dateText + item.month + '-' + item.year);
        }
      });
      this.chartOptions = {
        chart: {
          type: 'spline',
        },
        title: {
          text: '',
        },
        credits: {
          enabled: false,
        },
        legend: { enabled: false },
        //  subtitle: {
        //     text: "Source: WorldClimate.com"
        //  },
        xAxis: {
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          lineWidth: 0,
          lineColor: 'transparent',
          title: {
            text: '',
          //   style: {
          //     color: '#425466',
          //     fontSize: 16,
          //     fontWeight: 600,
          //     fontFamily: 'Inter', // Set the font size of the title
          // }
          },
          labels: {
            style: {
              color: '#5A657F',
              fontSize: 12,
              fontWeight: 600,
              fontFamily: 'Inter',
            },
          },
          categories: this.TotalMonths,
        },
        yAxis: {
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          title: {
            text: '',
          //   style: {
          //     color: '#425466',
          //     fontSize: 16,
          //     fontWeight: 600,
          //     fontFamily: 'Inter',
          // }
          },
          labels: {
            style: {
              color: '#425466',
              fontSize: 12,
              fontWeight: 600,
              fontFamily: 'Inter',
            },
          },
        },
        //  tooltip: {
        //     valueSuffix:" °C"
        //  },
        plotOptions: {
          series: {
            marker: {
              enabled: false,
            },
          },
        },

        colors: ['#0DBC67', '#FF4242'],
        series: [
          {
            name: 'Male',
            data: this.afterMaleMembersArray,
          },
          {
            name: 'Female',
            data: this.afterFemaleMembersArray,
          },
        ],
      };
    });
}
  resetMemberFilter() {
    this.selectedYear = '2023';
    this.selectedMonth = undefined;
    this.startDateMember = new Date(2023, 0, 1);
    this.endDateMember = new Date();
    this.getMembersData();
  }

  selectYear(event: any) {
    if (this.selectedYear == new Date().getFullYear()) {
      let month = new Date().getMonth() + 1;
      this.monthList = this.monthList.slice(0, month);
    } else {
      this.monthList = [
        { key: 'JAN', value: 0 },
        { key: 'FEB', value: 1 },
        { key: 'MAR', value: 2 },
        { key: 'APR', value: 3 },
        { key: 'MAY', value: 4 },
        { key: 'JUN', value: 5 },
        { key: 'JUL', value: 6 },
        { key: 'AUG', value: 7 },
        { key: 'SEP', value: 8 },
        { key: 'OCT', value: 9 },
        { key: 'NOV', value: 10 },
        { key: 'DEC', value: 11 },
      ];
    }
    this.queryparmsMembers = {
      startDate:
        this.startDateMember.getFullYear() +
        '-' +
        (this.startDateMember.getMonth() + 1) +
        '-' +
        this.startDateMember.getDate(),
      endDate:
        this.endDateMember.getFullYear() +
        '-' +
        (this.endDateMember.getMonth() + 1) +
        '-' +
        this.endDateMember.getDate(),
    };
    if (this.selectedMonth == undefined && this.selectedYear != new Date().getFullYear()) {
      this.startDateMember = new Date(Number(this.selectedYear), 0, 1);
      this.endDateMember = new Date(Number(this.selectedYear), 11, 31);
    }else if(this.selectedMonth == undefined && this.selectedYear == new Date().getFullYear()){
      this.startDateMember = new Date(Number(this.selectedYear), 0, 1);
      this.endDateMember = new Date();
    }else{
      this.startDateMember = new Date(Number(this.selectedYear), this.selectedMonth, 1);
      this.endDateMember = new Date(Number(this.selectedYear), this.selectedMonth, this.monthArray[this.selectedMonth]);
    }
    // this.startDateMember = new Date(Number(this.selectedYear), 0, 1);
    // this.endDateMember = new Date(Number(this.selectedYear), 11, 31);
    //comment below
    //  this.startDateMember = this.datePipe.transform(this.startDateMember,'dd/MM/yyyy')

    this.getMembersData();
  }

  selectMonth(month: any) {
    this.startDateMember = new Date(
      Number(this.selectedYear),
      this.selectedMonth,
      1
    );
    this.endDateMember = new Date(
      Number(this.selectedYear),
      this.selectedMonth,
      this.monthArray[this.selectedMonth]
    );

    this.getMembersData();
  }
  download(url: any, downloadName: any) {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = downloadName;
    a.click();
    document.body.removeChild(a);
  }
  membersExportBtn() {
    // this.queryparmsMembers = {
    //   year: this.memberYear ? this.memberYear : '2023',
    // };
    this.services
      .dashboardExportsMembers(this.queryparmsMembers)
      .subscribe((res) => {
        console.log(res);
        this.exportMembers = res.sheetUrl;
        this.download(this.exportMembers, 'sheetUrl');
      });
  }
}
