<ng-container *ngIf="
    permission?.Donation?.analyse ||
      permission?.Donation?.edit ||
      permission?.Donation?.view;
    else alternateTemplate
  " class="main">
  <div class="container-fluid bg-white">
    <div class="user-list-header d-flex pt-3 pb-1 ml-1">
      <ng-container *ngIf="permission?.Donation?.view">
        <div class="user-list-heading d-flex">
          <img src="../../assets/images/panel_images/donations.svg" class="user-list mr-2" />
          <h6 class="mt-2">Donations</h6>
        </div>
        <div class="search-bar ml-3 mr-2">
          <mat-form-field class="example-full-width" appearance="fill">
            <input matInput placeholder="Search by users..." #input [(ngModel)]="searchClear"
              (keyup)="searchDropDown($event)" />
            <mat-icon matSuffix><img src="../../assets/images/panel_images/search-icon.svg"
                class="calendra-img" /></mat-icon>
          </mat-form-field>
        </div>
        <app-showloader></app-showloader>
        <div class="service-list-drop-down mr-2">
          <mat-form-field appearance="fill">
            <div class="arrow-up"></div>
            <span matPrefix>Donation Cause:&nbsp;</span>
            <mat-icon matSuffix><img src="../../assets/images/panel_images/arrow-1.svg"
                class="calendra-img" /></mat-icon>
            <mat-select [formControl]="serviceControl" multiple placeholder="Select" disableOptionCentering
              panelClass="donationPanelClass" (selectionChange)="donationCauseDropDown($event)" #closeSerivce
              [(ngModel)]="donationCauseClear">
              <mat-select-trigger>
                {{serviceControl.value?.[0] || ''}}
                <span *ngIf="(serviceControl.value?.length || 0) > 1" class="example-additional-selection">
                  (+{{ (serviceControl.value?.length || 0) - 1 }}
                  {{ serviceControl.value?.length === 2 ? "other" : "others" }})
                </span>
              </mat-select-trigger>
              <mat-option class="services-view" *ngFor="let viewService of services" [value]="viewService">{{
                viewService }}</mat-option>
              <div class="apply-btn-option pt-4 pb-3">
                <button type="button" class="btn btn-apply" (click)="closeSerivce.close()">
                  Apply
                </button>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="calendra-field mr-2">
          <mat-form-field class="example-full-width" appearance="fill">
            <span matPrefix>Date:&nbsp;</span>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate matInput placeholder="Start date  " readonly="readonly"
                (dateChange)="onChangeDate($event)" [(ngModel)]="dateClear" />
              <input matEndDate matInput placeholder="End date" readonly="readonly"
                (dateChange)="onChangeToDate($event)" [(ngModel)]="dateClear1" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker">
              <mat-icon matDatepickerToggleIcon><img src="../../assets/images/panel_images/c-11.svg"
                  class="calendra-img" /></mat-icon>
            </mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>

        <div class="user-list-drop-down mr-2">
          <mat-form-field appearance="fill">
            <div class="arrow-up"></div>
            <span matPrefix>Payment:&nbsp;</span>
            <mat-icon matSuffix><img src="../../assets/images/panel_images/arrow-1.svg"
                class="calendra-img" /></mat-icon>
            <mat-select [formControl]="statusOption" multiple placeholder="Select" disableOptionCentering
              panelClass="eligablePanelClass" (keyup)="applyFilter($event)" (selectionChange)="serviceDropDown($event)"
              #closeSelect [(ngModel)]="paymentClear">
              <mat-select-trigger>
                {{statusOption.value?.[0] || ''}}
                <span *ngIf="(statusOption.value?.length || 0) > 1" class="example-additional-selection">
                  (+{{ (statusOption.value?.length || 0) - 1 }}
                  {{ statusOption.value?.length === 2 ? "other" : "others" }})
                </span>
              </mat-select-trigger>
              <mat-option class="services-view" *ngFor="let viewStatus of status" [value]="viewStatus.key">{{
                viewStatus.value }}</mat-option>
              <div class="apply-btn-option pt-4 pb-3">
                <button type="button" class="btn btn-apply" (click)="closeSelect.close()">
                  Apply
                </button>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="export-button mr-3">
          <button type="button" class="cursor" (click)="donationDetailExport()">Export
          </button>
        </div>
        <!-- <div class="create-btn-section mx-2">
          <button
            type="button"
            class="create-btn"
            (click)="donationDataExportBtn()"
          >
            Export
          </button>
        </div> -->
        <div class="graph cursor mr-2" (click)="reset()">
          <img src="../../assets/images/panel_images/reset-1.png" class="reset-img ml-2" />
        </div>
      </ng-container>

      <div class="graph cursor mt-1 mr-2 ml-auto" *ngIf="permission?.Donation?.analyse">
        <img src="../../assets/images/panel_images/graph.svg" class="graph-img ml-2" (click)="Analytics()" />
      </div>
    </div>
  </div>
  <ng-container *ngIf="permission?.Donation?.view; else listTemplate" class="container-fluid pt-4 ml-1">
    <div class="d-flex p-4">
      <div title="₹ {{ totalAmount }}" class="card users-count mr-3 total-user-bg">
        <div class="card-body d-flex">
          <img src="../../assets/images/panel_images/d-1.svg" id="users-total" class="mr-2" />
          <div class="card-details-users">
            <h4 class="mt-3">Total Donations</h4>
            <h2 class="mt-2">
              ₹ {{ totalAmount ? (totalAmount | records) : 0 }}
            </h2>
          </div>
        </div>
      </div>
      <div class="card users-count mr-3 total-active-bg">
        <div class="card-body d-flex">
          <img src="../../assets/images/panel_images/d-2.svg" id="users-total" class="mr-2" />
          <div class="card-details-users">
            <h4 class="mt-3">Donations</h4>
            <h2 class="mt-2">
              {{ numberOfDonation ? (numberOfDonation | records) : 0 }}
            </h2>
          </div>
        </div>
      </div>
      <div class="card users-count mr-3 total-existing-bg">
        <div class="card-body d-flex">
          <img src="../../assets/images/panel_images/d-3.svg" id="users-total" class="mr-2" />
          <div class="card-details-users">
            <h4 class="mt-3">Total Donors</h4>
            <h2 class="mt-2">{{ totalDonar ? (totalDonar | records) : 0 }}</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="table mt-5 mb-5 mr-5">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="PaymentID">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Payment_ID</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h6 class="mt-2">
              {{
              element?.payment?.paymentId
              ? element?.payment?.paymentId
              : "N/A"
              }}
            </h6>
          </td>
        </ng-container>
        <ng-container matColumnDef="user">
          <th mat-header-cell class="th-header-table" *matHeaderCellDef>
            <h2>user</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              <img *ngIf="element.user[0]?.profile != null" src="{{ element?.user[0]?.profile }}"
                class="user-image-table mr-2" />
              <ngx-avatar *ngIf="element.user[0]?.profile == null" class="mr-2" initialsSize="1" size="40"
                name="{{ element.name }}"></ngx-avatar>
              <ngx-avatar *ngIf="element?.name == null || element?.name == ''" class="mr-2 name-a" initialsSize="1"
                size="40" name="N/A"></ngx-avatar>

              <div class="">
                <h6 class="mt-2">{{ element.name ? element.name : "N/A" }}</h6>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="Phoneno">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Phone no.</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h4>
              <span *ngIf="element?.phone != null && element?.phone != ''">+91-</span>{{ element?.phone ? element?.phone
              : "N/A" }}
            </h4>
          </td>
        </ng-container>

        <ng-container matColumnDef="receivedin">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Donation cause</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h6>
              {{ element.donates[0]?.title ? element.donates[0].title : "-" }}
            </h6>
          </td>
        </ng-container>

        <ng-container matColumnDef="Amount">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Amount</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h6>₹ {{ element.amount ? element.amount : "N/A" }}</h6>
          </td>
        </ng-container>
        <ng-container matColumnDef="Dateoftrans">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Date of trans.</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h6>
              {{ element.createdAt | date : "MMMM d, y" }} <br />
              {{ element.createdAt | date : "shortTime" }}
            </h6>
          </td>
        </ng-container>
        <ng-container matColumnDef="PaymentMode">
          <th mat-header-cell class="th-header-table" *matHeaderCellDef>
            <h2>Payment Mode</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              <img *ngIf="element?.payment?.method == 'UPI'" src="../../assets/images/panel_images/upi.svg"
                class="upi-image-table mr-2" />
              <div class="">
                <h6>
                  {{ element?.payment?.method ? element?.payment?.method : "" }}
                </h6>
              </div>
            </div>
          </td>
        </ng-container>
        <!-- <ng-container matColumnDef="Origin">
                <th mat-header-cell *matHeaderCellDef>
                  <h2>Origin</h2>
                </th>
                <td mat-cell *matCellDef="let element">
                  <div class="d-flex" *ngIf="element.typeOfBug == 'IOS' || element.typeOfBug == 'Android'">
                    <img src="../../assets/images/panel_images/moblie-1.svg" class="user-image-table mr-2" />
                    <div class="">
                      <h6 class="mt-2">{{ element.typeOfBug }}</h6>

                    </div>
                  </div>
                  <div class="d-flex" *ngIf="element.typeOfBug == 'Website'">
                    <img src="../../assets/images/panel_images/web-1.svg" class="user-image-table mr-2" />
                    <div class="">
                      <h6 class="mt-2">{{ element.typeOfBug }}</h6>

                    </div>
                  </div>
                </td>
              </ng-container> -->
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Action</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button mat-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>
                <img src="../../assets/images/panel_images/action-dot.svg" id="users-total"
                  class="action-dots" /></mat-icon>
              <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" place></div>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button class="View" mat-menu-item value="Edit" routerLink="./Download-invoice"
                [queryParams]="{ _id: element._id }">
                <img src="../../assets/images/panel_images/view.svg" class="mr-3" />
                <span>View</span>
              </button>
              <!-- <button class="View" mat-menu-item value="Edit">
                <img
                  src="../../assets/images/panel_images/invoice.svg"
                  class="mr-3"
                />
                <span>Download Invoice</span>
              </button> -->
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data available</td>
        </tr>
      </table>
      <mat-paginator (page)="pageChanged($event)" [length]="totalDocument" [pageIndex]="currentPage"
        [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator>
    </div>
  </ng-container>
</ng-container>
<ng-template #listTemplate>
  <div class="mt-5 text-center">
    <img src="../../assets//images/panel_images/notPermitted.png" width="120" alt="" />
    <p class="mt-5">You dont have permission to access this feature</p>
  </div>
</ng-template>
<ng-template #alternateTemplate>
  <div class="mt-5 text-center">
    <img src="../../assets//images/panel_images/notPermitted.png" width="120" alt="" />
    <p class="mt-5">You dont have permission to access this feature</p>
  </div>
</ng-template>