
<div class="">
    <div class="close-btn cursor" (click)="close()">
        <img src="../../../assets/images/panel_images/close-d.svg" class="c-k-img">
    </div>
    <div class="request-img" *ngFor="let user of details">
        <img [src]="user.service[0].image ? user.service[0].image : ''" class="img-cake">
        <div class="birthday_cake_header">
        <h4>{{user.service[0].title ? user.service[0].title : ''}}</h4>

        </div>
    </div>
    
        <div class="details-section mt-3" *ngFor="let user of details">
             <div class="d-flex"  *ngIf="user.service[0].title != 'Birthday Cake' ">
                <div class="details-heading details-services">
                    <h4>Who’s This For:</h4>
                </div>
                <div class="details-heading-user">
                    <h4>{{user.whoIsThisFor}}</h4>
                </div>
            </div>
             <div class="d-flex"  *ngIf="user.service[0].title != 'Birthday Cake' ">
                <div class="details-heading details-services">
                    <h4>Receiver’s Name:</h4>
                </div>
                <div class="details-heading-user">
                    <h4>{{user.receiverName}}</h4>
                </div>
            </div>
            <div class="d-flex"  *ngIf="user.service[0].title != 'Birthday Cake' ">
                <div class="details-heading details-services">
                    <h4>Receiver’s Age:</h4>
                </div>
                <div class="details-heading-user">
                    <h4>{{user.receiverAge}}</h4>
                </div>
            </div>
            <div class="d-flex"  *ngIf="user.service[0].title == 'Birthday Cake' ">
                <div class="details-heading details-services">
                    <h4>Date:</h4>
                </div>
                <div class="details-heading-user">
                    <h4>{{user.dob | date: "MMMM d, y"}}</h4>
                </div>
            </div>
            <div class="d-flex" *ngIf="user.service[0].title != 'Birthday Cake'">
                <div class="details-heading details-services">
                    <h4>Date:</h4>
                </div>
                <div class="details-heading-user">
                    <h4>{{user.date | date: "MMMM d, y"}}</h4>
                </div>
            </div>
            <div class="d-flex" >
                <div class="details-heading details-services">
                    <h4>Address:</h4>
                </div>
                <div class="details-heading-user">
                    <h4>{{user.address}}</h4>
                </div>
            </div>
             <div class="d-flex" >
                <div class="details-heading details-services">
                    <h4>Pincode:</h4>
                </div>
                <div class="details-heading-user">
                    <h4>{{user.pinCode ? user.pinCode : '-'}}</h4>
                </div>
            </div>
             <div class="d-flex" >
                <div class="details-heading details-services">
                    <h4>Landmark:</h4>
                </div>
                <div class="details-heading-user">
                    <h4>{{user.landMark ? user.landMark : '-'}}</h4>
                </div>
            </div>
            <div class="d-flex" >
                <div class="details-heading details-services">
                    <h4>State:</h4>
                </div>
                <div class="details-heading-user">
                    <h4>{{user.state}}</h4>
                </div>
            </div>
            <div class="d-flex" >
                <div class="details-heading details-services">
                    <h4>City:</h4>
                </div>
                <div class="details-heading-user">
                    <h4>{{user.city}}</h4>
                </div>
            </div>
              <div class="schelude-request-btn text-center mt-3 mb-2">
            <button class="request-btn-service btn" >Schedule this request</button>
         </div>
        </div>
</div>
