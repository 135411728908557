<div class="container-fluid">
    <!-- <div class="back-btn cursor" (click)="close()">
        <img src="../../../assets/images/panel_images/back-btn-1.svg">
    </div> -->

    <div class="request-details-section">
        <div class="success-img text-center pt-3 pb-2">
            <img src="../../../assets/images/panel_images/thank.gif" style="width:225px">
        </div>
         <div class="cms-heading-succes-section">
            <h5>Password Updated Successfully!</h5>
         </div>
         <div class="okay-btn pt-5 mb-3 text-center">
            <button type="button" class="btn btn-okay" (click)="close()">Ok</button>
         </div>

    </div>
</div>