import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements DoCheck {
  title = 'adminpanel';
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  showLogin: boolean = true;
  sidebarOpen: boolean = true;
  notificationsOpen: boolean = false;
  showDashboard: boolean = false;
  constructor(public router: Router) {
    // if (localStorage.getItem('user') == null) {
    //   this.router.navigate(['/admin/login']);
    // } else {
    //   this.showDashboard = true;
    //   this.showLogin = false;
    // }
  }

  sidebarToggler() {
    this.sidebarOpen = !this.sidebarOpen;
    // if(this.sidebarOpen == true ){
    // }
  }
  ngDoCheck(): void {
    if (localStorage.getItem('token') == null) {
      this.showLogin = true;
      this.showDashboard = false;
    } else {
      this.showLogin = false;
      this.showDashboard = true;
    }
  }
}
