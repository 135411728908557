import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { DashboardServicesService } from 'src/app/_services/dashboard-services.service';
import { months } from '../../app.constant';

@Component({
  selector: 'app-supportanalytics',
  templateUrl: './supportanalytics.component.html',
  styleUrls: ['./supportanalytics.component.css'],
})
export class SupportanalyticsComponent implements OnInit {
  highcharts = Highcharts;
  supportchartOptions: any;
  dropDownSelection: any;
  calendraDate: any;
  today = new Date();
  Date: any;
  exportSupport: any;
  supportQueryParms: any;
  supportYear: any;
  supportMembers: any;
  supportMembersCharts: any;
  supportOpenArray: any = [];
  supportdArray: any = [];
  supportMembersMonths: any = [];
  toDate: any;
  monthArray = months;
  yearList:any = [
    '2023',
    '2022',
    '2021',
    '2020',
    '2019',
    '2018',
    '2017',
    '2016',
    '2015',
    '2014',
    '2013',
    '2012',
    '2011',
    '2010',
    '2009',
    '2008',
    '2007',
    '2006',
    '2005',
    '2004',
    '2003',
    '2002',
    '2001',
    '2000'
]
// selectedYear:any = '2023';
monthList = [
    { key: "JAN", value : 0},
    { key: "FEB", value : 1},
    { key: "MAR", value : 2},
    { key: "APR", value : 3},
    { key: "MAY", value : 4},
    { key: "JUN", value : 5},
    { key: "JUL", value : 6},
    { key: "AUG", value : 7},
    { key: "SEP", value : 8},
    { key: "OCT", value : 9},
    { key: "NOV", value : 10},
    { key: "DEC", value : 11}
]
  monthListTvSupport = this.monthList;
  selectedYearSupport:any = '2023';
  selectedMonthSupport:any;
  startDateTvSupport:Date =new Date(2023,0,1);
  endDateTvSupport:Date = new Date();
  totalsupport: any;
  activesupport: any;
  completedsupport: any;
  cancelledsupport: any;
  constructor(private services: DashboardServicesService) {
    
  }

  ngOnInit(): void {
    if (this.selectedYearSupport == new Date().getFullYear()) {
      let month = new Date().getMonth() + 1;
      this.monthListTvSupport = this.monthListTvSupport.slice(0, month);
    } else {
      this.monthListTvSupport = [
        { key: 'JAN', value: 0 },
        { key: 'FEB', value: 1 },
        { key: 'MAR', value: 2 },
        { key: 'APR', value: 3 },
        { key: 'MAY', value: 4 },
        { key: 'JUN', value: 5 },
        { key: 'JUL', value: 6 },
        { key: 'AUG', value: 7 },
        { key: 'SEP', value: 8 },
        { key: 'OCT', value: 9 },
        { key: 'NOV', value: 10 },
        { key: 'DEC', value: 11 },
      ];
    }
    this.getSupportData();
  }
  download(url: any, downloadName: any) {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = downloadName;
    a.click();
    document.body.removeChild(a);
  }
  supportDataExportBtn() {
    this.services.dashboardExportsSupport(this.supportQueryParms).subscribe((res) => {
      console.log(res);
      this.exportSupport = res.sheetUrl;
      this.download(this.exportSupport, 'sheetUrl');
    });
  }
  onChangeDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.supportQueryParms = {
        year: this.supportYear ? this.supportYear : '2023',
      };
      this.services
        .dashboardSupport(this.supportQueryParms)
        .subscribe((result) => {
          console.log(result);
          this.supportMembers = result.message;
          // this.totalsupport = result.totalRecords;
          // this.activesupport = result.active;
          // this.completedsupport = result.completed;
          // this.cancelledsupport = result.cancelled;
          this.supportMembersCharts = this.supportMembers.forEach(
            (item: any) => {
              this.supportOpenArray.push(item.open);
              console.log(this.supportOpenArray);
              this.supportdArray.push(item.closed);
              this.supportMembersMonths.push(item.month);
            }
          );
          this.supportchartOptions = {
            chart: {
              type: 'spline',
            },
            title: {
              text: '',
            },
            legend: { enabled: false },

            credits: {
              enabled: false,
            },
            //  subtitle: {
            //     text: "Source: WorldClimate.com"
            //  },
            xAxis: {
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              lineWidth: 0,
              lineColor: 'transparent',
              title: {
                text: '',
              },
              labels: {
                style: {
                  color: '#5A657F',
                  fontSize: 12,
                  fontWeight: 600,
                  fontFamily: 'Inter',
                },
              },
              categories: this.supportMembersMonths,
            },
            yAxis: {
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              title: {
                text: '',
              },
              labels: {
                format: '{value}',
                style: {
                  color: '#425466',
                  fontSize: 12,
                  fontWeight: 600,
                  fontFamily: 'Inter',
                },
              },
            },
            //  tooltip: {
            //     valueSuffix:" °C"
            //  },
            plotOptions: {
              series: {
                marker: {
                  enabled: false,
                },
              },
            },

            colors: ['#ff8d00', '#0DBC67'],
            series: [
              {
                name: 'Open',
                data: this.supportOpenArray,
              },
              {
                name: 'Closed',
                data: this.supportdArray,
              },
            ],
          };
        });
    }
  }
  onChangeToDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.supportQueryParms = {
        year: this.supportYear ? this.supportYear : '2023',
      };
      this.services
        .dashboardSupport(this.supportQueryParms)
        .subscribe((result) => {
          console.log(result);
          this.supportMembers = result.message;
          // this.totalsupport = result.totalRecords;
          // this.activesupport = result.active;
          // this.completedsupport = result.completed;
          // this.cancelledsupport = result.cancelled;
          this.supportMembersCharts = this.supportMembers.forEach(
            (item: any) => {
              this.supportOpenArray.push(item.open);
              console.log(this.supportOpenArray);
              this.supportdArray.push(item.closed);
              this.supportMembersMonths.push(item.month);
            }
          );
          this.supportchartOptions = {
            chart: {
              type: 'spline',
            },
            title: {
              text: '',
            },
            legend: { enabled: false },

            credits: {
              enabled: false,
            },
            //  subtitle: {
            //     text: "Source: WorldClimate.com"
            //  },
            xAxis: {
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              lineWidth: 0,
              lineColor: 'transparent',
              title: {
                text: '',
              },
              labels: {
                style: {
                  color: '#5A657F',
                  fontSize: 12,
                  fontWeight: 600,
                  fontFamily: 'Inter',
                },
              },
              // categories: this.supportMembersMonths,
              categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
            },
            yAxis: {
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              title: {
                text: '',
              },
              labels: {
                format: '{value}',
                style: {
                  color: '#425466',
                  fontSize: 12,
                  fontWeight: 600,
                  fontFamily: 'Inter',
                },
              },
            },
            //  tooltip: {
            //     valueSuffix:" °C"
            //  },
            plotOptions: {
              series: {
                marker: {
                  enabled: false,
                },
              },
            },

            colors: ['#ff8d00', '#0DBC67'],
            series: [
              {
                name: 'Open',
                data: this.supportOpenArray,
              },
              {
                name: 'Closed',
                data: this.supportdArray,
              },
            ],
          };
        });
    }
  }

  AnalyticsDateChange(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
  }





    onChangeSupportDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    this.startDateTvSupport = new Date(e.target.value);
    this.endDateTvSupport = new Date(e.target.value);
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getSupportData();
    }
  }

  getSupportData(){
    this.supportOpenArray = [];
    this.supportdArray = [];
    this.supportMembersMonths = [];
    this.supportMembers = [];
    this.supportMembersCharts = [];

    this.supportQueryParms = {
      startDate: this.startDateTvSupport.getFullYear()+'-'+(this.startDateTvSupport.getMonth()+1)
      +'-'+this.startDateTvSupport.getDate(),
      endDate: this.endDateTvSupport.getFullYear()+'-'+(this.endDateTvSupport.getMonth()+1)
      +'-'+this.endDateTvSupport.getDate(),
    };
    if (this.selectedMonthSupport != undefined) {
      this.supportQueryParms['groupBy'] = 'day';
    }

    if (
      this.startDateTvSupport.getFullYear() == this.endDateTvSupport.getFullYear() &&
      this.startDateTvSupport.getMonth() == this.endDateTvSupport.getMonth() &&
      this.startDateTvSupport.getDate() != this.endDateTvSupport.getDate()

    ) {
      this.supportQueryParms['groupBy'] = 'day';
    }
    if (
      this.startDateTvSupport.getFullYear() == this.endDateTvSupport.getFullYear() &&
      this.startDateTvSupport.getMonth() == this.endDateTvSupport.getMonth() &&
      this.startDateTvSupport.getDate() == this.endDateTvSupport.getDate()

    ) {
      this.supportQueryParms['groupBy'] = 'hour';
    }
    // if(this.selectedMonthSupport != undefined){
    //   this.supportQueryParms['groupBy'] = 'day';
    // }
    // if(this.startDateTvSupport.getFullYear() == this.endDateTvSupport.getFullYear() &&
    // this.startDateTvSupport.getMonth() == this.endDateTvSupport.getMonth()){
    //   this.supportQueryParms['groupBy'] = 'day';
    // }

    this.services
      .dashboardSupport(this.supportQueryParms)
      .subscribe((result) => {
        console.log(result);
        this.supportMembers = result.data;
        this.totalsupport = result.totalRecords;
        this.activesupport = result.active;
        this.completedsupport = result.completed;
        this.cancelledsupport = result.cancelled;
        this.supportMembersCharts = this.supportMembers.forEach(
          (item: any) => {
            this.supportOpenArray.push(item.open);
            this.supportdArray.push(item.closed);
            const day = item.date?.split('-')
            const dateText = item.day ? item.day + '-' : '';
            // const dateText = item.day ? item.day + '-' : '';
            if (item.date != undefined && item.hour == undefined) {
              this.supportMembersMonths.push(day[2] + '-' + item.month)
            }else if(item.date != undefined && item.hour != undefined){
              this.supportMembersMonths.push(item.hour + 1 + ':00')
            }else{
              this.supportMembersMonths.push(dateText + item.month + '-' + item.year);
            }

            // this.supportMembersMonths.push(dateText + item.month + '-' + item.year);
          }
        );
        this.supportchartOptions = {
          chart: {
            type: 'spline',
          },
          title: {
            text: '',
          },
          legend: { enabled: false },

          credits: {
            enabled: false,
          },
          //  subtitle: {
          //     text: "Source: WorldClimate.com"
          //  },
          xAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0,
            lineColor: 'transparent',
            title: {
              text: '',
            },
            labels: {
              style: {
                color: '#5A657F',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
            categories: this.supportMembersMonths,
          },
          yAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            title: {
              text: '',
            },
            labels: {
              format: '{value}',
              style: {
                color: '#425466',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
          },
          //  tooltip: {
          //     valueSuffix:" °C"
          //  },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
              },
            },
          },

          colors: ['#ff8d00', '#0DBC67'],
          series: [
            {
              name: 'Open',
              data: this.supportOpenArray,
            },
            {
              name: 'Closed',
              data: this.supportdArray,
            },
          ],
        };
      });
  }

  onChangeSuppportToDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
    this.endDateTvSupport = new Date(e.target.value);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getSupportData();
    }
  }

  resetSupportFilter(){
    this.selectedYearSupport = '2023';
    this.selectedMonthSupport = undefined;
    this.startDateTvSupport =new Date(2023,0,1);
    this.endDateTvSupport = new Date();
    this.getSupportData();
  }

  selectYearSupport(event:any){
    if(this.selectedYearSupport == new Date().getFullYear()){
      let month = new Date().getMonth() + 1;
      this.monthListTvSupport = this.monthListTvSupport.slice(0,month);
  }else{
      this.monthListTvSupport = [
       { key: "JAN", value : 0},
       { key: "FEB", value : 1},
       { key: "MAR", value : 2},
       { key: "APR", value : 3},
       { key: "MAY", value : 4},
       { key: "JUN", value : 5},
       { key: "JUL", value : 6},
       { key: "AUG", value : 7},
       { key: "SEP", value : 8},
       { key: "OCT", value : 9},
       { key: "NOV", value : 10},
       { key: "DEC", value : 11}
      ]
  }
  if (this.selectedMonthSupport == undefined && this.selectedYearSupport != new Date().getFullYear()) {
    this.startDateTvSupport = new Date(Number(this.selectedYearSupport), 0, 1);
    this.endDateTvSupport = new Date(Number(this.selectedYearSupport), 11, 31);
  }else if(this.selectedMonthSupport == undefined && this.selectedYearSupport == new Date().getFullYear()){
    this.startDateTvSupport = new Date(Number(this.selectedYearSupport), 0, 1);
    this.endDateTvSupport = new Date();
  }else{
    this.startDateTvSupport = new Date(Number(this.selectedYearSupport), this.selectedMonthSupport, 1);
    this.endDateTvSupport = new Date(Number(this.selectedYearSupport), this.selectedMonthSupport, this.monthArray[this.selectedMonthSupport]);
  }
      //  this.startDateTvSupport = new Date(Number(this.selectedYearSupport),0,1);
      //  this.endDateTvSupport = new Date(Number(this.selectedYearSupport),11,31);
      //prev|
      //  this.startDateMember = this.datePipe.transform(this.startDateMember,'dd/MM/yyyy')

      this.getSupportData();
  }

  selectMonthSupport(month:any){
    this.startDateTvSupport = new Date(Number(this.selectedYearSupport),this.selectedMonthSupport,1);
    this.endDateTvSupport = new Date(Number(this.selectedYearSupport),this.selectedMonthSupport,
    this.monthArray[this.selectedMonthSupport]);

    this.getSupportData();
  }
}
