import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MarkAsClosedcommonDialogComponent } from '../mark-as-closedcommon-dialog/mark-as-closedcommon-dialog.component';
import { MarkAsInvalidcommonDialogComponent } from '../mark-as-invalidcommon-dialog/mark-as-invalidcommon-dialog.component';
import { DashboardServicesService } from '../_services/dashboard-services.service';
import { ViewissueitsupportComponent } from './viewissueitsupport/viewissueitsupport.component';
export interface PeriodicElement {
  Requestno: string;
  user: string;
  Service: any;
  RECEIVERSNAME: any;
  phoneno: any;
  FAMILY: any;
  Date: any;
  Status: string;
  Action: any;
  image: any;
}
@Component({
  selector: 'app-itsupport',
  templateUrl: './itsupport.component.html',
  styleUrls: ['./itsupport.component.css'],
})
export class ItsupportComponent implements OnInit {
  displayedColumns: string[] = [
    'Requestno',
    'user',
    'Service',
    'RECEIVERSNAME',
    'Date',
    'Status',
    'Action',
  ];
  @ViewChild(MatPaginator) paginator: any = MatPaginator;
  queryParams: { pageLimit: any; pageNumber: any };
  pageSize: any;
  currentPage: any;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  itSupportDetails: any;
  role: any;
  total: any;
  open: any;
  invalid: any;
  closed: any;
  queryParamsStatus: { status: any };
  statusCompleted: any = 'closed';
  userDetailsParams: string;
  onLoadPage = new EventEmitter();
  statusInvalid: any = 'invalid';
  constructor(
    private fb: FormBuilder,
    private service: DashboardServicesService,
    private dialog: MatDialog
  ) {}
  ELEMENT_DATA: Element[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  ngOnInit(): void {
    this.queryParams = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.itSupportData(this.queryParams).subscribe((res) => {
      console.log(res);
      this.ELEMENT_DATA = res.message;
      this.total = res.total;
      this.open = res.open;
      this.invalid = res.invalid;
      this.closed = res.closed;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  pageChanged(event: PageEvent) {
    console.log(event);
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    this.queryParams = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    this.service.itSupportData(this.queryParams).subscribe((res) => {
      console.log(res);
      this.ELEMENT_DATA = res.message;
      this.total = res.total;
      this.open = res.open;
      this.invalid = res.invalid;
      this.closed = res.closed;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }
  view() {
    this.dialog.open(ViewissueitsupportComponent, {
      disableClose: true,
    });
  }
  getSupportRequest() {
    this.service.itSupportData(this.queryParams).subscribe((res) => {
      this.ELEMENT_DATA = res.message;
      this.total = res.total;
      this.open = res.open;
      this.invalid = res.invalid;
      this.closed = res.closed;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  markAsClose(id: any) {
    this.queryParamsStatus = {
      status: this.statusCompleted ? this.statusCompleted : '',
    };
    this.service
      .itSupportChangeStatus(id, this.queryParamsStatus)
      .subscribe((response) => {
        this.dialog.open(MarkAsClosedcommonDialogComponent, {
          disableClose: true,
        });
        this.getSupportRequest();
        this.onLoadPage.emit();
      });
  }
  markAsInvalid(id: any) {
    this.queryParamsStatus = {
      status: this.statusInvalid ? this.statusInvalid : '',
    };
    this.service
      .itSupportChangeStatus(id, this.queryParamsStatus)
      .subscribe((response) => {
        this.dialog.open(MarkAsInvalidcommonDialogComponent, {
          data: {
            disableClose: true,
          },
        });
        this.getSupportRequest();
        this.onLoadPage.emit();
      });
  }
}
