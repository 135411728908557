<ng-container *ngIf="
permissionsArray.prayer_Requests.analyse ||
  permissionsArray.prayer_Requests.edit ||
  permissionsArray.prayer_Requests.view;
else alternateTemplate
" class="main">
  <div class="container-fluid bg-white">
    <div class="user-list-header d-flex pt-3 pb-1 ml-1">
      <ng-container *ngIf="permissionsArray.prayer_Requests.view">
        <div class="user-list-heading d-flex">
          <img src="../../assets/images/panel_images/p-r.svg" class="user-list mr-2" />
          <h6 class="mt-2">Prayer Request</h6>
        </div>
        <div class="search-bar ml-3">
          <mat-form-field class="example-full-width" appearance="fill">
            <input [(ngModel)]="searchClear" matInput (keyup)="searchDropDown($event)"
              placeholder="Search by user or Request No..." #input />
            <mat-icon matSuffix><img src="../../assets/images/panel_images/search-icon.svg"
                class="calendra-img" /></mat-icon>
          </mat-form-field>
        </div>
        <div class="calendra-field mr-3 ml-3">
          <mat-form-field appearance="fill">
            <div class="arrow-up"></div>
            <span matPrefix>Language:&nbsp;</span>
            <mat-icon matSuffix><img src="../../assets/images/panel_images/arrow-1.svg"
                class="calendra-img" /></mat-icon>
            <mat-select [formControl]="languageControl" multiple placeholder="Select..." disableOptionCentering
              panelClass="eligablePanelClass" #languageservice (selectionChange)="serviceDropDown($event)"
              [(ngModel)]="languageClear">
              <mat-select-trigger>
                {{languageControl.value?.[0] || ''}}
                <span *ngIf="(languageControl.value?.length || 0) > 1" class="example-additional-selection">
                  (+{{ (languageControl.value?.length || 0) - 1 }}
                  {{ languageControl.value?.length === 2 ? "other" : "others" }})
                </span>
              </mat-select-trigger>
              <mat-option class="services-view" *ngFor="let viewService of language" [value]="viewService.key">{{
                viewService.key }}</mat-option>
              <div class="apply-btn-option pt-4 pb-3">
                <button type="button" class="btn btn-apply" (click)="languageservice.close()">
                  Apply
                </button>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="service-list-drop-down mr-2">
          <mat-form-field appearance="fill">
            <div class="arrow-up"></div>
            <span matPrefix>Prayer Cause:&nbsp;</span>
            <mat-icon matSuffix><img src="../../assets/images/panel_images/arrow-1.svg"
                class="calendra-img" /></mat-icon>
            <mat-select [formControl]="PrayerCauseControl" multiple placeholder="Select" disableOptionCentering
              panelClass="prayerPanelClass" #prayerCause (selectionChange)="prayerCauseDropDown($event)"
              [(ngModel)]="prayerCauseClear">
              <mat-select-trigger>
                {{PrayerCauseControl.value?.[0] || ''}}
                <span *ngIf="(serviceControl.value?.length || 0) > 1" class="example-additional-selection">
                  (+{{ (PrayerCauseControl.value?.length || 0) - 1 }}
                  {{
                  PrayerCauseControl.value?.length === 2 ? "other" : "others"
                  }})
                </span>
              </mat-select-trigger>
              <mat-option class="services-view" *ngFor="let viewService of prayerreq" [value]="viewService.title">{{
                viewService.title }}</mat-option>
              <div class="apply-btn-option pt-4 pb-3">
                <button type="button" class="btn btn-apply" (click)="prayerCause.close()">
                  Apply
                </button>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="user-list-drop-down mr-2">
          <mat-form-field appearance="fill">
            <div class="arrow-up"></div>
            <span matPrefix>Status:&nbsp;</span>
            <mat-icon matSuffix><img src="../../assets/images/panel_images/arrow-1.svg"
                class="calendra-img" /></mat-icon>
            <mat-select [formControl]="statusOption" multiple placeholder="Select..." disableOptionCentering
              panelClass="eligablePanelClass" (keyup)="applyFilter($event)" #closeSelect
              (selectionChange)="statusDropDown($event)" [(ngModel)]="statusClear">
              <mat-select-trigger>
                {{statusOption.value?.[0] || ''}}
                <span *ngIf="(statusOption.value?.length || 0) > 1" class="example-additional-selection">
                  (+{{ (statusOption.value?.length || 0) - 1 }}
                  {{ statusOption.value?.length === 2 ? "other" : "others" }})
                </span>
              </mat-select-trigger>
              <mat-option [class.eligable-option]="viewStatus === 'in-progress'"
                [class.not-eligable-option]="viewStatus === 'completed'"
                [class.not-member-option]="viewStatus === 'cancelled'" *ngFor="let viewStatus of status"
                [value]="viewStatus.value">{{ viewStatus.key }}</mat-option>
              <div class="apply-btn-option pt-4 pb-3">
                <button type="button" class="btn btn-apply" (click)="closeSelect.close()">
                  Apply
                </button>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="export-button mr-3">
          <button type="button" class="cursor" (click)="prayerReqDetailExport()">Export
          </button>
        </div>
        <div class="graph cursor mr-2" (click)="reset()">
          <img src="../../assets/images/panel_images/reset-1.png" class="reset-img ml-2" />
        </div>
      </ng-container>

      <div class="graph cursor ml-auto mt-1" *ngIf="permissionsArray.prayer_Requests.analyse" (click)="Analytics()">
        <img src="../../assets/images/panel_images/graph.svg" class="graph-img ml-2" />
      </div>
    </div>
  </div>
  <ng-container *ngIf="permissionsArray.prayer_Requests.view; else listTemplate" class="container-fluid pt-4 ml-1">
    <div class="d-flex p-4">
      <div class="card users-count mr-3 total-user-bg">
        <div class="card-body d-flex">
          <img src="../../assets/images/panel_images/prayer-1.svg" id="users-total" class="mr-2" />
          <div class="card-details-users">
            <h4 class="mt-3">Total Prayer Requests</h4>
            <h2 class="mt-2">
              {{ totalrecords ? (totalrecords | records) : "0" }}
            </h2>
          </div>
        </div>
      </div>
      <div class="card users-count mr-3 total-active-bg">
        <div class="card-body d-flex">
          <img src="../../assets/images/panel_images/prayer-2.svg" id="users-total" class="mr-2" />
          <div class="card-details-users">
            <h4 class="mt-3">Total Active Requests</h4>
            <h2 class="mt-2">
              {{ activeRequest ? (activeRequest | records) : "0" }}
            </h2>
          </div>
        </div>
      </div>
      <div class="card users-count mr-3 total-existing-bg">
        <div class="card-body d-flex">
          <img src="../../assets/images/panel_images/prayer-3.svg" id="users-total" class="mr-2" />
          <div class="card-details-users">
            <h4 class="mt-3">Total Completed Requests</h4>
            <h2 class="mt-2">
              {{ completedRequest ? (completedRequest | records) : "0" }}
            </h2>
          </div>
        </div>
      </div>
      <div class="card users-count mr-3 total-non-bg">
        <div class="card-body d-flex">
          <img src="../../assets/images/panel_images/prayer-4.svg" id="users-total" class="mr-2" />
          <div class="card-details-users">
            <h4 class="mt-3">Total Cancelled Requests</h4>
            <h2 class="mt-2">
              {{ cancelledRequest ? (cancelledRequest | records) : "0" }}
            </h2>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="spinner">
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  type="ball-spin-clockwise-fade"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

		</div> -->
    <app-showloader></app-showloader>

    <div class="table mt-5 mb-5">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Position Column -->
        <ng-container matColumnDef="Prayerrequestno">
          <th mat-header-cell class="th-header-table" *matHeaderCellDef>
            <h2>REQ.NO.</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="">
              <h6 class="mt-2">
                {{ element.prayerRequestId ? element.prayerRequestId : "N/A" }}
              </h6>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="user">
          <th mat-header-cell class="th-header-user" *matHeaderCellDef>
            <h2>user</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              <!-- <img [src]="element.user[0]?.profile !== '' ? element.user[0]?.profile + element.user[0]?.profile : '../../assets/images/panel_images/total-users-1.svg'"> -->
              <img *ngIf="element.user[0]?.profile != null" src="{{ element.user[0]?.profile }}"
                class="user-image-table mr-2" />

              <ngx-avatar *ngIf="element.user[0]?.profile == null" class="mr-2 name-a" initialsSize="1" size="40"
                name="{{ element?.name }}"></ngx-avatar>

              <div class="">
                <h6 class="mt-2">
                  <!-- {{ element?.name ? (element?.name | slice : 0 : 7) : "-" }} -->
                  {{element?.name}}
                </h6>
                <p>{{ element.user[0]?.membershipId }}</p>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="phoneno">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Phone no.</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h4>
              <span *ngIf="element?.phone != null && element?.phone != ''">+91-</span>{{ element?.phone ? element?.phone
              : "-" }}
            </h4>
          </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="Cause">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Cause</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h6>
              {{ element.prayer[0]?.title ? element.prayer[0]?.title : "-" }}
            </h6>
            <p>
              {{
              element.description
              ? (element.description | slice : 0 : 40)
              : "-"
              }}
            </p>
          </td>
        </ng-container>
        <!-- Weight Column -->
        <ng-container matColumnDef="language">
          <th mat-header-cell *matHeaderCellDef>
            <h2>language</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              <!-- <img *ngIf="element.language =='Telugu'" src="../../assets/images/panel_images/l-t.svg" class="user-image-table mr-2" />
					<img *ngIf="element.language =='English'" src="../../assets/images/panel_images/l-e.svg" class="user-image-table mr-2" />
					<img *ngIf="element.language =='Malyalam'" src="../../assets/images/panel_images/l-m.svg" class="user-image-table mr-2" />
					<img *ngIf="element.language =='Tamil'" src="../../assets/images/panel_images/l-ta.svg" class="user-image-table mr-2" />
					<img *ngIf="element.language =='Hindi'" src="../../assets/images/panel_images/l-h.svg" class="user-image-table mr-2" />
					<img *ngIf="element.language =='Gujrati'" src="../../assets/images/panel_images/l-g.svg" class="user-image-table mr-2" />
					<img *ngIf="element.language =='Punjabi'" src="../../assets/images/panel_images/l-p.svg" class="user-image-table mr-2" /> -->
              <h4 *ngIf="element.language == 'Telugu'" class="l-icons">అ</h4>
              <h4 *ngIf="element.language == 'Hindi'" class="l-icons">आ</h4>
              <h4 *ngIf="element.language == 'Gujarati'" class="l-icons">એ</h4>
              <h4 *ngIf="element.language == 'Tamil'" class="l-icons">ஏ</h4>
              <h4 *ngIf="element.language == 'Punjabi'" class="l-icons">ਏ</h4>
              <h4 *ngIf="element.language == 'Kannada'" class="l-icons">ಎ</h4>
              <h4 *ngIf="element.language == 'Sinhalese'" class="l-icons">ඒ</h4>
              <h4 *ngIf="element.language == 'Marathi'" class="l-icons">ए</h4>
              <h4 *ngIf="element.language == 'Bengali'" class="l-icons">ক</h4>
              <h4 *ngIf="element.language == 'Oriya'" class="l-icons">ଏ</h4>
              <h4 *ngIf="element.language == 'Urdu'" class="l-icons">اے</h4>
              <h4 *ngIf="element.language == 'Assamese'" class="l-icons">ক</h4>
              <h4 *ngIf="element.language == 'Malayalam'" class="l-icons">എ</h4>
              <h4 *ngIf="element.language == 'Bhojpuri'" class="l-icons">
                एगो
              </h4>
              <h4 *ngIf="element.language == 'Nepali'" class="l-icons">ए</h4>
              <h4 *ngIf="element.language == 'Konkani'" class="l-icons">K</h4>
              <h4 *ngIf="element.language == 'English'" class="l-icons">A</h4>

              <div class="">
                <h6 class="mt-2">
                  {{ element.language ? element.language : "-" }}
                </h6>
              </div>
            </div>
          </td>
        </ng-container>
        <!-- Symbol Column -->
        <ng-container matColumnDef="Date">
          <th class="text-left" mat-header-cell *matHeaderCellDef>
            <h2>Date & Time</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h6>
              {{ element.createdAt | date : "MMMM d, y" }} <br/>
              {{ element.createdAt | date : "shortTime" }}
            </h6>
          </td>
        </ng-container>
        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Status</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h6 *ngIf="element.status == 'completed'" class="eligable">
              {{ element.status | titlecase }}
            </h6>
            <h6 *ngIf="element.status == 'in-progress'" class="not-eligable">
              New
            </h6>
            <h6 *ngIf="element.status == 'cancelled'" class="not-member">
              {{ element.status | titlecase }}
            </h6>
          </td>
        </ng-container>
        <ng-container matColumnDef="Origin">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Origin</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              <img *ngIf="element.origin == 'General'" src="../../assets/images/panel_images/web-1.svg"
                class="user-image-table-origin mr-2" />
              <img *ngIf="element.origin == 'Website'" src="../../assets/images/panel_images/web-1.svg"
                class="user-image-table-origin mr-2" />
              <img *ngIf="
                  element.origin == 'Android' || element.origin == 'Mobile App'
                " src="../../assets/images/panel_images/moblie-1.svg" class="user-image-table-origin mr-2" />
              <img *ngIf="element.origin == 'IOS'" src="../../assets/images/panel_images/moblie-1.svg"
                class="user-image-table-origin mr-2" />
              <div class="">
                <h6 class="mt-2">{{ element.origin }}</h6>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Action</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button mat-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>
                <img src="../../assets/images/panel_images/action-dot.svg" id="users-total"
                  class="action-dots" /></mat-icon>
              <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" place></div>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button class="View" mat-menu-item value="Edit" routerLink="./viewRequest"
                [queryParams]="{ _id: element._id }">
                <img src="../../assets/images/panel_images/view.svg" class="mr-3" />
                <span>View</span>
              </button>
              <button class="View" mat-menu-item value="element._id" *ngIf="
                  element.status != 'completed' &&
                  element.status != 'cancelled' &&
                  permissionsArray.prayer_Requests.edit
                " (click)="mark(element._id)">
                <img src="../../assets/images/panel_images/mark.svg" class="mr-3" />
                <span>Mark as Close</span>
                <!-- <span>Change Customer type from New,Regular</span> -->
              </button>
              <button mat-menu-item class="Deactivate" value="Edit" *ngIf="
                  element.status != 'completed' &&
                  element.status != 'cancelled' &&
                  permissionsArray.prayer_Requests.edit
                " (click)="decline()" routerLink="./" [queryParams]="{ _id: element._id }">
                <img src="../../assets/images/panel_images/decline-1.svg" class="mr-3" />
                <span>Decline</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data available</td>
        </tr>
      </table>
      <mat-paginator (page)="pageChanged($event)" [length]="totalrecords" [pageIndex]="currentPage"
        [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator>
    </div>
  </ng-container>
</ng-container>

<ng-template #listTemplate>
  <div class="mt-5 text-center">
    <img src="../../assets//images/panel_images/notPermitted.png" width="120" alt="" />
    <p class="mt-5">You dont have permission to access this feature</p>
  </div>
</ng-template>
<ng-template #alternateTemplate>
  <div class="mt-5 text-center">
    <img src="../../assets//images/panel_images/notPermitted.png" width="120" alt="" />
    <p class="mt-5">You dont have permission to access this feature</p>
  </div>
</ng-template>

<!-- <audio class="cp-audioquote__player__src" (click)="playSong()" controls src="https://www.denisbouquet.com/pens/audioquotes/fightclub.mp3"></audio>
<audio #myAudio id="audio">
  <source src="https://www.w3schools.com/jsref/horse.ogg" type="audio/ogg">
  <source scr="https://www.w3schools.com/jsref/horse.mp3" type="audio/mpeg">
</audio> -->