<!-- <app-showloader></app-showloader> -->
<div class='spinner-loader' *ngIf="loaderSh">
    <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
<ng-container *ngIf="
    permissionsArray?.spiritual_Fitness.analyse ||
      permissionsArray?.spiritual_Fitness.edit ||
      permissionsArray?.spiritual_Fitness.view;
    else alternateTemplate
  " class="main">
    <div class="container-fluid bg-white">
        <div class="user-list-header d-flex pt-3 pb-1 ml-2">
            <div class="user-list-heading d-flex">
                <img src="../../assets/images/panel_images/sf-1.svg" class="user-list mr-2" />
                <h6 class="mt-2">Spiritual Fitness</h6>
            </div>
        </div>
    </div>
    <div class="container-fluid pt-4 ml-0">
        <div class="heading-fitness">
            <h4>Monitor the spiritual fitness of all Church members at one place</h4>
        </div>
        <ng-container *ngIf="permissionsArray?.spiritual_Fitness.view; 
          else listTemplate" class="container-fluid pt-4 ml-1">
            <div class="d-flex ">
                <!-- title="₹ {{ totalAmount }}" -->
                <!-- title="₹ {{  }}" -->
                <div class="card users-count mr-3 total-user-bg">
                    <div class="card-body d-flex">
                        <img src="../../assets/images/panel_images/total-users.svg" id="users-total" class="mr-2" />
                        <div class="card-details-users">
                            <h4 class="mt-3">Active Users</h4>
                            <h2 class="mt-2">
                                <!-- {{countResult?.irregular}} -->
                                {{ countResult?.activeCount ? countResult?.activeCount : "0" }}
                            </h2>
                        </div>
                    </div>
                </div>
                <div class="card users-count mr-3 total-non-bg">
                    <div class="card-body d-flex">
                        <img src="../../assets/images/panel_images/total-users.svg" id="users-total" class="mr-2" />
                        <div class="card-details-users">
                            <h4 class="mt-3">Irregular Users</h4>
                            <h2 class="mt-2">
                                {{ countResult?.irregular ? countResult?.irregular : "0" }}
                            </h2>
                        </div>
                    </div>
                </div>
                <div class="card users-count mr-3 total-existing-bg">
                    <div class="card-body d-flex">
                        <img src="../../assets/images/panel_images/total-users.svg" id="users-total" class="mr-2" />
                        <div class="card-details-users">
                            <h4 class="mt-3">Not Started Yet</h4>
                            <h2 class="mt-2">
                                <!-- {{ numberOfDonation ? (numberOfDonation | records) : 0 }} -->
                                {{ countResult?.notActive ? (countResult?.notActive | records) : "0" }}
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tabs-section">
                <!-- <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="medium" type="ball-clip-rotate-multiple" [fullScreen]="true" [color]="'#ffffff'" class="spinner" [show]="loadingData">
                    <p style="color: white">Loading...</p>
                  </ngx-spinner> -->
                <div class="mt-4 ml-0 mb-3 pb-2">
                    <mat-tab-group (selectedTabChange)="chartData($event)">
                        <mat-tab label="{{names.name}}" *ngFor="let names of ProfissionalList">
                            <div class="all-section pt-4 pb-5">
                                <div class="user-header-details-img-section pt-4 bg-white ml-1">
                                    <div class="d-flex mx-3">
                                        <div class="mr-auto all-section-heading">
                                            <h5>Statistics of people who are using the feature</h5>
                                        </div>
                                    </div>
                                    <div class="progress-section">
                                        <div class="progress-bar-title pt-5 mx-3">
                                            <div class="row">
                                                <div class="col-md-10">
                                                    <div class="details-prayer-fitness d-flex">
                                                        <div class="mr-auto">
                                                            <h5>{{names.count100}} Members (100%)</h5>
                                                        </div>
                                                    </div>
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar"
                                                            [style.width.%]="percent100" aria-valuenow="100"
                                                            aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>

                                                <div class="col-md-10 mt-4">
                                                    <div class="details-prayer-fitness d-flex">
                                                        <div class="mr-auto">
                                                            <h5>{{names.count65}} Members (Above 75%)</h5>
                                                        </div>
                                                    </div>
                                                    <div class="progress">
                                                        <div class="progress-bar2" role="progressbar"
                                                            [style.width.%]="percent65" aria-valuenow="65"
                                                            aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div class="col-md-10 mt-4">
                                                    <div class="details-prayer-fitness d-flex">
                                                        <div class="mr-auto">
                                                            <h5>{{names.count50a}} Members (Above 50%)</h5>
                                                        </div>
                                                    </div>
                                                    <div class="progress">
                                                        <div class="progress-bar3" role="progressbar"
                                                            [style.width.%]="percent50a" aria-valuenow="50"
                                                            aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div class="col-md-10 mt-4">
                                                    <div class="details-prayer-fitness d-flex">
                                                        <div class="mr-auto">
                                                            <h5>{{names.count50b}} Members (Below 50%)</h5>
                                                        </div>
                                                    </div>
                                                    <div class="progress">
                                                        <div class="progress-bar4" role="progressbar"
                                                            [style.width.%]='percent50b' aria-valuenow="45"
                                                            aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
            <div class="table-box">
                <div class="d-flex">
                    <h6 class="mt-2 sp">Spiritual Fitness Members</h6>
                    <div class="search-bar">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <input matInput placeholder="Search..." #input (keyup)="searchDropDown($event)"
                                [(ngModel)]="searchClear" />
                            <mat-icon matSuffix><img src="../../assets/images/panel_images/search-icon.svg"
                                    class="calendra-img" /></mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="calendra-field mr-3 ml-3">
                        <mat-form-field appearance="fill">
                            <!-- <mat-label>Toppings</mat-label> -->
                            <div class="arrow-up"></div>
                            <span matPrefix>Week Avg.&nbsp;</span>
                            <mat-icon matSuffix><img src="../../assets/images/panel_images/arrow-1.svg"
                                    class="calendra-img" /></mat-icon>
                            <mat-select [formControl]="weekAvg" multiple placeholder=""
                                (selectionChange)="weekDropDown($event)" disableOptionCentering
                                panelClass="eligablePanelClass" (keyup)="applyFilter($event)" #closeSelect
                                [(ngModel)]="weekAvgClear">
                                <mat-select-trigger>
                                    {{weekAvg.value?.[0] || ''}}
                                    <span *ngIf="(weekAvg.value?.length || 0) > 1" class="example-additional-selection">
                                        (+{{ (weekAvg.value?.length || 0) - 1 }}
                                        {{ weekAvg.value?.length === 2 ? "other" : "others" }})
                                    </span>
                                </mat-select-trigger>
                                <mat-option class="services-view" *ngFor="let Eligiblestatus of weekAvgList" [value]="Eligiblestatus.value">{{
                                    Eligiblestatus.key }}</mat-option>
                                <div class="apply-btn-option pt-4 pb-3">
                                    <button type="button" class="btn btn-apply" (click)="closeSelect.close()">
                                        Apply
                                    </button>
                                </div>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <div class="calendra-field mr-3 ml-3">
                        <mat-form-field appearance="fill">
                            <!-- <mat-label>Toppings</mat-label> -->
                            <div class="arrow-up"></div>
                            <span matPrefix>Month Avg&nbsp;</span>
                            <mat-icon matSuffix><img src="../../assets/images/panel_images/arrow-1.svg"
                                    class="calendra-img" /></mat-icon>
                            <mat-select [formControl]="monthAvg" multiple placeholder=""
                                (selectionChange)="monthDropDown($event)" disableOptionCentering
                                panelClass="eligablePanelClass" (keyup)="applyFilter($event)" #closeSelect
                                [(ngModel)]="monthAvgClear">
                                <mat-select-trigger>
                                    {{monthAvg.value?.[0] || ''}}
                                    <span *ngIf="(monthAvg.value?.length || 0) > 1"
                                        class="example-additional-selection">
                                        (+{{ (monthAvg.value?.length || 0) - 1 }}
                                        {{ monthAvg.value?.length === 2 ? "other" : "others" }})
                                    </span>
                                </mat-select-trigger>
                                <mat-option [class.eligable-option]="Eligiblestatus === 'Eligible'"
                                    [class.not-eligable-option]="Eligiblestatus === 'Not Eligible'"
                                    [class.not-member-option]="Eligiblestatus === 'Not Member'"
                                    [class.member-option]="Eligiblestatus === 'Member'"
                                    *ngFor="let Eligiblestatus of monthAvgList" [value]="Eligiblestatus.value">{{
                                    Eligiblestatus.key }}</mat-option>
                                <div class="apply-btn-option pt-4 pb-3">
                                    <button type="button" class="btn btn-apply" (click)="closeSelect.close()">
                                        Apply
                                    </button>
                                </div>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="calendra-field mr-3 ml-3">
                        <mat-form-field appearance="fill">
                            <!-- <mat-label>Toppings</mat-label> -->
                            <div class="arrow-up"></div>
                            <span matPrefix>Year Avg&nbsp;</span>
                            <mat-icon matSuffix><img src="../../assets/images/panel_images/arrow-1.svg"
                                    class="calendra-img" /></mat-icon>
                            <mat-select [formControl]="yearAvg" multiple placeholder=""
                                (selectionChange)="yearDropDown($event)" disableOptionCentering
                                panelClass="eligablePanelClass" (keyup)="applyFilter($event)" #closeSelect
                                [(ngModel)]="yearAvgClear">
                                <mat-select-trigger>
                                    {{yearAvg.value?.[0] || ''}}
                                    <span *ngIf="(weekAvg.value?.length || 0) > 1" class="example-additional-selection">
                                        (+{{ (yearAvg.value?.length || 0) - 1 }}
                                        {{ yearAvg.value?.length === 2 ? "other" : "others" }})
                                    </span>
                                </mat-select-trigger>
                                <mat-option [class.eligable-option]="Eligiblestatus === 'Eligible'"
                                    [class.not-eligable-option]="Eligiblestatus === 'Not Eligible'"
                                    [class.not-member-option]="Eligiblestatus === 'Not Member'"
                                    [class.member-option]="Eligiblestatus === 'Member'"
                                    *ngFor="let Eligiblestatus of yearAvgList" [value]="Eligiblestatus.value">{{
                                    Eligiblestatus.key }}</mat-option>
                                <div class="apply-btn-option pt-4 pb-3">
                                    <button type="button" class="btn btn-apply" (click)="closeSelect.close()">
                                        Apply
                                    </button>
                                </div>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!-- <div class="user-list-drop-down ml-2">
                        <mat-form-field>
                            <mat-label>Weekly Avg.</mat-label>
                            <mat-select>
                                <mat-option value="0-10">0-10</mat-option>
                                <mat-option value="11-20">11-20</mat-option>
                                <mat-option value="21-30">21-30</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="user-list-drop-down ml-2">
                        <mat-form-field>
                            <mat-label>Montly Avg.</mat-label>
                            <mat-select>
                                <mat-option value="0-10">0-10</mat-option>
                                <mat-option value="11-20">11-20</mat-option>
                                <mat-option value="21-30">21-30</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="user-list-drop-down ml-2">
                        <mat-form-field>
                            <mat-label>Yearly Avg.</mat-label>
                            <mat-select>
                                <mat-option value="0-10">0-10</mat-option>
                                <mat-option value="11-20">11-20</mat-option>
                                <mat-option value="21-30">21-30</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div> -->
                    <div class="graph cursor mr-2" (click)="reset()">
                        <img src="../../assets/images/panel_images/reset-1.png" class="reset-img ml-2" />
                    </div>
                </div>

                <div class="table mt-2  mb-5">
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 mr-3">
                        <!-- Position Column -->
                        <ng-container matColumnDef="Membershipid">
                            <th mat-header-cell *matHeaderCellDef>
                                <h2>MEMBERSHIP ID</h2>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="d-flex">
                                    <div class="">
                                        <h6 class="mt-2">{{ element.user.membershipId ? element.user.membershipId :
                                            "N/A" }}
                                        </h6>
                                    </div>
                                </div>
                            </td>
                        </ng-container>
                        <!-- Name Column -->
                        <ng-container matColumnDef="user">
                            <th mat-header-cell *matHeaderCellDef>
                                <h2>USER</h2>
                            </th>
                            <td mat-cell *matCellDef="let element"  class="truncate-text">
                               <h6>{{truncateText(element?.user.name ? element?.user.name : "N/A", 25)}}</h6>
                                <!-- <h6>{{ element?.user.name ? element?.user.name : "N/A" }}</h6> -->
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Phoneno">
                            <th mat-header-cell *matHeaderCellDef>
                                <h2>PHONE NO.</h2>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <h4>
                                    <span *ngIf="element?.user.phone != null && element?.user.phone != ''">+91-</span>{{
                                    element?.user.phone ? element?.user.phone
                                    : "N/A" }}
                                </h4>
                            </td>
                        </ng-container>
                        <!-- Weight Column -->
                        <!-- <ng-container matColumnDef="Attendance">
                              <th mat-header-cell *matHeaderCellDef>
                                  <h2>Attendance</h2></th>
                              <td mat-cell *matCellDef="let element">
                                  <h6>{{ element?.attendance ? element?.attendance : 'N/A'}}</h6> </td>
                          </ng-container> -->
                        <!-- Symbol Column -->
                        <ng-container matColumnDef="weekly">
                            <th mat-header-cell *matHeaderCellDef>
                                <h2>WEEKLY AVG SCORE</h2>
                            </th>
                            <td mat-cell *matCellDef="let element" class="cell-container">
                                <!-- *ngIf="element.week.scoreGet == 100" -->
                                <div class="left-content">
                                    {{element.week.scoreGet}}/{{element.week.scoreFrom}}
                                </div>
                                <span *ngIf="element.week.percent > 75" class="eligable">{{ (element.week.scoreGet /
                                    element.week.scoreFrom * 100).toFixed(1) }} %</span>
                                <span *ngIf="element.week.percent > 51 && element.week.percent < 75"
                                    class="not-eligable">{{
                                    (element.week.scoreGet / element.week.scoreFrom * 100).toFixed(1) }} %</span>
                                <span *ngIf="element.week.percent >= 0 && element.week.percent < 51"
                                    class="not-member">{{
                                    (element.week.scoreGet / element.week.scoreFrom * 100).toFixed(1) }} %</span>
                            </td>
                        </ng-container>
                        <!-- <ng-container matColumnDef="Lastvisited">
                              <th mat-header-cell *matHeaderCellDef>
                                  <h2>Last visited</h2> </th>
                              <td mat-cell *matCellDef="let element">
                                  <h6>{{ element.Lastvisited }}</h6> </td>
                          </ng-container> -->
                        <ng-container matColumnDef="monthly">
                            <th mat-header-cell *matHeaderCellDef>
                                <h2>MONTLY AVG SCORE</h2>
                            </th>
                            <td mat-cell *matCellDef="let element" class="cell-container">
                                <div class="left-content">
                                    {{element.month.scoreGet}}/{{element.month.scoreFrom}}
                                </div>
                                <span *ngIf="element.month.percent > 75" class="eligable">{{ (element.month.scoreGet /
                                    element.month.scoreFrom * 100).toFixed(1) }} %</span>
                                <span *ngIf="element.month.percent >= 51 && element.month.percent < 75"
                                    class="not-eligable">{{ (element.month.scoreGet / element.month.scoreFrom *
                                    100).toFixed(1) }} %</span>
                                <span *ngIf="element.month.percent >= 0 && element.month.percent < 51"
                                    class="not-member">{{
                                    (element.month.scoreGet / element.month.scoreFrom * 100).toFixed(1) }} %</span>
                                <!-- <button mat-icon-button mat-button [matMenuTriggerFor]="menu"
                                aria-label="Example icon-button with a menu">

                                <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" place></div>
                            </button>
                            <mat-menu #menu="matMenu" xPosition="before">

                            </mat-menu> -->
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="yearly">
                            <th mat-header-cell *matHeaderCellDef>
                                <h2>YEARLY AVG SCORE</h2>
                            </th>
                            <td mat-cell *matCellDef="let element" class="cell-container">
                                <div class="left-content">
                                    {{element.year.scoreGet}}/{{element.year.scoreFrom}}
                                </div>
                                <span *ngIf="element.year.percent > 75" class="eligable">{{ (element.year.scoreGet /
                                    element.year.scoreFrom * 100).toFixed(1) }} %</span>
                                <span *ngIf="element.year.percent >= 51 && element.year.percent < 75"
                                    class="not-eligable">{{
                                    (element.year.scoreGet / element.year.scoreFrom *
                                    100).toFixed(1) }} %</span>
                                <span *ngIf="element.year.percent >= 0 && element.year.percent < 51"
                                    class="not-member">{{
                                    (element.year.scoreGet / element.year.scoreFrom * 100).toFixed(1) }} %</span>
                                <!-- <button mat-icon-button mat-button [matMenuTriggerFor]="menu"
                                aria-label="Example icon-button with a menu">

                                <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" place></div>
                            </button>
                            <mat-menu #menu="matMenu" xPosition="before">

                            </mat-menu> -->
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Action">
                            <th mat-header-cell *matHeaderCellDef>
                                <h2>ACTION</h2>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <a value="Edit" (click)="view()" routerLink="../Userslist/viewuser"
                                    [queryParams]="{ _id: element.user._id }">
                                    View Member
                                </a>
                                <!-- <button mat-icon-button mat-button [matMenuTriggerFor]="menu"
                                aria-label="Example icon-button with a menu">
                                <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" place></div>
                            </button>
                            <mat-menu #menu="matMenu" xPosition="before">

                            </mat-menu> -->
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">
                                No data available
                            </td>
                        </tr>
                    </table>
                    <mat-paginator (page)="pageChanged($event)" [length]="totalrecords" [pageIndex]="currentPage"
                        [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                        aria-label="Select page of users"></mat-paginator>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>
<ng-template #listTemplate>
    <div class="mt-5 text-center">
        <img src="../../assets//images/panel_images/notPermitted.png" width="120" alt="" />
        <p class="mt-5">You dont have permission to access this feature</p>
    </div>
</ng-template>
<ng-template #alternateTemplate>
    <div class="mt-5 text-center">
        <img src="../../assets//images/panel_images/notPermitted.png" width="120" alt="" />
        <p class="mt-5">You dont have permission to access this feature</p>
    </div>
</ng-template>