<div class="header">
  <div class="d-flex">
    <div class="mr-auto pt-2 ml-4 mt-4">
      <img
        src="../../../assets/images/panel_images/toggle.svg"
        class="cursor"
        (click)="toggleSidenav()"
      />
    </div>
    <div class="p-2 mr-4 count-img mt-4">
      <img
        src="../../../assets/images/panel_images/notification.svg"
        class="cursor"
        (click)="notificaitonsOpen()"
      />
      <span
        *ngIf="this.notificationsCount != 0"
        class="notifcations-dot"
      ></span>
    </div>
    <div class="p-2 mr-5 mt-2">
      <div class="admin_details d-flex">
        <img *ngIf="adminImg != null && adminImg != '' " [src]="adminImg" class="admin_img" />
        <img
          *ngIf="adminImg == null || adminImg == ''"
          src="../../assets/images/panel_images/place-holder.jpg"
          class="admin_img"
        />
        <div class="details">
          <h4>{{ name ? name : "" }}</h4>
          <button
            mat-icon-button
            class="Administrator-btn"
            [class.adminValue]="firstName == 'null' || LastName == 'null'"
            mat-button
            [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu"
          >
            {{ this.role }}
            
            <div
              #menuTrigger="matMenuTrigger"
              [matMenuTriggerFor]="menu"
              place
            ></div>
          </button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button
              class="View"
              mat-menu-item
              value="Profile"
              routerLink="/Setting"
            >
              <img
                src="../../assets/images/panel_images/proflie-1.png"
                class="mr-3 logout"
              />
              <span>Profile</span>
            </button>
            <button
              class="Deactivate"
              mat-menu-item
              value="Logout"
              (click)="logout()"
            >
              <img
                src="../../assets/images/panel_images/logout.png"
                class="mr-3 logout"
              />
              <span>Logout</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="notifications" *ngIf="shownotification">
  <div class="notifcations-header">
    <div class="d-flex mx-md-3 py-md-4">
      <div class="d-flex">
        <div class="close-img">
          <img
            src="../../../assets/images/panel_images/close-n.svg"
            class="cursor mr-md-2"
            (click)="closeNotifications()"
          />
        </div>
        <div class="notifications-name mt-md-1">
          <h4>Notifications</h4>
        </div>
      </div>

      <div class="d-flex ml-auto" *ngIf="this.notificationsCount != 0">
        <div class="close-img"></div>
        <div class="mark-as mt-md-1">
          <button class="cursor" (click)="markAsRead()">
            <img
              src="../../../assets/images/panel_images/mark-as.svg"
              class="cursor mark-as-img mr-md-2"
            />
            Mark all as read
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="notifications-view" *ngIf="this.notificationsMarkAsRead != ''">
    <div class="">
      <div
        class="d-flex notifications-details-view py-md-3"
        *ngFor="let values of notificationsMarkAsRead"
      >
        <div class="">
          <div *ngIf="!values.isMarkAsRead" class="p-2 mr-4 count-img mt-1">
            <img
              src="../../../assets/images/Notifications/Unread-3.png"
              class="cursor"
              (click)="notificaitonsOpen()"
            />
            <!-- <span *ngIf="this.notificationsCount != 0 " class='notifcations-dot'></span> -->
          </div>
          <div *ngIf="values.isMarkAsRead" class="p-2 mr-4 count-img mt-1">
            <img
              src="../../../assets/images/Notifications/Read-3.png"
              class="cursor"
              (click)="notificaitonsOpen()"
            />
            <!-- <span *ngIf="this.notificationsCount != 0 " class='notifcations-dot'></span> -->
          </div>
          <!-- <img
            [src]="values.image"
            class="cursor mr-md-2 mt-md-1 ml-md-3 n-img"
          /> -->
        </div>
        <div class="notification-details mr-md-3" (click)="navigate(values)">
          <h4>{{ values.title }}</h4>
          <h1>{{ values.body }}</h1>
          <div class="row">
            <div class="col">
              <P class="mt-md-1"
                >{{ values.createdAt | date : "h:mm a" }} •
                {{ values.createdAt | datepipe }}
              </P>
            </div>
            <!-- <span *ngIf="!(values.isMarkAsRead)"  class="notifcations-dot-point">
                      </span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="no-notifications" *ngIf="this.notificationsMarkAsRead == ''">
    <div class="notifications-no">
      <img src="../../../assets/images/panel_images/not-n.svg" />
      <p class="mt-2" >No Notifications here</p>
    </div>
  </div>
</div>
