import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CmsModuleComponent } from './cms-module/cms-module.component';
import { ReviewdonationsComponent } from './cms-module/reviewdonations/reviewdonations.component';
import { ReviewpushnotificationsComponent } from './cms-module/reviewpushnotifications/reviewpushnotifications.component';
import { ReviewservicesComponent } from './cms-module/reviewservices/reviewservices.component';
import { ReviewspiritualfitnessComponent } from './cms-module/reviewspiritualfitness/reviewspiritualfitness.component';
import { ReviewtvprogramComponent } from './cms-module/reviewtvprogram/reviewtvprogram.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DonateAnalysticsComponent } from './donations/donate-analystics/donate-analystics.component';
import { DonationsComponent } from './donations/donations.component';
import { DowloadinvoiceComponent } from './donations/dowloadinvoice/dowloadinvoice.component';
import { ItsupportComponent } from './itsupport/itsupport.component';
import { ViewissueitsupportComponent } from './itsupport/viewissueitsupport/viewissueitsupport.component';
import { LegalComponent } from './legal/legal.component';
import { LoginComponent } from './Login-page/login/login.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { PrayerAnalyticsComponent } from './prayer-requests/prayer-analytics/prayer-analytics.component';
import { PrayerRequestsComponent } from './prayer-requests/prayer-requests.component';
import { ViewRequestComponent } from './prayer-requests/view-request/view-request.component';
import { ServiceAnalyticsComponent } from './service-requests/service-analytics/service-analytics.component';
import { ServiceRequestsComponent } from './service-requests/service-requests.component';
import { ViewservicerequestComponent } from './service-requests/viewservicerequest/viewservicerequest.component';
import { SettingsComponent } from './settings/settings.component';
import { SpiritualfitnessComponent } from './spiritualfitness/spiritualfitness.component';
import { SupportComponent } from './support/support.component';
import { SupportanalyticsComponent } from './support/supportanalytics/supportanalytics.component';
import { TvProgramsComponent } from './tv-programs/tv-programs.component';
import { TvdowloadinvoiceComponent } from './tv-programs/tvdowloadinvoice/tvdowloadinvoice.component';
import { TvprogramsAnalysticsComponent } from './tv-programs/tvprograms-analystics/tvprograms-analystics.component';
import { AnalyticsComponent } from './userlist/analytics/analytics.component';
import { UserlistComponent } from './userlist/userlist.component';
import { ViewuserlistComponent } from './userlist/viewuserlist/viewuserlist.component';
import { CreateuserComponent } from './usermanagment/createuser/createuser.component';
import { UsermanagmentComponent } from './usermanagment/usermanagment.component';
import { ViewuserComponent } from './usermanagment/viewuser/viewuser.component';
import { LoginGuard } from './_services/login.guard';
import { ReviewmediasComponent } from './cms-module/reviewmedias/reviewmedias.component';
import { ReviewtemplesComponent } from './cms-module/reviewtemples/reviewtemples.component';
import { ReviewprayerrequestsComponent } from './cms-module/reviewprayerrequests/reviewprayerrequests.component';
import { NewspiritualfitnessComponent } from './newspiritualfitness/newspiritualfitness.component';

const LoginPageModule = () =>
  import('../app/Login-page/login-page.module').then((x) => x.LoginPageModule);

const routes: Routes = [
  { path: 'admin', loadChildren: LoginPageModule },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'Dashboard',
    component: DashboardComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'It-Support',
    component: ItsupportComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'It-Support',
    children: [
      {
        path: 'view',
        component: ViewissueitsupportComponent,
      },
    ],
    canActivate: [LoginGuard],
  },
  {
    path: 'Userslist',
    component: UserlistComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'Userslist',
    children: [
      {
        path: 'viewuser',
        component: ViewuserlistComponent,
      },
    ],
    canActivate: [LoginGuard],
  },
  {
    path: 'Userslist',
    children: [
      {
        path: 'Analytics',
        component: AnalyticsComponent,
      },
    ],
    canActivate: [LoginGuard],
  },
  {
    path: 'Servicerequests',
    component: ServiceRequestsComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'Servicerequests',
    children: [
      {
        path: 'Analytics',
        component: ServiceAnalyticsComponent,
      },
    ],
    canActivate: [LoginGuard],
  },
  {
    path: 'Servicerequests',
    children: [
      {
        path: 'viewRequest',
        component: ViewservicerequestComponent,
      },
    ],
    canActivate: [LoginGuard],
  },
  {
    path: 'Prayer-requests',
    component: PrayerRequestsComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'Prayer-requests',
    children: [
      {
        path: 'viewRequest',
        component: ViewRequestComponent,
      },
    ],
    canActivate: [LoginGuard],
  },
  {
    path: 'Prayer-requests',
    children: [
      {
        path: 'Analytics',
        component: PrayerAnalyticsComponent,
      },
    ],
    canActivate: [LoginGuard],
  },
  {
    path: 'Tv-programs',
    component: TvProgramsComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'Tv-programs',
    children: [
      {
        path: 'Download-invoice',
        component: TvdowloadinvoiceComponent,
      },
    ],
    canActivate: [LoginGuard],
  },
  {
    path: 'Tv-programs',
    children: [
      {
        path: 'Analytics',
        component: TvprogramsAnalysticsComponent,
      },
    ],
    canActivate: [LoginGuard],
  },
  {
    path: 'Donations',
    component: DonationsComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'Donations',
    children: [
      {
        path: 'Download-invoice',
        component: DowloadinvoiceComponent,
      },
    ],
    canActivate: [LoginGuard],
  },
  {
    path: 'Donations',
    children: [
      {
        path: 'Analytics',
        component: DonateAnalysticsComponent,
      },
    ],
    canActivate: [LoginGuard],
  },
  {
    path: 'CMS-Module',
    component: CmsModuleComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'CMS-Module',
    children: [
      {
        path: 'Review/PushNotifications',
        component: ReviewpushnotificationsComponent,
      },
      {
        path: 'Review/TV-programs',
        component: ReviewtvprogramComponent,
      },
      {
        path: 'Review/Donations',
        component: ReviewdonationsComponent,
      },
      {
        path: 'Review/Services',
        component: ReviewservicesComponent,
      },
      {
        path: 'Review/Spiritualfitness',
        component: ReviewspiritualfitnessComponent,
      },
      {
        path: 'Review/Medias',
        component: ReviewmediasComponent,
      },
      {
        path: 'Review/Temples',
        component: ReviewtemplesComponent,
      },
      {
        path: 'Review/Prayerrequests',
        component: ReviewprayerrequestsComponent,
      },
    ],
    canActivate: [LoginGuard],
  },
  {
    path: 'Setting',
    component: SettingsComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'Support',
    component: SupportComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'Spiritual-Fitness',
    component: NewspiritualfitnessComponent,
    // component: SpiritualfitnessComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'Support',
    children: [
      {
        path: 'Analytics',
        component: SupportanalyticsComponent,
      },
    ],
    canActivate: [LoginGuard],
  },
  {
    path: 'Legal',
    component: LegalComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'Internal-Team',
    component: UsermanagmentComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'Internal-Team',
    children: [
      {
        path: 'view-user',
        component: ViewuserComponent,
      },
    ],
    canActivate: [LoginGuard],
  },
  {
    path: 'Internal-Team',
    children: [
      {
        path: 'create-user',
        component: CreateuserComponent,
      },
    ],
    canActivate: [LoginGuard],
  },

  //Wild Card Route for 404 request
  { path: '**', pathMatch: 'full', component: PagenotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
