<div class="container-fluid bg-white ">
	<div class="pt-3 ml-1 d-flex">
		<div class="d-flex pt-3 pb-2 mr-auto">
			<div class="back-btn mr-2 cursor" routerLink="/Prayer-requests"> <img src="../../../assets/images/panel_images/a-arrow.svg" class="back-img"> </div>
			<div class="user-header-back">
				<h5>Analytics</h5>
				<p><span>Home / Prayer Request /</span>Analytics</p>
			</div>
		</div>
		<!-- <div class="calendra-field mr-3 mt-2 ml-3">
			<mat-form-field class="example-full-width" appearance="fill">
				<input matInput (dateChange)="onChangeDate($event)" [matDatepicker]="picker" [max]="today" readonly="readonly" /> <span class="date" matPrefix>Date Range:&nbsp;</span>
				<mat-datepicker-toggle matSuffix [for]="picker">
					<mat-icon matDatepickerToggleIcon><img src="../../assets/images/panel_images/c-11.svg" class="calendra-img" /></mat-icon>
				</mat-datepicker-toggle>
				<mat-datepicker #picker></mat-datepicker>
			</mat-form-field>
		</div> -->
	</div>
</div>

    <div class="container-fluid px-0 pt-4 mb-4">
        <div class="mx-3 py-3 px-2 Analytics-one">
            <div class="d-flex">
                <div class="mt-1 mr-auto">
                    <div class="user-list-heading-prayer d-flex">
                        <img src="../../assets/images/panel_images/p-r.svg" class="user-list mr-2" />
                        <h6 class="mt-2">Filters</h6>
                    </div>
                </div>
                <div class="ml-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Select Year</mat-label>
                        <mat-select [(ngModel)]="selectedYearPrayer" (selectionChange)="selectYearPrayer($event)">
                            <mat-option *ngFor="let year of yearList" [value]="year">
                                {{ year }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="ml-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Select Month</mat-label>
                        <mat-select [(ngModel)]="selectedMonthPrayer" (selectionChange)="selectMonthPrayer($event)">
                            <mat-option *ngFor="let month of monthListPrayer" [value]="month.value">
                                {{ month.key }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="calendra-field mr-3 ml-3">
                    <mat-form-field class="example-full-width" appearance="fill"> <span matPrefix>Date:&nbsp;</span>
                        <mat-date-range-input [rangePicker]="picker2">
                            <input matStartDate class="ml-1" placeholder="Start date" readonly="readonly" (dateChange)="onChangePrayerDate($event)" [value]="startDatePrayer">
                            <input matEndDate placeholder="End date" readonly="readonly" (dateChange)="onChangePrayerToDate($event)" [value]="endDatePrayer">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker2">
                            <mat-icon matDatepickerToggleIcon><img src="../../assets/images/panel_images/c-11.svg" class="calendra-img" /></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-date-range-picker #picker2></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <div class="graph cursor mr-2" (click)="resetPrayerFilter()"> <img src="../../assets/images/panel_images/reset-1.png" class="reset-img ml-2"> </div>
            </div>
        </div>
        <div class="row mt-4 ml-1 mr-xl-1">
            <div class="col">
                <div class="charts-bg">
                    <div class="pt-3 ml-3 d-flex">
                        <div class="d-flex pt-3 pb-2 mt-1 mr-auto">
                            <div class="user-header-back">
                                <h5 class="ml-2">Prayer Requests</h5>
                                <div class="present-weeks ml-2 mt-4">
                                    <div class="d-flex">
                                        <div class="d-flex mr-3">
                                            <span class="present-dot"></span>
                                            <h5>Open</h5>
                                        </div>
                                        <div class="d-flex">
                                            <span class="romania-dot"></span>
                                            <h5>Closed</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="export-button mt-2 mr-3">
                            <button type="button" class="cursor" (click)="prayerExportBtn()">
                <img src="../../../assets/images/panel_images/export.svg" class="mr-2">Export </button>
                        </div>
                    </div>
                    <div class="chart ml-3 pt-3 pb-3 mr-3">
                        <highcharts-chart [Highcharts]="highcharts" [options]="prayerRequestchartOptions" style="width: 100%; height: 300px; display: block;"></highcharts-chart>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-6">
        <div class="charts-bg">
          <div class="pt-3 ml-3 d-flex">
            <div class="d-flex pt-3 pb-2 mt-1 mr-auto w-50">
              <div class="user-header-back">
                <h5 class="ml-2">Cause</h5>
                <div class="present-weeks ml-2 mt-4">
                  <div class="d-flex">
                    <div class="d-flex mr-3">
                      <span class="present-dot"></span>
                      <h5>Job</h5>
                    </div>
                    <div class="d-flex mr-3">
                      <span class="romania-dot"></span>
                      <h5> Family </h5>
                    </div>
                    <div class="d-flex mr-3 ">
                      <span class="romania-dot b-c-dot"></span>
                      <h5>Exam</h5>
                    </div>
                    <div class="d-flex mr-3">
                      <span class="romania-dot c-dot"></span>
                      <h5>Financial Problems</h5>
                    </div>
                    <div class="d-flex mr-3">
                      <span class="romania-dot"></span>
                      <h5> Marriage</h5>
                    </div>
                    <div class="d-flex mr-3">
                      <span class="romania-dot m-p-dot"></span>
                      <h5>Others</h5>
                    </div>
                    <div class="d-flex mr-3"><span class="romania-dot a-s-dot"></span><h5>Healing</h5></div>
                    <div class="d-flex mr-3"><span class="romania-dot p-f-dot"></span><h5>Spiritual Life</h5></div><div class="d-flex mr-3"><span class="romania-dot p-f-dot"></span><h5>Children</h5></div><div class="d-flex mr-3"><span class="romania-dot p-f-dot"></span><h5>Emergency</h5></div><div class="d-flex mr-3"><span class="romania-dot p-f-dot"></span><h5>Deliverance</h5></div><div class="d-flex mr-3"><span class="romania-dot p-f-dot"></span><h5>Other</h5></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="export-button mt-2 mr-3">
              <button type="button" class="">
                <img src="../../../assets/images/panel_images/export.svg" class="mr-2">Export </button>
            </div>
          </div>
          <div class="chart ml-3 pt-3 pb-3 mr-3">
            <highcharts-chart [Highcharts]="highcharts" [options]="prayerRequestchartOptionsAnalytics" style="width: 100%; height: 300px; display: block;"></highcharts-chart>
          </div>
        </div>
      </div> -->
        </div>
    </div>
