<div class="container-fluid bg-white ">
    <div class="pt-3 ml-1 d-flex">
       <div class="d-flex pt-3 pb-2 mr-auto">
        <div class="back-btn mr-2 cursor" routerLink="/Support">
            <img src="../../../assets/images/panel_images/a-arrow.svg" class="back-img">
        </div>
        <div class="user-header-back">
            <h5>Analytics</h5>
            <p><span>Home / Support / </span>Analytics</p>
        </div>
    </div>
    </div>
    </div>

    <div class="user-header-details-img-section mt-4 mb-4 mx-4 bg-white">
      <div class="container-fluid px-0 pt-4 mb-4">
          <div class="ml-3 Analytics-one">
              <div class="d-flex">
                  <div class="mt-1 mr-auto">
                      <div class="user-list-heading d-flex">
                          <img src="../../assets/images/panel_images/support-9.svg" class="user-list mr-2" />
                          <h6 class="mt-2">Filters</h6>
                      </div>
                  </div>
                  <div class="ml-3">
                      <mat-form-field appearance="fill">
                          <mat-label>Select Year</mat-label>
                          <mat-select [(ngModel)]="selectedYearSupport" (selectionChange)="selectYearSupport($event)">
                              <mat-option *ngFor="let year of yearList" [value]="year">
                                  {{ year }}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>
                  <div class="ml-3">
                      <mat-form-field appearance="fill">
                          <mat-label>Select Month</mat-label>
                          <mat-select [(ngModel)]="selectedMonthSupport" (selectionChange)="selectMonthSupport($event)">
                              <mat-option *ngFor="let month of monthListTvSupport" [value]="month.value">
                                  {{ month.key }}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>
                  <div class="calendra-field mr-3 ml-3">
                      <mat-form-field class="example-full-width" appearance="fill"> <span matPrefix>Date:&nbsp;</span>
                          <mat-date-range-input [rangePicker]="picker5">
                              <input matStartDate class="ml-1" placeholder="Start date" readonly="readonly" (dateChange)="onChangeSupportDate($event)" [value]="startDateTvSupport">
                              <input matEndDate placeholder="End date" readonly="readonly" (dateChange)="onChangeSuppportToDate($event)" [value]="endDateTvSupport">
                          </mat-date-range-input>
                          <mat-datepicker-toggle matSuffix [for]="picker5">
                              <mat-icon matDatepickerToggleIcon><img src="../../assets/images/panel_images/c-11.svg" class="calendra-img" /></mat-icon>
                          </mat-datepicker-toggle>
                          <mat-date-range-picker #picker5></mat-date-range-picker>
                      </mat-form-field>
                  </div>
                  <div class="graph cursor mr-2" (click)="resetSupportFilter()"> <img src="../../assets/images/panel_images/reset-1.png" class="reset-img ml-2"> </div>
              </div>
          </div>
          <div class="charts-bg mt-4 mx-3 ">
              <div class="pt-3 ml-3 d-flex">
                  <div class="d-flex pt-3 pb-2 mt-1 mr-auto">
                      <div class="user-header-back">
                          <h5 class="ml-2">Support Tickets</h5>
                          <div class="present-weeks ml-2 mt-4">
                              <div class="d-flex">
                                  <div class="d-flex mr-3">
                                      <span class="support-dot romania-dot"></span>
                                      <h5>Open</h5>
                                  </div>
                                  <div class="d-flex">
                                      <span class="romania-dot support-closed-dot"></span>
                                      <h5>Closed</h5>
                                  </div>
  
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="export-button mt-2 mr-3">
                      <button type="button" class="cursor" (click)="supportDataExportBtn()">
              <img src="../../../assets/images/panel_images/export.svg" class="mr-2">Export </button>
                  </div>
              </div>
              <div class="chart ml-3 pt-3 pb-3 mr-3">
                  <highcharts-chart [Highcharts]="highcharts" [options]="supportchartOptions" style="width: 100%; height: 300px; display: block;"></highcharts-chart>
              </div>
          </div>
      </div>
  </div>

    <!-- <div class="charts-bg mt-4 mx-4">
      <div class="pt-3 ml-3 d-flex">
        <div class="d-flex pt-3 pb-2 mt-1 mr-auto">
          <div class="user-header-back">
            <h5 class="ml-2">Support Tickets</h5>
            <div class="present-weeks ml-2 mt-4">
              <div class="d-flex">
                <div class="d-flex mr-3">
                  <span class="support-dot romania-dot"></span>
                  <h5>Open</h5>
                </div>
                <div class="d-flex">
                  <span class="romania-dot support-closed-dot"></span>
                  <h5>Closed</h5>
                </div>
                  
              </div>
            </div>
          </div>
        </div>
        <div class="calendra-field mr-3 mt-2 ml-3">
   	<mat-form-field class="example-full-width" appearance="fill"> <span matPrefix>Date:&nbsp;</span>
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate matInput class="ml-1" placeholder="Start date" readonly="readonly" (dateChange)="onChangeDate($event)"  >
						<input matEndDate matInput placeholder="End date" readonly="readonly" (dateChange)="onChangeToDate($event)" >
					 </mat-date-range-input>
					<mat-datepicker-toggle matSuffix [for]="picker">
						<mat-icon matDatepickerToggleIcon><img src="../../assets/images/panel_images/c-11.svg" class="calendra-img" /></mat-icon>
					</mat-datepicker-toggle>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
    </div>
        <div class="export-button mt-2 mr-3">
          <button type="button" class="cursor" (click)="supportDataExportBtn()">
            <img src="../../../assets/images/panel_images/export.svg" class="mr-2">Export </button>
        </div>
      </div>
      <div class="chart ml-3 pt-3 pb-3 mr-3">
        <highcharts-chart [Highcharts]="highcharts" [options]="supportchartOptions" style="width: 100%; height: 300px; display: block;"></highcharts-chart>
      </div>
    </div> -->