<ng-container
  *ngIf="
    permissionsArray?.spiritual_Fitness.analyse ||
      permissionsArray?.spiritual_Fitness.edit ||
      permissionsArray?.spiritual_Fitness.view;
    else alternateTemplate
  "
  class="main"
>
  <div class="container-fluid bg-white">
    <div class="user-list-header d-flex pt-3 pb-1 ml-2">
      <div class="user-list-heading d-flex">
        <img
          src="../../assets/images/panel_images/sf-1.svg"
          class="user-list mr-2"
        />
        <h6 class="mt-2">Spiritual Fitness</h6>
      </div>
    </div>
  </div>
  <div class="container-fluid pt-4 ml-0">
    <div class="heading-fitness">
      <h4>Monitor the spiritual fitness of all Church members at one place</h4>
    </div>
    <!-- <div class="d-flex pt-2">
            <div class="card users-count mr-3 total-user-bg">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/total-users.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">Atleat one committment</h4>
                        <h2 class="mt-2">{{atLeastRecord ? (atLeastRecord | records | number:'1.0-0') : 0}}</h2>
                    </div>
                </div>
            </div>
            <div class="card users-count total-non-bg mr-3">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/total-users-3.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">Total Committments</h4>
                        <h2 class="mt-2">{{totalCommitment ? (totalCommitment | records | number:'1.0-0' ): 0}}</h2>
                    </div>
                </div>
            </div>
            <div class="card users-count mr-3 total-active-bg">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/sr-2.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">Atleast 70% Disobedience</h4>
                        <h2 class="mt-2">{{seventyPercentObeying ? (seventyPercentObeying | records | number:'1.0-0') : 0}}</h2>
                    </div>
                </div>
            </div>
            <div class="card users-count   total-existing-bg">
                <div class="card-body d-flex">
                    <img src="../../assets/images/panel_images/total-users-1.svg" id="users-total" class="mr-2" />
                    <div class="card-details-users">
                        <h4 class="mt-3">100% Obedience</h4>
                        <h2 class="mt-2">{{hundredPercentObeying ? (hundredPercentObeying | records | number:'1.0-0' ) : 0}}</h2>
                    </div>
                </div>
            </div>
        </div> -->
    <div class="tabs-section">
      <app-showloader></app-showloader>
      <div class="mt-4 ml-0 mb-3 pb-2">
        <mat-tab-group (selectedTabChange)="chartData($event)">
          <mat-tab
            label="{{ names.title }}"
            *ngFor="let names of ProfissionalList"
          >
            <div class="all-section pt-4 pb-5">
              <div class="d-flex">
                <div class="mr-auto all-section-heading">
                  <h5>Committment wise performance</h5>
                </div>
                <div class="all-section-members">
                  <h3>{{ totalObeying }} Members</h3>
                </div>
              </div>
              <div
                class="progreess-bar-section d-flex pt-2 justify-content-around"
              >
                <div
                  class="progress-bar-success green"
                  [ngClass]="{ good: !chartArrayExcellent }"
                >
                  <apx-chart
                    [series]="chartOptionsExcellent.series"
                    [chart]="chartOptionsExcellent.chart"
                    [plotOptions]="chartOptionsExcellent.plotOptions"
                    [labels]="chartOptionsExcellent.labels"
                    [colors]="['#0dbc67', '#0dbc67', '#0dbc67']"
                  ></apx-chart>
                  <h6 class="mt-1">Excellent</h6>
                  <!-- <h6 class="mt-6" *ngIf="!chartArrayExcellent">Excellent</h6> -->
                </div>
                <!-- <div class="progress-bar-success green" [ngClass]="{'good' : !chartArrayExcellent }">
                                    <mat-progress-spinner class="example-margin" [color]="'green'" [mode]="'determinate'" [value]="chartArrayExcellent ? chartArrayExcellent : 100">
                                    </mat-progress-spinner>
                                    <label style="position:relative; top: -60px; left: 30px;" *ngIf="chartArrayExcellent">{{chartArrayExcellent}}%</label>
                                    <h6 class="mt-2" *ngIf="chartArrayExcellent">Excellent</h6>
                                    <h6 class="mt-6" *ngIf="!chartArrayExcellent">Excellent</h6>
                                </div> -->
                <div
                  class="progress-bar-success"
                  [ngClass]="{ good: !chartArrayveryGood }"
                >
                  <!-- <mat-progress-spinner class="example-margin" [color]="'accent'" [mode]="'determinate'" [value]="chartArrayveryGood ? chartArrayveryGood : 100">
                                    </mat-progress-spinner>
                                    <label style="position:relative; top: -60px; left: 30px;" *ngIf="chartArrayveryGood">{{chartArrayveryGood}}%</label> -->
                  <apx-chart
                    [series]="chartOptionsGood.series"
                    [chart]="chartOptionsGood.chart"
                    [plotOptions]="chartOptionsGood.plotOptions"
                    [labels]="chartOptionsGood.labels"
                    [colors]="['#ffd740', '#ffd740', '#ffd740']"
                  ></apx-chart>
                  <h6 class="mt-1">Good</h6>
                  <!-- <h6 class="mt-6" *ngIf="!chartArrayveryGood">Good</h6> -->
                </div>
                <div
                  class="progress-bar-success"
                  [ngClass]="{ good: !chartArrayAverage }"
                >
                  <!-- <mat-progress-spinner class="example-margin" [color]="'primary'" [mode]="'determinate'" [value]="chartArrayAverage ? chartArrayAverage : 100">
                                    </mat-progress-spinner>
                                    <label style="position:relative; top: -60px; left: 30px;" *ngIf="chartArrayAverage">{{chartArrayAverage}}%</label> -->
                  <apx-chart
                    [series]="chartOptionsAvg.series"
                    [chart]="chartOptionsAvg.chart"
                    [plotOptions]="chartOptionsAvg.plotOptions"
                    [labels]="chartOptionsAvg.labels"
                    [colors]="['#673ab7', '#673ab7', '#673ab7']"
                  ></apx-chart>
                  <h6 class="mt-1">Average</h6>
                  <!-- <h6 class="mt-6" *ngIf="!chartArrayAverage">Average</h6> -->
                </div>
                <div
                  class="progress-bar-success"
                  [ngClass]="{ good: !chartArrayBelowAverage }"
                >
                  <!-- <mat-progress-spinner class="example-margin" [color]="'primary'" [mode]="'determinate'" [value]="chartArrayBelowAverage ? chartArrayBelowAverage : 100">
                                    </mat-progress-spinner>
                                    <label style="position:relative; top: -60px; left: 30px;" *ngIf="chartArrayBelowAverage">{{chartArrayBelowAverage}}%</label> -->
                  <apx-chart
                    [series]="chartOptionsBelowAvg.series"
                    [chart]="chartOptionsBelowAvg.chart"
                    [plotOptions]="chartOptionsBelowAvg.plotOptions"
                    [labels]="chartOptionsBelowAvg.labels"
                    [colors]="['#e0831b', '#e0831b', '#e0831b']"
                  ></apx-chart>
                  <h6 class="mt-1">Below Average</h6>
                  <!-- <h6 class="mt-6" *ngIf="!chartArrayBelowAverage">Below Average</h6> -->
                </div>
                <div
                  class="progress-bar-success"
                  [ngClass]="{ good: !chartArrayveryBad }"
                >
                  <!-- <mat-progress-spinner class="example-margin" [color]="'warn'" [mode]="'determinate'" [value]="chartArrayveryBad ? chartArrayveryBad : 100">
                                    </mat-progress-spinner>
                                    <label style="position:relative; top: -60px; left: 30px;" *ngIf="chartArrayveryBad">{{chartArrayveryBad}}%</label> -->
                  <apx-chart
                    [series]="chartOptionsPoor.series"
                    [chart]="chartOptionsPoor.chart"
                    [plotOptions]="chartOptionsPoor.plotOptions"
                    [labels]="chartOptionsPoor.labels"
                    [colors]="['#f44336', '#f44336', '#f44336']"
                  ></apx-chart>
                  <h6 class="mt-1">Poor</h6>
                  <!-- <h6 class="mt-6" *ngIf="!chartArrayveryBad">Poor</h6> -->
                </div>
              </div>
              <div
                class="user-header-details-img-section pt-4 mt-4 bg-white ml-1"
              >
                <div class="d-flex mx-3">
                  <div class="mr-auto all-section-heading">
                    <h5>Committment wise performance</h5>
                  </div>
                  <div class="all-section-members">
                    <h3>{{ spiritulafitnessAll?.length }} Committments</h3>
                  </div>
                </div>
                <div class="progress-section">
                  <div
                    class="progress-bar-title pt-5 mx-3"
                    *ngFor="let fitness of spiritulafitnessAll"
                  >
                    <div class="row">
                      <div class="col-md-10">
                        <div class="details-prayer-fitness d-flex">
                          <div class="mr-auto">
                            <h5>{{ fitness.title }}</h5>
                          </div>
                          <div class="percentage-view">
                            <h5>{{ fitness.obeyingPercentage }}%</h5>
                          </div>
                        </div>
                        <!-- <mat-progress-bar mode="determinate" [value]="percentagevalue">50 seconds</mat-progress-bar> -->
                        <div class="progress">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            [style.width.%]="fitness.obeyingPercentage"
                            aria-valuenow="{{ fitness.obeyingPercentage }}"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <p class="mt-6">
                          {{ fitness.userCount }} / {{ totalObeying }} Taken
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div></ng-container
>
<ng-template #alternateTemplate>
    <div class="mt-5 text-center">
      <img
        src="../../assets//images/panel_images/notPermitted.png"
        width="120"
        alt=""
      />
      <p class="mt-5">You dont have permission to access this feature</p>
    </div>
  </ng-template>
