import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { PageEvent } from '@angular/material/paginator';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { DashboardServicesService } from 'src/app/_services/dashboard-services.service';
import { environment } from 'src/environments/environment';
declare const progressbar: any;
@Component({
  selector: 'app-viewuserlist',
  templateUrl: './viewuserlist.component.html',
  styleUrls: ['./viewuserlist.component.css'],
})
export class ViewuserlistComponent implements OnInit {
  dropDownSelection: any;
  url = `${environment.imageURl}`;
  Date: any;
  calendraDate: any;
  donationArray: any;
  today = new Date();
  chartOptionsExcellent: any;
  attendenceData: any;
  allAttendenceData: any;
  yearList: any;
  // e: any = { previousPageIndex: 0, pageIndex: 1, pageSize: 10};
  // userDetails = [
  //   { key: 'Gender', Value: 'Female' },
  //   { key: 'Email', Value: 'kristinwatson23@gmail.com' },
  //   { key: 'Mobile No', Value: '9876543210' },
  //   { key: 'Attendance', Value: '68%' },
  //   { key: 'Eligibility', Value: 'Yes' },
  // ];

  Donations = [
    {
      image: '../../../assets/images/panel_images/bank-1.svg',
      name: 'Calvary Temple Foundation',
      date: '12-04-2022 at 8:05 am',
      amount: '₹20,000',
    },
    {
      image: '../../../assets/images/panel_images/bank-2.svg',
      name: 'Calvary Temple Foundation',
      date: '12-04-2022 at 8:05 am',
      amount: '₹20,000',
    },
    {
      image: '../../../assets/images/panel_images/bank-3.svg',
      name: 'Calvary Temple Foundation',
      date: '12-04-2022 at 8:05 am',
      amount: '₹20,000',
    },
    {
      image: '../../../assets/images/panel_images/bank-4.svg',
      name: 'Calvary Temple Foundation',
      date: '12-04-2022 at 8:05 am',
      amount: '₹20,000',
    },
    {
      image: '../../../assets/images/panel_images/bank-5.svg',
      name: 'Calvary Temple Foundation',
      date: '12-04-2022 at 8:05 am',
      amount: '₹20,000',
    },
  ];
  userDetailsIdParams: any;
  queryParamsDetails: any;
  userDetails: any;
  userPreviousPrayerRequest: any;
  userPreviousServiceRequest: any;
  userCommitments: any;
  userprofileDetails: any = [];
  userDonations: any;
  attendanceparams: {};
  attendanceparams2: {};
  yearParam: {};
  attedanceMemberShipId: any;
  pageIndex: any;
  pageSize: any = 10;
  queryParams: any;
  totalRecords: any;
  totalServiceRecords: any;
  totalDonationRecords: any;
  totalCommitmentRecords: any;
  servicePageIndex: number;
  servicePageSize: number = 10;
  donationPageIndex: number;
  donationPageSize: number = 10;
  commitmentPageIndex: number;
  commitmentPageSize: number=10;
  // attendenceData:any
  constructor(
    private router: Router,
    public service: DashboardServicesService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.queryParamsDetails = { ...params.keys, ...params };
      this.userDetailsIdParams = params.get('_id');
      console.log(this.userDetailsIdParams);
    });
    this.chartOptionsExcellent = {
      series: [0],
      chart: {
        height: 150,
        width: 150,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '50%',
          },
          dataLabels: {
            value: {
              fontWeight: 'bold',
              offsetY: -11,
            },
          },
        },
      },
      labels: [''],
    };
  }

  progressPercentage = 0;
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'determinate';
  // Function to update the progress percentage
  updateProgress() {
    this.progressPercentage = 30;
  }
  ngOnInit(): void {
    // progressbar();
    // this.onPageChanged(this.e);

    this.service
      .membersDetailsRequest(this.userDetailsIdParams)
      .subscribe((res) => {
        console.log(res);
        this.userDetails = res.data;
        console.log('userdata', this.userDetails);
        this.attedanceMemberShipId = res.data.membershipId;
        console.log('memberShipId', this.attedanceMemberShipId);
        this.userprofileDetails.push(this.userDetails);
        this.attendanceparams = {
          // ctm: this.attedanceMemberShipId ? this.attedanceMemberShipId : '',
          ctm: 'CTM68455-1',
          year: 'ALL',
        };
        this.attendanceparams2 = {
          // ctm: this.attedanceMemberShipId ? this.attedanceMemberShipId : '',
          ctm: 'CTM68455-1',
        };
        this.service
          .AttendanceMembers(this.attendanceparams2)
          .subscribe((result) => {
            this.attendenceData = result.data;
          });
        this.service
          .AllAttendanceMembers(this.attendanceparams)
          .subscribe((result) => {
            this.allAttendenceData = result.data;
          });
      });
    this.yearParam = {
      ctm: 'CTM68455-1',
      year: 'ALL',
    };
    this.service.getYear(this.yearParam).subscribe((result) => {
      this.yearList = result.year;
    });

    this.queryParams = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.pageIndex ? this.pageIndex : '',
    };
    this.service
      .membersDetailsPrayerRequestP(this.userDetailsIdParams, this.queryParams)
      .subscribe((res) => {
        console.log(res);
        this.userPreviousPrayerRequest = res.data;
        this.totalRecords = res.totalRecords;
      });

    this.service
      .membersDetailsServiceRequest(this.userDetailsIdParams, this.queryParams)
      .subscribe((res) => {
        console.log(res);
        this.userPreviousServiceRequest = res.data;
        this.totalServiceRecords = res.totalRecords
      });
    this.service
      .membersDetailsCommitment(this.userDetailsIdParams, this.queryParams)
      .subscribe((res) => {
        // console.log(res);
        this.userCommitments = res.data;
        this.totalCommitmentRecords = res.totalRecords
      });
    this.service
      .membersDetailsDonations(this.userDetailsIdParams, this.queryParams)
      .subscribe((res) => {
        // console.log(res);
        this.userDonations = res.data;
        this.totalDonationRecords = res.totalRecords
      });

    this.service
      .donationView(this.userDetailsIdParams)
      .subscribe((res: any) => {
        console.log('main response', res);
        this.donationArray = res.data;
      });
  }
  reset() { }
  selectYear(e: any) {
  }
  onChangeDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
  }

  getCommitmentPagedData() {
    const startIndex = this.pageIndex * this.pageSize;
    return this.userCommitments.slice(
      startIndex,
      startIndex + this.pageSize
    );
  }
  onPageChanged(event: PageEvent, eType: 'prayerReq' | 'serviceReq' | 'donationReq' | 'commitmentReq') {

    switch (eType) {
      case 'prayerReq':
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.queryParams = {
          pageLimit: this.pageSize ? this.pageSize : '',
          pageNumber: this.pageIndex ? this.pageIndex + 1 : 1,
        };
        this.service
          .membersDetailsPrayerRequestP(this.userDetailsIdParams, this.queryParams)
          .subscribe((res) => {
            console.log(res);
            this.userPreviousPrayerRequest = res.data;
            this.totalRecords = res.totalRecords;
            // this.getPagedData()
          });
        break;
      case 'serviceReq':
        this.servicePageIndex = event.pageIndex;
        this.servicePageSize = event.pageSize;
        this.queryParams = {
          pageLimit: this.servicePageSize ? this.servicePageSize : '',
          pageNumber: this.servicePageIndex ? this.servicePageIndex + 1 : 1,
        };
        this.service
          .membersDetailsServiceRequest(this.userDetailsIdParams, this.queryParams)
          .subscribe((res) => {
            console.log(res);
            this.userPreviousServiceRequest = res.data;
            this.totalServiceRecords = res.totalRecords
          });
        break;
      case 'donationReq':
        this.donationPageIndex = event.pageIndex;
        this.donationPageSize = event.pageSize;
        this.queryParams = {
          pageLimit: this.donationPageSize ? this.donationPageSize : '',
          pageNumber: this.donationPageIndex ? this.donationPageIndex + 1 : 1,
        };
        this.service
          .membersDetailsDonations(this.userDetailsIdParams, this.queryParams)
          .subscribe((res) => {
            console.log(res);
            this.userDonations = res.data;
            this.totalDonationRecords = res.totalRecords
          });
        break;
      case 'commitmentReq':
        this.commitmentPageIndex = event.pageIndex;
        this.commitmentPageSize = event.pageSize;
        this.queryParams = {
          pageLimit: this.commitmentPageSize ? this.commitmentPageSize : '',
          pageNumber: this.commitmentPageIndex ? this.commitmentPageIndex + 1 : 1,
        };
        this.service
          .membersDetailsCommitment(this.userDetailsIdParams, this.queryParams)
          .subscribe((res) => {
            console.log(res);
            this.userCommitments = res.data;
            this.totalCommitmentRecords = res.totalRecords
          });
        break;

      default:
        break;
    }
  }
}
