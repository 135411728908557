import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { MarkAsClosedcommonDialogComponent } from 'src/app/mark-as-closedcommon-dialog/mark-as-closedcommon-dialog.component';
import { DashboardServicesService } from 'src/app/_services/dashboard-services.service';
import { MarkAsInvalidcommonDialogComponent } from 'src/app/mark-as-invalidcommon-dialog/mark-as-invalidcommon-dialog.component';

@Component({
  selector: 'app-invaliddialog',
  templateUrl: './invaliddialog.component.html',
  styleUrls: ['./invaliddialog.component.css'],
})
export class InvaliddialogComponent implements OnInit {
  onLoadPage = new EventEmitter();
  queryParamsDetails: any;
  userDetailsParams: string;
  queryParamsStatus: any;
  statusCompleted: any = 'completed';
  statusDeclined: any = 'cancelled';
  ELEMENT_DATA: Element[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  queryParams: {};
  pageSize: any;
  currentPage: any;
  totalrecords: any;
  activeRequest: any;
  cancelledRequest: any;
  completedRequest: any;
  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private services: DashboardServicesService,
    private dialogRef: MatDialogRef<InvaliddialogComponent>
  ) {}

  ngOnInit(): void {}
  close() {
    this.dialog.closeAll();
  }
  decline() {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.queryParamsDetails = { ...params.keys, ...params };
      if (params.get('_id') != null) {
        this.userDetailsParams = params.get('_id');
      }
    });

    this.queryParamsStatus = {
      status: this.statusDeclined ? this.statusDeclined : '',
    };
    this.services
      .prayerRequestStatus(this.userDetailsParams, this.queryParamsStatus)
      .subscribe((response) => {
        this.dialogRef.close();
        this.dialog.open(MarkAsInvalidcommonDialogComponent, {
          data: {
            disableClose: true,
          },
        });

        this.onLoadPage.emit();
      });
    //  setTimeout(() => {
    //    this.markedClose = false;
    //  }, 3000);
  }
  getAllPrayerRequest() {
    this.queryParams = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.services.prayerRequest(this.queryParams).subscribe((response) => {});
  }
}
