import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-suspenddialog',
  templateUrl: './suspenddialog.component.html',
  styleUrls: ['./suspenddialog.component.css'],
})
export class SuspenddialogComponent implements OnInit {
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}
  close() {
    this.dialog.closeAll();
  }
}
