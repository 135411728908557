import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DashboardServicesService } from 'src/app/_services/dashboard-services.service';

@Component({
  selector: 'app-dowloadinvoice',
  templateUrl: './dowloadinvoice.component.html',
  styleUrls: ['./dowloadinvoice.component.css'],
})
export class DowloadinvoiceComponent implements OnInit {
  userDetails = [
    { key: 'Name:', Value: 'Kristin Watson' },
    { key: 'Email:', Value: 'kristinwatson23@gmail.com' },
    { key: 'Mobile:', Value: '9876543210' },
    { key: 'Paid To:', Value: 'Calvary Temple Foundation' },
    { key: 'Date:', Value: '23-10-2022' },
    { key: 'Payment Mode:', Value: 'Netbanking' },
    { key: 'Status:', Value: 'Success' },
  ];
  queryParamsDetails: any;
  userDetailsParams: string;
  issueDialog: any;
  message: any;
  
  constructor(
    private services: DashboardServicesService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.queryParamsDetails = { ...params.keys, ...params };
      this.userDetailsParams = params.get('_id');
    });
    this.services
      .donateRequestPayment(this.userDetailsParams)
      .subscribe((response) => {
        this.message = response.message;
      });
  }
}
