


<div class="container">
    <div class="close-btn-section mb-2 cursor" (click)="close()">
    <img src="../../../assets/images/panel_images/close-1.svg" class="float-right">

    </div>
    <div class="close-img-section text-center pt-5 mb-3">
    <img src="../../../assets/images/panel_images/close.svg" class="mb-3">
    <div class="Deactive-user mb-4">
        <h5 class="mx-2">Decline Service Request</h5>
        <p>Are you sure you want to decline this service request? Once done, you can’t undo it.</p>
    </div>
    <div class="">
        <div class="Cancel-btn">
            <button class="btn btn-cancel" (click)="close()">Cancel</button>
            <button class="btn btn-Deactivate" (click)="decline()">Decline</button>
        </div>
    </div>
    </div>

</div>

