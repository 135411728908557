<div class="container-fluid bg-white ">
    <div class="pt-3 ml-1">
        <div class="d-flex pt-3 pb-2">
            <div class="back-btn mr-2 cursor" routerLink="/CMS-Module" [queryParams]="{tab:2}">
                <img src="../../../assets/images/panel_images/back.svg" class="back-img">
            </div>
            <div class="user-header-back">
                <h5>Push Notifications Request</h5>
                <p><span>CMS Module /  Review / </span>Push Notification Request</p>
            </div>
        </div>
    </div>

</div>

<div class="nofound" *ngIf="review == '' || review ==  null">
    <h5>
        No request found
    </h5>
</div>
<div class="user-header-details-img-section review-push bg-white mx-md-4 mt-md-5" *ngIf="review != '' && review !=  null">
    <div class="push-nofications-section pt-2 tabs-section">
        <div class="d-flex push-notofications-col ml-md-3 pl-md-5 pr-md-4 pb-md-3">
            <app-showloader *ngIf="Loader"></app-showloader>

            <div class="mr-auto push pl-md-3 ">
                <h4>Push Notifications Request</h4>
            </div>

        </div>
        <form [formGroup]="reviewPush">
            <div class="row event-row" *ngFor="let card of review;let i = index">
                <div class="col-md-8 push-notofications-col">
                    <div class="d-flex ml-4">
                        <div class="push-heading created-details-section">
                            <p>Created by:</p>
                        </div>
                        <div class="details-push-nofications">
                            <p>{{card?.createdBy?.firstName}} {{card?.createdBy?.lastName}}</p>
                        </div>
                    </div>
                    <div class="d-flex ml-4 my-3">
                        <div class="push-heading created-details-section ">
                            <p>Created Date:</p>
                        </div>
                        <div class="details-push-nofications">
                            <p>{{card.createdAt | date: 'MMMM d, y, h:mm a' }}</p>
                        </div>
                    </div>
                    <div class="d-flex ml-4 mt-2">
                        <div class="push-heading created-details-section ">
                            <p>Device:</p>
                        </div>
                        <div class="checkbox-section">
                            <section class="example-section">
                                <mat-checkbox [disableRipple]="true" [checked]="card.deviceType == 'both'" class="example-margin mr-3" disabled>Both</mat-checkbox>
                                <mat-checkbox [disableRipple]="true" [checked]="card.deviceType == 'android'" class="example-margin mr-3" disabled>Android</mat-checkbox>
                                <mat-checkbox [disableRipple]="true" [checked]="card.deviceType == 'ios'" class="example-margin" disabled>iOS</mat-checkbox>
                            </section>
                        </div>
                    </div>
                    <div class="d-flex ml-4 mt-3">
                        <div class="push-heading">
                            <p>Title:</p>
                        </div>
                        <div class="media-email-field mt-0 ml-4 pl-3" style='width:100%;'>
                            <mat-form-field>
                                <input matInput [value]="card.title" readonly/>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="d-flex ml-4 mt-3">
                        <div class="push-heading">
                            <p>Description:</p>
                        </div>
                        <div class="media-email-field mt-0 ml-4 pl-3" style='width:100%;'>
                            <mat-form-field>
                                <input matInput [value]="card.description" readonly/>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="d-flex ml-4 mt-4">
                        <div class="source-heading" style="width: 280px;">
                            <p>Image:</p>
                        </div>
                        <div class="upload-img" *ngIf="card?.image">
                            <img [src]="card?.image" class="i-img">
                        </div>
                        <div class="upload-img-na" *ngIf="!card?.image">
                            N/A
                        </div>
                    </div>
                    <div class="d-flex ml-4 mt-3">
                        <div class="push-heading">
                            <p>When to trigger:</p>
                        </div>
                        <div class="media-email-field mt-0 ml-4 pl-3" style='width:100%;'>
                            <mat-form-field>
                                <input matInput [value]="card.triggerTime | date: 'MMMM d, y, h:mm a' " readonly/>
                            </mat-form-field>
                            <!-- <mat-form-field class="example-full-width">
                            <input matInput [matDatepicker]="picker9" [value]="card.triggerTime" readonly="readonly" placeholder="Select Date" />
                            <mat-datepicker-toggle matSuffix [for]="picker9">
                              <mat-icon matDatepickerToggleIcon>
                                <img src="../../../assets/images/panel_images/cal-0.svg" class="calendra-img" />
                              </mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker9></mat-datepicker>
                          </mat-form-field> -->
                        </div>
                    </div>


                    <div class="button-section-tv-programs ml-4 pl-2 mt-5 pb-4" *ngIf="(role == 'superAdmin' || role == 'admin') && today <= getTime(card.triggerTime)">
                        <button type="button" class="btn draft-btn mr-4" (click)="decline(card._id,i)">Reject</button>
                        <button type="button" class="btn create-btn" (click)="approve(card._id,i)">Approve</button>
                    </div>
                </div>
                <div class="preview-btn button-section-tv-programs" *ngIf="today > getTime(card.triggerTime)">
                    <button type="button" class="btn">Request Expired</button>
                </div>
                <hr>
            </div>
        </form>
    </div>
</div>