import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { MarkAsClosedcommonDialogComponent } from '../mark-as-closedcommon-dialog/mark-as-closedcommon-dialog.component';
import { MarkAsInvalidcommonDialogComponent } from '../mark-as-invalidcommon-dialog/mark-as-invalidcommon-dialog.component';
import { DashboardServicesService } from '../_services/dashboard-services.service';
import { ViewissuedialogComponent } from './viewissuedialog/viewissuedialog.component';
export interface PeriodicElement {
  Requestno: string;
  user: string;
  Service: any;
  RECEIVERSNAME: any;
  phoneno: any;
  FAMILY: any;
  date: any;
  Status: string;
  Action: any;
  image: any;
  OriginImg: any;
  origin: any;
}
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css'],
})
export class SupportComponent implements OnInit, AfterViewInit {
  statusOption = new FormControl('');
  serviceControl = new FormControl('');

  status: string[] = ['Open', 'Closed', 'Invalid'];
  // language: any = [
  //   'English',
  //   'Hindi',
  //   'Telugu',
  //   'Tamil',
  //   'Malyalam',
  //   'Telugu',
  //   'Gujarati',
  //   'Punjabi',
  // ];
  Origin: any = [
    { key: 'general', value: 'General' },
    { key: 'website', value: 'Website' },
    { key: 'iOS', value: 'iOS' },
    { key: 'android', value: 'Android' },
  ];
  total: any;
  open: any;
  invalid: any;
  closed: any;
  statusMessage: any;
  queryParamsDetails: any;
  statusCompleted: any = 'closed';
  userDetailsParams: string;
  queryParamsStatus: any;
  onLoadPage = new EventEmitter();
  statusInvalid: any = 'invalid';
  totalRows: any;
  pageSize: any = 100;
  currentPage: any;
  // pageSizeOptions: number[] = [10, 20, 40, 100];
  pageSizeOptions: number[] = [100, 200, 400, 1000];
  pageLimit: any;
  pageNumber: any;
  percentageparams: any;
  origin: any;
  queryParams: any;
  statusClear: string;
  originClear: string;
  dateClear: any;
  dateClear1: any;
  search: any;
  fromDate: any;
  toDate: any;
  calendraDate: string;
  dropDownSelection: any;
  Date: any;
  searchClear: string;
  statusParams: any;
  originValue: string;
  searchparams: any;
  permissons: string;
  permissionsArray: any;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private services: DashboardServicesService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {
    console.log('====================================');
    console.log('logged procccesed');
    console.log('====================================');
  }

  @ViewChild(MatPaginator) paginator: any = MatPaginator;
  ELEMENT_DATA: Element[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  ngOnInit(): void {
    this.permissons = localStorage.getItem('permissons');

    this.permissionsArray = JSON.parse(this.permissons);
    console.log(this.permissionsArray.support.edit);

    this.spinner.show();

    setTimeout(() => {
      this.spinner.hide();
    }, 1500);
    this.queryParams = {
      search: this.search ? this.search : '',
      origin: this.origin ? this.origin : '',
      status: this.statusParams ? this.statusParams : '',
      fromDate: this.fromDate ? this.fromDate : '',
      toDate: this.toDate ? this.toDate : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.services.supportRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.message;
      console.log(this.ELEMENT_DATA);
      this.total = response.totalSupport;
      this.open = response.totalOpen;
      this.invalid = response.totalInvalid;
      this.closed = response.totalClose;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
    });
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    this.queryParams = {
      search: this.search ? this.search : '',
      origin: this.origin ? this.origin : '',
      status: this.statusParams ? this.statusParams : '',
      fromDate: this.fromDate ? this.fromDate : '',
      toDate: this.toDate ? this.toDate : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    this.services.supportRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.message;
      this.total = response.totalSupport;
      this.open = response.totalOpen;
      this.invalid = response.totalInvalid;
      this.closed = response.totalClose;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  reset() {
    this.statusClear = '';
    this.searchClear = '';
    this.originClear = '';
    this.search = '';
    this.origin = '';
    this.statusParams = '';
    this.fromDate = '';
    this.toDate = '';
    this.fromDate = '';
    this.dateClear = null;
    this.dateClear1 = null;
    this.queryParams = {
      search: this.search ? this.search : '',
      origin: this.origin ? this.origin : '',
      status: this.statusParams ? this.statusParams : '',
      fromDate: this.fromDate ? this.fromDate : '',
      toDate: this.toDate ? this.toDate : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.services.supportRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.message;
      this.total = response.totalSupport;
      this.open = response.totalOpen;
      this.invalid = response.totalInvalid;
      this.closed = response.totalClose;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      // this.dataSource.paginator = this.paginator;
    });
  }
  displayedColumns: string[] = [
    'Requestno',
    'user',
    'Service',
    'RECEIVERSNAME',
    'date',
    'Status',
    'Origin',
    'Action',
  ];
  // dataSource = new MatTableDataSource(ELEMENT_DATA);
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }
  onChangeDate(e: any) {
    this.dropDownSelection = e.target.value;
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    this.fromDate = this.calendraDate;
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.queryParams = {
        search: this.search ? this.search : '',
        origin: this.origin ? this.origin : '',
        status: this.statusParams ? this.statusParams : '',
        fromDate: this.fromDate ? this.fromDate : '',
        toDate: this.toDate ? this.toDate : '',
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage ? this.currentPage : '',
      };
      this.services.supportRequest(this.queryParams).subscribe((response) => {
        this.ELEMENT_DATA = response.message;
        this.total = response.totalSupport;
        this.open = response.totalOpen;
        this.invalid = response.totalInvalid;
        this.closed = response.totalClose;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
      });
    }
  }
  onChangeToDate(e: any) {
    this.dropDownSelection = e.target.value;
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');

    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.queryParams = {
        search: this.search ? this.search : '',
        origin: this.origin ? this.origin : '',
        status: this.statusParams ? this.statusParams : '',
        fromDate: this.fromDate ? this.fromDate : '',
        toDate: this.toDate ? this.toDate : '',
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage ? this.currentPage : '',
      };
      this.services.supportRequest(this.queryParams).subscribe((response) => {
        this.ELEMENT_DATA = response.message;
        this.total = response.totalSupport;
        this.open = response.totalOpen;
        this.invalid = response.totalInvalid;
        this.closed = response.totalClose;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
      });
    }
  }
  originDropDown(event: any) {
    if (event.value != '') {
      this.origin = JSON.stringify(event.value);
      // this.originValue = this.origin);
    } else {
      this.origin = '';
    }
    this.queryParams = {
      search: this.search ? this.search : '',
      origin: this.origin ? this.origin : '',
      status: this.statusParams ? this.statusParams : '',
      fromDate: this.fromDate ? this.fromDate : '',
      toDate: this.toDate ? this.toDate : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.services.supportRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.message;
      this.total = response.totalSupport;
      this.open = response.totalOpen;
      this.invalid = response.totalInvalid;
      this.closed = response.totalClose;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
    });
  }
  searchDropDown(event: any) {
    if (event.target.value != '') {
      this.search = event.target.value;
    } else {
      this.search = '';
    }
    this.queryParams = {
      search: this.search ? this.search : '',
      origin: this.origin ? this.origin : '',
      status: this.statusParams ? this.statusParams : '',
      fromDate: this.fromDate ? this.fromDate : '',
      toDate: this.toDate ? this.toDate : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.services.supportRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.message;
      this.total = response.totalSupport;
      this.open = response.totalOpen;
      this.invalid = response.totalInvalid;
      this.closed = response.totalClose;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      // this.dataSource.paginator = this.paginator;
    });
  }
  statusDropDown(event: any) {
    if (event.value != '') {
      this.statusParams = JSON.stringify(event.value);
    } else {
      this.statusParams = '';
    }
    this.queryParams = {
      search: this.search ? this.search : '',
      origin: this.origin ? this.origin : '',
      status: this.statusParams ? this.statusParams : '',
      fromDate: this.fromDate ? this.fromDate : '',
      toDate: this.toDate ? this.toDate : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.services.supportRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.message;
      this.total = response.totalSupport;
      this.open = response.totalOpen;
      this.invalid = response.totalInvalid;
      this.closed = response.totalClose;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  Analytics() {
    this.router.navigate(['Support/Analytics']);
  }
  view() {
    this.dialog.open(ViewissuedialogComponent, {
      disableClose: true,
    });
  }
  getSupportRequest() {
    // this.queryParams = {
    //   pageLimit: this.pageSize ? this.pageSize : '',
    //   pageNumber: this.currentPage ? this.currentPage : '',
    // };
    this.services.supportRequest(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.message;
      this.total = response.totalSupport;
      this.open = response.totalOpen;
      this.invalid = response.totalInvalid;
      this.closed = response.totalClose;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      // this.dataSource.paginator = this.paginator;
    });
  }
  markAsClose(id: any) {
    this.queryParamsStatus = {
      status: this.statusCompleted ? this.statusCompleted : '',
    };
    this.services
      .supportChangeStatus(id, this.queryParamsStatus)
      .subscribe((response) => {
        this.statusMessage = response.message;
        this.dialog.open(MarkAsClosedcommonDialogComponent, {
          data:{
            message: this.statusMessage,
            disableClose: true,
          }
        });
        this.getSupportRequest();
        // this.reset();
        this.onLoadPage.emit();
      });
  }
  markAsInvalid(id: any) {
    this.queryParamsStatus = {
      status: this.statusInvalid ? this.statusInvalid : '',
    };
    this.services
      .supportChangeStatus(id, this.queryParamsStatus)
      .subscribe((response) => {
        this.dialog.open(MarkAsInvalidcommonDialogComponent, {
          data: {
            disableClose: true,
          },
        });
        this.getSupportRequest();
        this.onLoadPage.emit();
      });
  }
}
