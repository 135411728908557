import { Component, EventEmitter, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MarkAsClosedcommonDialogComponent } from 'src/app/mark-as-closedcommon-dialog/mark-as-closedcommon-dialog.component';
import { DashboardServicesService } from 'src/app/_services/dashboard-services.service';
import { LoaderService } from 'src/app/_services/loader.service';
import { environment } from 'src/environments/environment';
import { ScheduleDialogComponent } from '../schedule-dialog/schedule-dialog.component';
import { MarkAsCompletecommonDialogComponent } from 'src/app/mark-as-completecommon-dialog/mark-as-completecommon-dialog.component';

@Component({
  selector: 'app-viewservicerequest',
  templateUrl: './viewservicerequest.component.html',
  styleUrls: ['./viewservicerequest.component.css'],
  
})
export class ViewservicerequestComponent implements OnInit {
  url = `${environment.imageURl}`;

  userDetails = [
    { key: 'Gender', Value: 'Female' },
    { key: 'Email', Value: 'kristinwatson23@gmail.com' },
    { key: 'Mobile No', Value: '9876543210' },
  ];
  serviceRequest = [
    { key: 'Who’s This For:', Value: 'Brother' },
    { key: 'Receiver’s Name:', Value: 'Female' },
    { key: 'Receiver’s Age:', Value: '24 Yrs.' },
    { key: 'Date:', Value: '18-11-2022' },
    {
      key: 'Address:',
      Value: '4517 Washington Ave. Manchester, Kentucky 39495',
    },
    { key: 'State:', Value: 'Telangana' },
    { key: 'City:', Value: 'Hyderabad' },
  ];
  userDetailsParams: any;
  queryParamsDetails: any;
  details: any;
  onLoadPage = new EventEmitter();
  serviceHistory: any;
  queryParamsStatus: any;
  statusCompleted: any = 'accepted';
  statusMessage: any;
  IMAGE_URL: any;
  loaderShow: boolean;
  permissons:any;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    public service: DashboardServicesService,
    private activatedRoute: ActivatedRoute,
    public loader: LoaderService,
    private spinner: NgxSpinnerService
  ) {
    this.IMAGE_URL = localStorage.getItem('IMAGE_URL');
    this.loader.loading.subscribe((res) => {
      this.loaderShow = res;
    });

     this.spinner.show();
    this.permissons = JSON.parse(localStorage.getItem('permissons'))
    setTimeout(() => {
      this.spinner.hide();
    }, 1500);
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.queryParamsDetails = { ...params.keys, ...params };
      this.userDetailsParams = params.get('_id');
      this.service
      .serviceRequestView(this.userDetailsParams)
      .subscribe((response) => {
        this.details = response.data;
        this.serviceHistory = response.serviceHistory;
      });
    });
  }
  ngOnInit(): void {
    
  // location.reload();
    
  }
  schedule(index:number) {
    // this.dialog.open(ScheduleDialogComponent, {
    //   disableClose: true,
    // });
    this.queryParamsStatus = {
      status: this.statusCompleted ? this.statusCompleted : '',
    };
    this.service
      .serviceRequestStatus(this.userDetailsParams, this.queryParamsStatus)
      .subscribe((response) => {
        this.statusMessage = response.message;
        this.dialog.open(MarkAsCompletecommonDialogComponent, {
          data: {
            message: this.statusMessage,
            disableClose: true,
          },
        });
        this.onLoadPage.emit();

        this.details[index].status = 'accepted';
      });
  }
  reloadComponent() {
    location.reload();
  }
}

