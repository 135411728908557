<div class="main">
    <div class="container-fluid">
      <div class="congrats text-center">
        <img src="../../assets/images/panel_images/thank.gif" class="success_Img" />
      </div>
      <div class="cms-heading-succes-section">
        <h5>Request Completed</h5>
        <p>The selected request has been marked as Accepted.
        </p>
      </div>
      <div class="close-btn mt-4 mb-4">
        <button class="btn btn-close" (click)="close()">Close</button>
      </div>
    </div>
  </div>