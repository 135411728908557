import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardServicesService } from 'src/app/_services/dashboard-services.service';

@Component({
  selector: 'app-schedule-dialog',
  templateUrl: './schedule-dialog.component.html',
  styleUrls: ['./schedule-dialog.component.css'],
})
export class ScheduleDialogComponent implements OnInit {
  serviceRequest = [
    { key: 'Who’s This For:', Value: 'Brother' },
    { key: 'Receiver’s Name:', Value: 'Female' },
    { key: 'Receiver’s Age:', Value: '24 Yrs.' },
    { key: 'Date:', Value: '18-11-2022' },
    {
      key: 'Address:',
      Value: '4517 Washington Ave. Manchester, Kentucky 39495',
    },
    { key: 'State:', Value: 'Telangana' },
    { key: 'City:', Value: 'Hyderabad' },
  ];
  queryParamsDetails: any;
  userDetailsParams: any;
  details: any;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    public service: DashboardServicesService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.queryParamsDetails = { ...params.keys, ...params };
      this.userDetailsParams = params.get('_id');
    });

    this.service
      .serviceRequestView(this.userDetailsParams)
      .subscribe((response) => {
        this.details = response.data;
      });
  }
  close() {
    this.dialog.closeAll();
  }
}
