<ng-container *ngIf="rolePermission==='superAdmin' || rolePermission==='admin'; else elseblock">
<div class="main">
  <div class="container-fluid bg-white">
    <div class="user-list-header d-flex pt-3 pb-1 ml-2">
      <div class="user-list-heading d-flex">
        <img
          src="../../assets/images/panel_images/it.svg"
          class="user-list mr-2"
        />
        <h6 class="mt-2">Internal Team</h6>
      </div>
      <div class="search-bar ml-3 mr-2">
        <mat-form-field class="example-full-width" appearance="fill">
          <input
            matInput
            (keyup)="searchDropDown($event)"
            placeholder="Search by name , email..."
            #input
            [(ngModel)]="searchClear"
          />
          <mat-icon matSuffix
            ><img
              src="../../assets/images/panel_images/search-icon.svg"
              class="calendra-img"
          /></mat-icon>
        </mat-form-field>
      </div>
      <div class="service-list-drop-down mr-2">
        <mat-form-field appearance="fill">
          <div class="arrow-up"></div>
          <span matPrefix>Role:&nbsp;</span>
          <mat-icon matSuffix 
          ><img
            src="../../assets/images/panel_images/arrow-1.svg"
            class="calendra-img"
        /></mat-icon>
          <mat-select
            [formControl]="serviceControl"
            placeholder="Select"
            multiple
            disableOptionCentering
            panelClass="myPanelDonationrole"
            #closeSerivce
            [(ngModel)]="roleClear"
            (selectionChange)="roleEvent($event)"
          >
          
            <mat-select-trigger>
              {{serviceControl.value?.[0] || ''}}
              <span
                *ngIf="(serviceControl.value?.length || 0) > 1"
                class="example-additional-selection"
              >
                (+{{ (serviceControl.value?.length || 0) - 1 }}
                {{ serviceControl.value?.length === 2 ? "other" : "others" }})
              </span>
            </mat-select-trigger>
            <mat-option
              class="services-view"
              *ngFor="let viewService of services"
              [value]="viewService.key"
              >{{ viewService.value }}</mat-option
            >
            <div class="apply-btn-option pt-4 pb-3">
              <button
                type="button"
                class="btn btn-apply"
                (click)="closeSerivce.close()"
              >
                Apply
              </button>
            </div>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="user-list-drop-down mr-2">
        <mat-form-field appearance="fill">
          <div class="arrow-up"></div>
          <span matPrefix>Status:&nbsp;</span>
          <mat-icon matSuffix 
          ><img
            src="../../assets/images/panel_images/arrow-1.svg"
            class="calendra-img"
        /></mat-icon>
          <mat-select
            [formControl]="statusOption"
            placeholder="Select"
            multiple
            disableOptionCentering
            panelClass="myPanelClass"
            #closeSelect
            [(ngModel)]="statusClear"
            (selectionChange)="statusDropDown($event)"
          >
            <mat-select-trigger>
              {{statusOption.value?.[0] || ''}}
              <span
                *ngIf="(statusOption.value?.length || 0) > 1"
                class="example-additional-selection"
              >
                (+{{ (statusOption.value?.length || 0) - 1 }}
                {{ statusOption.value?.length === 2 ? "other" : "others" }})
              </span>
            </mat-select-trigger>
            <mat-option
              class="services-view"
              *ngFor="let viewStatus of status"
              [value]="viewStatus.key"
              >{{ viewStatus.value }}</mat-option
            >
            <div class="apply-btn-option pt-4 pb-3">
              <button
                type="button"
                class="btn btn-apply"
                (click)="closeSelect.close()"
              >
                Apply
              </button>
            </div>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="export-button mr-3">
        <button type="button" class="cursor" (click)="internalTemDetailExport()">Export
        </button>
      </div>
      <div class="graph cursor mr-2" (click)="reset()">
        <img
          src="../../assets/images/panel_images/reset-1.png"
          class="reset-img ml-2"
        />
      </div>

      <div class="create-btn-section mx-2">
        <button
          type="button"
          class="create-btn ml-3 cursor"
          routerLink="./create-user"
        >
          Create
        </button>
      </div>
    </div>
  </div>
  <app-showloader></app-showloader>

  <div class="container-fluid pt-4 ml-2">
    <div class="table mb-5 mt-4 mr-3">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 mr-3">
        <!-- Position Column -->
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef>
            <h2>user</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              <!-- <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
      </mat-checkbox> -->

              <div class="d-flex">
                <ngx-avatar
                  *ngIf="element.profile == ''"
                  class="mr-2 mt-1"
                  size="40"
                  initialsSize="1"
                  name="{{ element.firstName }}"
                ></ngx-avatar>

                <img
                  *ngIf="element.profile != ''"
                  src="{{ element.profile }}"
                  class="user-image-table mr-2"
                />
                <div class="">
                  <h6 class="mt-2">
                    {{ element.firstName }}
                    {{
                      element.lastName != "undefined" ? element.lastName : ""
                    }}
                  </h6>
                  <p>{{ element.email }}</p>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="Phoneno">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Phone no.</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h4>{{ element.phone ? element.phone : 'NA' }}</h4>
          </td>
        </ng-container>
        <!-- Weight Column -->
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Role</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              <img
                src="../../assets/images/panel_images/admin-i.svg"
                class="admin-img mr-1"
              />
              <div class="">
                <h6 class="mt-2">{{ element.role | titlecase }}</h6>
              </div>
            </div>
          </td>
        </ng-container>
        <!-- Symbol Column -->
        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Status</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h6 *ngIf="element.status == 'active'" class="eligable">
              {{ element.status | titlecase }}
            </h6>
            <h6 *ngIf="element.status == 'suspended'" class="not-member">
              {{ element.status | titlecase }}
            </h6>
            <h6 *ngIf="element.status == ''" class="not-eligable">
              {{ element.status | titlecase }}
            </h6>
          </td>
        </ng-container>
        <ng-container matColumnDef="Lastvisited">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Created Date</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h6>{{ element.createdAt | date : "dd-MM-YYYY" }}</h6>
          </td>
        </ng-container>
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Action</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              mat-button
              [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu"
            >
              <mat-icon>
                <img
                  src="../../assets/images/panel_images/action-dot.svg"
                  id="users-total"
                  class="action-dots"
              /></mat-icon>
              <div
                #menuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="menu"
                place
              ></div>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button
                class="View"
                mat-menu-item
                value="Edit"
                routerLink="./view-user"
                [queryParams]="{ _id: element._id }"
              >
                <img
                  src="../../assets/images/panel_images/view.svg"
                  class="mr-3"
                />
                <span>View</span>
              </button>
              <button
                class="Deactivate"
                (click)="suspendUser(element._id)"
                *ngIf="element.status != 'suspended'"
                mat-menu-item
                value="Deactivate"
              >
                <img
                  src="../../assets/images/panel_images/suspened.svg"
                  class="mr-3"
                />
                <span>Suspend</span>
                <!-- <span>Change Customer type from New,Regular</span> -->
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data Available</td>
        </tr>
      </table>
      <mat-paginator
        (page)="pageChanged($event)"
        [length]="totalrecords"
        [pageIndex]="currentPage"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        aria-label="Select page of users"
      ></mat-paginator>
    </div>
  </div>
</div>
</ng-container>
<ng-template #elseblock>
  <div class="mt-5 text-center">
    <img src="../../assets//images/panel_images/notPermitted.png" width="120" alt="" />
    <p class="mt-5">You dont have permission to access this feature</p>
</div>
</ng-template>
