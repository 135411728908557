import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DashboardServicesService } from 'src/app/_services/dashboard-services.service';
import { SuccescreateDialogComponent } from '../succescreate-dialog/succescreate-dialog.component';
import { CheckboxDialogComponent } from '../checkbox-dialog/checkbox-dialog.component';
const emailPhonePattern =
  '^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})|(^[0-9]{10})+$';
  const phonePattern =
  '^[1-9][0-9]{9}$';
@Component({
  selector: 'app-createuser',
  templateUrl: './createuser.component.html',
  styleUrls: ['./createuser.component.css'],
})
export class CreateuserComponent implements OnInit {
  createSuccesfully: boolean = false;
  createUserLoader: boolean = false;
  managementOption: any = [
    { value: false, key: 'View' },
    { value: false, key: 'Edit' },
    { value: false, key: 'View analytics' },
  ];
  tvProgramsAndDontionsCheckboxValues: any = [
    { value: false, key: 'View' },
    { value: false, key: 'View analytics' },
  ];
  cmsCheckboxValues: any = [
    { value: false, key: 'View' },
    { value: false, key: 'Edit' },
  ];
  spiritualFitnessCheckboxValues: any = [
    { value: false, key: 'View' },
  ];
  createUserManagment: FormGroup;
  subRubrosSelec: any[];
  memberslistPermission: any = [];
  itSupportForm: any;
  submitted: boolean = false;
  url: any = '../../assets/images/panel_images/proflie-o.svg';
  selectedFile: File;
  updateProfileForm: any;
  @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>;
  supportQuery: {};
  sameEmailAddress: any;
  sameEmailAddressError: any;
  errorMessege: string;
  memberslistView: any;
  memberslistEdit: any;
  memberslistanalyse: any;
  apiData: any;
  serviceView: any;
  serviceEdit: any;
  serviceanalyse: any;
  members_List: any = {};
  donationView: any;
  donationEdit: any;
  donationanalyse: any;
  tvProgramEdit: any;
  tvProgramanalyse: any;
  tvProgramView: any;
  prayerView: any;
  prayerEdit: any;
  prayeranalyse: any;
  supportView: any;
  supportEdit: any;
  supportanalyse: any;
  cmsView: any;
  cmsEdit: any;
  cmsanalyse: any;
  spiritualFitnessView: any;
  firstName: any;
  firstNameUser: any;
  lastName: any;
  role: any;
  constructor(
    private service: DashboardServicesService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.role = localStorage.getItem('ROLE');
    this.createUserManagment = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      role: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern(phonePattern)]],
      email: ['', [Validators.required, Validators.pattern(emailPhonePattern)]],
      prayerRequest: [''],
      memberList: [''],
      serviceRequest: [''],
      support: [''],
      donation: [''],
      cmsModule: [''],
      spiritualFitnessModule: [''],
      tvProgram: [''],
      file: [''],
    });
  }

  ngOnInit(): void {
    this.supportQuery = {};
    this.service.userMangmentAdmin(this.supportQuery).subscribe((result) => {
      this.sameEmailAddress = result.message;
      console.log(this.sameEmailAddress);
    });
    setTimeout(() => {
      this.checkboxes.forEach((element) => {
        element.nativeElement.checked = true;
        element.nativeElement.checked = false;
      });
    });
  }
  deActivateUser() {
    this.dialog.open(CheckboxDialogComponent, {
      disableClose: true,
    });
  }
  sameEmailEvent(e: any) {
    this.sameEmailAddressError = this.sameEmailAddress.forEach(
      (result: any) => {
        // console.log(result.email);
        if (result.email == e.target.value) {
        }
        if (e.target.value == '') {
          this.errorMessege = '';
        }
      }
    );
  }
  createUser() {
    if (!this.userManagementControls.spiritualFitnessModule.value
      && !this.userManagementControls.cmsModule.value
      && !this.userManagementControls.support.value
      && !this.userManagementControls.tvProgram.value
      && !this.userManagementControls.donation.value
      && !this.userManagementControls.prayerRequest.value
      && !this.userManagementControls.serviceRequest.value
      && !this.userManagementControls.memberList.value) {
      this.submitted = true;
      this.deActivateUser();
      console.log(this.createUserManagment);
    } else if (this.createUserManagment.invalid) {
      this.submitted = true;
    } else {
      this.submitted = false;
      this.createUserLoader = true;
      let obj: any = {
        members_List: {
          view: this.memberslistView ? true : false,
          edit: this.memberslistEdit ? true : false,
          analyse: this.memberslistanalyse ? true : false,
        },
        service_Requests: {
          view: this.serviceView ? true : false,
          edit: this.serviceEdit ? true : false,
          analyse: this.serviceanalyse ? true : false,
        },
        prayer_Requests: {
          view: this.prayerView ? true : false,
          edit: this.prayerEdit ? true : false,
          analyse: this.prayeranalyse ? true : false,
        },
        Donation: {
          view: this.donationView ? true : false,
          edit: this.donationEdit ? true : false,
          analyse: this.donationanalyse ? true : false,
        },
        tv_programs: {
          view: this.tvProgramView ? true : false,
          edit: this.tvProgramEdit ? true : false,
          analyse: this.tvProgramanalyse ? true : false,
        },
        support: {
          view: this.supportView ? true : false,
          edit: this.supportEdit ? true : false,
          analyse: this.supportanalyse ? true : false,
        },
        cms_Model: {
          view: this.cmsView ? true : false,
          edit: this.cmsEdit ? true : false,
          analyse: this.cmsanalyse ? true : false,
        },
        spiritual_Fitness: {
          view: this.spiritualFitnessView ? true : false,
        },
      };
      const formData = new FormData();
      formData.append('file', this.createUserManagment.get('file').value);
      formData.append('firstName', this.createUserManagment.value.firstName);
      formData.append('lastName', this.createUserManagment.value.lastName);
      formData.append('email', this.createUserManagment.value.email);
      formData.append('phone', this.createUserManagment.value.phone);
      formData.append('role', this.createUserManagment.value.role);
      formData.append('permissions', JSON.stringify(obj));
      console.log(obj);
      console.log(formData);
      this.service.userMangmentAdminAddMember(formData).subscribe(
        (res) => {
          setTimeout(() => {
            console.log(res);
            this.createUserLoader = false;
            this.dialog.open(SuccescreateDialogComponent, {
              disableClose: true,
            });
          }, 1000);

          this.router.navigate(['Internal-Team']);
          this.createUserManagment.reset();
          this.checkboxes.forEach((element) => {
            element.nativeElement.checked = false;
          });
        },
        (error) => {
          if (error.error.message == 'Email already exits') {
            this.errorMessege = 'Email Already Exists';
            this.createUserLoader = false;
            console.error(error.error.message);
          }
        }
      );
    }
  }
  create() {
    this.createSuccesfully = true;
    setTimeout(() => {
      this.createSuccesfully = false;
    }, 4000);
  }
  onFileSelected(event: any): void {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      // this.msg = '';
      this.url = reader.result as string;
      this.selectedFile = <File>event.target.files[0];
    };
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.createUserManagment.get('file').setValue(file);
      console.log(this.createUserManagment.get('file').value);
    }
    //  this.updateProfileForm.get('file').setValue(file);
  }
  onChange(event: any): void {
    if (event.target.value == 'View') {
      // console.log(this.memberslistEdit);
      this.memberslistView = event.currentTarget.checked;
    } else if (event.target.value == 'Edit') {
      this.memberslistEdit = event.currentTarget.checked;
      this.memberslistView = this.memberslistEdit;

    } else {
      this.memberslistanalyse = event.currentTarget.checked;
    }
  }
  onChangeService(event: any): void {
    if (event.target.value == 'View') {
      this.serviceView = event.currentTarget.checked;
    } else if (event.target.value == 'Edit') {
      this.serviceEdit = event.currentTarget.checked;
      this.serviceView = this.serviceEdit
    } else {
      this.serviceanalyse = event.currentTarget.checked;
    }
  }
  onChangeDonations(event: any): void {
    if (event.target.value == 'View') {
      this.donationView = event.currentTarget.checked;
    } else if (event.target.value == 'Edit') {
      this.donationEdit = event.currentTarget.checked;
    } else {
      this.donationanalyse = event.currentTarget.checked;
    }
  }
  onChangeTvPrograms(event: any): void {
    if (event.target.value == 'View') {
      this.tvProgramView = event.currentTarget.checked;
    } else if (event.target.value == 'Edit') {
      this.tvProgramEdit = event.currentTarget.checked;
    } else {
      this.tvProgramanalyse = event.currentTarget.checked;
    }
  }
  onChangePrayer(event: any): void {
    if (event.target.value == 'View') {
      this.prayerView = event.currentTarget.checked;
    } else if (event.target.value == 'Edit') {
      this.prayerEdit = event.currentTarget.checked;
      this.prayerView = this.prayerEdit
    } else {
      this.prayeranalyse = event.currentTarget.checked;
    }
  }
  onChangeSupport(event: any): void {
    if (event.target.value == 'View') {
      this.supportView = event.currentTarget.checked;
    } else if (event.target.value == 'Edit') {
      this.supportEdit = event.currentTarget.checked;
      this.supportView = this.supportEdit
    } else {
      this.supportanalyse = event.currentTarget.checked;
    }
  }
  onChangeCms(event: any): void {
    if (event.target.value == 'View') {
      this.cmsView = event.currentTarget.checked;
    } else if (event.target.value == 'Edit') {
      this.cmsEdit = event.currentTarget.checked;
      this.cmsView = this.cmsEdit
    } else {
      this.cmsanalyse = event.currentTarget.checked;
    }
  }
  onChangeSpritualFitness(event: any): void {
    if (event.target.value == 'View') {
      this.spiritualFitnessView = event.currentTarget.checked;
    }

  }
  get userManagementControls(): { [key: string]: AbstractControl } {
    return this.createUserManagment.controls;
  }
  // validations for numberOnly
  numberOnly(event: any): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  // validations for numberOnly
  alphabetOnly(event: any): boolean {
    var inp = String.fromCharCode(event.keyCode);

    console.log(event.target.value);

    if (/[a-zA-Z ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  

}
