<div class="main">
  <div class="container-fluid">
    <div class="congrats text-center pt-2">
      <!-- <img src="../../assets/images/panel_images/thank.gif" class="success_Img" /> -->
       <!-- <img src="../../../assets/images/panel_images/close.svg" class="mb-3 success_Img"> -->
    </div>
        <div class="cms-heading-succes-section">
            <h5>Permission Error</h5>
            <p>Please Select Atleast One Permission</p>
         </div>
      <div class="text-center close-btn mt-4 mb-4">
        <button class="btn  btn-close" (click)="close()">Close</button>
      </div>
    </div>
    </div>
