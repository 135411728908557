<div class="main">
    <div class="">
        <div class="login-background-image">
            <div class="login-card-main-section h-100 d-flex align-items-center justify-content-center">
                <div class="card">
                    <div class="card-body">
                        <div class="">
                            <div class="login-heading-details pt-3 text-center">
                                <div class="login-card-image">
                                    <img src="../../../assets/images/Calvary_Temple/Property 1=New Logo@3x.png" width="100%" class="login_header_img" />
                                </div>
                                <div class="login-heading my-4">
                                    <h5>Calvary Temple</h5>
                                    <p>World’s second largest church</p>
                                </div>
                            </div>
                            <form [formGroup]="loginForm">
                                <div class="Email-field">
                                    <mat-form-field>
                                        <mat-label>Email address</mat-label>
                                        <input formControlName="email" matInput />
                                    </mat-form-field>
                                    <div *ngIf="f.email.errors?.required && submitted" class="invalid-feedback m-t d-block">
                                        Email is required
                                    </div>
                                    <div *ngIf="f.email.errors?.pattern && submitted" class="invalid-feedback m-t d-block">
                                        Email is Not-Valid
                                    </div>
                                </div>
                                <div class="Pasword-field mt-3">
                                    <mat-form-field>
                                        <mat-label>Password</mat-label>
                                        <input formControlName="password" matInput [type]="hide ? 'password' : 'text'" />

                                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                      <!-- <mat-icon
                      ><img
                        src="~src/../assets/images/login/password-eye.svg"
                      />{{ hide ? "visibility_off" : "visibility" }}</mat-icon
                    > -->
                      <mat-icon>{{
                        hide ? "visibility_off" : "visibility"
                      }}</mat-icon>
                    </button>
                                    </mat-form-field>
                                    <div *ngIf="f.password.errors?.required && submitted" class="invalid-feedback m-t d-block">
                                        Password is required
                                    </div>
                                </div>
                                <div *ngIf="this.error != null " class="invalid-feedback d-block">
                                    {{error}}
                                </div>
                            </form>
                            <div class="login-btn-continue mt-4">
                                <button class="btn" (click)="login()">Continue</button>
                            </div>
                            <div class="forgot_passoword mt-3 pb-5 text-center">
                                <a routerLink="/admin/forgot-password">Forgot Password?</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>