import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mark-as-successcommon-dialog',
  templateUrl: './mark-as-successcommon-dialog.component.html',
  styleUrls: ['./mark-as-successcommon-dialog.component.css']
})
export class MarkAsSuccesscommonDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
