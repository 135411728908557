<div class="main">
  <div class="container-fluid">
    <div class="congrats text-center pt-2">
      <!-- <img src="../../assets/images/panel_images/thank.gif" class="success_Img" /> -->
       <img src="../../../assets/images/panel_images/close.svg" class="mb-3 success_Img">
    </div>
        <div class="cms-heading-succes-section">
            <h5>Suspend User</h5>
            <p>The selected user has been suspended</p>
         </div>
      <div class="close-btn mt-4 mb-4">
        <button class="btn btn-close" (click)="close()">Close</button>
      </div>
    </div>
    </div>
