<ng-container
  *ngIf="
    permissionsArray.service_Requests.analyse ||
      permissionsArray.service_Requests.edit ||
      permissionsArray.service_Requests.view;
    else alternateTemplate
  "
  class="main"
>
  <div class="container-fluid bg-white">
    <div class="user-list-header d-flex pt-3 pb-1 ml-2">
        <ng-container *ngIf="permissionsArray.service_Requests.view;"> 
      <div class="user-list-heading d-flex">
        <img
          src="../../assets/images/panel_images/sr.svg"
          class="user-list mr-2"
        />
        <h6 class="mt-2">Service Requests</h6>
      </div>
      <div class="search-bar ml-3">
        <mat-form-field class="example-full-width" appearance="fill">
          <input
            matInput
            [(ngModel)]="searchClear"
            placeholder="Search users..."
            #input
            (keyup)="searchDropDown($event)"
          />
          <mat-icon matSuffix
            ><img
              src="../../assets/images/panel_images/search-icon.svg"
              class="calendra-img"
          /></mat-icon>
        </mat-form-field>
      </div>
      <div class="calendra-field mr-3">
        <mat-form-field class="example-full-width" appearance="fill">
          <span matPrefix>Date:&nbsp;</span>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              matInput
              class="ml-1"
              placeholder="Start date"
              readonly="readonly"
              (dateChange)="onChangeDate($event)"
              [(ngModel)]="dateClear"
            />
            <input
              matEndDate
              matInput
              placeholder="End date"
              readonly="readonly"
              (dateChange)="onChangeToDate($event)"
              [(ngModel)]="dateClear1"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon
              ><img
                src="../../assets/images/panel_images/c-11.svg"
                class="calendra-img"
            /></mat-icon>
          </mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="service-list-drop-down mr-2">
        <mat-form-field appearance="fill">
          <div class="arrow-up"></div>
          <span matPrefix>Service:&nbsp;</span>
          <mat-icon matSuffix 
          ><img
            src="../../assets/images/panel_images/arrow-1.svg"
            class="calendra-img"
        /></mat-icon>
          <mat-select
            [formControl]="serviceControl"
            placeholder="Select..."
            multiple
            disableOptionCentering
            panelClass="myPanelClass"
            #closeSerivce
            (selectionChange)="serviceDropDown($event)"
            [(ngModel)]="serviceClear"
          >
            <mat-select-trigger>
              {{serviceControl.value?.[0] || ''}}
              <span
                *ngIf="(serviceControl.value?.length || 0) > 1"
                class="example-additional-selection"
              >
                (+{{ (serviceControl.value?.length || 0) - 1 }}
                {{ serviceControl.value?.length === 2 ? "other" : "others" }})
              </span>
            </mat-select-trigger>
            <mat-option
              class="services-view"
              *ngFor="let viewService of servicesDropDown"
              [value]="viewService"
              >{{ viewService }}</mat-option
            >
            <div class="apply-btn-option pt-4 pb-3">
              <button
                type="button"
                class="btn btn-apply"
                (click)="closeSerivce.close()"
              >
                Apply
              </button>
            </div>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="user-list-drop-down mr-2">
        <mat-form-field appearance="fill">
          <div class="arrow-up"></div>
          <span matPrefix>Status:&nbsp;</span>
          <mat-icon matSuffix 
          ><img
            src="../../assets/images/panel_images/arrow-1.svg"
            class="calendra-img"
        /></mat-icon>
          <mat-select
            [formControl]="statusOption"
            placeholder="Select..."
            multiple
            disableOptionCentering
            panelClass="myPanelClass"
            (keyup)="applyFilter($event)"
            #closeSelect
            (selectionChange)="statusDropDown($event)"
            [(ngModel)]="statusClear"
          >
            <mat-select-trigger>
              {{statusOption.value?.[0] || ''}}
              <span
                *ngIf="(statusOption.value?.length || 0) > 1"
                class="example-additional-selection"
              >
                (+{{ (statusOption.value?.length || 0) - 1 }}
                {{ statusOption.value?.length === 2 ? "other" : "others" }})
              </span>
            </mat-select-trigger>
            <mat-option
              [class.not-eligable-option]="viewStatus === 'in-progress'"
              [class.eligable-option]="viewStatus === 'completed'"
              [class.not-member-option]="viewStatus === 'cancelled'"
              [class.memberStatus-status]="viewStatus === 'accepted'"
              *ngFor="let viewStatus of status"
              [value]="viewStatus.value"
              >{{ viewStatus.key }}</mat-option
            >
            <div class="apply-btn-option pt-4 pb-3">
              <button
                type="button"
                class="btn btn-apply"
                (click)="closeSelect.close()"
              >
                Apply
              </button>
            </div>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="export-button mr-3">
        <button type="button" class="cursor" (click)="serviceReqDetailExport()">Export
        </button>
      </div>
      <div class="graph cursor mr-2" (click)="reset()">
        <img
          src="../../assets/images/panel_images/reset-1.png"
          class="reset-img ml-2"
        />
      </div>
    </ng-container>
      <div
        class="graph cursor mr-2 ml-auto"
        *ngIf="permissionsArray.service_Requests.analyse"
        (click)="Analytics()"
      >
        <img
          src="../../assets/images/panel_images/graph.svg"
          class="graph-img ml-2"
        />
      </div>
    </div>
  </div>
  <ng-container *ngIf="permissionsArray.service_Requests.view; else listTemplate" class="container-fluid pt-4 ml-1">
    <div class="d-flex p-4">
      <div class="card users-count mr-3 total-user-bg">
        <div class="card-body d-flex">
          <img
            src="../../assets/images/panel_images/sr-1.svg"
            id="users-total"
            class="mr-2"
          />
          <div class="card-details-users">
            <h4 class="mt-3">Total Service Requests</h4>
            <h2 class="mt-2">
              {{ totalrecords ? (totalrecords | records) : "0" }}
            </h2>
          </div>
        </div>
      </div>
      <div class="card users-count mr-3 total-active-bg">
        <div class="card-body d-flex">
          <img
            src="../../assets/images/panel_images/sr-2.svg"
            id="users-total"
            class="mr-2"
          />
          <div class="card-details-users">
            <h4 class="mt-3">Total Active Requests</h4>
            <h2 class="mt-2">
              {{ activeRequest ? (activeRequest | records) : "0" }}
            </h2>
          </div>
        </div>
      </div>
      <div class="card users-count mr-3 total-existing-bg">
        <div class="card-body d-flex">
          <img
            src="../../assets/images/panel_images/sr-3.svg"
            id="users-total"
            class="mr-2"
          />
          <div class="card-details-users">
            <h4 class="mt-3">Total Completed Requests</h4>
            <h2 class="mt-2">
              {{ completedRequest ? (completedRequest | records) : "0" }}
            </h2>
          </div>
        </div>
      </div>

      <div class="card users-count mr-3 total-non-bg">
        <div class="card-body d-flex">
          <img
            src="../../assets/images/panel_images/sr-4.svg"
            id="users-total"
            class="mr-2"
          />
          <div class="card-details-users">
            <h4 class="mt-3">Total Cancelled Requests</h4>
            <h2 class="mt-2">
              {{ cancelledRequest ? (cancelledRequest | records) : "0" }}
            </h2>
          </div>
        </div>
      </div>
    </div>

    <app-showloader></app-showloader>
    <div class="table mt-5 mb-5">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Position Column -->
        <ng-container matColumnDef="Requestno">
          <th mat-header-cell class="th-header-table" *matHeaderCellDef>
            <h2>Request no</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="">
              <!-- <h6 class="mt-2">{{ element.serviceRequestId ? element.serviceRequestId : "-"}}</h6> </div> -->
              <h6 class="mt-2">
                {{ element.serviceRequestId ? element.serviceRequestId : "-" }}
              </h6>
            </div>
          </td>
        </ng-container>
        <ng-container class="text-center" matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef>
            <h2 class="ml-4">user</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              <!-- <img src="../../assets/images/panel_images/user-9.svg" class="user-image-table mr-2" /> -->
              <!-- <img  src="{{ element.user[0]?.profile  }}" class="user-image-table mr-2"  /> -->

              <img
                *ngIf="element.user[0]?.profile != null"
                [src]="element.user[0]?.profile"
                class="user-image-table mr-2 mt-md-2"
              />
              <ngx-avatar
                *ngIf="element.user[0]?.profile == null"
                class="mr-2 mt-md-2"
                initialsSize="1"
                size="40"
                name="{{ element?.name }}"
              ></ngx-avatar>
              <ngx-avatar
                *ngIf="element?.name == null"
                class="mr-3 mt-md-2"
                initialsSize="1"
                size="40"
                name="N/A"
              ></ngx-avatar>

              <div class="">
                <h6 class="mt-2">{{ element.name }}</h6>
                <p>
                  {{
                    element?.user[0]?.membershipId
                      ? element.user[0].membershipId
                      : ""
                  }}
                </p>
              </div>
            </div>
          </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="Service">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Service</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h6>
              {{ element.service[0]?.title ? element.service[0]?.title : "-" }}
            </h6>
          </td>
        </ng-container>
        <!-- Weight Column -->
        <!-- <ng-container matColumnDef="RECEIVERSNAME">
					<th mat-header-cell *matHeaderCellDef>
						<h2>RECEIVER'S NAME</h2></th>
					<td mat-cell *matCellDef="let element">
						<h6 class="mb-0">{{ element.receiverName ? element.receiverName : '-'}}</h6>

					</td>
				</ng-container> -->
        <!-- Symbol Column -->
        <ng-container matColumnDef="Date">
          <th class="text-left" mat-header-cell *matHeaderCellDef>
            <h2>Date & Time</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h6>
              {{ element.createdAt | date : "MMMM d, y" }} <br/>
              {{ element.createdAt | date : "shortTime" }}
            </h6>
          </td>
        </ng-container>
        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef>
            <h2 class="ml-5" >Status</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h6
              *ngIf="element.status == 'completed'"
              class="eligable s-service"
            >
              {{ element.status | titlecase }}
            </h6>
            <h6
              *ngIf="element.status == 'accepted'"
              class="memberStatus s-service"
            >
              {{ element.status | titlecase }}
            </h6>
            <h6
              *ngIf="element.status == 'in-progress'"
              class="not-eligable s-service"
            >
              New
            </h6>
            <h6
              *ngIf="element.status == 'cancelled'"
              class="not-member s-service"
            >
              {{ element.status | titlecase }}
            </h6>
          </td>
        </ng-container>
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Action</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              mat-button
              [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu"
            >
              <mat-icon>
                <img
                  src="../../assets/images/panel_images/action-dot.svg"
                  class="action-dots"
              /></mat-icon>
              <div
                #menuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="menu"
                place
              ></div>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button
                class="View"
                mat-menu-item
                value="view"
                routerLink="./viewRequest"
                [queryParams]="{ _id: element._id }"
              >
                <img
                  src="../../assets/images/panel_images/view.svg"
                  class="mr-3"
                />
                <span>View</span>
                <!-- <span>Change Customer type from New,Regular</span> -->
              </button>
              <button
                class="View"
                mat-menu-item
                value="element._id"
                *ngIf="
                  element.status != 'completed' &&
                  element.status != 'cancelled' &&
                  permissionsArray.service_Requests.edit
                "
                (click)="mark(element._id)"
              >
                <img
                  src="../../assets/images/panel_images/mark.svg"
                  class="mr-3"
                />
                <span>Mark as Close</span>
                <!-- <span>Change Customer type from New,Regular</span> -->
              </button>
              <button
                class="Deactivate"
                mat-menu-item
                value="Edit"
                *ngIf="
                  element.status != 'completed' &&
                  element.status != 'cancelled' &&
                  permissionsArray.service_Requests.edit
                "
                (click)="deActivateUser()"
                routerLink="./"
                [queryParams]="{ _id: element._id }"
              >
                <img
                  src="../../assets/images/panel_images/decline.svg"
                  class="mr-3"
                />
                <span>Decline</span>
                <!-- <span>Change Customer type from New,Regular</span> -->
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data available</td>
        </tr>
      </table>
      <mat-paginator
        (page)="pageChanged($event)"
        [length]="totalrecords"
        [pageIndex]="currentPage"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        aria-label="Select page of users"
      ></mat-paginator>
    </div>
  </ng-container>
  <!-- <div class="succesfully-close mt-5 mb-5 text-center" *ngIf="markedClose">
    <button type="button" class="btn">
      <img
        src="../../assets/images/panel_images/t-c.svg"
        class="mr-2"
      />Successfully Marked as Close
    </button>
  </div> -->
</ng-container>
<ng-template #listTemplate>
  <div class="mt-5 text-center">
    <img
      src="../../assets//images/panel_images/notPermitted.png"
      width="120"
      alt=""
    />
    <p class="mt-5">You dont have permission to access this feature</p>
  </div>
</ng-template>
<ng-template #alternateTemplate>
  <div class="mt-5 text-center">
    <img
      src="../../assets//images/panel_images/notPermitted.png"
      width="120"
      alt=""
    />
    <p class="mt-5">You dont have permission to access this feature</p>
  </div>
</ng-template>
