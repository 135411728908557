<div class="main">
  <div class="container-fluid">
    <div class="congrats text-center">
      <!-- <img src="../../assets/images/panel_images/thank.gif" class="success_Img" /> -->
       <img src="../../../assets/images/panel_images/close.svg" class="mb-3 success_Img">
    </div>
        <div class="cms-heading-succes-section  mb-4">
            <h5>Decline Prayer Request</h5>
            <p>Are you sure you want to decline this prayer request? Once done, you can’t undo it.
</p>
         </div>
      <div class="">
        <div class="Cancel-btn ">
            <button class="btn btn-cancel" (click)="close()">Cancel</button>
            <button class="btn btn-Deactivate" (click)="decline()">Decline</button>
        </div>
    </div>
    </div>
    </div>
