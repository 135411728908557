<div style="max-height: 90vh; overflow: auto !important;" class="main">
  <div class="container-fluid" *ngIf="errorIssue">
    <div style="scroll-behavior: auto;" *ngFor="let issue of issueDialog">
      <div class="d-flex">
        <div class="d-flex mr-auto">
          <div class="mr-0 mt-3">
            <!-- <img src="../../../assets/images/panel_images/user-img.svg" class="rounded" > -->
            <img
              *ngIf="issue?.user[0]?.profile != null && issue.user[0].profile != ''"
              src="{{ issue?.user[0]?.profile }}"
              class="user-img-s mr-2 ml-2"
            />
          
              <ngx-avatar
              
                *ngIf="issue?.user[0]?.profile == null ||
                issue?.user[0]?.profile == ''"
                class="mr-3"
                initialsSize="1"
                size="80"
                name="{{ issue?.user[0]?.firstName }}"
              ></ngx-avatar>
            
            <ngx-avatar
              *ngIf="issue?.user[0]?.firstName == null"
              class="mr-3"
              initialsSize="1"
              size="80"
              name="N/A"
            ></ngx-avatar>
          </div>
          <div class="details_user ml-2">
            <h4
              class=""
              [class.no-name]="
                issue.user[0]?.firstName == null ||
                issue?.user[0]?.lastName == null
              "
            >
              {{ issue.user[0]?.firstName ? issue.user[0]?.firstName : "N/A" }}
              {{ issue?.user[0]?.lastName ? issue?.user[0]?.lastName : "" }}
            </h4>
            <p *ngIf="issue?.user[0]?.membershipId != null">
              {{ issue?.user[0]?.membershipId }}
            </p>
          </div>
        </div>
        <div class="close-img cursor">
          <img
            src="../../../assets/images/panel_images/close2.svg"
            class=""
            (click)="close()"
          />
        </div>
      </div>
      <div class="issue_details mt-3">
        <h4 class="mt-2 mb-0">{{ issue?.title ? issue?.title : "-" }}</h4>
        <p class="mt-1 mb-0">
          Ticket ID: <span>{{ issue.ticketId ? issue.ticketId : "N/A" }}</span>
        </p>
        <p class="mt-1 mb-0">
          Email:
          <span *ngIf="issue?.user[0]?.email != null">{{
            issue?.user[0]?.email ? issue?.user[0]?.email : "N/A"
          }}</span>
        </p>
        <!-- <p class="mt-1 mb-0" *ngIf="i?ssue.email == null">Email: <span>N/A</span> </p> -->

        <div class="d-flex mt-3">
          <div class="mr-auto issued-date">
            <p>{{ issue?.createdAt | date : "medium" }}</p>
          </div>
          <div class="status-open">
            <h5>
              Status:
              <span *ngIf="issue.status == 'open'" class="eligable-option-s"
                >&nbsp;&nbsp;{{
                  issue.status ? (issue.status | titlecase) : "-"
                }}</span
              >
              <span
                *ngIf="issue.status == 'closed'"
                class="not-eligable-option-s"
                >&nbsp;&nbsp;{{
                  issue.status ? (issue.status | titlecase) : "-"
                }}</span
              >
              <span
                *ngIf="issue.status == 'invalid'"
                class="not-member-option-s"
                >&nbsp;&nbsp;{{
                  issue.status ? (issue.status | titlecase) : "-"
                }}</span
              >
            </h5>
          </div>
        </div>
        <div class="descrption mt-3">
          <h3 class="mb-1">Description:</h3>
          <p>{{ issue?.description ? issue?.description : "-" }}</p>
        </div>
        <img
          *ngIf="issue.attachment != null"
          src="{{ issue.attachment }}"
          class="img-a"
        />
        <div class="Cancel-btn mt-5">
          <button
            class="btn btn-cancel"
            *ngIf="issue.status != 'closed' && issue.status != 'invalid'"
            (click)="markAsInvalid()"
          >
            Mark As Invalid
          </button>
          <button
          *ngIf="issue.status == 'open'"
            class="btn btn-Deactivate"
            [class.markAsInvalidBtnHide]="
              issue.status == 'closed' || issue.status == 'invalid'
            "
            (click)="reply()"
          >
            Reply
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid" *ngIf="replyIssue">
    <div class="close-img cursor float-right mb-3">
      <img
        src="../../../assets/images/panel_images/close2.svg"
        class=""
        (click)="close()"
      />
    </div>
    <form [formGroup]="replyIssueForm">
      <div class="">
        <mat-form-field>
          <mat-label>Issue Reply</mat-label>
          <input matInput formControlName="text" />
        </mat-form-field>
        <div
          *ngIf="updatecmsEvent.text.errors?.required && submitted"
          class="invalid-feedback d-block"
        >
          Please enter issue
        </div>
      </div>
      <div class="attachement mt-3">
        <input
          multiple
          id="fileInput"
          style="display: none"
          type="file"
          accept="image/png, image/jpeg"
          (change)="onFileSelected($event)"
          #fileInput
        />
        <button
          *ngIf="!fileInput.files.length"
          class="file-btn btn"
          (click)="fileInput.click()"
        >
          <img
            src="../../../assets/images/panel_images/atta.svg"
            class="mr-2"
          />Add Attachment
        </button>
      </div>
      <div class="mt-md-4 ml-md-2">
        <div class="">
          <div style="position: relative" class="pr-3">
            <span *ngIf="fileInput.files.length " class="btn-remove-img" (click)="resetFileInput()">x</span>
            <img *ngIf="fileInput.files.length " [src]="url" class="url-img" />
            <!-- <p>{{url}}</p> -->
          </div>
        </div>
      </div>
    </form>
    <div class="d-flex align-items-center justify-content-center pt-5 pb-3">
      <button class="btn btn-reply" (click)="replyAfterIssues()">Reply</button>
    </div>
  </div>
  <div *ngIf="markAsInvalidStatus">
    <div class="back-btn cursor" (click)="close()">
      <img src="../../../assets/images/panel_images/back-btn-1.svg" />
    </div>

    <div class="request-details-section">
      <div class="success-img text-center pt-3 pb-2">
        <img
          src="../../../assets/images/panel_images/thank.gif"
          style="width: 225px"
        />
      </div>
      <div class="cms-heading-succes-section">
        <h5>Status Updated Successfully!</h5>
      </div>
      <div class="okay-btn pt-5 mb-3 text-center">
        <button type="button" class="btn btn-okay" (click)="close()">
          Okay
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="success">
    <div class="back-btn cursor" (click)="close()">
      <img src="../../../assets/images/panel_images/back-btn-1.svg" />
    </div>

    <div class="request-details-section">
      <div class="success-img text-center pt-3 pb-2">
        <img
          src="../../../assets/images/panel_images/thank.gif"
          style="width: 225px"
        />
      </div>
      <div class="cms-heading-succes-section">
        <h5>Reply Added Successfully!</h5>
      </div>
      <div class="okay-btn pt-5 mb-3 text-center">
        <button type="button" class="btn btn-okay" (click)="close()">
          Okay
        </button>
      </div>
    </div>
  </div>
</div>
