import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DashboardServicesService } from 'src/app/_services/dashboard-services.service';
import { RejectedApprovedDialogComponent } from '../rejected-approved-dialog/rejected-approved-dialog.component';
import { SuccessApprovedDialogComponent } from '../success-approved-dialog/success-approved-dialog.component';

@Component({
  selector: 'app-reviewmedias',
  templateUrl: './reviewmedias.component.html',
  styleUrls: ['./reviewmedias.component.css']
})
export class ReviewmediasComponent implements OnInit {

  mediaData:any = [];
  status:string = 'pending';

  approvedStatus: any = 'approved';
  rejectstatus: any = 'rejected';
  Loader:boolean = false;

  selectedType:string;
  role:any = 'superAdmin';
  today:Date = new Date();
  constructor(private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private services: DashboardServicesService) {

    this.activatedRoute.queryParams.subscribe(res => {
    this.selectedType = res.type;
    if(res.type == 'video'){
      let params = {
         status: this.status
      };
      this.services.cmsHistoryMediaVideo(params).subscribe((response) => {
        this.mediaData = response.data;
      });
    }else if(res.type == 'event'){
      let params = {
        status: this.status
      };
      this.services.cmsHistoryMediaEvent(params).subscribe((response) => {
        this.mediaData = response.data;
      });
    }else{
      let params = {
        status: this.status
      };
      this.services.cmsHistoryMediaDpromise(params).subscribe((response) => {
        this.mediaData = response.data;
      });
    }
    })
   }

  ngOnInit(): void {
    this.role = localStorage.getItem('ROLE');
  }

  approve(id: any, i: any) {
    let statusParams = {
      status: this.approvedStatus,
    };
    this.Loader = true;
    if(this.selectedType == 'video'){
      this.services
      .superAdminMediaVideo(id, statusParams)
      .subscribe((res) => {
        this.mediaData.splice(i, 1);
        setTimeout(() => {
          this.Loader = false;
          this.dialog.open(SuccessApprovedDialogComponent, {
            disableClose: true,
          });
        }, 1000);
      });
    }else if(this.selectedType == 'daily promise'){
      this.services
      .superAdminMediaPromise(id, statusParams)
      .subscribe((res) => {
        this.mediaData.splice(i, 1);
        setTimeout(() => {
          this.Loader = false;
          this.dialog.open(SuccessApprovedDialogComponent, {
            disableClose: true,
          });
        }, 1000);
      });
    }else{
      this.services
      .superAdminMediaEvent(id, statusParams)
      .subscribe((res) => {
        this.mediaData.splice(i, 1);
        setTimeout(() => {
          this.Loader = false;
          this.dialog.open(SuccessApprovedDialogComponent, {
            disableClose: true,
          });
        }, 1000);
      });
    }

    // console.log('id', id, 'status', this.statusParams);
  }

  decline(id: any, i: any) {
    let declineParams = {
      status: this.rejectstatus,
    };
    this.Loader = true;

    if(this.selectedType == 'video'){
      this.services
      .superAdminMediaVideo(id, declineParams)
      .subscribe((res) => {
        this.mediaData.splice(i, 1);
        setTimeout(() => {
          this.Loader = false;
          this.dialog.open(RejectedApprovedDialogComponent, {
            disableClose: true,
          });
        }, 1000);
      });
    }else if(this.selectedType == 'daily promise'){
      this.services
      .superAdminMediaPromise(id, declineParams)
      .subscribe((res) => {
        this.mediaData.splice(i, 1);
        setTimeout(() => {
          this.Loader = false;
          this.dialog.open(RejectedApprovedDialogComponent, {
            disableClose: true,
          });
        }, 1000);
      });
    }else{
      this.services
      .superAdminMediaEvent(id, declineParams)
      .subscribe((res) => {
        this.mediaData.splice(i, 1);
        setTimeout(() => {
          this.Loader = false;
          this.dialog.open(RejectedApprovedDialogComponent, {
            disableClose: true,
          });
        }, 1000);
      });
    }


    //   console.log('id', id, 'status', this.declineParams);
  }

  getTime(time:any){
    return new Date(time);
  }

}
