<!-- [ngClass]="message === 'true'?'active-sidebar':'close-sidebar'" -->
<div class="sidemenu-wrap">
  <span class="d-xl-none close-sidebar"><i class="fa fa-times"></i></span>
  <div class="sidebar-logo">
    <a href="#">
      <img src="{{ storelogo }}" width="100%" class="logo-img" alt="store-img" />
      <!-- <h1>{{storelogo}}</h1> -->
    </a>
  </div>

  <div class="menu-wrap">
    <ul [ngClass]="{ 'nav-list-open': sidebarOpen }">
      <p>Main</p>
      <ng-container *ngFor="let main of sidemenuMainItems">
        <!-- <li *ngIf="main.isPermit"> -->
        <li>
          <a routerLink="{{ main.link }}" routerLinkActive="active-page">
            <img class="images" src="{{ main.icon }}" alt="" />
            {{ main.title }}
          </a>
        </li>
      </ng-container>
    </ul>
    <p>HELP</p>
    <ul>
      <ng-container *ngFor="let help of sidemenuHelpItems; let i = index">
        <li *ngIf="help.isPermit">
        <!-- <li> -->
          <a routerLink="{{ help.link }}" routerLinkActive="active-page">
            <img src="{{ help.icon }}" alt="" />
            {{ help.title }}
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
