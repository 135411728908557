import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as Highcharts from 'highcharts';
import { DashboardServicesService } from 'src/app/_services/dashboard-services.service';
import { months } from '../../app.constant';
@Component({
  selector: 'app-service-analytics',
  templateUrl: './service-analytics.component.html',
  styleUrls: ['./service-analytics.component.css'],
})
export class ServiceAnalyticsComponent implements OnInit {
  today = new Date();
  dropDownSelection: any;
  calendraDate: any;
  Date: any;
  highcharts = Highcharts;
  chartOptions: any;
  chartOptionsAnalytics: any;
  serviceQueryParms: any;
  ServiceYear: any;
  serviceMembers: any;
  serviceMembersCharts: any;
  serviceCreatedArray: any = [];
  serviceCompletedArray: any = [];
  serviceMonths: any = [];
  serviceRequestCharts: any;
  exportService: any;
  toDate: string;
  afterFliterData: any = [];




  monthArray = months;
  yearList:any = [
    '2023',
    '2022',
    '2021',
    '2020',
    '2019',
    '2018',
    '2017',
    '2016',
    '2015',
    '2014',
    '2013',
    '2012',
    '2011',
    '2010',
    '2009',
    '2008',
    '2007',
    '2006',
    '2005',
    '2004',
    '2003',
    '2002',
    '2001',
    '2000'
]
// selectedYear:any = '2023';
  monthList = [
    { key: "JAN", value : 0},
    { key: "FEB", value : 1},
    { key: "MAR", value : 2},
    { key: "APR", value : 3},
    { key: "MAY", value : 4},
    { key: "JUN", value : 5},
    { key: "JUL", value : 6},
    { key: "AUG", value : 7},
    { key: "SEP", value : 8},
    { key: "OCT", value : 9},
    { key: "NOV", value : 10},
    { key: "DEC", value : 11}
]
  selectedYearService:any = '2023';
  selectedMonthService:any;
  startDateService:Date =new Date(2023,0,1);
  endDateService:Date = new Date();
  monthListService = this.monthList;
  totalService: any;
  activeRequest: any;
  completedRequest: any;
  cancelledRequest: any;
  dropDownSelectionService: any;
  toDateService: string;
  calendraDateService: string;
  serviceChartOptionsAnalytics: {
    chart: { type: string; }; title: { text: string; }; credits: { enabled: boolean; }; legend: { enabled: boolean; };
    //  subtitle: {
    //     text: "Source: WorldClimate.com"
    //  },
    xAxis: { gridLineWidth: number; minorGridLineWidth: number; lineWidth: number; lineColor: string; title: { text: string; }; labels: { style: { color: string; fontSize: number; fontWeight: number; fontFamily: string; }; }; categories: number[]; }; yAxis: { gridLineWidth: number; minorGridLineWidth: number; title: { text: string; }; labels: { format: string; style: { color: string; fontSize: number; fontWeight: number; fontFamily: string; }; }; };
    //  tooltip: {
    //     valueSuffix:" °C"
    //  },
    plotOptions: { series: { lineWidth: number; borderRadius: number; pointWidth: number; marker: { enabled: boolean; }; }; }; colors: string[]; series: { data: number[]; }[];
  };
  constructor(private services: DashboardServicesService) {
    
    this.serviceChartOptionsAnalytics = {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: { enabled: false },
      //  subtitle: {
      //     text: "Source: WorldClimate.com"
      //  },
      xAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineWidth: 0,
        lineColor: 'transparent',
        title: {
          text: '',
        },
        labels: {
          style: {
            color: '#8492A6',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
        categories: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        ],
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          text: '',
        },
        labels: {
          format: '{value}',
          style: {
            color: '#425466',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
      },
      //  tooltip: {
      //     valueSuffix:" °C"
      //  },
      plotOptions: {
        series: {
          lineWidth: 0.5,
          borderRadius: 5,
          pointWidth: 7,
          marker: {
            enabled: false,
          },
        },
      },

      colors: ['#0074D6', '#FD6A65', '#FFB65C', '#75809C'],
      series: [
        {
          data: [200, 300, 450, 750, 450, 350, 250, 760, 870, 970, 650, 540],
        },
        {
          data: [600, 500, 550, 650, 350, 250, 150, 650, 870, 650, 450, 870],
        },
        {
          data: [400, 560, 450, 550, 550, 650, 760, 760, 870, 890, 960, 650],
        },
        // {
        //   data: [700, 25, 30, 48, 55, 60, 65, 75, 89, 98, 190, 200],
        // },
        // {
        //   data: [900, 15, 20, 35, 45, 50, 55, 65, 70, 90, 100, 110],
        // },
      ],
    };
  }

  ngOnInit(): void {
    if(this.selectedYearService == new Date().getFullYear()){
      let month = new Date().getMonth() + 1;
      this.monthListService = this.monthListService.slice(0,month);
     }else{
      this.monthListService = [
       { key: "JAN", value : 0},
       { key: "FEB", value : 1},
       { key: "MAR", value : 2},
       { key: "APR", value : 3},
       { key: "MAY", value : 4},
       { key: "JUN", value : 5},
       { key: "JUL", value : 6},
       { key: "AUG", value : 7},
       { key: "SEP", value : 8},
       { key: "OCT", value : 9},
       { key: "NOV", value : 10},
       { key: "DEC", value : 11}
      ]
    }
   this.getServiceData();
    this.chartOptionsAnalytics = {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: { enabled: false },
      //  subtitle: {
      //     text: "Source: WorldClimate.com"
      //  },
      xAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineWidth: 0,
        lineColor: 'transparent',
        title: {
          text: '',
        },
        labels: {
          style: {
            color: '#8492A6',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
        categories: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        ],
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          text: '',
        },
        labels: {
          format: '{value}',
          style: {
            color: '#425466',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
      },
      //  tooltip: {
      //     valueSuffix:" °C"
      //  },
      plotOptions: {
        series: {
          lineWidth: 0.5,
          borderRadius: 5,
          pointWidth: 7,
          marker: {
            enabled: false,
          },
        },
      },

      colors: ['#0074D6', '#FD6A65', '#FFB65C', '#75809C'],
      series: [
        {
          data: [200, 300, 450, 750, 450, 350, 250, 760, 870, 970, 650, 540],
        },
        {
          data: [600, 500, 550, 650, 350, 250, 150, 650, 870, 650, 450, 870],
        },
        {
          data: [400, 560, 450, 550, 550, 650, 760, 760, 870, 890, 960, 650],
        },
        // {
        //   data: [700, 25, 30, 48, 55, 60, 65, 75, 89, 98, 190, 200],
        // },
        // {
        //   data: [900, 15, 20, 35, 45, 50, 55, 65, 70, 90, 100, 110],
        // },
      ],
    };
  }
  serviceExportBtn() {
    this.services.dashboardExportsService(this.serviceQueryParms).subscribe((res) => {
      console.log(res);
      this.exportService = res.sheetUrl;
      this.download(this.exportService, 'sheetUrl');
    });
  }
  onChangeToDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.serviceQueryParms = {
        startDate: this.calendraDate ? this.calendraDate : '',
        endDate: this.toDate ? this.toDate : '',
        year: this.ServiceYear ? this.ServiceYear : '2023',
      };
      this.services
        .dashboardService(this.serviceQueryParms)
        .subscribe((result) => {
          console.log(result);
          this.serviceMembers = result.serviceRequest;
          // this.totalService = result.totalService;
          // this.activeRequest = result.activeRequest;
          // this.completedRequest = result.completedRequest;
          // this.cancelledRequest = result.cancelledRequest;
          this.serviceMembersCharts = this.serviceMembers.forEach(
            (item: any) => {
              this.serviceCreatedArray.push(item.created);
              this.serviceCompletedArray.push(item.completed);
              this.serviceMonths.push(item.month);
            }
          );
          this.serviceRequestCharts = {
            chart: {
              type: 'spline',
            },
            title: {
              text: '',
            },
            credits: {
              enabled: false,
            },
            legend: { enabled: false },
            //  subtitle: {
            //     text: "Source: WorldClimate.com"
            //  },
            xAxis: {
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              lineWidth: 0,
              lineColor: 'transparent',
              title: {
                text: '',
              },
              labels: {
                style: {
                  color: '#5A657F',
                  fontSize: 12,
                  fontWeight: 600,
                  fontFamily: 'Inter',
                },
              },
              categories: this.serviceMonths,
            },
            yAxis: {
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              title: {
                text: '',
              },
              labels: {
                format: '{value}',
                style: {
                  color: '#425466',
                  fontSize: 12,
                  fontWeight: 600,
                  fontFamily: 'Inter',
                },
              },
            },
            //  tooltip: {
            //     valueSuffix:" °C"
            //  },
            plotOptions: {
              series: {
                marker: {
                  enabled: false,
                },
              },
            },

            colors: ['#0DBC67', '#FF4242'],
            series: [
              {
                name: 'Created',
                data: this.serviceCreatedArray,
              },
              {
                name: 'Completed',
                data: this.serviceCompletedArray,
              },
            ],
          };
        });
    }
  }
  onChangeDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.serviceQueryParms = {
        startDate: this.calendraDate ? this.calendraDate : '',
        endDate: this.toDate ? this.toDate : '',
        year: this.ServiceYear ? this.ServiceYear : '2023',
      };
      this.services
        .dashboardService(this.serviceQueryParms)
        .subscribe((result) => {
          console.log(result);
          this.serviceMembers = result.serviceRequest;
          // this.totalService = result.totalService;
          // this.activeRequest = result.activeRequest;
          // this.completedRequest = result.completedRequest;
          // this.cancelledRequest = result.cancelledRequest;
          this.serviceMembersCharts = this.serviceMembers.forEach(
            (item: any) => {
              this.serviceCreatedArray.push(item.created);
              this.serviceCompletedArray.push(item.completed);
              this.afterFliterData.push(item.month);
              let uniqueSubjects = [...new Set(this.afterFliterData)];
              this.serviceMonths = uniqueSubjects;
              console.log(this.serviceMonths);
            }
          );
          this.serviceRequestCharts = {
            chart: {
              type: 'spline',
            },
            title: {
              text: '',
            },
            credits: {
              enabled: false,
            },
            legend: { enabled: false },
            //  subtitle: {
            //     text: "Source: WorldClimate.com"
            //  },
            xAxis: {
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              lineWidth: 0,
              lineColor: 'transparent',
              title: {
                text: '',
              },
              labels: {
                style: {
                  color: '#5A657F',
                  fontSize: 12,
                  fontWeight: 600,
                  fontFamily: 'Inter',
                },
              },
              categories: this.serviceMonths,
            },
            yAxis: {
              gridLineWidth: 0,
              minorGridLineWidth: 0,
              title: {
                text: '',
              },
              labels: {
                format: '{value}',
                style: {
                  color: '#425466',
                  fontSize: 12,
                  fontWeight: 600,
                  fontFamily: 'Inter',
                },
              },
            },
            //  tooltip: {
            //     valueSuffix:" °C"
            //  },
            plotOptions: {
              series: {
                marker: {
                  enabled: false,
                },
              },
            },

            colors: ['#0DBC67', '#FF4242'],
            series: [
              {
                name: 'Created',
                data: this.serviceCreatedArray,
              },
              {
                name: 'Completed',
                data: this.serviceCompletedArray,
              },
            ],
          };
        });
    }
  }
  download(url: any, downloadName: any) {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = downloadName;
    a.click();
    document.body.removeChild(a);
  }

  StatisticsDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
  }
  AnalyticsDateChange(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
  }






  resetServiceFilter(){
    this.selectedYearService = '2023';
    this.selectedMonthService = undefined;
    this.startDateService =new Date(2023,0,1);
    this.endDateService = new Date();
    this.getServiceData();
  }
  onChangeServiceDate(e: any) {
    this.dropDownSelectionService = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelectionService;
    let ds = this.dropDownSelectionService;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.toDateService = date.format('YYYY-MM-DD');
    this.startDateService = new Date(e.target.value);
    this.endDateService = new Date(e.target.value);
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
        this.getServiceData();
    }
  }
  getServiceData(){
    this.serviceMembers = [];
    this.totalService = 0;
    this.serviceMembersCharts =[];
    this.serviceCreatedArray = [];
    this.serviceCompletedArray =[];
    this.serviceMonths = [];

    this.serviceQueryParms = {
      startDate:
        this.startDateService.getFullYear() +
        '-' +
        (this.startDateService.getMonth() + 1) +
        '-' +
        this.startDateService.getDate(),
      endDate:
        this.endDateService.getFullYear() +
        '-' +
        (this.endDateService.getMonth() + 1) +
        '-' +
        this.endDateService.getDate(),
    };
    if(this.selectedMonthService != undefined){
       this.serviceQueryParms['groupBy'] = 'day';
    }
    if (
      this.startDateService.getFullYear() == this.endDateService.getFullYear() &&
      this.startDateService.getMonth() == this.endDateService.getMonth() &&
      this.startDateService.getDate() != this.endDateService.getDate()

    ) {
      this.serviceQueryParms['groupBy'] = 'day';
    }
    if (
      this.startDateService.getFullYear() == this.endDateService.getFullYear() &&
      this.startDateService.getMonth() == this.endDateService.getMonth() &&
      this.startDateService.getDate() == this.endDateService.getDate()

    ) {
      this.serviceQueryParms['groupBy'] = 'hour';
    }

    this.services
      .dashboardService(this.serviceQueryParms)
      .subscribe((result) => {
        console.log(result);
        this.serviceMembers = result.data;
        this.totalService = result.totalService;
        this.activeRequest = result.activeRequest;
        this.completedRequest = result.completedRequest;
        this.cancelledRequest = result.cancelledRequest;
        this.serviceMembersCharts = this.serviceMembers.forEach(
          (item: any) => {
            this.serviceCreatedArray.push(item.created);
            this.serviceCompletedArray.push(item.completed);
            const day = item.date?.split('-')
            const dateText = item.day ? item.day + '-' : '';
            // const dateText = item.day ? item.day + '-' : '';
            if (item.date != undefined && item.hour == undefined) {
              this.serviceMonths.push(day[2] + '-' + item.month)
            }else if(item.date != undefined && item.hour != undefined){
              this.serviceMonths.push(item.hour + 1 + ':00')
            }else{
              this.serviceMonths.push(dateText + item.month + '-' + item.year);
            }
            // this.serviceMonths.push(dateText + item.month + '-' + item.year);
          }
        );
        this.serviceRequestCharts = {
          chart: {
            type: 'spline',
          },
          title: {
            text: '',
          },
          credits: {
            enabled: false,
          },
          legend: { enabled: false },
          //  subtitle: {
          //     text: "Source: WorldClimate.com"
          //  },
          xAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0,
            lineColor: 'transparent',
            title: {
              text: '',
            },
            labels: {
              style: {
                color: '#5A657F',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
            categories: this.serviceMonths,
          },
          yAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            title: {
              text: '',
            },
            labels: {
              format: '{value}',
              style: {
                color: '#425466',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
          },
          //  tooltip: {
          //     valueSuffix:" °C"
          //  },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
              },
            },
          },

          colors: ['#0DBC67', '#FF4242'],
          series: [
            {
              name: 'Created',
              data: this.serviceCreatedArray,
            },
            {
              name: 'Completed',
              data: this.serviceCompletedArray,
            },
          ],
        };
      });
  }
  
  onChangeServiceToDate(e: any) {
    this.dropDownSelectionService = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelectionService;
    let ds = this.dropDownSelectionService;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDateService = date.format('YYYY-MM-DD');
    this.endDateService = new Date(e.target.value);
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.getServiceData();
    }
  }
  selectYearService(event:any){
    if(this.selectedYearService == new Date().getFullYear()){
      let month = new Date().getMonth() + 1;
      this.monthListService = this.monthListService.slice(0,month);
     }else{
      this.monthListService = [
       { key: "JAN", value : 0},
       { key: "FEB", value : 1},
       { key: "MAR", value : 2},
       { key: "APR", value : 3},
       { key: "MAY", value : 4},
       { key: "JUN", value : 5},
       { key: "JUL", value : 6},
       { key: "AUG", value : 7},
       { key: "SEP", value : 8},
       { key: "OCT", value : 9},
       { key: "NOV", value : 10},
       { key: "DEC", value : 11}
      ]
    }
    this.serviceQueryParms = {
      startDate:
        this.startDateService.getFullYear() +
        '-' +
        (this.startDateService.getMonth() + 1) +
        '-' +
        this.startDateService.getDate(),
      endDate:
        this.endDateService.getFullYear() +
        '-' +
        (this.endDateService.getMonth() + 1) +
        '-' +
        this.endDateService.getDate(),
    };
    if (this.selectedMonthService == undefined && this.selectedYearService != new Date().getFullYear()) {
      this.startDateService = new Date(Number(this.selectedYearService), 0, 1);
      this.endDateService = new Date(Number(this.selectedYearService), 11, 31);
    }else if(this.selectMonthService == undefined && this.selectedYearService == new Date().getFullYear()){
      this.startDateService = new Date(Number(this.selectedYearService), 0, 1);
      this.endDateService = new Date();
    }else{
      this.startDateService = new Date(Number(this.selectedYearService), this.selectedMonthService, 1);
      this.endDateService = new Date(Number(this.selectedYearService), this.selectedMonthService, this.monthArray[this.selectedMonthService]);
    }
      //  this.startDateService = new Date(Number(this.selectedYearService),0,1);
      //  this.endDateService = new Date(Number(this.selectedYearService),11,31);

       this.getServiceData();
  }

  selectMonthService(month:any){
    this.startDateService = new Date(Number(this.selectedYearService),this.selectedMonthService,1);
    this.endDateService = new Date(Number(this.selectedYearService),this.selectedMonthService,
    this.monthArray[this.selectedMonthService]);

    this.getServiceData();
  }
}
