import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-mark-as-invalidcommon-dialog',
  templateUrl: './mark-as-invalidcommon-dialog.component.html',
  styleUrls: ['./mark-as-invalidcommon-dialog.component.css'],
})
export class MarkAsInvalidcommonDialogComponent implements OnInit {
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}
  close() {
    this.dialog.closeAll();
  }
}
