import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class LoginServiceService {
  IMAGE_URL: any;
  URL: any;
  constructor(private http: HttpClient, public configService: ConfigService) {
    //call the function which sets the env variables in local/session storage in constructor
    // this.addToLocalStorage();
  }
  // addToLocalStorage() {
  //   this.configService.getConfig().subscribe((data: any) => {
  //     console.log(data);
  //     localStorage.setItem('API_URL', data.API_URL);
  //     environment.API_URL = data.API_URL;

  //     localStorage.setItem('IMAGE_URL', data.IMAGE_URL);
  //     this.IMAGE_URL = data.IMAGE_URL;
  //   });
  // }
  login(data: any) {
    return this.http.post<any>(`${environment.API_URL}auth/login`, data);
  }
  forgotPassword(data: any) {
    return this.http.post<any>(
      `${environment.API_URL}auth/forgot-password`,
      data
    );
  }
  otpVerfication(data: any) {
    return this.http.post<any>(
      `${environment.API_URL}auth/otp-verification`,
      data
    );
  }
  resetPassword(data: any) {
    return this.http.put<any>(
      `${environment.API_URL}auth/reset-password`,
      data
    );
  }
  resetOtp(data: any) {
    return this.http.post<any>(`${environment.API_URL}auth/resendOtp`, data);
  }
}
