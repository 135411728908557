<app-showloader></app-showloader>

<div class="container-fluid bg-white main">
  <div class="pt-3 ml-1">
    <div class="d-flex pt-3 pb-2">
      <div class="back-btn mr-2 cursor" routerLink="/Prayer-requests">
        <img
          src="../../../assets/images/panel_images/back.svg"
          class="back-img"
        />
      </div>
      <div class="user-header-back">
        <h5>Prayer Request</h5>
        <p><span>Home / </span>Prayer Request</p>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid mt-4 main">
  <div class="user-header-details-img-section pt-5 bg-white ml-1">
    <div class="users-img mb-4">
      <div class="user-details-img d-flex mb-4" *ngFor="let user of details">
        <div class="d-flex mr-auto mt-0">
          <div
            class="mr-3 ml-4"
            [class.name-p]="
              user?.user[0]?.profile == null || user?.user[0]?.profile == ''
            "
          >
            <!-- <img src="../../../assets/images/panel_images/user-img.svg" class="rounded">  -->
            <img
              *ngIf="user?.user[0]?.profile != null"
              [src]="user?.user[0]?.profile"
              class="user-img-1"
            />
            <ngx-avatar
              *ngIf="
                user?.user[0]?.profile == null || user?.user[0]?.profile == ''
              "
              class="mr-2 name-a"
              initialsSize="1"
              size="80"
              name="{{ user?.name }}"
            ></ngx-avatar>
          </div>
          <div class="details_user mt-4">
            <h4
              [class.no-id]="
                user?.user[0]?.membershipId == null ||
                user?.user[0]?.membershipId == ''
              "
            >
              {{ user?.name }}
            </h4>
            <p
              *ngIf="
                user?.user[0]?.membershipId != null &&
                user?.user[0]?.membershipId != ''
              "
            >
              {{ user?.user[0]?.membershipId }}
            </p>
          </div>
        </div>
        <div
          *ngIf="
            permissons?.prayer_Requests?.edit &&
            user.status == 'in-progress' &&
            open
          "
          class="completed mt-2 mr-4"
        >
          <button
            type="button"
            class="completed-mark cursor"
            (click)="openBtn()"
          >
            <img
              src="../../../assets/images/panel_images/tick-3.svg"
              class="mr-2"
            />Mark as Complete
          </button>
        </div>
        <div *ngIf="user.status == 'completed' || closed" class="mt-2 mr-4">
          <button type="button" class="mark-as-closed cursor">Completed</button>
        </div>
        <div *ngIf="user.status == 'cancelled'" class="mt-2 mr-4">
          <button type="button" class="decline border-0 cursor">
            Cancelled
          </button>
        </div>
      </div>
    </div>
    <hr class="hr-line-user" />
    <div class="details-section mt-3 ml-5" *ngFor="let user of details">
      <div class="row">
        <div class="col-md-6">
          <div class="details-section-inner">
            <div class="d-flex">
              <div class="details-heading details-services">
                <h4>Mobile No:</h4>
              </div>
              <div class="details-heading-user">
                <h4>{{ user.phone }}</h4>
              </div>
            </div>
            <div class="d-flex">
              <div class="details-heading details-services">
                <h4>Email:</h4>
              </div>
              <div class="details-heading-user">
                <h4>
                  {{ user?.user[0]?.email ? user?.user[0]?.email : "N/A" }}
                </h4>
              </div>
            </div>
            <div class="d-flex">
              <div class="details-heading details-services">
                <h4>Prayer for whom:</h4>
              </div>
              <div class="details-heading-user">
                <h4>
                  {{
                    user?.prayer_fro_whom[0]?.name
                      ? user?.prayer_fro_whom[0]?.name
                      : "N/A"
                  }}
                </h4>
              </div>
            </div>
            <div class="d-flex">
              <div class="details-heading details-services">
                <h4>Place:</h4>
              </div>
              <div class="details-heading-user">
                <h4>{{ user.place ? user.place : "N/A" }}</h4>
              </div>
            </div>
            <div class="d-flex">
              <div class="details-heading details-services">
                <h4>Language:</h4>
              </div>
              <div class="details-heading-user">
                <h4>{{ user.language ? user.language : "N/A" }}</h4>
              </div>
            </div>
            
            <div class="d-flex">
              <div class="details-heading details-services">
                <h4>Prayer Cause:</h4>
              </div>
              <div class="details-heading-user">
                <h4>
                  {{ user?.prayer[0]?.title ? user?.prayer[0]?.title : "N/A" }}
                </h4>
              </div>
            </div>

            <!-- <div class="reuqest-audio prayer-request-section pt-3 ">
					<p class="mb-0 pb-0">Prayer Request (Audio)</p>

					  <div class="d-flex mr-auto mt-3">
<div class="cp-audioquote" style="width:100%">
  <div class="cp-audioquote__player">

    <audio src="https://www.denisbouquet.com/pens/audioquotes/fightclub.mp3">
    <audio [src]="user.file">

    </audio>
      <div class="cp-audioquote__player--playBtn"></div>
    <div class="cp-audioquote__player--display">
      <div class="cp-audioquote__player--progress">
        <span class="cp-audioquote__player--track"></span>
        <span class="cp-audioquote__player--playhead"></span>
      </div>
      <p class="cp-audioquote__player--timestamp playhead">0:00</p><p class="cp-audioquote__player--timestamp duration">0:00</p>
    </div>
  </div>
</div>
	<div class="listen-audio ml-auto mr-md-3">
							<button class="btn secondPLayBtn"><img  src="../../../assets/images/panel_images/audio-1.svg" class="mr-2">Listen Audio	</button>
						</div>


				</div>
			</div> -->
            <!-- <div class="prayer-request-section  pt-3 pb-2" *ngFor="let user of details">
			<div class="mr-auto">
				<p>Prayer Request</p>
				<div class="request">
					<audio controls *ngIf="user && user?.file">
						<source src="{{user?.file}}">
					  </audio>
					<p>{{user?.description ? user?.description : 'none'}}</p>
				</div>
			</div> -->
            <!-- </div> -->

            <div class="d-flex">
              <div class="details-heading details-services">
                <h4>Prayer Request:(Audio)</h4>
              </div>
              <div
                class="details-heading-user audio"
                [class.no-audio]="user?.file == null || user?.file == ''"
              >
                <audio controls *ngIf="user?.file != null && user?.file != ''">
                  <source src="{{ user?.file }}" />
                </audio>
                <h4 *ngIf="user?.file == null || user?.file == ''">N/A</h4>
              </div>
            </div>
            <div class="d-flex">
              <div class="details-heading details-services">
                <h4>Prayer Request:</h4>
              </div>
              <div class="details-heading-user">
                <h4>{{ user?.description ? user?.description : "N/A" }}</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="details-section-inner">
            <div class="d-flex">
              <div class="details-heading details-services">
                <h4>Attachment:</h4>
              </div>
              <div class="details-heading-user">
                <img
                  *ngIf="user?.attachment != null && user?.attachment != ''"
                  src="{{ user?.attachment }}"
                  class="img-class"
                  alt="Image"
                />
                <h4 *ngIf="user?.attachment == null || user?.attachment == ''">
                  N/A
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

    
    </div>
  </div>
  <div
    class="user-header-details-img-section pt-2 bg-white pl-5 pr-4 mb-4 mt-3"
  >
    <div class="past-history-heading pt-3">
      <h5>Past History</h5>
    </div>
    <div
      class="service-req-details mt-4 pb-4"
      *ngIf="this.serviceHistory == ''"
    >
      <h4>No previous records</h4>
    </div>
    <div class="previous-request-table pb-4" *ngIf="this.serviceHistory != ''">
      <table class="table">
        <!-- <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">First</th>
      <th scope="col">Last</th>
      <th scope="col">Handle</th>
    </tr>
  </thead> -->
        <tbody class="table-details-request pb-3">
          <tr *ngFor="let value of serviceHistory" class="pb-4">
            <th scope="row">
              {{ value.prayerRequestId ? value.prayerRequestId : "-" }}
            </th>
            <td>
              <div class="d-flex">
                <img
                  *ngIf="value?.userId?.profile != null"
                  src="{{ value?.userId?.profile }}"
                  class="user-request-img mr-2"
                />
                <ngx-avatar
                  *ngIf="value?.userId?.profile == null"
                  class="mr-2 name-a"
                  initialsSize="1"
                  size="40"
                  name="{{ value.name }}"
                ></ngx-avatar>
                <ngx-avatar
                  *ngIf="value.name == null || value.name == ''"
                  class="mr-3 mt-md-2"
                  initialsSize="1"
                  size="40"
                  name="N/A"
                ></ngx-avatar>

                <div class="mt-md-2">
                  <h6>
                    <!-- {{ value.name ? (value.name | slice : 0 : 6) : "N/A" }} -->
                    {{ value.name ? value.name  : "N/A" }}
                  </h6>
                  <!-- <p>56CALVARY1234</p> -->
                </div>
              </div>
            </td>
            <td>
              <h6 class="phone">
                <span *ngIf="value?.phone != null">+91-</span
                >{{ value.phone ? value.phone : "N/A" }}
              </h6>
            </td>
            <td>
              <h6>
                {{ value.prayerId.title ? value?.prayerId?.title : "N/A" }}
              </h6>
            </td>

            <td>
              <h6>
                {{
                  value?.prayerForWhomId?.name
                    ? value?.prayerForWhomId?.name
                    : "N/A"
                }}
              </h6>
            </td>
            <td>
              <div class="d-flex">
                <h4 *ngIf="value.language == 'Telugu'" class="l-icons">అ</h4>
                <h4 *ngIf="value.language == 'Hindi'" class="l-icons">आ</h4>
                <h4 *ngIf="value.language == 'Gujarati'" class="l-icons">એ</h4>
                <h4 *ngIf="value.language == 'Tamil'" class="l-icons">ஏ</h4>
                <h4 *ngIf="value.language == 'Punjabi'" class="l-icons">ਏ</h4>
                <h4 *ngIf="value.language == 'Kannada'" class="l-icons">ಎ</h4>
                <h4 *ngIf="value.language == 'Sinhalese'" class="l-icons">ඒ</h4>
                <h4 *ngIf="value.language == 'Marathi'" class="l-icons">ए</h4>
                <h4 *ngIf="value.language == 'Bengali'" class="l-icons">ক</h4>
                <h4 *ngIf="value.language == 'Oriya'" class="l-icons">ଏ</h4>
                <h4 *ngIf="value.language == 'Urdu'" class="l-icons">اے</h4>
                <h4 *ngIf="value.language == 'Assamese'" class="l-icons">ক</h4>
                <h4 *ngIf="value.language == 'Malayalam'" class="l-icons">എ</h4>
                <h4 *ngIf="value.language == 'Bhojpuri'" class="l-icons">
                  एगो
                </h4>
                <h4 *ngIf="value.language == 'Nepali'" class="l-icons">ए</h4>
                <h4 *ngIf="value.language == 'Konkani'" class="l-icons">K</h4>
                <h4 *ngIf="value.language == 'English'" class="l-icons">A</h4>

                <div class="">
                  <h6 class="mt-2">
                    {{ value.language ? value.language : "N/A" }}
                  </h6>
                </div>
              </div>
            </td>
            <td class="status">
              <h5 class="not-eligable mt-2" *ngIf="value.status == 'in-progress'">
                New
              </h5>
              <h5 class="closed mt-2" *ngIf="value.status == 'completed'">
                {{ value.status | titlecase }}
              </h5>
              <h5 class="closed mt-2" *ngIf="value.status == 'accepted'">
                {{ value.status | titlecase }}
              </h5>
              <h5 class="decline mt-2" *ngIf="value.status == 'cancelled'">
                {{ value.status | titlecase }}
              </h5>
            </td>
            <td class="status ">
              <div class="d-flex">
                <img
                  *ngIf="value.origin == 'General'"
                  src="../../assets/images/panel_images/web-1.svg"
                  class="user-image-table-origin mr-2"
                />
                <img
                  *ngIf="value.origin == 'Website'"
                  src="../../assets/images/panel_images/web-1.svg"
                  class="user-image-table-origin mr-2"
                />
                <img
                  *ngIf="
                  value.origin == 'Android' || value.origin == 'Mobile App'
                  "
                  src="../../assets/images/panel_images/moblie-1.svg"
                  class="user-image-table-origin mr-2"
                />
                <img
                  *ngIf="value.origin == 'IOS'"
                  src="../../assets/images/panel_images/moblie-1.svg"
                  class="user-image-table-origin mr-2"
                />
                <div class="">
                  <h6>{{ value.origin }}</h6>
                </div>
              </div>
            </td>
          </tr>

          <!-- <tr>
      <th scope="row">2</th>
      <td>Jacob</td>
      <td>Thornton</td>
      <td>@fat</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>Larry</td>
      <td>the Bird</td>
      <td>@twitter</td>
    </tr> -->
        </tbody>
      </table>
    </div>
  </div>
</div>
<!-- <div class="container-fluid mt-4">
	<div class="user-header-details-img-section pt-3 mb-4 bg-white ml-3">
		<div class="d-flex ml-4">
			<div class="mr-auto all-logs-header">
				<h4>All Logs</h4> </div>
			<div class="add-logo-btn mr-3">
				<button type="button" class="btn"><img src="../../../assets/images/panel_images/plus.svg" class="mr-1">Add Log</button>
			</div>
		</div>
		<hr class="hr-line-user">
		<div class="prayer-request-section d-flex  ml-4 pt-3 pb-2">
			<div class="mr-auto d-flex"> <img src="../../../assets/images/panel_images/open-p-r.svg" class="mr-3">
				<div class="reason-details request mt-2">
					<h6>08:00 pm</h6>
					<p class="w-100">Wade marked this prayer as completed.</p>
				</div>
			</div>
			<div class="request-open mr-4">
				<p> Open </p>
			</div>
		</div>
		<div class="prayer-request-section d-flex  ml-4 pt-3 pb-2">
			<div class="mr-auto d-flex"> <img src="../../../assets/images/panel_images/closed-p-r.svg" class="mr-3">
				<div class="reason-details request mt-2">
					<h6>08:00 pm</h6>
					<p class="w-100">Wade marked this prayer as completed.</p>
				</div>
			</div>
			<div class="request-open mr-4">
				<p class="not-eligable "> Not-Answered </p>
			</div>
		</div>
		<div class="prayer-request-section d-flex  ml-4 pt-3 pb-2">
			<div class="mr-auto d-flex"> <img src="../../../assets/images/panel_images/open-p-r.svg" class="mr-3">
				<div class="reason-details request mt-2">
					<h6>08:00 pm</h6>
					<p class="w-100">Wade marked this prayer as completed.</p>
				</div>
			</div>
			<div class="request-open mr-4">
				<p> Open </p>
			</div>
		</div>
		<div class="prayer-request-section d-flex  ml-4 pt-3 pb-2">
			<div class="mr-auto d-flex"> <img src="../../../assets/images/panel_images/open-p-r.svg" class="mr-3">
				<div class="reason-details request mt-2">
					<h6>08:00 pm</h6>
					<p class="w-100">Wade marked this prayer as completed.</p>
				</div>
			</div>
			<div class="request-open mr-4">
				<p> Open </p>
			</div>
		</div>
	</div>

</div> -->
