import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'highcharts';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { adminDetails } from '../_modal/adminDetails';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardServicesService implements OnInit {
  public user: any;
  public token?: any;
  prayerRequestUrl = environment.API_URL;
  URL: any;
  IMAGE_URL: any;
  constructor(
    private http: HttpClient,
    private router: Router,
    public configService: ConfigService
  ) {
    this.user = localStorage.getItem('token') || '{}';
    this.token = 'Bearer ' + this.user;
  }

  ngOnInit(): void { }
  getToken() {
    return localStorage.getItem('token') || '';
  }
  logOut() {
    if (localStorage.getItem('token') != null) {
      localStorage.clear();
      this.router.navigate(['/admin/login']);
    }
  }
  addToLocalStorage() { }
  prayerRequest(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}prayer`;

    return this.http.get<any>(url, httpOptions);
  }
  exportDetailPrayerRequest(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}dashboard/exportPrayerReq`;

    return this.http.get<any>(url, httpOptions);
  }
  totalCount() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}dashboard/spiritual-count`;
    return this.http.get<any>(url, httpOptions);
  }
  spUserDetail(params:any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params:params
    };
    const url = `${this.prayerRequestUrl}dashboard/spiritual-user-detail`;
    return this.http.get<any>(url, httpOptions);
  }
  spStatisticsDetail(params:any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}dashboard/spiritual-user-statistic`;
    return this.http.get<any>(url, httpOptions);
  }
  prayerRequestView(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}prayer/` + id;

    return this.http.get<any>(url, httpOptions);
  }
  prayerRequestStatus(id: any, params: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}prayer/` + id;
    return this.http.put<any>(url, { params: params }, httpOptions);
  }

  membersDetailsPrayerRequest(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}user/prayer/` + id;

    return this.http.get<any>(url, httpOptions);
  }
  // membersDetailsServiceRequest(id: any) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       Authorization: this.token,
  //     }),
  //   };
  //   const url = `${this.prayerRequestUrl}user/service/` + id;

  //   return this.http.get<any>(url, httpOptions);
  // }
  membersRequest(params: any) {
    let queryParams = new HttpParams();
    for (let k = 0; k < params.status.length; k++) {
      queryParams = queryParams.append('status', params.status[k]);
    }

    queryParams = queryParams.append('search', params.search);
    queryParams = queryParams.append('pageLimit', params.pageLimit);
    queryParams = queryParams.append('pageNumber', params.pageNumber);

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      // params: params,
    };
    const url = `${this.prayerRequestUrl}user`;

    return this.http.get<any>(url + `?${queryParams.toString()}`, httpOptions);
  }
  membersDetailsRequest(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}user/` + id;

    return this.http.get<any>(url, httpOptions);
  }
  membersDetailsPrayerRequestP(id: any, param: any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('pageLimit', param.pageLimit);
    queryParams = queryParams.append('pageNumber', param.pageNumber);
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}user/prayer/` + id;

    return this.http.get<any>(url + `?${queryParams.toString()}`, httpOptions);
  }
  membersDetailsServiceRequest(id: any, param: any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('pageLimit', param.pageLimit);
    queryParams = queryParams.append('pageNumber', param.pageNumber);
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}user/service/` + id;
    return this.http.get<any>(url + `?${queryParams.toString()}`, httpOptions);
  }
  membersAttendenceRequest(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}user/service/` + id;

    return this.http.get<any>(url, httpOptions);
  }
  membersDetailsDonations(id: any , param:any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('pageLimit', param.pageLimit);
    queryParams = queryParams.append('pageNumber', param.pageNumber);
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}user/donation/` + id;

    return this.http.get<any>(url +  `?${queryParams.toString()}`, httpOptions);
  }
  membersDetailsCommitment(id: any , param:any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('pageLimit', param.pageLimit);
    queryParams = queryParams.append('pageNumber', param.pageNumber);
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}user/commitment/` + id;

    return this.http.get<any>(url + `?${queryParams.toString()}`, httpOptions);
  }

  membersStatusRequest(id: any, data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}user/` + id;

    return this.http.put<any>(url, data, httpOptions);
  }
  memberRequestView(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}prayer/` + id;

    return this.http.get<any>(url, httpOptions);
  }
  tvProgramRequest(params: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}tv-program`;

    return this.http.get<any>(url, httpOptions);
  }

  tvProgramLanguage(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}tv-program/list`;

    return this.http.get<any>(url, httpOptions);
  }

  tvProgramRequestDetails(id: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}tv-program/details/` + id;

    return this.http.get<any>(url, httpOptions);
  }

  exportsDonationData(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}donate/exportData`;
    return this.http.get<any>(url, httpOptions);
  }
  exportsDetailTvprogramData(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}tv-program/exportDetailTvProgram`;
    return this.http.get<any>(url, httpOptions);
  }
  donateRequestPayment(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}donate/` + id;

    return this.http.get<any>(url, httpOptions);
  }
  donateRequest(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}donate`;

    return this.http.get<any>(url, httpOptions);
  }

  donateRequestServices() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}donate/list`;

    return this.http.get<any>(url, httpOptions);
  }

  supportRequest(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}support`;

    return this.http.get<any>(url, httpOptions);
  }
  itSupportRequestDetails(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}itSupport/userIssue/` + id;

    return this.http.get<any>(url, httpOptions);
  }
  supportRequestDetails(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}support/` + id;

    return this.http.get<any>(url, httpOptions);
  }
  supportRequestReply(id: any, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}support/reply/` + id;

    return this.http.put<any>(url, data, httpOptions);
  }
  itSupportRequestReply(id: any, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}itSupport/reply/` + id;

    return this.http.put<any>(url, data, httpOptions);
  }
  supportChangeStatus(id: any, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}support/` + id;

    return this.http.put<any>(url, data, httpOptions);
  }
  itSupportChangeStatus(id: any, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}itSupport/` + id;

    return this.http.put<any>(url, data, httpOptions);
  }

  serviceRequest(params: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    // super()
    const url = `${this.prayerRequestUrl}service`;
    return this.http.get<any>(url, httpOptions);
  }
  exportDetailServiceRequest(params: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    // super()
    const url = `${this.prayerRequestUrl}service/exportDetailServiceReq`;
    return this.http.get<any>(url, httpOptions);
  }

  serviceRequestFilter(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    // super()
    const url = `${this.prayerRequestUrl}service/list`;
    return this.http.get<any>(url, httpOptions);
  }
  // serviceRequestStatus(id: any): Observable<any> {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       Authorization: this.token,
  //     }),
  //   };
  //   // super()
  //   const url = `${this.prayerRequestUrl}service/` + id;
  //   return this.http.put<any>(url, httpOptions);
  // }
  serviceRequestStatus(id: any, params: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}service/` + id;
    return this.http.put<any>(url, { params: params }, httpOptions);
  }

  serviceRequestView(id: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    // super()
    const url = `${this.prayerRequestUrl}service/history/` + id;
    return this.http.get<any>(url, httpOptions);
  }

  adminDetails() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}admin/view`;

    return this.http.get<any>(url, httpOptions);
  }
  cmsVideoCategory() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}message/category`;

    return this.http.get<any>(url, httpOptions);
  }
  cmsModuleVideo(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}message`;

    return this.http.post<any>(url, data, httpOptions);
  }
  cmsModulePromiseCard(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}today-promise`;

    return this.http.post<any>(url, data, httpOptions);
  }
  cmsModuleEvents(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}live-video`;

    return this.http.post<any>(url, data, httpOptions);
  }
  cmsModuleTvPrograms(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}tv-program`;

    return this.http.post<any>(url, data, httpOptions);
  }
  cmsModuleService(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}service`;

    return this.http.post<any>(url, data, httpOptions);
  }
  cmsModulePrayerRequest(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}prayer`;

    return this.http.post<any>(url, data, httpOptions);
  }
  cmsModuleTemple(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}templeTest`;

    return this.http.post<any>(url, data, httpOptions);
  }
  cmsModuleSpiritualFitness(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}spiritual-fitness`;

    return this.http.post<any>(url, data, httpOptions);
  }
  SpiritualFitness() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}spiritual-fitness/list`;

    return this.http.get<any>(url, httpOptions);
  }
  cmsModuleDonations(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}donate`;

    return this.http.post<any>(url, data, httpOptions);
  }
  cmsModulePushNotifications(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}cms-notification`;

    return this.http.post<any>(url, data, httpOptions);
  }
  cmsModulePushNotificationsReview() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}cms-notification?status=pending`;

    return this.http.get<any>(url, httpOptions);
  }
  superAdminAPProval(id: any, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}cms-notification/` + id;

    return this.http.put<any>(url, data, httpOptions);
  }

  superAdminMediaVideo(id: any, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}message/status/` + id;

    return this.http.put<any>(url, data, httpOptions);
  }

  superAdminMediaPromise(id: any, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}today-promise/` + id;

    return this.http.put<any>(url, data, httpOptions);
  }

  superAdminMediaEvent(id: any, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}live-video/` + id;

    return this.http.put<any>(url, data, httpOptions);
  }

  superAdminReview(id: any, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}approval/` + id;

    return this.http.put<any>(url, data, httpOptions);
  }
  adminChangePassword(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}admin/change-password`;

    return this.http.put<any>(url, data, httpOptions);
  }
  adminUpdateProfile(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}admin/update`;

    return this.http.put<any>(url, data, httpOptions);
  }
  prayerRequestList() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}prayer/list`;

    return this.http.get<any>(url, httpOptions);
  }
  cmsNotificaitonsLastApproved() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}cms-notification/lastApproved`;

    return this.http.get<any>(url, httpOptions);
  }

  cmsNotificaitonsLastReview(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}cms-notification?status=${data}`;

    return this.http.get<any>(url, httpOptions);
  }
  cmsMediaLastApproved() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}message/lastApproved`;

    return this.http.get<any>(url, httpOptions);
  }
  cmsTvProgramsLastApproved() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}tv-program/lastApproved`;

    return this.http.get<any>(url, httpOptions);
  }
  cmsServiceLastApproved() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}service/lastApproved`;

    return this.http.get<any>(url, httpOptions);
  }
  cmsDonateLastApproved() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}donate/lastApproved`;

    return this.http.get<any>(url, httpOptions);
  }
  cmsTempleLastApproved() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}templeTest/lastApproved`;

    return this.http.get<any>(url, httpOptions);
  }
  cmsPrayerReqLastApproved() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}prayer/lastApproved`;

    return this.http.get<any>(url, httpOptions);
  }
  cmsSpiritualFitnessLastApproved() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}spiritual-fitness/lastApproved`;

    return this.http.get<any>(url, httpOptions);
  }
  cmsSpiritualFitnessLastProfessionList() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}spiritual-fitness/list`;

    return this.http.get<any>(url, httpOptions);
  }

  cmsSpiritualFitnessLastId(id: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}spiritual-fitness/${id}`;

    return this.http.get<any>(url, httpOptions);
  }

  cmsStatusLastApproved() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}approval/stats`;

    return this.http.get<any>(url, httpOptions);
  }
  cmsHistoryPushNotifications(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}cms-notification`;

    return this.http.get<any>(url, httpOptions);
  }
  cmsHistoryMedia(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}live-video`;

    return this.http.get<any>(url, httpOptions);
  }

  cmsHistoryMediaVideo(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}message`;

    return this.http.get<any>(url, httpOptions);
  }

  cmsHistoryMediaDpromise(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}today-promise/list`;

    return this.http.get<any>(url, httpOptions);
  }

  cmsHistoryMediaEvent(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}live-video`;

    return this.http.get<any>(url, httpOptions);
  }

  cmsHistoryTvProgramSection(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}approval`;

    return this.http.get<any>(url, httpOptions);
  }
  itSupportTitle() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}itSupport/title`;

    return this.http.get<any>(url, httpOptions);
  }
  itSupport(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}itSupport`;

    return this.http.post<any>(url, data, httpOptions);
  }
  itSupportData(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}itSupport`;

    return this.http.get<any>(url, httpOptions);
  }
  notifications(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}notification`;

    return this.http.get<any>(url, httpOptions);
  }
  notificationsCount() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}notification/unread`;

    return this.http.get<any>(url, httpOptions);
  }
  notificationsMarkAsRead() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}notification/mark-all-read`;

    return this.http.put<any>(url, httpOptions);
  }
  singleNotificationsMarkAsRead(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}notification/` + id;

    return this.http.put<any>(url, httpOptions);
  }
  spiritualFitnessAll(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}spiritual-fitness`;

    return this.http.get<any>(url, httpOptions);
  }
  userMangmentAdmin(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}admin`;

    return this.http.get<any>(url, httpOptions);
  }
  userDetailMangmentAdmin(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}admin/exportDetail`;

    return this.http.get<any>(url, httpOptions);
  }
  userMangmentAdminDetails(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}admin/` + id;

    return this.http.get<any>(url, httpOptions);
  }
  userMangmentAdminAddMember(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}admin`;

    return this.http.post<any>(url, data, httpOptions);
  }
  userMangmentAdminUpdateAddMember(data: any, id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}admin/userUpdate/` + id;

    return this.http.put<any>(url, data, httpOptions);
  }
  userMangmentAdminUpdateStatus(id: any, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}admin/` + id;

    return this.http.put<any>(url, data, httpOptions);
  }
  // http://202.65.140.164/appService/attendance/?ctm=CTM260233
  // http://202.65.140.164/appService/attendance/summery/?ctm=CTM260233&year=All
  //dashboard APIs
  AttendanceMembers(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET',
        Authorization: this.token,
      }),
      params: params,
    };
    // const url = '/api/appService/attendance/';
    const url = 'http://202.65.140.164/appService/attendance/';

    return this.http.get<any>(url, httpOptions);
  }
  AllAttendanceMembers(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET',
        Authorization: this.token,
      }),
      params: params,
    };
    const url = '/api/appService/attendance/summery/';

    return this.http.get<any>(url, httpOptions);
  }
  getYear(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET',
        Authorization: this.token,
      }),
      params: params,
    };
    const url = '/api/appService/attendance/years';

    return this.http.get<any>(url, httpOptions);
  }
  dashboardMembers(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}dashboard/member`;

    return this.http.get<any>(url, httpOptions);
  }
  dashboardExportsMembers(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}dashboard/exportMemberData`;
    return this.http.get<any>(url, httpOptions);
  }
  dashboardExportsDetailMembers(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}dashboard/exportDetailMemberData`;
    return this.http.get<any>(url, httpOptions);
  }
  dashboardService(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}dashboard/service`;

    return this.http.get<any>(url, httpOptions);
  }
  dashboardExportsService(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params : params
    };
    const url = `${this.prayerRequestUrl}dashboard/exportServiceData`;
    return this.http.get<any>(url, httpOptions);
  }
  dashboardPrayer(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}dashboard/prayer`;

    return this.http.get<any>(url, httpOptions);
  }
  dashboardPrayerCause(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}dashboard/prayer-cause`;

    return this.http.get<any>(url, httpOptions);
  }
  dashboardExportsPrayer(params:any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params:params
    };
    const url = `${this.prayerRequestUrl}dashboard/exportPrayerData`;
    return this.http.get<any>(url, httpOptions);
  }
  dashboardDonation(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}dashboard/donation`;

    return this.http.get<any>(url, httpOptions);
  }
  dashboardExportsDonationData(params:any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params:params
    };
    const url = `${this.prayerRequestUrl}dashboard/exportDonationData`;
    return this.http.get<any>(url, httpOptions);
  }
  dashboardExportsDonorsData() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}dashboard/exportDonorsData`;
    return this.http.get<any>(url, httpOptions);
  }
  dashboardtvProgram(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}dashboard/tvProgram`;

    return this.http.get<any>(url, httpOptions);
  }
  dashboardExportTvProgramData(params:any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params:params
    };
    const url = `${this.prayerRequestUrl}dashboard/exportTvProgramData`;
    return this.http.get<any>(url, httpOptions);
  }
  dashboardExportSponsorsData() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}dashboard/exportSponsorsData`;
    return this.http.get<any>(url, httpOptions);
  }
  dashboardSupport(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${this.prayerRequestUrl}dashboard/support`;

    return this.http.get<any>(url, httpOptions);
  }
  dashboardExportsSupport(params:any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params:params
    };
    const url = `${this.prayerRequestUrl}dashboard/exportSupportData`;
    return this.http.get<any>(url, httpOptions);
  }

  donationView(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${this.prayerRequestUrl}user/donation/` + id;

    return this.http.get<any>(url, httpOptions);
  }
}
