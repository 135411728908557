<ng-container *ngIf="
    permissionsArray.members_List.analyse ||
      permissionsArray.members_List.edit ||
      permissionsArray.members_List.view;
    else alternateTemplate
  " class="main">
  <div class="container-fluid bg-white">
    <div class="user-list-header d-flex pt-3 pb-1 ml-2">
      <!-- <div class="d-flex"> -->
      <ng-container *ngIf="permissionsArray.members_List.view">
        <div class="user-list-heading d-flex">
          <img src="../../assets/images/panel_images/user-list.svg" class="user-list mr-2" />
          <h6 class="mt-2">Members List</h6>
        </div>
        <div class="search-bar ml-3">
          <mat-form-field class="example-full-width" appearance="fill">
            <input matInput placeholder="Search Users..." #input (keyup)="searchDropDown($event)"
              [(ngModel)]="searchClear" />
            <mat-icon matSuffix><img src="../../assets/images/panel_images/search-icon.svg"
                class="calendra-img" /></mat-icon>
          </mat-form-field>
        </div>
        <app-showloader></app-showloader>
        <!-- <div class="calendra-field mr-3 ml-3">
              <mat-form-field appearance="fill">
                <div class="arrow-up"> </div> <span matPrefix>Membership:&nbsp;</span>
                <mat-select [formControl]="Membership" multiple placeholder="Select" (selectionChange)="MemberShipDropDown($event)"  disableOptionCentering panelClass="eligablePanelClass" (keyup)="applyFilter($event)" #membership [(ngModel)]="eligbilityClear">
                  <mat-select-trigger> {{Membership.value?.[0] || ''}} <span *ngIf="(Membership.value?.length || 0) > 1" class="example-additional-selection">
              (+{{(Membership.value?.length || 0) - 1}} {{Membership.value?.length === 2 ? 'other' : 'others'}})
            </span> </mat-select-trigger>
                  <mat-option [class.eligable-option]="Eligiblestatus === 'Eligible'" [class.not-eligable-option]="Eligiblestatus === 'Not Eligible'" [class.not-member-option]="Eligiblestatus === 'Not Member'" [class.member-option]="Eligiblestatus === 'Member'" *ngFor="let Eligiblestatus of MemberShipList" [value]="Eligiblestatus">{{Eligiblestatus}}</mat-option>
                  <div class="apply-btn-option pt-4 pb-3">
                    <button type="button" class="btn btn-apply" (click)="membership.close()">Apply</button>
                  </div>
                </mat-select>

              </mat-form-field>
            </div> -->
        <div class="calendra-field mr-3 ml-3">
          <mat-form-field appearance="fill">
            <!-- <mat-label>Toppings</mat-label> -->
            <div class="arrow-up"></div>
            <span matPrefix>Status:&nbsp;</span>
            <mat-icon matSuffix><img src="../../assets/images/panel_images/arrow-1.svg"
                class="calendra-img" /></mat-icon>
            <mat-select [formControl]="Eligable" multiple placeholder="Select"
              (selectionChange)="statusDropDown($event)" disableOptionCentering panelClass="eligablePanelClass"
              (keyup)="applyFilter($event)" #closeSelect [(ngModel)]="eligbilityClear">
              <mat-select-trigger>
                {{Eligable.value?.[0] || ''}}
                <span *ngIf="(Eligable.value?.length || 0) > 1" class="example-additional-selection">
                  (+{{ (Eligable.value?.length || 0) - 1 }}
                  {{ Eligable.value?.length === 2 ? "other" : "others" }})
                </span>
              </mat-select-trigger>
              <mat-option [class.eligable-option]="Eligiblestatus === 'Eligible'"
                [class.not-eligable-option]="Eligiblestatus === 'Not Eligible'"
                [class.not-member-option]="Eligiblestatus === 'Not Member'"
                [class.member-option]="Eligiblestatus === 'Member'" *ngFor="let Eligiblestatus of EligableList"
                [value]="Eligiblestatus.value">{{ Eligiblestatus.key }}</mat-option>
              <div class="apply-btn-option pt-4 pb-3">
                <button type="button" class="btn btn-apply" (click)="closeSelect.close()">
                  Apply
                </button>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="export-button mr-3">
          <button type="button" class="cursor" (click)="memberExport()">Export
          </button>
        </div>
        <!-- <div class="user-list-drop-down mr-2">
          <div class="arrow-up"></div>
          <mat-form-field appearance="fill">
            <span matPrefix>Attendance:&nbsp;</span>
            <mat-select
              [formControl]="AttendanceControl"
              multiple
              placeholder="Select"
              disableOptionCentering
              panelClass="eligablePanelClass"
              (keyup)="applyFilter($event)"
              #closeattendance
              [(ngModel)]="attendanceClear"
            >
              <mat-select-trigger>
                {{AttendanceControl.value?.[0] || ''}}
                <span
                  *ngIf="(AttendanceControl.value?.length || 0) > 1"
                  class="example-additional-selection"
                >
                  (+{{ (AttendanceControl.value?.length || 0) - 1 }}
                  {{
                    AttendanceControl.value?.length === 2 ? "other" : "others"
                  }})
                </span>
              </mat-select-trigger>
              <mat-option
                class="services-view"
                *ngFor="let AttendanceData of attendance"
                [value]="AttendanceData"
                >{{ AttendanceData }}</mat-option
              >
              <div class="apply-btn-option pt-4 pb-3">
                <button
                  type="button"
                  class="btn btn-apply"
                  (click)="closeattendance.close()"
                >
                  Apply
                </button>
              </div>
            </mat-select>
          </mat-form-field>
        </div> -->
        <div class="graph cursor mr-2" (click)="reset()">
          <img src="../../assets/images/panel_images/reset-1.png" class="reset-img ml-2" />
        </div>
      </ng-container>

      <!-- </div> -->

      <!-- #ml-auto -->
      <div class="graph cursor mr-2 ml-auto " *ngIf="permissionsArray.members_List.analyse" (click)="Analytics()">
        <img src="../../assets/images/panel_images/graph.svg" class="graph-img ml-2" />
      </div>

    </div>
  </div>
  <ng-container *ngIf="permissionsArray.members_List.view; else listTemplate" class="container-fluid pt-4 ml-1">
    <div class="d-flex p-4">
      <div class="card users-count mr-3 total-user-bg">
        <div class="card-body d-flex">
          <img src="../../assets/images/panel_images/total-users.svg" id="users-total" class="mr-2" />
          <div class="card-details-users">
            <h4 class="mt-3">Total Users</h4>
            <h2 class="mt-2">
              {{ totalrecords ? (totalrecords | records) : "0" }}
            </h2>
          </div>
        </div>
      </div>
      <div class="card users-count mr-3 total-existing-bg">
        <div class="card-body d-flex">
          <img src="../../assets/images/panel_images/total-users-1.svg" id="users-total" class="mr-2" />
          <div class="card-details-users">
            <h4 class="mt-3">Male</h4>
            <h2 class="mt-2">{{ male ? (male | records) : "0" }}</h2>
          </div>
        </div>
      </div>
      <div class="card users-count total-non-bg mr-3">
        <div class="card-body d-flex">
          <img src="../../assets/images/panel_images/total-users-3.svg" id="users-total" class="mr-2" />
          <div class="card-details-users">
            <h4 class="mt-3">Female</h4>
            <h2 class="mt-2">{{ female ? (female | records) : "0" }}</h2>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="spinner">
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  type="ball-spin-clockwise-fade"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
		</div> -->
    <div class="table mt-5 mb-5">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 mr-3">
        <!-- Position Column -->
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef>
            <h2>user</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              <img *ngIf="element.profile ? element.profile : ''" src="{{ element?.profile ? element.profile : '' }}"
                class="user-image-table mr-2 mt-md-2" />

              <!-- <img src="../../assets/images/panel_images/total-users-1.svg" class="user-image-table mr-2" *ngIf="element.profile == 0"/> -->
              <ngx-avatar *ngIf="element.profile == null" class="mr-2" size="40" initialsSize="1"
                name="{{ element.name }}"></ngx-avatar>
              <ngx-avatar *ngIf="element?.name == null" class="mr-3 name-a" initialsSize="1" size="40"
                name="N/A"></ngx-avatar>
              <div class="">
                <h6 class="mt-2">{{ element.name ? element.name : "N/A" }}</h6>
                <p>{{ element?.email ? element?.email : "" }}</p>
              </div>
            </div>
          </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="Membershipid">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Membership id</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h6>{{ element?.membershipId ? element?.membershipId : "N/A" }}</h6>
          </td>
        </ng-container>
        <ng-container matColumnDef="Phoneno">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Mobile no.</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h4>
              <span *ngIf="element?.phone != null && element?.phone != ''">+91-</span>{{ element?.phone ? element?.phone
              : "N/A" }}
            </h4>
          </td>
        </ng-container>
        <!-- Weight Column -->
        <!-- <ng-container matColumnDef="Attendance">
					<th mat-header-cell *matHeaderCellDef>
						<h2>Attendance</h2></th>
					<td mat-cell *matCellDef="let element">
						<h6>{{ element?.attendance ? element?.attendance : 'N/A'}}</h6> </td>
				</ng-container> -->
        <!-- Symbol Column -->
        <ng-container matColumnDef="Date">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Date of Creation</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h6>{{ element.createdAt | date : "MMMM d, y" }} <br/>
              {{ element.createdAt | date : "shortTime" }}</h6>
          </td>
        </ng-container>
        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef style="text-align: center !important;">
            <h2>Status</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <!-- <h6 *ngIf="element.Status == 'Eligible'" class="eligable">
            {{ element.Status }}
          </h6>
						<h6 *ngIf="element.Status == 'Not Member'" class="not-member">
            {{ element.Status }}
          </h6>
						<h6 *ngIf="element.Status == 'Not Eligible'" class="not-eligable">
            {{ element.Status }}
          </h6>  -->
            <h6 *ngIf="element.isMembershipId == false && element.isActive == true" class="not-member">
              Not Member
            </h6>
            <h6 *ngIf="
                element.isActive == false && (element.isMembershipId == false ||  element.isMembershipId == true)
              " class="not-member">
              Suspended
            </h6>

            <h6 *ngIf="element.isMembershipId == true && element.isActive == true && element.temporaryMember ==false"
              class="memberStatus">
              Member
              <img *ngIf="element.isEligible == true" class="mb-1" src="../../assets/images/other_images/verify.png"
                width="18" alt="" srcset="" />
            </h6>
            <h6 *ngIf="element.temporaryMember == true && element.isActive == true" class="memberStatus">
              Temp. Member
            </h6>
          </td>
        </ng-container>
        <!-- <ng-container matColumnDef="Lastvisited">
					<th mat-header-cell *matHeaderCellDef>
						<h2>Last visited</h2> </th>
					<td mat-cell *matCellDef="let element">
						<h6>{{ element.Lastvisited }}</h6> </td>
				</ng-container> -->
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Action</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button mat-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>
                <img src="../../assets/images/panel_images/action-dot.svg" id="users-total"
                  class="action-dots" /></mat-icon>
              <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" place></div>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button class="View" mat-menu-item value="Edit" (click)="view()" routerLink="./viewuser"
                [queryParams]="{ _id: element._id }">
                <img src="../../assets/images/panel_images/view.svg" class="mr-3" />
                <span>View</span>
              </button>
              <button class="Deactivate" mat-menu-item value="Deactivate" *ngIf="
                  element.isActive == true && permissionsArray.members_List.edit
                " routerLink="./" [queryParams]="{ _id: element._id }" (click)="deActivateUser()">
                <img src="../../assets/images/panel_images/suspened.svg" class="mr-3" />
                <span>Suspend</span>
                <!-- <span>Change Customer type from New,Regular</span> -->
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            No data available
            <!-- "{{ input.value }}" -->
          </td>
        </tr>
      </table>
      <mat-paginator (page)="pageChanged($event)" [length]="totalrecords" [pageIndex]="currentPage"
        [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator>
    </div>
  </ng-container>

  <ng-template #listTemplate>
    <div class="mt-5 text-center">
      <img src="../../assets//images/panel_images/notPermitted.png" width="120" alt="" />
      <p class="mt-5">You dont have permission to access this feature</p>
    </div>
  </ng-template>
</ng-container>
<ng-template #alternateTemplate>
  <div class="mt-5 text-center">
    <img src="../../assets//images/panel_images/notPermitted.png" width="120" alt="" />
    <p class="mt-5">You dont have permission to access this feature</p>
  </div>
</ng-template>